// importing modules
import React, { useState } from 'react';
// importing components
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonIcon,
    IonImg,
    IonLabel,
    IonModal,
    IonRow,
} from '@ionic/react';
import Strokes from 'components/Strokes';
// importing assets
import { arrowBackOutline } from 'ionicons/icons';
// importing types & graphql
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_ONE_SKILL_NEEDED } from 'GraphQL/SkillNeeded/UPDATE_ONE_SKILL_NEEDED/UPDATE_ONE_SKILL_NEEDED';
import {
    UpdateOneSkillNeeded,
    UpdateOneSkillNeededVariables,
} from 'GraphQL/__generated__/UpdateOneSkillNeeded';

interface SkillToEdit {
    id: number;
    name: string;
    description: string;
    additionalDetails: string;
    peopleNeeded: number;
}

export default ({
    userImg,
    isEditSkillModalActive,
    setisEditSkillModalActive,
    skill,
}: {
    userImg: string;
    isEditSkillModalActive: boolean;
    setisEditSkillModalActive: Function;
    skill: SkillToEdit;
}) => {
    const [skillT, setSkillT] = useState<string>(skill.description);
    const [skillD, setSkillD] = useState<string>(skill.additionalDetails);
    const [peopleN, setPeopleN] = useState<string>(
        skill.peopleNeeded.toString()
    );

    const [updateSkillNeeded, updateSkillNeededDatas] = useMutation<
        UpdateOneSkillNeeded,
        UpdateOneSkillNeededVariables
    >(UPDATE_ONE_SKILL_NEEDED);

    const handleSkillUpdate = () => {
        updateSkillNeeded({
            variables: {
                id: skill.id,
                description: skillT.length > 0 ? skillT : skill.description,
                additionalDetails:
                    skillD.length > 0 ? skillD : skill.additionalDetails,
                peopleNeededCount:
                    parseInt(peopleN) > 0
                        ? parseInt(peopleN)
                        : skill.peopleNeeded,
            },
        })
            .then((result) => {
                setisEditSkillModalActive(false);
            })
            .catch((error) => console.error(error));
    };

    return (
        <IonModal isOpen={isEditSkillModalActive}>
            <IonContent>
                <IonGrid>
                    {/* skillInfo header */}
                    <IonRow className='skillInfo__header'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='skillInfo__header--container'
                        >
                            <Strokes top={true} />
                            <IonCol
                                sizeXs='12'
                                sizeSm='12'
                                sizeMd='12'
                                className='completeTaskNav__btns--wrap'
                            >
                                <IonButton
                                    className='completeTaskNav__btn'
                                    onClick={() =>
                                        setisEditSkillModalActive(false)
                                    }
                                    color='ion-primary'
                                    fill='clear'
                                >
                                    <IonIcon
                                        className='completeTaskNav__btn--icon'
                                        icon={arrowBackOutline}
                                    />
                                </IonButton>
                            </IonCol>
                            <IonImg
                                className='createTask__header--img'
                                src={userImg}
                                alt='user'
                            />
                        </IonCol>
                    </IonRow>
                    {/* text content */}
                    <IonRow className='skillInfo__textContent'>
                        <IonCol
                            className='skillInfo__textContent--container'
                            sizeSm='12'
                            sizeXs='12'
                        >
                            <h1 className='skillInfo__textContent--title'>
                                Describe your need in detail
                            </h1>
                            <div className='skillInfo__textContent--badge'>
                                <p className='skillInfo__textContent--badge--text'>
                                    {skill.name}
                                </p>
                            </div>
                        </IonCol>
                    </IonRow>
                    {/* skill def form */}
                    <IonGrid className='skillInfo__form--container'>
                        <IonRow className='skillInfo__form--row'>
                            <IonCol
                                className='skillInfo__form--input--wrap'
                                sizeXs='12'
                                sizeSm='12'
                            >
                                <IonLabel className='skillInfo__form--label'>
                                    Type of {skill.name}
                                </IonLabel>
                                <input
                                    autoComplete='off'
                                    onChange={(e) => setSkillT(e.target.value)}
                                    defaultValue={skill.description}
                                    className='skillInfo__form--input'
                                    placeholder='Ex: Instagram post designer...'
                                    type='text'
                                    required
                                    name='skillType'
                                />
                            </IonCol>
                            <IonCol
                                className='skillInfo__form--input--wrap'
                                sizeXs='12'
                                sizeSm='12'
                            >
                                <IonLabel className='skillInfo__form--label'>
                                    What should they know how to do?
                                </IonLabel>
                                <textarea
                                    autoComplete='off'
                                    onChange={(e) => setSkillD(e.target.value)}
                                    defaultValue={skill.additionalDetails}
                                    className='skillInfo__form--textarea'
                                    placeholder='Ex: Experience with social posting…'
                                    required
                                ></textarea>
                            </IonCol>
                            <IonCol
                                className='skillInfo__form--inputs--container'
                                sizeXs='12'
                                sizeSm='12'
                            >
                                <IonCol
                                    className='skillInfo__form--input--wrap'
                                    sizeXs='12'
                                    sizeSm='12'
                                >
                                    <IonLabel className='skillInfo__form--label'>
                                        How Many People?
                                    </IonLabel>
                                    <input
                                        autoComplete='off'
                                        onChange={(e) =>
                                            setPeopleN(e.target.value)
                                        }
                                        defaultValue={skill.peopleNeeded}
                                        className='skillInfo__form--input'
                                        placeholder='Ex: 2'
                                        type='number'
                                        required
                                    />
                                </IonCol>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                    {/* btn */}
                    <IonRow className='skillInfo__btn--container'>
                        <IonCol
                            className='skillInfo__btn--wrap'
                            sizeXs='12'
                            sizeSm='12'
                        >
                            <IonButton
                                onClick={() => {
                                    handleSkillUpdate();
                                }}
                                fill='clear'
                                disabled={!(parseInt(peopleN) > 0)}
                                color='ion-primary'
                                className='skillInfo__btn--add'
                            >
                                Update
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonModal>
    );
};
