// importing modules
import React from 'react';
// importing components
import { IonCol, IonGrid, IonRow, IonToolbar, IonFooter } from '@ionic/react';
// importing styles
import './FooterWithChildren.css';

const FooterWithChildren: React.FC = ({ children }) => {
    return (
        <IonFooter className='footerWithChild'>
            <IonToolbar className='footerWithChild__wrap'>
                <IonGrid className='footerWithChild__grid'>
                    <IonRow className='footerWithChild__row'>
                        <IonCol
                            className='footerWithChild__col'
                            sizeXs='12'
                            sizeSm='12'
                        >
                            {children}
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonToolbar>
        </IonFooter>
    );
};

export default FooterWithChildren;
