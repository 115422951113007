import React from 'react';
import { IonCol } from '@ionic/react';
import { useHistory } from 'react-router-dom';
import './SkillsOfferedByUser.css';
import { UserProfile_byID_profile_skills } from '../../GraphQL/__generated__/UserProfile_byID';

interface extendMe extends UserProfile_byID_profile_skills {
    skillfullPersonsName: string;
    jobs: any;
}

const SkillOfferedItem: React.FC<extendMe> = (skill) => {
    const completedJobs = skill.jobs.filter(
        (job) =>
            job.Skill.name === skill.name &&
            job.Status.toString() === 'COMPLETED'
    );
    const history = useHistory();
    const encodedSkillName = encodeURIComponent(skill.category);
    const provider = skill.ownedByPortfolio.uid;
    return (
        <IonCol className='skillsOffered__col' sizeXs='12' sizeSm='12'>
            <IonCol
                sizeXs='9'
                sizeSm='9'
                className='ion-padding skillsOffered__content'
            >
                <p className='skillsOffered__content--keyword'>
                    {skill.category}
                </p>
                <p className='skillsOffered__content--skill'>{skill.name}</p>
                {/* update tasks completed with actual tasks data */}
                <p className='skillsOffered__content--completed'>
                    {completedJobs.length > 0 ? completedJobs.length : 0} Task
                    {completedJobs.length === 1 ? '' : 's'} Completed
                </p>
                <p className='skillsOffered__content--text'>
                    {skill.description}
                </p>
            </IonCol>
            <IonCol
                onClick={() =>
                    history.push(
                        `/requestSkill/${provider}/${encodedSkillName}/${skill.id}/date`
                    )
                }
                sizeXs='3'
                sizeSm='3'
                className='skillsOffered__request--btn'
            >
                <p className='skillsOffered__request--btn--text'>Request</p>
                <p className='skillsOffered__request--btn--name'>Me</p>
            </IonCol>
        </IonCol>
    );
};

export default SkillOfferedItem;
