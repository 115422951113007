// importing modules
import React, { ReactElement } from 'react';
import moment from 'moment-timezone';
import Linkify from 'react-linkify';
// importing utils
import { getLocalStorage } from '../../../helpers/localStorage/localStorageHelper';

const ReceiverChatBubble: React.FC<{
    id: string;
    sentAt: any;
    message: string;
    sender: string;
    senderName: string;
}> = ({ message, sentAt, id }) => {
    let timeStampEl: ReactElement;

    if (sentAt !== null) {
        const timezone = getLocalStorage('tz');
        const timeSentAt = moment(sentAt.toDate())
            .tz(timezone, false)
            .format('h:mm A'); // search by date
        timeStampEl = <p className='chat__timestamp'>{timeSentAt}</p>;
    }

    return (
        <div className='chat__receiver--col'>
            <p className='chat__receiver--text'>
                <Linkify>{message}</Linkify>
            </p>
            {timeStampEl}
        </div>
    );
};

export default ReceiverChatBubble;
