// importing assets
import React, { useEffect, useRef } from 'react';
import { Redirect, useParams } from 'react-router-dom';
// importing components
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonPage,
    IonRow,
} from '@ionic/react';
import ConfirmationScreenLogo from '../../../components/ConfirmationScreenLogo/ConfirmationScreenLogo';
import Loading from '../../../components/status/Loading';
// importing assets
// importing utilities
import { int_to_credits } from '../../../utils/timeConversion';
import {
    setLocalStorage,
    getLocalStorage,
} from '../../../helpers/localStorage/localStorageHelper';
// importing graphql
import { useQuery } from '@apollo/react-hooks';
import {
    GetParticipationRequestById,
    GetParticipationRequestByIdVariables,
} from '../../../GraphQL/__generated__/GetParticipationRequestById';
import { GET_PARTICIPATION_REQUEST_BY_ID } from '../../../GraphQL/ParticipationRequest/GET_PARTICIPATION_REQUEST_BY_ID/GET_PARTICIPATION_REQUEST_BY_ID';
// importing styles
import './TaskCompletedConfirmation.css';
import { useAuth } from '../../../context/firebase/authContext';
import Strokes from 'components/Strokes/Strokes';

const TaskConfirmation: React.FC = () => {
    const { currentUser } = useAuth();
    const { taskId } = useParams<{ taskId: string }>();
    const homeLink = useRef(null);

    const { data, loading } = useQuery<
        GetParticipationRequestById,
        GetParticipationRequestByIdVariables
    >(GET_PARTICIPATION_REQUEST_BY_ID, {
        variables: { id: parseInt(taskId) },
        fetchPolicy: 'no-cache',
    });

    useEffect(() => {
        setTimeout(() => {
            setLocalStorage('completeTaskPostedViaProvider', {
                hrs: 0,
                mins: 0,
                review: '',
                rating: 0,
            });
            homeLink.current?.click();
        }, 2000);
    }, []);

    if (loading) {
        return <Loading />;
    }

    // current user uid has to match the jobpost poster's uid
    if (currentUser.uid != data.participationRequest.provider.uid) {
        return <Redirect to='/' />;
    }

    const taskHrs: number = parseInt(
        getLocalStorage('completeTaskPostedViaProvider').hrs
    );
    const taskMins: number = parseInt(
        getLocalStorage('completeTaskPostedViaProvider').mins
    );

    return (
        <IonPage>
            <IonContent>
                <IonGrid className='taskConfirm__grid'>
                    <IonRow className='taskConfirm'>
                        <IonCol
                            sizeXs='12'
                            sizeSm='12'
                            className='taskConfirm__container'
                        >
                            <ConfirmationScreenLogo />
                            <Strokes />
                            <div className='taskConfirm__img--wrap'>
                                <IonImg
                                    src={
                                        !loading &&
                                        data.participationRequest.provider
                                            .profilePicture
                                    }
                                    className='taskConfirm__img'
                                />
                            </div>
                            <h1 className='taskConfirm__title'>
                                Congrats, task completed!
                            </h1>
                            <p className='taskConfirm__text'>
                                You will be given{' '}
                                {int_to_credits(taskHrs, taskMins)} Balance
                                Tokens and will see your review on their profile
                                shortly.
                            </p>
                            <a ref={homeLink} href={`/home`} type='hidden'></a>

                            {/* <IonButton
                                onClick={() => {
                                    history.push('/home');
                                    setLocalStorage('completeTaskPostedViaProvider', {
                                        hrs: 0,
                                        mins: 0,
                                        review: '',
                                        rating: 0,
                                    });
                                }}
                                fill='clear'
                                color='ion-primary'
                                className='taskConfirm__btn'
                            >
                                <Link
                                    to={{ pathname: `/home` }}
                                    className='completeTask__btn--link'
                                >
                                    Return Home
                                </Link>
                            </IonButton> */}
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default TaskConfirmation;
