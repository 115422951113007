import gql from 'graphql-tag';

export const SUBSCRIBEMAILCHIMP = gql`
    query SubscribeUserToMailchimp(
        $email: String!
        $fname: String
        $lname: String
        $groupcode: String
    ) {
        SubscribeUserToMailchimp(
            email: $email
            fname: $fname
            lname: $lname
            groupcode: $groupcode
        ) {
            response
        }
    }
`;

// The name "SubscribeUserToMailchimp" in the first line of query is irrelevant.
// The name "SubscribeUserToMailchimp" in the second line of the query is important.
