// importing modules
import React from 'react';
import { useHistory } from 'react-router-dom';
// importing components
import { IonCol, IonContent, IonPage, IonGrid, IonHeader } from '@ionic/react';
import CommunityOnboardingForm from './UI/CommunityOnBoardingForm';
import BackButton from 'components/BackButton';
// importing assets
import yinglogo from '../../assets/ying-logo-black@3x.png';
// importing styles
import './CommunityOnboarding.css';

const CommunityOnboard: React.FC = () => {
    const history = useHistory();

    return (
        <IonPage>
            <IonContent>
                <IonGrid>
                    {/* header */}
                    <IonHeader className='communityOnboard__header'>
                        <IonCol
                            className='communityOnboard__header--logo--wrap'
                            sizeXs='12'
                            sizeSm='12'
                            sizeMd='12'
                            sizeLg='12'
                            sizeXl='12'
                        >
                            <img
                                className='communityOnboard__header--logo'
                                src={yinglogo}
                                alt='ying logo'
                            />
                        </IonCol>
                    </IonHeader>
                    {/* back button */}
                    <div className='communityOnboard__btn--wrap'>
                        <BackButton />
                    </div>
                    <CommunityOnboardingForm />
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default CommunityOnboard;
