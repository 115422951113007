// importing modules
import React, { ReactElement, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import moment from 'moment-timezone';
import Linkify from 'react-linkify';
// importing utils
import { getLocalStorage } from '../../../helpers/localStorage/localStorageHelper';
import { IonIcon, IonAlert } from '@ionic/react';
import { trash } from 'ionicons/icons';

const SenderChatBubble: React.FC<{
    id: string;
    sentAt: any;
    message: string;
    sender: string;
    senderName: string;
    chatroomId: string;
}> = ({ message, sentAt, id, chatroomId }) => {
    const [showDeleteIcon, setShowDeleteIcon] = useState<boolean>(false);
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const firestore = firebase.firestore();

    let timeStampEl: ReactElement;
    if (sentAt !== null) {
        const timezone = getLocalStorage('tz');
        const timeSentAt = moment(sentAt.toDate())
            .tz(timezone, false)
            .format('h:mm A'); // search by date
        timeStampEl = <p className='chat__timestamp'>{timeSentAt}</p>;
    }

    const handleDeleteMessage = async (messageId) => {
        firestore
            .collection(`/chatrooms/${chatroomId}/messages/`)
            .doc(messageId)
            .get()
            .then((querySnapShot) => {
                querySnapShot.ref.delete();
            })
            .catch((err) => console.error('Error removing document: ', err));
    };

    return (
        <div
            className='chat__sender--col'
            onClick={() => setShowDeleteIcon(!showDeleteIcon)}
        >
            <IonAlert
                isOpen={showAlert}
                onDidDismiss={() => setShowAlert(false)}
                cssClass='msgChatroom__alert'
                header={'Delete Message'}
                message={'Are you sure you want to delete this message?'}
                buttons={[
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        handler: () => setShowAlert(false),
                        cssClass: 'msgChatroom__alert--btn--delete',
                    },
                    {
                        text: 'Delete',
                        handler: () => handleDeleteMessage(id),
                        cssClass: 'msgChatroom__alert--btn--delete',
                    },
                ]}
            />
            {showDeleteIcon && (
                <div
                    className='chat__sender--icon--wrap'
                    onClick={() => setShowAlert(true)}
                >
                    <IonIcon icon={trash} className='chat__sender--icon' />
                </div>
            )}
            <p className='chat__sender--text'>
                <Linkify>{message}</Linkify>
            </p>
            {timeStampEl}
        </div>
    );
};

export default SenderChatBubble;
