// importing modules
import React, { useState, useEffect, ReactElement } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
// importing components
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonPage,
    IonRow,
} from '@ionic/react';
import UserImg from '../UserImg/UserImg';
import LocationsModal from './LocationsModal/LocationsModal';
import RequestTaskTabs from '../UI/RequestTaskTabs';
import TaskLocationPreferenceItem from './TaskLocationPreferenceItem/TaskLocationPreferenceItem';
import Loading from '../../../components/status/Loading';
import Strokes from 'components/Strokes';
// importing assets
// importing utilities
import capitalize from '../../../utils/capitalize';
import {
    getLocalStorage,
    setLocalStorage,
} from '../../../helpers/localStorage/localStorageHelper';
import { LocationPreference } from '../../../GraphQL/__generated__/globalTypes';
// importing graphql
import { useQuery } from '@apollo/react-hooks';
import { GET_SKILL_BY_ID } from '../../../GraphQL/Skill/GET_SKILL_BY_ID/GET_SKILL_BY_ID';
import {
    GetSkillById,
    GetSkillByIdVariables,
} from '../../../GraphQL/__generated__/GetSkillById';
// importing styles
import './TaskLocation.css';
import { useAuth } from '../../../context/firebase/authContext';
import {
    UserByUID,
    UserByUIDVariables,
} from '../../../GraphQL/__generated__/UserByUID';
import { USER_ORGANIZATIONS_BY_UID } from '../../../GraphQL/Profile/USER_ORGANIZATIONS/USER_ORGANIZATIONS_BY_UID';

const locationsPreference = [
    {
        id: '1',
        name: LocationPreference.VIRTUAL,
        text: 'Virtual',
        description:
            'Send the skill provider a video conference link in the chat once you complete this request',
    },
    {
        id: '2',
        name: LocationPreference.INPERSON,
        text: 'In Person',
        description: 'Enter a place or choose one provided',
    },
];

const TaskLocation: React.FC = () => {
    const { currentUser } = useAuth();
    const history = useHistory();
    const { provider, skillName, skillId } = useParams<{
        provider: string;
        skillName: string;
        skillId: string;
    }>();
    const [taskLocation, setTaskLocation] = useState<string>('');
    const [locationType, setLocationType] = useState<string>('');
    const [modalStatus, setModalStatus] = useState<boolean>(false);

    useEffect(() => {
        if (getLocalStorage('requestSkill')) {
            const requestSkillObj = getLocalStorage('requestSkill');
            setLocationType(requestSkillObj.locationType);
            setTaskLocation(requestSkillObj.location);
        } else {
            history.push('/home');
        }
    }, []);

    const handleModal = () => setModalStatus(!modalStatus);
    const handleLocation = (loc: string) => {
        const requestSkillObj = getLocalStorage('requestSkill');
        requestSkillObj.location = loc !== 'Virtual' ? loc : 'Virtual';
        requestSkillObj.locationType = locationType;
        setLocalStorage('requestSkill', requestSkillObj);
        history.push(
            `/requestSkill/${provider}/${skillName}/${skillId}/message`
        );
    };

    const { data, loading, error, fetchMore } = useQuery<
        GetSkillById, // query return return type (shape of data)
        GetSkillByIdVariables // query request type       (shape of variables)
    >(GET_SKILL_BY_ID, {
        variables: {
            id: parseInt(skillId),
        },
    });

    //Gets organizations of both currentUser and service provider
    const { data: currentUserData, loading: currentUserDataLoading } = useQuery<
        UserByUID, // query return return type (shape of data)
        UserByUIDVariables // query request type       (shape of variables)
    >(USER_ORGANIZATIONS_BY_UID, {
        variables: {
            subId: currentUser.uid,
        },
    });
    const { data: providerUserData, loading: providerUserDataLoading } =
        useQuery<
            UserByUID, // query return return type (shape of data)
            UserByUIDVariables // query request type       (shape of variables)
        >(USER_ORGANIZATIONS_BY_UID, {
            variables: {
                subId: provider,
            },
        });

    if (loading || currentUserDataLoading || providerUserDataLoading) {
        return <Loading />;
    }

    //It checks if users have a group in common
    if (currentUserData != undefined && providerUserData != undefined) {
        const filteredArray = currentUserData.profile.organizations
            .map((orgs) => orgs.id)
            .filter((value) =>
                providerUserData.profile.organizations
                    .map((orgs) => orgs.id)
                    .includes(value)
            );

        if (filteredArray.length == 0) {
            return <Redirect to='/' />;
        }
    }

    // User cannot request himself.
    //Cannot be placed higher because hooks cannot be done condiniotaly
    if (provider == currentUser.uid) {
        return <Redirect to='/' />;
    }

    let locationsPreferenceArr;
    let preferenceText: ReactElement;
    if (
        data.skill.location.locationPreference === LocationPreference.OPENTOALL
    ) {
        locationsPreferenceArr = locationsPreference;
        preferenceText = (
            <h1 className='reqTaskLoc__textContent--title'>
                Would you prefer to meet <span>in person</span> or via{' '}
                <span>video call?</span>
            </h1>
        );
    } else if (
        data.skill.location.locationPreference === LocationPreference.VIRTUAL
    ) {
        locationsPreferenceArr = locationsPreference.slice(0, 1);
        preferenceText = (
            <h1 className='reqTaskLoc__textContent--title'>
                {!loading && capitalize(data.skill.ownedByPortfolio.firstName)}{' '}
                has agreed to book <span>video/conference call</span> sessions{' '}
                <span>only</span>
            </h1>
        );
    } else if (
        data.skill.location.locationPreference === LocationPreference.INPERSON
    ) {
        locationsPreferenceArr = locationsPreference.slice(1, 2);
        preferenceText = (
            <h1 className='reqTaskLoc__textContent--title'>
                {!loading && capitalize(data.skill.ownedByPortfolio.firstName)}{' '}
                has agreed to book <span>in person</span> sessions{' '}
                <span>only</span>
            </h1>
        );
    }

    return (
        <IonPage>
            <IonContent>
                <IonGrid>
                    {/* locations modal */}
                    <LocationsModal
                        locationStr={
                            !loading && data.skill.location.locationString
                        }
                        providerName={
                            !loading && data.skill.ownedByPortfolio.firstName
                        }
                        provider={provider}
                        handleLocation={handleLocation}
                        modalStatus={modalStatus}
                        handleModal={handleModal}
                    />
                    {/* taskLocation header */}
                    <IonRow className='reqTaskLoc__header'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='reqTaskLoc__header--container'
                        >
                            <Strokes top={true} />
                            <RequestTaskTabs
                                prevPage={`/requestSkill/${provider}/${skillName}/${skillId}/time`}
                                disablePrevStep={false}
                            />
                            <UserImg providerUid={provider} />
                        </IonCol>
                    </IonRow>
                    {/* text content */}
                    <IonRow className='reqTaskLoc__textContent'>
                        <IonCol
                            className='reqTaskLoc__textContent--container'
                            sizeSm='12'
                            sizeXs='12'
                        >
                            {preferenceText}
                        </IonCol>
                    </IonRow>
                    {/* locations */}
                    <IonGrid className='reqTaskLoc__form--container'>
                        <IonRow
                            className='reqTaskLoc__form--row'
                            id='skill-request-location-option'
                        >
                            <IonRow className='reqTaskLoc__col--wrap'>
                                {locationsPreferenceArr.map(
                                    (location, index) => (
                                        <TaskLocationPreferenceItem
                                            key={location.id}
                                            text={location.text}
                                            name={location.name}
                                            description={location.description}
                                            setLocationType={setLocationType}
                                            locationType={locationType}
                                            handleModal={handleModal}
                                        />
                                    )
                                )}
                            </IonRow>
                        </IonRow>
                    </IonGrid>
                    {/* btn */}
                    <IonRow className='reqTaskLoc__btn--container'>
                        <IonCol
                            className='reqTaskLoc__btn--wrap'
                            sizeXs='12'
                            sizeSm='12'
                        >
                            <IonButton
                                fill='clear'
                                disabled={
                                    locationType !== LocationPreference.VIRTUAL
                                }
                                onClick={() => handleLocation(locationType)}
                                color='ion-primary'
                                className='createTask__btn'
                            >
                                Next Step
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default TaskLocation;
