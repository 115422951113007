// importing modules
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAuth } from 'context/firebase/authContext';
// importing components
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonPage,
    IonRow,
} from '@ionic/react';
import ErrorMsg, {
    InputErrorMsgCentered,
} from '../../../components/InputErrorMsg/InputErrorMsg';
import BackButton from 'components/BackButton';
import Strokes from 'components/Strokes/Strokes';
// importing assets
// importing styles
import './PhoneNumberVerification.css';
// importing graphql utilities - types
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { FirebaseHelper } from '../../../helpers/FirebaseHelper';
import { yingDebug, yingInfo } from '../../../utils/debug/YingDebug';
import { MAILCHIMPPHONENUMBERVERIFICATION } from '../../../GraphQL/mailchimp/MailchimpPhoneNumberVerification';
import { useOnboardingFlow } from '../../../utils/onboarding';

type VerificationCode = {
    verificationCode: number;
};

const PhoneNumberVerification: React.FC = () => {
    const { register, handleSubmit, errors } = useForm<VerificationCode>();
    const [verificationCode, setVerification] = useState<String>('');
    const [verificationFeedback, setFeedback] = useState<string>('');
    const { go, next } = useOnboardingFlow();
    const { currentUser } = useAuth();

    const [mailchimpSend, { data: chimpSend }] = useLazyQuery(
        MAILCHIMPPHONENUMBERVERIFICATION
    );

    const handleOnSubmit = async (data: VerificationCode) => {
        try {
            await mailchimpSend({
                variables: {
                    email: currentUser.email,
                },
            });
        } catch (e) {
            console.error(e);
        }

        //@ts-ignore
        if (window.confirmationResult === undefined) {
            // something went wrong, redirect to the phone entry page
            go();
            return;
        }
        //@ts-ignore
        window.confirmationResult
            .confirm(data.verificationCode.toString())
            .then(function (usercred) {
                const user = usercred.user;

                // next takes the onboarding section we expect to be on now, so that
                // if it gets called twice side effects only happen once
                next('/onboarding/phone');
            })
            .catch(function (error) {
                setFeedback(error.code);
                console.error('Account linking error', error);
            })
            .finally(() =>
                yingInfo('END currentUser.linkWithCredential(credential)')
            );
    };

    if (chimpSend) {
        yingInfo(JSON.stringify(chimpSend));
    }

    const handleVerification = (event: React.ChangeEvent<HTMLInputElement>) => {
        setVerification(event.target.value);
    };

    return (
        <IonPage>
            <IonContent>
                <IonGrid className='phoneVf__grid'>
                    <IonRow className='phoneVf__row'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='phoneVf__container'
                        >
                            <Strokes top={true} />
                            <BackButton color='white' />
                            <Strokes bot={true} />
                            <IonCol
                                sizeSm='12'
                                sizeXs='12'
                                className='phoneVf__content--container'
                            >
                                <h1 className='phoneVf__content--title'>
                                    Please<span> Verify Your #</span>
                                </h1>
                                <p className='phoneVf__content--text'>
                                    We have sent an SMS code to the phone number
                                    you provided.
                                </p>
                                {verificationFeedback && (
                                    <InputErrorMsgCentered errorMsg='The confirmation code you entered is incorrect. Please try again or go back to make sure you entered the correct phone number.' />
                                )}
                                {errors.verificationCode &&
                                    errors.verificationCode.type ===
                                        'required' && (
                                        <InputErrorMsgCentered errorMsg='Verification code required' />
                                    )}
                                <form
                                    onSubmit={handleSubmit(handleOnSubmit)}
                                    className='phoneVf__input--container'
                                >
                                    <div className='phoneVf__input--wrap'>
                                        <input
                                            onChange={handleVerification}
                                            name='verificationCode'
                                            type='number'
                                            className={
                                                errors.verificationCode
                                                    ? 'phoneVf__input phoneVf__input--error'
                                                    : 'phoneVf__input'
                                            }
                                            placeholder='Enter the 6-digit code'
                                            ref={register({
                                                required:
                                                    'Verification Code required',
                                                maxLength: 6,
                                            })}
                                        />
                                    </div>
                                    <IonButton
                                        fill='clear'
                                        disabled={
                                            !(
                                                verificationCode.toString()
                                                    .length === 6
                                            )
                                        }
                                        type='submit'
                                        color='ion-primary'
                                        className='phoneVf__content--btn'
                                    >
                                        Verify
                                    </IonButton>
                                </form>
                            </IonCol>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default PhoneNumberVerification;
