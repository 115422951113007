// importing modules
import React, { useState } from 'react';
import moment from 'moment-timezone';
import { useHistory } from 'react-router-dom';
// * importing components
import {
    IonIcon,
    IonCol,
    IonAvatar,
    IonItemSliding,
    IonItemOptions,
    IonItemOption,
    IonItem,
} from '@ionic/react';
import NotificationActionCard from '../NotificationActionCard/NotificationActionCard';
// importing assets
import { filter, notifications, trashOutline } from 'ionicons/icons';
// importing graphql & types
import { useMutation } from '@apollo/react-hooks';
import {
    DeleteOneNotification,
    DeleteOneNotificationVariables,
} from '../../../GraphQL/__generated__/DeleteOneNotification';
import { DELETE_ONE_NOTIFICATION } from '../../../GraphQL/Notification/DELETE_ONE_NOTIFICATION/DELETE_ONE_NOTIFICATION';
import {
    GetNotificationsByUser_notificationsByUserUid,
    GetNotificationsByUser_profile_organizations,
} from '../../../GraphQL/__generated__/GetNotificationsByUser';
// import style sheets
import './NotificationTile.css';
import { NotificationType } from '../../../GraphQL/__generated__/globalTypes';
import {
    UpdateOneNotification,
    UpdateOneNotificationVariables,
} from '../../../GraphQL/__generated__/UpdateOneNotification';
import { UPDATE_ONE_NOTIFICATION } from '../../../GraphQL/Notification/UPDATE_ONE_NOTIFICATION/UPDATE_ONE_NOTIFICATION';

const RED_NOTIFICATIONS = [
    'TASK_REQUEST_CANCELED',
    'TASK_REQUEST_DENIED',
    'TASK_REQUEST_REMOVED',
    'TASK_POST_DELETED',
    'PARTICIPATION_DENIED',
    'PARTICIPATION_CANCELED',
];

const YELLOW_NOTIFICATIONS = ['NUDGE', 'TASK_POST_UPDATED'];

const NotificationTile: React.FC<{
    notification: GetNotificationsByUser_notificationsByUserUid;
    organizations: GetNotificationsByUser_profile_organizations[];
}> = ({ notification, organizations }) => {
    const history = useHistory();
    const [deleteNotification, deleteNotificationDatas] = useMutation<
        DeleteOneNotification,
        DeleteOneNotificationVariables
    >(DELETE_ONE_NOTIFICATION);
    const [showActionCard, setShowActionCard] = useState<boolean>(false);
    const [changeNotification, changeNotificationData] = useMutation<
        UpdateOneNotification,
        UpdateOneNotificationVariables
    >(UPDATE_ONE_NOTIFICATION);
    const formattedMonth = moment(notification.createdAt).format('MMM');
    const formattedDay = moment(notification.createdAt).format('DD');

    const handleDeleteNotification = (notificationId: number) => {
        deleteNotification({
            variables: {
                id: notificationId,
            },
        }).catch((error) => console.error(error));
    };

    const isAdminNotification = (): boolean => {
        // Admin check
        if (notification.groupId && notification.groupId > 0) return true;
        return false;
    };

    const getAdminOrUserProilePicture = (): string => {
        // Is not an admin
        if (notification.groupId === null || notification.groupId === 0) {
            return notification.senderProfile?.profilePicture;
        }
        // Is an admin
        else if (notification.groupId > 0) {
            let filteredOrg = organizations.filter(
                (org) => org.id === notification.groupId
            );
            return typeof filteredOrg === undefined
                ? ''
                : filteredOrg[0].orgPicture; // return group's admin profile picture
        }
        // Should get here but if it does pass empty string
        return '';
    };

    // FUNCTION TO SHOW ADMIN NOTIFICATION MESSAGE
    const showMessageBody = (): string => {
        // Is not an admin
        if (!isAdminNotification()) return notification.body;
        // If Admin
        else {
            let orgObjectArray = organizations.filter(
                (org) => org.id === notification.groupId
            ); // Returns 1 item from arr
            let orgName = orgObjectArray[0].name;

            switch (notification.notificationType) {
                // PARTICIPATION REQUESTS
                case NotificationType.PARTICIPATION_REQUEST:
                    return `Participation requested by ${orgName} Admin`;

                case NotificationType.PARTICIPATION_ACCEPTED:
                    return `Participation accepted by ${orgName} Admin`;

                case NotificationType.PARTICIPATION_DENIED:
                    return `Participation declined by ${orgName} Admin`;

                case NotificationType.PARTICIPATION_CANCELED:
                    return `Participation canceled by ${orgName} Admin`;

                // TASK REQUESTS
                case NotificationType.TASK_REQUEST:
                    return `Task requested by ${orgName} Admin`;

                case NotificationType.TASK_REQUEST_ACCEPTED:
                    return `Task request accepted by ${orgName} Admin`;

                case NotificationType.TASK_REQUEST_DENIED:
                    return `Task request declined by ${orgName} Admin`;

                case NotificationType.TASK_REQUEST_REMOVED:
                    return `Task request was removed by ${orgName} Admin`;

                // TASK INVITES
                case NotificationType.INVITE:
                    return `An admin of ${orgName} has invited you for a task`;

                case NotificationType.NUDGE:
                    return `An admin of ${orgName} nuged you`;

                case NotificationType.TASK_POST_UPDATED:
                    return `An admin of ${orgName} has updated this task`;

                default:
                    return '';
            }
        }
    };

    let notificationStyles: string;

    if (RED_NOTIFICATIONS.includes(notification.notificationType)) {
        notificationStyles = 'notificationTile__content--badge--red';
    } else if (YELLOW_NOTIFICATIONS.includes(notification.notificationType)) {
        notificationStyles = 'notificationTile__content--badge--yellow';
    } else {
        notificationStyles = 'notificationTile__content--badge--purple';
    }

    let actionModal;
    if (notification.notificationType === 'TASK_POST_UPDATED') {
        actionModal = (
            <NotificationActionCard
                setShowActionCard={setShowActionCard}
                notification={notification}
                organizations={organizations}
            />
        );
    } else if (notification.notificationType === 'PARTICIPATION_ACCEPTED') {
        actionModal = (
            <NotificationActionCard
                setShowActionCard={setShowActionCard}
                notification={notification}
                organizations={organizations}
            />
        );
    }

    return (
        <>
            {showActionCard ? actionModal : null}

            <IonItemSliding className='notificationTile__slide--row'>
                <IonItemOptions
                    className='notificationTile__slide--options'
                    onIonSwipe={() => handleDeleteNotification(notification.id)}
                    side='end'
                >
                    <IonItemOption
                        className='notificationTile__slide--option'
                        onClick={() =>
                            handleDeleteNotification(notification.id)
                        }
                    >
                        <IonIcon icon={trashOutline} slot='icon-only' />
                    </IonItemOption>
                </IonItemOptions>
                <IonItem lines='none' className='notificationTile__slide--item'>
                    <IonCol
                        key={notification.id}
                        className={
                            notification.read
                                ? 'notificationTile__col-read'
                                : 'notificationTile__col-unread'
                        }
                        sizeXs='12'
                        sizeSm='12'
                    >
                        <IonAvatar
                            onClick={() => {
                                if (notification.senderProfile !== null) {
                                    // Redirect to group page if admin task
                                    if (
                                        notification.groupId &&
                                        notification.groupId > 0
                                    ) {
                                        history.push(
                                            `/group/${notification.groupId}`
                                        );
                                    } else {
                                        // Redirect to user's profile if not admin
                                        history.push(
                                            `/user/${notification.senderProfile.uid}`
                                        );
                                    }
                                }
                            }}
                        >
                            <img
                                alt='user'
                                className='notificationTile__avatar--img'
                                src={getAdminOrUserProilePicture()}
                            />
                        </IonAvatar>
                        <IonCol
                            onClick={() => {
                                changeNotification({
                                    variables: {
                                        id: notification.id,
                                        dismissed: notification.dismissed,
                                        seen: true,
                                        read: true,
                                    },
                                });
                                if (
                                    notification.path !== null &&
                                    notification.notificationType !==
                                        'TASK_POST_UPDATED' &&
                                    notification.path !== null &&
                                    notification.notificationType !==
                                        'PARTICIPATION_ACCEPTED'
                                ) {
                                    history.push(notification.path);
                                } else if (
                                    notification.notificationType ===
                                        'TASK_POST_UPDATED' ||
                                    notification.notificationType ===
                                        'PARTICIPATION_ACCEPTED'
                                ) {
                                    setShowActionCard(true);
                                    // This marks the notification as both read and seen
                                }
                            }}
                            sizeXs='10'
                            sizeSm='10'
                            className='notificationTile__content'
                        >
                            <div className={notificationStyles}>
                                <p className='notificationTile__content--badge--text'>
                                    {formattedMonth}
                                    <br />
                                    {formattedDay}
                                </p>
                            </div>
                            <p className='notificationTile__content--title'>
                                {notification.title}
                            </p>
                            <p className='notificationTile__content--skill'>
                                {notification.subTitle}
                            </p>
                            <p className='notificationTile__content--text'>
                                {showMessageBody()}
                            </p>
                            {/* {
                            notification.notificationType === "TASK_POST_UPDATED" || notification.notificationType === "PARTICIPATION_ACCEPTED" ?
                            null : 
                                isAdminNotification() ? 
                                    <p className='notificationTile__content--text'>
                                        {notification.additionalDetails}
                                    </p>
                                :
                                    <p className='notificationTile__content--text'>
                                        {notification.additionalDetails}
                                    </p>
                        }
                         */}
                        </IonCol>
                    </IonCol>
                </IonItem>
            </IonItemSliding>
        </>
    );
};

export default NotificationTile;
