import React, { useEffect } from 'react';
import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { useAuth } from 'context/firebase/authContext';
import './TransactionCard.css';
import moment from 'moment-timezone';
import { useLazyQuery } from '@apollo/react-hooks';
import { GetStripeTransfer } from '../../GraphQL/StripeConnect/StripeConnect';
import { formatAmount } from '../../utils/formatFiat';
import { useHistory } from 'react-router-dom';

interface TransactionProps {
    transaction: any;
    showCash: boolean;
    isGroup: boolean;
    // isSkillRequest
}

const TransactionCard: React.FC<TransactionProps> = ({
    transaction,
    showCash,
    isGroup,
}) => {
    const { currentUser } = useAuth();
    const history = useHistory();
    const date = new Date(Date.parse(transaction.lastUpdatedAt));
    const provider: boolean = transaction?.provider?.uid === currentUser.uid;
    const [getStripeTransfer, { data: stripeTransferData }] =
        useLazyQuery(GetStripeTransfer);
    useEffect(() => {
        if (showCash && transaction.stripeTransfer) {
            getStripeTransfer({
                variables: {
                    stripeTransferId: transaction.stripeTransfer,
                },
            });
        }
    }, [showCash]);
    // if in cash view, it ill not render transaction if there is no stripe transaction or if group  view
    if ((showCash && !transaction.stripeTransfer) || (showCash && isGroup))
        return null;
    const user = provider ? transaction?.recipient : transaction?.provider;
    return (
        <IonGrid>
            <IonRow className='container'>
                <IonCol
                    onClick={() => {
                        history.push('/user/' + user?.uid);
                    }}
                    size='2'
                    className='img-column'
                >
                    {/* <div className="img-bg" > */}
                    <img
                        loading='lazy'
                        className='user-img'
                        src={user?.profilePicture}
                    />
                    {/* </div> */}
                </IonCol>
                <IonCol>
                    <div className='detail-container'>
                        <h2 className='detail-name'>
                            {'W/ ' + user?.firstName + ' ' + user?.lastName}
                        </h2>
                        <p className='detail-time'>
                            {moment(transaction.lastUpdatedAt).format(
                                'dddd, MMMM Do YYYY'
                            )}
                        </p>
                        <p
                            className={
                                provider
                                    ? 'detail-amount provider-color'
                                    : 'detail-amount recipient-color'
                            }
                        >
                            <span>{provider ? '+' : '-'}</span>
                            {showCash && stripeTransferData
                                ? formatAmount(
                                      stripeTransferData?.stripeTransfer?.amount
                                  )
                                : transaction.CREDITS == null
                                ? (
                                      transaction.recipientOfferCredits / 60
                                  ).toFixed(2)
                                : (transaction.CREDITS / 60).toFixed(2)}
                        </p>
                    </div>
                </IonCol>
            </IonRow>
        </IonGrid>
    );
};
export default TransactionCard;
