const filterCurrentScheduleOut = (jobs, weeklyDates) => {
    const weekStart = new Date(weeklyDates[0].dateStr);
    const weekEnd = new Date(weeklyDates[4].dateStr);
    const filteredJobs = jobs.filter((job) => {
        const jobTime = new Date(job.endTime);
        // Job time not between schedule start and end date
        return !(
            jobTime.getTime() < weekEnd.getTime() &&
            jobTime.getTime() > weekStart.getTime()
        );
    });
    return filteredJobs;
};
export default filterCurrentScheduleOut;
