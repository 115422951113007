// importing modules
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAuth } from 'context/firebase/authContext';
// importing components
import {
    IonCol,
    IonContent,
    IonGrid,
    IonIcon,
    IonPage,
    IonRow,
    IonSearchbar,
    IonHeader,
    IonToolbar,
} from '@ionic/react';
// importing assets
import { closeOutline, searchOutline, arrowBackOutline } from 'ionicons/icons';
import MemberTile from '../../../components/Tile/MemberTile';
import NewMemberTile from '../../../pages/GeneralSearch/UI/NewMemberTile';
import Loading from '../../../components/status/Loading';
import NavBar from '../../../components/NavBar';
// importing graphql utilities - types
import { useQuery } from '@apollo/react-hooks';
import { SKILL_POOL_FROM_MANY_ORGS } from '../../../GraphQL/Skill/SKILL_POOL_FROM_MANY_ORGS/SKILL_POOL_FROM_MANY_ORGS';
import {
    SkillPoolFromManyOrgs,
    SkillPoolFromManyOrgsVariables,
} from '../../../GraphQL/__generated__/SkillPoolFromManyOrgs';
import { GET_SKILL_KEYWORDS } from '../../../GraphQL/Keywords/GET_SKILL_KEYWORDS';
import {
    GetSkillKeywords,
    GetSkillKeywords_keywords,
} from '../../../GraphQL/__generated__/GetSkillKeywords';
// importing utilities
import filterKeywordsArr from '../../../utils/filterKeywordsArr';
import filterAuthUserOut from '../../../utils/filterOutAuthUser';
// importing styles
import './MemberSkillSearch.css';

const GeneralSearch: React.FC = () => {
    const history = useHistory();
    const { skill } = useParams<{ skill: string }>();
    const { currentUser } = useAuth();
    const decodedSkillName = decodeURIComponent(skill);
    const [searchInput, setSearchInput] = useState<string>(decodedSkillName);

    const { data, loading } = useQuery<
        SkillPoolFromManyOrgs,
        SkillPoolFromManyOrgsVariables
    >(SKILL_POOL_FROM_MANY_ORGS, { variables: { uid: currentUser.uid } });
    const { data: keywordData, loading: keywordLoading } =
        useQuery<GetSkillKeywords>(GET_SKILL_KEYWORDS);

    if (loading) {
        return <Loading />;
    }

    let filteredMembersBySkill = [];
    let filteredKeywords: GetSkillKeywords_keywords[] = [];
    let keywords = [];
    if (searchInput.length > 0 && !loading) {
        filteredKeywords = keywordData.keywords.filter((kw) =>
            kw.keyword.toLowerCase().includes(searchInput.toLowerCase())
        );
        keywords = filterKeywordsArr(filteredKeywords);

        data.profilesFromManyOrgs.forEach((member) => {
            member.skills.forEach((skill) => {
                if (
                    skill.category
                        .toLowerCase()
                        .includes(searchInput.toLowerCase()) || // filter members by category
                    skill.name.toLowerCase().includes(searchInput.toLowerCase())
                ) {
                    // filter members by skill name
                    filteredMembersBySkill.push(member);
                }
            });
        });

        filteredMembersBySkill = filterAuthUserOut(
            filteredMembersBySkill,
            currentUser.uid
        );
    } else {
        filteredMembersBySkill = [];
        filteredKeywords = [];
    }

    return (
        <IonPage>
            <IonHeader className='uniMemberSkillSearch-header'>
                <IonToolbar className='uniMemberSkillSearch-toolbar'>
                    <IonSearchbar
                        className='uniMemberSkillSearch-ionSearchBar'
                        search-icon={searchOutline}
                        clear-icon={closeOutline}
                        placeholder='Search by name or skill'
                        value={searchInput}
                        onIonChange={(event) =>
                            setSearchInput(event.detail.value)
                        }
                    >
                        <IonIcon
                            onClick={() => history.goBack()}
                            className='uniMemberSkillSearch__close--btn'
                            icon={arrowBackOutline}
                        />
                    </IonSearchbar>
                    {/* <IonBackButton
                                className='uniMemberSkillSearch__close--btn'
                                defaultHref='/home'
                                text=''
                                icon={closeOutline}
                            /> */}
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonGrid className='uniSearch__container'>
                    {/* <IonRow className='uniMemberSkillSearch__searchbar'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='uniMemberSkillSearch__searchbar--container'
                        >
                            <div className='uniMemberSkillSearch__searchbar--input--wrap'>
                                <IonIcon
                                    className='uniMemberSkillSearch__searchbar--input--icon'
                                    icon={searchOutline}
                                />
                                <input
                                    onChange={(event) =>
                                        setSearchInput(event.target.value)
                                    }
                                    value={searchInput}
                                    type='text'
                                    placeholder='Search by name or skill'
                                    className='uniMemberSkillSearch__searchbar--input'
                                />
                            </div>
                            <IonBackButton
                                className='uniMemberSkillSearch__close--btn'
                                defaultHref='/home'
                                text=''
                                icon={closeOutline}
                            />
                        </IonCol>
                    </IonRow> */}
                    {/* skills list */}
                    {/* {keywords.length > 0 && (
                        <IonGrid>
                            <IonRow className='uniMemberSkillSearch__sectionTitle'>
                                <IonCol
                                    sizeSm='12'
                                    sizeXs='12'
                                    className='uniMemberSkillSearch__sectionTitle--wrap'
                                >
                                    <h3 className='uniMemberSkillSearch__sectionTitle--text'>
                                        Skill List
                                    </h3>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                {keywords.length > 0
                                    ? keywords.map((kw, index) => (
                                          <IonCol
                                              key={index}
                                              className='uniMemberSkillSearch__sectionTitle--wrap'
                                          >
                                              <h3 className='uniMemberSkillSearch__sectionTitle--text uniMemberSkillSearch__sectionTitle--skillName'>
                                                  {kw}
                                              </h3>
                                          </IonCol>
                                      ))
                                    : null}
                            </IonRow>
                        </IonGrid>
                    )} */}
                    <IonRow className='uniMemberSkillSearch__sectionTitle'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='uniMemberSkillSearch__sectionTitle--wrap'
                        >
                            <h3 className='uniMemberSkillSearch__sectionTitle--text'>
                                Members who <span>{searchInput}</span>
                            </h3>
                        </IonCol>
                    </IonRow>
                    <IonGrid className='uniMemberSkillSearch__newMembers--container'>
                        <IonRow className='uniMemberSkillSearch__newMembers--list'>
                            {!loading && filteredMembersBySkill.length > 0 ? (
                                filteredMembersBySkill.map((member, index) => (
                                    <NewMemberTile key={index} {...member} />
                                ))
                            ) : (
                                <IonRow className='nearbyMember__row'>
                                    <p>
                                        Doesn't look like a group member
                                        <br />
                                        has this skill.
                                        <br />
                                        <br />
                                        If you know a group member with this
                                        skill
                                        <br />
                                        encourage them to add it to their
                                        profile!
                                    </p>
                                </IonRow>
                            )}
                        </IonRow>
                    </IonGrid>
                    {/* members with similar skills */}
                    <IonRow className='uniMemberSkillSearch__nearbyMembers--container'>
                        <IonCol
                            className='uniMemberSkillSearch__nearbyMembers--text--wrap'
                            sizeSm='11'
                            sizeXs='11'
                        >
                            {/* <h3 className='uniMemberSkillSearch__nearbyMembers--title'>
                                Members with similar skills to{' '}
                                <span>{searchInput}</span>
                            </h3> */}
                        </IonCol>
                    </IonRow>
                    <IonRow className='nearbyMember__row'>
                        {
                            // filteredMembersBySkill.length > 0 ?
                            filteredMembersBySkill.map((member) => (
                                <MemberTile key={member.id} {...member} />
                            ))
                            // ) : (
                            //     <p>No members found.</p>
                        }
                    </IonRow>
                </IonGrid>
                <NavBar />
            </IonContent>
        </IonPage>
    );
};

export default GeneralSearch;
