import React from 'react';
import { useHistory } from 'react-router-dom';
import { IonButton, IonIcon } from '@ionic/react';
import { close } from 'ionicons/icons';
import styles from './XButton.module.css';

type Event = React.MouseEvent<HTMLIonButtonElement, MouseEvent>;

export default ({ action }: { action: (event: Event) => void }) => {
    return (
        <IonButton
            fill='clear'
            color='ion-primary'
            className={styles.btn}
            onClick={action}
        >
            <IonIcon className={styles.icon} icon={close} />
        </IonButton>
    );
};
