import MACRO from '../MACROS.json';

export class FirebaseHelper {
    uid: string;
    firstName: string;
    lastName: string;
    photoURL: string;
    email: string;
    displayName: string;
    organizations: string[];

    constructor(user: any) {
        if (user?.displayName) {
            const displayNameSplit =
                user.displayName.search(';') == -1
                    ? user.displayName.split(' ')
                    : user.displayName.split(';');

            this.firstName = displayNameSplit[0];
            this.lastName = displayNameSplit[1];
            if (displayNameSplit.length > 2) {
                this.organizations = displayNameSplit.slice(
                    2,
                    displayNameSplit.length
                );
                //     .map( orgCode => {
                //     let resultElt: OrganizationWhereUniqueInput = {code: orgCode}
                //     return resultElt
                // })
            }
        } else {
            this.firstName = '';
            this.lastName = '';
            this.organizations = [];
        }

        this.uid = user.uid;
        this.photoURL = user.photoURL;
        this.email = user.email;
        this.displayName = user.displayName;

        // else {
        //     console.error("There should be at least one ORG code encoded in the firebase user displayName!!")
        // }
    }
}
