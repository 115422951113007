// importing modules
import React, { useState } from 'react';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import { Calendar } from '@ionic-native/calendar';
import { Capacitor } from '@capacitor/core';
// importing components
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonLabel,
    IonRow,
    IonModal,
    IonIcon,
    IonItem,
    IonDatetime,
} from '@ionic/react';
import CreateTaskFooter from 'components/FooterWithChildren/FooterWithChildren';
import { arrowBackOutline } from 'ionicons/icons';
import Strokes from 'components/Strokes';
// importing assets
// importing utilities
import { getLocalStorage } from 'helpers/localStorage/localStorageHelper';
// importing graphql & types
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_ONE_JOB_POST } from 'GraphQL/JobPost/UPDATE_ONE_JOB_POST/UPDATE_ONE_JOB_POST';
import { PUBLISH_JOB_POST_UPDATED } from 'GraphQL/JobPost/PUBLISH_JOB_POST_UPDATED/PUBLISH_JOB_POST_UPDATED';
import {
    PublishJobPostUpdatedNotification,
    PublishJobPostUpdatedNotificationVariables,
} from 'GraphQL/__generated__/PublishJobPostUpdatedNotification';
import {
    UpdateOneJobPost,
    UpdateOneJobPostVariables,
} from 'GraphQL/__generated__/UpdateOneJobPost';
import { useHistory } from 'react-router-dom';

export default ({
    userImg,
    taskPostId,
    title,
    description,
    taskLocation,
    taskStartDate,
    taskStartTime,
    taskEndTime,
    isEditDescrModalActive,
    setIsEditDescrModalActive,
}: {
    userImg: string;
    taskPostId: number;
    title: string;
    description: string;
    taskLocation: string;
    isEditDescrModalActive: boolean;
    taskStartDate: any;
    taskStartTime: any;
    taskEndTime: any;
    setIsEditDescrModalActive: Function;
}) => {
    const history = useHistory();
    const [taskName, setTaskName] = useState<string>(title);
    const [taskDescr, setTaskDescr] = useState<string>(description);
    const [selectedDate, setSelectedDate] = useState<string>(taskStartDate);
    const [selectedStartTime, setSelectedStartTime] =
        useState<string>(taskStartTime);
    const [selectedEndTime, setSelectedEndTime] = useState<string>(taskEndTime);
    const [changeDate, setChangeDate] = useState<boolean>(false);

    const minDate = moment().format('YYYY-MM-DD');
    const maxDate = moment().add(1, 'years').format('YYYY-MM-DD');

    const [updateJobPost, updateJobPostDatas] = useMutation<
        UpdateOneJobPost,
        UpdateOneJobPostVariables
    >(UPDATE_ONE_JOB_POST);

    const [publishUpdateNotification, publishUpdateNotificationDatas] =
        useMutation<
            PublishJobPostUpdatedNotification,
            PublishJobPostUpdatedNotificationVariables
        >(PUBLISH_JOB_POST_UPDATED);
    function isDST(d) {
        let jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
        let jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
        return Math.max(jan, jul) != d.getTimezoneOffset();
    }
    const updateDate = (e) => {
        setSelectedDate(e.detail.value);
        setChangeDate(true);
    };
    const handleUpdateTaskPost = () => {
        //If the selectedTime and currentTime are both not in daylight times are both in daylight savings time, no change in time.
        //However if selectedTime or currentTime is in daylight savings time, shift back by 1 hour.
        let sTime =
            selectedStartTime !== null
                ? isDST(new Date(selectedStartTime)) !== isDST(new Date())
                    ? momentTZ(selectedStartTime)
                          .add(-1, 'hours')
                          .tz(getLocalStorage('tz'))
                          .format('HH:mm')
                    : momentTZ(selectedStartTime)
                          .tz(getLocalStorage('tz'))
                          .format('HH:mm')
                : null; // startime
        let eTime =
            selectedEndTime !== null
                ? isDST(new Date(selectedEndTime)) !== isDST(new Date())
                    ? momentTZ(selectedEndTime)
                          .add(-1, 'hours')
                          .tz(getLocalStorage('tz'))
                          .format('HH:mm')
                    : momentTZ(selectedEndTime)
                          .tz(getLocalStorage('tz'))
                          .format('HH:mm')
                : null; // endtime
        // November 7 ends Daylight savings
        // March 13 Starts Daylight Savings
        if (changeDate) {
            if (
                (isDST(new Date(taskStartDate)) == false &&
                    isDST(new Date(selectedDate)) == true) ||
                (isDST(new Date(taskStartDate)) == true &&
                    isDST(new Date(selectedDate)) == true)
            ) {
                // When date is changed into daylight savings time
                // or Date change date in daylight savings time
                // Set the times back another hour.
                sTime =
                    selectedStartTime !== null
                        ? momentTZ(selectedStartTime)
                              .tz(getLocalStorage('tz'))
                              .add(-1, 'hours')
                              .format('HH:mm')
                        : null; // startime
                console.log(sTime);
                eTime =
                    selectedEndTime !== null
                        ? momentTZ(selectedEndTime)
                              .tz(getLocalStorage('tz'))
                              .add(-1, 'hours')
                              .format('HH:mm')
                        : null; // endtime
            } else if (
                isDST(new Date(taskStartDate)) == true &&
                isDST(new Date(selectedDate)) == false
            ) {
                // When date is changed out of daylight savings time no change in time.
                sTime =
                    selectedStartTime !== null
                        ? momentTZ(selectedStartTime)
                              .tz(getLocalStorage('tz'))
                              .format('HH:mm')
                        : null; // startime
                console.log(sTime);
                eTime =
                    selectedEndTime !== null
                        ? momentTZ(selectedEndTime)
                              .tz(getLocalStorage('tz'))
                              .format('HH:mm')
                        : null; // endtime
            }
        }
        const taskDateTimeObj = {
            date:
                selectedDate === null
                    ? null
                    : moment(selectedDate).format('YYYY-MM-DD'), // date
            startTime: sTime,
            endTime: eTime,
        };

        // postee timezone
        const recipientTZ = momentTZ.tz(getLocalStorage('tz')).format('Z');

        let startDate;
        let startDateTime;
        let endDateTime;
        if (
            taskDateTimeObj.date !== null &&
            taskDateTimeObj.startTime !== null
        ) {
            startDate =
                taskDateTimeObj.date +
                'T' +
                taskDateTimeObj.startTime +
                `:00${recipientTZ}`;
            startDateTime =
                taskDateTimeObj.date +
                'T' +
                taskDateTimeObj.startTime +
                `:00${recipientTZ}`;
            endDateTime =
                taskDateTimeObj.date +
                'T' +
                taskDateTimeObj.endTime +
                `:00${recipientTZ}`;
        } else if (
            taskDateTimeObj.date !== null &&
            taskDateTimeObj.startTime === null
        ) {
            startDate = selectedDate;
            startDateTime = null;
            endDateTime = null;
        } else if (
            taskDateTimeObj.date === null &&
            taskDateTimeObj.startTime !== null
        ) {
            startDate = null;
            startDateTime =
                moment(new Date()).format('YYYY-MM-DD') +
                'T' +
                taskDateTimeObj.startTime +
                `:00${recipientTZ}`;
            endDateTime =
                moment(new Date()).format('YYYY-MM-DD') +
                'T' +
                taskDateTimeObj.endTime +
                `:00${recipientTZ}`;
        } else if (
            taskDateTimeObj.date === null &&
            taskDateTimeObj.startTime === null
        ) {
            startDate = null;
            startDateTime = null;
            endDateTime = null;
        }

        updateJobPost({
            variables: {
                jobId: taskPostId,
                title: taskName,
                description: taskDescr,
                startDate: startDate,
                startTime: startDateTime,
                endTime: endDateTime,
            },
        })
            .then((res) => {
                // if native device run the code inside this block
                const prevStartTime = momentTZ(taskStartTime);
                const prevStartDate = prevStartTime.tz(
                    getLocalStorage('tz'),
                    false
                );
                const prevEndTime = momentTZ(taskEndTime);
                const prevEndDate = prevEndTime.tz(
                    getLocalStorage('tz'),
                    false
                );

                const tStartTime = momentTZ(startDateTime);
                const tStartDate = tStartTime.tz(getLocalStorage('tz'), false);
                const tEndTime = momentTZ(endDateTime);
                const tEndDate = tEndTime.tz(getLocalStorage('tz'), false);

                const prevJobData = {
                    title: `YING - ${title}`,
                    location: taskLocation,
                    notes: 'No notes available',
                    startTime: taskStartTime,
                    endTime: taskEndTime,
                    options: {
                        id: taskPostId.toString(),
                        firstReminderMinutes: 5,
                        secondReminderMinutes: 120,
                    },
                };

                publishUpdateNotification({
                    variables: {
                        jobId: taskPostId,
                        prevJobInfoData: JSON.stringify(prevJobData),
                    },
                }).catch((err) => console.error(err));

                // creates an event on the devices native calendar (if calendar permission was granted)
                if (
                    Capacitor.platform !== 'web' &&
                    startDate !== null &&
                    startDateTime !== null &&
                    endDateTime !== null
                ) {
                    // removes a scheduled event from the devices native calendar
                    Calendar.deleteEvent(
                        `YING - ${title}`,
                        taskLocation,
                        'No notes available',
                        prevStartDate.toDate(),
                        prevEndDate.toDate()
                    ).catch((err) =>
                        console.error('error creating calendar event', err)
                    );

                    // creates a new updated calendar event
                    Calendar.createEventWithOptions(
                        `YING - ${taskName}`,
                        taskLocation,
                        'No notes available',
                        tStartDate.toDate(),
                        tEndDate.toDate(),
                        {
                            id: taskPostId.toString(),
                            firstReminderMinutes: 5,
                            secondReminderMinutes: 120,
                        }
                    ).catch((err) =>
                        console.error('error creating calendar event', err)
                    );
                }
                setIsEditDescrModalActive(false);
            })
            .catch((err) => console.error(err));

        // history.go(0);
    };

    const handleDeleteButton = () => {
        setIsEditDescrModalActive(false);
        history.push(`/delete/jobPost/${taskPostId}`);
    };

    return (
        <IonModal backdropDismiss={false} isOpen={isEditDescrModalActive}>
            <IonContent color='ion-secondary' className='describeTask__page'>
                <IonGrid className='describeTask__page'>
                    <IonRow className='createTask__header'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='createTask__header--container'
                        >
                            <Strokes top={true} />
                            <IonCol
                                sizeXs='12'
                                sizeSm='12'
                                sizeMd='12'
                                className='completeTaskNav__btns--wrap'
                            >
                                <IonButton
                                    className='completeTaskNav__btn'
                                    onClick={() =>
                                        setIsEditDescrModalActive(false)
                                    }
                                    color='ion-primary'
                                    fill='clear'
                                >
                                    <IonIcon
                                        className='completeTaskNav__btn--icon'
                                        icon={arrowBackOutline}
                                    />
                                </IonButton>
                            </IonCol>
                            <IonImg
                                className='createTask__header--img'
                                src={userImg}
                                alt='user'
                            />
                        </IonCol>
                    </IonRow>
                    {/* text content */}
                    <IonRow className='createTask__textContent'>
                        <IonCol
                            className='createTask__textContent--container'
                            sizeSm='12'
                            sizeXs='12'
                        >
                            <h1 className='createTask__textContent--title'>
                                <span>Update</span> your task
                            </h1>
                            <p className='createTask__textContent--text'>
                                Once updated, participants will be notified of
                                your changes.
                            </p>
                        </IonCol>
                    </IonRow>
                    {/* task def form */}
                    <IonGrid className='createTask__form--container'>
                        <IonRow className='createTask__form--row'>
                            <IonCol
                                className='createTask__form--input--wrap'
                                sizeXs='12'
                                sizeSm='12'
                            >
                                <IonLabel className='createTask__form--label'>
                                    Task Name
                                </IonLabel>
                                <input
                                    onChange={(event) =>
                                        setTaskName(event.target.value)
                                    }
                                    value={taskName}
                                    className='createTask__form--input'
                                    placeholder='Ex: Need help moving...'
                                    type='text'
                                    required
                                />
                            </IonCol>
                            <IonCol
                                className='createTask__form--input--wrap'
                                sizeXs='12'
                                sizeSm='12'
                            >
                                <IonLabel className='createTask__form--label'>
                                    Task Description
                                </IonLabel>
                                <textarea
                                    onChange={(event) =>
                                        setTaskDescr(event.target.value)
                                    }
                                    value={taskDescr}
                                    className='createTask__form--textarea'
                                    placeholder='Ex: would love some help, moving saturday...'
                                    required
                                ></textarea>
                            </IonCol>
                            <IonCol
                                sizeSm='12'
                                sizeXs='12'
                                className='createTask__dateTime--block'
                            >
                                <IonItem
                                    lines='none'
                                    className='createTask__dateTime--item--date'
                                >
                                    <IonLabel>Date</IonLabel>
                                    <IonDatetime
                                        dayNames={[
                                            'Sun',
                                            'Mon',
                                            'Tue',
                                            'Wed',
                                            'Thu',
                                            'Fri',
                                            'Sat',
                                        ]}
                                        dayShortNames={[
                                            'Sun',
                                            'Mon',
                                            'Tue',
                                            'Wed',
                                            'Thu',
                                            'Fri',
                                            'Sat',
                                        ]}
                                        min={minDate}
                                        max={maxDate}
                                        displayFormat='DDD. MMM DD, YY'
                                        value={selectedDate}
                                        placeholder='-'
                                        onIonChange={(e) => updateDate(e)}
                                    ></IonDatetime>
                                </IonItem>
                                <IonItem
                                    lines='none'
                                    className='createTask__dateTime--item--starttime'
                                >
                                    <IonLabel>Start Time</IonLabel>
                                    <IonDatetime
                                        displayTimezone={getLocalStorage('tz')}
                                        displayFormat='h:mm A'
                                        minuteValues='0,15,30,45'
                                        placeholder={'-'}
                                        value={selectedStartTime}
                                        onIonChange={(e) => {
                                            setSelectedStartTime(
                                                e.detail.value
                                            );
                                        }}
                                    ></IonDatetime>
                                </IonItem>
                                <IonItem
                                    lines='none'
                                    className='createTask__dateTime--item--endtime'
                                >
                                    <IonLabel>End Time</IonLabel>
                                    <IonDatetime
                                        displayTimezone={getLocalStorage('tz')}
                                        displayFormat='h:mm A'
                                        minuteValues='0,15,30,45'
                                        placeholder={'-'}
                                        value={selectedEndTime}
                                        disabled={selectedStartTime === null}
                                        onIonChange={(e) => {
                                            setSelectedEndTime(e.detail.value);
                                        }}
                                    ></IonDatetime>
                                </IonItem>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonGrid>
            </IonContent>
            <CreateTaskFooter>
                <IonButton
                    fill='clear'
                    color='ion-primary'
                    className='taskItem__btn--cancel taskItem__btn--cancel--recipient'
                    onClick={() => handleDeleteButton()}
                >
                    Delete Task
                </IonButton>
                <IonButton
                    fill='clear'
                    disabled={!(taskName.length > 0 && taskDescr.length > 0)}
                    color='ion-primary'
                    onClick={handleUpdateTaskPost}
                    className='footerWithChild__btn'
                >
                    Update Task
                </IonButton>
            </CreateTaskFooter>
        </IonModal>
    );
};
