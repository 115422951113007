import moment from 'moment';

// constructs an array of 5 days
// uses the moment.js package
export default function getDates(startDate, stopDate) {
    var dateArray = [];
    var currentDate = moment(startDate);
    while (currentDate <= moment(stopDate)) {
        const d = {
            dateStr: moment(currentDate).format('YYYY-MM-DD'),
            dateDay: moment(currentDate).format('D'),
            dateMonth: moment(currentDate).format('MMM'),
            dateWeekDay: moment(currentDate).format('ddd'),
        };
        dateArray.push(d);
        currentDate = moment(currentDate).add(1, 'days');
    }
    return dateArray;
}
