// importing modules
import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useAuth } from 'context/firebase/authContext';
// importing components
import {
    IonButton,
    IonIcon,
    IonCol,
    IonContent,
    IonGrid,
    IonPage,
    IonRow,
} from '@ionic/react';
import UserMenu from '../../components/UserMenu/UserMenu';
import UserMenuBackdrop from '../../components/UserMenu/UserMenuBackdrop';
import ProfileHeader from '../../components/ProfileHeader/ProfileHeader';
import SkillReviews from '../../components/SkillReviews/SkillReviewsPortfolioPreview';
import ReviewsFallback from '../../components/SkillReviewItemFallback/ReviewsFallback';
import ProfileDetails from '../../components/ProfileDetails/PortfolioDetails';
import Loading from '../../components/status/Loading';
import { menuOutline, searchOutline } from 'ionicons/icons';
// importing graphql utilities - types
import { useQuery } from '@apollo/react-hooks';
import {
    UserProfile_byID,
    UserProfile_byIDVariables,
    UserProfile_byID_profile_skills_reviews,
    UserProfile_byID_profile_skills,
} from '../../GraphQL/__generated__/UserProfile_byID';
import { USER_PROFILE_BY_UID } from '../../GraphQL/Profile/UserProfile/USER_PROFILE_BY_UID';
// importing utilities
import { FirebaseHelper } from '../../helpers/FirebaseHelper';
// importing styles
import './UserPortfolio.css';
import '../../components/SkillReviewItem/SkillReviewItem.css';
import NavBar from '../../components/NavBar';

const PortfolioPreview: React.FC = () => {
    const { uid } = useParams<{ uid: string }>();
    const history = useHistory();
    const { currentUser } = useAuth();
    const fuser = new FirebaseHelper(currentUser);
    const [selectedSkill, setSelectedSkill] = useState<string>('');
    const [selectedSkillId, setSelectedSkillId] = useState<number>(0);
    const [skillReviews, setSkillReviews] = useState<
        UserProfile_byID_profile_skills_reviews[]
    >([]);
    const [toggleMenu, setToggleMenu] = useState<boolean>(false); // handles hamburger menu

    const handleMenuToggle = () => setToggleMenu((prevToggle) => !prevToggle);

    const { data, loading } = useQuery<
        UserProfile_byID, // query return return type (shape of data)
        UserProfile_byIDVariables // query request type       (shape of variables)
    >(USER_PROFILE_BY_UID, {
        variables: {
            subId: uid,
        },
    });

    if (loading) {
        return <Loading />;
    }

    const activeSkills: UserProfile_byID_profile_skills[] =
        data.profile.skills.filter((skill) => skill.status === 'ACTIVE');

    const handleSelectedSkill = (skillName: string, skillId: number) => {
        setSelectedSkill(skillName);
        setSelectedSkillId(skillId);
        const filteredSkill = activeSkills.find(
            (item) => item.name === skillName
        );
        setSkillReviews([...filteredSkill.reviews]);
    };

    return (
        <IonPage>
            <IonContent>
                <IonGrid className='userProfile__wrap'>
                    {toggleMenu ? (
                        <IonCol className='menu__sidebar--active'>
                            <UserMenu
                                handleMenuToggle={handleMenuToggle}
                                userUid={fuser.uid}
                            />
                        </IonCol>
                    ) : (
                        <IonCol className='menu__sidebar--inactive' />
                    )}
                    {toggleMenu ? (
                        <UserMenuBackdrop handleMenuToggle={handleMenuToggle} />
                    ) : null}
                    <IonRow className='profileStates__tabs--container'>
                        <IonCol
                            sizeXs='12'
                            sizeSm='12'
                            sizeMd='12'
                            className='profileStates__tabs--wrap'
                        >
                            <IonButton
                                fill='clear'
                                onClick={handleMenuToggle}
                                color='ion-primary'
                                className='profileStates__tabs--btn'
                            >
                                <IonIcon
                                    className='profileStates__tabs--btn--icon'
                                    icon={menuOutline}
                                />
                            </IonButton>
                        </IonCol>
                    </IonRow>
                    <ProfileHeader
                        firstName={!loading && data.profile.firstName}
                        lastName={!loading && data.profile.lastName}
                        profileImg={!loading && data.profile.profilePicture}
                    />
                    <ProfileDetails
                        uid={uid}
                        userLocation={'Private or unpublished.'}
                        userBio={!loading && data.profile.bio}
                    />
                    <IonRow className='skills'>
                        <IonRow className='skills__row'>
                            <IonCol
                                className='skills__col--title'
                                sizeXs='12'
                                sizeSm='12'
                            >
                                <h2 className='skills__title'>
                                    What I can offer
                                </h2>
                            </IonCol>
                        </IonRow>
                        <IonGrid className='groupSkills__container'>
                            <IonRow className='groupSkills__row'>
                                {!loading && activeSkills.length > 0 ? (
                                    activeSkills.map((skill) => {
                                        const completedJobs =
                                            data.profile.upcomingJobsServe.filter(
                                                (job) =>
                                                    job.Skill.name ===
                                                        skill.name &&
                                                    job.Status.toString() ===
                                                        'COMPLETED'
                                            );
                                        return (
                                            <IonCol
                                                key={skill.id}
                                                className='groupSkills__col'
                                                sizeXs='12'
                                                sizeSm='12'
                                            >
                                                <IonCol
                                                    sizeXs='12'
                                                    sizeSm='12'
                                                    className='ion-padding groupSkills__content'
                                                >
                                                    <p className='skillsOffered__content--keyword'>
                                                        {skill.category}
                                                    </p>
                                                    <p className='groupSkills__content--skill'>
                                                        {skill.name}
                                                    </p>
                                                    {/* update tasks with real count */}
                                                    <p className='groupSkills__content--completed'>
                                                        {completedJobs.length}{' '}
                                                        Task
                                                        {completedJobs.length ===
                                                        1
                                                            ? ''
                                                            : 's'}{' '}
                                                        Completed
                                                    </p>
                                                    <p className='groupSkills__content--text'>
                                                        {skill.description}
                                                    </p>
                                                </IonCol>
                                            </IonCol>
                                        );
                                    })
                                ) : (
                                    <IonCol
                                        className='tasksFallback'
                                        style={{ backgroundColor: '#fff' }}
                                    >
                                        <h3 className='tasksFallback__title'>
                                            You currently haven't
                                            <br /> added any skills.
                                        </h3>
                                    </IonCol>
                                )}
                            </IonRow>
                        </IonGrid>
                    </IonRow>
                    {/* skill reviews */}
                    {activeSkills.length > 0 ? (
                        <SkillReviews
                            userSkills={!loading && activeSkills}
                            handleSelectedSkill={handleSelectedSkill}
                            selectedSkill={selectedSkill}
                            selectedSkillId={selectedSkillId}
                            userName={!loading && data.profile.firstName}
                            skillReviews={skillReviews}
                        />
                    ) : (
                        <ReviewsFallback />
                    )}
                </IonGrid>
                <NavBar />
            </IonContent>
        </IonPage>
    );
};

export default PortfolioPreview;
