import React from 'react';
// importing components
import Loading from '../../components/status/Loading';
import { IonImg } from '@ionic/react';
// importing graphql utilities - types
import { useQuery } from '@apollo/react-hooks';
import {
    UserProfile_byID,
    UserProfile_byIDVariables,
} from '../../GraphQL/__generated__/UserProfile_byID';
import { USER_PROFILE_BY_UID } from '../../GraphQL/Profile/UserProfile/USER_PROFILE_BY_UID';
import { FirebaseHelper } from '../../helpers/FirebaseHelper';
// firebase
import { useAuth } from '../../context/firebase/authContext';

const UserImg: React.FC = () => {
    const { currentUser } = useAuth();
    const fuser = new FirebaseHelper(currentUser);

    const { data, loading } = useQuery<
        UserProfile_byID,
        UserProfile_byIDVariables
    >(USER_PROFILE_BY_UID, {
        variables: {
            subId: fuser.uid,
        },
    });

    if (loading) {
        return <Loading />;
    }

    return (
        <IonImg
            className='createTask__header--img'
            src={!loading && data.profile.profilePicture}
            alt='user'
        />
    );
};

export default UserImg;
