import React from 'react';
import { useHistory } from 'react-router-dom';
import { IonCol, IonImg } from '@ionic/react';
import './GroupTaskItem.css';

const GroupTaskItem: React.FC<{
    id: number;
    title: string;
    description: string;
    recipientUid: string;
    firstName: string;
    lastName: string;
    groupName: string;
    profilePicture: string;
    adminId: number;
    adminPicture: string;
}> = ({
    id,
    title,
    description,
    recipientUid,
    firstName,
    lastName,
    groupName,
    profilePicture,
    adminId,
    adminPicture,
}) => {
    const history = useHistory();

    return (
        <IonCol
            onClick={() => history.push(`/task/${id}`)}
            className='groupTaskItem'
            sizeSm='11'
            sizeXs='11'
        >
            <IonCol sizeXs='12' sizeSm='12' className='groupTaskItem__info'>
                <IonCol sizeXl='3' sizeLg='3' sizeMd='3' sizeXs='3' sizeSm='3'>
                    <div className='groupTaskItem__img--wrap'>
                        <IonImg
                            // onClick={() =>
                            //     history.push(`/user/${recipientUid}`)
                            // }
                            className='groupTaskItem__img'
                            src={adminId ? adminPicture : profilePicture}
                        />
                    </div>
                </IonCol>
                <IonCol
                    className='groupTaskItem__info--wrap'
                    sizeXs='9'
                    sizeSm='9'
                >
                    <h3 className='groupTaskItem__info--title'>{title}</h3>
                    <div className='groupTaskItem__info--user'>
                        {adminId
                            ? `Posted by Admin of ${groupName}`
                            : `Posted by ${firstName} ${lastName}`}
                    </div>
                </IonCol>
            </IonCol>
            <IonCol
                sizeXs='12'
                sizeSm='12'
                className='groupTaskItem__description--wrap'
            >
                <p className='groupTaskItem__description'>{description}</p>
            </IonCol>
        </IonCol>
    );
};

export default GroupTaskItem;
