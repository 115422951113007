// importing modules
import React from 'react';
// importing components
import { IonButton, IonCol, IonImg } from '@ionic/react';

const TaskParticipantItem: React.FC<{
    id: number;
    firstName: string;
    lastName: string;
    credits: number;
    profileImg: string;
    handleCreditsModal: () => void;
    handleParticipantModal: () => void;
    handleSelectParticipant: Function;
}> = ({
    id,
    profileImg,
    firstName,
    lastName,
    credits,
    handleCreditsModal,
    handleParticipantModal,
    handleSelectParticipant,
}) => {
    return (
        <IonCol className='taskPartcItem' sizeSm='12' sizeXs='12'>
            <IonCol sizeXs='12' sizeSm='12' className='taskPartcItem__content'>
                <IonCol sizeXs='12' sizeSm='12' className='taskPartcItem__info'>
                    <IonCol
                        sizeXl='3'
                        sizeLg='3'
                        sizeMd='3'
                        sizeXs='3'
                        sizeSm='3'
                    >
                        <div className='taskPartcItem__img--wrap'>
                            <IonImg
                                className='taskPartcItem__img'
                                src={profileImg}
                            />
                        </div>
                    </IonCol>
                    <IonCol
                        className='taskPartcItem__info--wrap'
                        sizeXs='9'
                        sizeSm='9'
                    >
                        <h3 className='taskPartcItem__info--name'>
                            {firstName} {lastName}
                        </h3>
                        <p className='taskPartcItem__info--cred'>
                            Will get {credits} Balance Tokens
                        </p>
                    </IonCol>
                </IonCol>
                <IonCol
                    sizeXs='12'
                    sizeSm='12'
                    className='taskPartcItem__btn--wrap'
                >
                    <IonButton
                        fill='clear'
                        color='ion-primary'
                        className='taskPartcItem__btn--cred'
                        onClick={() => {
                            handleSelectParticipant(id);
                            handleCreditsModal();
                        }}
                    >
                        Change Credits
                    </IonButton>
                    <IonButton
                        fill='clear'
                        color='ion-primary'
                        className='taskPartcItem__btn--remove'
                        onClick={() => {
                            handleSelectParticipant(id);
                            handleParticipantModal();
                        }}
                    >
                        Remove
                    </IonButton>
                </IonCol>
            </IonCol>
        </IonCol>
    );
};

export default TaskParticipantItem;
