// importing modules
import React, { useState, useEffect } from 'react';
import { useAuth } from 'context/firebase/authContext';
import { useHistory } from 'react-router-dom';
// importing components
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonIcon,
    IonRow,
    IonPage,
} from '@ionic/react';
import UserImg from '../../RequestSkill/UserImg/UserImg';
import Strokes from 'components/Strokes';
import Loading from '../../../components/status/LoadingScreen';
import BackButton from 'components/BackButton';
import CancelButton from 'components/CancelButton/CancelButton';
// importing utilities
// importing assets
import { createOutline } from 'ionicons/icons';
// importing graphql & types
import StripeOnboarding from '../../StripeOnboarding/StripeOnboarding';
// importing styles
import '../Settings.css';
import './PayoutMethods.css';
import { GetStripeAccount } from './StripeHelpers';
import { useLazyQuery } from '@apollo/react-hooks';
import { GetLoginURL as GLUQuery } from '../../../GraphQL/StripeConnect/StripeConnect';
import { Plugins } from '@capacitor/core';

const { Browser } = Plugins;
const PayoutMethods: React.FC = () => {
    const { currentUser } = useAuth();
    const history = useHistory();

    let stripeAccount = GetStripeAccount();
    const [isOnboarding, setisOnboarding] = useState<boolean>(true);
    const [showModal, setshowModal] = useState<boolean>(false);
    const [getLoginUrl, { data: loginData, loading }] = useLazyQuery(GLUQuery, {
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            Browser.open({ url: loginData.getLoginURL.loginURL });
        },
    });

    useEffect(() => {
        if (stripeAccount?.account_status) {
            setisOnboarding(stripeAccount?.account_status == 'onboarding');
        }
    }, [stripeAccount]);

    if (!stripeAccount) {
        return <Loading />;
    }

    const handleClick = () => {
        if (isOnboarding) {
            setshowModal(true);
        }
    };

    const RedirectToStripe = () => {
        getLoginUrl({
            variables: {
                uid: currentUser.uid,
            },
        });
    };
    return (
        <IonPage>
            <IonContent>
                <IonGrid>
                    <StripeOnboarding
                        setModal={setshowModal}
                        showModal={showModal}
                    />
                    <IonRow className='settings__header'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='settings__header--container'
                        >
                            <Strokes top={true} />
                            <IonCol
                                sizeXs='12'
                                sizeSm='12'
                                sizeMd='12'
                                className='reqTaskNav__btns--wrap'
                            >
                                <BackButton color='white' />
                                <CancelButton />
                            </IonCol>
                            <UserImg providerUid={currentUser.uid} />
                        </IonCol>
                    </IonRow>
                    {/* title */}
                    <IonRow className='settings__textContent'>
                        <IonCol
                            className='settings__textContent--container'
                            sizeSm='12'
                            sizeXs='12'
                        >
                            <h1 className='settings__textContent--title'>
                                <span> Payout </span> Methods
                            </h1>
                        </IonCol>
                    </IonRow>
                    {/* Shows payout methods if at least one account exists and is not onboarding (redundant) */}

                    {stripeAccount?.external_accounts?.length > 0 &&
                        !isOnboarding && (
                            <IonRow className='settings__btn--container'>
                                <IonGrid className='settings__form--container'>
                                    <IonRow className='settings__form--row'>
                                        <IonRow className='settings__col--wrap'>
                                            {stripeAccount?.external_accounts?.map(
                                                (account) => (
                                                    <IonCol
                                                        key={account.id}
                                                        className='settings__col paymentInfo__content'
                                                        onClick={() =>
                                                            RedirectToStripe()
                                                        }
                                                    >
                                                        <div>
                                                            <p className='settings__content--text'>
                                                                {account.bank_name ||
                                                                    account.brand}
                                                            </p>
                                                            <p className='settings__content--description'>
                                                                {account.object ==
                                                                'bank_account'
                                                                    ? 'Bank'
                                                                    : 'Card'}
                                                                {' ••••  ' +
                                                                    account.last4}
                                                            </p>
                                                        </div>
                                                        <div className='edit_icon'>
                                                            <IonIcon
                                                                className='edit_icon'
                                                                icon={
                                                                    createOutline
                                                                }
                                                            />
                                                        </div>
                                                    </IonCol>
                                                )
                                            )}
                                        </IonRow>
                                    </IonRow>
                                </IonGrid>
                            </IonRow>
                        )}

                    {/* if user doesn't have at least one payout method (external account), give them the option to add one, or finish the process*/}
                    {isOnboarding && (
                        <IonRow className='settings__btn--container'>
                            <IonRow className='payment_body_container'>
                                <h5 className='no_payment_methods_heading'>
                                    Your payout methods will appear here.
                                    <br /> <br /> Add a payout method to receive
                                    tips via the Gratitude Pot.
                                </h5>
                            </IonRow>
                            <IonCol
                                onClick={handleClick}
                                className='settings__btn--wrap'
                                sizeXs='12'
                                sizeSm='12'
                            >
                                <IonButton
                                    fill='clear'
                                    color='ion-primary'
                                    className='settings__btn'
                                >
                                    Add Payout Method
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    )}
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default PayoutMethods;
