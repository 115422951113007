import React, { useRef } from 'react';
import { IonButton, IonCol, IonIcon, IonRow } from '@ionic/react';
import { chatbubbleEllipses } from 'ionicons/icons';
import Linkify from 'react-linkify';
import './ProfileDetails.css';

interface ProfileDetails {
    currentUserUid: string;

    otherUserUid: string;
    otherUserBio: string;
}

const ProfileDetails: React.FC<ProfileDetails> = ({
    currentUserUid,
    otherUserBio,
    otherUserUid,
}) => {
    const chatLink = useRef(null);
    const handleChatroom = () => chatLink.current?.click();
    return (
        <IonRow className='profileDetails'>
            <IonCol
                sizeXs='12'
                sizeSm='12'
                className='profileDetails__col--btn'
            >
                <IonButton
                    onClick={handleChatroom}
                    fill='clear'
                    expand='block'
                    className='profileBio__btn'
                    color='ion-primary'
                >
                    <a
                        ref={chatLink}
                        href={`/chatroom/${currentUserUid}/${otherUserUid}`}
                        type='hidden'
                    ></a>
                    <IonIcon icon={chatbubbleEllipses} slot='start' />
                    Message Me
                </IonButton>
            </IonCol>
            <IonRow className='profileDetails__row--bio'>
                <IonCol
                    sizeXs='11'
                    sizeSm='11'
                    className='profileDetails__col--bio'
                >
                    {/* <p className="profileDetails__location">
                        <span><IonIcon icon={locationOutline}/></span>
                        &nbsp;{otherUserLocation}
                    </p> */}
                    <p className='profileDetails__bio'>
                        <Linkify>{otherUserBio}</Linkify>
                    </p>
                </IonCol>
            </IonRow>
        </IonRow>
    );
};

export default ProfileDetails;
