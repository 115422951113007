// importing modules
import React, { useState } from 'react';
import { IonApp, IonHeader } from '@ionic/react';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
/* Theme variables */
import './theme/variables.css';
/* Global CSS */
import './global.css';
import Layout from './components/Layout/Layout';
// importing components
import Loading from './components/status/LoadingScreen';
// importing utilities
import { GraphQLProvider } from './GraphQLProvider';
// styling
import './App.css';
// routes
import { AuthRoutes, UnauthRoutes } from './routes';
// contexts
import { AuthProvider, useAuth } from './context/firebase/authContext';
import { OnboardingContextProvider } from './utils/onboarding';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

const InnerApp = ({ setToken }: { setToken: (string) => void }) => {
    const { currentUser, loading, error } = useAuth();
    let routes;
    if (loading) {
        return <Loading />;
    } else if (error) {
        routes = <div>Error Loading Page</div>;
    } else if (currentUser) {
        // authenticated routes
        currentUser.getIdToken().then((tk) => setToken(tk)); // set firebase token
        routes = <AuthRoutes />;
    } else {
        // unAuthenticated routes
        routes = <UnauthRoutes setToken={setToken} />;
    }
    return (
        <Layout>
            <IonApp>{routes}</IonApp>
        </Layout>
    );
};

const App = () => {
    const [token, setToken] = useState(''); // firebase JWT
    return (
        <GraphQLProvider token={token}>
            <AuthProvider>
                {/* depends on AuthProvider & GraphQLProvider */}
                <OnboardingContextProvider maxRetries={-1}>
                    <Elements stripe={stripePromise}>
                        <InnerApp setToken={setToken} />
                    </Elements>
                </OnboardingContextProvider>
            </AuthProvider>
        </GraphQLProvider>
    );
};

export default App;
