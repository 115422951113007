// importing modules
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
// importing components
import {
    IonButton,
    IonCol,
    IonContent,
    IonImg,
    IonPage,
    IonRow,
} from '@ionic/react';
import CompleteTaskNavTabs from '../UI/CompleteTaskTabs/CompleteTaskTabs';
import UserImg from '../../../components/UserImg/UserImg';
import ParticipantReviewItem from './ParticipantReviewItem/ParticipantReviewItem';
import CompleteTaskFooter from '../../../components/FooterWithChildren/FooterWithChildren';
import Loading from '../../../components/status/Loading';
import Strokes from 'components/Strokes';
// importing assets
// importing utilities
import { hrs_mins_to_credits } from '../../../utils/timeConversion';
import { getLocalStorage } from '../../../helpers/localStorage/localStorageHelper';
import { yingDebug } from '../../../utils/debug/YingDebug';
// importing graphql
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_JOB_BY_ID } from '../../../GraphQL/Job/GET_JOB_BY_ID/GET_JOB_BY_ID';
import {
    GetJobById,
    GetJobByIdVariables,
} from '../../../GraphQL/__generated__/GetJobById';
import { gql_RECIPIENT_COMPLETE_PAYMENT } from '../../../GraphQL/Job/RECIPIENT_COMPLETE_PAYMENT/RECIPIENT_COMPLETE_PAYMENT';
import {
    RECIPIENT_COMPLETE_PAYMENT,
    RECIPIENT_COMPLETE_PAYMENTVariables,
} from '../../../GraphQL/__generated__/RECIPIENT_COMPLETE_PAYMENT';
//importing styles
import './ParticipantReview.css';

const ParticipantReview: React.FC = () => {
    const history = useHistory();
    const { taskId } = useParams<{ taskId: string }>();
    const [review, setReview] = useState<string>('');
    const [
        exe__RECIPIENT_COMPLETE_PAYMENT,
        {
            data: data__RECIPIENT_COMPLETE_PAYMENT,
            loading: loading__RECIPIENT_COMPLETE_PAYMENT,
            error: error__RECIPIENT_COMPLETE_PAYMENT,
        },
    ] = useMutation<
        RECIPIENT_COMPLETE_PAYMENT,
        RECIPIENT_COMPLETE_PAYMENTVariables
    >(gql_RECIPIENT_COMPLETE_PAYMENT);

    const { data, loading, error, fetchMore } = useQuery<
        GetJobById, // query return return type (shape of data)
        GetJobByIdVariables // query request type       (shape of variables)
    >(GET_JOB_BY_ID, {
        variables: {
            jobId: parseInt(taskId),
        },
        fetchPolicy: 'no-cache',
    });

    if (loading) {
        return <Loading />;
    }

    const handleCompleteTask = () => {
        const completeTaskObj = getLocalStorage('completeTaskViaRecipient');
        exe__RECIPIENT_COMPLETE_PAYMENT({
            variables: {
                id_job: parseInt(taskId),
                credits: hrs_mins_to_credits(
                    parseInt(completeTaskObj.hrs),
                    parseInt(completeTaskObj.mins)
                ),
                review: completeTaskObj.review,
                rating: completeTaskObj.rating,
            },
        })
            .then((result) => {
                history.push(`/completeTask/recipient/${taskId}/confirmation`);
            })
            .catch((e) => yingDebug(e));
    };

    return (
        <IonPage>
            <IonContent fullscreen>
                <IonRow className='taskPartc__header'>
                    <IonCol
                        sizeSm='12'
                        sizeXs='12'
                        className='taskPartc__header--container'
                    >
                        <Strokes top={true} />
                        <CompleteTaskNavTabs
                            disablePrevStep={false}
                            prevPage={`/completeTask/recipient/${taskId}/rating`}
                        />
                        <UserImg />
                    </IonCol>
                </IonRow>
                <IonRow className='taskPartc__textContent'>
                    <IonCol
                        className='taskPartc__textContent--container'
                        sizeSm='12'
                        sizeXs='12'
                    >
                        <h1 className='taskPartc__textContent--title'>
                            Nice! Please write a<span> review </span>
                            to show your appreciation
                        </h1>
                        <p className='completeTask__textContent--text'>
                            Your review will appear for {data.job.Skill.name} on
                            their profile.
                        </p>
                    </IonCol>
                </IonRow>
                <IonRow className='taskPartcItem__row'>
                    <ParticipantReviewItem
                        id={data.job.id}
                        firstName={data.job.provider.firstName}
                        lastName={data.job.provider.lastName}
                        profileImg={data.job.provider.profilePicture}
                        skill={data.job.Skill.name}
                        key={data.job.id}
                        review={review}
                        setReview={setReview}
                    />
                </IonRow>
            </IonContent>
            <CompleteTaskFooter>
                <IonButton
                    fill='clear'
                    color='ion-primary'
                    onClick={handleCompleteTask}
                    disabled={review.length === 0}
                    className='footerWithChild__btn'
                >
                    Complete Task
                </IonButton>
            </CompleteTaskFooter>
        </IonPage>
    );
};

export default ParticipantReview;
