// importing modules
import React, { useState, useEffect } from 'react';
import moment from 'moment';
// importing components
import { IonCol, IonGrid, IonRow, IonSpinner } from '@ionic/react';
import TaskScheduleControl from '../TaskScheduleControls/TaskScheduleControl';
import ScheduledTaskItemFallback from '../ScheduledTaskItemFallback/ScheduledTaskItemFallback';
import HelpingTaskItem from '../UserTaskItem/HelpingTaskItem';
import ReceivingTaskItem from '../UserTaskItem/ReceivingTaskItem';

import ProviderRequestItem from '../ParticipationRequestItem/ProviderRequestItem';
import RecipientRequestItem from '../ParticipationRequestItem/RecipientRequestItem';
// importing utils
import getDates from 'utils/getDates';
import filterScheduleByDay from 'utils/filterScheduleByDay';
import filterParticipationRequestsByDay from 'utils/filterParticipationRequestsByDay';
// importing graphql & types
import { useQuery } from '@apollo/react-hooks';
import { PROFILE_JOBS } from 'GraphQL/Profile/PROFILE_JOBS/PROFILE_JOBS';
import {
    GetProfileJobs,
    GetProfileJobsVariables,
    GetProfileJobs_profile_upcomingJobsReceive,
    GetProfileJobs_profile_upcomingJobsServe,
    GetProfileJobs_profile_incomingParticipationRequests,
    GetProfileJobs_profile_outgoingParticipationRequests,
    GetProfileJobs_profile_jobsPosted,
} from 'GraphQL/__generated__/GetProfileJobs';
// importing styles
import 'pages/UserHomeProfile/UserHomeProfile.css';
// firebase
import { useAuth } from '../../context/firebase/authContext';
import filterCurrentScheduleOut from './filterCurrentScheduleOut';

export default () => {
    const { currentUser } = useAuth();
    const [weeklyDates, setWeeklyDates] = useState(
        getDates(moment(), moment().add(4, 'days'))
    ); // sets weekly dates
    const [d, setD] = useState<string>(moment().format('YYYY-MM-DD'));

    const { data, loading, error } = useQuery<
        GetProfileJobs, // query return return type (shape of data)
        GetProfileJobsVariables // query request type (shape of variables)
    >(PROFILE_JOBS, {
        variables: { subId: currentUser.uid },
        pollInterval: 5000,
        fetchPolicy: 'no-cache',
    });

    useEffect(() => {
        handleSelectedDate(d);
    }, []);

    const handleSelectedDate = (dt: string) => setD(dt);
    if (loading) {
        return (
            <div className='schedule__spinner--wrap'>
                <IonSpinner className='schedule__spinner' name='crescent' />
            </div>
        );
    }

    if (error) {
        console.log(error);
    }

    const filteredJobsServe: GetProfileJobs_profile_upcomingJobsServe[] =
        filterScheduleByDay(
            data?.profile?.upcomingJobsServe ?? [],
            d.toString()
        );
    const filteredJobsReceive: GetProfileJobs_profile_upcomingJobsReceive[] =
        filterScheduleByDay(
            data?.profile?.upcomingJobsReceive ?? [],
            d.toString()
        );
    const activeRequestsInbound: GetProfileJobs_profile_upcomingJobsServe[] =
        filteredJobsServe.filter(
            (job) =>
                job.Status.toString() === 'SCHEDULED' ||
                job.Status.toString() === 'RECIPIENT_HAS_PROPOSED_OFFER'
        );
    const activeRequestsOutbound: GetProfileJobs_profile_upcomingJobsReceive[] =
        filteredJobsReceive.filter(
            (job) =>
                job.Status.toString() === 'SCHEDULED' ||
                job.Status.toString() === 'RECIPIENT_HAS_PROPOSED_OFFER'
        );
    // const jobFilter = job =>
    // (job?.status?.toString() === 'SCHEDULED' ||
    //     job?.status?.toString() === 'RECIPIENT_HAS_PROPOSED_OFFER')

    // const activeRequestsOutbound = filterCurrentScheduleOut(
    //     data
    //         ?.profile
    //         ?.upcomingJobsReceive
    //         ?.concat(data?.profile?.jobsPosted as any ?? [])
    //         ?.filter(jobFilter),
    //     weeklyDates,
    //     );

    const filteredOutgoingParticipationRequests: GetProfileJobs_profile_outgoingParticipationRequests[] =
        filterParticipationRequestsByDay(
            data?.profile?.outgoingParticipationRequests ?? [],
            d.toString()
        );

    const activeOutgoingParticipationRequests: GetProfileJobs_profile_outgoingParticipationRequests[] =
        filteredOutgoingParticipationRequests.filter(
            (participationreq) =>
                participationreq.status.toString() === 'ACCEPTED' ||
                participationreq.status.toString() ===
                    'RECIPIENT_HAS_PROPOSED_OFFER'
        );

    const filteredIncomingParticipationRequests: GetProfileJobs_profile_incomingParticipationRequests[] =
        filterParticipationRequestsByDay(
            data?.profile?.incomingParticipationRequests ?? [],
            d.toString()
        );

    const activeIncomingParticipationRequests: GetProfileJobs_profile_incomingParticipationRequests[] =
        filteredIncomingParticipationRequests.filter(
            (participationreq) =>
                participationreq.status.toString() ===
                'RECIPIENT_HAS_PROPOSED_OFFER'
        );

    const filteredJobsPosted: GetProfileJobs_profile_jobsPosted[] =
        filterScheduleByDay(data?.profile?.jobsPosted ?? [], d.toString());

    const activeJobPosts: GetProfileJobs_profile_jobsPosted[] =
        filteredJobsPosted.filter(
            (job) =>
                job.status.toString() === 'ACTIVE' &&
                job.startDate !== null &&
                job.startTime !== null
        );

    //  Gets incomplete pending tasks (previously tasks posted/skill requests tab)
    // incompleteOutgoingParticipationRequests is currently unused, will be readded/removed later depending on if they
    // are found to be needed

    const incompleteRequestsInbound: GetProfileJobs_profile_upcomingJobsServe[] =
        filterCurrentScheduleOut(
            data.profile.upcomingJobsServe.filter(
                (job) =>
                    (job.Status.toString() === 'SCHEDULED' ||
                        job.Status.toString() ===
                            'RECIPIENT_HAS_PROPOSED_OFFER') &&
                    new Date(job.endTime) < new Date()
                // false
            ),
            weeklyDates
        );

    const incompleteRequestsOutbound: GetProfileJobs_profile_upcomingJobsReceive[] =
        filterCurrentScheduleOut(
            data.profile.upcomingJobsReceive.filter(
                (job) =>
                    (job.Status.toString() === 'SCHEDULED' ||
                        job.Status.toString() ===
                            'RECIPIENT_HAS_PROPOSED_OFFER') &&
                    new Date(job.endTime) < new Date()
                // false
            ),
            weeklyDates
        );

    const incompleteIncomingParticipationRequests: GetProfileJobs_profile_incomingParticipationRequests[] =
        filterCurrentScheduleOut(
            data.profile.incomingParticipationRequests.filter(
                (participationreq) =>
                    participationreq.status.toString() ===
                        'RECIPIENT_HAS_PROPOSED_OFFER' &&
                    new Date(participationreq.skill.jobPost.endTime) <
                        new Date()
            ),
            weeklyDates
        );

    const incompleteActiveJobPosts: GetProfileJobs_profile_jobsPosted[] =
        filterCurrentScheduleOut(
            data.profile.jobsPosted.filter(
                (job) =>
                    job.status.toString() === 'ACTIVE' &&
                    job.startDate !== null &&
                    job.startTime !== null &&
                    job.endTime !== null &&
                    new Date(job.endTime) < new Date()
            ),
            weeklyDates
        );

    // Used for page formatting when the "incomplete task" schedule is empty.
    const scheduleIsNotEmpty =
        incompleteRequestsInbound.length > 0 ||
        incompleteRequestsOutbound.length > 0 ||
        incompleteIncomingParticipationRequests.length > 0 ||
        incompleteActiveJobPosts.length > 0;

    return (
        <>
            <IonGrid>
                <IonRow className='schedule'>
                    <IonRow className='schedule__row'>
                        <IonCol
                            className='schedule__col--header'
                            sizeSm='12'
                            sizeXs='12'
                        >
                            <h1 className='schedule__title'>Your Schedule</h1>
                        </IonCol>
                    </IonRow>
                    <IonRow className='schedule__row--days'>
                        {weeklyDates.length > 0 &&
                            weeklyDates.map((day) => (
                                <TaskScheduleControl
                                    handleSelectedDate={handleSelectedDate}
                                    selectedDate={d}
                                    key={day.dateStr}
                                    {...day}
                                />
                            ))}
                    </IonRow>
                    {activeRequestsOutbound.length === 0 &&
                    activeRequestsInbound.length === 0 &&
                    activeOutgoingParticipationRequests.length === 0 &&
                    activeJobPosts.length === 0 ? (
                        <ScheduledTaskItemFallback
                            isEmpty={!scheduleIsNotEmpty}
                        />
                    ) : null}
                    <IonRow className='tasks__row'>
                        {activeRequestsInbound.length > 0 &&
                            activeRequestsInbound.map((task, id) => {
                                return (
                                    <HelpingTaskItem
                                        key={id}
                                        otherProfile={task.recipient}
                                        task={task}
                                    />
                                );
                            })}
                        {activeRequestsOutbound.length > 0 &&
                            activeRequestsOutbound.map((task, id) => {
                                return (
                                    <ReceivingTaskItem
                                        key={id}
                                        otherProfile={task.provider}
                                        task={task}
                                    />
                                );
                            })}
                        {activeOutgoingParticipationRequests.length > 0 &&
                            activeOutgoingParticipationRequests.map(
                                (task, id) => {
                                    return (
                                        <ProviderRequestItem
                                            key={id}
                                            otherProfile={task.recipient}
                                            task={task}
                                        />
                                    );
                                }
                            )}
                        {activeIncomingParticipationRequests.length > 0 &&
                            activeIncomingParticipationRequests.map(
                                (task, id) => {
                                    return (
                                        <ReceivingTaskItem
                                            key={id}
                                            otherProfile={task.provider}
                                            task={task}
                                        />
                                    );
                                }
                            )}
                        {activeJobPosts.length > 0 &&
                            activeJobPosts.map((task, id) => {
                                return (
                                    <RecipientRequestItem
                                        key={id}
                                        otherProfile={task.recipient}
                                        task={task}
                                    />
                                );
                            })}
                    </IonRow>
                </IonRow>
                {scheduleIsNotEmpty ? (
                    <IonRow className='schedule'>
                        <IonRow className='schedule__row'>
                            <IonCol
                                className='schedule__col--header'
                                sizeSm='12'
                                sizeXs='12'
                            >
                                <h1 className='schedule__title'>
                                    Incomplete Tasks
                                </h1>
                            </IonCol>
                        </IonRow>
                        <IonRow className='tasks__row'>
                            {incompleteRequestsInbound.length > 0 &&
                                incompleteRequestsInbound.map((task, id) => {
                                    return (
                                        <HelpingTaskItem
                                            key={id}
                                            otherProfile={task.recipient}
                                            task={task}
                                        />
                                    );
                                })}
                            {incompleteRequestsOutbound.length > 0 &&
                                incompleteRequestsOutbound.map((task, id) => {
                                    return (
                                        <ReceivingTaskItem
                                            key={id}
                                            otherProfile={task.provider}
                                            task={task}
                                        />
                                    );
                                })}
                            {incompleteIncomingParticipationRequests.length >
                                0 &&
                                incompleteIncomingParticipationRequests.map(
                                    (task, id) => {
                                        return (
                                            <ReceivingTaskItem
                                                key={id}
                                                otherProfile={task.provider}
                                                task={task}
                                            />
                                        );
                                    }
                                )}
                            {incompleteActiveJobPosts.length > 0 &&
                                incompleteActiveJobPosts.map((task, id) => {
                                    return (
                                        <RecipientRequestItem
                                            key={id}
                                            otherProfile={task.recipient}
                                            task={task}
                                        />
                                    );
                                })}
                        </IonRow>
                    </IonRow>
                ) : (
                    ''
                )}
            </IonGrid>
            {/* add spacing to stop home screen from encroaching */}
            {activeJobPosts.length > 0 ? (
                <div
                    id='scheduleSpacer'
                    style={{
                        height: '50px',
                    }}
                />
            ) : null}
        </>
    );
};
