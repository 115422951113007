import React from 'react';
import yingLogo from '../../assets/ying-white-logo-3x.png';
import './Loading.css';

const Loading = () => (
    <div className='yingLoader'>
        <img className='yingLoader__logo' src={yingLogo} alt='ying logo' />
    </div>
);

export default Loading;
