// importing modules
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
// importing components
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonIcon,
    IonImg,
    IonRow,
    IonPage,
} from '@ionic/react';
import UserImg from '../UI/UserImg/UserImg';
import GroupImg from '../UI/GroupImg/GroupImg';
import CreateTaskTabs from '../UI/CreateTaskTabs/CreateTaskTabs';
import TaskInviteMember from '../UI/MemberInviteItem/MemberInviteItem';
import CreateTaskFooter from '../../../components/FooterWithChildren/FooterWithChildren';
import Loading from '../../../components/status/Loading';
import Strokes from 'components/Strokes';
// importing assets
import { closeOutline, searchOutline } from 'ionicons/icons';
// importing utilities
import {
    updateLocalStorage,
    getLocalStorage,
    deleteMemberInviteFromLocalStorage,
} from '../../../helpers/localStorage/localStorageHelper';
import filterAuthUserOut from '../../../utils/filterOutAuthUser';
// importing types & graphq;
import { useQuery } from '@apollo/react-hooks';
import { USER_PROFILE_BY_UID } from '../../../GraphQL/Profile/UserProfile/USER_PROFILE_BY_UID';
import {
    UserProfile_byID,
    UserProfile_byIDVariables,
} from '../../../GraphQL/__generated__/UserProfile_byID';
import { SKILL_POOL_FROM_MANY_ORGS } from '../../../GraphQL/Skill/SKILL_POOL_FROM_MANY_ORGS/SKILL_POOL_FROM_MANY_ORGS';
import {
    SkillPoolFromManyOrgs,
    SkillPoolFromManyOrgsVariables,
    SkillPoolFromManyOrgs_profilesFromManyOrgs,
} from '../../../GraphQL/__generated__/SkillPoolFromManyOrgs';
// importing styles
import './TaskInvite.css';
import { useAuth } from '../../../context/firebase/authContext';
interface GroupCode {
    code: string;
}

const TaskHomeInvites: React.FC<{ taskType: string }> = ({ taskType }) => {
    const history = useHistory();
    const { currentUser } = useAuth();
    const [groupCodes, setGroupCodes] = useState<string[]>([]);
    const [memberInvites, setMemberInvites] = useState<
        { name: string; uid: string }[]
    >([]);
    const [searchInput, setSearchInput] = useState<string>('');

    useEffect(() => {
        if (getLocalStorage('createTask')) {
            const createTaskObj = getLocalStorage('createTask');
            const codes = createTaskObj.groupCodes.map((group) => group.code);
            setGroupCodes(codes);
            setMemberInvites(createTaskObj.memberInvites);
        } else {
            history.push('/home');
        }
    }, [history]);

    const { data, loading } = useQuery<
        UserProfile_byID,
        UserProfile_byIDVariables
    >(USER_PROFILE_BY_UID, {
        variables: {
            subId: currentUser.uid,
        },
    });

    const { data: usersData, loading: usersLoading } = useQuery<
        SkillPoolFromManyOrgs,
        SkillPoolFromManyOrgsVariables
    >(SKILL_POOL_FROM_MANY_ORGS, {
        variables: { uid: currentUser.uid },
    });

    if (loading || usersLoading) {
        return <Loading />;
    }

    const handleRemoveInvite = (uid: string) => {
        deleteMemberInviteFromLocalStorage('createTask', 'memberInvites', uid);
        setMemberInvites(memberInvites.filter((member) => member.uid !== uid));
    };

    const handleAddGroup = (groupCode: string) => {
        const codeFound = groupCodes.find((code) => code === groupCode);
        if (codeFound) {
            setGroupCodes(groupCodes.filter((code) => code !== groupCode));
        } else {
            setGroupCodes((prevCodes) => [...prevCodes, groupCode]);
        }
    };

    const handleConfirmGroupsSelected = () => {
        const codes: GroupCode[] = [];
        groupCodes.forEach((code) => {
            codes.push({ code: code });
        });
        updateLocalStorage('createTask', 'groupCodes', codes);
        history.push('/create-task/confirmation');
    };

    let filteredMembers: SkillPoolFromManyOrgs_profilesFromManyOrgs[] = [];
    if (searchInput.length > 0 && !loading) {
        filteredMembers = usersData.profilesFromManyOrgs.filter((member) =>
            `${member.firstName} ${member.lastName}`
                .toLowerCase()
                .includes(searchInput.toLowerCase())
        ); // filter users based on first name & last name
        filteredMembers = filterAuthUserOut(filteredMembers, currentUser.uid); // filters out the current authenticated user from the members lists
    } else {
        filteredMembers = [];
    }

    let img;
    if (taskType === 'HOME' && getLocalStorage('currentAdminGroupId') == 0) {
        img = <UserImg />;
    } else if (
        taskType === 'GROUP' &&
        getLocalStorage('currentAdminGroupId') == 0
    ) {
        img = <UserImg />;
    } else {
        img = <GroupImg />;
    }

    return (
        <IonPage>
            <IonContent>
                <IonGrid>
                    {/* taskInvite header */}
                    <IonRow className='taskInvite__header'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='taskInvite__header--container'
                        >
                            <Strokes top={true} />
                            <CreateTaskTabs disablePrevStep={false} />
                            {img}
                        </IonCol>
                    </IonRow>
                    {/* text content */}
                    <IonRow className='taskInvite__textContent'>
                        <IonCol
                            className='taskInvite__textContent--container'
                            sizeSm='12'
                            sizeXs='12'
                        >
                            <h1 className='taskInvite__textContent--title'>
                                Who Would you like to <span>invite</span> to
                                participate?
                            </h1>
                            <p className='taskInvite__textContent--text'>
                                You can search for members by their name or
                                filter people by their skill set.
                            </p>
                        </IonCol>
                    </IonRow>
                    <IonRow className='skillSearch__item--container'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='skillSearch__item--col'
                        >
                            {memberInvites.length > 0
                                ? memberInvites.map((member) => (
                                      <div
                                          key={member.uid}
                                          onClick={() =>
                                              handleRemoveInvite(member.uid)
                                          }
                                          className='skillSearch__item--keyword'
                                      >
                                          {member.name}&nbsp;
                                          <IonIcon icon={closeOutline} />
                                      </div>
                                  ))
                                : null}
                        </IonCol>
                    </IonRow>
                    {/* searchbar */}
                    <IonRow className='skillSelection__searchbar'>
                        <IonCol
                            sizeXs='12'
                            sizeSm='12'
                            className='skillSelection__searchbar--container'
                        >
                            <div className='skillSelection__searchbar--input--wrap'>
                                <IonIcon
                                    className='skillSelection__searchbar--input--icon'
                                    icon={searchOutline}
                                />
                                <input
                                    onChange={(event) =>
                                        setSearchInput(event.target.value)
                                    }
                                    value={searchInput}
                                    className='skillSelection__searchbar--input'
                                    type='text'
                                    placeholder='Search by name or group'
                                />
                            </div>
                        </IonCol>
                    </IonRow>
                    {/* filtered members list */}
                    {filteredMembers.length > 0 && (
                        <IonGrid>
                            <IonRow className='taskInvite__members--row'>
                                {filteredMembers.length > 0
                                    ? filteredMembers
                                          .splice(0, 5)
                                          .map((member) => {
                                              return (
                                                  <TaskInviteMember
                                                      key={member.id}
                                                      member={member}
                                                      memberInvites={
                                                          memberInvites
                                                      }
                                                      setMemberInvites={
                                                          setMemberInvites
                                                      }
                                                  />
                                              );
                                          })
                                    : null}
                            </IonRow>
                        </IonGrid>
                    )}
                    <IonRow className='taskInvite__textContent'>
                        <IonCol
                            className='taskInvite__textContent--sub--container'
                            sizeSm='12'
                            sizeXs='12'
                        >
                            <h1 className='taskInvite__textContent--title'>
                                Where would you like to <span>post</span> your
                                task?
                            </h1>
                            <p className='taskInvite__textContent--text'>
                                Select one or multiple groups you wish to post
                                your task to.
                            </p>
                        </IonCol>
                    </IonRow>
                    {/* groups list */}
                    <IonGrid className='taskInvite__form--container'>
                        <IonRow className='taskInvite__form--row'>
                            <IonRow className='taskInvite__col--wrap'>
                                {data.profile.organizations.map((group) => (
                                    <IonCol
                                        key={group.id}
                                        onClick={() =>
                                            handleAddGroup(group.code)
                                        }
                                        className={
                                            groupCodes.includes(group.code)
                                                ? 'taskInvite__col taskInvite__col--active'
                                                : 'taskInvite__col'
                                        }
                                        sizeXs='12'
                                        sizeSm='12'
                                    >
                                        <IonCol
                                            sizeXs='12'
                                            sizeSm='12'
                                            className={
                                                groupCodes.includes(group.code)
                                                    ? 'taskInvite__content taskInvite__content--active'
                                                    : 'taskInvite__content'
                                            }
                                        >
                                            <p
                                                className={
                                                    groupCodes.includes(
                                                        group.code
                                                    )
                                                        ? 'taskInvite__content--place taskInvite__content--place--active'
                                                        : 'taskInvite__content--place'
                                                }
                                            >
                                                {group.name}
                                            </p>
                                        </IonCol>
                                    </IonCol>
                                ))}
                            </IonRow>
                        </IonRow>
                    </IonGrid>
                </IonGrid>
            </IonContent>
            <CreateTaskFooter>
                <IonButton
                    fill='clear'
                    disabled={
                        memberInvites.length === 0 && groupCodes.length === 0
                    }
                    color='ion-primary'
                    onClick={handleConfirmGroupsSelected}
                    className='footerWithChild__btn'
                    id='invite-confirm-btn'
                >
                    Continue
                </IonButton>
            </CreateTaskFooter>
        </IonPage>
    );
};

export default TaskHomeInvites;
