import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import firebase from 'firebase/app';
import 'firebase/auth';
import {
    CreateStripeAccount as CSAQuery,
    GetStripeAccount as GSAQuery,
    GetLoginURL as GLUQuery,
    GetStripeCustomerAccount as GSCAQuery,
    GetCurrentBalance as GCBQuery,
} from '../../../GraphQL/StripeConnect/StripeConnect';

export const CreateStripeAccount = () => {
    const { data, loading, error, fetchMore } = useQuery(CSAQuery, {
        variables: {
            uid: firebase?.auth()?.currentUser?.uid,
        },
    });
    return data;
};
//  gets stripe account for current signed in user
export const GetStripeAccount = () => {
    const {
        data: stripeData,
        loading,
        error,
        fetchMore,
    } = useQuery(GSAQuery, {
        variables: {
            uid: firebase?.auth()?.currentUser?.uid,
        },
        pollInterval: 10000,
    });
    if (stripeData) {
        return stripeData.getStripeAccount;
    }
};
//  gets stripe account for other users by uid
export const GetStripeAccountByUID = (uid: string) => {
    const {
        data: stripeData,
        loading,
        error,
        fetchMore,
    } = useQuery(GSAQuery, {
        variables: {
            uid: uid,
        },
    });
    if (stripeData) {
        return stripeData.getStripeAccount;
    }
};

export const GetProviderStripeAccount = (uid: string) => {
    const {
        data: stripeData,
        loading,
        error,
        fetchMore,
    } = useQuery(GSAQuery, {
        variables: {
            uid: uid,
        },
    });
    if (stripeData) {
        return stripeData.getStripeAccount;
    }
};

export const GetLoginURL = () => {
    const [getUrl, { data, loading }] = useLazyQuery(GLUQuery);
    return getUrl;
};

export const GetStripeCustomerAccount = () => {
    const {
        data: stripeData,
        loading,
        error,
        fetchMore,
    } = useQuery(GSCAQuery, {
        fetchPolicy: 'no-cache',
        variables: {
            uid: firebase?.auth()?.currentUser?.uid,
        },
    });
    if (stripeData) {
        return stripeData.getStripeCustomerAccount;
    }
};

export const GetStripeAccountBalance = () => {
    const {
        data: stripeData,
        loading,
        error,
        fetchMore,
    } = useQuery(GCBQuery, {
        fetchPolicy: 'no-cache',
        variables: {
            uid: firebase?.auth()?.currentUser?.uid,
        },
    });
    if (stripeData) {
        return stripeData.getCurrentBalance;
    }
};
