// importing modules
import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
// importing components
import {
    IonButton,
    IonCol,
    IonImg,
    IonPage,
    IonContent,
    IonRow,
} from '@ionic/react';
import CompleteTaskNavTabs from '../UI/CompleteTaskTabs/CompleteTaskTabs';
import UserImg from '../../../components/UserImg/UserImg';
import ParticipantItem from './ParticipantRatingItem/ParticipantRatingItem';
import CompleteTaskFooter from '../../../components/FooterWithChildren/FooterWithChildren';
import Loading from '../../../components/status/Loading';
import Strokes from 'components/Strokes';
// importing assets
// importing graphql
import { useQuery } from '@apollo/react-hooks';
import { GET_JOB_BY_ID } from '../../../GraphQL/Job/GET_JOB_BY_ID/GET_JOB_BY_ID';
import {
    GetJobById,
    GetJobByIdVariables,
} from '../../../GraphQL/__generated__/GetJobById';
// importing utilities
import capitalize from '../../../utils/capitalize';
//importing styles
import './ParticipantRating.css';

const ParticipantRating: React.FC = () => {
    const history = useHistory();
    const { taskId } = useParams<{ taskId: string }>();

    const { data, loading, error, fetchMore } = useQuery<
        GetJobById, // query return return type (shape of data)
        GetJobByIdVariables // query request type       (shape of variables)
    >(GET_JOB_BY_ID, {
        variables: {
            jobId: parseInt(taskId),
        },
        fetchPolicy: 'no-cache',
    });

    if (loading) {
        return <Loading />;
    }

    return (
        <IonPage>
            <IonContent>
                <IonRow className='taskPartc__header'>
                    <IonCol
                        sizeSm='12'
                        sizeXs='12'
                        className='taskPartc__header--container'
                    >
                        <Strokes top={true} />
                        <CompleteTaskNavTabs
                            disablePrevStep={false}
                            prevPage={`/completeTask/recipient/${taskId}`}
                        />
                        <UserImg />
                    </IonCol>
                </IonRow>
                <IonRow className='taskPartc__textContent'>
                    <IonCol
                        className='taskPartc__textContent--container'
                        sizeSm='12'
                        sizeXs='12'
                    >
                        <h1 className='taskPartc__textContent--title'>
                            How would you <span>rate</span> your{' '}
                            <span>{data.job.Skill.category}</span> experience
                            with{' '}
                            <span>
                                {capitalize(data.job.provider.firstName)}
                            </span>{' '}
                            ?
                        </h1>
                        <p className='completeTask__textContent--text'>
                            All tasks on YING require a rating from both people
                            involved.
                        </p>
                    </IonCol>
                </IonRow>
                <IonRow className='taskPartcItem__row'>
                    {
                        <ParticipantItem
                            id={data.job.id}
                            firstName={data.job.provider.firstName}
                            lastName={data.job.provider.lastName}
                            profileImg={data.job.provider.profilePicture}
                            skill={data.job.Skill.name}
                            key={data.job.id}
                        />
                    }
                </IonRow>
            </IonContent>
            <CompleteTaskFooter>
                <IonButton
                    onClick={() =>
                        history.push(`/completeTask/recipient/${taskId}/review`)
                    }
                    fill='clear'
                    color='ion-primary'
                    className='footerWithChild__btn'
                >
                    Continue
                </IonButton>
            </CompleteTaskFooter>
        </IonPage>
    );
};

export default ParticipantRating;
