const genRandomColor = () => {
    const colorsArr = [
        {
            id: 1,
            text: '#00B47C',
            bg: '#00b47b1a',
        },
        {
            id: 2,
            text: '#0091FF',
            bg: '#E5F4FF',
        },
        {
            id: 3,
            text: '#B620E0',
            bg: '#F7E8FB',
        },
        {
            id: 4,
            text: '#FA6400',
            bg: '#FEEFE5',
        },
        {
            id: 5,
            text: '#DCA204',
            bg: '#FBF5E5',
        },
        {
            id: 6,
            text: '#847222',
            bg: '#F2F0E8',
        },
        {
            id: 7,
            text: '#720A34',
            bg: '#F0E6EA',
        },
        {
            id: 8,
            text: '#1C8095',
            bg: '#E8F2F4',
        },
        {
            id: 9,
            text: '#2A5804',
            bg: '#E9EEE5',
        },
        {
            id: 10,
            text: '#822909',
            bg: '#F2E9E6',
        },
        {
            id: 11,
            text: '#9F7B18',
            bg: '#F5F1E7',
        },
        {
            id: 12,
            text: '#847222',
            bg: '#F2F0E8',
        },
        {
            id: 13,
            text: ' #B43042',
            bg: '#F7EAEC',
        },
        {
            id: 14,
            text: ' #081BBD',
            bg: '#E6E8F8',
        },
        {
            id: 15,
            text: '#983F51',
            bg: '#F4EBED',
        },
    ];
    return colorsArr[Math.floor(Math.random() * colorsArr.length)];
};
export { genRandomColor as default };
