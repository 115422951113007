import React from 'react';
import { IonRefresher, IonRefresherContent } from '@ionic/react';
import { chevronDownOutline } from 'ionicons/icons';
import { RefresherEventDetail } from '@ionic/core';

const refresh = (event: CustomEvent<RefresherEventDetail>) => {
    window.location.reload();
    event.detail.complete();
};

const Refresher = ({
    handleReload = refresh,
    text = 'Pull to refresh',
}: {
    handleReload?: (event: CustomEvent<RefresherEventDetail>) => void;
    text?: string;
}) => (
    <IonRefresher slot='fixed' onIonRefresh={handleReload}>
        <IonRefresherContent
            pullingIcon={chevronDownOutline}
            pullingText={text}
            refreshingSpinner='circles'
        ></IonRefresherContent>
    </IonRefresher>
);

export default Refresher;
