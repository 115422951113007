import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonPage,
    IonRow,
    IonImg,
} from '@ionic/react';
import Strokes from 'components/Strokes';
import BackButton from 'components/BackButton';
import { chatbubbleEllipses, searchOutline, menuOutline } from 'ionicons/icons';
import { FirebaseHelper } from '../../helpers/FirebaseHelper';
import {
    setLocalStorage,
    getLocalStorage,
    updateLocalStorage,
    deleteLocalStorage,
} from '../../helpers/localStorage/localStorageHelper';
import moment from 'moment-timezone';
import { useQuery } from '@apollo/react-hooks';
import {
    RETRIEVE_PROFILE__INITIALIZE_IF_NEEDED,
    RETRIEVE_PROFILE__INITIALIZE_IF_NEEDEDVariables,
} from '../../GraphQL/__generated__/RETRIEVE_PROFILE__INITIALIZE_IF_NEEDED';
import { gql_RETRIEVE_PROFILE__INITIALIZE_IF_NEEDED } from '../../GraphQL/Profile/RETRIEVE_PROFILE__INITIALIZE_IF_NEEDED/RETRIEVE_PROFILE__INITIALIZE_IF_NEEDED';
import { gql_ADMIN_CHECK } from '../../GraphQL/Admin/adminCheck';
import {
    ListOfOrgs,
    ListOfOrgsVariables,
} from '../../GraphQL/__generated__/ListOfOrgs';
import { USER_MENU_STATE } from '../../GraphQL/Profile/USER_MENU_STATE/USER_MENU_STATE';
import {
    UserMenuStateVariables,
    UserMenuState,
} from '../../GraphQL/__generated__/UserMenuState';
import { GET_ORG_BY_ID } from '../../GraphQL/organization/GET_ORG_BY_ID/GET_ORG_BY_ID';
import {
    GetOrgById,
    GetOrgByIdVariables,
} from '../../GraphQL/__generated__/GetOrgById';
import { Status } from '../../GraphQL/__generated__/globalTypes';
import './AccountSwitcher.css';
// firebase
import { useAuth } from '../../context/firebase/authContext';

const AccountSwitcher: React.FC<{
    handleMenuToggle: () => void;
    userUid: string;
}> = ({ handleMenuToggle, userUid }) => {
    const { currentUser } = useAuth();
    const fuser = new FirebaseHelper(currentUser);
    const { id } = useParams<{ id: string }>(); // group id
    userUid = 'Istf4jLNDAUBsq2myLYBt0JOWJ82';
    const history = useHistory();
    const [somethingToggle, setSomethingToggle] = useState<number>(0);
    const {
        data: orgsData,
        loading: orgsLoading,
        error: orgsError,
    } = useQuery<ListOfOrgs, ListOfOrgsVariables>(gql_ADMIN_CHECK, {
        variables: { uid: currentUser.uid },
    });

    const { data, loading, error } = useQuery<
        UserMenuState, // query return return type (shape of data)
        UserMenuStateVariables // query request type (shape of variables)
    >(USER_MENU_STATE, {
        variables: {
            subId: fuser.uid,
        },
    });

    const toggleAdminMode = (adminId, id, name, orgPicture) => {
        if (getLocalStorage(id)) {
            if (getLocalStorage(id).admin == 0) {
                localStorage.setItem('currentAdminId', JSON.stringify(adminId));
                localStorage.setItem(id, JSON.stringify({ admin: 1 }));
                localStorage.setItem('currentAdminGroupId', JSON.stringify(id));
                localStorage.setItem(
                    'currentAdminGroupName',
                    JSON.stringify(name)
                );
                localStorage.setItem(
                    'currentAdminGroupPicture',
                    JSON.stringify(orgPicture)
                );
                setSomethingToggle(1);
                history.push(`/home`);
            } else {
                setLocalStorage(id, { admin: 0 });
                localStorage.setItem('currentAdminGroupId', JSON.stringify(0));
                localStorage.setItem('currentAdminId', JSON.stringify(0));
                setSomethingToggle(0);
            }
        } else {
            setLocalStorage(id, { admin: 0 });
            localStorage.setItem('currentAdminId', JSON.stringify(0));
            localStorage.setItem('currentAdminGroupId', JSON.stringify(0));
            setSomethingToggle(0);
        }
    };

    const clearAdminMode = () => {
        localStorage.setItem('currentAdminGroupId', JSON.stringify(0));
        localStorage.setItem('currentAdminId', JSON.stringify(0));
    };

    return (
        <IonPage>
            <IonContent>
                <IonGrid>
                    <IonRow className='confirmPost__header homeHeader'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='confirmPost__header--container'
                        >
                            <Strokes top={true} />
                        </IonCol>
                    </IonRow>
                    <BackButton
                        color={'white'}
                        onClick={() => history.push('/')}
                    />
                    <IonRow className='bendy'>
                        <h2 className='slightlyLeft'>Profile</h2>
                        <div className='bigCenter'>
                            <IonButton
                                routerLink='/home'
                                onClick={clearAdminMode}
                                className='menu__sidebar--item fixed'
                                color='#ffffff'
                            >
                                <span className='adminGroupItems'>
                                    <IonImg
                                        className='menu__sidebar--item--img'
                                        src={
                                            !loading &&
                                            data?.profile?.profilePicture
                                        }
                                        alt='user'
                                    />
                                    <p className='paddingRight'>
                                        {!loading && data?.profile?.firstName}
                                    </p>
                                </span>
                            </IonButton>
                        </div>
                        <h2 className='slightlyLeft'>Admin Accounts</h2>
                        <IonRow className='bigCenter'>
                            <div className='alignAdmin'>
                                {!orgsLoading &&
                                    orgsData?.listOfOrgs?.map((org) => (
                                        <IonButton
                                            color='#ffffff'
                                            className='menu__sidebar--item fixed'
                                            key={org.id}
                                            onClick={() => {
                                                toggleAdminMode(
                                                    org.id,
                                                    org.organization.id,
                                                    org.organization.name,
                                                    org.organization.orgPicture
                                                );
                                            }}
                                        >
                                            <span className='adminGroupItems'>
                                                <IonImg
                                                    className='menu__sidebar--item--img'
                                                    src={
                                                        !loading &&
                                                        org.organization
                                                            .orgPicture
                                                    }
                                                    alt='user'
                                                />
                                                <p className='paddingRight'>
                                                    {org.organization.name}
                                                </p>
                                            </span>
                                        </IonButton>
                                    ))}
                            </div>
                        </IonRow>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default AccountSwitcher;
