import { Status } from '../GraphQL/__generated__/globalTypes';

const filterCompletedTask = (upcomingJobsReceive, upcomingJobsServe) => {
    const completedJobsReceiving = upcomingJobsReceive.filter(
        (job) => job.Status === Status.COMPLETED
    );
    const completedJobsServing = upcomingJobsServe.filter(
        (job) => job.Status === Status.COMPLETED
    );
    return completedJobsReceiving.length + completedJobsServing.length;
};

export { filterCompletedTask as default };
