// importing assets
import React, { useEffect, useRef } from 'react';
import { useParams, Link, Redirect } from 'react-router-dom';
// importing components
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonPage,
    IonRow,
} from '@ionic/react';
import ConfirmationScreenLogo from '../../../components/ConfirmationScreenLogo/ConfirmationScreenLogo';
import Loading from '../../../components/status/Loading';
import Strokes from 'components/Strokes';
// importing assets
// importing utilities
import { setLocalStorage } from '../../../helpers/localStorage/localStorageHelper';
// importing graphql
import { useQuery } from '@apollo/react-hooks';
import { GET_JOB_POST_BY_ID } from '../../../GraphQL/JobPost/GET_JOB_POST_BY_ID/GET_JOB_POST_BY_ID';
import {
    GetJobPostById,
    GetJobPostByIdVariables,
} from '../../../GraphQL/__generated__/GetJobPostById';
// importing styles
import './TaskCompletedConfirmation.css';
import { useAuth } from '../../../context/firebase/authContext';
import { useHistory } from 'react-router-dom';
import { ADMIN_GROUP_PROFILE_PICTURE } from 'GraphQL/Admin/adminGroupProfilePicture/adminGroupProfilePicture';

const TaskConfirmation: React.FC = () => {
    const { currentUser } = useAuth();
    const { taskId } = useParams<{ taskId: string }>();
    const homeLink = useRef(null);
    const history = useHistory();

    const { data, loading } = useQuery<GetJobPostById, GetJobPostByIdVariables>(
        GET_JOB_POST_BY_ID,
        {
            variables: { jobPostId: parseInt(taskId) },
            fetchPolicy: 'cache-and-network',
        }
    );
    const { data: adminData, loading: adminLoading } = useQuery(
        ADMIN_GROUP_PROFILE_PICTURE,
        {
            variables: { adminId: data?.jobPost.adminId },
        }
    );

    useEffect(() => {
        setTimeout(() => {
            setLocalStorage('completeTaskPostedViaRecipient', {
                hrs: '',
                mins: '',
                participants: [],
            });
            history.push('/');
        }, 2000);
    }, [taskId]);

    if (loading) {
        return <Loading />;
    }

    // current user uid has to match the jobpost poster's uid
    if (currentUser.uid != data.jobPost.recipient.uid) {
        return <Redirect to='/' />;
    }

    return (
        <IonPage>
            <IonContent>
                <IonGrid className='taskConfirm__grid'>
                    <IonRow className='taskConfirm'>
                        <IonCol
                            sizeXs='12'
                            sizeSm='12'
                            className='taskConfirm__container'
                        >
                            <ConfirmationScreenLogo />
                            <Strokes />
                            <div className='taskConfirm__img--wrap'>
                                <IonImg
                                    src={
                                        data.jobPost.adminId
                                            ? adminLoading
                                                ? null
                                                : adminData
                                                      .AdminGroupProfilePicture
                                                      .orgPicture
                                            : data.jobPost.recipient
                                                  .profilePicture
                                    }
                                    className='taskConfirm__img'
                                />
                            </div>
                            <h1 className='taskConfirm__title'>
                                Congrats, your task is completed
                            </h1>
                            <p className='taskConfirm__text'>
                                Members will be given Balance Tokens and will
                                see your review on their profile shortly.
                            </p>
                            <a
                                className='goHome_button taskConfirm__text '
                                ref={homeLink}
                                href={`/home`}
                                type='hidden'
                            >
                                Go Home
                            </a>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default TaskConfirmation;
