import React, { useEffect, useRef } from 'react';
import { Route, useHistory } from 'react-router-dom';
import Login from '../pages/Login/Login';
import { useAuth } from '../context/firebase/authContext.jsx';

export const AuthenticatedRoute = ({
    component = null,
    render = null,
    children = null,
    tabs = false,
    ...options
}) => {
    const { currentUser } = useAuth(); // logged in check

    if (!currentUser) {
        return <Route {...options} component={Login} />;
    } else if (children) {
        return <Route {...options}>{children}</Route>;
    } else if (component) {
        return <Route {...options} component={component} />;
    } else if (render) {
        return <Route {...options} render={render} />;
    } else {
        throw new Error('One of either component or render props are required');
    }
};
