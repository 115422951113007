import React from 'react';
import { useHistory } from 'react-router-dom';
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonPage,
    IonRow,
} from '@ionic/react';
import yingLogo from '../../assets/ying-logo-black@3x.png';
import './OnboardAssociateGroup.css';

const OnboardAssociateGroup: React.FC = () => {
    const history = useHistory();
    return (
        <IonPage>
            <IonContent>
                <IonGrid>
                    <IonRow className='assocGroup__header'>
                        <IonCol
                            className='assocGroup__header--logo--wrap'
                            sizeXs='12'
                            sizeSm='12'
                            sizeMd='12'
                            sizeLg='12'
                            sizeXl='12'
                        >
                            <img
                                className='assocGroup__header--logo'
                                src={yingLogo}
                                alt='ying logo'
                            />
                        </IonCol>
                    </IonRow>
                    <div className='assocGroup__content--container'>
                        <div className='assocGroup__text--wrap'>
                            <h1 className='assocGroup__text--title'>
                                <span className='assocGroup__title--span'>
                                    Welcome
                                </span>
                                , begin your
                                <br />
                                skill sharing
                                <br />
                                journey on YING
                            </h1>
                            <p className='assocGroup__text--text'>
                                Already have a group? Let's find it!
                            </p>
                            <IonButton
                                onClick={() => history.push('/joinGroup')}
                                fill='clear'
                                color='ion-primary'
                                className='assocGroup__content--btn'
                                id='access_code_btn'
                            >
                                I have a group access code!
                            </IonButton>
                            <IonRow className='assocGroup__typeDivider'>
                                <IonCol
                                    sizeXs='10'
                                    sizeSm='10'
                                    className='assocGroup__typeDivider--container'
                                >
                                    <p className='assocGroup__typeDivider__text'>
                                        <span className='assocGroup__typeDivider__text--span'>
                                            Or
                                        </span>
                                    </p>
                                </IonCol>
                            </IonRow>
                            <p className='assocGroup__text--text'>
                                Create a new group! I have been given a YING
                                admin code.
                            </p>
                            <IonButton
                                onClick={() =>
                                    history.push('/communityOnboarding')
                                }
                                fill='clear'
                                color='ion-primary'
                                className='assocGroup__content--btn'
                            >
                                I have an admin code!
                            </IonButton>
                        </div>
                    </div>
                    <div className='assocGroup__policy--link--container'>
                        <div className='assocGroup__policy--link--wrap'>
                            <a className='assocGroup__policy--link' href='#'>
                                Privacy Policy
                            </a>
                            &nbsp;&nbsp;
                            <a className='assocGroup__policy--link' href='#'>
                                Terms & Conditions
                            </a>
                            &nbsp;&nbsp;
                            <a className='assocGroup__policy--link' href='#'>
                                Contact Us
                            </a>
                        </div>
                    </div>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};
export default OnboardAssociateGroup;
