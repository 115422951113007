// importing modules
import React, { useState, useEffect, useRef } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
// importing components
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonPage,
    IonRow,
} from '@ionic/react';
import ConfirmationScreenLogo from '../../../components/ConfirmationScreenLogo/ConfirmationScreenLogo';
import Loading from '../../../components/status/Loading';
import Strokes from 'components/Strokes';
// importing assets
// importing utilities
import { currentUserProfilePicture } from '../../util/ProfilePictureHandler';
import capitalize from '../../../utils/capitalize';
// importing graphql utilities - types
import { useQuery } from '@apollo/react-hooks';
import {
    GetProviderTaskInfo,
    GetProviderTaskInfoVariables,
} from '../../../GraphQL/__generated__/GetProviderTaskInfo';
import { TASK_PROVIDER_INFO } from '../../../GraphQL/RequestTaskConfirmation/getProviderTaskInfo';
// importing styles
import './TaskConfirmation.css';
import { useAuth } from '../../../context/firebase/authContext';
import {
    UserByUID,
    UserByUIDVariables,
} from '../../../GraphQL/__generated__/UserByUID';
import { USER_ORGANIZATIONS_BY_UID } from '../../../GraphQL/Profile/USER_ORGANIZATIONS/USER_ORGANIZATIONS_BY_UID';

const TaskConfirmation: React.FC = () => {
    const { currentUser } = useAuth();
    const history = useHistory();
    const { provider, skillName } = useParams<{
        provider: string;
        skillName: string;
    }>();
    const decodedSkillName = decodeURIComponent(skillName);
    const [userImg, setUserImg] = useState<string>('');
    const linkRef = useRef(null);

    currentUserProfilePicture()
        .getDownloadURL()
        .then((r) => {
            setUserImg(r);

            if (!currentUser.photoURL) {
                currentUser
                    .updateProfile({
                        photoURL: r,
                    })
                    .then(function () {
                        // Update successful.
                    })
                    .catch(function (error) {
                        // An error happened.
                    });
            }
        });

    useEffect(() => {
        setTimeout(() => {
            linkRef.current?.click();
        }, 1500);
    });

    const { data, loading, error, fetchMore } = useQuery<
        GetProviderTaskInfo,
        GetProviderTaskInfoVariables
    >(TASK_PROVIDER_INFO, {
        variables: {
            uid: provider,
        },
    });
    const { data: currentUserData, loading: currentUserDataLoading } = useQuery<
        UserByUID, // query return return type (shape of data)
        UserByUIDVariables // query request type       (shape of variables)
    >(USER_ORGANIZATIONS_BY_UID, {
        variables: {
            subId: currentUser.uid,
        },
    });

    if (loading || currentUserDataLoading) {
        return <Loading />;
    }

    //It checks if users have a group in common
    if (currentUserData != undefined && data != undefined) {
        const filteredArray = currentUserData.profile.organizations
            .map((orgs) => orgs.id)
            .filter((value) =>
                data.profile.organizations
                    .map((orgs) => orgs.id)
                    .includes(value)
            );

        if (filteredArray.length == 0) {
            return <Redirect to='/' />;
        }
    }

    // User cannot request himself.
    //Cannot be placed higher because hooks cannot be done condiniotaly
    if (provider == currentUser.uid) {
        return <Redirect to='/' />;
    }

    return (
        <IonPage>
            <IonContent>
                <IonGrid className='taskConfirm__grid'>
                    <IonRow className='taskConfirm'>
                        <IonCol
                            sizeXs='12'
                            sizeSm='12'
                            className='taskConfirm__container'
                        >
                            <ConfirmationScreenLogo />
                            <Strokes />
                            <div className='taskConfirm__img--container'>
                                <div className='taskConfirm__img--wrap taskConfirm__img--wrap--left'>
                                    <IonImg
                                        src={userImg}
                                        className='taskConfirm__img'
                                    />
                                </div>
                                <div className='taskConfirm__img--wrap taskConfirm__img--wrap--right'>
                                    <IonImg
                                        src={data.profile.profilePicture}
                                        className='taskConfirm__img'
                                    />
                                </div>
                            </div>
                            <h1 className='taskConfirm__title'>
                                Your {capitalize(decodedSkillName)} session with{' '}
                                {capitalize(data.profile.firstName)} is
                                Requested!
                            </h1>
                            <p className='taskConfirm__text'>
                                We will notify you with further updates.
                            </p>
                            {/* <IonButton 
                                fill="clear" 
                                onClick={() => history.push('/home')} 
                                color="ion-primary"
                                className="taskConfirm__btn">
                                Return Home
                            </IonButton> */}
                            <a // need a hard refresh after requesting a skill
                                style={{ display: 'none' }}
                                type='hidden'
                                ref={linkRef}
                                href='/home'
                                className='taskConfirm__btn'
                            >
                                Return Home
                            </a>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default TaskConfirmation;
