// importing modules
import React, { useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
// importing components
import {
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonRow,
    IonPage,
} from '@ionic/react';
import ConfirmationScreenLogo from '../../../components/ConfirmationScreenLogo/ConfirmationScreenLogo';
import Strokes from 'components/Strokes';
// importing assets
import Loading from '../../../components/status/Loading';
// importing graphql utilities - types
import { useQuery } from '@apollo/react-hooks';
import { GET_SKILL_NEEDED_BY_ID } from '../../../GraphQL/SkillNeeded/GET_SKILL_NEEDED_BY_ID/GET_SKILL_NEEDED_BY_ID';
import {
    GetSkillNeededById,
    GetSkillNeededByIdVariables,
} from '../../../GraphQL/__generated__/GetSkillNeededById';
// importing styles
import './TaskParticipationConfirmationScreen.css';

const TaskConfirmation: React.FC = () => {
    const history = useHistory();
    const { skillId } = useParams<{ skillId: string }>();
    const linkRef = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            linkRef.current?.click();
        }, 1500);
    });

    const { data, loading } = useQuery<
        GetSkillNeededById,
        GetSkillNeededByIdVariables
    >(GET_SKILL_NEEDED_BY_ID, {
        variables: { id: parseInt(skillId) },
        fetchPolicy: 'no-cache',
    });

    if (loading) {
        return <Loading />;
    }

    return (
        <IonPage>
            <IonContent>
                <IonGrid className='taskParConfirm__grid'>
                    <IonRow className='taskParConfirm'>
                        <IonCol
                            sizeXs='12'
                            sizeSm='12'
                            className='taskParConfirm__container'
                        >
                            <ConfirmationScreenLogo />
                            <Strokes />
                            {/* <div className='taskParConfirm__img--wrap'>
                                <IonImg
                                    src={
                                        data.skillNeeded.jobPost.recipient
                                            .profilePicture
                                    }
                                    className='taskParConfirm__img'
                                />
                            </div> */}
                            <h1 className='taskParConfirm__title'>
                                Thank you for requesting participation!
                            </h1>
                            <p className='taskParConfirm__text'>
                                We will notify you with further updates
                            </p>
                            <a // need a hard refresh after requesting a skill
                                style={{ display: 'none' }}
                                type='hidden'
                                ref={linkRef}
                                href='/home'
                                className='taskParConfirm__btn'
                            >
                                Return Home
                            </a>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default TaskConfirmation;
