import React, { useState, createContext, useEffect, useContext } from 'react';
import { auth } from './firebase';

export const AuthContext = createContext();

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    // core signup/signin/logout/reset functionality
    const signUp = (email, password) => {
        return auth.createUserWithEmailAndPassword(email, password);
    };

    const login = (email, password) => {
        return auth.signInWithEmailAndPassword(email, password);
    };

    const logout = () => {
        return auth.signOut();
    };

    const resetPassword = (email) => {
        return auth.sendPasswordResetEmail(email);
    };

    // phone number functionality

    const linkWithPhoneNumber = (phoneNumber, appVerifier) => {
        return currentUser.linkWithPhoneNumber(phoneNumber, appVerifier);
    };

    const updateEmail = (email) => {
        return currentUser.updateEmail(email);
    };

    const updatePassword = (password) => {
        return currentUser.updatePassword(password);
    };

    const isError = () => {
        if (!error) {
            setError(false);
        } else {
            setError(true);
        }
    };
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setCurrentUser(user);
            setLoading(false);
        });

        return unsubscribe;
    }, []);

    const value = {
        currentUser,
        loading,
        error,
        login,
        signUp,
        logout,
        resetPassword,
        linkWithPhoneNumber,
        updateEmail,
        updatePassword,
        isError,
    };

    return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
};
