// importing modules
import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Calendar } from '@ionic-native/calendar';
import { Capacitor } from '@capacitor/core';
import moment from 'moment-timezone';
// importing components
import Loading from '../../components/status/Loading';
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonPage,
    IonRow,
} from '@ionic/react';
import UserImg from '../CreateTask/UI/UserImg/UserImg';
import BackButton from 'components/BackButton';
import Strokes from 'components/Strokes';
// importing assets
// importing utilities
import { getLocalStorage } from '../../helpers/localStorage/localStorageHelper';
// importing types & graphql
import { useMutation, useQuery } from '@apollo/react-hooks';
import { GET_JOB_BY_ID } from '../../GraphQL/Job/GET_JOB_BY_ID/GET_JOB_BY_ID';
import {
    GetJobById,
    GetJobByIdVariables,
} from '../../GraphQL/__generated__/GetJobById';
import { UPDATE_ONE_JOB_STATUS } from '../../GraphQL/Job/UPDATE_ONE_JOB_STATUS/UPDATE_ONE_JOB_STATUS';
import {
    UpdateOneJobStatus,
    UpdateOneJobStatusVariables,
} from '../../GraphQL/__generated__/UpdateOneJobStatus';
import {
    createOneNotificationWithSender,
    createOneNotificationWithSenderVariables,
} from '../../GraphQL/__generated__/createOneNotificationWithSender';
import { CREATE_ONE_NOTIFICATION_WITH_SENDER } from '../../GraphQL/Notification/CREATE_ONE_NOTIFICATION_WITH_SENDER/CREATE_ONE_NOTIFICATION_WITH_SENDER';
import { NotificationType } from '../../GraphQL/__generated__/globalTypes';
// importing styles
import './CancelHelpingRecipient.css';

const CancelHelpingRecipient: React.FC = () => {
    const history = useHistory();
    const { recipient, taskId } = useParams<{
        recipient: string;
        taskId: string;
    }>();
    const [msg, setMsg] = useState<string>('');
    const [CancelJob, CancelJobDatas] = useMutation<
        UpdateOneJobStatus,
        UpdateOneJobStatusVariables
    >(UPDATE_ONE_JOB_STATUS);
    const [createNotification, createNotificationDatas] = useMutation<
        createOneNotificationWithSender,
        createOneNotificationWithSenderVariables
    >(CREATE_ONE_NOTIFICATION_WITH_SENDER);

    const { data, loading, error, fetchMore } = useQuery<
        GetJobById, // query return return type (shape of data)
        GetJobByIdVariables // query request type       (shape of variables)
    >(GET_JOB_BY_ID, {
        variables: {
            jobId: parseInt(taskId),
        },
        fetchPolicy: 'no-cache',
    });

    if (loading) {
        return <Loading />;
    }

    const handleCancelTask = () => {
        CancelJob({
            variables: {
                jobId: parseInt(taskId),
                status: 'CANCELED',
            },
        })
            .then((r) => {
                createNotification({
                    variables: {
                        title: 'Cancelled Participation',
                        subTitle: data.job.Skill.category,
                        body: `${data.job.provider.firstName} has cancelled their participation for this task`,
                        additionalDetails: null,
                        expires: data.job.startTime,
                        path: `/skillRequest/${data.job.id}`,
                        notificationType:
                            NotificationType.TASK_REQUEST_CANCELED,
                        uid: data.job.recipient.uid,
                        senderUid: data.job.provider.uid,
                        adminId: parseInt(
                            getLocalStorage('currentAdminGroupId')
                        ),
                        groupId: parseInt(
                            getLocalStorage('currentAdminGroupId')
                        ),
                    },
                }).catch((err) => console.error(err));

                if (Capacitor.platform !== 'web') {
                    const taskStartTime = moment(data.job.startTime);
                    const taskStartDate = taskStartTime.tz(
                        getLocalStorage('tz'),
                        false
                    );
                    const taskEndTime = moment(data.job.endTime);
                    const taskEndDate = taskEndTime.tz(
                        getLocalStorage('tz'),
                        false
                    );
                    // removes a scheduled event from the devices native calendar
                    Calendar.deleteEvent(
                        `YING - ${data.job.Skill.category} w/ ${data.job.recipient.firstName}`,
                        data.job.location.locationString,
                        'No notes available',
                        taskStartDate.toDate(),
                        taskEndDate.toDate()
                    ).catch((err) =>
                        console.error('error creating calendar event', err)
                    );
                    history.push(
                        `/cancel/helping/recipient/${taskId}/confirmation`
                    );
                } else {
                    history.push(
                        `/cancel/helping/recipient/${taskId}/confirmation`
                    );
                }
            })
            .catch((err) => console.error(err));
    };

    return (
        <IonPage>
            <IonContent>
                <IonGrid>
                    <IonRow className='cancelTask__header'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='cancelTask__header--container'
                        >
                            <BackButton color='white' />
                            <Strokes top={true} />
                            <UserImg />
                        </IonCol>
                    </IonRow>
                    <IonRow className='cancelTask__textContent'>
                        <IonCol
                            className='cancelTask__textContent--container'
                            sizeSm='12'
                            sizeXs='12'
                        >
                            <h1 className='cancelTask__textContent--title'>
                                <span>Confirm,</span> cancel task?
                            </h1>
                            <p className='cancelTask__textContent--text'>
                                The task recipient will be notified and the task
                                will be removed from your schedule. If you
                                change your mind and wish to participate in this
                                task you will need to request participation
                                again.
                            </p>
                        </IonCol>
                    </IonRow>
                    <IonGrid className='cancelTask__form--container'>
                        <IonRow className='cancelTask__form--row'>
                            <IonCol
                                className='cancelTask__form--input--wrap'
                                sizeXs='12'
                                sizeSm='12'
                            >
                                <textarea
                                    onChange={(event) =>
                                        setMsg(event.target.value)
                                    }
                                    value={msg}
                                    className='cancelTask__form--textarea'
                                    placeholder='Leave a message...'
                                    required
                                ></textarea>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                    <IonRow className='cancelTask__btn--container'>
                        <IonCol
                            className='cancelTask__btn--wrap'
                            sizeXs='12'
                            sizeSm='12'
                        >
                            <IonButton
                                fill='clear'
                                color='ion-primary'
                                className='cancelTask__btn'
                                onClick={handleCancelTask}
                            >
                                Cancel Task
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default CancelHelpingRecipient;
