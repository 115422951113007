// importing modules
import React from 'react';
// importing components
import { IonRow } from '@ionic/react';
import ParticipationRequestItem from '../ParticipationRequestItem/ParticipationRequestItem';
// importing types
import { GetJobPostById_jobPost_skillsNeeded } from '../../../GraphQL/__generated__/GetJobPostById';

const ParticipationRequestItemList: React.FC<{
    skillsNeeded: GetJobPostById_jobPost_skillsNeeded[];
}> = ({ skillsNeeded }) => {
    return (
        <IonRow className='manageReqItem__row'>
            {skillsNeeded.map((skill) => {
                return (
                    <ParticipationRequestItem
                        key={skill.id}
                        skillNeeded={skill}
                    />
                );
            })}
        </IonRow>
    );
};

export default ParticipationRequestItemList;
