// importing modules
import React, { useState } from 'react';
import { useAuth } from 'context/firebase/authContext';
import { useParams } from 'react-router';
import moment from 'moment-timezone';
// importing components
import {
    IonCol,
    IonIcon,
    IonImg,
    IonPage,
    IonContent,
    IonRow,
} from '@ionic/react';
import ProfileStateTabs from '../../components/ProfileStateTabs/ProfileStateTabs';
import UserMenu from '../../components/UserMenu/UserMenu';
import UserImg from '../CreateTask/UI/UserImg/UserImg';
import UserMenuBackdrop from '../../components/UserMenu/UserMenuBackdrop';
import FilteredPendingTasksList from './FilteredPendingTasksList/FilteredPendingTasksList';
import PendingTaskList from './PendingTaskList/PendingTaskList';
import Loading from '../../components/status/Loading';
import NavBar from '../../components/NavBar';
import Strokes from 'components/Strokes';
// importing assets
import { searchOutline } from 'ionicons/icons';
// importing utilities
import { FirebaseHelper } from '../../helpers/FirebaseHelper';
import { getLocalStorage } from '../../helpers/localStorage/localStorageHelper';
// importing graphql
import { useQuery } from '@apollo/react-hooks';
import { GET_PENDING_JOBS } from '../../GraphQL/Job/GET_PENDING_JOBS/GET_PENDING_JOBS';
import {
    GetPendingJobsById,
    GetPendingJobsByIdVariables,
    GetPendingJobsById_pendingJobs,
} from '../../GraphQL/__generated__/GetPendingJobsById';

const PendingTasks: React.FC = () => {
    const { userId } = useParams<{ userId: string }>();
    const fuser = new FirebaseHelper(useAuth().currentUser);
    const [toggleMenu, setToggleMenu] = useState<boolean>(false); // handles hamburger menu
    const [searchInput, setSearchInput] = useState<string>('');
    const handleMenuToggle = () => setToggleMenu((prevToggle) => !prevToggle);

    const { data, loading, fetchMore } = useQuery<
        GetPendingJobsById,
        GetPendingJobsByIdVariables
    >(GET_PENDING_JOBS, { variables: { id: parseInt(userId) } });

    if (loading) {
        return <Loading />;
    }

    const activeRequestsInbound = data.pendingJobs.filter(
        (job) => job.provider.id === parseInt(userId)
    );
    const activeRequestsOutbound = data.pendingJobs.filter(
        (job) => job.recipient.id === parseInt(userId)
    );
    let activeInbound: GetPendingJobsById_pendingJobs[] = activeRequestsInbound;
    let activeOutbound: GetPendingJobsById_pendingJobs[] =
        activeRequestsOutbound;

    if (searchInput.length > 0 && !loading) {
        activeInbound = activeInbound.filter(
            (task) =>
                task.recipient.firstName.toLowerCase().includes(searchInput) || // search by recipient name
                task.recipient.lastName.toLowerCase().includes(searchInput) || // search by recipient name
                (task.recipient.firstName + ' ' + task.recipient.lastName)
                    .toLowerCase()
                    .includes(searchInput) || // search by recipient name
                task.Skill.name.toLowerCase().includes(searchInput) || // search by skill
                moment(task.endTime)
                    .tz(getLocalStorage('tz'), false)
                    .format('MMMM Do YYYY')
                    .toLowerCase()
                    .includes(searchInput)
        ); // search by date

        activeOutbound = activeOutbound.filter(
            (task) =>
                task.provider.firstName.toLowerCase().includes(searchInput) || // search by provider name
                task.provider.lastName.toLowerCase().includes(searchInput) || // search by provider name
                (task.provider.firstName + ' ' + task.provider.lastName)
                    .toLowerCase()
                    .includes(searchInput) || // search by provider name
                task.Skill.name.toLowerCase().includes(searchInput) || // search by skill
                moment(task.endTime)
                    .tz(getLocalStorage('tz'), false)
                    .format('MMMM Do YYYY')
                    .toLowerCase()
                    .includes(searchInput)
        ); // search by date
    }

    return (
        <IonPage>
            <IonContent>
                <IonContent>
                    {toggleMenu ? (
                        <IonCol className='menu__sidebar--active'>
                            <UserMenu
                                handleMenuToggle={handleMenuToggle}
                                userUid={fuser.uid}
                                // communities={fuser.organizations}
                            />
                        </IonCol>
                    ) : (
                        <IonCol className='menu__sidebar--inactive' />
                    )}
                    {toggleMenu ? (
                        <UserMenuBackdrop handleMenuToggle={handleMenuToggle} />
                    ) : null}
                    {/* pending Tasks header */}
                    <ProfileStateTabs
                        showSearch={false}
                        handleMenuToggle={handleMenuToggle}
                    />
                    <IonRow className='completedTasks__header'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='completedTasks__header--container'
                        >
                            <Strokes top={true} />
                            <UserImg />
                        </IonCol>
                    </IonRow>
                    {/* text content */}
                    <IonRow className='completedTasks__textContent'>
                        <IonCol
                            className='completedTasks__textContent--container'
                            sizeSm='12'
                            sizeXs='12'
                        >
                            <h1 className='completedTasks__textContent--title'>
                                Pending<span> Tasks</span>
                            </h1>
                        </IonCol>
                    </IonRow>
                    {/* task searchbar */}
                    <IonRow className='completedTasks__searchbar'>
                        <IonCol
                            sizeXs='12'
                            sizeSm='12'
                            className='completedTasks__searchbar--container'
                        >
                            <div className='completedTasks__searchbar--input--wrap'>
                                <IonIcon
                                    className='completedTasks__searchbar--input--icon'
                                    icon={searchOutline}
                                />
                                <input
                                    onChange={(e) =>
                                        setSearchInput(
                                            e.target.value.toLowerCase()
                                        )
                                    }
                                    className='completedTasks__searchbar--input'
                                    type='text'
                                    placeholder='Search for a task here...'
                                />
                            </div>
                        </IonCol>
                    </IonRow>
                    {/* pending Tasks list */}
                    {searchInput.length > 0 ? (
                        <FilteredPendingTasksList
                            activeInbound={activeInbound}
                            activeOutbound={activeOutbound}
                        />
                    ) : (
                        <PendingTaskList
                            activeInbound={activeInbound}
                            activeOutbound={activeOutbound}
                        />
                    )}
                </IonContent>
                <NavBar />
            </IonContent>
        </IonPage>
    );
};

export default PendingTasks;
