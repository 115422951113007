// importing modules
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
// importing components
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonLabel,
    IonPage,
    IonRow,
} from '@ionic/react';
import CompleteTaskNavTabs from '../UI/CompleteTaskTabs/CompleteTaskTabs';
import UserImg from '../UI/ProviderImg';
import CompleteTaskFooter from '../../../components/FooterWithChildren/FooterWithChildren';
import TransactionWall from '../../../components/TransactionWall/TransactionWall';
import Loading from '../../../components/status/Loading';
import Strokes from 'components/Strokes';
// importing assets
// importing helpers
import {
    setLocalStorage,
    getLocalStorage,
    updateLocalStorage,
} from '../../../helpers/localStorage/localStorageHelper';
// importing graphql
import { useQuery } from '@apollo/react-hooks';
import { GET_JOB_BY_ID } from '../../../GraphQL/Job/GET_JOB_BY_ID/GET_JOB_BY_ID';
import {
    GetJobById,
    GetJobByIdVariables,
} from '../../../GraphQL/__generated__/GetJobById';
import { Status } from '../../../GraphQL/__generated__/globalTypes';
// importing styles
import './TaskCreditsInput.css';

type CompletionTime = {
    hours: number;
    minutes: number;
};

const TaskCredits: React.FC = () => {
    const history = useHistory();
    const { taskId } = useParams<{ taskId: string }>();
    const { register, handleSubmit, errors } = useForm<CompletionTime>();
    const [mins, setMins] = useState<string>('');
    const [hrs, setHrs] = useState<string>('');

    useEffect(() => {
        if (!getLocalStorage('completeTaskViaRecipient')) {
            setLocalStorage('completeTaskViaRecipient', {
                hrs: '',
                mins: '',
                review: '',
                rating: 0,
            });
        } else {
            setHrs(getLocalStorage('completeTaskViaRecipient').hrs);
            setMins(getLocalStorage('completeTaskViaRecipient').mins);
        }
    }, []);

    const { data, loading, error } = useQuery<
        GetJobById, // query return return type (shape of data)
        GetJobByIdVariables // query request type       (shape of variables)
    >(GET_JOB_BY_ID, {
        variables: {
            jobId: parseInt(taskId),
        },
        fetchPolicy: 'no-cache',
    });

    if (loading) {
        return <Loading />;
    }

    const handleProposeCredits = () => {
        updateLocalStorage('completeTaskViaRecipient', 'hrs', hrs);
        updateLocalStorage('completeTaskViaRecipient', 'mins', mins);
        history.push(`/completeTask/recipient/${taskId}/rating`);
    };

    let transactionWallTitle: string;
    let transactionWallText: string;

    if (data.job.Status === Status.RECIPIENT_HAS_PROPOSED_OFFER) {
        transactionWallTitle =
            'You already proposed Balance Tokens for this transaction';
        transactionWallText = `Feel free to reach out to ${data.job.provider.firstName} to complete their end of the transaction.`;
    } else if (data.job.Status === Status.COMPLETED) {
        transactionWallTitle = 'This transaction has already been completed';
        transactionWallText = `Please dismiss the notification for ${data.job.Skill.category} with ${data.job.provider.firstName}.`;
    }

    return (
        <>
            {data.job.Status === Status.RECIPIENT_HAS_PROPOSED_OFFER ||
            data.job.Status === Status.COMPLETED ? (
                <TransactionWall
                    title={transactionWallTitle}
                    text={transactionWallText}
                />
            ) : (
                <IonPage>
                    <IonContent fullscreen>
                        <IonGrid>
                            <IonRow className='completeTask__header'>
                                <IonCol
                                    sizeSm='12'
                                    sizeXs='12'
                                    className='completeTask__header--container'
                                >
                                    <Strokes top={true} />
                                    <CompleteTaskNavTabs
                                        disablePrevStep={true}
                                        prevPage=''
                                    />
                                    <UserImg />
                                </IonCol>
                            </IonRow>
                            <IonRow className='completeTask__textContent'>
                                <IonCol
                                    className='completeTask__textContent--container'
                                    sizeSm='12'
                                    sizeXs='12'
                                >
                                    <h1 className='completeTask__textContent--title'>
                                        Confirm the <span>amount of time</span>{' '}
                                        the task took to finish
                                    </h1>
                                </IonCol>
                            </IonRow>
                            <IonGrid className='completeTask__form--container'>
                                <IonRow className='completeTask__form--row'>
                                    <form className='completeTask__col'>
                                        <IonCol
                                            sizeXs='6'
                                            sizeSm='6'
                                            className='completeTask__input--wrap'
                                        >
                                            <input
                                                onChange={(e) =>
                                                    setHrs(e.target.value)
                                                }
                                                name='hours'
                                                className='completeTask__input'
                                                placeholder='-'
                                                type='number'
                                                min={1}
                                                max={24}
                                                ref={register({
                                                    required:
                                                        'Hours are required',
                                                })}
                                                pattern='\d*'
                                                value={hrs}
                                            />
                                            <IonLabel className='completeTask__input--label'>
                                                Hours
                                            </IonLabel>
                                        </IonCol>
                                        <IonCol
                                            sizeXs='6'
                                            sizeSm='6'
                                            className='completeTask__input--wrap'
                                        >
                                            <input
                                                onChange={(e) =>
                                                    setMins(e.target.value)
                                                }
                                                name='minutes'
                                                className='completeTask__input'
                                                placeholder='-'
                                                type='number'
                                                min={1}
                                                max={60}
                                                ref={register({
                                                    required:
                                                        'Minutes are required',
                                                })}
                                                pattern='\d*'
                                                value={mins}
                                            />
                                            <IonLabel className='completeTask__input--label'>
                                                Minutes
                                            </IonLabel>
                                        </IonCol>
                                    </form>
                                </IonRow>
                                <p className='completeTask__textContent--text'>
                                    You can change by tapping and editing. We
                                    will confirm this time with the other person
                                    before removing credits from your account.
                                </p>
                            </IonGrid>
                        </IonGrid>
                    </IonContent>
                    <CompleteTaskFooter>
                        <IonButton
                            fill='clear'
                            disabled={!(mins.length > 0 && hrs.length > 0)}
                            color='ion-primary'
                            className='footerWithChild__btn'
                            onClick={handleProposeCredits}
                        >
                            Continue
                        </IonButton>
                    </CompleteTaskFooter>
                </IonPage>
            )}
            );
        </>
    );
};

export default TaskCredits;
