// importing modules
import React, { useState } from 'react';
import { useHistory, NavLink } from 'react-router-dom';
import moment from 'moment-timezone';
// importing components
import {
    IonCol,
    IonImg,
    IonButton,
    IonIcon,
    IonSpinner,
    IonToast,
} from '@ionic/react';
// importing assets
import { informationCircleOutline } from 'ionicons/icons';
// importing utilities
import { time_credit_conversion } from '../../../utils/timeConversion';
import { getLocalStorage } from '../../../helpers/localStorage/localStorageHelper';
// importing types
import { useMutation } from '@apollo/react-hooks';
import { GetProfileJobsPosted_profile_outgoingParticipationRequests } from '../../../GraphQL/__generated__/GetProfileJobsPosted';
import { PARTICIPATION_REQUEST_NUDGE } from '../../../GraphQL/ParticipationRequest/PARTICIPATION_REQUEST_NUDGE/PARTICIPATION_REQUEST_NUDGE';
import {
    ParticipationRequestNudge,
    ParticipationRequestNudgeVariables,
} from '../../../GraphQL/__generated__/ParticipationRequestNudge';

const ParticipationRequestItem: React.FC<{
    participationRequest: GetProfileJobsPosted_profile_outgoingParticipationRequests;
}> = ({ participationRequest }) => {
    const history = useHistory();
    const [disableClick, setDisableClick] = useState<boolean>(false);
    const [showToast, setShowToast] = useState<boolean>(false);

    const [participationRequestNudge, participationRequestNudgeDatas] =
        useMutation<
            ParticipationRequestNudge,
            ParticipationRequestNudgeVariables
        >(PARTICIPATION_REQUEST_NUDGE);

    const handleNudge = () => {
        setDisableClick(true);
        setShowToast(true);

        participationRequestNudge({
            variables: { id: participationRequest.id, nudgeWho: 'recipient' },
        })
            .then((r) => setShowToast(true))
            .catch((err) => console.error(err));
        // disable nudge button for 30secs after click
        setTimeout(() => {
            setDisableClick(false);
        }, 30000);
    };

    const startDate =
        participationRequest.skill.jobPost.startDate === null
            ? null
            : moment(participationRequest.skill.jobPost.startDate).tz(
                  getLocalStorage('tz'),
                  false
              );
    const taskStartTime =
        participationRequest.skill.jobPost.startTime === null
            ? null
            : moment(participationRequest.skill.jobPost.startTime).tz(
                  getLocalStorage('tz'),
                  false
              );
    const taskEndTime =
        participationRequest.skill.jobPost.endTime === null
            ? null
            : moment(participationRequest.skill.jobPost.endTime).tz(
                  getLocalStorage('tz'),
                  false
              );

    let taskTime;
    let btns;

    // conditionals to render component based on task time/status
    if (
        (startDate === null && taskStartTime !== null) ||
        (startDate !== null && taskStartTime === null) ||
        (startDate === null && taskStartTime === null)
    ) {
        // check if the task hasnt started
        taskTime = (
            <p className='providerItem__info--time'>
                &#x02022;&nbsp;Starts TBD
                <span>- Participating</span>
            </p>
        );
        btns = (
            <React.Fragment>
                <IonButton
                    fill='clear'
                    color='ion-primary'
                    className='providerItem__btn--send'
                >
                    Send Message
                </IonButton>
                <IonButton
                    fill='clear'
                    color='ion-primary'
                    className='providerItem__btn--cancel'
                >
                    Cancel Task
                </IonButton>
            </React.Fragment>
        );
    } else if (
        moment() < taskStartTime &&
        startDate !== null &&
        taskStartTime !== null
    ) {
        // check if the task hasnt started
        taskTime = (
            <p className='providerItem__info--time'>
                &#x02022;&nbsp;Starts {taskStartTime.fromNow()} &nbsp;{' '}
                <span>- Participating</span>
            </p>
        );
        btns = (
            <React.Fragment>
                <IonButton
                    fill='clear'
                    color='ion-primary'
                    className='providerItem__btn--send'
                >
                    Send Message
                </IonButton>
                <IonButton
                    fill='clear'
                    color='ion-primary'
                    className='providerItem__btn--cancel'
                >
                    Cancel Task
                </IonButton>
            </React.Fragment>
        );
    } else if (
        moment().isBetween(taskStartTime, taskEndTime) &&
        startDate !== null &&
        taskStartTime !== null
    ) {
        // check if the task is in progress
        taskTime = (
            <p className='providerItem__info--time'>
                &#x02022;&nbsp;Ends {taskEndTime.fromNow()} &nbsp;{' '}
                <span>- Participating</span>
            </p>
        );
        if (
            participationRequest.status.toString() ===
            'RECIPIENT_HAS_PROPOSED_OFFER'
        ) {
            btns = (
                <IonButton
                    fill='clear'
                    color='ion-primary'
                    className='completeTask__btn'
                >
                    <NavLink
                        to={{
                            pathname: `/completeTaskPosted/provider/${participationRequest.skill.jobPost.id}`,
                            state: {
                                id: participationRequest.skill.jobPost.id,
                            },
                        }}
                        className='completeTask__btn--link'
                    >
                        Complete This Task
                    </NavLink>
                </IonButton>
            );
        } else {
            btns = (
                <>
                    <div className='completeTask__btn--waiting'>
                        <IonSpinner
                            className='completeTask__btn--waiting--spinner'
                            name='crescent'
                        />
                        <p className='completeTask__btn--waiting--text'>
                            Waiting on Recipient
                        </p>
                    </div>
                    <IonButton
                        fill='clear'
                        color='ion-primary'
                        onClick={handleNudge}
                        disabled={disableClick}
                        className='completeTask__btn--nudge'
                    >
                        Nudge
                    </IonButton>
                </>
            );
        }
    } else if (
        moment() > taskEndTime &&
        startDate !== null &&
        taskEndTime !== null
    ) {
        // check if the task has ended
        taskTime = (
            <p className='providerItem__info--time'>
                &#x02022;&nbsp;Ended {taskEndTime.fromNow()} &nbsp;{' '}
                <span>- Participating</span>
            </p>
        );
        if (
            participationRequest.status.toString() ===
            'RECIPIENT_HAS_PROPOSED_OFFER'
        ) {
            btns = (
                <IonButton
                    fill='clear'
                    color='ion-primary'
                    className='completeTask__btn'
                >
                    <NavLink
                        to={{
                            pathname: `/completeTaskPosted/provider/${participationRequest.id}`,
                            state: {
                                id: participationRequest.id,
                            },
                        }}
                        className='completeTask__btn--link'
                    >
                        Complete This Task
                    </NavLink>
                </IonButton>
            );
        } else {
            btns = (
                <>
                    <div className='completeTask__btn--waiting'>
                        <IonSpinner
                            className='completeTask__btn--waiting--spinner'
                            name='crescent'
                        />
                        <p className='completeTask__btn--waiting--text'>
                            Waiting on Recipient
                        </p>
                    </div>
                    <IonButton
                        fill='clear'
                        color='ion-primary'
                        onClick={handleNudge}
                        disabled={disableClick}
                        className='completeTask__btn--nudge'
                    >
                        Nudge
                    </IonButton>
                </>
            );
        }
    }

    return (
        <IonCol className='providerItem' sizeSm='11' sizeXs='11'>
            <IonToast
                cssClass='completeTask__nudge--toast'
                isOpen={showToast}
                onDidDismiss={() => setShowToast(false)}
                message='Transaction Reminder Sent!'
                duration={2500}
            />
            <IonIcon
                onClick={() =>
                    history.push(
                        `/task/participationRequest/${participationRequest.id}`
                    )
                }
                icon={informationCircleOutline}
                className='providerItem__info--icon'
            />
            <IonCol sizeXs='12' sizeSm='12' className='providerItem__info'>
                <IonCol sizeXl='3' sizeLg='3' sizeMd='3' sizeXs='3' sizeSm='3'>
                    <div className='providerItem__img--wrap'>
                        <IonImg
                            onClick={() =>
                                history.push(
                                    `/user/${participationRequest.provider.uid}`
                                )
                            }
                            className='providerItem__img'
                            src={participationRequest.recipient.profilePicture}
                        />
                    </div>
                </IonCol>
                <IonCol
                    className='providerItem__info--wrap'
                    sizeXs='9'
                    sizeSm='9'
                >
                    <h3 className='providerItem__info--name'>
                        {participationRequest.skill.name} w/{' '}
                        {participationRequest.recipient.firstName}
                    </h3>
                    {taskTime}
                </IonCol>
            </IonCol>
            {participationRequest.status.toString() ===
                'RECIPIENT_HAS_PROPOSED_OFFER' && (
                <IonCol sizeXs='12' sizeSm='12'>
                    <p className='providerItem__info--time'>
                        <span>
                            Recipient has proposed{' '}
                            {time_credit_conversion(
                                participationRequest.recipientOfferCredits
                            )}{' '}
                            Balance Tokens
                        </span>
                    </p>
                </IonCol>
            )}
            <IonCol sizeXs='12' sizeSm='12' className='providerItem__btn--wrap'>
                {btns}
            </IonCol>
        </IonCol>
    );
};

export default ParticipationRequestItem;

// const ParticipationRequestItem: React.FC<{
//     participationRequest: GetProfileJobsPosted_profile_outgoingParticipationRequests
// }> = ({ participationRequest }) => {
//     const history = useHistory();

//     // TODO: should display what the schedule displays

//     return (
//         <IonCol className='tasksPostedItem' sizeSm='11' sizeXs='11'>
//             <IonCol sizeXs='12' sizeSm='12' className='tasksPostedItem__info'>
//                 <IonCol sizeXl='3' sizeLg='3' sizeMd='3' sizeXs='3' sizeSm='3'>
//                     <div className='tasksPostedItem__img--wrap'>
//                         <IonImg
//                             className='tasksPostedItem__img'
//                             src={participationRequest.recipient.profilePicture}
//                         />
//                     </div>
//                 </IonCol>
//                 <IonCol
//                     className='tasksPostedItem__info--wrap'
//                     sizeXs='9'
//                     sizeSm='9'
//                 >
//                     <h3 className='tasksPostedItem__info--name'>{participationRequest.skill.description} w/ {participationRequest.recipient.firstName}</h3>
//                     <p className='tasksPostedItem__info--date'>
//                         {moment(participationRequest.skill.jobPost.startTime)
//                             .tz(getLocalStorage('tz'), false)
//                             .format('dddd, MMMM Do YYYY')}
//                     </p>
//                 </IonCol>
//             </IonCol>
//             <IonCol sizeXs='12' sizeSm='12'>
//                 <p className='tasksPostedItem__info--descr'>{participationRequest.skill.additionalDetails}</p>
//             </IonCol>
//             <IonCol
//                 className='tasksPosted__item--btn--wrap'
//                 sizeSm='12'
//                 sizeXs='12'
//             >
//                 <IonButton
//                     onClick={() => history.push(`/manage/${participationRequest.skill.jobPost.id}/participation`)}
//                     color='ion-primary'
//                     fill='clear'
//                     className='tasksPosted__item--btn--manage'
//                 >
//                     Cancel
//                 </IonButton>
//                 <IonButton
//                     onClick={() => history.push(`/task/${participationRequest.skill.jobPost.id}`)}
//                     color='ion-primary'
//                     fill='clear'
//                     className='tasksPosted__item--btn--view'
//                 >
//                     View
//                 </IonButton>
//             </IonCol>
//         </IonCol>
//     );
// };
