// importing modules
import React, { useState, useEffect } from 'react';
// importing components
import { IonButton, IonCol, IonImg, IonAvatar } from '@ionic/react';
// importing utilities
import {
    addMemberInviteToLocalStorage,
    deleteMemberInviteFromLocalStorage,
} from '../../../../helpers/localStorage/localStorageHelper';
import filterCompletedTasks from '../../../../utils/filterCompletedTasks';
import capitalize from '../../../../utils/capitalize';
import genCreatedAt from '../../../../utils/genCreatedAt';
// importing types & graphq;
import { SkillPoolFromManyOrgs_profilesFromManyOrgs } from '../../../../GraphQL/__generated__/SkillPoolFromManyOrgs';

const TaskInviteMember: React.FC<{
    member: SkillPoolFromManyOrgs_profilesFromManyOrgs;
    memberInvites: { name: string; uid: string }[];
    setMemberInvites: Function;
}> = ({ member, memberInvites, setMemberInvites }) => {
    const [isChecked, setisChecked] = useState<boolean>(false);
    const completedTasksCount: number = filterCompletedTasks(
        member.upcomingJobsReceive,
        member.upcomingJobsServe
    );

    useEffect(() => {
        const memberInvited = memberInvites.find((m) => m.uid === member.uid);
        if (memberInvited) {
            setisChecked(true);
        }
    }, []);

    const handleCheckBox = (
        name: string,
        uid: string,
        checkboxStatus: boolean
    ) => {
        if (checkboxStatus === true) {
            addMemberInviteToLocalStorage('createTask', 'memberInvites', {
                name: name,
                uid: uid,
            });
            setMemberInvites((prevMembers) => [
                ...prevMembers,
                { name: name, uid: uid },
            ]);
            setisChecked(!isChecked);
        } else {
            deleteMemberInviteFromLocalStorage(
                'createTask',
                'memberInvites',
                uid
            );
            setMemberInvites(
                memberInvites.filter((member) => member.uid !== uid)
            );
            setisChecked(!isChecked);
        }
    };

    return (
        <IonCol className='taskInvite__members' sizeSm='11' sizeXs='11'>
            <IonCol
                sizeXs='12'
                sizeSm='12'
                className='taskInvite__members--info'
            >
                <IonCol sizeXl='3' sizeLg='3' sizeMd='3' sizeXs='3' sizeSm='3'>
                    <IonAvatar className='taskInvite__members--img--wrap'>
                        <IonImg
                            className='taskInvite__members--img'
                            src={member.profilePicture}
                        />
                    </IonAvatar>
                </IonCol>
                <IonCol
                    className='taskInvite__members--info--wrap'
                    sizeXs='9'
                    sizeSm='9'
                >
                    <h3 className='taskInvite__members--info--name'>
                        {capitalize(member.firstName)}{' '}
                        {capitalize(member.lastName)}
                    </h3>
                    <p className='taskInvite__members--info--reviews'>
                        {genCreatedAt(member.createdAt)} | {completedTasksCount}{' '}
                        tasks completed
                    </p>
                </IonCol>
                <input
                    className='taskInvite__members--checkbox'
                    type='checkbox'
                    value={isChecked.toString()}
                    checked={isChecked}
                    onChange={(e) =>
                        handleCheckBox(
                            `${member.firstName} ${member.lastName}`,
                            member.uid,
                            e.target.checked
                        )
                    }
                />
            </IonCol>
            <IonCol
                sizeXs='12'
                sizeSm='12'
                className='taskInvite__members--skills--wrap'
            >
                {member.skills.length > 0
                    ? member.skills.map((skill, index) => (
                          <IonButton
                              key={index}
                              color='ion-primary'
                              className='taskInvite__members--skill'
                          >
                              {skill.category}
                          </IonButton>
                      ))
                    : null}
            </IonCol>
        </IonCol>
    );
};

export default TaskInviteMember;
