import React from 'react';
import { useParams } from 'react-router-dom';
// importing components
import Loading from '../../../../components/status/Loading';
import { IonImg } from '@ionic/react';
// importing graphql
import { useQuery } from '@apollo/react-hooks';
import { GET_PARTICIPATION_REQUEST_BY_ID } from '../../../../GraphQL/ParticipationRequest/GET_PARTICIPATION_REQUEST_BY_ID/GET_PARTICIPATION_REQUEST_BY_ID';
import {
    GetParticipationRequestById,
    GetParticipationRequestByIdVariables,
} from '../../../../GraphQL/__generated__/GetParticipationRequestById';

const UserImg: React.FC = () => {
    const { taskId } = useParams<{ taskId: string }>();

    const { data, loading, error, fetchMore } = useQuery<
        GetParticipationRequestById, // query return return type (shape of data)
        GetParticipationRequestByIdVariables // query request type       (shape of variables)
    >(GET_PARTICIPATION_REQUEST_BY_ID, {
        variables: {
            id: parseInt(taskId),
        },
        fetchPolicy: 'no-cache',
    });

    if (loading) {
        return <Loading />;
    }

    return (
        <IonImg
            className='createTask__header--img'
            src={!loading && data.participationRequest.provider.profilePicture}
            alt='user'
        />
    );
};

export default UserImg;
