// importing modules
import React from 'react';
// importing components
import { IonCol } from '@ionic/react';

const LocationItem: React.FC<{
    handleLocationPreference: Function;
    locationPref: string;
    location: string;
    text: string;
    description: string;
}> = ({
    handleLocationPreference,
    locationPref,
    location,
    description,
    text,
}) => {
    return (
        <IonCol
            onClick={() => handleLocationPreference(location)}
            className='locationModal__col'
            sizeXs='12'
            sizeSm='12'
        >
            <IonCol
                sizeXs='12'
                sizeSm='12'
                className={
                    locationPref === location
                        ? 'ion-padding locationModal__content locationModal__content--active'
                        : 'ion-padding locationModal__content'
                }
            >
                <p
                    className={
                        locationPref === location
                            ? 'locationModal__content--skill locationModal__content--skill--active'
                            : 'locationModal__content--skill'
                    }
                >
                    {text}
                </p>
                <p
                    className={
                        locationPref === location
                            ? 'locationModal__content--text locationModal__content--text--active'
                            : 'locationModal__content--text'
                    }
                >
                    {description}
                </p>
            </IonCol>
        </IonCol>
    );
};

export default LocationItem;
