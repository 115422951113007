// importing modules
import React from 'react';
import { useAuth } from 'context/firebase/authContext';
// importing components
import {
    IonCol,
    IonItem,
    IonItemOption,
    IonItemOptions,
    IonItemSliding,
    IonIcon,
} from '@ionic/react';
// importing assets
import { trashOutline } from 'ionicons/icons';
// importing graphql
import { useMutation } from '@apollo/react-hooks';
import { SkillsById_profile_skills } from '../../../GraphQL/__generated__/SkillsById';
import { USER_EDIT_PROFILE_DATA } from '../../../GraphQL/Profile/EditUserProfile/editUserProfile';
import { DELETE_ONE_SKILL } from '../../../GraphQL/Skill/DeleteOneSkill';
import {
    DeleteOneSkill,
    DeleteOneSkillVariables,
} from '../../../GraphQL/__generated__/DeleteOneSkill';

interface extendMe extends SkillsById_profile_skills {
    skillfullPersonsName: string;
    setSkillId: Function;
    setSkillName: Function;
    setSkillDescription: Function;
    handleSkillModal: Function;
}

const SkillConfirmItem: React.FC<extendMe> = ({
    setSkillId,
    setSkillName,
    setSkillDescription,
    handleSkillModal,
    ...skill
}) => {
    const { currentUser: user } = useAuth();
    const [deleteOneSkill, deleteOneSkillDatas] = useMutation<
        DeleteOneSkill,
        DeleteOneSkillVariables
    >(DELETE_ONE_SKILL);

    const handleDeleteSkill = (id: number) => {
        deleteOneSkill({
            variables: {
                id: id,
            },
            refetchQueries: [
                {
                    query: USER_EDIT_PROFILE_DATA,
                    variables: {
                        uid: user.uid,
                    },
                },
            ],
        });
    };

    return (
        <IonItemSliding key={skill.id} className='skillConfirm__item'>
            <IonItemOptions side='end'>
                <IonItemOption
                    className='homeEdit__delete--btn'
                    onClick={() => handleDeleteSkill(skill.id)}
                >
                    <IonIcon icon={trashOutline} slot='icon-only' />
                </IonItemOption>
            </IonItemOptions>
            <IonItem lines='none' className='homeEdit__content--slide--wrap'>
                <IonCol
                    sizeXs='9'
                    sizeSm='9'
                    className='skillConfirm__content ion-padding-vertical'
                >
                    <p className='skillConfirm__content--keyword'>
                        {skill.category}
                    </p>
                    <p className='skillConfirm__content--skill'>{skill.name}</p>
                    <p className='skillConfirm__content--text'>
                        {skill.description.length > 0
                            ? skill.description
                            : 'Description not available...'}
                    </p>
                </IonCol>
                <IonCol
                    onClick={() => {
                        setSkillId(skill.id);
                        setSkillName(skill.name);
                        setSkillDescription(skill.description);
                        handleSkillModal();
                    }}
                    sizeXs='3'
                    sizeSm='3'
                    className='skillConfirm__request--btn'
                >
                    <p className='skillConfirm__request--btn--text'>Edit</p>
                    <p className='skillConfirm__request--btn--name'>Skill</p>
                </IonCol>
            </IonItem>
        </IonItemSliding>
    );
};

export default SkillConfirmItem;
