// importing modules
import React from 'react';
import { useHistory } from 'react-router';
import moment from 'moment-timezone';
// importing components
import { IonCol, IonImg, IonButton } from '@ionic/react';
// importing utilities
import { getLocalStorage } from '../../../helpers/localStorage/localStorageHelper';
import { time_credit_conversion } from '../../../utils/timeConversion';
// importing types
import { GetProfileJobsPosted_profile_outgoingParticipationRequests } from '../../../GraphQL/__generated__/GetProfileJobsPosted';

const ParticipationRequestItem: React.FC<{
    participationRequest: GetProfileJobsPosted_profile_outgoingParticipationRequests;
}> = ({ participationRequest }) => {
    const history = useHistory();

    return (
        <IonCol className='tasksPostedItem' sizeSm='11' sizeXs='11'>
            <IonCol sizeXs='12' sizeSm='12' className='tasksPostedItem__info'>
                <IonCol sizeXl='3' sizeLg='3' sizeMd='3' sizeXs='3' sizeSm='3'>
                    <div className='tasksPostedItem__img--wrap'>
                        <IonImg
                            className='tasksPostedItem__img'
                            src={participationRequest.recipient.profilePicture}
                        />
                    </div>
                </IonCol>
                <IonCol
                    className='tasksPostedItem__info--wrap'
                    sizeXs='9'
                    sizeSm='9'
                >
                    <h3 className='tasksPostedItem__info--name'>
                        {participationRequest.skill.description} w/{' '}
                        {participationRequest.recipient.firstName}
                    </h3>
                    <p className='tasksPostedItem__info--date'>
                        {moment(participationRequest.skill.jobPost.startTime)
                            .tz(getLocalStorage('tz'), false)
                            .format('dddd, MMMM Do YYYY')}
                    </p>
                    <p className='tasksPostedItem__info--credits--earned'>
                        +{time_credit_conversion(participationRequest.credits)}
                    </p>
                </IonCol>
            </IonCol>
            <IonCol sizeXs='12' sizeSm='12'>
                <p className='tasksPostedItem__info--descr'>
                    {participationRequest.skill.additionalDetails}
                </p>
            </IonCol>
            <IonCol
                className='tasksPosted__item--btn--wrap'
                sizeSm='12'
                sizeXs='12'
            >
                {/* <IonButton
                    onClick={() => history.push(`/manage/${participationRequest.skill.jobPost.id}/participation`)}
                    color='ion-primary'
                    fill='clear'
                    className='tasksPosted__item--btn--manage'
                >
                    Cancel
                </IonButton> */}
                <IonButton
                    onClick={() =>
                        history.push(
                            `/task/${participationRequest.skill.jobPost.id}`
                        )
                    }
                    color='ion-primary'
                    fill='clear'
                    className='tasksPosted__item--completed--btn--view'
                >
                    View
                </IonButton>
            </IonCol>
        </IonCol>
    );
};

export default ParticipationRequestItem;
