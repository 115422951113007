// importing modules
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Redirect, useHistory, useParams } from 'react-router-dom';
// importing components
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonLabel,
    IonPage,
    IonRow,
} from '@ionic/react';
import CompleteTaskNavTabs from '../UI/CompleteTaskTabs/CompleteTaskTabs';
import CompleteTaskFooter from '../../../components/FooterWithChildren/FooterWithChildren';
import TransactionWall from '../../../components/TransactionWall/TransactionWall';
import Loading from '../../../components/status/Loading';
import Strokes from 'components/Strokes';
// importing assets
// importing helpers
import {
    setLocalStorage,
    getLocalStorage,
    updateLocalStorage,
} from '../../../helpers/localStorage/localStorageHelper';
// importing types & graphql
import { useQuery } from '@apollo/react-hooks';
import { GET_JOB_POST_BY_ID } from '../../../GraphQL/JobPost/GET_JOB_POST_BY_ID/GET_JOB_POST_BY_ID';
import {
    GetJobPostById,
    GetJobPostByIdVariables,
} from '../../../GraphQL/__generated__/GetJobPostById';
import { Status } from '../../../GraphQL/__generated__/globalTypes';
// importing styles
import './TaskCreditsInput.css';
import { useAuth } from '../../../context/firebase/authContext';
import { ADMIN_GROUP_PROFILE_PICTURE } from 'GraphQL/Admin/adminGroupProfilePicture/adminGroupProfilePicture';

type CompletionTime = {
    hours: number;
    minutes: number;
};

const TaskPostedCredits: React.FC = () => {
    const { currentUser } = useAuth();
    const history = useHistory();
    const { taskId } = useParams<{ taskId: string }>();
    const { register, handleSubmit, errors } = useForm<CompletionTime>();
    const [mins, setMins] = useState<string>('');
    const [hrs, setHrs] = useState<string>('');

    const { data, loading } = useQuery<GetJobPostById, GetJobPostByIdVariables>(
        GET_JOB_POST_BY_ID,
        {
            variables: { jobPostId: parseInt(taskId) },
            fetchPolicy: 'cache-and-network',
        }
    );
    const { data: adminData, loading: adminLoading } = useQuery(
        ADMIN_GROUP_PROFILE_PICTURE,
        {
            variables: { adminId: data?.jobPost.adminId },
        }
    );

    useEffect(() => {
        if (!getLocalStorage('completeTaskPostedViaRecipient')) {
            setLocalStorage('completeTaskPostedViaRecipient', {
                hrs: '',
                mins: '',
                participants: [],
            });
        } else {
            setHrs(getLocalStorage('completeTaskPostedViaRecipient').hrs);
            setMins(getLocalStorage('completeTaskPostedViaRecipient').mins);
        }
    }, []);

    if (loading) {
        return <Loading />;
    }

    // current user uid has to match the jobpost poster's uid
    if (currentUser.uid != data.jobPost.recipient.uid) {
        return <Redirect to='/' />;
    }

    const handleProposeCredits = () => {
        const currentParticipants = [];
        data.jobPost.skillsNeeded.forEach((skill) => {
            skill.participationRequests.forEach((p) => {
                if (p.status === Status.ACCEPTED) {
                    currentParticipants.push({
                        ...p.provider,
                        hrs: hrs,
                        mins: mins,
                        rating: 0,
                        review: '',
                        status: p.status,
                        skill: skill.name,
                        participationReqId: p.id,
                    });
                }
            });
        });
        updateLocalStorage('completeTaskPostedViaRecipient', 'hrs', hrs);
        updateLocalStorage('completeTaskPostedViaRecipient', 'mins', mins);
        updateLocalStorage(
            'completeTaskPostedViaRecipient',
            'participants',
            currentParticipants
        );
        history.push(`/completeTaskPosted/recipient/${taskId}/participants`);
    };

    let transactionWallTitle: string;
    let transactionWallText: string;

    if (data.jobPost.status === Status.RECIPIENT_HAS_PROPOSED_OFFER) {
        transactionWallTitle =
            'You already proposed Balance Tokens for this transaction';
        transactionWallText = `Feel free to reach out to the participants to complete their end of the transaction.`;
    } else if (data.jobPost.status === Status.COMPLETED) {
        transactionWallTitle = 'This transaction has already been completed';
        transactionWallText = `Please dismiss the notification for ${data.jobPost.title}.`;
    }

    return (
        <>
            {data.jobPost.status === Status.RECIPIENT_HAS_PROPOSED_OFFER ||
            data.jobPost.status === Status.COMPLETED ? (
                <TransactionWall
                    title={transactionWallTitle}
                    text={transactionWallText}
                />
            ) : (
                <IonPage>
                    <IonContent>
                        <IonGrid>
                            <IonRow className='completeTask__header'>
                                <IonCol
                                    sizeSm='12'
                                    sizeXs='12'
                                    className='completeTask__header--container'
                                >
                                    <Strokes top={true} />
                                    <CompleteTaskNavTabs
                                        disablePrevStep={true}
                                        prevPage=''
                                    />
                                    <IonImg
                                        className='createTask__header--img'
                                        src={
                                            data.jobPost.adminId
                                                ? adminLoading
                                                    ? null
                                                    : adminData
                                                          .AdminGroupProfilePicture
                                                          .orgPicture
                                                : data.jobPost.recipient
                                                      .profilePicture
                                        }
                                        alt='user'
                                    />
                                </IonCol>
                            </IonRow>
                            <IonRow className='completeTask__textContent'>
                                <IonCol
                                    className='completeTask__textContent--container'
                                    sizeSm='12'
                                    sizeXs='12'
                                >
                                    <h1 className='completeTask__textContent--title'>
                                        Confirm the <span>amount of time</span>{' '}
                                        the task took to finish
                                    </h1>
                                </IonCol>
                            </IonRow>
                            <IonGrid className='completeTask__form--container'>
                                <IonRow className='completeTask__form--row'>
                                    <form className='completeTask__col'>
                                        <IonCol
                                            sizeXs='6'
                                            sizeSm='6'
                                            className='completeTask__input--wrap'
                                        >
                                            <input
                                                onChange={(e) =>
                                                    setHrs(e.target.value)
                                                }
                                                name='hours'
                                                className='completeTask__input'
                                                placeholder='-'
                                                type='number'
                                                min={1}
                                                max={24}
                                                ref={register({
                                                    required:
                                                        'Hours are required',
                                                })}
                                                pattern='\d*'
                                                value={hrs}
                                            />
                                            <IonLabel className='completeTask__input--label'>
                                                Hours
                                            </IonLabel>
                                        </IonCol>
                                        <IonCol
                                            sizeXs='6'
                                            sizeSm='6'
                                            className='completeTask__input--wrap'
                                        >
                                            <input
                                                onChange={(e) =>
                                                    setMins(e.target.value)
                                                }
                                                name='minutes'
                                                className='completeTask__input'
                                                placeholder='-'
                                                type='number'
                                                min={1}
                                                max={60}
                                                ref={register({
                                                    required:
                                                        'Minutes are required',
                                                })}
                                                pattern='\d*'
                                                value={mins}
                                            />
                                            <IonLabel className='completeTask__input--label'>
                                                Minutes
                                            </IonLabel>
                                        </IonCol>
                                    </form>
                                </IonRow>
                                <p className='completeTask__textContent--text'>
                                    You can change by tapping and editing. We
                                    will confirm this time with the other person
                                    before removing credits from your account.
                                </p>
                            </IonGrid>
                        </IonGrid>
                    </IonContent>
                    <CompleteTaskFooter>
                        <IonButton
                            fill='clear'
                            disabled={!(mins.length > 0 && hrs.length > 0)}
                            color='ion-primary'
                            onClick={handleProposeCredits}
                            className='footerWithChild__btn'
                        >
                            Continue
                        </IonButton>
                    </CompleteTaskFooter>
                </IonPage>
            )}
        </>
    );
};

export default TaskPostedCredits;
