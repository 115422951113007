// importing modules
import React, { useState, useEffect } from 'react';
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonLabel,
    IonRow,
    IonIcon,
    IonModal,
} from '@ionic/react';
import { useHistory } from 'react-router-dom';
// importing components
import UserImg from '../UI/UserImg/UserImg';
import GroupImg from '../UI/GroupImg/GroupImg';
import Strokes from 'components/Strokes';
// importing assets
import { arrowBackOutline, closeOutline } from 'ionicons/icons';
// importing helpers
import {
    getLocalStorage,
    updateLocalStorage,
    setLocalStorage,
} from '../../../helpers/localStorage/localStorageHelper';
// importing styles
import '../TaskDescription/TaskDescription';

const DescribeTaskModal: React.FC<{
    setTskName: Function;
    setTskDescr: Function;
    modalStatus: boolean;
    handleModal: () => void;
    taskType: string;
}> = ({ handleModal, modalStatus, setTskName, setTskDescr, taskType }) => {
    const history = useHistory();
    const [taskName, setTaskName] = useState<string>('');
    const [taskDescr, setTaskDescr] = useState<string>('');

    useEffect(() => {
        if (getLocalStorage('createTask')) {
            const createTaskObj = getLocalStorage('createTask');
            setTaskName(createTaskObj.taskName);
            setTaskDescr(createTaskObj.taskDescr);
        }
    }, []);

    const handleUpdateTaskDescription = () => {
        setTskName(taskName);
        setTskDescr(taskDescr);
        updateLocalStorage('createTask', 'taskName', taskName);
        updateLocalStorage('createTask', 'taskDescr', taskDescr);
    };

    let img;
    if (taskType === 'HOME' && getLocalStorage('currentAdminGroupId') == 0) {
        img = <UserImg />;
    } else if (
        taskType === 'GROUP' &&
        getLocalStorage('currentAdminGroupId') == 0
    ) {
        img = <UserImg />;
    } else {
        img = <GroupImg />;
    }

    return (
        <IonModal isOpen={modalStatus}>
            <IonContent color='ion-secondary' className='describeTask__page'>
                <IonGrid className='describeTask__page'>
                    <IonRow className='createTask__header'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='createTask__header--container'
                        >
                            <Strokes top={true} />
                            <IonCol
                                sizeXs='12'
                                sizeSm='12'
                                sizeMd='12'
                                className='completeTaskNav__btns--wrap'
                            >
                                <IonButton
                                    className='completeTaskNav__btn'
                                    onClick={handleModal}
                                    fill='clear'
                                    color='ion-primary'
                                >
                                    <IonIcon
                                        className='completeTaskNav__btn--icon'
                                        icon={arrowBackOutline}
                                    />
                                </IonButton>
                                <IonButton
                                    className='completeTaskNav__btn'
                                    onClick={() => {
                                        handleModal();
                                        const createTask = {
                                            taskName: '',
                                            taskDescr: '',
                                            date: null,
                                            startTime: '',
                                            endTime: '',
                                            location: '',
                                            locationPreference: '',
                                            skillsNeeded: [],
                                            groupCodes: [],
                                            memberInvites: [],
                                        };
                                        setLocalStorage(
                                            'createTask',
                                            createTask
                                        );
                                        history.push('/home');
                                    }}
                                    color='ion-primary'
                                    fill='clear'
                                >
                                    <IonIcon
                                        className='completeTaskNav__btn--icon'
                                        icon={closeOutline}
                                    />
                                </IonButton>
                            </IonCol>
                            {img}
                        </IonCol>
                    </IonRow>
                    {/* text content */}
                    <IonRow className='createTask__textContent'>
                        <IonCol
                            className='createTask__textContent--container'
                            sizeSm='12'
                            sizeXs='12'
                        >
                            <h1 className='createTask__textContent--title'>
                                <span>Describe</span> your task
                            </h1>
                            <p className='createTask__textContent--text'>
                                Once posted, this request will live in the Earn
                                section of your home screen.
                            </p>
                        </IonCol>
                    </IonRow>
                    {/* skill description */}
                    <IonGrid className='createTask__form--container'>
                        <IonRow className='createTask__form--row'>
                            <IonCol
                                className='createTask__form--input--wrap'
                                sizeXs='12'
                                sizeSm='12'
                            >
                                <IonLabel className='createTask__form--label'>
                                    Task Name
                                </IonLabel>
                                <input
                                    onChange={(event) =>
                                        setTaskName(event.target.value)
                                    }
                                    value={taskName}
                                    className='createTask__form--input'
                                    placeholder='Ex: Need help moving...'
                                    type='text'
                                    required
                                />
                            </IonCol>
                            <IonCol
                                className='createTask__form--input--wrap'
                                sizeXs='12'
                                sizeSm='12'
                            >
                                <IonLabel className='createTask__form--label'>
                                    Task Description
                                </IonLabel>
                                <textarea
                                    onChange={(event) =>
                                        setTaskDescr(event.target.value)
                                    }
                                    value={taskDescr}
                                    className='createTask__form--textarea'
                                    placeholder='Ex: would love some help, moving saturday...'
                                    required
                                ></textarea>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                    {/* btn */}
                    <IonRow className='createTask__btn--container'>
                        <IonCol
                            className='createTask__btn--wrap'
                            sizeXs='12'
                            sizeSm='12'
                        >
                            <IonButton
                                fill='clear'
                                disabled={
                                    !(
                                        taskName.length > 0 &&
                                        taskDescr.length > 0
                                    )
                                }
                                color='ion-primary'
                                className='createTask__btn'
                                onClick={() => {
                                    handleUpdateTaskDescription();
                                    handleModal();
                                }}
                            >
                                Update Description
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonModal>
    );
};

export default DescribeTaskModal;
