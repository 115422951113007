// importing modules
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

// importing components
import {
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonModal,
    IonRow,
} from '@ionic/react';
import BackButton from 'components/BackButton';
import Strokes from 'components/Strokes';
// importing assets
// importing styles
import './LegalScreens.css';

interface Props {
    showModal: boolean;
    setModal: Function;
}

const TermsOfUseModal: React.FC<Props> = ({ showModal, setModal }) => {
    const history = useHistory();

    return (
        <IonModal backdropDismiss={false} isOpen={showModal}>
            <IonContent>
                <Strokes top={true} green={false} />
                <IonGrid>
                    <BackButton onClick={() => setModal(false)} />
                    <div
                        style={{
                            height: '6rem',
                        }}
                    />
                    <IonRow className='termsOfUse__title--container'>
                        <IonCol
                            className='about__title--wrap'
                            sizeXs='12'
                            sizeSm='12'
                        >
                            <h1 className='about__title'>
                                Terms of <span>Use</span>
                            </h1>
                        </IonCol>
                    </IonRow>
                    <IonGrid className='about__manifesto--container'>
                        <IonRow className='about__manifesto--wrap'>
                            <IonCol
                                sizeXs='12'
                                sizeSm='12'
                                className='about__manifesto--content'
                            >
                                <h1 className='YING__title'>YING</h1>
                                <h1 className='Terms__title'>Terms of Use</h1>
                                <h1 className='Update__title'>
                                    Last Updated Date: June 01, 2021
                                </h1>
                                <div className='.terms--content'>
                                    <p>
                                        Welcome to YING! This agreement
                                        (“Agreement”) describes your rights and
                                        responsibilities in your use of YING’s
                                        website
                                        <span>
                                            <a href='http://www.yingme.co'>
                                                (www.yingme.co)
                                            </a>
                                        </span>
                                        , mobile apps
                                        <span>
                                            <a href='http://yingme.app'>
                                                (yingme.app)
                                            </a>
                                        </span>
                                        , and service, both as a visitor and as
                                        a registered YING member, and what you
                                        can expect from us. By clicking “I
                                        accept” or by otherwise indicating that
                                        you agree, you are agreeing to these
                                        terms, so please take a moment to read
                                        this legally binding agreement.
                                    </p>
                                    <p>
                                        YING is a skill sharing community. With
                                        time moving at the speed of life and
                                        death, we seek joy in passing seconds.
                                        We believe in the rising stock of the
                                        human equity. We implore the laws of
                                        abundance and stoke the fire of
                                        continuous reciprocity. We believe in
                                        the art of creating our stories and our
                                        relationships. We believe in the
                                        richness of reliability and trust; in
                                        the elegance of the helping hand. We
                                        believe in the banking of our time. We
                                        believe in rising together, in upholding
                                        the traditions of the
                                        village;&nbsp;&nbsp; in the innate
                                        dignity of communal success, where
                                        neighborhoods leave no neighbor without.
                                        We believe in celebration of harmony,
                                        understanding there are no throwaway
                                        people: that each of us is endowed with
                                        gifts and needs for services to exchange
                                        with purpose and kindness. At YING, your
                                        self worth will always come before your
                                        net worth. YING: the currency for
                                        humankind.
                                    </p>
                                    <p>
                                        PLEASE READ THIS TERMS OF USE AGREEMENT
                                        (THE “<b>TERMS OF USE</b>” OR{' '}
                                        <b>TERMS</b>) CAREFULLY. BY ACCESSING OR
                                        USING THE YING WEBSITE AND OUR YING APP
                                        OR ANY OTHER SERVICES OF YING, INC. (
                                        <b>YING</b> OR “<b>WE</b>” OR “<b>US</b>
                                        ”), ITS AFFILIATES OR AGENTS
                                        (COLLECTIVELY, THE “<b>WEBSITE</b>”) IN
                                        ANY WAY, INCLUDING USING THE SERVICES
                                        AND RESOURCES AVAILABLE OR ENABLED VIA
                                        THE WEBSITE OUR IOS APPLICATION, AND OUR
                                        ANDROID APPLICATION (EACH A “
                                        <b>SERVICE</b>” AND COLLECTIVELY, THE “
                                        <b>SERVICES</b>”) BY YING OR USERS OF
                                        THE SERVICES (“<b>USERS</b>”), CLICKING
                                        ON THE “I ACCEPT” CHECKBOX, COMPLETING
                                        THE REGISTRATION PROCESS, AND/OR
                                        BROWSING THE WEBSITE YOU REPRESENT THAT
                                        (1) YOU HAVE READ, UNDERSTAND, AND AGREE
                                        TO BE BOUND BY THE TERMS OF USE, (2) YOU
                                        ARE OF LEGAL AGE TO FORM A BINDING
                                        CONTRACT WITH YING, AND (3) YOU HAVE THE
                                        AUTHORITY TO ENTER INTO THE TERMS OF USE
                                        PERSONALLY OR ON BEHALF OF THE COMPANY
                                        YOU HAVE NAMED AS THE USER, AND TO BIND
                                        THAT COMPANY TO THE TERMS OF USE. THE
                                        TERM "<b>YOU</b>" REFERS TO THE
                                        INDIVIDUAL OR LEGAL ENTITY, AS
                                        APPLICABLE, IDENTIFIED AS THE USER WHEN
                                        YOU REGISTERED ON THE WEBSITE.{' '}
                                        <b>
                                            IF YOU DO NOT AGREE TO BE BOUND BY
                                            THE TERMS OF USE, YOU MAY NOT ACCESS
                                            OR USE THE SERVICES.
                                        </b>
                                    </p>
                                    <p>
                                        <b>
                                            THE TERMS OF USE INCLUDE A CLASS
                                            ACTION WAIVER AND A WAIVER OF JURY
                                            TRIALS, AND REQUIRE BINDING
                                            ARBITRATION ON AN INDIVIDUAL BASIS
                                            TO RESOLVE DISPUTES RELATING IN ANY
                                            WAY TO YOUR USE OF THE WEBSITE, OR
                                            ANY PAYMENT DISPUTES BETWEEN YOU AND
                                            ANOTHER USER OF THE WEBSITE. THE
                                            TERMS OF USE LIMIT THE REMEDIES THAT
                                            MAY BE AVAILABLE TO YOU IN THE EVENT
                                            OF A DISPUTE. BY ENTERING INTO THESE
                                            TERMS, YOU ARE WAIVING YOUR RIGHT TO
                                            SEEK RELIEF IN A COURT OF LAW.
                                            PLEASE SEE SECTION 18.7 BELOW FOR
                                            MORE INFORMATION REGARDING
                                            ARBITRATION AND HOW IT DIFFERS FROM
                                            A PROCEEDING IN A COURT OF LAW.{' '}
                                        </b>
                                    </p>
                                    <p>
                                        PLEASE NOTE THAT THE TERMS ARE SUBJECT
                                        TO CHANGE BY YING IN ITS SOLE DISCRETION
                                        AT ANY TIME. When changes are made, YING
                                        will make a new copy of the Terms of Use
                                        available at Yingme.co and on our Ying
                                        APP. We will also update the “Last
                                        Updated” date at the top of the Terms of
                                        Use. If we make any material changes,
                                        and you have registered with us to
                                        create an Account (as defined in Section
                                        4.1 below), we will also send an email
                                        to you at the last email address you
                                        provided to us pursuant to the
                                        Terms.&nbsp; Any changes to the Terms
                                        will be effective immediately for new
                                        Users of the Website and the Services
                                        (as these terms are defined herein)
                                        (collectively, the “
                                        <b>Our Properties</b>”) and will be
                                        effective thirty (30) days after posting
                                        notice of such changes on the Website
                                        for existing Users, provided that any
                                        material changes shall be effective for
                                        Users who have an Account with us upon
                                        the earlier of thirty (30) days after
                                        posting notice of such changes on the
                                        Website or thirty (30) days after
                                        dispatch of an email notice of such
                                        changes to Registered Users (defined in
                                        Section 4.1 below). YING may require you
                                        to provide consent to the updated Terms
                                        in a specified manner before further use
                                        of Our Properties is permitted. If you
                                        do not agree to any change(s) after
                                        receiving a notice of such change(s),
                                        you shall stop using Our Properties.
                                        Otherwise, your continued use of the
                                        Website and/or Services constitutes your
                                        acceptance of such change(s). PLEASE
                                        REGULARLY CHECK YINGME.CO AND/OR YING
                                        APP TO VIEW THE THEN-CURRENT TERMS.
                                    </p>
                                    <ol>
                                        <li>
                                            <p>
                                                <b>Services.</b>&nbsp;&nbsp;
                                                YING provides a community that
                                                brings together individuals
                                                seeking the performance of
                                                certain requested services on
                                                their behalf, and individuals
                                                seeking to advertise and provide
                                                certain services, to enable such
                                                individuals to offer, request,
                                                connect, identify, and obtain or
                                                perform such services (“
                                                <b>Opportunities</b>").
                                            </p>
                                            <p>
                                                <b>1.1.</b>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <b>Opportunities.</b>&nbsp; Our
                                                Services enable our users to
                                                create and post Opportunities on
                                                our Ying App. Opportunities
                                                require users to exchange
                                                Balance Tokens (as defined
                                                below) to receive such
                                                Opportunities.
                                            </p>
                                        </li>
                                        <li>
                                            <b>YING Provides Opportunities.</b>{' '}
                                            &nbsp;&nbsp; YING will serve our
                                            users personally curated
                                            opportunities to earn Balance Tokens
                                            and spend them to receive services
                                            from other members in the
                                            community–all based on the
                                            preferences the user listed on the
                                            user’s registration. While YING may
                                            provide recommended Balance Tokens
                                            and guidance in our Services, such
                                            information is solely informational.
                                            We do not take part in the
                                            interaction between Users. We do not
                                            have control over the quality,
                                            timing, legality, failure to
                                            provide, or any aspect whatsoever of
                                            any work product provided by Users
                                            or ratings provided by Users,
                                            Projects or Opportunities posted by
                                            Ying members, or of the integrity,
                                            responsibility, or any actions of
                                            any Users. YING makes no
                                            representations about the
                                            suitability, reliability, timeliness
                                            or accuracy in public, private or
                                            offline interactions. When
                                            interacting with other Users you
                                            should exercise caution and common
                                            sense to protect your personal
                                            safety and property, just as you
                                            would when interacting with other
                                            persons whom you don't know. NEITHER
                                            YING NOR ITS AFFILIATES OR LICENSORS
                                            IS RESPONSIBLE FOR THE CONDUCT,
                                            WHETHER ONLINE OR OFFLINE, OF ANY
                                            USER OF THE SERVICES. YING AND ITS
                                            AFFILIATES AND LICENSORS WILL NOT BE
                                            LIABLE FOR ANY CLAIM, INJURY OR
                                            DAMAGE ARISING IN CONNECTION WITH
                                            YOUR USE OF OUR PROPERTIES.
                                        </li>
                                        <br></br>
                                        <li>
                                            <b>
                                                Use of the Services and Our
                                                Properties.
                                            </b>
                                            &nbsp;&nbsp; Our Properties and the
                                            information and content available on
                                            Our Properties are protected by
                                            copyright laws throughout the world.
                                            <br></br>
                                            <br></br>
                                            <b>3.1.</b>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>
                                                <u>Certain Restrictions.</u>
                                            </b>{' '}
                                            &nbsp;&nbsp;The rights granted to
                                            you in the Terms are subject to the
                                            following restrictions: (a) you
                                            shall not license, sell, rent,
                                            lease, transfer, assign, reproduce,
                                            distribute, host or otherwise
                                            commercially exploit Our Properties
                                            or any portion of Our Properties,
                                            including the Website, (b) you shall
                                            not frame or utilize framing
                                            techniques to enclose any trademark,
                                            logo, or other of Our Properties
                                            (including images, text, page layout
                                            or form) of YING; (c) you shall not
                                            use any metatags or other “hidden
                                            text” using YING’s name or
                                            trademarks; (d) you shall not
                                            modify, translate, adapt, merge,
                                            make derivative works of,
                                            disassemble, decompile, reverse
                                            compile or reverse engineer any part
                                            of Our Properties except to the
                                            extent the foregoing restrictions
                                            are expressly prohibited by
                                            applicable law; (e) you shall not
                                            use any manual or automated
                                            software, devices or other processes
                                            (including but not limited to
                                            spiders, robots, scrapers, crawlers,
                                            avatars, data mining tools or the
                                            like) to “scrape” or download data
                                            from any web pages contained in the
                                            Website (except that we grant the
                                            operators of public search engines
                                            revocable permission to use spiders
                                            to copy materials from the Website
                                            for the sole purpose of and solely
                                            to the extent necessary for creating
                                            publicly available searchable
                                            indices of the materials, but not
                                            caches or archives of such
                                            materials); (f) access Our
                                            Properties in order to build a
                                            similar or competitive website or
                                            service; (g) except as expressly
                                            stated herein, no part of Our
                                            Properties may be copied,
                                            reproduced, distributed,
                                            republished, downloaded, displayed,
                                            posted or transmitted in any form or
                                            by any means; and (h) you shall not
                                            remove or destroy any copyright
                                            notices or other proprietary
                                            markings contained on or in Our
                                            Properties.
                                            <br></br>
                                            <br></br>
                                            <b>3.2.</b>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>
                                                <u>Third-Party Materials.</u>
                                            </b>{' '}
                                            &nbsp;&nbsp;As a part of Our
                                            Properties, you may have access to
                                            materials that are hosted by another
                                            party. You agree that it is
                                            impossible for YING to monitor such
                                            materials and that you access these
                                            materials at your own risk.
                                        </li>
                                        <br></br>
                                        <li>
                                            <b>Registration.</b> <br></br>
                                            <br></br>
                                            <b>4.1.</b>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>
                                                <u>Registering Your Account.</u>
                                            </b>
                                            &nbsp;&nbsp; In order to access Our
                                            Properties you may be required to
                                            become a Registered User. For
                                            purposes of the Terms, a “
                                            <b>Registered User</b>” is a User
                                            who has registered an account on our
                                            Ying App (“<b>Account”</b>).
                                            <br></br>
                                            <br></br>
                                            <b>4.2.</b>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>
                                                <u>Registration Data.</u>
                                            </b>
                                            &nbsp;&nbsp; In registering for the
                                            Services, you agree to (1) provide
                                            true, accurate, current and complete
                                            information about yourself as
                                            prompted by the Services’
                                            registration form (the “
                                            <b>Registration Data</b>”); and (2)
                                            maintain and promptly update the
                                            Registration Data to keep it true,
                                            accurate, current and complete. You
                                            represent that you are (1) at least
                                            eighteen (18+) years old; (2) of
                                            legal age to form a binding
                                            contract; and (3) not a person
                                            barred from using Our Properties
                                            under the laws of the United States,
                                            your place of residence or any other
                                            applicable jurisdiction. You are
                                            responsible for all activities that
                                            occur under your Account. You may
                                            not share your Account or password
                                            with anyone, and you agree to (1)
                                            notify YING immediately of any
                                            unauthorized use of your password or
                                            any other breach of security; and
                                            (2) exit from your Account at the
                                            end of each session. If you provide
                                            any information that is untrue,
                                            inaccurate, not current or
                                            incomplete, or YING has reasonable
                                            grounds to suspect that such
                                            information is untrue, inaccurate,
                                            not current or incomplete, YING has
                                            the right to suspend or terminate
                                            your Account and refuse any and all
                                            current or future use of Our
                                            Properties (or any portion thereof).
                                            You agree not to create an Account
                                            using a false identity or
                                            information, or on behalf of someone
                                            other than yourself. YING reserves
                                            the right to remove or reclaim any
                                            usernames at any time and for any
                                            reason, including but not limited
                                            to, claims by a third party that a
                                            username violates the third party’s
                                            rights. You agree not to create an
                                            Account or use Our Properties if you
                                            have been previously removed by
                                            YING, or if you have been previously
                                            banned from any of Our Properties.
                                            <br></br>
                                            <br></br>
                                            <b>4.3.</b>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>
                                                <u>Profile Information.</u>
                                            </b>
                                            &nbsp;&nbsp;Upon creating an
                                            Account, our Services may
                                            automatically populate your public
                                            profile with publicly available
                                            information related to you, which
                                            may be changed in the “Settings”
                                            section of your Account. You are
                                            solely responsible for maintaining
                                            and ensuring the accuracy,
                                            completeness and truthfulness of
                                            such information. You represent and
                                            warrant that all information in your
                                            profile is true, accurate, current
                                            and complete.
                                        </li>
                                        <br></br>
                                        <li>
                                            <b>Balance Tokens.</b>
                                            <br></br>
                                            <br></br>
                                            <b>5.1</b>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>
                                                <u>Balance Tokens.</u>
                                            </b>
                                            &nbsp;&nbsp;YING enables you to earn
                                            valuable and tangible currency for
                                            any exchange of skills and services
                                            (“Balance Tokens”). You are solely
                                            responsible for verifying the proper
                                            amount of Balance Tokens that have
                                            been added or deducted from your
                                            Account during any given
                                            transaction. You are responsible for
                                            notifying YING if you believe that a
                                            mistake has been made with your
                                            Balance Tokens. YING may require
                                            additional information and/or
                                            documentation to verify your claim,
                                            and YING, in its sole discretion,
                                            may take action on such mistake. You
                                            agree that YING has sole and
                                            absolute discretion in determining
                                            if your claim is valid and, if so,
                                            the appropriate remedy. Company
                                            prohibits and does not recognize any
                                            purported transfers of Balance
                                            Tokens effectuated outside of the
                                            Services.
                                        </li>
                                        <br></br>
                                        <li>
                                            <span>
                                                <b>Release.</b>&nbsp;&nbsp; YING
                                                expressly disclaims any
                                                liability that may arise between
                                                Users of its Services. The
                                                Services are only a venue for
                                                connecting Users. Because YING
                                                is not involved in the actual
                                                contract between Users with
                                                respect to any Opportunity, in
                                                the event that you have a
                                                dispute with one or more Users,
                                                you release YING (and our
                                                officers, directors, agents,
                                                investors, subsidiaries, and
                                                employees) from any and all
                                                claims, demands, or damages
                                                (actual or consequential) of
                                                every kind and nature, known and
                                                unknown, suspected and
                                                unsuspected, disclosed and
                                                undisclosed, arising out of or
                                                in any way connected with such
                                                disputes.
                                            </span>
                                        </li>
                                        <br></br>
                                        <li>
                                            <b>Responsibility for Content.</b>
                                            &nbsp;&nbsp;
                                            <br></br>
                                            <br></br>
                                            <b>7.1.</b>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>
                                                <u>Types of Content.</u>
                                            </b>
                                            &nbsp;&nbsp; You acknowledge that
                                            all text, images, videos,
                                            audio-visual content, information,
                                            data, works of authorship and
                                            materials (collectively “Content”),
                                            is the sole responsibility of the
                                            party from whom such Content
                                            originated. This means that you, and
                                            not YING, are entirely responsible
                                            for all Content that you upload,
                                            post, transmit or otherwise make
                                            available (“<b>Make Available</b>”)
                                            through Our Properties (“
                                            <b>Your Content</b>”), and other
                                            Users of Our Properties, and not
                                            YING, are similarly responsible for
                                            all Content they Make Available
                                            through Our Properties (“
                                            <b>User Content</b>”).
                                            <br></br>
                                            <br></br>
                                            <b>7.2.</b>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>
                                                <u>
                                                    No Obligation to Pre-Screen
                                                    Content.
                                                </u>
                                            </b>
                                            &nbsp;&nbsp; You acknowledge that
                                            YING has no obligation to pre-screen
                                            Content (including, but not limited
                                            to, Your Content and User Content),
                                            although YING reserves the right in
                                            its sole discretion to pre-screen,
                                            refuse or remove any Content. By
                                            entering into the Terms, you hereby
                                            provide your irrevocable consent to
                                            such monitoring. &nbsp; You
                                            acknowledge and agree that you have
                                            no expectation of privacy concerning
                                            the transmission of Your Content,
                                            including without limitation chat,
                                            text, or voice communications.
                                            &nbsp; In the event that YING
                                            pre-screens, refuses or removes any
                                            Content, you acknowledge that YING
                                            will do so for YING’s benefit, not
                                            yours. Without limiting the
                                            foregoing, YING shall have the right
                                            to remove any Content that violates
                                            the Terms or is otherwise
                                            objectionable.
                                            <br></br>
                                            <br></br>
                                            <b>7.3.</b>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>
                                                <u>Storage.</u>
                                            </b>
                                            &nbsp;&nbsp; Unless expressly agreed
                                            to by YING in writing elsewhere,
                                            YING has no obligation to store any
                                            of Your Content that you Make
                                            Available on Our Properties. YING
                                            has no responsibility or liability
                                            for the deletion or accuracy of any
                                            Content, including Your Content; the
                                            failure to store, transmit or
                                            receive transmission of Content; or
                                            the security, privacy, storage, or
                                            transmission of other communications
                                            originating with or involving use of
                                            Our Properties.
                                        </li>
                                        <br></br>
                                        <li>
                                            <b>Ownership.</b>&nbsp;&nbsp;
                                            <br></br>
                                            <br></br>
                                            <b>8.1.</b>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>
                                                <u>Our Properties.</u>
                                            </b>
                                            &nbsp;&nbsp; Except with respect to
                                            Your Content and User Content, you
                                            agree that YING and its suppliers
                                            own all rights, title and interest
                                            in the Our Properties (including but
                                            not limited to, any computer code
                                            and documentation). &nbsp; You will
                                            not remove, alter or obscure any
                                            copyright, trademark, service mark
                                            or other proprietary rights notices
                                            incorporated in or accompanying Our
                                            Properties.
                                            <br></br>
                                            <br></br>
                                            <b>8.2.</b>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span>
                                                <b>
                                                    <u>
                                                        License to Your Content.
                                                    </u>
                                                </b>
                                                &nbsp;&nbsp; Subject to any
                                                applicable account settings that
                                                you select, you grant YING a
                                                fully paid, royalty-free,
                                                perpetual, irrevocable,
                                                worldwide, royalty-free,
                                                non-exclusive and fully
                                                sublicensable right (including
                                                any moral rights) and license to
                                                use, license, distribute,
                                                reproduce, modify, adapt,
                                                publicly perform, and publicly
                                                display, Your Content (in whole
                                                or in part) for the purposes of
                                                operating and providing Our
                                                Properties to you and to our
                                                other Users. &nbsp;Please
                                                remember that other Users may
                                                search for, see, use, modify and
                                                reproduce any of Your Content
                                                that you submit to any “public”
                                                area of Our Properties,
                                                including but not limited to our
                                                question and answer forum.
                                                &nbsp; You warrant that the
                                                holder of any worldwide
                                                intellectual property right,
                                                including moral rights, in Your
                                                Content, has completely and
                                                effectively waived all such
                                                rights and validly and
                                                irrevocably granted to you the
                                                right to grant the license
                                                stated above. &nbsp; You agree
                                                that you, not YING, are
                                                responsible for all of Your
                                                Content that you Make Available
                                                on or in Our Properties.
                                            </span>
                                            <br></br>
                                            <br></br>
                                            <b>8.3.</b>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span>
                                                <b>
                                                    <u>
                                                        Your&nbsp;&nbsp;&nbsp;&nbsp;
                                                        Account.
                                                    </u>
                                                </b>
                                                &nbsp;&nbsp; Notwithstanding
                                                anything to the contrary herein,
                                                you acknowledge and agree that
                                                you shall have no ownership or
                                                other property interest in your
                                                Account, and you further
                                                acknowledge and agree that all
                                                rights in and to your Account
                                                are and shall forever be owned
                                                by and inure to the benefit of
                                                YING.{' '}
                                            </span>
                                            <br></br>
                                            <br></br>
                                            <b>8.4.</b>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>
                                                <u>Your Profile.</u>
                                            </b>
                                            &nbsp;&nbsp; Any Content posted by
                                            you in your profile may not contain
                                            nudity, violence, sexually explicit,
                                            or offensive subject matter. You may
                                            not post or submit for print
                                            services a photograph of another
                                            person without that person’s
                                            permission
                                            <br></br>
                                            <br></br>
                                            <b>8.5.</b>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>
                                                <u>Feedback.</u>
                                            </b>
                                            &nbsp;&nbsp; You agree that
                                            submission of any ideas,
                                            suggestions, documents, and/or
                                            proposals to YING through its
                                            suggestion or feedback pages (“
                                            <b>Feedback”</b>) is at your own
                                            risk and that YING has no
                                            obligations (including without
                                            limitation obligations of
                                            confidentiality) with respect to
                                            such Feedback. You represent and
                                            warrant that you have all rights
                                            necessary to submit the Feedback.
                                            You hereby grant to YING a fully
                                            paid, royalty-free, perpetual,
                                            irrevocable, worldwide,
                                            non-exclusive, and fully
                                            sublicensable right and license to
                                            use, reproduce, perform, display,
                                            distribute, adapt, modify,
                                            re-format, create derivative works
                                            of, and otherwise commercially or
                                            non-commercially exploit in any
                                            manner, any and all Feedback, and to
                                            sublicense the foregoing rights, in
                                            connection with the operation and
                                            maintenance of Our Properties.
                                            <br></br>
                                            <br></br>
                                            <b>8.6.</b>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>
                                                <u>Reviews.</u>
                                            </b>
                                            &nbsp;&nbsp; The Services host User
                                            Content that is related to reviews
                                            of certain Ying Members.
                                            &nbsp;&nbsp; Such reviews are
                                            opinions and are not the opinion of
                                            YING, have not been verified by
                                            YING, and each User should undertake
                                            his or her own research to be
                                            satisfied concerning any specific
                                            Ying Member. &nbsp;&nbsp; You agree
                                            that YING is not liable for any User
                                            Content.
                                        </li>
                                        <br></br>
                                        <li>
                                            <b>User Conduct.</b>
                                            &nbsp;&nbsp;&nbsp;&nbsp;While using
                                            or accessing Our Properties you
                                            agree that you will not, under any
                                            circumstances:<br></br>
                                            <br></br>
                                            <b>(a)</b>
                                            &nbsp;&nbsp;&nbsp;&nbsp;Engage in
                                            any activity that interferes with or
                                            disrupts YING;
                                            <br></br>
                                            <br></br>
                                            <b>(b)</b>
                                            &nbsp;&nbsp;&nbsp;&nbsp;Use Our
                                            Properties to advertise, solicit, or
                                            compete, directly or indirectly,
                                            with YING;
                                            <br></br>
                                            <br></br>
                                            <b>(c)</b>
                                            &nbsp;&nbsp;&nbsp;&nbsp;Post false,
                                            inaccurate, misleading, defamatory
                                            or libelous content;
                                            <br></br>
                                            <br></br>
                                            <b>(d)</b>
                                            &nbsp;&nbsp;&nbsp;&nbsp;Take any
                                            action that may undermine our
                                            feedback or ratings systems;
                                            <br></br>
                                            <br></br>
                                            <b>(e)</b>
                                            &nbsp;&nbsp;&nbsp;&nbsp;Transfer
                                            your Account and username to another
                                            party without our consent;
                                            <br></br>
                                            <br></br>
                                            <b>(f)</b>
                                            &nbsp;&nbsp;&nbsp;&nbsp;Upload,
                                            post, email, transmit or otherwise
                                            make available any unsolicited or
                                            unauthorized advertising,
                                            promotional materials, “junk mail,”
                                            “spam,” “chain letters,” “pyramid
                                            schemes,” or any other form of
                                            solicitation;
                                            <br></br>
                                            <br></br>
                                            <b>(g)</b>
                                            &nbsp;&nbsp;&nbsp;&nbsp;Use Our
                                            Properties to collect, harvest,
                                            transmit, distribute, post or submit
                                            any information concerning any other
                                            person or entity, including without
                                            limitation, photographs of others
                                            without their permission, personal
                                            contact information or credit,
                                            debit, calling card or account
                                            numbers;
                                            <br></br>
                                            <br></br>
                                            <b>(h)</b>
                                            &nbsp;&nbsp;&nbsp;&nbsp;Make
                                            Available any Content that (i) is
                                            unlawful, tortious, defamatory,
                                            vulgar, obscene, libelous, or
                                            racially, ethnically or otherwise
                                            objectionable; (ii) violates, or
                                            encourages any conduct that would
                                            violate, any applicable law or
                                            regulation or would give rise to
                                            civil liability; (iii) promotes
                                            discrimination, bigotry, racism,
                                            hatred, harassment or harm against
                                            any individual or group; (iv) is
                                            violent or threatening, or promotes
                                            violence or actions that are
                                            threatening to any other person; or
                                            (v) promotes illegal or harmful
                                            activities; or
                                        </li>
                                        <br></br>
                                        <li>
                                            <b>Indemnification.</b>&nbsp;&nbsp;
                                            You agree to indemnify and hold
                                            YING, its parents, subsidiaries,
                                            affiliates, officers, employees,
                                            agents, partners and licensors
                                            (collectively, the “
                                            <b>YING Parties</b>”) harmless from
                                            any losses, costs, liabilities and
                                            expenses (including reasonable
                                            attorneys’ fees) relating to or
                                            arising out of: (a) Your Content;
                                            (b) your use of, or inability to
                                            use, Our Properties; (c) your
                                            violation of the Terms; (d) your
                                            violation of any rights of another
                                            party, including any Users; or (e)
                                            your violation of any applicable
                                            laws, rules or regulations. YING
                                            reserves the right, at its own cost,
                                            to assume the exclusive defense and
                                            control of any matter otherwise
                                            subject to indemnification by you,
                                            in which event you will fully
                                            cooperate with YING in asserting any
                                            available defenses. You agree that
                                            the provisions in this section will
                                            survive any termination of your
                                            Account, the Terms or your access to
                                            Our Properties.
                                        </li>
                                        <br></br>
                                        <li>
                                            <b>
                                                Disclaimer of Warranties and
                                                Conditions.
                                            </b>
                                            <br></br>
                                            <br></br>3.1.&nbsp;&nbsp;
                                            <b>
                                                <u>As&nbsp;&nbsp;&nbsp;Is</u>
                                            </b>
                                            &nbsp; YOU EXPRESSLY UNDERSTAND AND
                                            AGREE THAT TO THE EXTENT PERMITTED
                                            BY APPLICABLE LAW, YOUR USE OF OUR
                                            PROPERTIES IS AT YOUR SOLE RISK, AND
                                            OUR PROPERTIES ARE PROVIDED ON AN
                                            “AS IS” AND “AS AVAILABLE” BASIS,
                                            WITH ALL FAULTS. YING PARTIES
                                            EXPRESSLY DISCLAIM ALL WARRANTIES,
                                            REPRESENTATIONS, AND CONDITIONS OF
                                            ANY KIND, WHETHER EXPRESS OR
                                            IMPLIED, INCLUDING, BUT NOT LIMITED
                                            TO, THE IMPLIED WARRANTIES OR
                                            CONDITIONS OF MERCHANTABILITY,
                                            FITNESS FOR A PARTICULAR PURPOSE AND
                                            NON-INFRINGEMENT.
                                            <br></br>
                                            <br></br>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>(a)</b>
                                            &nbsp;&nbsp;&nbsp;&nbsp;YING PARTIES
                                            MAKE NO WARRANTY, REPRESENTATION OR
                                            CONDITION THAT: (1) OUR PROPERTIES
                                            WILL MEET YOUR REQUIREMENTS; (2)
                                            YOUR USE OF OUR PROPERTIES WILL BE
                                            UNINTERRUPTED, TIMELY, SECURE OR
                                            ERROR-FREE; (3) THE RESULTS THAT MAY
                                            BE OBTAINED FROM USE OF OUR
                                            PROPERTIES WILL BE ACCURATE OR
                                            RELIABLE; OR (4) ANY ERRORS IN OUR
                                            PROPERTIES WILL BE CORRECTED.
                                            <br></br>
                                            <br></br>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>(b)</b>&nbsp;&nbsp;&nbsp;&nbsp;
                                            THE SERVICES MAY BE SUBJECT TO
                                            DELAYS, CANCELLATIONS AND OTHER
                                            DISRUPTIONS. YING MAKES NO WARRANTY,
                                            REPRESENTATION OR CONDITION WITH
                                            RESPECT TO SERVICES, INCLUDING BUT
                                            NOT LIMITED TO, THE QUALITY,
                                            EFFECTIVENESS, REPUTATION AND OTHER
                                            CHARACTERISTICS OF SERVICES.
                                            <br></br>
                                            <br></br>
                                            3.2.&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>
                                                <u>
                                                    No Liability for Conduct of
                                                    Other Users.
                                                </u>
                                            </b>
                                            &nbsp;&nbsp;&nbsp;&nbsp; YOU ARE
                                            SOLELY RESPONSIBLE FOR ALL OF YOUR
                                            COMMUNICATIONS AND INTERACTIONS WITH
                                            OTHER USERS OF OUR PROPERTIES. YOU
                                            UNDERSTAND THAT YING DOES NOT MAKE
                                            ANY ATTEMPT TO VERIFY THE STATEMENTS
                                            OF USERS OF&nbsp;&nbsp;
                                            OUR&nbsp;&nbsp;
                                            PROPERTIES.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;YING&nbsp;&nbsp;MAKES&nbsp;&nbsp;NO&nbsp;&nbsp;REPRESENTATIONS&nbsp;&nbsp;OR
                                            WARRANTIES AS TO THE CONDUCT OF
                                            USERS OF OUR
                                            PROPERTIES.&nbsp;&nbsp;YOU AGREE TO
                                            TAKE REASONABLE PRECAUTIONS IN ALL
                                            COMMUNICATIONS AND INTERACTIONS WITH
                                            OTHER USERS OF OUR PROPERTIES.
                                            <br></br>
                                            <br></br>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>(a)</b>&nbsp;&nbsp;&nbsp;&nbsp;
                                            You acknowledge and agree that YING
                                            does not control any of its Users
                                            /Members nor does YING make any
                                            attempt to verify the accuracy of
                                            the information any YING Members
                                            posts in their public profiles. As
                                            such, YING makes no warranty that
                                            any services provided by YING
                                            Members will meet your requirements
                                            or be available on an uninterrupted,
                                            secure, or error-free basis. YING
                                            makes no warranty regarding the
                                            quality of any such services, or the
                                            accuracy, timeliness, truthfulness,
                                            completeness or reliability of any
                                            User content obtained through Our
                                            Properties.
                                            <br></br>
                                            <br></br>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>(b)</b>&nbsp;&nbsp;&nbsp;&nbsp;
                                            We are not involved in the actual
                                            transaction between our YING
                                            Members. While we may help
                                            facilitate the resolution of
                                            disputes through various programs,
                                            we have no control over and do not
                                            guarantee the quality, safety or
                                            legality of Projects or
                                            Opportunities advertised, the truth
                                            or accuracy of Users’ content,
                                            Projects or Opportunities, the
                                            ability of Users to provide
                                            services, or that any User or YING
                                            Member will complete a transaction
                                            or return any Confidential
                                            Information that has been given to
                                            them.
                                        </li>
                                        <br></br>
                                        <li>
                                            <b>Limitation of Liability.</b>
                                            <br></br>
                                            <br></br>
                                            4.1.&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>
                                                <u>
                                                    Disclaimer of Certain
                                                    Damages.
                                                </u>
                                            </b>
                                            &nbsp;&nbsp; YOU UNDERSTAND AND
                                            AGREE THAT IN NO EVENT SHALL YING
                                            PARTIES BE LIABLE FOR ANY INDIRECT,
                                            INCIDENTAL, SPECIAL, EXEMPLARY, OR
                                            CONSEQUENTIAL DAMAGES ARISING OUT OF
                                            OR IN CONNECTION WITH OUR
                                            PROPERTIES, INCLUDING, WITHOUT
                                            LIMITATION, ANY DAMAGES RESULTING
                                            FROM LOSS OF USE, DATA, OR PROFITS,
                                            WHETHER OR NOT YING HAS BEEN ADVISED
                                            OF THE POSSIBILITY OF SUCH DAMAGES,
                                            OR FOR ANY DAMAGES FOR PERSONAL OR
                                            BODILY INJURY OR EMOTIONAL DISTRESS
                                            ARISING OUT OF OR IN CONNECTION WITH
                                            THE TERMS, OR FROM ANY
                                            COMMUNICATIONS, INTERACTIONS OR
                                            MEETINGS WITH OTHER USERS OF OUR
                                            PROPERTIES, ON ANY THEORY OF
                                            LIABILITY, RESULTING FROM: (1) THE
                                            USE OR INABILITY TO USE OUR
                                            PROPERTIES; (2) THE COST OF
                                            PROCUREMENT OF SUBSTITUTE GOODS OR
                                            SERVICES RESULTING FROM ANY GOODS,
                                            DATA, INFORMATION OR SERVICES
                                            PURCHASED OR OBTAINED OR MESSAGES
                                            RECEIVED FOR TRANSACTIONS ENTERED
                                            INTO THROUGH OUR PROPERTIES; (3)
                                            UNAUTHORIZED ACCESS TO OR ALTERATION
                                            OF YOUR TRANSMISSIONS OR DATA; (4)
                                            STATEMENTS OR CONDUCT OF ANY THIRD
                                            PARTY ON OUR PROPERTIES; OR (5) ANY
                                            OTHER MATTER RELATED TO OUR
                                            PROPERTIES, WHETHER BASED ON
                                            WARRANTY, COPYRIGHT, CONTRACT, TORT
                                            (INCLUDING NEGLIGENCE) OR ANY OTHER
                                            LEGAL THEORY.
                                            <br></br>
                                            <br></br>
                                            <br></br>
                                            4.2.&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>
                                                <u>Cap on Liability.</u>
                                            </b>
                                            &nbsp;&nbsp; UNDER NO CIRCUMSTANCES
                                            WILL YING PARTIES BE LIABLE TO YOU
                                            FOR MORE THAN THE AMOUNT RECEIVED BY
                                            YING AS A RESULT OF YOUR USE OF OUR
                                            PROPERTIES DURING THE SIX (6) MONTH
                                            PERIOD IMMEDIATELY PRECEDING THE
                                            EVENT(S) GIVING RISE TO LIABILITY
                                            HEREUNDER. IF YOU HAVE NOT PAID YING
                                            ANY AMOUNTS YING’S SOLE AND
                                            EXCLUSIVE LIABILITY SHALL BE LIMITED
                                            TO FIFTY DOLLARS ($50).
                                            <br></br>
                                            <br></br>
                                            4.3.&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>
                                                <u>Exclusion of Damages.</u>
                                            </b>
                                            &nbsp;&nbsp; CERTAIN JURISDICTIONS
                                            DO NOT ALLOW THE EXCLUSION OR
                                            LIMITATION OF CERTAIN DAMAGES. IF
                                            THESE LAWS APPLY TO YOU, SOME OR ALL
                                            OF THE ABOVE EXCLUSIONS OR
                                            LIMITATIONS MAY NOT APPLY TO YOU,
                                            AND YOU MIGHT HAVE ADDITIONAL
                                            RIGHTS.
                                            <br></br>
                                            <br></br>
                                            4.4.&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>
                                                <u>User Content.</u>
                                            </b>
                                            &nbsp;&nbsp; YING PARTIES ASSUME NO
                                            RESPONSIBILITY FOR THE TIMELINESS,
                                            DELETION, MIS-DELIVERY OR FAILURE TO
                                            STORE ANY CONTENT (INCLUDING, BUT
                                            NOT LIMITED TO, YOUR CONTENT AND
                                            USER CONTENT), USER COMMUNICATIONS
                                            OR PERSONALIZATION SETTINGS.
                                            <br></br>
                                            <br></br>
                                            4.5.&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>
                                                <u>Basis of the Bargain.</u>
                                            </b>
                                            &nbsp;&nbsp; THE LIMITATIONS OF
                                            DAMAGES SET FORTH ABOVE ARE
                                            FUNDAMENTAL ELEMENTS OF THE BASIS OF
                                            THE BARGAIN BETWEEN YING AND YOU.
                                        </li>
                                        <br></br>
                                        <li>
                                            <b>
                                                Procedure for Making Claims of
                                                Copyright Infringement.
                                            </b>
                                            &nbsp;&nbsp; It is YING’s policy to
                                            terminate membership privileges of
                                            any User who repeatedly infringes
                                            copyright upon prompt notification
                                            to YING by the copyright owner or
                                            the copyright owner’s legal agent.
                                            Without limiting the foregoing, if
                                            you believe that your work has been
                                            copied and posted on Our Properties
                                            in a way that constitutes copyright
                                            infringement, please provide our
                                            Copyright Agent with the following
                                            information: (1) an electronic or
                                            physical signature of the person
                                            authorized to act on behalf of the
                                            owner of the copyright interest; (2)
                                            a description of the copyrighted
                                            work that you claim has been
                                            infringed; (3) a description of the
                                            location on Our Properties of the
                                            material that you claim is
                                            infringing; (4) your address,
                                            telephone number and email address;
                                            (5) a written statement by you that
                                            you have a good faith belief that
                                            the disputed use is not authorized
                                            by the copyright owner, its agent or
                                            the law; and (6) a statement by you,
                                            made under penalty of perjury, that
                                            the above information in your notice
                                            is accurate and that you are the
                                            copyright owner or authorized to act
                                            on the copyright owner’s behalf.
                                            Contact information for YING’s
                                            Copyright Agent for notice of claims
                                            of copyright infringement is as
                                            follows: Attn: Legal; 5909 Greene
                                            Street Philadelphia, PA 19144.
                                        </li>
                                        <br></br>
                                        <li>
                                            <b>Termination and Suspension</b>
                                            &nbsp;&nbsp; YING may terminate or
                                            suspend your right to use Our
                                            Properties at any time for any or no
                                            reason by providing you with written
                                            or email notice of such termination,
                                            and termination will be effective
                                            immediately upon delivery of such
                                            notice. We may cancel unconfirmed
                                            Accounts or Accounts that have been
                                            inactive for a long time, or modify
                                            or discontinue our Services. Without
                                            limitation, YING may terminate or
                                            suspend your right to use Our
                                            Properties if you breach any
                                            provision of the Terms or any YING
                                            policy posted through Our Properties
                                            from time to time; if YING otherwise
                                            finds that you have engaged in
                                            inappropriate and/or offensive
                                            behavior; if YING believes you are
                                            creating problems or possible legal
                                            liabilities; if YING believes such
                                            action will improve the security of
                                            our community or reduce another
                                            User’s exposure to financial
                                            liabilities; if YING believes you
                                            are infringing the rights of third
                                            parties; if YING believes you are
                                            acting inconsistently with the
                                            spirit of these Terms; if despite
                                            our reasonable endeavors, YING is
                                            unable to verify or authenticate any
                                            information you provide; or if you
                                            fail to pay all fees due for our
                                            Services by the payment due date. In
                                            addition to terminating or
                                            suspending your Account, YING
                                            reserves the right to take
                                            appropriate legal action, including
                                            without limitation pursuing civil,
                                            criminal, and injunctive redress.
                                            Even after your right to use the
                                            Services is terminated or suspended,
                                            these Terms will remain enforceable
                                            against you.
                                        </li>
                                        <br></br>
                                        <li>
                                            <b>Remedies.</b>
                                            <br></br>
                                            <br></br>
                                            7.1.&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>
                                                <u>Violations.</u>
                                            </b>
                                            &nbsp;&nbsp; If YING becomes aware
                                            of any possible violations by you of
                                            the Terms, YING reserves the right
                                            to investigate such
                                            violations.&nbsp; If, as a result of
                                            the investigation, YING believes
                                            that criminal activity has occurred,
                                            YING reserves the right to refer the
                                            matter to, and to cooperate with,
                                            any and all applicable legal
                                            authorities. YING is entitled,
                                            except to the extent prohibited by
                                            applicable law, to disclose any
                                            information or materials on or in
                                            Our Properties, including Your
                                            Content, in YING’s possession in
                                            connection with your use of Our
                                            Properties, to (1) comply with
                                            applicable laws, legal process or
                                            governmental request; (2) enforce
                                            the Terms, (3) respond to any claims
                                            that Your Content violates the
                                            rights of third parties, (4) respond
                                            to your requests for customer
                                            service, or (5) protect the rights,
                                            property or personal safety of YING,
                                            its Users or the public, and all
                                            enforcement or other government
                                            officials, as YING in its sole
                                            discretion believes to be necessary
                                            or appropriate.
                                            <br></br>
                                            <br></br>
                                            7.2.&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>
                                                <u>Breach.</u>
                                            </b>
                                            &nbsp;&nbsp; In the event that YING
                                            determines, in its sole discretion,
                                            that you have breached any portion
                                            of the Terms, or have otherwise
                                            demonstrated conduct inappropriate
                                            for Our Properties, YING reserves
                                            the right to:
                                            <br></br>
                                            <br></br>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>(a)</b>
                                            &nbsp;&nbsp;&nbsp;&nbsp;Warn you via
                                            email (to the email address you
                                            provided YING) that you have
                                            violated the Terms;
                                            <br></br>
                                            <br></br>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>(b)</b>
                                            &nbsp;&nbsp;&nbsp;&nbsp;Delete any
                                            of Your Content provided by you or
                                            your agent(s) to Our Properties;
                                            <br></br>
                                            <br></br>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>(c)</b>
                                            &nbsp;&nbsp;&nbsp;&nbsp;Discontinue
                                            your registration(s) with any of Our
                                            Properties, including any Services;
                                            <br></br>
                                            <br></br>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>(d)</b>
                                            &nbsp;&nbsp;&nbsp;&nbsp;Notify
                                            and/or send Content to and/or fully
                                            cooperate with the proper law
                                            enforcement authorities for further
                                            action; and/or
                                            <br></br>
                                            <br></br>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>(e)</b>
                                            &nbsp;&nbsp;&nbsp;&nbsp;Pursue any
                                            other action which YING deems to be
                                            appropriate.
                                            <br></br>
                                            <br></br>
                                            7.3.&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>
                                                <u>
                                                    No Subsequent Registration.
                                                </u>
                                            </b>
                                            &nbsp;&nbsp; If your registration(s)
                                            with or ability to access Our
                                            Properties is discontinued by us due
                                            to your violation of any portion of
                                            the Terms or for conduct otherwise
                                            inappropriate for the community,
                                            then you agree that you shall not
                                            attempt to re-register with or
                                            access Our Properties through use of
                                            a different email address, member
                                            name or otherwise, and you
                                            acknowledge that you will not be
                                            entitled to receive a refund for
                                            fees related to those of Our
                                            Properties to which your access has
                                            been terminated. In the event that
                                            you violate the immediately
                                            preceding sentence, YING reserves
                                            the right, in its sole discretion,
                                            to immediately take any or all of
                                            the actions set forth herein without
                                            any notice or warning to you.
                                        </li>
                                        <br></br>
                                        <li>
                                            <b>International Users.</b>
                                            &nbsp;&nbsp; Our Properties can be
                                            accessed from countries around the
                                            world and may contain references to
                                            Services and Content that are not
                                            available in your country. These
                                            references do not imply that YING
                                            intends to announce such Services or
                                            Content in your country. Our
                                            Properties are controlled and
                                            offered by YING from its facilities
                                            in the United States of America.
                                            &nbsp;&nbsp;YING makes no
                                            representations that Our Properties
                                            are appropriate or available for use
                                            in other locations.&nbsp;&nbsp;
                                            Those who access or use Our
                                            Properties from other jurisdictions
                                            do so at their own volition and are
                                            responsible for compliance with
                                            local law.
                                        </li>
                                        <br></br>
                                        <li>
                                            <b>General Provisions.</b>
                                            <br></br>
                                            <br></br>9.1&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>
                                                <u>
                                                    Electronic Communications.
                                                </u>
                                            </b>
                                            &nbsp;&nbsp; The communications
                                            between you and YING use electronic
                                            means, whether you visit Our
                                            Properties or send use-mails, or
                                            whether we post notices on Our
                                            Properties or communicate with you
                                            via email.&nbsp;&nbsp; For
                                            contractual purposes, you (1)
                                            consent to receive communications
                                            from YING in an electronic form; and
                                            (2) agree that all terms and
                                            conditions, agreements, notices,
                                            disclosures, and other
                                            communications that YING provides to
                                            you electronically satisfy any legal
                                            requirement that such communications
                                            would satisfy if it were to be in
                                            writing.&nbsp;&nbsp; The foregoing
                                            does not affect your statutory
                                            rights.
                                            <br></br>
                                            <br></br>
                                            9.2.&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>
                                                <u>Release.</u>
                                            </b>
                                            &nbsp;&nbsp; You hereby release YING
                                            Parties and their successors from
                                            claims, demands, any and all losses,
                                            damages, rights, and actions of any
                                            kind, including personal injuries,
                                            death, and property damage, that is
                                            either directly or indirectly
                                            related to or arises from your use
                                            of Our Properties, including but not
                                            limited to, any interactions with or
                                            conduct of other Users or
                                            third-party websites of any kind
                                            arising in connection with or as a
                                            result of the Terms or your use of
                                            Our Properties.&nbsp;&nbsp; If you
                                            are a California resident, you
                                            hereby waive California Civil Code
                                            Section 1542, which states, “A
                                            general release does not extend to
                                            claims which the creditor does not
                                            know or suspect to exist in his
                                            favor at the time of executing the
                                            release, which, if known by him must
                                            have materially affected his
                                            settlement with the debtor.
                                            <br></br>
                                            <br></br>
                                            9.3.&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>
                                                <u>Assignment.</u>
                                            </b>
                                            &nbsp;&nbsp; The Terms, and your
                                            rights and obligations hereunder,
                                            may not be assigned, subcontracted,
                                            delegated or otherwise transferred
                                            by you without YING’s prior written
                                            consent, and any attempted
                                            assignment, subcontract, delegation,
                                            or transfer in violation of the
                                            foregoing will be null and void.
                                            <br></br>
                                            <br></br>
                                            9.4.&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>
                                                <u>Force Majeure.</u>
                                            </b>
                                            &nbsp;&nbsp; YING shall not be
                                            liable for any delay or failure to
                                            perform resulting from causes
                                            outside its reasonable control,
                                            including, but not limited to, acts
                                            of God, war, terrorism, riots,
                                            embargos, acts of civil or military
                                            authorities, fire, floods,
                                            accidents, strikes or shortages of
                                            transportation facilities, fuel,
                                            energy, labor or materials.
                                            <br></br>
                                            <br></br>
                                            9.5.&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>
                                                <u>
                                                    Questions, Complaints,
                                                    Claims.
                                                </u>
                                            </b>
                                            &nbsp;&nbsp; If you have any
                                            questions, complaints or claims with
                                            respect to Our Properties, please
                                            contact us at: <a></a>
                                            [info@yingme.co]. We will do our
                                            best to address your concerns. If
                                            you feel that your concerns have
                                            been addressed incompletely, we
                                            invite you to let us know for
                                            further investigation.
                                            <br></br>
                                            <br></br>
                                            9.6.&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>
                                                <u>Limitations Period.</u>
                                            </b>
                                            &nbsp;&nbsp; YOU AND YING AGREE THAT
                                            ANY CAUSE OF ACTION ARISING OUT OF
                                            OR RELATED TO THE TERMS, OUR
                                            PROPERTIES OR THE CONTENT MUST
                                            COMMENCE WITHIN ONE (1) YEAR AFTER
                                            THE CAUSE OF ACTION ACCRUES.
                                            OTHERWISE, SUCH CAUSE OF ACTION IS
                                            PERMANENTLY BARRED.
                                            <br></br>
                                            <br></br>
                                            9.7.&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>
                                                <u>Dispute Resolution.</u>
                                            </b>
                                            &nbsp;&nbsp;
                                            <i>
                                                Please read this Arbitration
                                                Agreement
                                                carefully.&nbsp;&nbsp;It is part
                                                of your contract with YING and
                                                affects your rights.&nbsp;&nbsp;
                                                It contains procedures for
                                                MANDATORY BINDING ARBITRATION
                                                AND A CLASS ACTION WAIVER.
                                            </i>
                                            <br></br>
                                            <br></br>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>(a)</b>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <i>
                                                Applicability&nbsp; of&nbsp;
                                                Arbitration&nbsp; Agreement.
                                            </i>
                                            &nbsp;&nbsp;&nbsp;&nbsp;All claims
                                            and disputes (excluding claims for
                                            injunctive or other equitable relief
                                            as set forth below) in connection
                                            with the Terms or the use of any
                                            product or service provided by YING
                                            that cannot be resolved informally
                                            or in small claims court shall be
                                            resolved by binding arbitration on
                                            an individual basis under the terms
                                            of this Arbitration Agreement.&nbsp;
                                            Unless otherwise agreed, all
                                            arbitration proceedings will be held
                                            in English.&nbsp; This Arbitration
                                            Agreement applies to you and YING,
                                            and to any subsidiaries, affiliates,
                                            agents, employees, predecessors in
                                            interest, successors, and assigns,
                                            as well as all authorized or
                                            unauthorized users or beneficiaries
                                            of services or goods provided under
                                            the Terms.
                                            <br></br>
                                            <br></br>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>(b)</b>&nbsp;&nbsp;
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <i>
                                                Notice Requirement and Informal
                                                Dispute Resolution.
                                            </i>
                                            &nbsp;&nbsp;&nbsp;Before either
                                            party may seek arbitration, the
                                            party must first send to the other
                                            party a written Notice of Dispute (“
                                            <b>Notice</b>"") describing the
                                            nature and basis of the claim or
                                            dispute, and the requested
                                            relief.&nbsp;&nbsp;A Notice to YING
                                            should be sent to:
                                            &nbsp;.&nbsp;&nbsp; After the Notice
                                            is received, you and YING may
                                            attempt to resolve the claim or
                                            dispute informally. &nbsp;&nbsp;If
                                            you and YING do not resolve the
                                            claim or dispute within thirty (30)
                                            days after the Notice is received,
                                            either party may begin an
                                            arbitration
                                            proceeding.&nbsp;&nbsp;The amount of
                                            any settlement offer made by any
                                            party may not be disclosed to the
                                            arbitrator until after the
                                            arbitrator has determined the amount
                                            of the award, if any, to which
                                            either party is entitled.
                                            <br></br>
                                            <br></br>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>(c)</b>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <i>Arbitration Rules.</i>
                                            &nbsp;&nbsp;&nbsp;Arbitration shall
                                            be initiated through the American
                                            Arbitration Association (“<b>AAA</b>
                                            ”), an established alternative
                                            dispute resolution provider (“
                                            <b>ADR Provider</b>”) that offers
                                            arbitration as set forth in this
                                            section.&nbsp;&nbsp;If AAA is not
                                            available to arbitrate, the parties
                                            shall agree to select an alternative
                                            ADR Provider.&nbsp;&nbsp;The rules
                                            of the ADR Provider shall govern all
                                            aspects of the arbitration,
                                            including but not limited to the
                                            method of initiating and/or
                                            demanding arbitration, except to the
                                            extent such rules are in conflict
                                            with the Terms.&nbsp;&nbsp;The
                                            arbitration shall be conducted by a
                                            single, neutral
                                            arbitrator.&nbsp;&nbsp;Any claims or
                                            disputes where the total amount of
                                            the award sought is less than Ten
                                            Thousand U.S. Dollars (US
                                            $10,000.00) may be resolved through
                                            binding non-appearance-based
                                            arbitration, at the option of the
                                            party seeking relief.&nbsp;&nbsp;
                                            For claims or disputes where the
                                            total amount of the award sought is
                                            Ten Thousand U.S. Dollars (US
                                            $10,000.00) or more, the right to a
                                            hearing will be determined by the
                                            Arbitration Rules.&nbsp;&nbsp; Any
                                            hearing will be held in a location
                                            within 100 miles of your residence,
                                            unless you reside outside of the
                                            United States, and unless the
                                            parties agree otherwise.&nbsp;&nbsp;
                                            If you reside outside of the U.S.,
                                            the arbitrator shall give the
                                            parties reasonable notice of the
                                            date, time and place of any oral
                                            hearings.&nbsp;&nbsp; Any judgment
                                            on the award rendered by the
                                            arbitrator may be entered in any
                                            court of competent jurisdiction.
                                            Each party shall bear its own costs
                                            (including attorney’s fees) and
                                            disbursements arising out of the
                                            arbitration and shall pay an equal
                                            share of the fees and costs of the
                                            ADR Provider.
                                            <br></br>
                                            <br></br>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>(d)</b>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <i>
                                                Additional&nbsp;&nbsp;&nbsp;Rules&nbsp;&nbsp;&nbsp;for&nbsp;&nbsp;&nbsp;Non-Appearance&nbsp;&nbsp;&nbsp;
                                                Based&nbsp;&nbsp;&nbsp;Arbitration.
                                            </i>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            If non-appearance based arbitration
                                            is elected, the arbitration shall be
                                            conducted by telephone, online
                                            and/or based solely on written
                                            submissions; the specific manner
                                            shall be chosen by the party
                                            initiating the arbitration.
                                            &nbsp;&nbsp;The arbitration shall
                                            not involve any personal appearance
                                            by the parties or witnesses unless
                                            otherwise agreed by the parties.
                                            <br></br>
                                            <br></br>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>(e)</b>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <i>Authority of Arbitrator.</i>
                                            &nbsp;&nbsp; If arbitration is
                                            initiated, the arbitrator will
                                            decide the rights and liabilities,
                                            if any, of you and YING, and the
                                            dispute will not be consolidated
                                            with any other matters or joined
                                            with any other cases or
                                            parties.&nbsp;&nbsp;The arbitrator
                                            shall have the authority to grant
                                            motions dispositive of all or part
                                            of any claim.&nbsp;&nbsp;The
                                            arbitrator shall have the authority
                                            to award monetary damages, and to
                                            grant any non-monetary remedy or
                                            relief available to an individual
                                            under applicable law, the AAA Rules,
                                            and the Terms.&nbsp;&nbsp;The
                                            arbitrator shall issue a written
                                            award and statement of decision
                                            describing the essential findings
                                            and conclusions on which the award
                                            is based, including the calculation
                                            of any damages
                                            awarded.&nbsp;&nbsp;The arbitrator
                                            has the same authority to award
                                            relief on an individual basis that a
                                            judge in a court of law would
                                            have.&nbsp;&nbsp; The award of the
                                            arbitrator is final and binding upon
                                            you and YING.
                                            <br></br>
                                            <br></br>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>(f)</b>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <i>
                                                Waiver&nbsp;&nbsp;&nbsp;
                                                of&nbsp;&nbsp;&nbsp;
                                                Jury&nbsp;&nbsp;&nbsp; Trial.
                                            </i>
                                            &nbsp;&nbsp;&nbsp;&nbsp; THE PARTIES
                                            HEREBY WAIVE THEIR CONSTITUTIONAL
                                            AND STATUTORY RIGHTS TO GO TO COURT
                                            AND HAVE A TRIAL IN FRONT OF A JUDGE
                                            OR A JURY, instead electing that all
                                            claims and disputes shall be
                                            resolved by arbitration under this
                                            Arbitration Agreement. Arbitration
                                            procedures are typically more
                                            limited, more efficient and less
                                            costly than rules applicable in a
                                            court and are subject to very
                                            limited review by a court. In the
                                            event any litigation should arise
                                            between you and YING in any state or
                                            federal court in a suit to vacate or
                                            enforce an arbitration award or
                                            otherwise, YOU AND YING WAIVE ALL
                                            RIGHTS TO A JURY TRIAL, instead
                                            electing that the dispute be
                                            resolved by a judge.
                                            <br></br>
                                            <br></br>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>(g)</b>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <i>
                                                Waiver&nbsp;&nbsp;&nbsp;
                                                of&nbsp;&nbsp;&nbsp;
                                                Class&nbsp;&nbsp;&nbsp;
                                                or&nbsp;&nbsp;&nbsp;
                                                Consolidated&nbsp;&nbsp;&nbsp;
                                                &nbsp;&nbsp;&nbsp;Actions.
                                            </i>
                                            &nbsp;&nbsp;&nbsp;&nbsp; ALL CLAIMS
                                            AND DISPUTES WITHIN THE SCOPE OF
                                            THIS ARBITRATION AGREEMENT MUST BE
                                            ARBITRATED OR LITIGATED ON AN
                                            INDIVIDUAL BASIS AND NOT ON A CLASS
                                            BASIS, AND CLAIMS OF MORE THAN ONE
                                            CUSTOMER OR USER CANNOT BE
                                            ARBITRATED OR LITIGATED JOINTLY OR
                                            CONSOLIDATED WITH THOSE OF ANY OTHER
                                            CUSTOMER OR USER. If, however, this
                                            waiver of class or consolidated
                                            actions is deemed invalid or
                                            unenforceable with respect to a
                                            particular claim or dispute, then
                                            notwithstanding anything to the
                                            contrary in this Arbitration
                                            Agreement or Terms, neither you or
                                            YING is entitled to arbitration of
                                            such claim or dispute.
                                            &nbsp;&nbsp;Instead, all such claims
                                            and disputes will then be resolved
                                            in a court as set forth in Section
                                            18.7(m).
                                            <br></br>
                                            <br></br>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>(h)</b>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                                            <i>Severability.</i>
                                            &nbsp;&nbsp;&nbsp;&nbsp; If any part
                                            or parts of this Arbitration
                                            Agreement are found under the law to
                                            be invalid or unenforceable by a
                                            court of competent jurisdiction,
                                            then such specific part or parts
                                            shall be of no force and effect and
                                            shall be severed and the remainder
                                            of the Agreement shall continue in
                                            full force and effect.
                                            <br></br>
                                            <br></br>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>(f)</b>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                                            <i>
                                                Right&nbsp;&nbsp;to&nbsp;&nbsp;Waive.
                                            </i>
                                            &nbsp;&nbsp;&nbsp;&nbsp; Any or all
                                            of the rights and limitations set
                                            forth in this Arbitration Agreement
                                            may be waived by the party against
                                            whom the claim is
                                            asserted.&nbsp;&nbsp;Such waiver
                                            shall not waive or affect any other
                                            portion of this Arbitration
                                            Agreement
                                            <br></br>
                                            <br></br>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>(f)</b>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                                            <i>
                                                Survival&nbsp;&nbsp;of&nbsp;&nbsp;Agreement.
                                            </i>
                                            &nbsp;&nbsp;&nbsp;&nbsp; This
                                            Arbitration Agreement will survive
                                            the termination of your relationship
                                            with YING.
                                            <br></br>
                                            <br></br>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>(k)</b>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                                            <i>
                                                Small&nbsp;&nbsp;Claims&nbsp;&nbsp;Court.
                                            </i>
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            Notwithstanding the foregoing,
                                            either you or YING may bring an
                                            individual action in small claims
                                            court.
                                            <br></br>
                                            <br></br>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>(f)</b>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                                            <i>
                                                Emergency&nbsp;&nbsp;Equitable&nbsp;&nbsp;Relief.
                                            </i>
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            Notwithstanding the foregoing,
                                            either party may seek emergency
                                            equitable relief before a state or
                                            federal court in order to maintain
                                            the status quo pending
                                            arbitration.&nbsp;&nbsp;A request
                                            for interim measures shall not be
                                            deemed a waiver of any other rights
                                            or obligations under this
                                            Arbitration Agreement.
                                            <br></br>
                                            <br></br>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>(m)</b>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                                            <i>Courts</i>
                                            &nbsp;&nbsp;&nbsp;&nbsp; In any
                                            circumstances where the foregoing
                                            Arbitration Agreement permits the
                                            parties to litigate in court, the
                                            parties hereby agree to submit to
                                            the personal jurisdiction of the
                                            courts located within Los Angeles,
                                            for such purpose.
                                            <br></br>
                                            <br></br>
                                            9.8.&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>
                                                <u>Governing Law.</u>
                                            </b>
                                            &nbsp; The Terms and any action
                                            related thereto will be governed and
                                            interpreted by and under the laws of
                                            the State of Delaware, consistent
                                            with the Federal Arbitration Act,
                                            without giving effect to any
                                            principles that provide for the
                                            application of the law of another
                                            jurisdiction.&nbsp;&nbsp;The United
                                            Nations Convention on Contracts for
                                            the international sale of goods does
                                            not apply to these Terms.
                                            <br></br>
                                            <br></br>
                                            9.9.&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>
                                                <u>Notice.</u>
                                            </b>
                                            &nbsp;&nbsp;&nbsp; Where YING
                                            requires that you provide an email
                                            address, you are responsible for
                                            providing YING with your most
                                            current email address.&nbsp;&nbsp;
                                            In the event that the last email
                                            address you provided to YING is not
                                            valid, or for any reason is not
                                            capable of delivering to you any
                                            notices required/ permitted by the
                                            Terms, YING’s dispatch of the email
                                            containing such notice will
                                            nonetheless constitute effective
                                            notice.&nbsp;&nbsp; You may give
                                            notice to YING at the following
                                            address: Attn: Legal;100 West
                                            Tremont Street, Pasadena, CA 91103.
                                            Such notice shall be deemed given
                                            when received by YING by letter
                                            delivered by nationally recognized
                                            overnight delivery service or first
                                            class postage prepaid mail at the
                                            above address.
                                            <br></br>
                                            <br></br>
                                            9.10.&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>
                                                <u>Waiver.</u>
                                            </b>
                                            &nbsp;&nbsp;&nbsp; Any waiver or
                                            failure to enforce any provision of
                                            the Terms on one occasion will not
                                            be deemed a waiver of any other
                                            provision or of such provision on
                                            any other occasion.
                                            <br></br>
                                            <br></br>
                                            9.11.&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>
                                                <u>Severability.</u>
                                            </b>
                                            &nbsp;&nbsp;&nbsp; If any provision
                                            of the Terms is, for any reason,
                                            held to be invalid or unenforceable,
                                            the other provisions of the Terms
                                            will remain enforceable, and the
                                            invalid or unenforceable provision
                                            will be deemed modified so that it
                                            is valid and enforceable to the
                                            maximum extent permitted by law.
                                            <br></br>
                                            <br></br>
                                            9.12.&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>
                                                <u>Entire Agreement.</u>
                                            </b>
                                            &nbsp;&nbsp;&nbsp; The Terms are the
                                            final, complete and exclusive
                                            agreement of the parties with
                                            respect to the subject matter hereof
                                            and supersedes and merges all prior
                                            discussions between the parties with
                                            respect to such subject matter.
                                        </li>
                                    </ol>
                                    <br></br>
                                    <div className='endOfTerms'>
                                        End of Terms
                                    </div>
                                </div>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                    <IonGrid className='about__manifesto--container'>
                        <IonRow className='about__manifesto--wrap'>
                            <IonCol
                                sizeXs='12'
                                sizeSm='12'
                                className='about__manifesto--contactUs'
                            >
                                <p className='about__manifesto--text'>
                                    For questions, feedback & suggestions
                                    contact us at{' '}
                                    <span>
                                        <a href='mailto:info@yingme.co'>
                                            info@yingme.co
                                        </a>
                                    </span>
                                </p>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonGrid>
            </IonContent>
        </IonModal>
    );
};

export default TermsOfUseModal;
