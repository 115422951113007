import React from 'react';
import { useHistory } from 'react-router-dom';
import { IonIcon } from '@ionic/react';
import { arrowBackOutline } from 'ionicons/icons';
import styles from './BackButton.module.css';

export default ({
    color = 'black',
    count,
    onClick,
}: {
    color?: string;
    count?: number;
    onClick?: (e: MouseEvent) => void;
}) => {
    const history = useHistory();

    let action;
    if (count !== undefined) action = () => history.go(-Math.abs(count));
    else if (onClick !== undefined) action = onClick;
    else action = history.goBack;

    return (
        <button className={styles.btn} onClick={action}>
            <IonIcon
                className={styles.icon}
                icon={arrowBackOutline}
                style={{ color }}
            />
        </button>
    );
};
