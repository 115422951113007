// importing modules
import React from 'react';
// importing components
import { IonRow } from '@ionic/react';
import HelpingTaskItem from '../taskItems/HelpingTaskItem';
import ReceivingTaskItem from '../taskItems/ReceivingTaskItem';
// importing types
import { GetProfileSkillRequests_profile_upcomingJobsServe } from '../../../GraphQL/__generated__/GetProfileSkillRequests';

const IncomingSkillRequests: React.FC<{
    skillRequests: GetProfileSkillRequests_profile_upcomingJobsServe[];
}> = ({ skillRequests }) => {
    return (
        <IonRow className='completedTasksItem__row'>
            <IonRow className='tasks__row'>
                {skillRequests.length > 0 ? (
                    skillRequests.map((task, id) => {
                        return (
                            <HelpingTaskItem
                                key={id}
                                otherProfile={task.recipient}
                                task={task}
                            />
                        );
                    })
                ) : (
                    <IonRow className='tasksPosted__text'>
                        <p>
                            Skill requests that you accepted
                            <br />
                            will appear here.
                        </p>
                    </IonRow>
                )}
            </IonRow>
        </IonRow>
    );
};

export default IncomingSkillRequests;
