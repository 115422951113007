// importing modules
import React, { useEffect } from 'react';
import { IonContent, IonRouterOutlet } from '@ionic/react';
import { Redirect, Route, Switch } from 'react-router-dom';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
/* Theme variables */
import './theme/variables.css';
/* Global CSS */
import './global.css';
// importing components
import { AuthenticatedRoute } from './auth/AuthenticatedRoute';
import Landing from './pages/Landing/Landing';
import GetStarted from './pages/GetStarted/GetStarted';
import SignUp from './pages/SignUp/SignUp';
import Login from './pages/Login/Login';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import ForgotPasswordEmailConfirmation from './pages/ForgotPassword/ForgotPasswordConfirmation/ForgotPasswordConfirmation';
import UserProfile from './pages/UserProfile/UserProfile';
import UserHomeProfile from './pages/UserHomeProfile/UserHomeProfile';
import EditUserProfile from './pages/EditProfile/EditProfile';
import GroupProfile from './pages/GroupProfile/GroupProfile';
import PhoneNumberInput from './pages/OnBoarding/PhoneNumberInput/PhoneNumberInput';
import PhoneVerification from './pages/OnBoarding/PhoneNumberVerification/PhoneNumberVerification';
import ImageUpload from './pages/OnBoarding/ImageUpload/ImageUpload';
import UserBio from './pages/OnBoarding/UserBio/UserBio';
import SkillSelectionPage from './pages/SkillSelectionPage/SkillSelectionPage';
import DefineSkill from './pages/SkillCreation/DefineSkill';
import SkillsConfirmationPage from './pages/SkillConfirmationPage/SkillConfirmation';
import TaskDayAvl from './pages/RequestSkill/TaskDayAvl/TaskDayAvl';
import TaskTimeAvl from './pages/RequestSkill/TaskTimeAvl/TaskTimeAvlV2';
import RequestTaskConfirmation from './pages/RequestSkill/TaskConfirmation/TaskConfirmation';
import TaskLocation from './pages/RequestSkill/TaskLocation/TaskLocation';
import RequestMsg from './pages/RequestSkill/TaskMsg/TaskMsgV2';
import TasksPosted from './pages/TasksPosted/TasksPosted';
import TaskPostedDetails from './pages/TaskPostedDetails/TaskPostedDetails';
import TaskPostedDetailsViaPr from './pages/TaskPostedDetails/TaskPostedDetailsViaPr';
import SkillRequestDetails from './pages/SkillRequestDetails/SkillRequestDetails';
import CancelHelpingRecipient from './pages/CancelHelpingRecipient/CancelHelpingRecipient';
import CancelHelpingRecipientConfirmationScreen from './pages/CancelHelpingRecipient/CancelHelpingRecipientConfirmationScreen/CancelHelpingRecipientConfirmationScreen';
import RequestTaskParticipation from './pages/ConfirmTaskParticipation/ConfirmTaskParticipation';
import RequestTaskParticipationConfirmationScreen from './pages/ConfirmTaskParticipation/TaskParticipationConfirmationScreen/TaskParticipationConfirmationScreen';
import CancelParticipationRequest from './pages/CancelParticipationRequest/CancelParticipationRequest';
import CancelParticipationRequestConfirmationScreen from './pages/CancelParticipationRequest/CancelParticipationRequestConfirmationScreen/CancelParticipationRequestConfirmationScreen';
import ManageParticipationRequests from './pages/ManageParticipationRequests/ManageRequests';
import AcceptServiceRequest from './pages/UserServiceRequestAcceptance/UserServiceRequest';
import AcceptServiceRequestConfirmationScreen from './pages/UserServiceRequestAcceptance/ServiceRequestConfirmationScreen/ServiceRequestConfirmationScreen';
import DeclineServiceRequestConfirmationScreen from './pages/UserServiceRequestAcceptance/ServiceRequestConfirmationScreen/DeclineServiceRequestConfirmationScreen';
import DeleteJobPost from './pages/DeleteJobPost/DeleteJobPost';
import DeleteJobPostConfirmationScreen from './pages/DeleteJobPost/DeleteJobPostConfirmationScreen/DeleteJobPostConfirmationScreen';
import DeleteTask from './pages/DeleteRecipientTask/DeleteRecipientTask';
import DeleteTaskConfirmationScreen from './pages/DeleteRecipientTask/DeleteRecipientTaskConfirmationScreen/DeleteRecipientTaskConfirmationScreen';
import Portfolio from './pages/PortfolioPreview/UserPortfolio';
import TaskCreditsInput from './pages/CompleteTaskViaRecipient/TaskCredits/TaskCreditsInput';
import TaskParticipants from './pages/CompleteTaskViaRecipient/TaskParticipants/TaskParticipants';
import TaskParticipantRatings from './pages/CompleteTaskPostedViaRecipient/ParticipantRating/ParticipantRating';
import TaskParticipantReviews from './pages/CompleteTaskPostedViaRecipient/ParticipantReview/ParticipantReview';
import ParticipantRating from './pages/CompleteTaskViaRecipient/ParticipantRating/ParticipantRating';
import ParticipantReview from './pages/CompleteTaskViaRecipient/ParticipantReview/ParticipantReview';
import TaskCompleteConfirmation from './pages/CompleteTaskViaRecipient/TaskConfirmationScreen/TaskCompletedConfirmation';
import CompleteTask from './pages/CompleteTaskViaProvider/CompleteTask/CompleteTask';
import TaskRating from './pages/CompleteTaskViaProvider/TaskRating/TaskRating';
import TaskReview from './pages/CompleteTaskViaProvider/ReviewTask/ReviewTask';
import TaskCompletedConfirmation from './pages/CompleteTaskViaProvider/CompletedTaskConfirmation/TaskCompletedConfirmation';
import CompleteTaskPostedByProvider from './pages/CompleteTaskPostedViaProvider/CompleteTask/CompleteTask';
import TaskPostedProviderRating from './pages/CompleteTaskPostedViaProvider/TaskRating/TaskRating';
import TaskPostedProviderReview from './pages/CompleteTaskPostedViaProvider/ReviewTask/ReviewTask';
import TaskPostedCompletedByProviderConfirmation from './pages/CompleteTaskPostedViaProvider/CompletedTaskConfirmation/TaskCompletedConfirmation';
import TaskPostedCredits from './pages/CompleteTaskPostedViaRecipient/TaskCredits/TaskCreditsInput';
import TaskPostedParticipants from './pages/CompleteTaskPostedViaRecipient/TaskParticipants/TaskParticipants';
import TaskPostedCompletedConfirmation from './pages/CompleteTaskPostedViaRecipient/TaskConfirmationScreen/TaskCompletedConfirmation';
import SkillRequests from './pages/SkillRequests/SkillRequests';
import CompletedTasks from './pages/CompletedTasks/CompletedTasks';
import PendingTasks from './pages/PendingTasks/PendingTasks';
import GeneralSearch from './pages/GeneralSearch/GeneralSearch';
import UniversalSearch from './pages/UniversalSearch/UniversalSearch';
import UniversalSkillSearch from './pages/UniversalSearch/MemberSkillSearch/MemberSkillSearch';
import MemberSkillSearch from './pages/GeneralSearch/MemberSkillSearch/MemberSkillSearch';
import ChatIndex from './pages/Chat/ChatIndex';
import ChatRoom from './pages/Chat/ChatRoom/ChatRoom';
import ChatRoomById from './pages/Chat/ChatRoom/ChatRoomById';
import ChatRoomByUsers from './pages/Chat/ChatRoom/ChatRoomByUsers';
import AboutUs from './pages/AboutUs/AboutUs';
import Settings from './pages/Settings/Settings';
import ContributeViaPayPal from './pages/ContributeViaPayPal/ContributeViaPayPal';
import JoinGroup from './pages/JoinGroup/JoinGroup';
import JoinGroupConfirmation from './pages/JoinGroup/JoinGroupConfirmation/JoinGroupConfirmation';
import AccountSwitcher from './pages/AccountSwitch/AccountSwitcher';
import CommunityOnboardingLanding from './pages/LandingCommunityOnboard/CommunityOnboardLanding';
import CommunityOnboarding from './pages/CommunityOnboarding/CommunityOnboarding';
import CommunityOnboardConfirmation from './pages/CommunityOnboardConfirmation/CommunityOnboardConfirmation';
import TaskDescription from './pages/CreateTask/TaskDescription/TaskDescription';
import TaskSchedule from './pages/CreateTask/TaskSchedule/Schedule';
import TaskStartTime from './pages/CreateTask/TaskStartTime/StartTime';
import TaskLocations from './pages/CreateTask/TaskLocation/TaskLocation';
import TaskSkillSearch from './pages/CreateTask/SkillSearch/SkillSearch';
import TaskHomeInvites from './pages/CreateTask/TaskInvites/TaskHomeInvites';
import TaskGroupInvites from './pages/CreateTask/TaskInvites/TaskGroupInvites';
import ConfirmTaskPost from './pages/CreateTask/ConfirmTaskPost/ConfirmTaskPost';
import ConfirmGroupTaskPost from './pages/CreateTask/ConfirmTaskPost/ConfirmGroupTaskPost';
import OnboardAssociateGroup from './pages/OnboardAssociateGroup/OnboardAssociateGroup';
//Importing legal screen pages and modals
import TermsOfUseModal from './pages/LegalScreens/TermsOfUseModal';
import PrivacyPolicyModal from './pages/LegalScreens/PrivacyPolicyModal';
import TermsOfUsePage from './pages/LegalScreens/TermsOfUsePage';
import PrivacyPolicyPage from './pages/LegalScreens/PrivacyPolicyPage';
import Notifications from './pages/Notifications/Notifications';
//stripe pages
import GratitudePot from './pages/CompleteTaskPostedViaRecipient/GratitudePot/GratitudePot';
import PayoutMethods from './pages/Settings/StripeSettings/PayoutMethods';
import PaymentMethods from './pages/Settings/StripeSettings/PaymentMethods';
import AddPaymentMethod from './pages/AddPaymentMethod/AddPaymentMethod';
import ProfileSettings from './pages/Settings/ProfileSettings/ProfileSettings';
import FAQPage from './pages/FAQ/FAQPage';
import TransactionHistory from './pages/TransactionHistory/TransactionHistory';
// custom hooks
import { useHijacker } from './utils/onboarding';
// styling
import './App.css';

import NavBar from './components/NavBar';
import GratitudeSettings from 'pages/GratitudeSettings/GratitudeSettings';

export const AuthRoutes = () => {
    useHijacker();

    return (
        <IonContent>
            <IonRouterOutlet>
                {/* Nav Tab Bar IS Rendered */}
                <AuthenticatedRoute
                    exact
                    path='/portfolio/:uid'
                    component={Portfolio}
                />
                <AuthenticatedRoute
                    exact
                    path='/'
                    component={UserHomeProfile}
                />
                <AuthenticatedRoute
                    exact
                    path='/home'
                    component={UserHomeProfile}
                />
                <AuthenticatedRoute
                    exact
                    path='/user/:uid'
                    component={UserProfile}
                />
                <AuthenticatedRoute
                    exact
                    path='/user/:uid/edit'
                    component={EditUserProfile}
                />
                <AuthenticatedRoute
                    exact
                    path='/group/:id'
                    component={GroupProfile}
                />
                <AuthenticatedRoute
                    exact
                    path='/tasksPosted/:userId'
                    component={TasksPosted}
                />
                <AuthenticatedRoute
                    exact
                    path='/skillRequests/:userId'
                    component={SkillRequests}
                />
                <AuthenticatedRoute
                    exact
                    path='/completedTasks/:userId'
                    component={CompletedTasks}
                />
                <AuthenticatedRoute
                    exact
                    path='/pendingTasks/:userId'
                    component={PendingTasks}
                />
                <AuthenticatedRoute
                    exact
                    path='/search'
                    component={UniversalSearch}
                />
                <AuthenticatedRoute
                    exact
                    path='/search/:skill'
                    component={UniversalSkillSearch}
                />
                <AuthenticatedRoute
                    exact
                    path='/search/group/:groupCode'
                    component={GeneralSearch}
                />
                <AuthenticatedRoute
                    exact
                    path='/search/group/:groupCode/:skill'
                    component={MemberSkillSearch}
                />
                <AuthenticatedRoute exact path='/chat' component={ChatIndex} />
                <AuthenticatedRoute
                    exact
                    path='/chatroom'
                    component={ChatRoom}
                />
                <AuthenticatedRoute
                    exact
                    path='/chatroom/:chatId/:sender/:receiver'
                    component={ChatRoomById}
                />
                <AuthenticatedRoute
                    exact
                    path='/chatroom/:sender/:receiver'
                    component={ChatRoomByUsers}
                />
                <AuthenticatedRoute
                    exact
                    path='/accountswitch'
                    component={AccountSwitcher}
                />
                <AuthenticatedRoute
                    exact
                    path='/notifications'
                    component={Notifications}
                />

                {/* Nav Tab Bar IS NOT Rendered */}
                <AuthenticatedRoute
                    exact
                    path='/onboarding/phone'
                    component={PhoneNumberInput}
                />
                <AuthenticatedRoute
                    exact
                    path='/onboarding/phoneVerification'
                    component={PhoneVerification}
                />
                <AuthenticatedRoute
                    exact
                    path='/onboarding/associateGroup'
                    component={OnboardAssociateGroup}
                />

                <AuthenticatedRoute
                    exact
                    path='/joinGroup'
                    component={JoinGroup}
                />
                <AuthenticatedRoute
                    exact
                    path='/joinGroup/:name/:groupId/confirmation'
                    component={JoinGroupConfirmation}
                />
                <AuthenticatedRoute
                    exact
                    path='/onboarding/imageUpload'
                    component={ImageUpload}
                />
                <AuthenticatedRoute
                    exact
                    path='/onboarding/bio'
                    component={UserBio}
                />
                <AuthenticatedRoute
                    exact
                    path='/landonboard'
                    component={CommunityOnboardingLanding}
                />
                <AuthenticatedRoute
                    exact
                    path='/communityonboarding'
                    component={CommunityOnboarding}
                />
                <AuthenticatedRoute
                    exact
                    path='/communityonboarding/confirmation'
                    component={CommunityOnboardConfirmation}
                />
                <AuthenticatedRoute
                    exact
                    path='/invite/:groupName/:memberCode'
                    component={SignUp}
                />
                <AuthenticatedRoute
                    exact
                    path='/skillSelection'
                    component={SkillSelectionPage}
                />
                <AuthenticatedRoute
                    exact
                    path='/skillCreation/:slug'
                    component={DefineSkill}
                />
                <AuthenticatedRoute
                    exact
                    path='/skillsConfirmation'
                    component={SkillsConfirmationPage}
                />

                <Route
                    exact
                    path='/create-task'
                    render={(props) => (
                        <TaskDescription {...props} taskType='HOME' />
                    )}
                />
                <Route
                    exact
                    path='/create-task/date'
                    render={(props) => (
                        <TaskSchedule {...props} taskType='HOME' />
                    )}
                />
                <Route
                    exact
                    path='/create-task/start-time'
                    render={(props) => (
                        <TaskStartTime {...props} taskType='HOME' />
                    )}
                />
                <Route
                    exact
                    path='/create-task/location'
                    render={(props) => (
                        <TaskLocations {...props} taskType='HOME' />
                    )}
                />
                <Route
                    exact
                    path='/create-task/skills'
                    render={(props) => (
                        <TaskSkillSearch {...props} taskType='HOME' />
                    )}
                />
                <Route
                    exact
                    path='/create-task/invites'
                    render={(props) => (
                        <TaskHomeInvites {...props} taskType='HOME' />
                    )}
                />
                <Route
                    exact
                    path='/create-task/confirmation'
                    render={(props) => (
                        <ConfirmTaskPost {...props} taskType='HOME' />
                    )}
                />

                <Route
                    exact
                    path='/create-task/:groupId/:groupCode'
                    render={(props) => (
                        <TaskDescription {...props} taskType='GROUP' />
                    )}
                />
                <Route
                    exact
                    path='/create-task/:groupId/:groupCode/date'
                    render={(props) => (
                        <TaskSchedule {...props} taskType='GROUP' />
                    )}
                />
                <Route
                    exact
                    path='/create-task/:groupId/:groupCode/start-time'
                    render={(props) => (
                        <TaskStartTime {...props} taskType='GROUP' />
                    )}
                />
                <Route
                    exact
                    path='/create-task/:groupId/:groupCode/location'
                    render={(props) => (
                        <TaskLocations {...props} taskType='GROUP' />
                    )}
                />
                <Route
                    exact
                    path='/create-task/:groupId/:groupCode/skills'
                    render={(props) => (
                        <TaskSkillSearch {...props} taskType='GROUP' />
                    )}
                />
                <Route
                    exact
                    path='/create-task/:groupId/:groupCode/invites'
                    render={(props) => (
                        <TaskGroupInvites {...props} taskType='GROUP' />
                    )}
                />
                <Route
                    exact
                    path='/create-task/:groupId/:groupCode/confirmation'
                    render={(props) => (
                        <ConfirmGroupTaskPost {...props} taskType='GROUP' />
                    )}
                />
                <AuthenticatedRoute
                    exact
                    path='/task/:taskId'
                    component={TaskPostedDetails}
                />
                <AuthenticatedRoute
                    exact
                    path='/task/participationRequest/:id'
                    component={TaskPostedDetailsViaPr}
                />
                <AuthenticatedRoute
                    exact
                    path='/skillRequest/:id'
                    component={SkillRequestDetails}
                />
                <AuthenticatedRoute
                    exact
                    path='/manage/:taskId/participation'
                    component={ManageParticipationRequests}
                />
                <AuthenticatedRoute
                    exact
                    path='/request/:skillId/participation'
                    component={RequestTaskParticipation}
                />
                <AuthenticatedRoute
                    exact
                    path='/request/:skillId/participation/confirmation'
                    component={RequestTaskParticipationConfirmationScreen}
                />
                <AuthenticatedRoute
                    exact
                    path='/cancel/:taskId/participation'
                    component={CancelParticipationRequest}
                />
                <AuthenticatedRoute
                    exact
                    path='/cancel/participationRequest/:taskId/confirmation'
                    component={CancelParticipationRequestConfirmationScreen}
                />
                <AuthenticatedRoute
                    exact
                    path='/cancel/helping/:recipient/:taskId'
                    component={CancelHelpingRecipient}
                />
                <AuthenticatedRoute
                    exact
                    path='/cancel/helping/:recipient/:taskId/confirmation'
                    component={CancelHelpingRecipientConfirmationScreen}
                />
                <AuthenticatedRoute
                    exact
                    path='/delete/jobPost/:taskId'
                    component={DeleteJobPost}
                />
                <AuthenticatedRoute
                    exact
                    path='/delete/jobPost/:taskId/confirmation'
                    component={DeleteJobPostConfirmationScreen}
                />
                <AuthenticatedRoute
                    exact
                    path='/delete/:taskId'
                    component={DeleteTask}
                />
                <AuthenticatedRoute
                    exact
                    path='/delete/:taskId/confirmation'
                    component={DeleteTaskConfirmationScreen}
                />

                <AuthenticatedRoute
                    exact
                    path='/completeTaskPosted/recipient/:taskId'
                    component={TaskPostedCredits}
                />
                <AuthenticatedRoute
                    exact
                    path='/completeTaskPosted/recipient/:taskId/participants'
                    component={TaskPostedParticipants}
                />
                <AuthenticatedRoute
                    exact
                    path='/completeTaskPosted/recipient/:taskId/rating'
                    component={TaskParticipantRatings}
                />
                <AuthenticatedRoute
                    exact
                    path='/completeTaskPosted/recipient/:taskId/review'
                    component={TaskParticipantReviews}
                />
                <AuthenticatedRoute
                    exact
                    path='/completeTaskPosted/recipient/:taskId/confirmation'
                    component={TaskPostedCompletedConfirmation}
                />
                <AuthenticatedRoute
                    exact
                    path='/completeTaskPosted/recipient/:taskId/gratitudePot'
                    component={GratitudePot}
                />

                <AuthenticatedRoute
                    exact
                    path='/completeTaskPosted/provider/:taskId'
                    component={CompleteTaskPostedByProvider}
                />
                <AuthenticatedRoute
                    exact
                    path='/completeTaskPosted/provider/:taskId/rating'
                    component={TaskPostedProviderRating}
                />
                <AuthenticatedRoute
                    exact
                    path='/completeTaskPosted/provider/:taskId/review'
                    component={TaskPostedProviderReview}
                />
                <AuthenticatedRoute
                    exact
                    path='/completeTaskPosted/provider/:taskId/confirmation'
                    component={TaskPostedCompletedByProviderConfirmation}
                />

                <AuthenticatedRoute
                    exact
                    path='/requestSkill/:provider/:skillName/:skillId/date'
                    component={TaskDayAvl}
                />
                <AuthenticatedRoute
                    exact
                    path='/requestSkill/:provider/:skillName/:skillId/time'
                    component={TaskTimeAvl}
                />
                <AuthenticatedRoute
                    exact
                    path='/requestSkill/:provider/:skillName/:skillId/location'
                    component={TaskLocation}
                />
                <AuthenticatedRoute
                    exact
                    path='/requestSkill/:provider/:skillName/:skillId/message'
                    component={RequestMsg}
                />
                <AuthenticatedRoute
                    exact
                    path='/requestSkill/:provider/:skillName/confirmation'
                    component={RequestTaskConfirmation}
                />
                <AuthenticatedRoute
                    exact
                    path='/accept/skillRequest/:jobId'
                    component={AcceptServiceRequest}
                />
                <AuthenticatedRoute
                    exact
                    path='/accept/skillRequest/:jobId/confirmation'
                    component={AcceptServiceRequestConfirmationScreen}
                />
                <AuthenticatedRoute
                    exact
                    path='/deny/skillRequest/:jobId/confirmation'
                    component={DeclineServiceRequestConfirmationScreen}
                />
                <AuthenticatedRoute
                    exact
                    path='/completeTask/recipient/:taskId'
                    component={TaskCreditsInput}
                />
                <AuthenticatedRoute
                    exact
                    path='/completeTask/recipient/:taskId/participants'
                    component={TaskParticipants}
                />
                <AuthenticatedRoute
                    exact
                    path='/completeTask/recipient/:taskId/rating'
                    component={ParticipantRating}
                />
                <AuthenticatedRoute
                    exact
                    path='/completeTask/recipient/:taskId/review'
                    component={ParticipantReview}
                />
                <AuthenticatedRoute
                    exact
                    path='/completeTask/recipient/:taskId/confirmation'
                    component={TaskCompleteConfirmation}
                />
                <AuthenticatedRoute
                    exact
                    path='/completeTask/provider/:taskId'
                    component={CompleteTask}
                />
                <AuthenticatedRoute
                    exact
                    path='/completeTask/provider/:taskId/rating'
                    component={TaskRating}
                />
                <AuthenticatedRoute
                    exact
                    path='/completeTask/provider/:taskId/review'
                    component={TaskReview}
                />
                <AuthenticatedRoute
                    exact
                    path='/completeTask/provider/:taskId/confirmation'
                    component={TaskCompletedConfirmation}
                />
                <AuthenticatedRoute exact path='/about' component={AboutUs} />
                <AuthenticatedRoute
                    exact
                    path='/payments'
                    component={ContributeViaPayPal}
                />
                <AuthenticatedRoute
                    exact
                    path='/settings'
                    component={Settings}
                />
                <AuthenticatedRoute
                    exact
                    path='/settings/paymentmethods'
                    component={PaymentMethods}
                />
                <AuthenticatedRoute
                    exact
                    path='/settings/payoutmethods'
                    component={PayoutMethods}
                />
                <AuthenticatedRoute
                    exact
                    path='/settings/paymentmethods/add'
                    component={AddPaymentMethod}
                />
                <AuthenticatedRoute
                    exact
                    path='/settings/profile'
                    component={ProfileSettings}
                />
                <AuthenticatedRoute
                    exact
                    path='/transactionHistory'
                    component={TransactionHistory}
                />

                {/*Logged in users gain access to legal screens*/}
                <AuthenticatedRoute
                    path='/loggedInTermsOfUse'
                    component={TermsOfUsePage}
                />
                <AuthenticatedRoute
                    path='/loggedInPrivacyPolicy'
                    component={PrivacyPolicyPage}
                />
                <AuthenticatedRoute path='/loggedInFAQ' component={FAQPage} />
                <AuthenticatedRoute
                    path='/settings/gratitudePot'
                    component={GratitudeSettings}
                />
            </IonRouterOutlet>
        </IonContent>
    );
};

export const UnauthRoutes = ({ setToken }: { setToken: (string) => void }) => (
    <Switch>
        <Route path='/FAQ' component={FAQPage} />
        {/*Logged out users have access to signup modals &*/}
        {/*legal pages from direct link*/}
        <Route path='/TermsOfUse' component={TermsOfUsePage} />

        <Route path='/PrivacyPolicy' component={PrivacyPolicyPage} />
        <Route path='/TermsOfUseModal' component={TermsOfUseModal} />
        <Route path='/PrivacyPolicyModal' component={PrivacyPolicyModal} />
        <Route exact path='/forgotPassword' component={ForgotPassword} />
        <Route
            exact
            path='/forgotPassword/:email/confirmation'
            component={ForgotPasswordEmailConfirmation}
        />
        <Route exact path='/getStarted' component={GetStarted} />
        <Route
            exact
            path='/signup'
            render={(props) => <SignUp {...props} setToken={setToken} />}
        />
        <Route exact path='/login' component={Login} />
        <Route exact path='/landing' component={Landing} />
        <Route exact path='/' component={Landing} />
        <Route path='/'>
            {/* Redirect all signed-out misc urls to landing */}
            <Redirect to='/' />
        </Route>
    </Switch>
);
