// importing modules
import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
// importing components
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonRow,
    IonPage,
} from '@ionic/react';
import CreateTaskTabs from '../UI/CreateTaskTabs/CreateTaskTabs';
import Calendar from 'react-calendar';
import UserImg from '../UI/UserImg/UserImg';
import GroupImg from '../UI/GroupImg/GroupImg';
import CreateTaskFooter from 'components/FooterWithChildren/FooterWithChildren';
import moment from 'moment-timezone';
import momentTZ from 'moment-timezone';
import Strokes from 'components/Strokes';
// importing assets
// importing helpers
import {
    getLocalStorage,
    updateLocalStorage,
} from 'helpers/localStorage/localStorageHelper';
// importing styles
import './Schedule.css';

export default ({ taskType }: { taskType: string }) => {
    const history = useHistory();
    const location = useLocation();
    const { groupId, groupCode } = useParams<{
        groupId: string;
        groupCode: string;
    }>();
    const [selectedDate, setSelectedDate] = useState<any>(null);

    useEffect(() => {
        if (getLocalStorage('createTask')?.date !== null) {
            const createTask = getLocalStorage('createTask');
            setSelectedDate(
                createTask.date === 'Undecided'
                    ? 'Undecided'
                    : new Date(createTask.date)
            );
        } else {
            setSelectedDate(null);
        }
    }, [location]);

    const handleSelectedDate = (date: any) => setSelectedDate(date);

    let content; //error msg holder
    const currentTime = momentTZ(moment()) //grab currentTime according to local timezone
        .tz(getLocalStorage('tz')) //grab tz
        .format('YYYY-MM-DD'); //format

    if (selectedDate != null && selectedDate != 'Undecided') {
        //if date isn't null or undecided
        var dateString = selectedDate.toString(); //stringify to manipulate
        var dateObj = new Date(dateString); //create date var
        var momentObj = moment(dateObj); //convert to moment
        var pickedDateString = momentObj.format('YYYY-MM-DD'); //format
    }

    if (currentTime > pickedDateString) {
        //If the date is before current date, set error msg
        content = (
            <p className='startTime__dials--msg--na'>
                x The task date cannot be before the current date.
            </p>
        );
    }

    const handleDateConfirmation = () => {
        if (currentTime <= pickedDateString || selectedDate == 'Undecided') {
            //If date is after or during current date
            selectedDate === 'Undecided'
                ? updateLocalStorage('createTask', 'date', 'Undecided')
                : updateLocalStorage('createTask', 'date', selectedDate);

            if (selectedDate === 'Undecided') {
                updateLocalStorage('createTask', 'startTime', '');
                updateLocalStorage('createTask', 'endTime', '');
            }

            if (taskType === 'HOME') {
                history.push('/create-task/start-time');
            } else if (taskType === 'GROUP') {
                history.push(`/create-task/${groupId}/${groupCode}/start-time`);
            }
        }

        if (selectedDate === 'Undecided') {
            history.push('/create-task/location');
        }
    };

    let img;
    if (taskType === 'HOME' && getLocalStorage('currentAdminGroupId') == 0) {
        img = <UserImg />;
    } else if (
        taskType === 'GROUP' &&
        getLocalStorage('currentAdminGroupId') == 0
    ) {
        img = <UserImg />;
    } else {
        img = <GroupImg />;
    }

    return (
        <IonPage>
            <IonContent>
                <IonGrid>
                    {/* calend header */}
                    <IonRow className='calend__header'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='calend__header--container'
                        >
                            <Strokes top={true} />

                            <CreateTaskTabs disablePrevStep={false} />
                            {img}
                        </IonCol>
                    </IonRow>
                    {/* text content */}
                    <IonRow className='calend__textContent'>
                        <IonCol
                            className='calend__textContent--container'
                            sizeSm='12'
                            sizeXs='12'
                        >
                            <h1 className='calend__textContent--title'>
                                Choose a <span>date</span>
                            </h1>
                        </IonCol>
                    </IonRow>
                    {/* calendar */}
                    <IonGrid>
                        <IonRow className='calendar__container'>
                            <IonCol
                                sizeXs='12'
                                sizeSm='12'
                                className='calendar__wrap'
                            >
                                <IonRow className='calendar__row'>
                                    <Calendar
                                        onChange={handleSelectedDate}
                                        value={
                                            selectedDate === 'Undecided'
                                                ? null
                                                : selectedDate
                                        }
                                        prev2Label=''
                                        next2Label=''
                                        defaultValue={
                                            selectedDate === 'Undecided'
                                                ? null
                                                : selectedDate
                                        }
                                        className='react-calendar'
                                    />
                                </IonRow>
                                <IonRow className='startDate__form--row-tbd'>
                                    <div
                                        onClick={() =>
                                            setSelectedDate('Undecided')
                                        }
                                        className={
                                            selectedDate === 'Undecided'
                                                ? 'startTime__content--day--na startTime__content--day--active'
                                                : 'startTime__content--day--na'
                                        }
                                    >
                                        Date hasn't been decided
                                    </div>
                                </IonRow>
                                {/* <IonCol
                                    className='calendar__text--wrap'
                                    sizeSm='12'
                                    sizeXs='12'
                                >
                                    <p className='calendar__text'>
                                        <span className='calendar__text--span'></span>
                                    </p>
                                </IonCol> */}
                            </IonCol>
                            {/* message */}
                            <IonRow className='startTime__dials--msg--wrap'>
                                <IonCol
                                    className='startTime__dials--msg'
                                    sizeSm='12'
                                    sizeXs='12'
                                >
                                    {content}
                                </IonCol>
                            </IonRow>
                        </IonRow>
                    </IonGrid>
                </IonGrid>
            </IonContent>
            <CreateTaskFooter>
                <IonButton
                    fill='clear'
                    disabled={selectedDate === null}
                    color='ion-primary'
                    onClick={handleDateConfirmation}
                    className='footerWithChild__btn'
                    id='task_from_home_date_btn'
                >
                    Continue
                </IonButton>
            </CreateTaskFooter>
        </IonPage>
    );
};
