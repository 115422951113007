// importing modules
import React from 'react';
import moment from 'moment-timezone';
import { NavLink, useHistory } from 'react-router-dom';
// importing components
import { IonButton, IonCol, IonImg, IonSpinner, IonIcon } from '@ionic/react';
// importing assets
import { informationCircleOutline } from 'ionicons/icons';
// importing utilities
import { time_credit_conversion } from '../../../utils/timeConversion';
import { getLocalStorage } from '../../../helpers/localStorage/localStorageHelper';
// importing graphql
import {
    GetPendingJobsById_pendingJobs,
    GetPendingJobsById_pendingJobs_recipient,
    GetPendingJobsById_pendingJobs_provider,
} from '../../../GraphQL/__generated__/GetPendingJobsById';

const HelpingTaskItem: React.FC<{
    task: GetPendingJobsById_pendingJobs;
    otherProfile:
        | GetPendingJobsById_pendingJobs_recipient
        | GetPendingJobsById_pendingJobs_provider;
}> = ({ task, otherProfile }) => {
    const history = useHistory();
    const taskStartTime = moment(task.startTime).tz(
        getLocalStorage('tz'),
        false
    );
    const taskEndTime = moment(task.endTime).tz(getLocalStorage('tz'), false);
    let taskTime;
    let btns;

    // conditionals to render component based on task time/status
    if (moment() < taskStartTime) {
        // check if the task hasnt started
        taskTime = (
            <p className='taskItem__info--time'>
                &#x02022;&nbsp;Starts {taskStartTime.fromNow()} &nbsp;{' '}
                <span>- Helping</span>
            </p>
        );
        btns = (
            <React.Fragment>
                <IonButton
                    fill='clear'
                    color='ion-primary'
                    className='taskItem__btn--send'
                >
                    Send Message
                </IonButton>
                <IonButton
                    onClick={() =>
                        history.push(`/cancel/helping/recipient/${task.id}`)
                    }
                    fill='clear'
                    color='ion-primary'
                    className='taskItem__btn--cancel'
                >
                    Cancel Task
                </IonButton>
            </React.Fragment>
        );
    } else if (moment().isBetween(taskStartTime, taskEndTime)) {
        // check if the task is in progress
        taskTime = (
            <p className='taskItem__info--time'>
                &#x02022;&nbsp;Ends {taskEndTime.fromNow()} &nbsp;{' '}
                <span>- Helping</span>
            </p>
        );
        if (task.Status.toString() === 'RECIPIENT_HAS_PROPOSED_OFFER') {
            btns = (
                <IonButton
                    fill='clear'
                    color='ion-primary'
                    className='completeTask__btn'
                >
                    <NavLink
                        to={{
                            pathname: `/completeTask/provider/${task.id}`,
                            state: {
                                id: task.id,
                            },
                        }}
                        className='completeTask__btn--link'
                    >
                        Complete This Task
                    </NavLink>
                </IonButton>
            );
        } else {
            btns = (
                <div className='completeTask__btn--waiting'>
                    <IonSpinner
                        className='completeTask__btn--waiting--spinner'
                        name='crescent'
                    />
                    <p className='completeTask__btn--waiting--text'>
                        Waiting on Recipient
                    </p>
                </div>
            );
        }
    } else if (moment() > taskEndTime) {
        // check if the task has ended
        taskTime = (
            <p className='taskItem__info--time'>
                &#x02022;&nbsp;Ended {taskEndTime.fromNow()} &nbsp;{' '}
                <span>- Helping</span>
            </p>
        );
        if (task.Status.toString() === 'RECIPIENT_HAS_PROPOSED_OFFER') {
            btns = (
                <IonButton
                    fill='clear'
                    color='ion-primary'
                    className='completeTask__btn'
                >
                    <NavLink
                        to={{
                            pathname: `/completeTask/provider/${task.id}`,
                            state: {
                                id: task.id,
                            },
                        }}
                        className='completeTask__btn--link'
                    >
                        Complete This Task
                    </NavLink>
                </IonButton>
            );
        } else {
            btns = (
                <div className='completeTask__btn--waiting'>
                    <IonSpinner
                        className='completeTask__btn--waiting--spinner'
                        name='crescent'
                    />
                    <p className='completeTask__btn--waiting--text'>
                        Waiting on Recipient
                    </p>
                </div>
            );
        }
    }

    return (
        <IonCol
            className='taskItem'
            style={{ backgroundColor: '#f3f5f7' }}
            sizeSm='11'
            sizeXs='11'
        >
            <IonIcon
                onClick={() => history.push(`/skillRequest/${task.id}`)}
                icon={informationCircleOutline}
                className='taskItem__info--icon'
            />
            <IonCol sizeXs='12' sizeSm='12' className='taskItem__info'>
                <IonCol sizeXl='3' sizeLg='3' sizeMd='3' sizeXs='3' sizeSm='3'>
                    <div className='taskItem__img--wrap'>
                        <IonImg
                            onClick={() =>
                                history.push(`/user/${otherProfile.uid}`)
                            }
                            className='taskItem__img'
                            src={otherProfile.profilePicture}
                        />
                    </div>
                </IonCol>
                <IonCol className='taskItem__info--wrap' sizeXs='9' sizeSm='9'>
                    <h3 className='taskItem__info--name'>
                        {task.Skill.name} w/{' '}
                        {otherProfile.firstName + ' ' + otherProfile.lastName}
                    </h3>
                    {taskTime}
                    <p className='completedTasksItem__info--date'>
                        {moment(task.endTime)
                            .tz(getLocalStorage('tz'), false)
                            .format('dddd, MMMM Do YYYY')}
                    </p>
                </IonCol>
            </IonCol>
            {task.requestMessage.length > 0 &&
            task.Status.toString() !== 'RECIPIENT_HAS_PROPOSED_OFFER' ? (
                <IonCol sizeXs='12' sizeSm='12'>
                    <p className='taskItem__info--text'>
                        &quot; {task.requestMessage} &quot;
                    </p>
                </IonCol>
            ) : null}
            {task.Status.toString() === 'RECIPIENT_HAS_PROPOSED_OFFER' && (
                <IonCol sizeXs='12' sizeSm='12'>
                    <p className='taskItem__info--text'>
                        <span>
                            Recipient has proposed{' '}
                            {time_credit_conversion(task.recipientOfferCredits)}{' '}
                            Balance Tokens
                        </span>
                    </p>
                </IonCol>
            )}
            <IonCol sizeXs='12' sizeSm='12' className='taskItem__btn--wrap'>
                {btns}
            </IonCol>
        </IonCol>
    );
};

export default HelpingTaskItem;
