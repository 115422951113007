// importing modules
import React from 'react';
// importing components
import { IonRow } from '@ionic/react';
import ParticipationRequestItem from '../IncomingParticipationRequestItem/ParticipationRequestItem';
// importing types
import { GetProfileJobsPosted_profile_jobsPosted } from '../../../GraphQL/__generated__/GetProfileJobsPosted';

const ParticipationRequestList: React.FC<{
    jobsPosts: GetProfileJobsPosted_profile_jobsPosted[];
}> = ({ jobsPosts }) => {
    return (
        <>
            {jobsPosts.length > 0 ? (
                jobsPosts.map((pr) => {
                    return (
                        <ParticipationRequestItem key={pr.id} jobPost={pr} />
                    );
                })
            ) : (
                <IonRow className='tasksPosted__text'>
                    <p>
                        Your task will appear here when you
                        <br />
                        accept a participant for your task.
                    </p>
                </IonRow>
            )}
        </>
    );
};

export default ParticipationRequestList;
