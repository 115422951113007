import React from 'react';
import { useHistory } from 'react-router-dom';
import { IonButton } from '@ionic/react';
import { useAuth } from '../context/firebase/authContext';

const Logout: React.FC = () => {
    const history = useHistory();
    const { logout } = useAuth();
    return (
        <IonButton
            fill='clear'
            color='ion-primary'
            className='menu__btn--logout'
            style={{ color: '#fff' }}
            onClick={() => {
                localStorage.clear();
                // firebase auth hook here
                // causes the app to crash
                logout();
                history.push('/');
            }}
        >
            Logout
        </IonButton>
    );
};

export default Logout;
