import React from 'react';
import { IonCol } from '@ionic/react';
import './SkillsOfferedByGroup.css';

const SkillsOfferedByGroup: React.FC<{
    firstName: String;
    skillDescription: String;
    skillTitle: String;
    tasksCompleted: Number;
}> = ({ firstName, skillDescription, skillTitle, tasksCompleted }) => {
    return (
        <IonCol className='groupSkills__col' sizeXs='12' sizeSm='12'>
            <IonCol
                sizeXs='9'
                sizeSm='9'
                className='ion-padding groupSkills__content'
            >
                <p className='groupSkills__content--skill'>{skillTitle}</p>
                <p className='groupSkills__content--completed'>
                    {tasksCompleted} Tasks Completed
                </p>
                <p className='groupSkills__content--text'>{skillDescription}</p>
            </IonCol>
            <IonCol sizeXs='3' sizeSm='3' className='groupSkills__request--btn'>
                <p className='skillsOffered__request--btn--text'>Request</p>
                <p className='skillsOffered__request--btn--name'>Us</p>
            </IonCol>
        </IonCol>
    );
};

export default SkillsOfferedByGroup;
