// importing modules
import React from 'react';
import { useHistory } from 'react-router-dom';
// importing components
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonIcon,
    IonImg,
    IonRow,
    IonModal,
} from '@ionic/react';
import UserImg from '../../../../components/UserImg/UserImg';
import Strokes from 'components/Strokes';
// importing assets
import { searchOutline, arrowBackOutline, closeOutline } from 'ionicons/icons';
// importing styles
import './TaskInviteModal.css';

const TaskInviteModal: React.FC<{
    showInviteModal: boolean;
    handleInviteModal: () => void;
}> = ({ showInviteModal, handleInviteModal }) => {
    const history = useHistory();

    // add graphql-query to retrieve participants from the current community

    // fire a mutation to add new participants to the task

    return (
        <IonModal isOpen={showInviteModal}>
            <IonContent>
                <IonGrid>
                    {/* taskInvite header */}
                    <IonRow className='taskInvite__header'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='taskInvite__header--container'
                        >
                            <Strokes top={true} />
                            <IonCol
                                sizeXs='12'
                                sizeSm='12'
                                sizeMd='12'
                                className='completeTaskNav__btns--wrap'
                            >
                                <IonButton
                                    className='completeTaskNav__btn'
                                    fill='clear'
                                    color='ion-primary'
                                    onClick={handleInviteModal}
                                >
                                    <IonIcon
                                        className='completeTaskNav__btn--icon'
                                        icon={arrowBackOutline}
                                    />
                                </IonButton>
                                <IonButton
                                    className='completeTaskNav__btn'
                                    onClick={() => {
                                        handleInviteModal();
                                        history.push('/home');
                                    }}
                                    color='ion-primary'
                                    fill='clear'
                                >
                                    <IonIcon
                                        className='completeTaskNav__btn--icon'
                                        icon={closeOutline}
                                    />
                                </IonButton>
                            </IonCol>
                            <UserImg />
                        </IonCol>
                    </IonRow>
                    {/* taskInvite header */}

                    {/* text content */}
                    <IonRow className='taskInvite__textContent'>
                        <IonCol
                            className='taskInvite__textContent--container'
                            sizeSm='12'
                            sizeXs='12'
                        >
                            <h1 className='taskInvite__textContent--title'>
                                Add <span>New Participants</span>
                            </h1>
                            <p className='taskInvite__textContent--text'>
                                New Participants will receive Balance Tokens for
                                this task
                            </p>
                        </IonCol>
                    </IonRow>
                    {/* text content */}

                    {/* user invite searchbar */}
                    <IonRow className='skillSelection__searchbar'>
                        <IonCol
                            sizeXs='12'
                            sizeSm='12'
                            className='skillSelection__searchbar--container'
                        >
                            <div className='skillSelection__searchbar--input--wrap'>
                                <IonIcon
                                    className='skillSelection__searchbar--input--icon'
                                    icon={searchOutline}
                                />
                                <input
                                    className='skillSelection__searchbar--input'
                                    type='text'
                                    placeholder='Search a member here...'
                                />
                            </div>
                        </IonCol>
                    </IonRow>

                    <IonRow className='completeTask__btn--container'>
                        <IonCol
                            className='completeTask__btn--wrap'
                            sizeXs='12'
                            sizeSm='12'
                        >
                            <IonButton
                                fill='clear'
                                color='ion-primary'
                                className='createTask__btn'
                            >
                                Continue
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonModal>
    );
};

export default TaskInviteModal;
