// accessible through messages icon which leads to chatroom
// importing modules
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Redirect, useHistory, useParams } from 'react-router';
// importing components
import {
    useIonViewWillEnter,
    useIonViewDidEnter,
    IonButton,
    IonContent,
    IonIcon,
    IonPage,
    IonImg,
    IonList,
} from '@ionic/react';
import Strokes from 'components/Strokes';
// importing assets
import { closeOutline, arrowUpOutline, cameraOutline } from 'ionicons/icons';
import SenderChatBubble from '../UI/SenderChatBubble';
import ReceiverChatBubble from '../UI/ReceiverChatBubble';
import Loading from '../../../components/status/Loading';
import NavBar from '../../../components/NavBar';
// importing graphql utilities - types
import { useMutation } from '@apollo/react-hooks';
import { PUBLISH_CHAT_NOTIFICATION } from '../../../GraphQL/Device/PUBLISH_CHAT_NOTIFICATION/PUBLISH_CHAT_NOTIFICATION';
import {
    PublishChatNotification,
    PublishChatNotificationVariables,
} from '../../../GraphQL/__generated__/PublishChatNotification';
import { useQuery } from '@apollo/react-hooks';
import { USER_PROFILE_IMG } from '../../../GraphQL/Profile/USER_PROFILE_IMG/USER_PROFILE_IMG';
import {
    UserProfileImg,
    UserProfileImgVariables,
} from '../../../GraphQL/__generated__/UserProfileImg';
// importing utilities
import { FirebaseHelper } from '../../../helpers/FirebaseHelper';
import limitStr from '../../../utils/limitString';
// importing styles
import './ChatRoom.css';
// firebase context
import firebase from 'firebase/app';
import 'firebase/firestore';
import { useAuth } from '../../../context/firebase/authContext';

interface Messages {
    id: string;
    sentAt: any;
    message: string;
    sender: string;
    senderName: string;
    seen: boolean;
}

const ChatRoomById: React.FC = () => {
    const { chatId, sender, receiver } = useParams<{
        chatId: string;
        sender: string;
        receiver: string;
    }>();
    const { currentUser } = useAuth();
    const fuser = new FirebaseHelper(currentUser);
    const firestore = firebase.firestore();
    const history = useHistory();
    const [message, setMessage] = useState<string>('');
    const [messages, setMessages] = useState<Messages[]>([]);
    const [isLoadingMessages, setIsLoadingMessages] = useState<boolean>(true);
    const [nodRef, setNodeRef] = useState(false);
    const [publishNotification, publishNotificationDatas] = useMutation<
        PublishChatNotification,
        PublishChatNotificationVariables
    >(PUBLISH_CHAT_NOTIFICATION);

    const scrollToBottom = () => {
        // ! making the change from <Switch /> to <IonRouterOutlet />
        // ! disables the scrollToBottom behavior
        let content = document.querySelector('ion-content');
        if (content === null) return;
        content.scrollToBottom();
    };

    // ref to scroll to bottom
    const newMessage: any = useRef(null);

    // useEffect for new messages to scroll to bottom

    useEffect(() => {
        if (newMessage && newMessage.current) {
            newMessage.current.scrollIntoView({ block: 'center' });
        }
    }, [messages]);

    useEffect(() => {
        scrollToBottom();

        const unsubscribe = firestore
            .collection(`/chatrooms/${chatId}/messages`)
            .orderBy('sentAt')
            .onSnapshot(
                (snapshot) => {
                    const msgs = [];
                    snapshot.forEach((doc) => {
                        const { id } = doc;
                        const msgObj = { ...doc.data(), id };
                        if ((doc.data() as Messages).sender === receiver) {
                            firestore
                                .collection(`/chatrooms/${chatId}/messages`)
                                .doc(id)
                                .update({ seen: true });
                        }

                        msgs.push(msgObj);
                    });
                    setMessages(msgs);
                    setIsLoadingMessages(false);
                    scrollToBottom();
                },
                (err) => console.error(err)
            );

        return () => unsubscribe();
    }, []);

    const { data: otherUserData, loading: otherUserLoading } = useQuery<
        UserProfileImg,
        UserProfileImgVariables
    >(USER_PROFILE_IMG, {
        variables: { uid: receiver },
    });
    const { data, loading } = useQuery<UserProfileImg, UserProfileImgVariables>(
        USER_PROFILE_IMG,
        {
            variables: { uid: sender },
        }
    );

    if (sender != currentUser.uid || receiver == currentUser.uid) {
        return <Redirect to='/' />;
    }

    if (otherUserLoading || loading) {
        return <Loading />;
    }

    // const onPhotoSelect = (e) => {
    //     e.preventDefault();
    //     console.log('photo select');
    // };

    const handleSendMessage = async (e) => {
        e.preventDefault();
        if (message.length === 0 || message === '' || message === null) return;
        const { serverTimestamp } = firebase.firestore.FieldValue;
        firestore
            .collection(`/chatrooms/${chatId}/messages`)
            .add({
                // check if document exists , if true send message
                sentAt: serverTimestamp(),
                message: message,
                sender: sender,
                senderFirstName: data.profile.firstName,
                senderLastName: data.profile.lastName,
                senderImg: data.profile.profilePicture,
                seen: false,
            })
            .then(() => {
                publishNotification({
                    variables: {
                        senderId: sender,
                        recieverId: receiver,
                        textMessage: message,
                        chatId: chatId,
                    },
                })
                    .then(
                        newMessage.current.scrollIntoView({ block: 'center' })
                    )
                    .catch((err) => console.error(err));
                setMessage('');
                scrollToBottom();
            })
            .catch((err) => console.error(err));
    };

    return (
        <IonPage>
            <div>
                <div>
                    <div className='chatRoom__header'>
                        <Strokes top={true} />
                    </div>
                </div>
                <div>
                    <div className='chatRoom__header--details--col'>
                        <div className='chatRoom__details--img--wrap'>
                            <IonImg
                                className='chatRoom__details--img'
                                src={
                                    (!otherUserLoading || !loading) &&
                                    otherUserData.profile?.profilePicture
                                }
                                alt='user'
                            />
                        </div>
                        <p className='chatRoom__details--name'>
                            {(!otherUserLoading || !loading) &&
                                limitStr(
                                    `${otherUserData.profile?.firstName} ${otherUserData.profile?.lastName}`,
                                    15
                                )}
                        </p>
                        <span>
                            <IonIcon
                                onClick={() => history.push('/chat')}
                                icon={closeOutline}
                                className='chatRoom__details--icon'
                            />
                        </span>
                    </div>
                </div>
            </div>
            <IonContent>
                <IonList className='chatRoom__convo messageScroll'>
                    {messages.length > 0 &&
                        messages.map((message) => {
                            const chatBubble =
                                message.sender === fuser.uid ? (
                                    <SenderChatBubble
                                        key={message.id}
                                        {...message}
                                        chatroomId={chatId}
                                    />
                                ) : (
                                    <ReceiverChatBubble
                                        key={message.id}
                                        {...message}
                                    />
                                );
                            return chatBubble;
                        })}
                </IonList>
                <div ref={newMessage}></div>
            </IonContent>
            <div className='chatRoom__input--form'>
                {/* <IonButton
                    className='chatRoom__photo'
                    type='submit'
                    fill='clear'
                    color='ion-primary'
                    onClick={onPhotoSelect}
                >
                    <IonIcon
                        icon={cameraOutline}
                        className='chatRoom__photo--icon'
                    />
                </IonButton> */}
                <textarea
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    // type="text"
                    autoComplete='off'
                    className='chatRoom__input'
                    placeholder='Message...'
                ></textarea>

                <IonButton
                    onClick={handleSendMessage}
                    type='submit'
                    fill='clear'
                    color='ion-primary'
                    className='chatRoom__submit'
                >
                    <IonIcon
                        className='chatRoom__submit--icon'
                        icon={arrowUpOutline}
                    />
                </IonButton>
            </div>
            <NavBar />
        </IonPage>
    );
};

export default ChatRoomById;
