// importing modules
import React, { useState } from 'react';
import moment from 'moment-timezone';
import { useHistory } from 'react-router-dom';
// importing components
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonToast,
    IonModal,
    IonIcon,
    IonRow,
} from '@ionic/react';

// importing assets
import { closeOutline, checkmarkOutline } from 'ionicons/icons';

interface Props {
    showModal: boolean;
    setModal: Function;
    paymentMethods: any;
    setPaymentMethod: Function;
    currentActive: any;
}

const MethodSelectionModal: React.FC<Props> = ({
    showModal,
    setModal,
    paymentMethods,
    setPaymentMethod,
    currentActive,
}) => {
    const history = useHistory();
    const [showToast, setShowToast] = useState(false);
    const handleClick = (method) => {
        setShowToast(true);
        setPaymentMethod(method);
    };
    return (
        <IonModal isOpen={showModal}>
            <IonContent>
                <IonGrid className='stripe_onboarding_grid'>
                    <IonToast
                        cssClass='requestDetails__location--clipboard--toast'
                        isOpen={showToast}
                        onDidDismiss={() => setShowToast(false)}
                        message='Payment Method Changed!'
                        duration={1000}
                    />
                    <IonRow>
                        <IonCol sizeXs='12' sizeSm='12' sizeMd='12'>
                            <IonButton
                                onClick={() => {
                                    setModal(false);
                                }}
                                fill='clear'
                            >
                                <IonIcon
                                    style={{ color: 'black' }}
                                    className='reqTaskNav__btn--icon'
                                    icon={closeOutline}
                                />
                            </IonButton>
                        </IonCol>
                    </IonRow>
                    <IonRow className='gratitude_pot_header--container'>
                        <h1 className='gratitude_pot_header'>
                            Select <span>Payment Method</span>
                        </h1>
                    </IonRow>
                    <IonRow className='settings__btn--container'>
                        <IonGrid className='payment_methods_container'>
                            <IonRow className='settings__form--row'>
                                <IonRow className='payment_methods_sub_container'>
                                    {paymentMethods?.map((method) => (
                                        <IonCol
                                            key={method.created}
                                            className='settings__col payment_method_card'
                                            sizeXs='12'
                                            sizeSm='12'
                                            onClick={() => handleClick(method)}
                                        >
                                            <IonCol
                                                sizeXs='12'
                                                sizeSm='12'
                                                className='paymentInfo__content'
                                                style={{
                                                    textTransform: 'capitalize',
                                                }}
                                            >
                                                <div>
                                                    <p className='settings__content--text'>
                                                        {method.card.brand}
                                                    </p>
                                                    <p className='settings__content--description'>
                                                        {method.type}
                                                        {' ••••  ' +
                                                            method.card.last4}
                                                    </p>
                                                </div>

                                                {currentActive.id ==
                                                    method.id && (
                                                    <div>
                                                        <IonIcon
                                                            icon={
                                                                checkmarkOutline
                                                            }
                                                        />
                                                    </div>
                                                )}
                                            </IonCol>
                                        </IonCol>
                                    ))}
                                </IonRow>
                            </IonRow>
                        </IonGrid>
                    </IonRow>
                    <IonRow className='gratitude_pot_header--container'>
                        <IonCol
                            onClick={() => {
                                setModal(false);
                                history.push('/settings/paymentmethods/add');
                            }}
                            className='settings__btn--wrap'
                            sizeXs='12'
                            sizeSm='12'
                        >
                            <IonButton
                                fill='clear'
                                color='ion-primary'
                                className='settings__btn'
                            >
                                Add a Payment Method
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonModal>
    );
};

export default MethodSelectionModal;
