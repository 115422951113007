// importing modules & hooks
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useOnboardingFlow } from '../../utils/onboarding';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import firebase from 'firebase/app';
import 'firebase/auth';
import faker from 'faker';
import moment from 'moment-timezone';
// legal modals
import TermsOfUseModal from 'pages/LegalScreens/TermsOfUseModal';
import PrivacyPolicyModal from 'pages/LegalScreens/PrivacyPolicyModal';
// importing components
import {
    IonCol,
    IonContent,
    IonPage,
    IonIcon,
    IonCheckbox,
    IonGrid,
    IonRow,
} from '@ionic/react';
import Strokes from 'components/Strokes';
// Styled Ion Components
import {
    Form,
    SignUpContainer,
    SignUpWrapper,
    SignUpForm,
    TopStrokes,
    BottomStrokes,
    Logo,
    InputWrap,
    SubmitButton,
    CopyRightCol,
} from './style';
import BackButton from 'components/BackButton';
// InputErrorMsg
import InputErrorMsg from 'components/InputErrorMsg/InputErrorMsg';
// importing assets
import yingLogo from 'assets/ying-logo-large-blk.png';
import { chevronBackOutline, eyeOffOutline, eyeOutline } from 'ionicons/icons';
// importing graphql utilities - types
import { SUBSCRIBEMAILCHIMP } from 'GraphQL/mailchimp/SubscribeMailchimp';
//import { MAILCHIMPPHONENUMBERVERIFICATION }
//from 'GraphQL/mailchimp/MailchimpPhoneNumberVerification';
import {
    MATRICULATE_NEW_USER,
    MATRICULATE_NEW_USERVariables,
} from 'GraphQL/__generated__/MATRICULATE_NEW_USER';
import { gql_MATRICULATE_NEW_USER } from 'GraphQL/Profile/MATRICULATE_NEW_USER/MATRICULATE_NEW_USER';
import {
    AccountCreation,
    AccountCreationVariables,
} from 'GraphQL/__generated__/AccountCreation';
import { USER_ACCOUNT_CREATION } from 'GraphQL/userAccountCreation/userAccountCreation';
import { accountType } from 'GraphQL/__generated__/globalTypes';
// importing utilities
import { yingDebug, yingInfo } from 'utils/debug/YingDebug';
// styles
import './SignUp.css';

type UserSignup = {
    firstName: string;
    lastName: string;
    email: string;
    confirmEmail: string;
    password: string;
    confirmPassword: string;
};

const SignUp = ({ setToken }: { setToken: (string) => void }) => {
    const history = useHistory();
    const { next } = useOnboardingFlow();
    const { memberCode } = useParams<{ memberCode: string }>();
    const [showPassword, setShowPassword] = useState<boolean>(false);
    // legal stuff states
    const [checked, setChecked] = useState<boolean>(false);
    const [showTermsModal, setTermsModal] = useState<boolean>(false);
    const [showPrivacyModal, setPrivacyModal] = useState<boolean>(false);
    // firebase errors
    const [emailInUse, setEmailInUse] = useState(false);
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [weakPassword, setWeakPassword] = useState(false);

    const [mailchimpSub, { data: chimpData }] =
        useLazyQuery(SUBSCRIBEMAILCHIMP);
    const { register, watch, handleSubmit, errors } = useForm<UserSignup>();
    const watchFields = watch(['password', 'email']);

    const [matriculateUser, { data, loading, error }] = useMutation<
        MATRICULATE_NEW_USER,
        MATRICULATE_NEW_USERVariables
    >(gql_MATRICULATE_NEW_USER);

    const [AccountCreation, AccountCreationDatas] = useMutation<
        AccountCreation,
        AccountCreationVariables
    >(USER_ACCOUNT_CREATION);

    const handleOnSubmit = (data: UserSignup) => {
        //TODO make this more robust
        localStorage.setItem('isOnboarding', JSON.stringify(true));

        // reset previous auth error messages
        setEmailInUse(false);
        setInvalidEmail(false);
        setWeakPassword(false);

        firebase
            .auth()
            .createUserWithEmailAndPassword(
                data.email.trim(),
                data.password.trim()
            )
            .then((userCredential) => {
                // moved this here so it only happens if the user acc was created
                try {
                    mailchimpSub({
                        variables: {
                            email: data.email,
                            fname: data.firstName,
                            lname: data.lastName,
                            groupcode: '',
                        },
                    });
                } catch (e) {
                    console.error(e);
                }
                yingInfo('userCredential succeeded', userCredential);

                userCredential.user
                    .getIdToken()
                    .then((idToken) => {
                        setToken(idToken);
                    })
                    .catch((error) => {
                        // Handle Errors here.
                        let errorCode = error.code;
                        let errorMessage = error.message;
                        yingDebug(errorCode, errorMessage);
                    });

                matriculateUser({
                    variables: {
                        uid: userCredential.user.uid,
                        firstName: data.firstName,
                        lastName: data.lastName,
                        photoUrl: userCredential.user.photoURL
                            ? userCredential.user.photoURL
                            : '',
                        email: data.email,
                        tz: moment.tz.guess(),
                    },
                })
                    .then((r) => {
                        const userInfo = r;
                        AccountCreation({
                            variables: {
                                bankaccountid: parseInt(
                                    faker.finance.account(),
                                    10
                                ),
                                userIdFk: userInfo.data.matriculateUser.id,
                                accountType: accountType.BALANCE,
                            },
                        }).then((r) => {
                            AccountCreation({
                                variables: {
                                    bankaccountid: parseInt(
                                        faker.finance.account(),
                                        10
                                    ),
                                    userIdFk: userInfo.data.matriculateUser.id,
                                    accountType: accountType.FIAT,
                                },
                            });
                        });
                    })
                    .catch((e) => {
                        yingDebug(e);
                        console.error(e, 'THIS IS e');
                    });
            })
            .catch(function (error) {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                switch (errorCode) {
                    case 'auth/email-already-in-use':
                        setEmailInUse(true);
                        break;
                    case 'auth/invalid-email':
                        setInvalidEmail(true);
                        break;
                    case 'auth/operation-not-allowed':
                        // if this happens then firebase is incorrectly configured
                        console.error('FATAL: auth misconfiguration');
                        break;
                    case 'auth/weak-password':
                        setWeakPassword(true);
                        break;
                    default:
                        console.error('Unrecognized auth error');
                        break;
                }
                // this is after, so it shows up after 'Unrecognized...' above
                console.error(`${errorCode}: ${errorMessage}`);
            });
    };

    return (
        <IonPage>
            <Strokes top={true} green={false} />
            <IonContent fullscreen>
                <BackButton />
                <SignUpContainer>
                    <SignUpWrapper>
                        {/* signup form */}
                        <SignUpForm>
                            <Logo src={yingLogo} alt='ying-logo' />
                            <IonCol
                                sizeXs='10'
                                sizeSm='10'
                                className='signup__form--wrap'
                            >
                                <Form onSubmit={handleSubmit(handleOnSubmit)}>
                                    <InputWrap>
                                        {errors.firstName && (
                                            <InputErrorMsg errorMsg='First name is required' />
                                        )}
                                        <input
                                            ref={register({
                                                required:
                                                    'First name is required',
                                            })}
                                            type='text'
                                            className={`${
                                                errors.firstName
                                                    ? 'signup__form--input signup__form--input--error'
                                                    : 'signup__form--input'
                                            }`}
                                            name='firstName'
                                            placeholder='First Name'
                                            autoComplete='off'
                                            formNoValidate
                                        />
                                    </InputWrap>
                                    <InputWrap>
                                        {errors.lastName && (
                                            <InputErrorMsg errorMsg='Last name is required' />
                                        )}
                                        <input
                                            ref={register({
                                                required:
                                                    'Last name is required',
                                            })}
                                            type='text'
                                            className={`${
                                                errors.lastName
                                                    ? 'signup__form--input signup__form--input--error'
                                                    : 'signup__form--input'
                                            }`}
                                            name='lastName'
                                            placeholder='Last Name'
                                            autoComplete='off'
                                            formNoValidate
                                        />
                                    </InputWrap>
                                    <InputWrap>
                                        {errors.email?.type === 'required' && (
                                            <InputErrorMsg errorMsg='Email is required' />
                                        )}
                                        {(invalidEmail ||
                                            errors.email?.type ===
                                                'pattern') && (
                                            <InputErrorMsg errorMsg='Email is not valid' />
                                        )}
                                        {emailInUse && (
                                            <InputErrorMsg errorMsg='Email is already in use; use a different address or try logging in' />
                                        )}
                                        <input
                                            ref={register({
                                                required: 'Email is required',
                                                pattern:
                                                    /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                            })}
                                            type='email'
                                            className={`${
                                                errors.email
                                                    ? 'signup__form--input signup__form--input--error'
                                                    : 'signup__form--input'
                                            }`}
                                            name='email'
                                            placeholder='Email Address'
                                            autoComplete='off'
                                            formNoValidate
                                        />
                                    </InputWrap>
                                    <InputWrap>
                                        {errors.confirmEmail?.message && (
                                            <InputErrorMsg errorMsg="Emails don't match" />
                                        )}
                                        <input
                                            ref={register({
                                                validate: (value) =>
                                                    value ===
                                                        watch('email') || (
                                                        <InputErrorMsg errorMsg="Emails don't match" />
                                                    ),
                                                required:
                                                    'Email Confirmation is required',
                                            })}
                                            type='email'
                                            className={`${
                                                errors.confirmEmail
                                                    ? 'signup__form--input signup__form--input--error'
                                                    : 'signup__form--input'
                                            }`}
                                            name='confirmEmail'
                                            placeholder='Confirm Email Address'
                                            autoComplete='off'
                                            formNoValidate
                                        />
                                    </InputWrap>
                                    <InputWrap>
                                        {errors.password?.type ===
                                            'pattern' && (
                                            <p className='signup__error'>
                                                <span className='signup__error--span'>
                                                    x
                                                </span>
                                                &nbsp;Password must have at
                                                least 8 characters.
                                            </p>
                                        )}
                                        {errors.password?.type ===
                                            'required' && (
                                            <InputErrorMsg errorMsg='Password is required' />
                                        )}
                                        {weakPassword && (
                                            <InputErrorMsg errorMsg='Password is too weak' />
                                        )}
                                        <div className='signup__form--input--password--wrap'>
                                            <input
                                                ref={register({
                                                    required:
                                                        'Password is required',
                                                    // pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
                                                    pattern: /^.{8,}$/,
                                                })}
                                                type={
                                                    showPassword
                                                        ? 'text'
                                                        : 'password'
                                                }
                                                className={`${
                                                    errors.password
                                                        ? 'signup__form--input signup__form--input--error'
                                                        : 'signup__form--input'
                                                }`}
                                                name='password'
                                                placeholder='Password'
                                                autoComplete='off'
                                                formNoValidate
                                            />
                                            <IonIcon
                                                onClick={() =>
                                                    setShowPassword(
                                                        !showPassword
                                                    )
                                                }
                                                icon={
                                                    showPassword
                                                        ? eyeOutline
                                                        : eyeOffOutline
                                                }
                                                className='signup__form--input--password--icon'
                                            />
                                        </div>
                                    </InputWrap>
                                    <InputWrap>
                                        {errors.confirmPassword &&
                                            errors.confirmPassword.message && (
                                                <InputErrorMsg errorMsg="Passwords don't match" />
                                            )}
                                        <input
                                            ref={register({
                                                validate: (value) =>
                                                    value ===
                                                        watch('password') || (
                                                        <InputErrorMsg errorMsg="Passwords don't match" />
                                                    ),
                                                required:
                                                    'Password Confirmation is required',
                                            })}
                                            type={
                                                showPassword
                                                    ? 'text'
                                                    : 'password'
                                            }
                                            className={`${
                                                errors.confirmPassword
                                                    ? 'signup__form--input signup__form--input--error'
                                                    : 'signup__form--input'
                                            }`}
                                            name='confirmPassword'
                                            placeholder='Confirm Password'
                                            autoComplete='off'
                                            formNoValidate
                                        />
                                    </InputWrap>
                                    <InputWrap>
                                        <div className='agreementCheckbox'>
                                            <IonGrid>
                                                <IonRow>
                                                    <IonCol size='1'>
                                                        <IonCheckbox
                                                            id='legalCheckbox'
                                                            onIonChange={(e) =>
                                                                setChecked(
                                                                    e.detail
                                                                        .checked
                                                                )
                                                            }
                                                        />
                                                    </IonCol>
                                                    <IonCol size='10'>
                                                        &nbsp;&nbsp;
                                                        <span>
                                                            I accept the Terms
                                                            of Use, Privacy
                                                            Policy, and confirm
                                                            that I am at least
                                                            18 years of age or
                                                            older.
                                                        </span>
                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid>
                                        </div>
                                    </InputWrap>{' '}
                                    {/*On the change of the checkbox, set its useState variable*/}
                                    <SubmitButton
                                        id='subBtn'
                                        fill='clear'
                                        color='ion-primary'
                                        type='submit'
                                        className='signup__form--submit'
                                        disabled={!checked} //if checkbox usestate variable is not checked, disable button
                                    >
                                        Create An Account
                                    </SubmitButton>
                                </Form>
                            </IonCol>
                            {/* copyright */}
                            <InputWrap className='copyRightWrap'>
                                <CopyRightCol
                                    sizeXs='10'
                                    sizeSm='10'
                                    className='signup__copyright--container'
                                >
                                    <p className='signup__copyright--text'>
                                        By continuing, you are indicating that
                                        you agree with our{' '}
                                        <a
                                            className='signup__copyright--link'
                                            onClick={() => setTermsModal(true)}
                                        >
                                            Terms
                                        </a>
                                        {/* when this link is clicked, open the terms modal */}{' '}
                                        and{' '}
                                        <span className='signup__copyright--link'>
                                            <a
                                                className='signup__copyright--link'
                                                onClick={() =>
                                                    setPrivacyModal(true)
                                                }
                                            >
                                                Privacy Policy
                                            </a>
                                            {/*when this link is clicked, open the privacy modal */}
                                        </span>
                                    </p>
                                </CopyRightCol>
                            </InputWrap>
                        </SignUpForm>
                    </SignUpWrapper>
                </SignUpContainer>
                {/* <Strokes bot={true} green={false} /> */}
                <TermsOfUseModal
                    showModal={showTermsModal}
                    setModal={setTermsModal}
                >
                    Terms
                </TermsOfUseModal>{' '}
                {/*Render the terms modal on the page*/}
                <PrivacyPolicyModal
                    showModal={showPrivacyModal}
                    setModal={setPrivacyModal}
                >
                    Terms
                </PrivacyPolicyModal>
                {/*Render the privacy modal on the page*/}
            </IonContent>
        </IonPage>
    );
};

export default SignUp;
