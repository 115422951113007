// importing modules
import React, { useState } from 'react';
import { useAuth } from 'context/firebase/authContext';
import moment from 'moment-timezone';
// importing components
import { IonCol, IonRow, IonSearchbar } from '@ionic/react';
import EarnSectionTileList from './EarnSectionTileList/EarnSectionTileList';
import Loading from '../status/Loading';
// importing assets
import { searchOutline, closeOutline } from 'ionicons/icons';
// importing utilities
import { getLocalStorage } from '../../helpers/localStorage/localStorageHelper';
// importing graphql
import { useQuery } from '@apollo/react-hooks';
import { GET_JOB_POSTS_FROM_MANY_ORGS } from '../../GraphQL/JobPost/GET_JOB_POSTS_FROM_MANY_ORGS/GET_JOB_POSTS_FROM_MANY_ORGS';
import {
    GetJobPostsFromManyOrgs,
    GetJobPostsFromManyOrgsVariables,
    GetJobPostsFromManyOrgs_jobPostsFromManyOrgs,
} from '../../GraphQL/__generated__/GetJobPostsFromManyOrgs';
//importing styles
import './EarnSection.css';

const EarnSection: React.FC = () => {
    const { currentUser } = useAuth();
    const [searchInput, setSearchInput] = useState<string>('');

    const { data, loading } = useQuery<
        GetJobPostsFromManyOrgs,
        GetJobPostsFromManyOrgsVariables
    >(GET_JOB_POSTS_FROM_MANY_ORGS, { variables: { uid: currentUser.uid } });

    if (loading) {
        return <Loading />;
    }

    const filteredJobsPosted = data.jobPostsFromManyOrgs.filter((jobPost) => {
        const jobPostStartDate = moment(jobPost.startDate).tz(
            getLocalStorage('tz'),
            false
        );
        const jobPostStartTime = moment(jobPost.startTime).tz(
            getLocalStorage('tz'),
            false
        );

        const jobCreated = moment(jobPost.createdAt);

        return (
            (moment() < jobPostStartTime &&
                moment() < jobPostStartDate &&
                jobCreated > moment('03/21/2021')) || // renders if the task hasnt expired yet
            (jobPost.startDate === null &&
                jobPost.startTime === null &&
                jobCreated > moment('03/21/2021')) || // renders if start date/time are tbd
            (jobPost.startDate !== null &&
                jobPost.startTime === null &&
                jobCreated > moment('03/21/2021')) || // renders if the start date is known but the start time is tbd
            (jobPost.startDate === null &&
                jobPost.startTime !== null &&
                jobCreated > moment('03/21/2021')) // renders if start date is tbd but the start time is known
        );
    });

    const filteredPostsList: GetJobPostsFromManyOrgs_jobPostsFromManyOrgs[] =
        filteredJobsPosted.filter((jobPost) => {
            return jobPost.title
                .toLowerCase()
                .includes(searchInput.toLowerCase());
        });

    return (
        <IonRow className='earnSectionTile__row'>
            {/* <IonSearchbar
                className='earnSection-ionSearchBar'
                search-icon={searchOutline}
                clear-icon={closeOutline}
                placeholder='Search by name of task'
                value={searchInput}
                onIonChange={(event) => setSearchInput(event.detail.value)}
            ></IonSearchbar> */}
            <IonCol
                className='earnSection__title--wrap'
                sizeSm='12'
                sizeXs='12'
            ></IonCol>
            {searchInput.length > 0 && filteredPostsList.length > 0 ? (
                <EarnSectionTileList jobsPosted={filteredPostsList} /> // Matching search result.
            ) : (
                <EarnSectionTileList jobsPosted={filteredJobsPosted} /> // No matching search result. Show everything instead.
            )}
        </IonRow>
    );
};

export default EarnSection;
