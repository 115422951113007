// importing assets
import React, { useRef, useEffect } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
// importing components
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonPage,
    IonRow,
} from '@ionic/react';
import ConfirmationScreenLogo from '../../../components/ConfirmationScreenLogo/ConfirmationScreenLogo';
import Loading from '../../../components/status/Loading';
import Strokes from 'components/Strokes';
// importing assets
// importing utilities
import { int_to_credits } from '../../../utils/timeConversion';
import {
    setLocalStorage,
    getLocalStorage,
} from '../../../helpers/localStorage/localStorageHelper';
// importing graphql
import { useQuery } from '@apollo/react-hooks';
import { GET_JOB_BY_ID } from '../../../GraphQL/Job/GET_JOB_BY_ID/GET_JOB_BY_ID';
import {
    GetJobById,
    GetJobByIdVariables,
} from '../../../GraphQL/__generated__/GetJobById';
// importing styles
import './TaskCompletedConfirmation.css';

const TaskConfirmation: React.FC = () => {
    const { taskId } = useParams<{ taskId: string }>();
    // const history = useHistory();
    const homeLink = useRef(null);

    const { data, loading, error } = useQuery<
        GetJobById, // query return return type (shape of data)
        GetJobByIdVariables // query request type       (shape of variables)
    >(GET_JOB_BY_ID, {
        variables: {
            jobId: parseInt(taskId),
        },
        fetchPolicy: 'no-cache',
    });

    useEffect(() => {
        setTimeout(() => {
            setLocalStorage('completeTaskViaRecipient', {
                hrs: '',
                mins: '',
                review: '',
                rating: 0,
            });
            homeLink.current?.click();
        }, 2000);
    }, []);

    if (loading) {
        return <Loading />;
    }

    const taskHrs = parseInt(getLocalStorage('completeTaskViaRecipient').hrs);
    const taskMins = parseInt(getLocalStorage('completeTaskViaRecipient').mins);

    return (
        <IonPage>
            <IonContent>
                <IonGrid className='taskConfirm__grid'>
                    <IonRow className='taskConfirm'>
                        <IonCol
                            sizeXs='12'
                            sizeSm='12'
                            className='taskConfirm__container'
                        >
                            <ConfirmationScreenLogo />
                            <Strokes />
                            <div className='taskConfirm__img--wrap'>
                                <IonImg
                                    src={
                                        !loading &&
                                        data.job.provider.profilePicture
                                    }
                                    className='taskConfirm__img'
                                />
                            </div>
                            <h1 className='taskConfirm__title'>
                                Congrats, your task is completed
                            </h1>
                            <p className='taskConfirm__text'>
                                Members will be given{' '}
                                {int_to_credits(taskHrs, taskMins)} Balance
                                Tokens and will see your review on their profile
                                shortly.
                            </p>
                            <a ref={homeLink} href={`/home`} type='hidden'></a>
                            {/* <IonButton
                                onClick={() => {
                                    setLocalStorage('completeTaskViaRecipient', {
                                        hrs: '',
                                        mins: '',
                                        review: '',
                                        rating: 0,
                                    });
                                    history.push('/home');
                                }}
                                fill='clear'
                                color='ion-primary'
                                className='taskConfirm__btn'
                            >
                                <Link
                                    to={{ pathname: `/home` }}
                                    className='completeTask__btn--link'
                                >
                                    Return Home
                                </Link>
                            </IonButton> */}
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default TaskConfirmation;
