import React from 'react';
import { useHistory } from 'react-router-dom';
import { IonButton } from '@ionic/react';
import genRandomColor from 'utils/genRandomColor';
import { setLocalStorage } from 'helpers/localStorage/localStorageHelper';

const SkillSelectionItem: React.FC<{
    setSearchInput: Function;
    keywordName: string;
}> = ({ keywordName, setSearchInput }) => {
    const color = genRandomColor();
    const history = useHistory();
    return (
        <IonButton
            fill='clear'
            color='ion-primary'
            className='skillSelection__list--item'
            style={{ color: color.text, backgroundColor: color.bg }}
            onClick={() => {
                setLocalStorage('skillColors', {
                    color: color.text,
                    backgroundColor: color.bg,
                });
                // (/) symbol causes url to not update correctly, hot fix -> switch symbol to (&)
                setSearchInput(''); // clear search state
                history.push(
                    '/skillCreation/' + encodeURIComponent(keywordName),
                    history.location.state
                );
            }}
        >
            {keywordName}
        </IonButton>
    );
};

export default SkillSelectionItem;
