// modules and hooks
import { useEffect } from 'react';
// helpers
import {
    setLocalStorage,
    getLocalStorage,
} from 'helpers/localStorage/localStorageHelper';

//TODO delete this whole file when it's unnecessary
export default () =>
    useEffect(() => {
        if (!getLocalStorage('currentAdminGroupId')) {
            setLocalStorage('currentAdminGroupId', 0);
        }

        // TO CLEAR WHAT USERS MIGHT HAVE PRE-POPULATED IN LOCALSTORAGE
        const groupTask = {
            taskName: '',
            taskDescr: '',
            date: null,
            startTime: '',
            endTime: '',
            location: '',
            locationPreference: '',
            skillsNeeded: [],
            memberInvites: [],
        };
        setLocalStorage('groupTask', groupTask);

        // TO CLEAR WHAT USERS MIGHT HAVE PRE-POPULATED IN LOCALSTORAGE
        const createTask = {
            taskName: '',
            taskDescr: '',
            date: null,
            startTime: '',
            endTime: '',
            location: '',
            locationPreference: '',
            skillsNeeded: [],
            groupCodes: [],
            memberInvites: [],
        };
        setLocalStorage('createTask', createTask);
    }, []);
