import React from 'react';
// importing components
import { IonButton, IonCol, IonImg, IonRow } from '@ionic/react';
// importing assets
import balanceToken from '../../../assets/balance-token.png';
// importing utilities
import time_credit_conversion from '../../../utils/timeConversion';
import { cash } from 'ionicons/icons';
import { GetStripeAccountBalance } from '../../../pages/Settings/StripeSettings/StripeHelpers';

const CurrencyDisplay: React.FC<{
    currencyShown: string;
    handleCurrencyShown: Function;
    data: { balanceByUID: number };
    loading: boolean;
}> = ({ currencyShown, handleCurrencyShown, data, loading }) => {
    let displayedCurrency;
    const cashAmount = 0; // mock data
    let balances = GetStripeAccountBalance();
    const formatAmount = (amount: number) => {
        //  input: 100000 (penny/cent)
        //  output: $1,000.00 (dollar amount formatted)
        return (
            '$' +
            (amount / 100)
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        );
    };
    if (loading) {
        return <p>...loading</p>;
    }

    if (currencyShown === 'balance-tokens') {
        // Calculate Currency Conversion
        // MIGHT NEED TO WORK MORE ON THIS LOGIC
        const hours = data.balanceByUID / 60;
        const hours2 = Math.floor(data.balanceByUID / 60);
        const decimal = hours.toString().split('.')[1];
        const actualDecimal = parseFloat(`0.${decimal}`) * (60 / 100);
        const actualMinutes = Math.floor(
            parseFloat(actualDecimal.toPrecision(2)) * 100
        );
        const minutes = Math.floor((data.balanceByUID / 60 - hours) * 100);
        displayedCurrency = (
            <div>
                <div className='currencyDisplay__col--bottom'>
                    <h2 className='currencyDisplay__credits--token'>
                        {/*time_credit_conversion(data?.balanceByUID)*/}
                        {(data?.balanceByUID / 60).toFixed(2)}
                    </h2>
                </div>

                <span className='conversion'>
                    {hours2} hours : {actualMinutes} minutes{' '}
                </span>
            </div>
        );
    } else {
        displayedCurrency = (
            <IonRow style={{ gap: '5px', width: '100%' }}>
                {/* <IonCol>
                    <h2 className='currencyDisplay__credits--cash'>
                        {balances?.object ? formatAmount(balances?.available[0].amount) : cashAmount.toFixed(2)}
                    </h2>
                    <h2 className='currencyDisplay__cash--description'>
                        Available
			  </h2>
                </IonCol> */}
                <IonCol>
                    <h2 className='currencyDisplay__credits--cash'>
                        {balances?.object
                            ? formatAmount(
                                  balances?.pending[0].amount.toFixed(2)
                              )
                            : cashAmount.toFixed(2)}
                    </h2>
                    <h2 className='currencyDisplay__cash--description'>
                        Balance
                    </h2>
                </IonCol>
            </IonRow>
        );
    }

    return (
        <IonCol className='currencyDisplay__container' sizeXs='12' sizeSm='12'>
            <IonCol
                className='currencyDisplay__col--mid seg'
                sizeXs='12'
                sizeSm='12'
            >
                <div
                    onClick={() => handleCurrencyShown('balance-tokens')}
                    // fill='clear'
                    className={`currencyDisplay__currency--btn ${
                        currencyShown === 'balance-tokens'
                            ? 'currencyDisplay__currency--btn--active'
                            : null
                    }`}
                >
                    <IonImg
                        className='currencyDisplay__img'
                        src={balanceToken}
                        alt='balance token'
                    />
                    Tokens
                </div>
                <div
                    onClick={() => handleCurrencyShown('cash')}
                    // fill='clear'
                    className={`currencyDisplay__currency--btn ${
                        currencyShown === 'cash'
                            ? 'currencyDisplay__currency--btn--active'
                            : null
                    }`}
                >
                    Cash
                </div>
            </IonCol>
            {/* custom segment buttons */}
            <IonCol
                className='currencyDisplay__col--bottom'
                sizeSm='12'
                sizeXs='12'
            >
                {displayedCurrency}
            </IonCol>
        </IonCol>
    );
};

export default CurrencyDisplay;
