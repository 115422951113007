// importing modules
import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router';
// importing components
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonIcon,
    IonImg,
    IonItem,
    IonItemOption,
    IonItemOptions,
    IonItemSliding,
    IonModal,
    IonPage,
    IonRow,
    IonText,
    IonToast,
} from '@ionic/react';
import EditBioModal from './EditBioModal/EditBioModal';
import EditSkillModal from './EditSkillModal/EditSkillModal';
import ProfileStateTabs from 'components/ProfileStateTabs/ProfileStateTabs';
import UserMenu from 'components/UserMenu/UserMenu';
import UserMenuBackdrop from 'components/UserMenu/UserMenuBackdrop';
import ImageCropperModal from 'components/ImgCropper/ImageCropperModal';
import Loading from 'components/status/Loading';
import NavBar from 'components/NavBar';
import Strokes from 'components/Strokes';
// importing assets
import { arrowForwardOutline, camera, trashOutline } from 'ionicons/icons';
// importing styles
import './EditProfile.css';
// importing graphql utilities - types
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
    GetUserEditProfile,
    GetUserEditProfileVariables,
    GetUserEditProfile_profile_skills,
} from 'GraphQL/__generated__/GetUserEditProfile';
import { USER_EDIT_PROFILE_DATA } from 'GraphQL/Profile/EditUserProfile/editUserProfile';
import {
    UPDATE_PHOTO_BY_UID,
    UPDATE_PHOTO_BY_UIDVariables,
} from 'GraphQL/__generated__/UPDATE_PHOTO_BY_UID';
import {
    DisableSkillById,
    DisableSkillByIdVariables,
} from 'GraphQL/__generated__/DisableSkillById';
import { DISABLE_SKILL_BY_ID } from 'GraphQL/Skill/DISABLE_SKILL_BY_ID/DISABLE_SKILL_BY_ID';
import { gql_UPDATE_PHOTO_BY_UID } from 'GraphQL/Profile/UPDATE_PHOTO_BY_UID/UPDATE_PHOTO_BY_UID';
import { FirebaseHelper } from 'helpers/FirebaseHelper';
import { handleImageUpload } from 'helpers/imageHandling/ImageHandlerForCropper';
import { useAuth } from 'context/firebase/authContext';

export default () => {
    const { currentUser } = useAuth();
    const fuser = new FirebaseHelper(currentUser);

    const { data, loading, error, fetchMore } = useQuery<
        GetUserEditProfile,
        GetUserEditProfileVariables
    >(USER_EDIT_PROFILE_DATA, {
        variables: {
            uid: fuser.uid,
        },
    });

    const history = useHistory();
    const [toggleMenu, setToggleMenu] = useState<boolean>(false); // handles hamburger menu
    const [showToast, setShowToast] = useState<boolean>(false);
    const [showBioModal, setShowBioModal] = useState<boolean>(false);
    const [showSkillModal, setShowSkillModal] = useState<boolean>(false);
    const [skillName, setSkillName] = useState<string>('');
    const [skillDescription, setSkillDescription] = useState<string>('');
    // ! img cropper logic
    const [showImageCropper, setShowImageCropper] = useState<boolean>(false); // image-cropper modal state
    const [imgPreview, setImgPreview] = useState<string>('');
    const [imgToCrop, setImgToCrop] = useState<string>(); // img to crop -> file upload
    const [imgBlob, setImgBlob] = useState<string>(); // img blob -> blob to upload to server
    const filePickerRef = useRef<HTMLInputElement>(null);
    const [
        updatePhoto,
        {
            data: updatePhotoData,
            loading: updatePhotoLoading,
            error: updatePhotoError,
        },
    ] = useMutation<UPDATE_PHOTO_BY_UID, UPDATE_PHOTO_BY_UIDVariables>(
        gql_UPDATE_PHOTO_BY_UID
    );

    const [skillId, setSkillId] = useState<number>(-1);

    const handleCropperModal = () => setShowImageCropper(!showImageCropper); // handles image cropper
    const handleMenuToggle = () => setToggleMenu((prevToggle) => !prevToggle);
    const handleBioModal = () => setShowBioModal(!showBioModal);
    const handleSkillModal = () => setShowSkillModal(!showSkillModal);
    const [disableOneSkill, disableOneSkillDatas] = useMutation<
        DisableSkillById,
        DisableSkillByIdVariables
    >(DISABLE_SKILL_BY_ID);

    const handleDeleteSkill = (id: number) => {
        disableOneSkill({
            variables: {
                id: id,
            },
            refetchQueries: [
                {
                    query: USER_EDIT_PROFILE_DATA,
                    variables: {
                        uid: fuser.uid,
                    },
                },
            ],
        });
    };

    const handleOpenFilePicker = () => filePickerRef.current?.click();

    const handlePickFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        // handles selecting a file
        const file = event.target!.files![0];
        if (!file) return; // return nothing if no file was selected
        const fr = new FileReader();
        fr.onload = () => {
            // @ts-ignore
            setImgToCrop(fr.result!.toString());
            setShowImageCropper(true);
        };
        fr.readAsDataURL(file);
        event.target.value = null; // To fix issue where user wasnt able to upload the same picture twice
    };

    const handleCroppedImageUpload = () => {
        // handles uploading an image to the server
        if (imgPreview.length > 0) {
            handleImageUpload(currentUser, imgBlob, updatePhoto)
                .then((res) => {
                    history.push(`/portfolio/${fuser.uid}`);
                    setImgPreview('');
                    setImgBlob('');
                })
                .catch((e) => console.error(e));
            setShowToast(true);
        } else {
            history.push(`/portfolio/${fuser.uid}`);
            setShowToast(true);
            setImgBlob('');
            setImgPreview('');
        }
    };

    if (loading) {
        return <Loading />;
    }

    const activeSkills: GetUserEditProfile_profile_skills[] =
        data.profile.skills.filter((skill) => skill.status === 'ACTIVE');

    return (
        <IonPage>
            <IonContent>
                <IonGrid>
                    <IonToast
                        cssClass='requestDetails__location--clipboard--toast'
                        isOpen={showToast}
                        onDidDismiss={() => setShowToast(false)}
                        message='Profile saved!'
                        duration={1000}
                    />
                    <ImageCropperModal
                        setImgBlob={setImgBlob}
                        imgToCrop={imgToCrop}
                        setImgToCrop={setImgToCrop}
                        setImgPreview={setImgPreview}
                        showCropperModal={showImageCropper}
                        handleCropperModal={handleCropperModal}
                    />
                    <EditBioModal
                        handleBioModal={handleBioModal}
                        showBioModal={showBioModal}
                        userBio={data.profile.bio}
                        uid={fuser.uid}
                    />
                    <EditSkillModal
                        handleSkillModal={handleSkillModal}
                        showSkillModal={showSkillModal}
                        id={skillId}
                        skillName={skillName}
                        skillDescription={skillDescription}
                        uid={fuser.uid}
                    />
                    {/* After profile pic is done uploading(imgBlob has value) we ask the user to confirm the upload or cancel*/}
                    {imgBlob ? (
                        // CLEAR IMAGE BLOB TO CLOSE MODAL
                        <IonModal
                            backdropDismiss={false}
                            cssClass='exit-modal-wrapper'
                            isOpen={true}
                        >
                            <IonGrid>
                                <IonRow>
                                    <IonText className='exit-modal-text'>
                                        Photo is ready for upload!
                                    </IonText>
                                </IonRow>

                                <div className='modal-button-container'>
                                    <button
                                        onClick={handleCroppedImageUpload}
                                        className='modal-button confirm-button'
                                    >
                                        Confirm
                                    </button>
                                    <button
                                        onClick={() => {
                                            setImgBlob('');
                                            setImgPreview('');
                                            setImgToCrop('');
                                        }}
                                        className='modal-button cancel-button'
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </IonGrid>
                        </IonModal>
                    ) : null}

                    {toggleMenu ? (
                        <IonCol className='menu__sidebar--active'>
                            <UserMenu
                                handleMenuToggle={handleMenuToggle}
                                userUid={fuser.uid}
                                // communities={fuser.organizations}
                            />
                        </IonCol>
                    ) : (
                        <IonCol className='menu__sidebar--inactive' />
                    )}
                    {toggleMenu ? (
                        <UserMenuBackdrop handleMenuToggle={handleMenuToggle} />
                    ) : null}

                    <ProfileStateTabs
                        handleMenuToggle={handleMenuToggle}
                        showSearch={false}
                    />
                    {/* home profile header start */}
                    <IonRow className='homeEditHeader'>
                        <IonCol
                            sizeXs='12'
                            sizeSm='12'
                            className='homeEditHeader__col--top'
                        >
                            <Strokes top={true} />
                        </IonCol>
                        <IonRow className='homeEditHeader__row'>
                            <IonCol
                                sizeSm='4'
                                sizeXs='4'
                                className='homeEditHeader__col--img'
                            >
                                <div className='homeEditHeader__img--wrap'>
                                    <div className='homeEdit__img--badge'>
                                        {/* <p className="homeEdit__img--badge--text">icon</p> */}
                                        <IonIcon
                                            onClick={handleOpenFilePicker}
                                            className='homeEdit__img--badge--icon'
                                            icon={camera}
                                        />
                                        <input
                                            type='file'
                                            name='image_Upload_Input'
                                            hidden
                                            ref={filePickerRef}
                                            onChange={handlePickFile}
                                        />
                                    </div>
                                    {imgPreview.length > 0 ? (
                                        <IonImg
                                            className='homeEditHeader__img'
                                            src={imgPreview}
                                        />
                                    ) : (
                                        <IonImg
                                            className='homeEditHeader__img'
                                            src={
                                                !loading &&
                                                data.profile.profilePicture
                                            }
                                        />
                                    )}
                                </div>
                            </IonCol>
                            <IonCol
                                sizeSm='8'
                                sizeXs='8'
                                className='homeEditHeader__col--bottom'
                            >
                                <h1 className='homeEditHeader__name'>
                                    {!loading && data.profile.firstName}
                                    <br />
                                    {!loading && data.profile.lastName}
                                </h1>
                            </IonCol>
                        </IonRow>
                    </IonRow>
                    {/* home profile header start */}

                    {/* profile bio start */}
                    <IonRow className='homeEdit__save--btn--container'>
                        <IonCol
                            sizeXs='12'
                            sizeSm='12'
                            className='homeEdit__save--btn--wrap'
                        >
                            <IonButton
                                onClick={handleCroppedImageUpload}
                                expand='block'
                                className='homeEdit__save--btn'
                                color='ion-primary'
                            >
                                Save Profile{' '}
                            </IonButton>
                        </IonCol>
                    </IonRow>
                    {/* profile bio end */}

                    {/* home interface start */}
                    <IonGrid className='homeEdit__list--container'>
                        <IonRow className='homeEdit__editBio--row'>
                            <IonCol
                                className='homeEdit__editBio--item'
                                sizeXs='12'
                                sizeSm='12'
                            >
                                <IonCol
                                    sizeXs='9'
                                    sizeSm='9'
                                    className='ion-padding homeEdit__editBio--item--content'
                                >
                                    <p className='homeEdit__editBio--item--content--text'>
                                        {data.profile.bio?.length > 0
                                            ? data.profile.bio
                                            : 'Bio not available...'}
                                    </p>
                                </IonCol>
                                <IonCol
                                    onClick={handleBioModal}
                                    sizeXs='3'
                                    sizeSm='3'
                                    className='homeEdit__editBio--btn'
                                >
                                    <p className='homeEdit__editBio--btn--text'>
                                        Edit
                                    </p>
                                    <p className='homeEdit__editBio--btn--name'>
                                        Bio
                                    </p>
                                </IonCol>
                            </IonCol>
                        </IonRow>
                    </IonGrid>

                    <IonRow className='homeEdit__title--wrap'>
                        <IonCol sizeXs='12' sizeSm='12'>
                            <h1 className='homeEdit__title'>
                                What I Can Offer
                            </h1>
                        </IonCol>
                    </IonRow>
                    {/* home header interface end */}
                    <IonGrid className='homeEdit__list--container'>
                        <IonRow className='homeEdit__list'>
                            {activeSkills &&
                                activeSkills.map((skill) => (
                                    <IonItemSliding
                                        key={skill.id}
                                        className='skillConfirm__item'
                                    >
                                        <IonItemOptions side='end'>
                                            <IonItemOption
                                                className='homeEdit__delete--btn'
                                                onClick={() =>
                                                    handleDeleteSkill(skill.id)
                                                }
                                            >
                                                <IonIcon
                                                    icon={trashOutline}
                                                    slot='icon-only'
                                                />
                                            </IonItemOption>
                                        </IonItemOptions>
                                        <IonItem
                                            lines='none'
                                            className='homeEdit__content--slide--wrap'
                                        >
                                            <IonCol
                                                sizeXs='9'
                                                sizeSm='9'
                                                sizeLg='9'
                                                sizeMd='3'
                                                sizeXl='9'
                                                className='ion-padding-vertical skillConfirm__content'
                                            >
                                                <p className='skillConfirm__content--keyword'>
                                                    {skill.category}
                                                </p>
                                                <p className='skillConfirm__content--skill'>
                                                    {skill.name}
                                                </p>
                                                <p className='skillConfirm__content--text'>
                                                    {skill.description.length >
                                                    0
                                                        ? skill.description
                                                        : 'Description not available...'}
                                                </p>
                                            </IonCol>
                                            <IonCol
                                                onClick={() => {
                                                    setSkillId(skill.id);
                                                    setSkillName(skill.name);
                                                    setSkillDescription(
                                                        skill.description
                                                    );
                                                    handleSkillModal();
                                                }}
                                                sizeXs='3'
                                                sizeSm='3'
                                                sizeLg='3'
                                                sizeMd='3'
                                                sizeXl='3'
                                                className='skillConfirm__request--btn'
                                            >
                                                <p className='skillConfirm__request--btn--text'>
                                                    Edit
                                                </p>
                                                <p className='skillConfirm__request--btn--name'>
                                                    Skill
                                                </p>
                                            </IonCol>
                                        </IonItem>
                                    </IonItemSliding>
                                ))}
                            {/* newSKill btn */}
                            <IonCol
                                onClick={() => history.push('/skillSelection')}
                                className='homeEdit__newSkill--btn'
                                sizeXs='12'
                                sizeSm='12'
                            >
                                <IonCol
                                    sizeXs='12'
                                    sizeSm='12'
                                    className='ion-padding homeEdit__newSkill--content'
                                >
                                    <p className='homeEdit__newSkill--btn--text'>
                                        + Add Another Skill
                                    </p>
                                </IonCol>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonGrid>
                <NavBar />
            </IonContent>
        </IonPage>
    );
};
