// importing modules
import React from 'react';
// importing components
import { IonCol, IonRow } from '@ionic/react';
// importing styles
import './TextContent.css';

const CreateSkillTextContent: React.FC<{
    h1TextLeft: string;
    h1TextRight: string;
    pText: string;
    spanText: string;
}> = ({ h1TextLeft, h1TextRight, spanText, pText }) => {
    return (
        <IonRow className='skillDef__textContent'>
            <IonCol
                className='skillDef__textContent--container'
                sizeSm='12'
                sizeXs='12'
            >
                <h1 className='skillDef__textContent--title'>
                    {h1TextLeft} <span>{spanText}</span> {h1TextRight}
                </h1>
                <p className='skillDef__textContent--text'>{pText}</p>
            </IonCol>
        </IonRow>
    );
};

export default CreateSkillTextContent;
