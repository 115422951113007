// importing modules & hooks
import React, { useState } from 'react';
import { useAuth } from 'context/firebase/authContext';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
// importing components
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonIcon,
    IonImg,
    IonLabel,
    IonPage,
    IonRow,
} from '@ionic/react';
import LocationPreferenceModal from './LocationPreferenceModal/LocationPreferenceModal';
import SkillCreationUserImg from 'components/SkillCreationUserImg/SkillCreationUserImg';
import CreateSkillTextContent from './UI/TextContent';
import BackButton from 'components/BackButton';
import Strokes from 'components/Strokes';
// importing assets
import { arrowForwardOutline } from 'ionicons/icons';
// importing utils
import {
    getLocalStorage,
    setLocalStorage,
} from 'helpers/localStorage/localStorageHelper';
// graphql ...
import { LocationPreference } from 'GraphQL/__generated__/globalTypes';
import { USER_PROFILE_BY_UID } from 'GraphQL/Profile/UserProfile/USER_PROFILE_BY_UID';
import { SKILL_BY_UID } from 'GraphQL/Skill/SKILL_BY_UID/SkillBy_subId';
import { CREATE_ONE_SKILL } from 'GraphQL/Skill/CreateOneSkill/CreateOneSkill';
import {
    CreateOneSkill,
    CreateOneSkillVariables,
} from 'GraphQL/__generated__/CreateOneSkill';
// importing styles
import './DefineSkill.css';

interface LocationSelected {
    id: number;
    location: LocationPreference;
    text: string;
    description: string;
}

const locationsPreference = [
    {
        id: 1,
        location: LocationPreference.OPENTOALL,
        text: 'Im Open for All',
        description:
            'This means that people can enter custom places for you to meet at for tasks and/or video calls',
    },
    {
        id: 2,
        location: LocationPreference.VIRTUAL,
        text: 'Virtual',
        description:
            'This means that people will only be able to book sessions with you via video/conference call',
    },
    {
        id: 2,
        location: LocationPreference.INPERSON,
        text: 'In Person',
        description:
            'This means that people can enter custom places for you to meet at for tasks',
    },
];

const DefineSkill: React.FC = () => {
    const history = useHistory();
    const { slug } = useParams<{ slug: string }>();
    const decodedSkillName = decodeURIComponent(slug);
    const { currentUser } = useAuth();

    const [showLocationModal, setShowLocationModal] = useState<boolean>(false);
    const [skillDesc, setSkillDesc] = useState<string>('');
    const [skillType, setSkillType] = useState<string>('');
    const [locationPref, setLocationPref] = useState<string>('');
    const [selectedlocation, setSelectedLocation] = useState<LocationSelected>({
        id: 0,
        location: LocationPreference.OPENTOALL,
        text: '',
        description: '',
    });
    const [address, setAddress] = useState<string>('');

    const [createOneSkill, { data: skillCreatedDatas, loading, error }] =
        useMutation<CreateOneSkill, CreateOneSkillVariables>(
            CREATE_ONE_SKILL,
            {}
        );

    const handleLocationModal = () => setShowLocationModal(!showLocationModal);
    const handleLocationPreference = (preference: string) => {
        setLocationPref(preference);
        const l = locationsPreference.find(
            (loc) => loc.location === preference
        );
        setSelectedLocation(l);
    };

    const handleConfirmSkillsCreated = () => {
        createOneSkill({
            variables: {
                name: skillType,
                category: decodedSkillName,
                description: skillDesc,
                portfolio: currentUser.uid,
                daysAvailable: [],
                hoursAvailable: [],
                locationPreference: selectedlocation.location,
                locationString: address,
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: SKILL_BY_UID,
                    variables: { uid: currentUser.uid },
                },
                {
                    query: USER_PROFILE_BY_UID,
                    variables: { subId: currentUser.uid },
                },
            ],
        }).then(() => {
            setAddress('');
            setSkillDesc('');
            setSkillType('');
            setLocationPref('');
            setLocalStorage('skillColors', { color: '', backgroundColor: '' });
            history.push('/skillsConfirmation', history.location.state);
        });
    };

    return (
        <IonPage>
            <IonContent>
                <BackButton />
                <IonGrid>
                    <LocationPreferenceModal
                        address={address}
                        setAddress={setAddress}
                        locationPref={locationPref}
                        handleLocationPreference={handleLocationPreference}
                        handleLocationModal={handleLocationModal}
                        showLocationModal={showLocationModal}
                        slug={decodedSkillName}
                    />
                    {/* skillDef header */}
                    <IonRow className='skillDef__header'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='skillDef__header--container'
                        >
                            <Strokes top={true} green={false} />
                            <SkillCreationUserImg />
                        </IonCol>
                    </IonRow>
                    {/* skillDef badge */}
                    <IonRow className='skillDef__badge--container'>
                        <IonCol
                            style={{
                                color: getLocalStorage('skillColors').color,
                                backgroundColor:
                                    getLocalStorage('skillColors')
                                        .backgroundColor,
                            }}
                            sizeSm='10'
                            sizeXs='10'
                            className='skillDef__badge'
                        >
                            <p>{decodedSkillName}</p>
                        </IonCol>
                    </IonRow>
                    {/* text content */}
                    <CreateSkillTextContent
                        h1TextLeft='Nice! Give us'
                        spanText='details'
                        h1TextRight='on what you can offer'
                        pText='This will inform people the specifics of your 
                        skill offering & your availability to help'
                    />
                    {/* skill def form */}
                    <IonGrid className='defSkill__form--container'>
                        <IonRow className='defSkill__form--row'>
                            <IonCol
                                className='defSkill__form--input--wrap'
                                sizeXs='12'
                                sizeSm='12'
                            >
                                <IonLabel className='defSkill__form--label'>
                                    Type of {decodedSkillName} (Optional)
                                </IonLabel>
                                <input
                                    value={skillType}
                                    onChange={(event) =>
                                        setSkillType(event.target.value)
                                    }
                                    className='defSkill__form--input'
                                    placeholder='Tell people specifically what you offer'
                                    type='text'
                                    required
                                    name='typeofSkill'
                                />
                            </IonCol>
                            <IonCol
                                className='defSkill__form--input--wrap'
                                sizeXs='12'
                                sizeSm='12'
                            >
                                <IonLabel className='defSkill__form--label'>
                                    Describe Your Skill (Optional)
                                </IonLabel>
                                <textarea
                                    value={skillDesc}
                                    onChange={(event) =>
                                        setSkillDesc(event.target.value)
                                    }
                                    className='defSkill__form--input'
                                    placeholder='Tell people about your offering...'
                                    required
                                ></textarea>
                            </IonCol>
                            <IonCol
                                className='defSkill__form--btns--container'
                                sizeXs='12'
                                sizeSm='12'
                            >
                                <IonCol
                                    sizeXs='12'
                                    sizeSm='12'
                                    className='defSkill__form--btn--wrap'
                                >
                                    <IonLabel className='defSkill__form--label'>
                                        Available Locations
                                    </IonLabel>
                                    <IonButton
                                        fill='clear'
                                        onClick={handleLocationModal}
                                        color='ion-primary'
                                        className='defSkill__form--btn'
                                    >
                                        {selectedlocation.text.length > 0
                                            ? selectedlocation.text
                                            : 'Select location Preference'}
                                        <IonIcon
                                            slot='end'
                                            icon={arrowForwardOutline}
                                        />
                                    </IonButton>
                                </IonCol>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                    {/* skill def confirmation btn */}
                    <IonRow className='skillDef__btn--container'>
                        <IonCol
                            className='skillDef__btn--wrap'
                            sizeXs='12'
                            sizeSm='12'
                        >
                            <IonButton
                                disabled={
                                    // skillType.length === 0 ||
                                    locationPref.length === 0
                                }
                                fill='clear'
                                onClick={handleConfirmSkillsCreated}
                                color='ion-primary'
                                className='skillDef__btn'
                            >
                                Confirm Skill
                            </IonButton>
                        </IonCol>
                    </IonRow>
                    {/* <Strokes bot={true} green={false} /> */}
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default DefineSkill;
