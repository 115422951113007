import gql from 'graphql-tag';

export const MAILCHIMPCOMMUNITYONBOARDINGADMIN = gql`
    query AdminWebOnboardingComplete(
        $email: String!
        $fname: String
        $lname: String
        $groupcode: String
    ) {
        AdminWebOnboardingComplete(
            email: $email
            fname: $fname
            lname: $lname
            groupcode: $groupcode
        ) {
            response
        }
    }
`;
