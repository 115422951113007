// importing modules
import React, { ReactElement, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useAuth } from 'context/firebase/authContext';
import { Plugins } from '@capacitor/core';
import Geocode from 'react-geocode';
import moment, { now } from 'moment-timezone';
import momentTZ from 'moment-timezone';
import Linkify from 'react-linkify';
// importing components
import {
    IonCol,
    IonContent,
    IonGrid,
    IonIcon,
    IonImg,
    IonRow,
    IonPage,
    IonToast,
    IonItemSliding,
    IonItem,
    IonItemOption,
    IonItemOptions,
} from '@ionic/react';
import GoogleMap from 'components/GoogleMap/PublicMap/Map';
import PrivateGoogleMap from 'components/GoogleMap/PrivateMap/Map';
import EditTaskDescriptionModal from './EditModals/EditTaskDescriptionModal';
import EditSkillModal from './EditModals/EditSkillInfoModal';
import EditLocationModal from './EditModals/LocationUpdateModal';
import AddSkillModal from './SkillSearch/SkillSearch';
import Loading from 'components/status/Loading';
import Strokes from 'components/Strokes';
import BackButton from 'components/BackButton';
// importing assets
import { videocam, copy, trashOutline } from 'ionicons/icons';
// importing utilites
import limitStr from 'utils/limitString';
import getLocationPreferenceStr from 'utils/getLocationPreferenceStr';
import { getLocalStorage } from 'helpers/localStorage/localStorageHelper';
// importing graphql
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { GET_JOB_POST_BY_ID } from 'GraphQL/JobPost/GET_JOB_POST_BY_ID/GET_JOB_POST_BY_ID';
import {
    DeleteOneSkillNeeded,
    DeleteOneSkillNeededVariables,
} from 'GraphQL/__generated__/DeleteOneSkillNeeded';
import { DELETE_ONE_SKILL_NEEDED } from 'GraphQL/SkillNeeded/DELETE_ONE_SKILL_NEEDED/DELETE_ONE_SKILL_NEEDED';
import { GET_ORG_BY_ID } from 'GraphQL/organization/GET_ORG_BY_ID/GET_ORG_BY_ID';
import {
    GetJobPostById,
    GetJobPostByIdVariables,
    GetJobPostById_jobPost_skillsNeeded_participationRequests_provider,
} from 'GraphQL/__generated__/GetJobPostById';
import { LocationPreference, Status } from 'GraphQL/__generated__/globalTypes';
// importing styles

//FIXME - Should this secret be in .env or something????
Geocode.setApiKey('AIzaSyD7B3yKTqxrAYt-HWajE5mGW8z2k2Z2TyQ');

interface SkillToEdit {
    id: number;
    name: string;
    description: string;
    additionalDetails: string;
    peopleNeeded: number;
}

export default () => {
    const history = useHistory();
    const { taskId } = useParams<{ taskId: string }>();
    const { currentUser } = useAuth();
    const [showToast, setShowToast] = useState(false);
    const [isEditDescrModalActive, setIsEditDescrModalActive] =
        useState<boolean>(false);
    const [isEditSkillModalActive, setIsEditSkillModalActive] =
        useState<boolean>(false);
    const [isEditLocationModalActive, setIsEditLocationModalActive] =
        useState<boolean>(false);
    const [isAddSkillModalActive, setIsAddSkillModalActive] =
        useState<boolean>(false);
    const [location, setLocation] = useState({ address: '', lat: 0, lng: 0 });
    const [getLocationOnce, setGetLocationOnce] = useState<boolean>(true);
    const [skillToEdit, setSkillToEdit] = useState<SkillToEdit>({
        id: 0,
        name: '',
        description: '',
        additionalDetails: '',
        peopleNeeded: 1,
    });

    const [deleteSkillNeeded, deleteSkillNeededDatas] = useMutation<
        DeleteOneSkillNeeded,
        DeleteOneSkillNeededVariables
    >(DELETE_ONE_SKILL_NEEDED);
    const [getTaskGroup, { data: groupData }] = useLazyQuery(GET_ORG_BY_ID);

    const { data, loading } = useQuery<GetJobPostById, GetJobPostByIdVariables>(
        GET_JOB_POST_BY_ID,
        {
            variables: { jobPostId: parseInt(taskId) },
            onCompleted: () => {
                getTaskGroup({
                    variables: {
                        id: data.jobPost.groupId,
                    },
                });
            },
        }
    );
    const groupImage = groupData?.organization?.orgPicture;
    const handleDeleteSkill = (id: number) => {
        deleteSkillNeeded({
            variables: {
                id: id,
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GET_JOB_POST_BY_ID,
                    variables: { jobPostId: parseInt(taskId) },
                },
            ],
        });
    };

    const handleCopyToClip = (str: string) => {
        const { Clipboard } = Plugins; // get GeoLocation plugin from Plugins
        Clipboard.write({ string: str });
        setShowToast(true);
    };

    if (loading) {
        return <Loading />;
    } else if (!loading && getLocationOnce) {
        if (
            data.jobPost.location.locationString.length > 0 &&
            data.jobPost.location.locationPreference !==
                LocationPreference.VIRTUAL
        ) {
            Geocode.fromAddress(data.jobPost.location.locationString) // get the tasks current location
                .then((position) => {
                    setLocation({
                        address: position.results[0].formatted_address, // formatted google address
                        lat: position.results[0].geometry.location.lat, // location -> lat
                        lng: position.results[0].geometry.location.lng, // location -> lng
                    });
                    setGetLocationOnce(false);
                })
                .catch((err) => console.error(err));
        }
    }

    const participants: GetJobPostById_jobPost_skillsNeeded_participationRequests_provider[] =
        [];
    data.jobPost.skillsNeeded.forEach((skill) => {
        skill.participationRequests.forEach((request) => {
            if (
                request.status === Status.ACCEPTED ||
                request.status === Status.RECIPIENT_HAS_PROPOSED_OFFER ||
                request.status === Status.COMPLETED
            ) {
                participants.push(request.provider);
            }
        });
    });

    const locationStruct = {
        ...location,
        locationPreference: data.jobPost.location.locationPreference,
        status: data.jobPost.status,
    };
    let locationText: string;
    let locationDetail: ReactElement;

    if (
        data.jobPost.location.locationPreference === LocationPreference.VIRTUAL
    ) {
        locationDetail = (
            <div className='requestDetails__icon--container'>
                <div className='requestDetails__icon--wrap'>
                    <IonIcon className='requestDetails__icon' icon={videocam} />
                </div>
            </div>
        );
        locationText =
            'Send the skill provider a video conference link in the chat once you complete this request';
    } else if (
        (data.jobPost.location.locationPreference ===
            LocationPreference.INPERSON &&
            data.jobPost.recipient.uid === currentUser.uid) ||
        (data.jobPost.location.locationPreference ===
            LocationPreference.INPERSON &&
            participants.find((p) => p.uid === currentUser.uid))
    ) {
        locationDetail = (
            <div className='requestDetails__map--container'>
                <GoogleMap {...locationStruct} />
            </div>
        );
        locationText = data.jobPost.location.locationString;
    } else if (
        data.jobPost.location.locationPreference === LocationPreference.INPERSON
    ) {
        locationDetail = (
            <div className='requestDetails__map--container'>
                <PrivateGoogleMap {...locationStruct} />
            </div>
        );
        locationText =
            'Exact address will be shown once the participation request has been accepted.';
    }

    let scheduledDetails;
    const startDate = moment(data.jobPost.startDate)
        .tz(getLocalStorage('tz'), false)
        .format('MMM Do');
    const startTime = moment(data.jobPost.startTime)
        .tz(getLocalStorage('tz'), false)
        .format('h:mma');
    const endTime = moment(data.jobPost.endTime)
        .tz(getLocalStorage('tz'), false)
        .format('h:mma');

    if (data.jobPost.startDate === null && data.jobPost.startTime === null) {
        scheduledDetails = (
            <p className='requestDetails__header--desc--taskDescr'>
                <span className='requestDetails__header--desc--taskDescr--span'>
                    Scheduled for
                </span>
                <br />
                TBD - @TBD
            </p>
        );
    } else if (
        data.jobPost.startDate !== null &&
        data.jobPost.startTime === null
    ) {
        scheduledDetails = (
            <p className='requestDetails__header--desc--taskDescr'>
                <span className='requestDetails__header--desc--taskDescr--span'>
                    Scheduled for
                </span>
                <br />
                {startDate} @TBD
            </p>
        );
    } else if (
        data.jobPost.startDate === null &&
        data.jobPost.startTime !== null
    ) {
        scheduledDetails = (
            <p className='requestDetails__header--desc--taskDescr'>
                <span className='requestDetails__header--desc--taskDescr--span'>
                    Scheduled for
                </span>
                <br />
                TBD @{startTime} - {endTime}
            </p>
        );
    } else if (
        data.jobPost.startDate !== null &&
        data.jobPost.startTime !== null
    ) {
        scheduledDetails = (
            <p className='requestDetails__header--desc--taskDescr'>
                <span className='requestDetails__header--desc--taskDescr--span'>
                    Scheduled for
                </span>
                <br />
                {startDate} @{startTime} - {endTime}
            </p>
        );
    }
    const currentTime = momentTZ(moment()) //grab currentTime according to local timezone
        .tz(getLocalStorage('tz')) //grab tz
        .format('YYYY-MM-DD-HH:mm'); //format
    if (data.jobPost.startTime != null) {
        //if startTime isn't null
        var dateString = data.jobPost.startTime.toString(); //stringify to manipulate
        var dateObj = new Date(dateString); //create date var
        var momentObj = moment(dateObj); //convert to moment
        var dataJobPostStarttimeString = momentObj.format('YYYY-MM-DD-HH:mm'); //format
    }
    return (
        <IonPage>
            <IonContent>
                <EditTaskDescriptionModal
                    taskPostId={data.jobPost.id}
                    title={data.jobPost.title}
                    description={data.jobPost.description}
                    taskLocation={data.jobPost.location.locationString}
                    userImg={
                        data.jobPost.adminId
                            ? groupImage
                            : data?.jobPost?.recipient?.profilePicture
                    }
                    taskStartDate={data.jobPost.startDate}
                    taskStartTime={data.jobPost.startTime}
                    taskEndTime={data.jobPost.endTime}
                    isEditDescrModalActive={isEditDescrModalActive}
                    setIsEditDescrModalActive={setIsEditDescrModalActive}
                />
                <EditSkillModal
                    userImg={
                        data.jobPost.adminId
                            ? groupImage
                            : data?.jobPost?.recipient?.profilePicture
                    }
                    skill={skillToEdit}
                    isEditSkillModalActive={isEditSkillModalActive}
                    setisEditSkillModalActive={setIsEditSkillModalActive}
                />
                <EditLocationModal
                    taskPostId={data.jobPost.id}
                    title={data.jobPost.title}
                    taskStartDate={data.jobPost.startDate}
                    taskStartTime={data.jobPost.startTime}
                    taskEndTime={data.jobPost.endTime}
                    locationId={data.jobPost.id}
                    userImg={
                        data.jobPost.adminId
                            ? groupImage
                            : data?.jobPost?.recipient?.profilePicture
                    }
                    locationPref={data.jobPost.location.locationPreference}
                    locationStr={data.jobPost.location.locationString}
                    isEditLocationModalActive={isEditLocationModalActive}
                    setIsEditLocationModalActive={setIsEditLocationModalActive}
                />
                <AddSkillModal
                    taskPostId={data.jobPost.id}
                    userImg={
                        data.jobPost.adminId
                            ? groupImage
                            : data?.jobPost?.recipient?.profilePicture
                    }
                    isAddSkillModalActive={isAddSkillModalActive}
                    setIsAddSkillModalActive={setIsAddSkillModalActive}
                />
                <IonGrid>
                    <IonToast
                        cssClass='requestDetails__location--clipboard--toast'
                        isOpen={showToast}
                        onDidDismiss={() => setShowToast(false)}
                        message='Address Copied to Clipboard!'
                        duration={1000}
                    />
                    {/* taskDetails header */}
                    <IonRow className='tpDetails__header'>
                        <Strokes top={true} />
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='tpDetails__header--container'
                        >
                            <BackButton color='white' />
                            <IonCol
                                sizeSm='12'
                                sizeXs='12'
                                className='requestDetails__header--desc--wrap'
                            >
                                {/* If the following conditons are true, the Edit button below will render, otherwise, render null */}
                                {
                                    // You have to be the task owner && The current time is before the task start time
                                    (data.jobPost.recipient.uid ===
                                        currentUser.uid &&
                                        currentTime <
                                            dataJobPostStarttimeString) ||
                                    //  You have to be the task owner && A time has yet to be decided
                                    (data.jobPost.recipient.uid ===
                                        currentUser.uid &&
                                        data.jobPost.startTime === null) ? (
                                        <p
                                            className='requestDetails__edit--btn'
                                            onClick={() =>
                                                setIsEditDescrModalActive(true)
                                            }
                                        >
                                            Edit
                                        </p>
                                    ) : null
                                }

                                <h1 className='tpDetails__header--desc--taskName'>
                                    {data.jobPost.title}
                                </h1>
                                <p className='requestDetails__header--desc--taskDescr'>
                                    <span className='requestDetails__header--desc--taskDescr--span'>
                                        Description
                                    </span>
                                    <br />
                                    {data.jobPost.description.length > 0 ? (
                                        <Linkify>
                                            {data.jobPost.description}
                                        </Linkify>
                                    ) : (
                                        'No description available for this job post'
                                    )}
                                </p>
                                {scheduledDetails}
                            </IonCol>
                            <IonCol
                                sizeSm='12'
                                sizeXs='12'
                                className='tpDetails__header--img--wrap'
                            >
                                <button
                                    style={{ backgroundColor: 'transparent' }}
                                    onClick={() =>
                                        history.push(
                                            `/user/${data.jobPost.recipient.uid}`
                                        )
                                    }
                                >
                                    <img
                                        className='tpDetails__header--descr--img'
                                        src={
                                            data.jobPost.adminId
                                                ? groupImage
                                                : data?.jobPost?.recipient
                                                      ?.profilePicture
                                        }
                                        alt={''}
                                    />
                                </button>
                                {data.jobPost.adminId ? (
                                    <p className='tpDetails__header--desc--user'>
                                        Posted by an Admin
                                    </p>
                                ) : (
                                    <p className='tpDetails__header--desc--user'>
                                        Posted By{' '}
                                        {data.jobPost.recipient.firstName}{' '}
                                        {data.jobPost.recipient.lastName}
                                    </p>
                                )}
                            </IonCol>
                        </IonCol>
                    </IonRow>

                    {/* taskDetails skills needed */}
                    <IonRow className='tpDetails__skills'>
                        <IonCol
                            className='tpDetails__skills--container'
                            sizeSm='12'
                            sizeXs='12'
                        >
                            <h1 className='tpDetails__skills--title'>
                                What's Needed
                            </h1>
                        </IonCol>
                        <IonGrid className='tpSkills__container'>
                            <IonRow className='tpSkills__row'>
                                {data.jobPost.skillsNeeded.map((skill) => {
                                    const isCurrentUsersPost =
                                        data.jobPost.recipient.uid ===
                                        currentUser.uid;
                                    const participationRequest =
                                        skill.participationRequests.find(
                                            (pr) =>
                                                pr.provider.uid ===
                                                currentUser.uid
                                        );
                                    const skillParticipants =
                                        skill.participationRequests.filter(
                                            (p) =>
                                                p.status === Status.ACCEPTED ||
                                                p.status ===
                                                    Status.RECIPIENT_HAS_PROPOSED_OFFER ||
                                                p.status === Status.COMPLETED
                                        );
                                    let participateBtn: ReactElement;

                                    if (
                                        participationRequest &&
                                        participationRequest.status ===
                                            Status.ACCEPTED
                                    ) {
                                        participateBtn = (
                                            <IonCol
                                                sizeXs='3'
                                                sizeSm='3'
                                                className='tpSkills__request--btn'
                                            >
                                                <p className='tpSkills__btn--text--accepted'>
                                                    Accepted
                                                </p>
                                            </IonCol>
                                        );
                                    } else if (
                                        participationRequest &&
                                        participationRequest.status ===
                                            Status.REQUESTED
                                    ) {
                                        participateBtn = (
                                            <IonCol
                                                sizeXs='3'
                                                sizeSm='3'
                                                className='tpSkills__request--btn'
                                            >
                                                <p className='tpSkills__btn--text--warning'>
                                                    Cancel
                                                    <br />
                                                    Request
                                                </p>
                                            </IonCol>
                                        );
                                    } else if (
                                        !participationRequest &&
                                        skillParticipants.length ===
                                            skill.peopleNeededCount
                                    ) {
                                        participateBtn = (
                                            <IonCol
                                                sizeXs='3'
                                                sizeSm='3'
                                                className='tpSkills__request--btn'
                                            >
                                                <p className='tpSkills__btn--text--warning'>
                                                    Full
                                                </p>
                                            </IonCol>
                                        );
                                    } else if (
                                        participationRequest &&
                                        participationRequest.status ===
                                            Status.RECIPIENT_HAS_PROPOSED_OFFER
                                    ) {
                                        participateBtn = (
                                            <IonCol
                                                sizeXs='3'
                                                sizeSm='3'
                                                className='tpSkills__request--btn'
                                            >
                                                <p className='tpSkills__btn--text'>
                                                    Credits Proposed
                                                </p>
                                            </IonCol>
                                        );
                                    } else if (
                                        participationRequest &&
                                        participationRequest.status ===
                                            Status.COMPLETED
                                    ) {
                                        participateBtn = (
                                            <IonCol
                                                sizeXs='3'
                                                sizeSm='3'
                                                className='tpSkills__request--btn'
                                            >
                                                <p className='tpSkills__btn--text'>
                                                    Completed
                                                </p>
                                            </IonCol>
                                        );
                                    } else {
                                        participateBtn = (
                                            <IonCol
                                                sizeXs='3'
                                                sizeSm='3'
                                                className='tpSkills__request--btn'
                                                onClick={() =>
                                                    history.push(
                                                        `/request/${skill.id}/participation`
                                                    )
                                                }
                                            >
                                                <p className='tpSkills__btn--text'>
                                                    Participate
                                                </p>
                                            </IonCol>
                                        );
                                    }
                                    return (
                                        <IonCol
                                            key={skill.id}
                                            className='tpSkills__col'
                                            sizeXs='12'
                                            sizeSm='12'
                                        >
                                            {isCurrentUsersPost ? (
                                                <IonItemSliding
                                                    key={skill.id}
                                                    className='tpSkills__slide'
                                                >
                                                    <IonItemOptions side='end'>
                                                        <IonItemOption
                                                            className='homeEdit__delete--btn'
                                                            onClick={() =>
                                                                handleDeleteSkill(
                                                                    skill.id
                                                                )
                                                            }
                                                            disabled={
                                                                skillParticipants.length !==
                                                                0
                                                            }
                                                        >
                                                            <IonIcon
                                                                icon={
                                                                    trashOutline
                                                                }
                                                                slot='icon-only'
                                                            />
                                                        </IonItemOption>
                                                    </IonItemOptions>
                                                    <IonItem
                                                        lines='none'
                                                        className='homeEdit__content--slide--wrap'
                                                    >
                                                        <IonCol
                                                            sizeXs='9'
                                                            sizeSm='9'
                                                            className='ion-padding-top ion-padding-bottom tpSkills__content'
                                                        >
                                                            <p className='tpSkills__content--skill'>
                                                                {skill.name}
                                                            </p>
                                                            {/* <p className="tpSkills__content--completed">Worth 2 Balance Tokens</p> */}
                                                            <p className='tpSkills__content--completed'>
                                                                {
                                                                    skillParticipants.length
                                                                }
                                                                /
                                                                {
                                                                    skill.peopleNeededCount
                                                                }{' '}
                                                                roles filled
                                                            </p>
                                                            <p className='tpSkills__content--text'>
                                                                {
                                                                    skill.additionalDetails
                                                                }
                                                            </p>
                                                        </IonCol>
                                                        {(data.jobPost.recipient
                                                            .uid ===
                                                            currentUser.uid &&
                                                            data.jobPost.status.toString() ===
                                                                Status.COMPLETED.toString()) ||
                                                        (data.jobPost.recipient
                                                            .uid ===
                                                            currentUser.uid &&
                                                            data.jobPost.status.toString() ===
                                                                Status.RECIPIENT_HAS_PROPOSED_OFFER.toString()) ||
                                                        (data.jobPost.recipient
                                                            .uid ===
                                                            currentUser.uid &&
                                                            moment().isBetween(
                                                                data.jobPost
                                                                    .startTime,
                                                                data.jobPost
                                                                    .endTime
                                                            )) ||
                                                        (data.jobPost.recipient
                                                            .uid ===
                                                            currentUser.uid &&
                                                            moment() >
                                                                data.jobPost
                                                                    .endTime) ? (
                                                            <IonCol
                                                                onClick={() => {
                                                                    setSkillToEdit(
                                                                        {
                                                                            id: skill.id,
                                                                            name: skill.name,
                                                                            description:
                                                                                skill.description,
                                                                            additionalDetails:
                                                                                skill.additionalDetails,
                                                                            peopleNeeded:
                                                                                skill.peopleNeededCount,
                                                                        }
                                                                    );
                                                                    setIsEditSkillModalActive(
                                                                        true
                                                                    );
                                                                }}
                                                                sizeXs='3'
                                                                sizeSm='3'
                                                                className='tpSkills__request--btn'
                                                            >
                                                                <p className='tpSkills__btn--text'>
                                                                    Edit
                                                                    <br />
                                                                    Skill
                                                                </p>
                                                            </IonCol>
                                                        ) : null}
                                                    </IonItem>
                                                </IonItemSliding>
                                            ) : (
                                                <>
                                                    <IonCol
                                                        sizeXs='9'
                                                        sizeSm='9'
                                                        className='ion-padding tpSkills__content'
                                                    >
                                                        <p className='tpSkills__content--skill'>
                                                            {skill.name}
                                                        </p>
                                                        {/* <p className="tpSkills__content--completed">Worth 2 Balance Tokens</p> */}
                                                        <p className='tpSkills__content--completed'>
                                                            {
                                                                skillParticipants.length
                                                            }
                                                            /
                                                            {
                                                                skill.peopleNeededCount
                                                            }{' '}
                                                            roles filled
                                                        </p>
                                                        <p className='tpSkills__content--text'>
                                                            {
                                                                skill.additionalDetails
                                                            }
                                                        </p>
                                                    </IonCol>
                                                    {participateBtn}
                                                </>
                                            )}
                                        </IonCol>
                                    );
                                })}
                                {(data.jobPost.recipient.uid ===
                                    currentUser.uid &&
                                    data.jobPost.status.toString() ===
                                        Status.COMPLETED.toString()) ||
                                (data.jobPost.recipient.uid ===
                                    currentUser.uid &&
                                    data.jobPost.status.toString() ===
                                        Status.RECIPIENT_HAS_PROPOSED_OFFER.toString()) ||
                                (data.jobPost.recipient.uid ===
                                    currentUser.uid &&
                                    moment().isBetween(
                                        data.jobPost.startTime,
                                        data.jobPost.endTime
                                    )) ||
                                (data.jobPost.recipient.uid ===
                                    currentUser.uid &&
                                    moment() > data.jobPost.endTime) ? (
                                    <IonCol
                                        onClick={() =>
                                            setIsAddSkillModalActive(true)
                                        }
                                        className='skillConfirm__newSkill--btn'
                                        sizeXs='12'
                                        sizeSm='12'
                                    >
                                        <IonCol
                                            sizeXs='12'
                                            sizeSm='12'
                                            className='ion-padding skillConfirm__newSkill--content'
                                        >
                                            <p className='skillConfirm__newSkill--btn--text'>
                                                + Add Another Skill
                                            </p>
                                        </IonCol>
                                    </IonCol>
                                ) : null}
                            </IonRow>
                        </IonGrid>
                    </IonRow>
                    <IonGrid className='tpDetails__users--container'>
                        <IonRow className='tpDetails__users'>
                            <IonCol sizeSm='12' sizeXs='12'>
                                <h1 className='tpDetails__skills--title'>
                                    Current Participants
                                </h1>
                            </IonCol>
                            {participants.length > 0 ? (
                                participants.map((participant) => (
                                    <button
                                        style={{
                                            backgroundColor: 'transparent',
                                        }}
                                        onClick={() =>
                                            history.push(
                                                `/user/${participant.uid}`
                                            )
                                        }
                                    >
                                        <div
                                            key={participant.id}
                                            className='tpDetails__user'
                                        >
                                            <IonImg
                                                className='tpDetails__user--img'
                                                src={participant.profilePicture}
                                                alt='user'
                                            />
                                            <p className='tpDetails__user--name'>
                                                {limitStr(
                                                    participant.firstName,
                                                    11
                                                )}
                                            </p>
                                        </div>
                                    </button>
                                ))
                            ) : (
                                <IonCol className='tasksFallback'>
                                    <h3 className='tasksFallback__title'>
                                        This job post currently has no
                                        participants
                                    </h3>
                                </IonCol>
                            )}
                        </IonRow>
                    </IonGrid>
                    <IonRow className='requestDetails__location'>
                        <IonCol sizeSm='12' sizeXs='12'>
                            <h1 className='requestDetails__skills--title'>
                                Location &nbsp;
                                {(data.jobPost.recipient.uid ===
                                    currentUser.uid &&
                                    data.jobPost.status.toString() ===
                                        Status.COMPLETED.toString()) ||
                                (data.jobPost.recipient.uid ===
                                    currentUser.uid &&
                                    data.jobPost.status.toString() ===
                                        Status.RECIPIENT_HAS_PROPOSED_OFFER.toString()) ||
                                (data.jobPost.recipient.uid ===
                                    currentUser.uid &&
                                    moment().isBetween(
                                        data.jobPost.startTime,
                                        data.jobPost.endTime
                                    )) ||
                                (data.jobPost.recipient.uid ===
                                    currentUser.uid &&
                                    moment() > data.jobPost.endTime) ? (
                                    <span
                                        onClick={() =>
                                            setIsEditLocationModalActive(true)
                                        }
                                        className='requestDetails__edit--location--btn'
                                    >
                                        Edit
                                    </span>
                                ) : null}
                            </h1>
                        </IonCol>
                    </IonRow>
                    {locationDetail}

                    <IonRow className='requestDetails__location'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='requestDetails__location--wrap'
                        >
                            {data.jobPost.location.locationPreference !==
                                LocationPreference.VIRTUAL && (
                                <IonIcon
                                    className='requestDetails__location--clipboard'
                                    icon={copy}
                                    onClick={() =>
                                        handleCopyToClip(locationText)
                                    }
                                />
                            )}
                            <h4 className='requestDetails__location--title'>
                                {getLocationPreferenceStr(
                                    data.jobPost.location.locationPreference
                                )}
                            </h4>
                            <p className='requestDetails__location--text'>
                                {locationText}
                            </p>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};
