import React, { useState } from 'react';
import ErrorCodes from '../../assets/error-codes.json';
import { IonRow, IonIcon } from '@ionic/react';
import { happyOutline } from 'ionicons/icons';
import './ErrorMessage.css';
interface ErrorProps {
    errorCode: string;
    extendedError?: string;
}
const ErrorMessage: React.FC<ErrorProps> = ({ errorCode, extendedError }) => {
    //toggles the extended error message after user clicks on 'show more'
    const [showExtra, setShowExtra] = useState<boolean>(false);
    const errorMessage = ErrorCodes.Errors[errorCode];
    if (errorCode) {
        const error = (
            <IonRow className='error-message'>
                <span className='error-icon'>
                    <IonIcon icon={happyOutline} />
                </span>
                <p>
                    {errorMessage + ' '}
                    <span onClick={() => setShowExtra(!showExtra)}>
                        {errorCode}
                    </span>
                </p>
                {showExtra && extendedError && (
                    <p>
                        This is a secret message for YING devs: {extendedError}
                    </p>
                )}
            </IonRow>
        );
        return error;
    }
    return null;
};
export default ErrorMessage;
