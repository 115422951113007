// importing modules
import React from 'react';
import { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
// importing components
import {
    IonSlides,
    IonSlide,
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonImg,
    IonText,
    IonButton,
} from '@ionic/react';
import BackButton from 'components/BackButton';
// importing assets
import yingLogo from '../../assets/ying-logo-large-blk.png';
import balanceToken from '../../assets/balance-token.png';
import nodes from '../../assets/nodes.png';
// importing styles
import './GetStarted.css';

const slideOpts = {
    initialSlide: 0,
    speed: 600,
};

const GetStarted: React.FC = () => {
    const history = useHistory();
    const slider = useRef(null);
    const [hidePrevButton, setHidePrevButton] = useState(true);
    const [hideNextButton, setHideNextButton] = useState(false);

    const handleSlideChange = async () => {
        const swiper = await slider.current.getSwiper();
        setHidePrevButton(swiper.isBeginning);
        setHideNextButton(swiper.isEnd);
    };

    const onBtnClicked = async (direction: string) => {
        const swiper = await slider.current.getSwiper();
        if (direction === 'next') {
            swiper.slideNext();
        } else if (direction === 'prev') {
            swiper.slidePrev();
        }
    };

    return (
        <IonPage>
            <IonContent fullscreen>
                <BackButton />
                <IonSlides
                    pager={true}
                    options={slideOpts}
                    ref={slider}
                    onIonSlideDidChange={handleSlideChange}
                >
                    {/* slide 1 start */}
                    <IonSlide>
                        <IonGrid className='getStarted__slide--container'>
                            <IonRow className='getStarted__slide--row'>
                                <IonCol
                                    sizeXs='12'
                                    sizeSm='12'
                                    className='getStarted__slide--col'
                                >
                                    <IonImg
                                        className='getStarted__slide--img'
                                        src={yingLogo}
                                        alt='ying-logo'
                                    />
                                </IonCol>
                                <IonCol
                                    sizeXs='12'
                                    sizeSm='12'
                                    className='getStarted__slide--col getStarted__slide--col--mid'
                                >
                                    <IonText>
                                        <h3 className='getStarted__slide--title'>
                                            Introducing
                                            <span className='getStarted__slide--title--span'>
                                                {' '}
                                                YING
                                            </span>
                                        </h3>
                                    </IonText>
                                    <IonText>
                                        <p className='getStarted__slide--text'>
                                            Skill sharing on demand
                                        </p>
                                    </IonText>
                                </IonCol>
                                <IonCol className='arrows'></IonCol>
                                <IonCol
                                    sizeXs='12'
                                    sizeSm='12'
                                    className='getStarted__slide--col'
                                ></IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonSlide>
                    {/* slide 1 end */}
                    {/* slide 2 start */}
                    <IonSlide>
                        <IonGrid className='getStarted__slide--container'>
                            <IonRow className='getStarted__slide--row'>
                                <IonCol
                                    sizeXs='12'
                                    sizeSm='12'
                                    className='getStarted__slide--col'
                                >
                                    <IonImg
                                        className='getStarted__slide--img'
                                        src={balanceToken}
                                        alt='balance token'
                                    />
                                    <IonText>
                                        <h5 className='getStarted__slide--sub--title'>
                                            Balance Token{' '}
                                            <span className='getStarted__slide--sub--title--span'>
                                                &#8482;
                                            </span>
                                        </h5>
                                    </IonText>
                                </IonCol>
                                <IonCol
                                    sizeXs='12'
                                    sizeSm='12'
                                    className='getStarted__slide--col getStarted__slide--col--mid'
                                >
                                    <IonText>
                                        <h3 className='getStarted__slide--title'>
                                            Community Capital
                                        </h3>
                                    </IonText>
                                    <IonText>
                                        <p className='getStarted__slide--text'>
                                            Unlock a new form of currency
                                        </p>
                                    </IonText>
                                </IonCol>
                                <IonCol
                                    sizeXs='12'
                                    sizeSm='12'
                                    className='getStarted__slide--col'
                                ></IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonSlide>
                    {/* slide 2 end */}
                    {/* slide 3 start */}
                    <IonSlide>
                        <IonGrid className='getStarted__slide--container'>
                            <IonRow className='getStarted__slide--row'>
                                <IonCol
                                    sizeXs='12'
                                    sizeSm='12'
                                    className='getStarted__slide--col'
                                >
                                    <IonImg
                                        className='getStarted__slide--img'
                                        src={nodes}
                                        alt='nodes'
                                    />
                                </IonCol>
                                <IonCol
                                    sizeXs='12'
                                    sizeSm='12'
                                    className='getStarted__slide--col getStarted__slide--col--mid'
                                >
                                    <IonText>
                                        <h3 className='getStarted__slide--title'>
                                            Banking on Your Network
                                        </h3>
                                    </IonText>
                                    <IonText>
                                        <p className='getStarted__slide--text'>
                                            Share skills and passions with
                                            <br />
                                            your community and earn value
                                        </p>
                                    </IonText>
                                </IonCol>
                                <IonCol
                                    sizeXs='12'
                                    sizeSm='12'
                                    className='getStarted__slide--col'
                                >
                                    <IonButton
                                        onClick={() => history.push('/signup')}
                                        fill='clear'
                                        color='ion-primary'
                                        className='getStarted__btn'
                                        id='signup-btn'
                                    >
                                        Continue
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonSlide>
                    {/* slide 3 end */}
                </IonSlides>
            </IonContent>
            <div className='prevBackButtons'>
                <IonButton
                    className={
                        hidePrevButton ? 'hidePrevButton' : 'showPrevButton'
                    }
                    disabled={hidePrevButton}
                    onClick={() => onBtnClicked('prev')}
                    fill='clear'
                    color='ion-primary'
                >
                    Prev
                </IonButton>
                {/* {console.log(hidePrevButton)} */}
                <IonButton
                    className={
                        hideNextButton ? 'hideNextButton' : 'showNextButton'
                    }
                    disabled={hideNextButton}
                    onClick={() => onBtnClicked('next')}
                    fill='clear'
                    color='ion-primary'
                >
                    Next
                </IonButton>
            </div>
        </IonPage>
    );
};

export default GetStarted;
