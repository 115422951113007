export const formatAmount = (amount: number) => {
    //  input: 100000 (penny/cent)
    //  output: $1,000.00 (dollar amount formatted)
    return (
        '$' +
        (amount / 100)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    );
};
