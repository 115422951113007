// importing modules
import React, { useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import {
    LocationPreference,
    Status,
} from '../../../GraphQL/__generated__/globalTypes';
// import Geocode from 'react-geocode';
// import getGeoLocation from '../../helpers/geolocation/geoLocation';
import { getLocalStorage } from '../../../helpers/localStorage/localStorageHelper';
// import components
import LocationPin from './LocationPin';
// GeoCode api key setup
// Geocode.setApiKey('AIzaSyD7B3yKTqxrAYt-HWajE5mGW8z2k2Z2TyQ');

interface LocationObj {
    address: string;
    lat: number;
    lng: number;
    status: Status;
    locationPreference: LocationPreference;
}

const Map: React.FC<LocationObj> = ({ address, lat, lng, status }) => {
    const [location, setLocation] = useState({ address: '', lat: 0, lng: 0 });

    useEffect(() => {
        // Geocode.fromAddress(address) // get the tasks current location
        // .then((data) => {
        //     setLocation({
        //         address: data.results[0].formatted_address,
        //         lat: data.results[0].geometry.location.lat,
        //         lng: data.results[0].geometry.location.lng
        //     })
    }, []);

    const apiIsLoaded = async (map, maps) => {
        // const directionsService = new maps.DirectionsService();
        // const directionsDisplay = new maps.DirectionsRenderer();
        // const originLocation = new google.maps.LatLng(parseFloat(getLocalStorage('geoLocation').lat), parseFloat(getLocalStorage('geoLocation').lng));  // get the local storage geoLocations
        // directionsService.route(
        //     {
        //         origin: originLocation, // get the user's current location
        //         destination: address,
        //         travelMode: 'DRIVING',
        //         drivingOptions: {
        //             departureTime: new Date(/* now, or future date */),
        //             trafficModel: 'bestguess'
        //         },
        //     },
        //     (result, status) => {
        //         if (status === google.maps.DirectionsStatus.OK) {
        //             directionsDisplay.setDirections(result);
        //             directionsDisplay.setMap(map);
        //         } else {
        //             console.error(`error fetching directions ${result}`);
        //         }
        //     });
    };

    return (
        <GoogleMapReact
            bootstrapURLKeys={{
                key: 'AIzaSyD7B3yKTqxrAYt-HWajE5mGW8z2k2Z2TyQ',
            }}
            center={{ lat: lat, lng: lng }}
            defaultZoom={15}
            options={{
                streetViewControl: true,
                styles: [
                    {
                        featureType: 'poi',
                        elementType: 'labels',
                        stylers: [{ visibility: 'on' }],
                    },
                ],
            }}
            onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}
            yesIWantToUseGoogleMapApiInternals
        >
            {
                <LocationPin
                    // @ts-ignore
                    lat={lat}
                    lng={lng}
                    text={address}
                />
            }
        </GoogleMapReact>
    );
};

export default Map;
