// importing modules
import React, { useRef } from 'react';
// import components
import {
    IonContent,
    IonPage,
    IonImg,
    IonGrid,
    IonCol,
    IonRow,
    IonText,
    IonButton,
} from '@ionic/react';
import Strokes from 'components/Strokes';
import BackButton from 'components/BackButton';
// importing assets
import yingLogo from '../../assets/ying-logo-large-blk.png';
import payPalLogo from '../../assets/paypal-logo.png';
// importing styles
import './ContributeViaPayPal.css';

const ContributeViaPayPal: React.FC = () => {
    const payPalLink = useRef(null);

    const handlePayPalRedirect = () => payPalLink.current.click();

    return (
        <IonPage>
            <IonContent fullscreen>
                <Strokes top={true} green={false} />
                <BackButton />
                <IonGrid className='contributePayPal__container'>
                    <IonRow className='contributePayPal__row'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='contributePayPal__col'
                        >
                            <IonText>
                                <h1 className='contributePayPal__title'>
                                    Thank{' '}
                                    <span className='contributePayPal__title--span'>
                                        You!
                                    </span>
                                </h1>
                            </IonText>
                            <IonImg
                                className='contributePayPal__img'
                                alt='ying-logo'
                                src={yingLogo}
                            />
                            <IonText>
                                <p className='contributePayPal__text'>
                                    We are building a movement to democratize{' '}
                                    <br />
                                    abundance
                                </p>
                            </IonText>
                            <IonText>
                                <p className='contributePayPal__text'>
                                    Our aim is to support organizations and{' '}
                                    <br />
                                    communities to build a culture of skill
                                    sharing!
                                </p>
                            </IonText>
                            <IonText>
                                <p className='contributePayPal__text contributePayPal__text--last'>
                                    Your support is greatly appreciated.
                                </p>
                            </IonText>
                            <a
                                ref={payPalLink}
                                href='https://paypal.me/yingme'
                                target='_blank'
                                type='hidden'
                            />
                            <IonButton
                                onClick={handlePayPalRedirect}
                                fill='clear'
                                color='ion-primary'
                                className='contributePayPal__btn--solid'
                            >
                                Contribute
                            </IonButton>
                            <IonImg
                                onClick={handlePayPalRedirect}
                                className='contributePayPal__img--paypal'
                                alt='paypal-logo'
                                src={payPalLogo}
                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <Strokes bot={true} />
            </IonContent>
        </IonPage>
    );
};

export default ContributeViaPayPal;
