import React from 'react';
import { IonCol } from '@ionic/react';
import './SkillReviewItemFallback.css';

const SkillReviewItemFallback: React.FC = ({ children }) => {
    return (
        <IonCol className='reviewsFallback'>
            <h3 className='reviewsFallback__title'>
                {' '}
                Reviews of skills will appear here.
            </h3>
            <p className='reviewsFallback__text'>{children}</p>
        </IonCol>
    );
};

export default SkillReviewItemFallback;
