// importing modules
import React from 'react';
// importing components
import { IonCol } from '@ionic/react';
// importing utilities
import { LocationPreference } from '../../../../GraphQL/__generated__/globalTypes';
// importing styles
import '../TaskLocation.css';

const TaskLocationPreferenceItem: React.FC<{
    name: string;
    text: string;
    description: string;
    setLocationType: Function;
    locationType: string;
    handleModal: () => void;
}> = ({
    name,
    description,
    setLocationType,
    locationType,
    handleModal,
    text,
}) => {
    return (
        <IonCol
            onClick={() => {
                if (name === LocationPreference.INPERSON) {
                    setLocationType(name);
                    handleModal();
                } else {
                    setLocationType(name);
                }
            }}
            className={
                locationType === name
                    ? 'reqTaskLoc__col reqTaskLoc__col--active'
                    : 'reqTaskLoc__col'
            }
            sizeXs='12'
            sizeSm='12'
        >
            <IonCol
                sizeXs='12'
                sizeSm='12'
                className={
                    locationType === name
                        ? 'reqTaskLoc__content reqTaskLoc__content--active'
                        : 'reqTaskLoc__content'
                }
            >
                <p
                    className={
                        locationType === name
                            ? 'reqTaskLoc__content--place reqTaskLoc__content--place--active'
                            : 'reqTaskLoc__content--place'
                    }
                >
                    {text}
                </p>
                <p
                    className={
                        locationType === name
                            ? 'reqTaskLoc__content--address reqTaskLoc__content--address--active'
                            : 'reqTaskLoc__content--address'
                    }
                >
                    {description}
                </p>
            </IonCol>
        </IonCol>
    );
};

export default TaskLocationPreferenceItem;
