// importing modules
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import { Calendar } from '@ionic-native/calendar';
import { Capacitor } from '@capacitor/core';
import { useHistory, useParams, useLocation } from 'react-router-dom';
// importing components
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonRow,
    IonPage,
} from '@ionic/react';
import CreateTaskTabs from '../UI/CreateTaskTabs/CreateTaskTabs';
import UserImg from '../UI/UserImg/UserImg';
import SkillInfoUpdateModal from '../SkillInfoUpdateModal/SkillInfoUpdateModal';
import LocationUpdateModal from '../LocationUpdateModal/LocationUpdateModal';
import DescriptionUpdateModal from '../DescriptionUpdateModal/DescriptionUpdateModal';
import Strokes from 'components/Strokes';
// importing assets
// importing helpers
import {
    getLocalStorage,
    updateLocalStorage,
    setLocalStorage,
} from '../../../helpers/localStorage/localStorageHelper';
// importing types & graphql
import { useMutation, useQuery } from '@apollo/react-hooks';
import { PUBLISH_JOB_POST_INVITES } from '../../../GraphQL/JobPost/PUBLISH_JOB_POST_INVITES/PUBLISH_JOB_POST_INVITES';
import {
    PublishJobPostInvites,
    PublishJobPostInvitesVariables,
} from '../../../GraphQL/__generated__/PublishJobPostInvites';
import {
    publishTaskNotification,
    publishTaskNotificationVariables,
} from '../../../GraphQL/__generated__/publishTaskNotification';
import { PUBLISH_TASK_NOTIFICATION } from '../../../GraphQL/Job/publishTaskNotification/publishTaskNotification';
import { GET_ORG_BY_ID } from '../../../GraphQL/organization/GET_ORG_BY_ID/GET_ORG_BY_ID';
import { CREATE_ONE_JOB_POST } from '../../../GraphQL/JobPost/CREATE_ONE_JOB_POST/CREATE_ONE_JOB_POST';
import { gql_ADMIN_CHECK } from '../../../GraphQL/Admin/adminCheck';
import {
    ListOfOrgs,
    ListOfOrgsVariables,
} from '../../../GraphQL/__generated__/ListOfOrgs';
import {
    CreateOneJobPost,
    CreateOneJobPostVariables,
} from '../../../GraphQL/__generated__/CreateOneJobPost';
import { CREATE_ONE_NON_ADMIN_JOB_POST } from '../../../GraphQL/JobPost/CREATE_ONE_NON_ADMIN_JOB_POST/CREATE_ONE_NON_ADMIN_JOB_POST';
// imports for ledger
import { USER_PROFILE_BY_UID } from '../../../GraphQL/Profile/UserProfile/USER_PROFILE_BY_UID';
import {
    UserProfile_byID,
    UserProfile_byIDVariables,
} from '../../../GraphQL/__generated__/UserProfile_byID';
import { GET_USER_PARTY_INFO } from '../../../GraphQL/parties/GET_USER_PARTY_INFO';
import {
    GetUserPartyInfo,
    GetUserPartyInfoVariables,
} from '../../../GraphQL/__generated__/GetUserPartyInfo';
import { GET_SKILL_NEEDED_BY_ID } from '../../../GraphQL/SkillNeeded/GET_SKILL_NEEDED_BY_ID/GET_SKILL_NEEDED_BY_ID';
import {
    GetSkillNeededById,
    GetSkillNeededByIdVariables,
} from '../../../GraphQL/__generated__/GetSkillNeededById';
// importing styles
import './ConfirmTaskPost.css';
// firebase context
import { useAuth } from '../../../context/firebase/authContext';

type Skill = {
    name: string;
    description: string;
    additionalDetails: string;
    peopleNeededCount: string;
    // taskDuration: string
};

const ConfirmPost: React.FC<{ taskType: string }> = ({ taskType }) => {
    const { currentUser } = useAuth();
    const { skillId } = useParams<{ skillId: string }>();
    const history = useHistory();
    const location = useLocation();
    const [senderId, senderIdData] = useState(0);
    const [receiverId, receiverIdData] = useState(0);
    const [groupId, setGroupId] = useState<number>(0);
    const [tskName, setTskName] = useState<string>('');
    const [tskDescr, setTskDescr] = useState<string>('');
    const [lilAdminId, setLilAdminId] = useState<number>(0);
    const [descrModalStatus, setDescrModalStatus] = useState<boolean>(false);
    const [skillsNeededArr, setSkillsNeededArr] = useState<Skill[]>();
    const [skillModalStatus, setSkillModalStatus] = useState<boolean>(false);
    const [tskLocation, setTskLocation] = useState<string>('');
    const [locationModalStatus, setLocationModalStatus] =
        useState<boolean>(false);
    const [taskTime, setTaskTime] = useState<string>('');
    const [taskDate, setTaskDate] = useState<string>('');
    const [skillToUpdate, setSkillToUpdate] = useState<Skill>({
        name: '',
        description: '',
        additionalDetails: '',
        peopleNeededCount: '',
    });
    const [hasClicked, sethasClicked] = useState<boolean>(false);
    const [showError, setShowError] = useState<string>('');

    const [createOneJobPost1, createOneJobPostDatas1] = useMutation<
        CreateOneJobPost,
        CreateOneJobPostVariables
    >(CREATE_ONE_NON_ADMIN_JOB_POST);

    const [createOneJobPost, createOneJobPostDatas] = useMutation<
        CreateOneJobPost,
        CreateOneJobPostVariables
    >(CREATE_ONE_JOB_POST);

    const [publishInvites, publishInvitesDatas] = useMutation<
        PublishJobPostInvites,
        PublishJobPostInvitesVariables
    >(PUBLISH_JOB_POST_INVITES);

    const [publishNotification, publishNotificationDatas] = useMutation<
        publishTaskNotification,
        publishTaskNotificationVariables
    >(PUBLISH_TASK_NOTIFICATION);

    const { data, loading, error } = useQuery<ListOfOrgs, ListOfOrgsVariables>(
        gql_ADMIN_CHECK,
        { variables: { uid: currentUser.uid } }
    );

    const { data: data1, loading: loading1 } = useQuery<
        GetSkillNeededById,
        GetSkillNeededByIdVariables
    >(GET_SKILL_NEEDED_BY_ID, {
        variables: { id: parseInt(skillId) },
        fetchPolicy: 'no-cache',
    });
    /*

    //Grabbing the receiverId
    const { data: dataReceiverOne, error: something, loading: stillLoading } = useQuery<
    UserProfile_byID, 
    UserProfile_byIDVariables>(USER_PROFILE_BY_UID, {
        variables: { subId: currentUser.uid },
        fetchPolicy: 'no-cache'
    })
    const {data: dataReceiverTwo, loading: receiverloading} = useQuery<
    GetUserPartyInfo,
    GetUserPartyInfoVariables>(GET_USER_PARTY_INFO, {
        skip: !dataReceiverOne,
        variables: {userId: dataReceiverOne && dataReceiverOne?.profile.id}
    });
 
 
    //Grabbing the senderId
    const { data: dataSenderId, error: somethingSender, loading: stillLoadingSender } = useQuery<
    UserProfile_byID, 
    UserProfile_byIDVariables>(USER_PROFILE_BY_UID, {
        skip: !dataReceiverTwo,
        variables: { subId: data1?.skillNeeded.jobPost.recipient.uid  },
        fetchPolicy: 'no-cache'
    })
    
    const {data: dataSenderTwo, loading: loadingsender} = useQuery<
    GetUserPartyInfo,
    GetUserPartyInfoVariables>(GET_USER_PARTY_INFO, {
        skip: !dataSenderId,
        variables: {userId: dataSenderId && dataSenderId?.profile.id}
    });
    

    useEffect(()=>{
    dataSenderTwo?.showAllParties.map(thing => {
        if (thing.bankAccountrelation[0].accountType == "BALANCE") {
            senderIdData(thing.partyId)
        }
    })
    dataReceiverTwo?.showAllParties.map(thing => {
        if (thing.bankAccountrelation[0].accountType == "BALANCE") {
            receiverIdData(thing.partyId)
        }
    })
    })

  */

    useEffect(() => {
        //enables the submit button and removes error if they page changes
        sethasClicked(false);
        setShowError('');
        if (getLocalStorage('createTask')) {
            const taskInfo = getLocalStorage('createTask');
            setTskName(taskInfo.taskName);
            setTskDescr(taskInfo.taskDescr);
            setTskLocation(taskInfo.location);
            setSkillsNeededArr(taskInfo.skillsNeeded);
            setTaskDate(taskInfo.date);
            setTaskTime(taskInfo.time);
            setGroupId(Number(getLocalStorage('currentAdminGroupId')));
        } else {
            history.push('/home');
        }
    }, [history, location]);

    const handleSkillUpdateSelection = (skillName: string) =>
        setSkillToUpdate(
            skillsNeededArr.find((skill) => skill.name === skillName)
        );

    const handleUpdateMySkill = (skillName: string, skillObj: {}) => {
        const updatedSkills = skillsNeededArr.map((item) =>
            item.name === skillName ? { ...item, ...skillObj } : item
        );
        setSkillsNeededArr(updatedSkills);
        updateLocalStorage('createTask', 'skillsNeeded', updatedSkills);
    };

    const handleSkillModal = () => setSkillModalStatus(!skillModalStatus);
    const handleLocationModal = () =>
        setLocationModalStatus(!locationModalStatus);
    const handleDescrModal = () => setDescrModalStatus(!descrModalStatus);

    function isDST(d) {
        let jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
        let jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
        return Math.max(jan, jul) != d.getTimezoneOffset(); // determines the months that fall into Daylight Savings Time
    }
    const handlePostSubmit = () => {
        sethasClicked(true);
        const taskInfo = getLocalStorage('createTask'); // get values from local storage
        // task date,start,end-times obj
        const taskDateTimeObj = {
            date:
                taskInfo.date === 'Undecided'
                    ? null
                    : moment(taskInfo.date).format('YYYY-MM-DD'), // date
            //If the selectedTime and currentTime are both not in daylight times are both in daylight savings time, no change in time.
            //However if selectedTime or currentTime is in daylight savings time, shift back by two hours.
            startTime:
                taskInfo.startTime.length > 0
                    ? isDST(new Date(taskInfo.startTime)) !== isDST(new Date())
                        ? momentTZ(taskInfo.startTime)
                              .add(-1, 'hours')
                              .tz(getLocalStorage('tz'))
                              .format('HH:mm')
                        : momentTZ(taskInfo.startTime)
                              .tz(getLocalStorage('tz'))
                              .format('HH:mm')
                    : null, // startime edited to accommodate Daylight Savings
            endTime:
                taskInfo.endTime.length > 0
                    ? isDST(new Date(taskInfo.endTime)) !== isDST(new Date())
                        ? momentTZ(taskInfo.endTime)
                              .add(-1, 'hours')
                              .tz(getLocalStorage('tz'))
                              .format('HH:mm')
                        : momentTZ(taskInfo.endTime)
                              .tz(getLocalStorage('tz'))
                              .format('HH:mm')
                    : null, // endtime edited to accommodate Daylight Savings
        };

        // postee timezone
        const recipientTZ = momentTZ.tz(getLocalStorage('tz')).format('Z');

        let startDate;
        let startDateTime;
        let endDateTime;
        if (
            taskDateTimeObj.date !== null &&
            taskDateTimeObj.startTime !== null
        ) {
            startDate =
                taskDateTimeObj.date +
                'T' +
                taskDateTimeObj.startTime +
                `:00${recipientTZ}`;
            startDateTime =
                taskDateTimeObj.date +
                'T' +
                taskDateTimeObj.startTime +
                `:00${recipientTZ}`;
            endDateTime =
                taskDateTimeObj.date +
                'T' +
                taskDateTimeObj.endTime +
                `:00${recipientTZ}`;
        } else if (
            taskDateTimeObj.date !== null &&
            taskDateTimeObj.startTime === null
        ) {
            startDate = taskInfo.date;
            startDateTime = null;
            endDateTime = null;
        } else if (
            taskDateTimeObj.date === null &&
            taskDateTimeObj.startTime !== null
        ) {
            startDate = null;
            startDateTime =
                moment(new Date()).format('YYYY-MM-DD') +
                'T' +
                taskDateTimeObj.startTime +
                `:00${recipientTZ}`;
            endDateTime =
                moment(new Date()).format('YYYY-MM-DD') +
                'T' +
                taskDateTimeObj.endTime +
                `:00${recipientTZ}`;
        } else if (
            taskDateTimeObj.date === null &&
            taskDateTimeObj.startTime === null
        ) {
            startDate = null;
            startDateTime = null;
            endDateTime = null;
        }

        const skillsNeededList = [];
        taskInfo.skillsNeeded.forEach((skill: Skill) => {
            skillsNeededList.push({
                ...skill,
                peopleNeededCount: parseInt(skill.peopleNeededCount),
            });
        });

        const inviteUids: { uid: string }[] = [];
        taskInfo.memberInvites.forEach(
            (member: { name: string; uid: string }) =>
                inviteUids.push({ uid: member.uid })
        );

        //finding the correct adminId
        let badState = 0;
        if (groupId !== 0) {
            if (!loading) {
                let orgMatch = (org) => {
                    return org.organization.id === groupId;
                };

                let foo = data.listOfOrgs.find(orgMatch).id;
                badState = foo;
                setLilAdminId(foo);
            }
        }
        // if not admin
        if (getLocalStorage('currentAdminGroupId') == 0) {
            createOneJobPost1({
                variables: {
                    title: tskName,
                    description: tskDescr,
                    startTime: startDateTime,
                    endTime: endDateTime,
                    startDate: startDate,
                    recipientID: currentUser.uid,
                    skillsNeeded: skillsNeededList,
                    locationString: tskLocation,
                    locationPreference: taskInfo.locationPreference,
                    groupCodes:
                        taskInfo.groupCodes.length > 0
                            ? taskInfo.groupCodes
                            : [],
                },
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_ORG_BY_ID,
                        variables: {
                            id: Number(groupId),
                        },
                    },
                ],
            })
                .then((result) => {
                    // fire push notification
                    publishNotification({
                        variables: {
                            uid: currentUser.uid,
                            jobPostTitle: tskName,
                            groupCodes: taskInfo.groupCodes,
                            jobPostId: result.data.createOneJobPost.id,
                        },
                    }).catch((err) => {
                        setShowError(
                            'There was an error processing your request. Please try again later.'
                        );
                        console.error(err);
                    });

                    if (inviteUids.length > 0) {
                        // publish task invites
                        publishInvites({
                            variables: {
                                data: inviteUids,
                                uid: currentUser.uid,
                                jobPostId: result.data.createOneJobPost.id,
                            },
                        }).catch((err) => {
                            setShowError(
                                'There was an error processing your request. Please try again later.'
                            );
                            console.error(err);
                        });
                    }

                    // creates an event on the devices native calendar (if calendar permission was granted)
                    if (
                        Capacitor.platform !== 'web' &&
                        startDate !== null &&
                        startDateTime !== null &&
                        endDateTime !== null
                    ) {
                        // if native device run the code inside this block
                        const tStartTime = momentTZ(startDateTime);
                        const tStartDate = tStartTime.tz(
                            getLocalStorage('tz'),
                            false
                        );
                        const tEndTime = momentTZ(endDateTime);
                        const tEndDate = tEndTime.tz(
                            getLocalStorage('tz'),
                            false
                        );

                        Calendar.createEventWithOptions(
                            `YING - ${tskName}`,
                            tskLocation,
                            'No notes available',
                            tStartDate.toDate(),
                            tEndDate.toDate(),
                            {
                                id: result.data.createOneJobPost.id.toString(),
                                firstReminderMinutes: 5,
                                secondReminderMinutes: 120,
                            }
                        ).catch((err) => {
                            setShowError(
                                'There was an error processing your request. Please try again later.'
                            );
                            console.error(err);
                        });
                    }

                    // clear local storage
                    const createTask = {
                        taskName: '',
                        taskDescr: '',
                        date: null,
                        startTime: '',
                        endTime: '',
                        location: '',
                        locationPreference: '',
                        skillsNeeded: [],
                        groupCodes: [],
                        memberInvites: [],
                    };
                    setLocalStorage('createTask', createTask);
                    history.push(`/home`);
                })
                .catch((err) => {
                    setShowError(
                        'There was an error processing your request. Please try again later.'
                    );
                    console.error(err);
                });
        } else {
            createOneJobPost({
                variables: {
                    title: tskName,
                    description: tskDescr,
                    startTime: startDateTime,
                    startDate: startDate,
                    endTime: endDateTime,
                    adminId: badState,
                    groupId: Number(groupId),
                    recipientID: currentUser.uid,
                    skillsNeeded: skillsNeededList,
                    locationString: tskLocation,
                    locationPreference: taskInfo.locationPreference,
                    groupCodes:
                        taskInfo.groupCodes.length > 0
                            ? taskInfo.groupCodes
                            : [],
                },
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_ORG_BY_ID,
                        variables: {
                            id: Number(groupId),
                        },
                    },
                ],
            })
                .then((result) => {
                    // fire push notification
                    publishNotification({
                        variables: {
                            uid: currentUser.uid,
                            jobPostTitle: tskName,
                            groupCodes: taskInfo.groupCodes,
                            jobPostId: result.data.createOneJobPost.id,
                        },
                    }).catch((err) => {
                        setShowError(
                            'There was an error processing your request. Please try again later.'
                        );
                        console.error(err);
                    });

                    if (inviteUids.length > 0) {
                        // publish task invites
                        publishInvites({
                            variables: {
                                data: inviteUids,
                                uid: currentUser.uid,
                                jobPostId: result.data.createOneJobPost.id,
                                adminId: Number(
                                    getLocalStorage('currentAdminId')
                                ),
                                groupId: Number(groupId),
                            },
                        }).catch((err) => {
                            setShowError(
                                'There was an error processing your request. Please try again later.'
                            );
                            console.error(err);
                        });
                    }

                    // creates an event on the devices native calendar (if calendar permission was granted)
                    if (
                        Capacitor.platform !== 'web' &&
                        startDate !== null &&
                        startDateTime !== null &&
                        endDateTime !== null
                    ) {
                        // if native device run the code inside this block
                        const tStartTime = momentTZ(startDateTime);
                        const tStartDate = tStartTime.tz(
                            getLocalStorage('tz'),
                            false
                        );
                        const tEndTime = momentTZ(endDateTime);
                        const tEndDate = tEndTime.tz(
                            getLocalStorage('tz'),
                            false
                        );

                        Calendar.createEventWithOptions(
                            `YING - ${tskName}`,
                            tskLocation,
                            'No notes available',
                            tStartDate.toDate(),
                            tEndDate.toDate(),
                            {
                                id: result.data.createOneJobPost.id.toString(),
                                firstReminderMinutes: 5,
                                secondReminderMinutes: 120,
                            }
                        ).catch((err) => {
                            setShowError(
                                'There was an error processing your request. Please try again later.'
                            );
                            console.error(err);
                        });
                    }

                    // clear local storage
                    const createTask = {
                        taskName: '',
                        taskDescr: '',
                        date: null,
                        startTime: '',
                        endTime: '',
                        location: '',
                        locationPreference: '',
                        skillsNeeded: [],
                        groupCodes: [],
                        memberInvites: [],
                    };
                    setLocalStorage('createTask', createTask);
                    history.push(`/home`);
                })
                .catch((err) => {
                    setShowError(
                        'There was an error processing your request. Please try again later.'
                    );
                    console.error(err);
                });
        }
    };
    return (
        <IonPage>
            <IonContent>
                <IonGrid>
                    <SkillInfoUpdateModal
                        skillToUpdate={skillToUpdate}
                        modalStatus={skillModalStatus}
                        handleModal={handleSkillModal}
                        handleUpdateMySkill={handleUpdateMySkill}
                        taskType={taskType}
                    />
                    <LocationUpdateModal
                        setTskLocation={setTskLocation}
                        tskLocation={tskLocation}
                        modalStatus={locationModalStatus}
                        handleModal={handleLocationModal}
                        taskType={taskType}
                    />
                    <DescriptionUpdateModal
                        setTskName={setTskName}
                        setTskDescr={setTskDescr}
                        modalStatus={descrModalStatus}
                        handleModal={handleDescrModal}
                        taskType={taskType}
                    />
                    {/* confirmPost header */}
                    <IonRow className='confirmPost__header'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='confirmPost__header--container'
                        >
                            <Strokes top={true} />
                            <CreateTaskTabs disablePrevStep={false} />
                            {getLocalStorage('currentAdminGroupId') > 0 ? (
                                <IonImg
                                    className='createTask__header--img'
                                    src={getLocalStorage(
                                        'currentAdminGroupPicture'
                                    )}
                                    alt='user'
                                />
                            ) : (
                                <UserImg />
                            )}
                        </IonCol>
                    </IonRow>
                    {/* text content */}
                    <IonRow className='confirmPost__textContent'>
                        <IonCol
                            className='confirmPost__textContent--container'
                            sizeSm='12'
                            sizeXs='12'
                        >
                            <h1 className='confirmPost__textContent--title'>
                                Lastly, <span>confirm</span> your post
                            </h1>
                        </IonCol>
                    </IonRow>
                    {/* task details */}
                    <IonRow className='confirmPost__sectionTitle'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='confirmPost__sectionTitle--wrap'
                        >
                            <h1 className='confirmPost__sectionTitle--text'>
                                Task Description
                            </h1>
                        </IonCol>
                    </IonRow>
                    <IonRow className='confirmPost__item--wrap'>
                        <IonCol
                            className='confirmPost__item'
                            sizeXs='12'
                            sizeSm='12'
                        >
                            <IonCol
                                sizeXs='9'
                                sizeSm='9'
                                className='ion-padding confirmPost__content confirmPost__content--text--only'
                            >
                                <p className='confirmPost__content--skill'>
                                    {tskName}
                                </p>
                                <p className='confirmPost__content--text'>
                                    {tskDescr}
                                </p>
                            </IonCol>
                            <IonCol
                                onClick={() => {
                                    handleDescrModal();
                                }}
                                sizeXs='3'
                                sizeSm='3'
                                className='confirmPost__request--btn'
                            >
                                <p className='confirmPost__request--btn--text'>
                                    Edit
                                </p>
                            </IonCol>
                        </IonCol>
                    </IonRow>
                    {/* skill requested section */}
                    <IonRow className='confirmPost__sectionTitle'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='confirmPost__sectionTitle--wrap'
                        >
                            <h1 className='confirmPost__sectionTitle--text'>
                                Skills Requested
                            </h1>
                        </IonCol>
                    </IonRow>
                    <IonGrid className='confirmPost__list--container'>
                        <IonRow className='confirmPost__list'>
                            {skillsNeededArr?.length > 0
                                ? skillsNeededArr.map((skill, index) => (
                                      <IonCol
                                          key={index}
                                          className='confirmPost__item'
                                          sizeXs='12'
                                          sizeSm='12'
                                      >
                                          <IonCol
                                              sizeXs='9'
                                              sizeSm='9'
                                              className='ion-padding confirmPost__content'
                                          >
                                              <p className='confirmPost__content--skill'>
                                                  {skill.name}
                                              </p>
                                              {/* <p className="confirmPost__content--worth">Worth {skill.taskDuration} Balance Tokens</p> */}
                                              <p className='confirmPost__content--text'>
                                                  {skill.additionalDetails}
                                              </p>
                                          </IonCol>
                                          <IonCol
                                              onClick={() => {
                                                  handleSkillUpdateSelection(
                                                      skill.name
                                                  );
                                                  handleSkillModal();
                                              }}
                                              sizeXs='3'
                                              sizeSm='3'
                                              className='confirmPost__request--btn'
                                          >
                                              <p className='confirmPost__request--btn--text'>
                                                  Edit
                                              </p>
                                          </IonCol>
                                      </IonCol>
                                  ))
                                : null}
                        </IonRow>
                    </IonGrid>
                    {/* task location */}
                    <IonRow className='confirmPost__sectionTitle'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='confirmPost__sectionTitle--wrap'
                        >
                            <h1 className='confirmPost__sectionTitle--text'>
                                Location
                            </h1>
                        </IonCol>
                    </IonRow>
                    <IonRow className='confirmPost__item--wrap'>
                        <IonCol
                            className='confirmPost__item'
                            sizeXs='12'
                            sizeSm='12'
                        >
                            <IonCol
                                sizeXs='9'
                                sizeSm='9'
                                className='ion-padding confirmPost__content confirmPost__content--text--only'
                            >
                                <p className='confirmPost__content--skill'>
                                    {tskLocation}
                                </p>
                            </IonCol>
                            <IonCol
                                onClick={() => {
                                    handleLocationModal();
                                }}
                                sizeXs='3'
                                sizeSm='3'
                                className='confirmPost__request--btn'
                            >
                                <p className='confirmPost__request--btn--text'>
                                    Edit
                                </p>
                            </IonCol>
                        </IonCol>
                    </IonRow>
                    {/* btn */}
                    <IonRow className='confirmPost__btn--container'>
                        <IonCol
                            className='confirmPost__btn--wrap'
                            sizeXs='12'
                            sizeSm='12'
                        >
                            <IonButton
                                onClick={handlePostSubmit}
                                fill='clear'
                                color='ion-primary'
                                className='confirmPost__btn'
                                id='confirm_finish_post_btn'
                                disabled={hasClicked}
                            >
                                Finish & Post
                            </IonButton>
                        </IonCol>
                    </IonRow>
                    {showError && (
                        <IonRow>
                            <p className='error-message'>{showError}</p>
                        </IonRow>
                    )}
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default ConfirmPost;
