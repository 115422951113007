import assert from 'assert';
import firebase from 'firebase/app';
//import 'firebase/auth';
import 'firebase/storage';
import MACRO from 'MACROS.json';
import { auth } from 'context/firebase/firebase';

export function profilePicPath(): string {
    const user = auth.currentUser;
    const file_extension = '.jpg';

    let prefix = 'nouser';
    // we need a user for this op
    try {
        assert(user, 'uh oh.. no user object');
        prefix = user.uid;
    } catch {
        console.error('moving on');
    }
    return prefix + file_extension;
}

export function fullyQualifiedProfilePicPath(): string {
    return MACRO.PROFILE_PICTURES_PATH + '/' + profilePicPath();
}

export function currentUserProfilePicture(): firebase.storage.Reference {
    return firebase.storage().ref().child(fullyQualifiedProfilePicPath());
}
