import gql from 'graphql-tag';

export const UPDATE_BIO_BY_SUB_ID = gql`
    mutation UpdateBio($uid: String!, $newBio: String!) {
        updateOneProfile(where: { uid: $uid }, data: { bio: $newBio }) {
            id
            uid
            bio
        }
    }
`;
