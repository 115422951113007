// importing modules
import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';
// importing components
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonPage,
    IonRow,
} from '@ionic/react';
import Loading from '../../../components/status/Loading';
import ConfirmationScreenLogo from '../../../components/ConfirmationScreenLogo/ConfirmationScreenLogo';
import Strokes from 'components/Strokes';
// importing assets
// importing graphql
import { useQuery } from '@apollo/react-hooks';
import { GET_JOB_BY_ID } from '../../../GraphQL/Job/GET_JOB_BY_ID/GET_JOB_BY_ID';
import {
    GetJobById,
    GetJobByIdVariables,
} from '../../../GraphQL/__generated__/GetJobById';
// importing styles
import './ServiceRequestConfirmationScreen.css';

const TaskDeniedConfirmation: React.FC = () => {
    const { jobId } = useParams<{ jobId: string }>();
    const homeLink = useRef(null);

    const { data, loading } = useQuery<
        GetJobById, // query return return type (shape of data)
        GetJobByIdVariables // query request type       (shape of variables)
    >(GET_JOB_BY_ID, {
        variables: {
            jobId: parseInt(jobId),
        },
        fetchPolicy: 'no-cache',
    });

    const handleReturnHome = () => homeLink.current?.click();

    if (loading) {
        return <Loading />;
    }

    return (
        <IonPage>
            <IonContent>
                <IonGrid className='servReqConfirm__grid'>
                    <IonRow className='servReqConfirm'>
                        <IonCol
                            sizeXs='12'
                            sizeSm='12'
                            sizeMd='12'
                            sizeXl='12'
                            sizeLg='12'
                            className='servReqConfirm__container'
                        >
                            <ConfirmationScreenLogo />
                            <Strokes />
                            <div className='servReqConfirm__img--container'>
                                <div className='servReqConfirm__img--wrap servReqConfirm__img--wrap--left'>
                                    <IonImg
                                        alt='user'
                                        src={
                                            !loading &&
                                            data.job.provider.profilePicture
                                        }
                                        className='servReqConfirm__img'
                                    />
                                </div>
                                <div className='servReqConfirm__img--wrap servReqConfirm__img--wrap--right'>
                                    <IonImg
                                        alt='user'
                                        src={
                                            !loading &&
                                            data.job.recipient.profilePicture
                                        }
                                        className='servReqConfirm__img'
                                    />
                                </div>
                            </div>
                            <h1 className='servReqConfirm__title'>
                                Your {data.job.Skill.category} session with{' '}
                                {data.job.recipient.firstName} was not booked!
                            </h1>
                            <p className='servReqConfirm__text'>
                                We will notify the recipient, their skill
                                request has been declined.
                            </p>
                            <a ref={homeLink} href={'/home'} type='hidden'></a>
                            <IonButton
                                fill='clear'
                                onClick={handleReturnHome}
                                color='ion-primary'
                                className='servReqConfirm__btn'
                            >
                                Return Home
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default TaskDeniedConfirmation;
