// importing modules & hooks
import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useOnboardingFlow } from 'utils/onboarding';
import { useAuth } from 'context/firebase/authContext';
// importing components
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonPage,
    IonRow,
} from '@ionic/react';
import BackButton from 'components/BackButton';
import Strokes from 'components/Strokes';
// importing assets
// importing graphql utilities - types
import { UpdateBio, UpdateBioVariables } from 'GraphQL/__generated__/UpdateBio';
import { UPDATE_BIO_BY_SUB_ID } from 'GraphQL/UpdateBioBySubID';
import { yingDebug, yingInfo } from 'utils/debug/YingDebug';
// importing styles
import './UserBio.css';

const UserBio: React.FC = () => {
    const { currentUser } = useAuth();
    const [bio, setBio] = useState<string>('');
    const [updateBio, bioUpdateDatas] = useMutation<
        UpdateBio,
        UpdateBioVariables
    >(UPDATE_BIO_BY_SUB_ID);
    const { next } = useOnboardingFlow();

    const handleUpdateBio = (newBio) => {
        updateBio({
            variables: {
                newBio: newBio,
                uid: currentUser.uid,
            },
        })
            .catch((e) => yingDebug(e))
            .then((r) => yingInfo(JSON.stringify(r)));
    };

    return (
        <IonPage>
            <IonContent scrollY={false}>
                <BackButton />
                <IonGrid className='onBio__grid'>
                    <IonRow className='onBio__row'>
                        {/* content centered via container */}
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='onBio__container'
                        >
                            <Strokes top={true} green={false} />
                            <Strokes bot={true} />
                            {/* content starts here */}
                            <IonCol
                                sizeSm='12'
                                sizeXs='12'
                                className='onBio__content--container'
                            >
                                <h1 className='onBio__content--title'>
                                    Next, <span>Write a brief bio</span>
                                </h1>
                                <p className='onBio__content--text'>
                                    Provide a short bio about yourself to tell
                                    the rest of the community.
                                </p>
                                <IonRow className='onBio__textarea--container'>
                                    <IonCol
                                        className='onBio__textarea--wrap'
                                        sizeXs='12'
                                        sizeSm='12'
                                    >
                                        <textarea
                                            onChange={(event) =>
                                                setBio(event.target.value)
                                            }
                                            value={bio}
                                            className='onBio__textarea'
                                            placeholder='Tell us about yourself...'
                                            required
                                        ></textarea>
                                    </IonCol>
                                </IonRow>
                                <IonButton
                                    fill='clear'
                                    disabled={bio.length < 1}
                                    type='submit'
                                    color='ion-primary'
                                    className='onBio__content--btn'
                                    id='onboarding_bio_confirmation'
                                    onClick={() => {
                                        handleUpdateBio(bio);
                                        // next takes the onboarding stage we
                                        // expect to be on now, so that if it
                                        // gets called multiple times then side
                                        // effects only happen once
                                        next('/onboarding/bio');
                                    }}
                                >
                                    Continue
                                </IonButton>
                                <IonButton
                                    fill='clear'
                                    color='ion-primary'
                                    onClick={() => {
                                        handleUpdateBio('');
                                        // next takes the onboarding stage we
                                        // expect to be on now, so that if it
                                        // gets called multiple times then side
                                        // effects only happen once
                                        next('/onboarding/bio');
                                    }}
                                    className='onBio__skip--btn'
                                >
                                    Skip, for now
                                </IonButton>
                            </IonCol>
                        </IonCol>
                    </IonRow>
                    <Strokes bot={true} green={false} />
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default UserBio;
