// importing modules
import React, { useState } from 'react';
// importing components
import {
    IonCol,
    IonContent,
    IonPage,
    IonRow,
    IonSpinner,
    IonIcon,
    IonImg,
    IonButton,
    IonGrid,
} from '@ionic/react';
import { menuOutline } from 'ionicons/icons';
import NotificationTile from './NotificationTile/NotificationTile';
import Refresher from '../../components/Refresher';
import GreenStrokesHeader from '../../components/GreenStrokesHeader/GreenStrokesHeader';
import UserMenu from '../../components/UserMenu/UserMenu';
import UserMenuBackdrop from '../../components/UserMenu/UserMenuBackdrop';
import NavBar from '../../components/NavBar';
// importing graphql utilities - types
import { User_Data } from '../../GraphQL/Profile/GetUserByUID/getUserByUID';
// importing helpers
import {
    setLocalStorage,
    getLocalStorage,
} from '../../helpers/localStorage/localStorageHelper';
// importing assets
// importing graphql & types
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_NOTIFICATIONS_BY_USER } from '../../GraphQL/Notification/GET_NOTIFICATIONS_BY_USER/GET_NOTIFICATIONS_BY_USER';
import {
    GetNotificationsByUser,
    GetNotificationsByUserVariables,
} from '../../GraphQL/__generated__/GetNotificationsByUser';
import {
    UpdateOneNotification,
    UpdateOneNotificationVariables,
} from '../../GraphQL/__generated__/UpdateOneNotification';
// importing styles
import './NotificationTile/NotificationTile.css';
// firebase context
import { useAuth } from '../../context/firebase/authContext';
import { UPDATE_ONE_NOTIFICATION } from '../../GraphQL/Notification/UPDATE_ONE_NOTIFICATION/UPDATE_ONE_NOTIFICATION';

const Notifications: React.FC = () => {
    const { currentUser } = useAuth();
    const [markedSeen, setMarkedSeen] = useState<boolean>(false);
    const { data, loading } = useQuery<
        GetNotificationsByUser, // query return return type (shape of data)
        GetNotificationsByUserVariables // query request type (shape of variables)
    >(GET_NOTIFICATIONS_BY_USER, {
        variables: { uid: currentUser.uid },
        pollInterval: 5000,
        fetchPolicy: 'no-cache',
    });

    const [changeNotification, changeNotificationData] = useMutation<
        UpdateOneNotification,
        UpdateOneNotificationVariables
    >(UPDATE_ONE_NOTIFICATION);

    const { data: profileData, loading: profileLoading } = useQuery(User_Data, {
        variables: {
            uid: currentUser.uid,
        },
        fetchPolicy: 'no-cache',
    });

    const [toggleMenu, setToggleMenu] = useState<boolean>(false); // handles hamburger menu
    const handleMenuToggle = () => setToggleMenu((prevToggle) => !prevToggle);

    if (!getLocalStorage('currentAdminGroupId')) {
        setLocalStorage('currentAdminGroupId', 0);
    }

    if (loading) {
        return (
            <div className='schedule__spinner--wrap'>
                <IonSpinner className='schedule__spinner' name='crescent' />
            </div>
        );
    }

    /*This fucntion changes the state of notifications so that it's marked
      as seen when the page is loaded*/
    if (!markedSeen) {
        data.notificationsByUserUid.map((notif) => {
            changeNotification({
                variables: {
                    id: notif.id,
                    dismissed: notif.dismissed,
                    seen: true,
                    read: notif.read,
                },
            });
        });

        setMarkedSeen(true);
    }

    return (
        <IonPage>
            {toggleMenu ? (
                <>
                    <IonCol className='menu__sidebar--active'>
                        <UserMenu
                            handleMenuToggle={handleMenuToggle}
                            userUid={currentUser.uid}
                        />
                    </IonCol>
                    <UserMenuBackdrop handleMenuToggle={handleMenuToggle} />
                </>
            ) : null}
            <IonContent>
                <Refresher />
                <IonGrid>
                    <IonRow className='profileStates__tabs--container'>
                        <IonCol
                            sizeXs='12'
                            sizeSm='12'
                            sizeMd='12'
                            className='profileStates__tabs--wrap'
                        >
                            <IonRow className='header__buttons'>
                                <IonButton
                                    fill='clear'
                                    onClick={handleMenuToggle}
                                    color='ion-primary'
                                    className='profileStates__tabs--btn'
                                >
                                    <IonIcon
                                        className='profileStates__tabs--btn--icon'
                                        icon={menuOutline}
                                    />
                                </IonButton>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                    {getLocalStorage('currentAdminGroupId') == 0 ? (
                        <GreenStrokesHeader
                            profileImg={
                                !loading && profileData?.profile.profilePicture
                            }
                        />
                    ) : (
                        <GreenStrokesHeader
                            profileImg={
                                !loading &&
                                getLocalStorage('currentAdminGroupPicture')
                            }
                        />
                    )}
                    <IonRow className='notificationDetails'>
                        <IonRow className='notification__title--wrap'>
                            <h1 className='notifications--title'>
                                Notifications
                            </h1>
                        </IonRow>
                    </IonRow>
                    <IonRow
                        className='notificationTile__row'
                        style={{ backgroundColor: '#fff' }}
                    >
                        {data.notificationsByUserUid.length > 0 ? (
                            data.notificationsByUserUid.map((notification) => {
                                return (
                                    <NotificationTile
                                        key={notification.id}
                                        notification={notification}
                                        // Passing org array to childern component
                                        organizations={
                                            data.profile.organizations
                                                ? data.profile.organizations
                                                : []
                                        }
                                    />
                                );
                            })
                        ) : (
                            <IonCol
                                sizeXs='12'
                                sizeSm='12'
                                className='tasksFallback'
                                style={{ backgroundColor: '#fff' }}
                            >
                                <p className='tasksFallback__title'>
                                    Your notifications will appear here.
                                    <br /> <br /> Begin skillsharing today!
                                </p>
                            </IonCol>
                        )}
                    </IonRow>
                </IonGrid>
                <NavBar />
            </IonContent>
        </IonPage>
    );
};

export default Notifications;
