// importing modules
import React from 'react';
import { useHistory } from 'react-router-dom';
// importing components
import { IonAvatar, IonButton, IonCol, IonImg, IonRow } from '@ionic/react';
// importing graphql utilities - types
import { SkillPoolFromManyOrgs_profilesFromManyOrgs } from '../../../GraphQL/__generated__/SkillPoolFromManyOrgs';
// importing utilities
import filterCompletedTasks from '../../../utils/filterCompletedTasks';
import capitalize from '../../../utils/capitalize';
import genCreatedAt from '../../../utils/genCreatedAt';

const FilteredMemberList: React.FC<{
    filteredMembers: SkillPoolFromManyOrgs_profilesFromManyOrgs[];
}> = ({ filteredMembers }) => {
    const history = useHistory();
    return (
        <IonRow className='nearbyMember__row'>
            {filteredMembers.length > 0
                ? filteredMembers.map((member) => {
                      const completedTasksCount: number = filterCompletedTasks(
                          member.upcomingJobsReceive,
                          member.upcomingJobsServe
                      );

                      let skillsArr: string[] = [];
                      member.skills.forEach((skill) => {
                          // check for duplicates
                          if (!skillsArr.includes(skill.category))
                              skillsArr.push(skill.category);
                      });
                      return (
                          <IonCol
                              onClick={() =>
                                  history.push(`/user/${member.uid}`)
                              }
                              key={member.id}
                              className='nearbyMember'
                              sizeSm='11'
                              sizeXs='11'
                          >
                              <IonCol
                                  sizeXs='12'
                                  sizeSm='12'
                                  className='nearbyMember__info'
                              >
                                  <IonCol
                                      sizeXl='3'
                                      sizeLg='3'
                                      sizeMd='3'
                                      sizeXs='3'
                                      sizeSm='3'
                                  >
                                      <IonAvatar className='groupTaskItem__img--wrap'>
                                          <IonImg
                                              className='groupTaskItem__img'
                                              src={member.profilePicture}
                                          />
                                      </IonAvatar>
                                  </IonCol>
                                  <IonCol
                                      className='nearbyMember__info--wrap'
                                      sizeXs='9'
                                      sizeSm='9'
                                  >
                                      <div className='uniMember__group--list'>
                                          {member.organizations
                                              .slice(0, 5)
                                              .map((org) => {
                                                  return (
                                                      <IonImg
                                                          className='uniSearch__newMembers--group--item'
                                                          src={
                                                              org.orgPicture ===
                                                                  null ||
                                                              org.orgPicture
                                                                  .length === 0
                                                                  ? 'https://is3-ssl.mzstatic.com/image/thumb/Purple123/v4/3e/62/e2/3e62e239-f433-bc84-3775-21af60499ec7/source/512x512bb.jpg'
                                                                  : org.orgPicture
                                                          }
                                                          alt={org.name}
                                                          key={org.id}
                                                      />
                                                  );
                                              })}
                                          {member.organizations.length > 5 && (
                                              <p className='uniMember__group--list--count'>
                                                  +
                                                  {member.organizations.length -
                                                      5}
                                              </p>
                                          )}
                                      </div>
                                      <h3 className='nearbyMember__info--name'>
                                          {capitalize(member.firstName)}{' '}
                                          {capitalize(member.lastName)}
                                      </h3>
                                      <p className='nearbyMember__info--reviews'>
                                          {genCreatedAt(member.createdAt)} |{' '}
                                          {completedTasksCount} tasks completed
                                      </p>
                                  </IonCol>
                              </IonCol>
                              <IonCol
                                  sizeXs='12'
                                  sizeSm='12'
                                  className='nearbyMember__skills--wrap'
                              >
                                  {skillsArr.length > 0
                                      ? skillsArr.map((skill, index) => (
                                            <IonButton
                                                key={index}
                                                color='ion-primary'
                                                className='nearbyMember__skill'
                                            >
                                                {skill}
                                            </IonButton>
                                        ))
                                      : null}
                              </IonCol>
                          </IonCol>
                      );
                  })
                : null}
        </IonRow>
    );
};

export default FilteredMemberList;
