// importing modules
import React, { useEffect, useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
// importing components
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonIcon,
    IonImg,
    IonPage,
    IonRow,
} from '@ionic/react';
import UserImg from '../UI/UserImg/UserImg';
import CompleteTaskNavTabs from '../UI/CompleteTaskNavTabs/CompleteTaskNavTabs';
import CompleteTaskFooter from 'components/FooterWithChildren/FooterWithChildren';
import Strokes from 'components/Strokes';
// importing assets
import { star } from 'ionicons/icons';
import Loading from 'components/status/Loading';
// importing helpers
import {
    deleteLocalStorage,
    getLocalStorage,
} from 'helpers/localStorage/localStorageHelper';
import capitalize from 'utils/capitalize';
import { hrs_mins_to_credits } from 'utils/timeConversion';
// importing graphql
import { useMutation, useQuery } from '@apollo/react-hooks';
import { GET_TIME_CREDITS_BY_UID } from 'GraphQL/Ledger/GET_TIME_CREDITS_BY_UID';
import { GET_PARTICIPATION_REQUEST_BY_ID } from 'GraphQL/ParticipationRequest/GET_PARTICIPATION_REQUEST_BY_ID/GET_PARTICIPATION_REQUEST_BY_ID';
import {
    GetParticipationRequestById,
    GetParticipationRequestByIdVariables,
} from 'GraphQL/__generated__/GetParticipationRequestById';
import {
    CompleteParticipationRequest,
    CompleteParticipationRequestVariables,
} from 'GraphQL/__generated__/CompleteParticipationRequest';
import { COMPLETE_PARTICIPATION_REQUEST } from 'GraphQL/ParticipationRequest/COMPLETE_PARTICIPATION_REQUEST/COMPLETE_PARTICIPATION_REQUEST';
import { Status } from 'GraphQL/__generated__/globalTypes';
// import get participation request by ID
import {
    ChangeOneBankAccount,
    ChangeOneBankAccountVariables,
} from 'GraphQL/__generated__/ChangeOneBankAccount';
import { CHANGE_ONE_BANKACCOUNT } from 'GraphQL/bankAccounts/CHANGE_ONE_BANKACCOUNT';
// importing styles
import './ReviewTask.css';
import { useAuth } from 'context/firebase/authContext';

// const ReviewTaskConfirm: React.FC = () => {
export default () => {
    const { currentUser } = useAuth();
    const history = useHistory();
    const { taskId } = useParams<{ taskId: string }>();
    const [rt, setRating] = useState<number>(0);
    const [reviewDescr, setReviewDescr] = useState<string>('');
    const [senderBank, setSenderBank] = useState<number>(0);
    const [receiverBank, setReceiverBank] = useState<number>(0);
    const [senderBankBalance, setSenderBankBalance] = useState<number>(0);
    const [receiverBankBalance, setReceiverBankBalance] = useState<number>(0);

    useEffect(() => {
        if (getLocalStorage('completeTaskPostedViaProvider')) {
            const completeTaskObj = getLocalStorage(
                'completeTaskPostedViaProvider'
            );
            setRating(completeTaskObj.rating);
        } else {
            history.push('/home');
        }
    });

    const { data, loading } = useQuery<
        GetParticipationRequestById,
        GetParticipationRequestByIdVariables
    >(GET_PARTICIPATION_REQUEST_BY_ID, {
        variables: { id: parseInt(taskId) },
        fetchPolicy: 'no-cache',
    });

    useEffect(() => {
        if (!loading) {
            setSenderBank(data?.participationRequest.sender.bankAccountId);
            setReceiverBank(data?.participationRequest.receiver.bankAccountId);
            setSenderBankBalance(
                data?.participationRequest.sender.bankAccountrelation[0].balance
            );
            setReceiverBankBalance(
                data?.participationRequest.receiver.bankAccountrelation[0]
                    .balance
            );
        }
    });

    const [changeBank, changeBankDatas] = useMutation<
        ChangeOneBankAccount,
        ChangeOneBankAccountVariables
    >(CHANGE_ONE_BANKACCOUNT);
    const [completeParticipationRequest, completeParticipationRequestDatas] =
        useMutation<
            CompleteParticipationRequest,
            CompleteParticipationRequestVariables
        >(COMPLETE_PARTICIPATION_REQUEST);

    const handleCompleteTask = () => {
        const completeTaskObj = getLocalStorage(
            'completeTaskPostedViaProvider'
        );

        completeParticipationRequest({
            variables: {
                id: parseInt(taskId),
                review: reviewDescr,
                rating: parseInt(completeTaskObj.rating),
                credits: hrs_mins_to_credits(
                    parseInt(completeTaskObj.hrs),
                    parseInt(completeTaskObj.mins)
                ),
                status: 'COMPLETED',
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GET_TIME_CREDITS_BY_UID,
                    variables: {
                        uid: currentUser.uid,
                    },
                },
            ],
        })
            .then((res) => {
                changeBank({
                    variables: {
                        id: senderBank,
                        balance:
                            senderBankBalance -
                            hrs_mins_to_credits(
                                parseInt(completeTaskObj.hrs),
                                parseInt(completeTaskObj.mins)
                            ),
                    },
                }).then((res) => {
                    changeBank({
                        variables: {
                            id: receiverBank,
                            balance:
                                receiverBankBalance +
                                hrs_mins_to_credits(
                                    parseInt(completeTaskObj.hrs),
                                    parseInt(completeTaskObj.mins)
                                ),
                        },
                    });
                });
                // use account number to grab id and balance before changeBank
                history.push(
                    `/completeTaskPosted/provider/${taskId}/confirmation`
                );
            })
            .catch((err) => console.error(err));
    };

    if (loading) {
        return <Loading />;
    }

    // current user uid has to match the jobpost poster's uid
    if (currentUser.uid != data.participationRequest.provider.uid) {
        return <Redirect to='/' />;
    }

    return (
        <IonPage>
            <IonContent>
                <IonGrid>
                    {/* reviewTask header */}
                    <IonRow className='reviewTask__header'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='reviewTask__header--container'
                        >
                            <Strokes top={true} />
                            <CompleteTaskNavTabs
                                disablePrevStep={false}
                                prevPage={`/completeTaskPosted/provider/${taskId}/rating`}
                            />
                            <UserImg />
                        </IonCol>
                    </IonRow>
                    {/* text content */}
                    <IonRow className='reviewTask__textContent'>
                        <IonCol
                            className='reviewTask__textContent--container'
                            sizeSm='12'
                            sizeXs='12'
                        >
                            <h1 className='reviewTask__textContent--title'>
                                Nice! Please write a <span>review</span> of your
                                experience helping{' '}
                                <span>
                                    {capitalize(
                                        data.participationRequest.recipient
                                            .firstName
                                    )}
                                </span>
                            </h1>
                        </IonCol>
                    </IonRow>
                    {/* star rating */}
                    <IonGrid className='taskReview__container'>
                        <IonRow className='stars'>
                            <IonCol
                                sizeXs='10'
                                sizeSm='10'
                                className='stars__container'
                            >
                                {[...Array(5)].map((s, i) => {
                                    const ratingVal: number = i + 1;
                                    return (
                                        <label key={i}>
                                            <input
                                                type='radio'
                                                name='rating'
                                                value={ratingVal}
                                            />
                                            <IonIcon
                                                style={{
                                                    color:
                                                        ratingVal <= rt
                                                            ? '#f7b500'
                                                            : '#d3d3d3',
                                                }}
                                                icon={star}
                                                className='star'
                                            />
                                        </label>
                                    );
                                })}
                            </IonCol>
                            <IonCol
                                className='reviewTask__textarea--wrap'
                                sizeXs='12'
                                sizeSm='12'
                            >
                                <textarea
                                    onChange={(event) =>
                                        setReviewDescr(event.target.value)
                                    }
                                    value={reviewDescr}
                                    className='reviewTask__textarea'
                                    placeholder='Say something nice...'
                                    required
                                ></textarea>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonGrid>
            </IonContent>
            <CompleteTaskFooter>
                <IonButton
                    fill='clear'
                    onClick={handleCompleteTask}
                    disabled={reviewDescr.length === 0}
                    type='submit'
                    color='ion-primary'
                    className='footerWithChild__btn'
                >
                    Complete Task
                </IonButton>
            </CompleteTaskFooter>
        </IonPage>
    );
};
