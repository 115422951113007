import firebase from 'firebase/app';
import 'firebase/storage';
import { fullyQualifiedProfilePicPath } from '../../pages/util/ProfilePictureHandler';
import { USER_HOME_DATA } from '../../GraphQL/Profile/Home/home';

export const handleImageUpload = async (
    user: any,
    imgBlob: any,
    updatePhoto: any
) => {
    // store the file
    const storageRef = firebase
        .storage()
        .ref()
        .child(fullyQualifiedProfilePicPath());

    return storageRef
        .put(imgBlob)
        .then(async function (snapshot) {
            const downloadURL = await snapshot.ref.getDownloadURL();
            user.updateProfile({
                photoURL: downloadURL,
            }).then(function () {
                updatePhoto({
                    variables: {
                        uid: user.uid,
                        photoURL: downloadURL,
                    },
                    refetchQueries: [
                        {
                            query: USER_HOME_DATA,
                            variables: { uid: user.uid },
                        },
                    ],
                });
            });
        })
        .catch((error) =>
            console.error('Error: Unable to update profile picture', error)
        );
};
