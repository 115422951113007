// importing modules
import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';
// importing components
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonIcon,
    IonPage,
    IonRow,
    IonImg,
    IonSpinner,
    IonList,
} from '@ionic/react';
import UserMenu from '../../components/UserMenu/UserMenu';
import UserMenuBackdrop from '../../components/UserMenu/UserMenuBackdrop';
import ProfileStateTabs from '../../components/ProfileStateTabs/ProfileStateTabs';
import ChatroomItemPreview from './ChatroomItemPreview/ChatroomItemPreview';
import GreenStrokesHeader from '../../components/GreenStrokesHeader/GreenStrokesHeader';
import PrivateMsgItemPreview from './PrivateMessageItemPreview/PrivateMessageItemPreview';
import Loading from '../../components/status/Loading';
import NavBar from '../../components/NavBar';
// importing helpers
import {
    setLocalStorage,
    getLocalStorage,
} from '../../helpers/localStorage/localStorageHelper';
// importing graphql utilities - types
import { useQuery } from '@apollo/react-hooks';
import {
    UserProfile_byID,
    UserProfile_byIDVariables,
} from '../../GraphQL/__generated__/UserProfile_byID';
import { USER_PROFILE_BY_UID } from '../../GraphQL/Profile/UserProfile/USER_PROFILE_BY_UID';
import { User_Data } from '../../GraphQL/Profile/GetUserByUID/getUserByUID';
// importing utilities
import { FirebaseHelper } from '../../helpers/FirebaseHelper';
// importing styles
import './ChatIndex.css';
// firebase context
import firebase from 'firebase/app';
import 'firebase/firestore';
import { useAuth } from '../../context/firebase/authContext';

interface Messages {
    id: string;
    sentAt: any;
    message: string;
    sender: string;
    senderName: string;
    seen: boolean;
}

const Messaging: React.FC = () => {
    const { currentUser } = useAuth();
    const history = useHistory();
    const fuser = new FirebaseHelper(currentUser);
    const firestore = firebase.firestore();
    const [chatrooms, setChatrooms] = useState<any>([]);
    const [isLoadingChatrooms, setIsLoadingChatrooms] = useState<boolean>(true);
    const [toggleMenu, setToggleMenu] = useState<Boolean>(false);
    const handleMenuToggle = () => setToggleMenu((prevToggle) => !prevToggle);

    const { data, loading } = useQuery<
        UserProfile_byID,
        UserProfile_byIDVariables
    >(USER_PROFILE_BY_UID, {
        variables: {
            subId: fuser.uid,
        },
    });

    const { data: profileData, loading: profileLoading } = useQuery(User_Data, {
        variables: {
            uid: currentUser.uid,
        },
        fetchPolicy: 'no-cache',
    });

    useEffect(() => {
        firestore
            .collection('chatrooms')
            .where('usersArr', 'array-contains', fuser.uid)
            .get()
            .then(
                (snapshot) => {
                    const rooms = [];
                    snapshot.forEach((doc) => {
                        const { id } = doc;
                        firestore
                            .collection(`/chatrooms/${id}/messages`)
                            .orderBy('sentAt')
                            .get()
                            .then((docSnapshot) => {
                                if (docSnapshot.docs.length > 0) {
                                    const docObj = {
                                        ...doc.data(),
                                        id,
                                        ...docSnapshot.docs[
                                            docSnapshot.docs.length - 1
                                        ].data(),
                                    };
                                    rooms.push(docObj);
                                    setChatrooms((prevChatrooms) => [
                                        ...prevChatrooms,
                                        docObj,
                                    ]);
                                } else {
                                    const docObj = {
                                        ...doc.data(),
                                        id,
                                        message: 'Start your conversation...',
                                    };
                                    rooms.push(docObj);
                                    setChatrooms(rooms);
                                    setChatrooms((prevChatrooms) => [
                                        ...prevChatrooms,
                                        docObj,
                                    ]);
                                }
                                setIsLoadingChatrooms(false);
                            });
                    });
                    if (snapshot.empty) {
                        setIsLoadingChatrooms(false);
                    }
                },
                (err) => console.error(err)
            );
    }, []);

    if (!getLocalStorage('currentAdminGroupId')) {
        setLocalStorage('currentAdminGroupId', 0);
    }

    if (loading) {
        return <Loading />;
    }

    const handleDeleteChatRoom = async (chatroomId) => {
        firestore
            .collection('chatrooms')
            .doc(chatroomId)
            .delete()
            .then(() => {
                const currentChatrooms = chatrooms.filter(
                    (room) => room.id !== chatroomId
                );
                setChatrooms(currentChatrooms);
            })
            .catch(function (error) {
                console.error('Error removing document: ', error);
            });
    };
    return (
        <IonPage>
            <IonContent>
                <IonGrid>
                    {toggleMenu ? (
                        <IonCol className='menu__sidebar--active'>
                            <UserMenu
                                handleMenuToggle={handleMenuToggle}
                                userUid={fuser.uid}
                            />
                        </IonCol>
                    ) : (
                        <IonCol className='menu__sidebar--inactive' />
                    )}
                    {toggleMenu ? (
                        <UserMenuBackdrop handleMenuToggle={handleMenuToggle} />
                    ) : null}
                    <ProfileStateTabs
                        showSearch={false}
                        handleMenuToggle={handleMenuToggle}
                    />
                    {getLocalStorage('currentAdminGroupId') == 0 ? (
                        <GreenStrokesHeader
                            profileImg={
                                !loading && profileData?.profile.profilePicture
                            }
                        />
                    ) : (
                        <GreenStrokesHeader
                            profileImg={
                                !loading &&
                                getLocalStorage('currentAdminGroupPicture')
                            }
                        />
                    )}
                    <IonRow className='msgDetails'>
                        <IonCol
                            sizeXs='12'
                            sizeSm='12'
                            className='msgDetails__col--btn'
                        >
                            {/* New Message button - Functionality to create new message from main messaging screen to be implemented. All the styling for the New Message button is here, it just needs to be un-commented. */}
                            <IonButton
                                fill='clear'
                                // onClick={() => history.push(`/`)}
                                // expand='block'
                                // className='msgDetails__home--btn'
                                // color='ion-primary'
                            >
                                {/* New Message{' '}
                                <IonIcon icon={mailOutline} slot='end' /> */}
                            </IonButton>
                        </IonCol>
                    </IonRow>
                    {/* chatrooms */}
                    <IonRow className='msgChatrooms'>
                        <IonRow className='msgBroadcast__btn--container'>
                            {/* <IonCol
                                className='msgBroadcast__btn--wrap'
                                sizeXs='12'
                                sizeSm='12'
                            >
                                <div className='msgBroadcast__btn'>
                                    Broadcast message to all members
                                    <IonIcon
                                        icon={arrowForwardOutline}
                                        slot='end'
                                    />
                                </div>
                            </IonCol> */}
                            <IonCol
                                className='msgChatrooms__col--title'
                                sizeXs='12'
                                sizeSm='12'
                            >
                                <h1 className='msgChatrooms__title'>
                                    Chatrooms
                                </h1>
                            </IonCol>
                        </IonRow>
                        <IonGrid className='msgChatrooms__container'>
                            <IonList className='msgChatrooms__row'>
                                {isLoadingChatrooms ? (
                                    <div className='schedule__spinner--wrap'>
                                        <IonSpinner
                                            className='schedule__spinner'
                                            name='crescent'
                                        />
                                    </div>
                                ) : (
                                    chatrooms.length > 0 &&
                                    chatrooms.map((room, index) => {
                                        const currentUser = room.usersArr.find(
                                            (user) => user === fuser.uid
                                        );
                                        const otherUser = room.usersArr.find(
                                            (user) => user !== fuser.uid
                                        );
                                        return (
                                            <ChatroomItemPreview
                                                key={room.id}
                                                latestMessage={room.message}
                                                seenStatus={
                                                    (room as Messages).seen
                                                }
                                                messageUid={
                                                    (room as Messages).sender
                                                }
                                                roomId={room.id}
                                                currentUserUid={
                                                    room.users[`${currentUser}`]
                                                        .id
                                                }
                                                otherUserUid={
                                                    room.users[`${otherUser}`]
                                                        .id
                                                }
                                                otherUserFirstName={
                                                    room.users[`${otherUser}`]
                                                        .firstName
                                                }
                                                otherUserLastName={
                                                    room.users[`${otherUser}`]
                                                        .lastName
                                                }
                                                otherUserImg={
                                                    room.users[`${otherUser}`]
                                                        .imageUrl
                                                }
                                                handleDeleteChatRoom={
                                                    handleDeleteChatRoom
                                                }
                                            />
                                        );
                                    })
                                )}
                                {!isLoadingChatrooms && chatrooms.length === 0 && (
                                    <IonCol
                                        className='msgFallbackText--wrap'
                                        style={{ backgroundColor: '#fff' }}
                                    >
                                        <IonRow className='msgFallbackText'>
                                            <p>
                                                Begin your first chatroom by
                                                going to a<br />
                                                user's profile and tapping on
                                                Message Me.
                                            </p>
                                        </IonRow>
                                    </IonCol>
                                )}
                            </IonList>
                        </IonGrid>
                    </IonRow>
                    {/* private messages */}
                    {/* <IonRow className="msgPrivaterooms">
                        <IonRow className="msgPrivaterooms__row">
                            <IonCol className="msgPrivaterooms__col--title" sizeXs="12" sizeSm="12">
                                <h2 className="msgPrivaterooms__title">Private Messages</h2>
                            </IonCol>
                        </IonRow>
                        <IonGrid className="msgPrivaterooms__container">
                            <IonRow className="msgPrivaterooms__row">
                                {
                                    MOCK_PRIVATE_MESSAGES.length > 0 ? 
                                    MOCK_PRIVATE_MESSAGES.map((msg) => (
                                        <PrivateMsgItemPreview key={msg.id} {...msg}/>
                                    )) : <IonCol className="tasksFallback" style={{ backgroundColor: "#f6f6f6" }}>
                                            <h3 className="tasksFallback__title">No Private Messages available</h3>
                                        </IonCol>
                                }
                            </IonRow>
                        </IonGrid>
                    </IonRow> */}
                </IonGrid>
                <NavBar />
            </IonContent>
        </IonPage>
    );
};

export default Messaging;
