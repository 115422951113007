// importing modules
import React from 'react';
import { useHistory } from 'react-router-dom';
// importing components
import { IonCol } from '@ionic/react';
// importing utilities
import filterCompletedTasks from '../../../utils/filterCompletedTasks';
import genCreatedAt from '../../../utils/genCreatedAt';
import capitalize from '../../../utils/capitalize';
import limitStr from '../../../utils/limitString';
// importing types
import {
    SkillPoolFromOneOrg_profilesFromOneOrg_upcomingJobsReceive,
    SkillPoolFromOneOrg_profilesFromOneOrg_upcomingJobsServe,
} from '../../../GraphQL/__generated__/SkillPoolFromOneOrg';

const NewMemberTile: React.FC<{
    uid: string;
    id: number;
    profilePicture: string;
    firstName: string;
    lastName: string;
    createdAt: any;
    upcomingJobsReceive: SkillPoolFromOneOrg_profilesFromOneOrg_upcomingJobsReceive[];
    upcomingJobsServe: SkillPoolFromOneOrg_profilesFromOneOrg_upcomingJobsServe[];
}> = ({
    id,
    profilePicture,
    firstName,
    lastName,
    uid,
    createdAt,
    upcomingJobsReceive,
    upcomingJobsServe,
}) => {
    const completedTasksCount: number = filterCompletedTasks(
        upcomingJobsReceive,
        upcomingJobsServe
    );
    const timeOnYing: string = genCreatedAt(createdAt);
    const history = useHistory();
    return (
        <IonCol
            onClick={() => history.push(`/user/${uid}`)}
            style={{
                backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.10), rgba(66, 66, 66, 0.95)), url(${profilePicture})`,
            }}
            className='genSearch__newMembers--item'
            sizeSm='8'
            sizeXs='8'
        >
            <h3 className='genSearch__newMembers--name'>
                {limitStr(
                    `${capitalize(firstName)} ${capitalize(lastName)}`,
                    16
                )}
            </h3>
            <p className='genSearch__newMembers--text'>
                {timeOnYing} | {completedTasksCount} tasks completed
            </p>
            {/* <p className="genSearch__newMembers--reviews">{0} positive reviews</p> */}
        </IonCol>
    );
};

export default NewMemberTile;
