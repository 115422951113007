// importing modules
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

// importing components
import {
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonModal,
    IonRow,
} from '@ionic/react';
import BackButton from 'components/BackButton';
import Strokes from 'components/Strokes';
// importing assets
// importing styles
import './LegalScreens.css';

interface Props {
    showModal: boolean;
    setModal: Function;
}

const PrivacyPolicyModal: React.FC<Props> = ({ showModal, setModal }) => {
    const history = useHistory();

    return (
        <IonModal backdropDismiss={false} isOpen={showModal}>
            <IonContent>
                <Strokes top={true} green={false} />

                <IonGrid>
                    <BackButton onClick={() => setModal(false)} />
                    <div
                        style={{
                            height: '6rem',
                        }}
                    />
                    <IonRow className='privacyPolicy__title--container'>
                        <IonCol
                            className='about__title--wrap'
                            sizeXs='12'
                            sizeSm='12'
                        >
                            <h1 className='about__title'>
                                Privacy <span>Policy</span>
                            </h1>
                        </IonCol>
                    </IonRow>
                    <IonGrid className='about__manifesto--container'>
                        <IonRow className='about__manifesto--wrap'>
                            <IonCol
                                sizeXs='12'
                                sizeSm='12'
                                className='about__manifesto--content'
                            >
                                <span>Last updated June 07, 2021</span>
                                <br></br>
                                <br></br>
                                <p>
                                    Thank you for choosing to be part of our
                                    community at YING, Inc. ("Company", "we",
                                    "us", "our"). We are committed to protecting
                                    your personal information and your right to
                                    privacy. If you have any questions or
                                    concerns about this privacy notice, or our
                                    practices with regards to your personal
                                    information, please contact us at
                                    info@yingme.co.{' '}
                                </p>
                                <p>
                                    When you visit our website yingme.app (the
                                    "Website"), use our mobile application, as
                                    the case may be (the "App") and more
                                    generally, use any of our services (the
                                    "Services", which include the Website and
                                    App), we appreciate that you are trusting us
                                    with your personal information. We take your
                                    privacy very seriously. In this privacy
                                    notice, we seek to explain to you in the
                                    clearest way possible what information we
                                    collect, how we use it and what rights you
                                    have in relation to it. We hope you take
                                    some time to read through it carefully, as
                                    it is important. If there are any terms in
                                    this privacy notice that you do not agree
                                    with, please discontinue use of our Services
                                    immediately.
                                </p>
                                <p>
                                    This privacy notice applies to all
                                    information collected through our Services
                                    (which, as described above, includes our
                                    Website and App), as well as, any related
                                    services, sales, marketing or events.
                                </p>
                                <p>
                                    Please read this privacy notice carefully as
                                    it will help you understand what we do with
                                    the information that we collect.
                                </p>
                                <p>TABLE OF CONTENTS</p>
                                <ol>
                                    <li>
                                        <span>
                                            <u>
                                                <a className='regular'>
                                                    WHAT INFORMATION DO WE
                                                    COLLECT?
                                                </a>
                                            </u>
                                        </span>
                                    </li>
                                    <li className='tableMargin'>
                                        <span>
                                            <u>
                                                <a className='regular'>
                                                    HOW DO WE USE YOUR
                                                    INFORMATION?
                                                </a>
                                            </u>
                                        </span>
                                    </li>
                                    <li className='tableMargin'>
                                        <u>
                                            <span>
                                                <a className='regular'>
                                                    WILL YOUR INFORMATION BE
                                                    SHARED WITH ANYONE?
                                                </a>
                                            </span>
                                        </u>
                                    </li>
                                    <li className='tableMargin'>
                                        <u>
                                            <span>
                                                <a className='regular'>
                                                    WHO WILL YOUR INFORMATION
                                                    SHARED WITH?
                                                </a>
                                            </span>
                                        </u>
                                    </li>
                                    <li className='tableMargin'>
                                        <u>
                                            <span>
                                                <a className='regular'>
                                                    DO WE USE COOKIES AND OTHER
                                                    TRACKING TECHNOLOGIES?
                                                </a>
                                            </span>
                                        </u>
                                    </li>
                                    <li className='tableMargin'>
                                        <u>
                                            <span>
                                                <a className='regular'>
                                                    IS YOUR INFORMATION
                                                    TRANSFERRED INTERNATIONALLY?
                                                </a>
                                            </span>
                                        </u>
                                    </li>
                                    <li className='tableMargin'>
                                        <u>
                                            <span>
                                                <a className='regular'>
                                                    HOW LONG DO WE KEEP YOUR
                                                    INFORMATION?
                                                </a>
                                            </span>
                                        </u>
                                    </li>
                                    <li className='tableMargin'>
                                        <u>
                                            <span>
                                                <a className='regular'>
                                                    DO WE COLLECT INFORMATION
                                                    FROM MINORS?
                                                </a>
                                            </span>
                                        </u>
                                    </li>
                                    <li className='tableMargin'>
                                        <u>
                                            <span>
                                                <a className='regular'>
                                                    WHAT ARE YOUR PRIVACY
                                                    RIGHTS?
                                                </a>
                                            </span>
                                        </u>
                                    </li>
                                    <li className='tableMargin'>
                                        <u>
                                            <span>
                                                <a className='regular'>
                                                    CONTROLS FOR DO-NOT-TRACK
                                                    FEATURES
                                                </a>
                                            </span>
                                        </u>
                                    </li>
                                    <li className='tableMargin'>
                                        <u>
                                            <span>
                                                <a className='regular'>
                                                    DO CALIFORNIA RESIDENTS HAVE
                                                    SPECIFIC PRIVACY RIGHTS?
                                                </a>
                                            </span>
                                        </u>
                                    </li>
                                    <li className='tableMargin'>
                                        <u>
                                            <span>
                                                <a className='regular'>
                                                    DO WE MAKE UPDATES TO THIS
                                                    NOTICE?
                                                </a>
                                            </span>
                                        </u>
                                    </li>
                                    <li className='tableMargin'>
                                        <u>
                                            <span>
                                                <a className='regular'>
                                                    HOW CAN YOU CONTACT US ABOUT
                                                    THIS NOTICE?
                                                </a>
                                            </span>
                                        </u>
                                    </li>
                                    <li className='tableMargin'>
                                        <u>
                                            <span>
                                                <a className='regular'>
                                                    HOW CAN YOU REVIEW, UPDATE
                                                    OR DELETE THE DATA WE
                                                    COLLECT FROM YOU?
                                                </a>
                                            </span>
                                        </u>
                                    </li>
                                </ol>
                                <ol>
                                    <br></br>
                                    <li>
                                        <b>WHAT INFORMATION DO WE COLLECT?</b>
                                        <br></br>
                                        <br></br>Personal information you
                                        disclose to us<br></br>
                                        <br></br>
                                        In Short:&nbsp;&nbsp; We collect
                                        personal information that you provide to
                                        us.<br></br>
                                        <br></br>
                                        We collect personal information that you
                                        voluntarily provide to us when you
                                        register on the Services, express an
                                        interest in obtaining information about
                                        us or our products and Services, when
                                        you participate in activities on the
                                        Services (such as by posting messages in
                                        our online forums or entering
                                        competitions, contests or giveaways) or
                                        otherwise when you contact us.
                                        <br></br>
                                        <br></br>
                                        The personal information that we collect
                                        depends on the context of your
                                        interactions with us and the Services,
                                        the choices you make and the products
                                        and features you use. The personal
                                        information we collect may include the
                                        following:
                                        <br></br>
                                        <br></br>
                                        Personal Information Provided by You. We
                                        collect names; phone numbers; email
                                        addresses; passwords; contact or
                                        authentication data; and other similar
                                        information.
                                        <br></br>
                                        <br></br>
                                        Payment Data. We may collect data
                                        necessary to process your payment if you
                                        make purchases, such as your payment
                                        instrument number (such as a credit card
                                        number), and the security code
                                        associated with your payment instrument.
                                        All payment data is stored by Stripe.
                                        You may find their privacy notice
                                        link(s) here:
                                        https://stripe.com/privacy.
                                        <br></br>
                                        <br></br>
                                        All personal information that you
                                        provide to us must be true, complete and
                                        accurate, and you must notify us of any
                                        changes to such personal information.
                                        <br></br>
                                        <br></br>Information automatically
                                        collected<br></br>
                                        <br></br>
                                        In Short:&nbsp;&nbsp; Some information —
                                        such as your Internet Protocol (IP)
                                        address and/or browser and device
                                        characteristics — is collected
                                        automatically when you visit our
                                        Services.
                                        <br></br>
                                        <br></br>
                                        We automatically collect certain
                                        information when you visit, use or
                                        navigate the Services. This information
                                        does not reveal your specific identity
                                        (like your name or contact information)
                                        but may include device and usage
                                        information, such as your IP address,
                                        browser and device characteristics,
                                        operating system, language preferences,
                                        referring URLs, device name, country,
                                        location, information about how and when
                                        you use our Services and other technical
                                        information. This information is
                                        primarily needed to maintain the
                                        security and operation of our Services,
                                        and for our internal analytics and
                                        reporting purposes.
                                        <br></br>
                                        <br></br>
                                        Like many businesses, we also collect
                                        information through cookies and similar
                                        technologies.
                                        <br></br>
                                        <br></br>The information we collect
                                        includes:<br></br>
                                        <ol>
                                            <li className='tableMargin'>
                                                Log and Usage Data. Log and
                                                usage data is service-related,
                                                diagnostic, usage and
                                                performance information our
                                                servers automatically collect
                                                when you access or use our
                                                Services and which we record in
                                                log files. Depending on how you
                                                interact with us, this log data
                                                may include your IP address,
                                                device information, browser type
                                                and settings and information
                                                about your activity in the
                                                Services (such as the date/time
                                                stamps associated with your
                                                usage, pages and files viewed,
                                                searches and other actions you
                                                take such as which features you
                                                use), device event information
                                                (such as system activity, error
                                                reports (sometimes called 'crash
                                                dumps') and hardware settings).
                                            </li>
                                            <li className='tableMargin'>
                                                Device Data. We collect device
                                                data such as information about
                                                your computer, phone, tablet or
                                                other device you use to access
                                                the Services. Depending on the
                                                device used, this device data
                                                may include information such as
                                                your IP address (or proxy
                                                server), device and application
                                                identification numbers,
                                                location, browser type, hardware
                                                model, Internet service provider
                                                and/or mobile carrier, operating
                                                system and system configuration
                                                information.
                                            </li>
                                            <li className='tableMargin'>
                                                Location Data. We collect
                                                location data such as
                                                information about your device's
                                                location, which can be either
                                                precise or imprecise. How much
                                                information we collect depends
                                                on the type and settings of the
                                                device you use to access the
                                                Services. For example, we may
                                                use GPS and other technologies
                                                to collect geolocation data that
                                                tells us your current location
                                                (based on your IP address). You
                                                can opt out of allowing us to
                                                collect this information either
                                                by refusing access to the
                                                information or by disabling your
                                                Location setting on your device.
                                                Note however, if you choose to
                                                opt out, you may not be able to
                                                use certain aspects of the
                                                Services.
                                            </li>
                                        </ol>
                                        <br></br>
                                        <b>
                                            Information collected through our
                                            App
                                        </b>
                                        <br></br>
                                        <br></br>
                                        In Short:&nbsp;&nbsp; We collect
                                        information regarding your geolocation,
                                        mobile device, push notifications, when
                                        you use our App.
                                        <br></br>
                                        <br></br>
                                        If you use our App, we also collect the
                                        following information:<br></br>
                                        <ul>
                                            <li className='tableMargin'>
                                                Geolocation Information. We may
                                                request access or permission to
                                                and track location-based
                                                information from your mobile
                                                device, either continuously or
                                                while you are using our App, to
                                                provide certain location-based
                                                services. If you wish to change
                                                our access or permissions, you
                                                may do so in your device's
                                                settings.
                                            </li>
                                            <li className='tableMargin'>
                                                Mobile Device Access. We may
                                                request access or permission to
                                                certain features from your
                                                mobile device, including your
                                                mobile device's calendar,
                                                photos, camera, files app,
                                                storage, microphone, and other
                                                features. If you wish to change
                                                our access or permissions, you
                                                may do so in your device's
                                                settings.
                                            </li>
                                            <li className='tableMargin'>
                                                Mobile Device Data. We
                                                automatically collect device
                                                information (such as your mobile
                                                device ID, model and
                                                manufacturer), operating system,
                                                version information and system
                                                configuration information,
                                                device and application
                                                identification numbers, browser
                                                type and version, hardware model
                                                Internet service provider and/or
                                                mobile carrier, and Internet
                                                Protocol (IP) address (or proxy
                                                server). If you are using our
                                                App, we may also collect
                                                information about the phone
                                                network associated with your
                                                mobile device, your mobile
                                                device’s operating system or
                                                platform, the type of mobile
                                                device you use, your mobile
                                                device’s unique device ID and
                                                information about the features
                                                of our App you accessed.
                                            </li>
                                            <li className='tableMargin'>
                                                Push Notifications. We may
                                                request to send you push
                                                notifications regarding your
                                                account or certain features of
                                                the App. If you wish to opt-out
                                                from receiving these types of
                                                communications, you may turn
                                                them off in your device's
                                                settings.
                                            </li>
                                        </ul>
                                        <br></br>
                                        This information is primarily needed to
                                        maintain the security and operation of
                                        our App, for troubleshooting and for our
                                        internal analytics and reporting
                                        purposes.
                                    </li>
                                    <br></br>
                                    <li>
                                        <b>HOW DO WE USE YOUR INFORMATION?</b>
                                        <br></br>
                                        <br></br>
                                        In Short:&nbsp;&nbsp; We process your
                                        information for purposes based on
                                        legitimate business interests, the
                                        fulfillment of our contract with you,
                                        compliance with our legal obligations,
                                        and/or your consent.<br></br>
                                        <br></br>
                                        We use personal information collected
                                        via our Services for a variety of
                                        business purposes described below. We
                                        process your personal information for
                                        these purposes in reliance on our
                                        legitimate business interests, in order
                                        to enter into or perform a contract with
                                        you, with your consent, and/or for
                                        compliance with our legal obligations.
                                        We indicate the specific processing
                                        grounds we rely on next to each purpose
                                        listed below.<br></br>
                                        <br></br>
                                        We use the information we collect or
                                        receive: <br></br>
                                        <ul>
                                            <li className='tableMargin'>
                                                To facilitate account creation
                                                and logon process. If you choose
                                                to link your account with us to
                                                a third-party account (such as
                                                your Google or Facebook
                                                account), we use the information
                                                you allowed us to collect from
                                                those third parties to
                                                facilitate account creation and
                                                logon process for the
                                                performance of the contract.
                                            </li>
                                            <li className='tableMargin'>
                                                To post testimonials. We post
                                                testimonials on our Services
                                                that may contain personal
                                                information. Prior to posting a
                                                testimonial, we will obtain your
                                                consent to use your name and the
                                                content of the testimonial. If
                                                you wish to update, or delete
                                                your testimonial, please contact
                                                us at info@yingme.co and be sure
                                                to include your name,
                                                testimonial location, and
                                                contact information.
                                            </li>
                                            <li className='tableMargin'>
                                                Request feedback. We may use
                                                your information to request
                                                feedback and to contact you
                                                about your use of our Services.
                                            </li>
                                            <li className='tableMargin'>
                                                To enable user-to-user
                                                communications. We may use your
                                                information in order to enable
                                                user-to-user communications with
                                                each user's consent.
                                            </li>
                                            <li className='tableMargin'>
                                                To manage user accounts. We may
                                                use your information for the
                                                purposes of managing our account
                                                and to keeping it in working
                                                order.
                                            </li>
                                            <li className='tableMargin'>
                                                To send administrative
                                                information to you. We may use
                                                your personal information to
                                                send you product, service and
                                                new feature information and/or
                                                information about changes to our
                                                terms, conditions, and policies.
                                            </li>
                                            <li className='tableMargin'>
                                                To protect our Services. We may
                                                use your information as part of
                                                our efforts to keep our Services
                                                safe and secure (for example,
                                                for fraud monitoring and
                                                prevention).
                                            </li>
                                            <li className='tableMargin'>
                                                To enforce our terms, conditions
                                                and policies for business
                                                purposes, to comply with legal
                                                and regulatory requirements or
                                                in connection with our contract.
                                            </li>
                                            <li className='tableMargin'>
                                                To respond to legal requests and
                                                prevent harm. If we receive a
                                                subpoena or other legal request,
                                                we may need to inspect the data
                                                we hold to determine how to
                                                respond.
                                            </li>
                                            <li className='tableMargin'>
                                                For other business purposes. We
                                                may use your information for
                                                other business purposes, such as
                                                data analysis, identifying usage
                                                trends, determining the
                                                effectiveness of our promotional
                                                campaigns and to evaluate and
                                                improve our Services, products,
                                                marketing and your experience.
                                                We may use and store this
                                                information in aggregated and
                                                anonymized form so that it is
                                                not associated with individual
                                                end users and does not include
                                                personal information. We will
                                                not use identifiable personal
                                                information without your
                                                consent.
                                            </li>
                                        </ul>
                                    </li>
                                    <br></br>
                                    <br></br>
                                    <li>
                                        <b>
                                            WILL YOUR INFORMATION BE SHARED WITH
                                            ANYONE?
                                        </b>
                                        <br></br>
                                        <br></br>
                                        In Short:&nbsp;&nbsp; We only share
                                        information with your consent, to comply
                                        with laws, to provide you with services,
                                        to protect your rights, or to fulfill
                                        business obligations.<br></br>
                                        <br></br>
                                        We may process or share your data that
                                        we hold based on the following legal
                                        basis:
                                        <ul>
                                            <li className='tableMargin'>
                                                Consent: We may process your
                                                data if you have given us
                                                specific consent to use your
                                                personal information for a
                                                specific purpose.
                                            </li>
                                            <li className='tableMargin'>
                                                Legitimate Interests: We may
                                                process your data when it is
                                                reasonably necessary to achieve
                                                our legitimate business
                                                interests.
                                            </li>
                                            <li className='tableMargin'>
                                                Performance of a Contract: Where
                                                we have entered into a contract
                                                with you, we may process your
                                                personal information to fulfill
                                                the terms of our contract.
                                            </li>
                                            <li className='tableMargin'>
                                                Legal Obligations: We may
                                                disclose your information where
                                                we are legally required to do so
                                                in order to comply with
                                                applicable law, governmental
                                                requests, a judicial proceeding,
                                                court order, or legal process,
                                                such as in response to a court
                                                order or a subpoena (including
                                                in response to public
                                                authorities to meet national
                                                security or law enforcement
                                                requirements).
                                            </li>
                                            <li className='tableMargin'>
                                                Vital Interests: We may disclose
                                                your information where we
                                                believe it is necessary to
                                                investigate, prevent, or take
                                                action regarding potential
                                                violations of our policies,
                                                suspected fraud, situations
                                                involving potential threats to
                                                the safety of any person and
                                                illegal activities, or as
                                                evidence in litigation in which
                                                we are involved.
                                            </li>
                                        </ul>
                                        <br></br>More specifically, we may need
                                        to process your data or share your
                                        personal information in the following
                                        situations:<br></br>
                                        <ul>
                                            <li className='tableMargin'>
                                                Business Transfers. We may share
                                                or transfer your information in
                                                connection with, or during
                                                negotiations of, any merger,
                                                sale of company assets,
                                                financing, or acquisition of all
                                                or a portion of our business to
                                                another company.
                                            </li>
                                            <li className='tableMargin'>
                                                Google Maps Platform APIs. We
                                                may share your information with
                                                certain Google Maps Platform
                                                APIs (e.g., Google Maps API,
                                                Place API). To find out more
                                                about Google’s Privacy Policy,
                                                please refer to this link. We
                                                use certain Google Maps Platform
                                                APIs to retrieve certain
                                                information when you make
                                                location-specific requests. This
                                                includes: Location; and other
                                                similar information. A full list
                                                of what we use information for
                                                can be found in this section and
                                                in the previous section titled
                                                "HOW DO WE USE YOUR
                                                INFORMATION?". We obtain and
                                                store on your device ('cache')
                                                your location. You may revoke
                                                your consent anytime by
                                                contacting us at the contact
                                                details provided at the end of
                                                this document. The Google Maps
                                                Platform APIs that we use store
                                                and access cookies and other
                                                information on your devices. If
                                                you are a user currently in the
                                                European Economic Area (EU
                                                countries, Iceland,
                                                Liechtenstein and Norway) or
                                                United Kingdom, please take a
                                                look at our Cookie Notice.
                                                Vendors, Consultants and Other
                                                Third-Party Service Providers.
                                                We may share your data with
                                                third-party vendors, service
                                                providers, contractors or agents
                                                who perform services for us or
                                                on our behalf and require access
                                                to such information to do that
                                                work. Examples include: payment
                                                processing, data analysis, email
                                                delivery, hosting services,
                                                customer service and marketing
                                                efforts. We may allow selected
                                                third parties to use tracking
                                                technology on the Services,
                                                which will enable them to
                                                collect data on our behalf about
                                                how you interact with our
                                                Services over time. This
                                                information may be used to,
                                                among other things, analyze and
                                                track data, determine the
                                                popularity of certain content,
                                                pages or features, and better
                                                understand online activity.
                                                Unless described in this notice,
                                                we do not share, sell, rent or
                                                trade any of your information
                                                with third parties for their
                                                promotional purposes. We have
                                                contracts in place with our data
                                                processors, which are designed
                                                to help safeguard your personal
                                                information. This means that
                                                they cannot do anything with
                                                your personal information unless
                                                we have instructed them to do
                                                it. They will also not share
                                                your personal information with
                                                any organization apart from us.
                                                They also commit to protect the
                                                data they hold on our behalf and
                                                to retain it for the period we
                                                instruct.
                                            </li>
                                            <li className='tableMargin'>
                                                Business Partners. We may share
                                                your information with our
                                                business partners to offer you
                                                certain products, services or
                                                promotions.
                                            </li>
                                            <li className='tableMargin'>
                                                Other Users. When you share
                                                personal information (for
                                                example, by posting comments,
                                                contributions or other content
                                                to the Services) or otherwise
                                                interact with public areas of
                                                the Services, such personal
                                                information may be viewed by all
                                                users and may be publicly made
                                                available outside the Services
                                                in perpetuity. Similarly, other
                                                users will be able to view
                                                descriptions of your activity,
                                                communicate with you within our
                                                Services, and view your profile.
                                            </li>
                                        </ul>
                                    </li>
                                    <br></br>
                                    <li>
                                        <b>
                                            WHO WILL YOUR INFORMATION BE SHARED
                                            WITH?{' '}
                                        </b>
                                        <br></br>
                                        <br></br>
                                        In Short:&nbsp;&nbsp; We only share
                                        information with the following
                                        categories of third parties.<br></br>
                                        <br></br>
                                        We only share and disclose your
                                        information with the following
                                        categories of third parties. If we have
                                        processed your data based on your
                                        consent and you wish to revoke your
                                        consent, please contact us using the
                                        contact details provided in the section
                                        below titled "HOW CAN YOU CONTACT US
                                        ABOUT THIS NOTICE?".
                                        <ul>
                                            <li>Data Analytics Services</li>
                                            <li>Cloud Computing Services</li>
                                            <li>
                                                Data Storage Service Providers
                                            </li>
                                            <li>Payment Processors</li>
                                            <li>
                                                Performance Monitoring Tools
                                            </li>
                                            <li>
                                                User Account Registration &
                                                Authentication Services
                                            </li>
                                            <li>
                                                Communication & Collaboration
                                                Tools
                                            </li>
                                        </ul>
                                    </li>
                                    <br></br>
                                    <li>
                                        <b>
                                            &nbsp;&nbsp;DO WE USE COOKIES AND
                                            OTHER TRACKING TECHNOLOGIES?
                                        </b>
                                        <br></br>
                                        <br></br>
                                        In Short:&nbsp;&nbsp; We may use cookies
                                        and other tracking technologies to
                                        collect and store your information.
                                        <br></br>
                                        <br></br>
                                        We may use cookies and similar tracking
                                        technologies (like web beacons and
                                        pixels) to access or store information.
                                        {/*Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.*/}
                                    </li>
                                    <br></br>
                                    <li>
                                        <b>
                                            IS YOUR INFORMATION TRANSFERRED
                                            INTERNATIONALLY?
                                        </b>
                                        <br></br>
                                        <br></br>
                                        In Short:&nbsp;&nbsp; We may transfer,
                                        store, and process your information in
                                        countries other than your own.<br></br>
                                        <br></br>
                                        Our servers are located in the United
                                        States. If you are accessing our
                                        Services from outside the United States,
                                        please be aware that your information
                                        may be transferred to, stored, and
                                        processed by us in our facilities and by
                                        those third parties with whom we may
                                        share your personal information (see
                                        "WILL YOUR INFORMATION BE SHARED WITH
                                        ANYONE?" above), in the United States,
                                        and other countries.
                                        <br></br>
                                        <br></br>
                                        If you are a resident in the European
                                        Economic Area (EEA) or United Kingdom
                                        (UK), then these countries may not
                                        necessarily have data protection laws or
                                        other similar laws as comprehensive as
                                        those in your country. We will however
                                        take all necessary measures to protect
                                        your personal information in accordance
                                        with this privacy notice and applicable
                                        law.
                                        <br></br>
                                        <br></br>European Commission's Standard
                                        Contractual Clauses:<br></br>
                                        <br></br>
                                        We have implemented measures to protect
                                        your personal information, including by
                                        using the European Commission's Standard
                                        Contractual Clauses for transfers of
                                        personal information between our group
                                        companies and between us and our
                                        third-party providers. These clauses
                                        require all recipients to protect all
                                        personal information that they process
                                        originating from the EEA or UK in
                                        accordance with European data protection
                                        laws and regulations. Our Standard
                                        Contractual Clauses can be provided upon
                                        request. We have implemented similar
                                        appropriate safeguards with our
                                        third-party service providers and
                                        partners and further details can be
                                        provided upon request.
                                    </li>
                                    <br></br>
                                    <li>
                                        <b>
                                            &nbsp;&nbsp;HOW LONG DO WE KEEP YOUR
                                            INFORMATION?
                                        </b>
                                        <br></br>
                                        <br></br>
                                        In Short:&nbsp;&nbsp; We keep your
                                        information for as long as necessary to
                                        fulfill the purposes outlined in this
                                        privacy notice unless otherwise required
                                        by law.<br></br>
                                        <br></br>
                                        We will only keep your personal
                                        information for as long as it is
                                        necessary for the purposes set out in
                                        this privacy notice, unless a longer
                                        retention period is required or
                                        permitted by law (such as tax,
                                        accounting or other legal requirements).
                                        No purpose in this notice will require
                                        us keeping your personal information for
                                        longer than the period of time in which
                                        users have an account with us. <br></br>
                                        <br></br>
                                        When we have no ongoing legitimate
                                        business need to process your personal
                                        information, we will either delete or
                                        anonymize such information, or, if this
                                        is not possible (for example, because
                                        your personal information has been
                                        stored in backup archives), then we will
                                        securely store your personal information
                                        and isolate it from any further
                                        processing until deletion is possible.
                                    </li>
                                    <br></br>
                                    <li>
                                        <b>
                                            DO WE COLLECT INFORMATION FROM
                                            MINORS?
                                        </b>
                                        <br></br>
                                        <br></br>In Short:&nbsp;&nbsp; We do not
                                        knowingly collect data from or market to
                                        children under 18 years of age.<br></br>
                                        <br></br>
                                        We do not knowingly solicit data from or
                                        market to children under 18 years of
                                        age. By using the Services, you
                                        represent that you are at least 18 or
                                        that you are the parent or guardian of
                                        such a minor and consent to such minor
                                        dependent’s use of the Services. If we
                                        learn that personal information from
                                        users less than 18 years of age has been
                                        collected, we will deactivate the
                                        account and take reasonable measures to
                                        promptly delete such data from our
                                        records. If you become aware of any data
                                        we may have collected from children
                                        under age 18, please contact us at
                                        info@yingme.co.
                                    </li>
                                    <br></br>
                                    <li>
                                        <b>WHAT ARE YOUR PRIVACY RIGHTS?</b>
                                        <br></br>
                                        <br></br>In Short:&nbsp;&nbsp; In some
                                        regions, such as the European Economic
                                        Area (EEA) and United Kingdom (UK), you
                                        have rights that allow you greater
                                        access to and control over your personal
                                        information. You may review, change, or
                                        terminate your account at any time.
                                        <br></br>
                                        <br></br>
                                        In some regions (like the EEA and UK),
                                        you have certain rights under applicable
                                        data protection laws. These may include
                                        the right (i) to request access and
                                        obtain a copy of your personal
                                        information, (ii) to request
                                        rectification or erasure; (iii) to
                                        restrict the processing of your personal
                                        information; and (iv) if applicable, to
                                        data portability. In certain
                                        circumstances, you may also have the
                                        right to object to the processing of
                                        your personal information. To make such
                                        a request, please use the{' '}
                                        <span>
                                            <a>contact details</a>
                                        </span>{' '}
                                        provided below. We will consider and act
                                        upon any request in accordance with
                                        applicable data protection laws.
                                        <br></br>
                                        <br></br>
                                        If we are relying on your consent to
                                        process your personal information, you
                                        have the right to withdraw your consent
                                        at any time. Please note however that
                                        this will not affect the lawfulness of
                                        the processing before its withdrawal,
                                        nor will it affect the processing of
                                        your personal information conducted in
                                        reliance on lawful processing grounds
                                        other than consent.<br></br>
                                        <br></br>
                                        If you are a resident in the EEA or UK
                                        and you believe we are unlawfully
                                        processing your personal information,
                                        you also have the right to complain to
                                        your local data protection supervisory
                                        authority. You can find their contact
                                        details here: &nbsp;
                                        <span>
                                            <a href='http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm'>
                                                http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
                                            </a>
                                        </span>
                                        .<br></br>
                                        <br></br>
                                        If you are a resident in Switzerland,
                                        the contact details for the data
                                        protection authorities are available
                                        here: &nbsp;
                                        <span>
                                            <a href='https://www.edoeb.admin.ch/edoeb/en/home.html.'>
                                                https://www.edoeb.admin.ch/edoeb/en/home.html.
                                            </a>
                                        </span>
                                        <br></br>
                                        <br></br>
                                        If you have questions or comments about
                                        your privacy rights, you may email us at
                                        info@yingme.co.
                                        <br></br>
                                        <br></br>Account Information<br></br>
                                        <br></br>
                                        If you would at any time like to review
                                        or change the information in your
                                        account or terminate your account, you
                                        can:
                                        <ul>
                                            <br></br>
                                            <li>
                                                Contact us using the contact
                                                information provided.
                                            </li>
                                            <li>
                                                Log in to your account and go to
                                                the edit profile page to update
                                                your personal information.
                                            </li>
                                            <li>
                                                Log in to your account settings
                                                to update your timezone.
                                            </li>
                                        </ul>
                                        <br></br>
                                        Upon your request to terminate your
                                        account, we will deactivate or delete
                                        your account and information from our
                                        active databases. However, we may retain
                                        some information in our files to prevent
                                        fraud, troubleshoot problems, assist
                                        with any investigations, enforce our
                                        Terms of Use and/or comply with
                                        applicable legal requirements.<br></br>
                                        <br></br>
                                        <u>
                                            Opting out of email marketing:
                                        </u>{' '}
                                        You can unsubscribe from our marketing
                                        email list at any time by clicking on
                                        the unsubscribe link in the emails that
                                        we send or by contacting us using the
                                        details provided below. You will then be
                                        removed from the marketing email list —
                                        however, we may still communicate with
                                        you, for example to send you
                                        service-related emails that are
                                        necessary for the administration and use
                                        of your account, to respond to service
                                        requests, or for other non-marketing
                                        purposes. To otherwise opt-out, you may:
                                        <ul>
                                            {' '}
                                            <br></br>
                                            <li>
                                                <span className='poloBlue'>
                                                    Contact us using the contact
                                                    information provided.
                                                </span>
                                            </li>
                                        </ul>
                                    </li>
                                    <br></br>
                                    <br></br>
                                    <li>
                                        <b>
                                            CONTROLS FOR DO-NOT-TRACK FEATURES
                                        </b>
                                        <br></br>
                                        <br></br>
                                        Most web browsers and some mobile
                                        operating systems and mobile
                                        applications include a Do-Not-Track
                                        ("DNT") feature or setting you can
                                        activate to signal your privacy
                                        preference not to have data about your
                                        online browsing activities monitored and
                                        collected. At this stage no uniform
                                        technology standard for recognizing and
                                        implementing DNT signals has been
                                        finalized. As such, we do not currently
                                        respond to DNT browser signals or any
                                        other mechanism that automatically
                                        communicates your choice not to be
                                        tracked online. If a standard for online
                                        tracking is adopted that we must follow
                                        in the future, we will inform you about
                                        that practice in a revised version of
                                        this privacy notice.
                                    </li>
                                    <br></br>
                                    <li>
                                        <b>
                                            {' '}
                                            DO CALIFORNIA RESIDENTS HAVE
                                            SPECIFIC PRIVACY RIGHTS?
                                        </b>
                                        <br></br>
                                        <br></br>
                                        In Short:&nbsp;&nbsp; Yes, if you are a
                                        resident of California, you are granted
                                        specific rights regarding access to your
                                        personal information.<br></br>
                                        <br></br>
                                        California Civil Code Section 1798.83,
                                        also known as the "Shine The Light" law,
                                        permits our users who are California
                                        residents to request and obtain from us,
                                        once a year and free of charge,
                                        information about categories of personal
                                        information (if any) we disclosed to
                                        third parties for direct marketing
                                        purposes and the names and addresses of
                                        all third parties with which we shared
                                        personal information in the immediately
                                        preceding calendar year. If you are a
                                        California resident and would like to
                                        make such a request, please submit your
                                        request in writing to us using the
                                        contact information provided below.
                                        <br></br>
                                        <br></br>
                                        If you are under 18 years of age, reside
                                        in California, and have a registered
                                        account with a Service, you have the
                                        right to request removal of unwanted
                                        data that you publicly post on the
                                        Services. To request removal of such
                                        data, please contact us using the
                                        contact information provided below, and
                                        include the email address associated
                                        with your account and a statement that
                                        you reside in California. We will make
                                        sure the data is not publicly displayed
                                        on the Services, but please be aware
                                        that the data may not be completely or
                                        comprehensively removed from all our
                                        systems (e.g. backups, etc.).
                                        <br></br>
                                        <br></br>CCPA Privacy Notice<br></br>
                                        <br></br>
                                        The California Code of Regulations
                                        defines a "resident" as:
                                        <ol>
                                            <br></br>
                                            <li>
                                                every individual who is in the
                                                State of California for other
                                                than a temporary or transitory
                                                purpose and
                                            </li>
                                            <br></br>
                                            <li>
                                                every individual who is
                                                domiciled in the State of
                                                California who is outside the
                                                State of California for a
                                                temporary or transitory purpose
                                            </li>
                                        </ol>
                                        <br></br>All other individuals are
                                        defined as "non-residents." <br></br>
                                        <br></br>If this definition of
                                        "resident" applies to you, we must
                                        adhere to certain rights and obligations
                                        regarding your personal information.
                                        <br></br>
                                        <br></br>What categories of personal
                                        information do we collect?<br></br>
                                        <br></br>We have collected the following
                                        categories of personal information in
                                        the past twelve (12) months:<br></br>
                                        <br></br>
                                        <div className='CCPAWrapper'>
                                            <table className='CCPATable'>
                                                <tr>
                                                    <th className='CCPACol1'>
                                                        Category
                                                    </th>
                                                    <th className='CCPACol1'>
                                                        Examples
                                                    </th>
                                                    <th className='CCPACol1'>
                                                        Collected
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th className='CCPACol1'>
                                                        Identifiers
                                                    </th>
                                                    <th className='CCPACol1'>
                                                        Contact details, such as
                                                        real name, alias, postal
                                                        address, telephone or
                                                        mobile contact number,
                                                        unique personal
                                                        identifier, online
                                                        identifier, Internet
                                                        Protocol address, email
                                                        address and account name
                                                    </th>
                                                    <th className='CCPACol1'>
                                                        YES
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th className='CCPACol1'>
                                                        Personal infor-mation
                                                        categories listed in the
                                                        California Customer
                                                        Records statute
                                                    </th>
                                                    <th className='CCPACol1'>
                                                        Name, contact
                                                        infor-mation, education,
                                                        employ-ment, employment
                                                        history and financial
                                                        inform-ation
                                                    </th>
                                                    <th className='CCPACol1'>
                                                        YES
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th className='CCPACol1'>
                                                        Protected
                                                        classifi-cation
                                                        character-istics under
                                                        California or federal
                                                        law
                                                    </th>
                                                    <th className='CCPACol1'>
                                                        Gender and date of birth
                                                    </th>
                                                    <th className='CCPACol1'>
                                                        NO
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th className='CCPACol1'>
                                                        Commercial information
                                                    </th>
                                                    <th className='CCPACol1'>
                                                        Tran-saction
                                                        infor-mation, purchase
                                                        history, financial
                                                        details and payment
                                                        infor-mation
                                                    </th>
                                                    <th className='CCPACol1'>
                                                        YES
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th className='CCPACol1'>
                                                        Biometric infor-mation
                                                    </th>
                                                    <th className='CCPACol1'>
                                                        Finger-prints and
                                                        voice-prints
                                                    </th>
                                                    <th className='CCPACol1'>
                                                        NO
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th className='CCPACol1'>
                                                        Internet or other
                                                        similar network activity
                                                    </th>
                                                    <th className='CCPACol1'>
                                                        Browsing history, search
                                                        history, online
                                                        behavior, interest data,
                                                        and inter-actions with
                                                        our and other websites,
                                                        appli-cations, systems
                                                        and advertise-ments
                                                    </th>
                                                    <th className='CCPACol1'>
                                                        NO
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th className='CCPACol1'>
                                                        Geo-location data{' '}
                                                    </th>
                                                    <th className='CCPACol1'>
                                                        Device location
                                                    </th>
                                                    <th className='CCPACol1'>
                                                        YES
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th className='CCPACol1'>
                                                        Audio, electronic,
                                                        visual, thermal,
                                                        olfactory, or similar
                                                        infor-mation
                                                    </th>
                                                    <th className='CCPACol1'>
                                                        Images and audio, video
                                                        or call recordings
                                                        created in connect-ion
                                                        with our business
                                                        activities
                                                    </th>
                                                    <th className='CCPACol1'>
                                                        NO
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th className='CCPACol1'>
                                                        Professional or
                                                        employment-related
                                                        infor-mation{' '}
                                                    </th>
                                                    <th className='CCPACol1'>
                                                        Business contact details
                                                        in order to provide you
                                                        our services at a
                                                        business level, job
                                                        title as well as work
                                                        history and professional
                                                        quali-fications if you
                                                        apply for a job with us
                                                    </th>
                                                    <th className='CCPACol1'>
                                                        NO
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th className='CCPACol1'>
                                                        Education Infor-mation
                                                    </th>
                                                    <th className='CCPACol1'>
                                                        Student records and
                                                        directory infor-mation
                                                    </th>
                                                    <th className='CCPACol1'>
                                                        NO
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th className='CCPACol1'>
                                                        Inferences drawn from
                                                        other personal
                                                        infor-mation
                                                    </th>
                                                    <th className='CCPACol1'>
                                                        Inferences drawn from
                                                        any of the collected
                                                        personal information
                                                        listed above to create a
                                                        profile or summary
                                                        about, for example, an
                                                        indi-vidual’s
                                                        pre-ferences and
                                                        character-istics
                                                    </th>
                                                    <th className='CCPACol1'>
                                                        NO
                                                    </th>
                                                </tr>
                                            </table>
                                        </div>
                                        <br></br>
                                        <br></br>
                                        We may also collect other personal
                                        information outside of these categories
                                        instances where you interact with us
                                        in-person, online, or by phone or mail
                                        in the context of:
                                        <br></br>
                                        <ul>
                                            <li className='tableMargin'>
                                                Receiving help through our
                                                customer support channels;
                                            </li>
                                            <li className='tableMargin'>
                                                Participation in customer
                                                surveys or contests; and
                                            </li>
                                            <li className='tableMargin'>
                                                Facilitation in the delivery of
                                                our Services and to respond to
                                                your inquiries.
                                            </li>
                                        </ul>
                                        <br></br>How do we use and share your
                                        personal information?<br></br>
                                        <br></br>
                                        More information about our data
                                        collection and sharing practices can be
                                        found in this privacy notice.<br></br>
                                        <br></br>
                                        You may contact us by email at
                                        info@yingme.co,{' '}
                                        <span className='poloBlue'>
                                            or by referring to the contact
                                            details at the bottom of this
                                            document.
                                        </span>
                                        <br></br>
                                        <br></br>If you are using an authorized
                                        agent to exercise your right to opt-out
                                        we may deny a request if the authorized
                                        agent does not submit proof that they
                                        have been validly authorized to act on
                                        your behalf.
                                        <br></br>
                                        <br></br>Will your information be shared
                                        with anyone else?<br></br>
                                        <br></br>
                                        We may disclose your personal
                                        information with our service providers
                                        pursuant to a written contract between
                                        us and each service provider. Each
                                        service provider is a for-profit entity
                                        that processes the information on our
                                        behalf.<br></br>
                                        <br></br>
                                        We may use your personal information for
                                        our own business purposes, such as for
                                        undertaking internal research for
                                        technological development and
                                        demonstration. This is not considered to
                                        be "selling" of your personal data.
                                        <br></br>
                                        <br></br>
                                        YING, Inc. has disclosed the following
                                        categories of personal information to
                                        third parties for a business or
                                        commercial purpose in the preceding
                                        twelve (12) months:
                                        <ul>
                                            <li className='tableMargin'>
                                                Category A. Identifiers, such as
                                                contact details, like your real
                                                name, alias, postal address,
                                                telephone or mobile contact
                                                number, unique personal
                                                identifier, online identifier,
                                                Internet Protocol address, email
                                                address and account name.
                                            </li>
                                            <li className='tableMargin'>
                                                Category B. Personal
                                                information, as defined in the
                                                California Customer Records law,
                                                such as your name, contact
                                                information, education,
                                                employment, employment history
                                                and financial information.
                                            </li>
                                            <li className='tableMargin'>
                                                Category D. Commercial
                                                information, such as transaction
                                                information, purchase history,
                                                financial details and payment
                                                information.
                                            </li>
                                            <li className='tableMargin'>
                                                Category G. Geolocation data,
                                                such as device location.
                                            </li>
                                        </ul>
                                        <br></br>
                                        <br></br>
                                        YING, Inc. has not sold any personal
                                        information to third parties for a
                                        business or commercial purpose in the
                                        preceding 12 months. YING, Inc. will not
                                        sell personal information in the future
                                        belonging to website visitors, users and
                                        other consumers.<br></br>
                                        <br></br>
                                        Your rights with respect to your
                                        personal data<br></br>
                                        <br></br>
                                        <u>
                                            Right to request deletion of the
                                            data - Request to delete
                                        </u>
                                        <br></br>
                                        <br></br>
                                        You can ask for the deletion of your
                                        personal information. If you ask us to
                                        delete your personal information, we
                                        will respect your request and delete
                                        your personal information, subject to
                                        certain exceptions provided by law, such
                                        as (but not limited to) the exercise by
                                        another consumer of his or her right to
                                        free speech, our compliance requirements
                                        resulting from a legal obligation or any
                                        processing that may be required to
                                        protect against illegal activities.
                                        <br></br>
                                        <br></br>
                                        <u>
                                            Right to be informed - Request to
                                            know
                                        </u>
                                        <br></br>
                                        <br></br>
                                        Depending on the circumstances, you have
                                        a right to know:<br></br>
                                        <ul>
                                            <li className='tableMargin'>
                                                whether we collect and use your
                                                personal information;
                                            </li>
                                            <li className='tableMargin'>
                                                the categories of personal
                                                information that we collect;
                                            </li>
                                            <li className='tableMargin'>
                                                the purposes for which the
                                                collected personal information
                                                is used;
                                            </li>
                                            <li className='tableMargin'>
                                                whether we sell your personal
                                                information to third parties;
                                            </li>
                                            <li className='tableMargin'>
                                                the categories of personal
                                                information that we sold or
                                                disclosed for a business
                                                purpose;
                                            </li>
                                            <li className='tableMargin'>
                                                the categories of third parties
                                                to whom the personal information
                                                was sold or disclosed for a
                                                business purpose; and
                                            </li>
                                            <li className='tableMargin'>
                                                the business or commercial
                                                purpose for collecting or
                                                selling personal information.
                                            </li>
                                        </ul>
                                        <br></br>
                                        In accordance with applicable law, we
                                        are not obligated to provide or delete
                                        consumer information that is
                                        de-identified in response to a consumer
                                        request or to re-identify individual
                                        data to verify a consumer request.
                                        <br></br>
                                        <br></br>
                                        <u>
                                            Right to Non-Discrimination for the
                                            Exercise of a Consumer’s Privacy
                                            Rights
                                        </u>
                                        <br></br>
                                        <br></br>
                                        We will not discriminate against you if
                                        you exercise your privacy rights.
                                        <br></br>
                                        <br></br>
                                        <u>Verification process</u>
                                        <br></br>
                                        <br></br>
                                        Upon receiving your request, we will
                                        need to verify your identity to
                                        determine you are the same person about
                                        whom we have the information in our
                                        system. These verification efforts
                                        require us to ask you to provide
                                        information so that we can match it with
                                        information you have previously provided
                                        us. For instance, depending on the type
                                        of request you submit, we may ask you to
                                        provide certain information so that we
                                        can match the information you provide
                                        with the information we already have on
                                        file, or we may contact you through a
                                        communication method (e.g. phone or
                                        email) that you have previously provided
                                        to us. We may also use other
                                        verification methods as the
                                        circumstances dictate.<br></br>
                                        <br></br>
                                        We will only use personal information
                                        provided in your request to verify your
                                        identity or authority to make the
                                        request. To the extent possible, we will
                                        avoid requesting additional information
                                        from you for the purposes of
                                        verification. If, however, we cannot
                                        verify your identity from the
                                        information already maintained by us, we
                                        may request that you provide additional
                                        information for the purposes of
                                        verifying your identity, and for
                                        security or fraud-prevention purposes.
                                        We will delete such additionally
                                        provided information as soon as we
                                        finish verifying you.<br></br>
                                        <br></br>
                                        <u>Other privacy rights</u>
                                        <br></br>
                                        <ul>
                                            <li className='tableMargin'>
                                                you may object to the processing
                                                of your personal data
                                            </li>
                                            <li className='tableMargin'>
                                                you may request correction of
                                                your personal data if it is
                                                incorrect or no longer relevant,
                                                or ask to restrict the
                                                processing of the data
                                            </li>
                                            <li className='tableMargin'>
                                                you can designate an authorized
                                                agent to make a request under
                                                the CCPA on your behalf. We may
                                                deny a request from an
                                                authorized agent that does not
                                                submit proof that they have been
                                                validly authorized to act on
                                                your behalf in accordance with
                                                the CCPA.
                                            </li>
                                            <li className='tableMargin'>
                                                you may request to opt-out from
                                                future selling of your personal
                                                information to third parties.
                                                Upon receiving a request to
                                                opt-out, we will act upon the
                                                request as soon as feasibly
                                                possible, but no later than 15
                                                days from the date of the
                                                request submission.
                                            </li>
                                        </ul>
                                        <br></br>
                                        To exercise these rights, you can
                                        contact us by email at info@yingme.co,
                                        <span className='poloBlue'>
                                            {' '}
                                            or by referring to the contact
                                            details at the bottom of this
                                            document. If you have a complaint
                                            about how we handle your data, we
                                            would like to hear from you.
                                        </span>
                                    </li>
                                    <br></br>
                                    <li>
                                        <b>
                                            DO WE MAKE UPDATES TO THIS NOTICE?
                                        </b>
                                        <br></br>
                                        <br></br>In Short:&nbsp;&nbsp; Yes, we
                                        will update this notice as necessary to
                                        stay compliant with relevant laws.
                                        <br></br>
                                        <br></br>
                                        We may update this privacy notice from
                                        time to time. The updated version will
                                        be indicated by an updated "Revised"
                                        date and the updated version will be
                                        effective as soon as it is accessible.
                                        If we make material changes to this
                                        privacy notice, we may notify you either
                                        by prominently posting a notice of such
                                        changes or by directly sending you a
                                        notification. We encourage you to review
                                        this privacy notice frequently to be
                                        informed of how we are protecting your
                                        information.
                                    </li>
                                    <br></br>
                                    <li>
                                        <b>
                                            HOW CAN YOU CONTACT US ABOUT THIS
                                            NOTICE?
                                        </b>
                                        <br></br>
                                        <br></br>
                                        If you have questions or comments about
                                        this notice, you may email us at
                                        info@yingme.co or by post to:<br></br>
                                        <br></br>
                                        <span>YING, Inc.</span>
                                        <br></br>
                                        <span className='poloBlue'>
                                            Kismet Coworking
                                        </span>
                                        <br></br>
                                        <span className='poloBlue'>
                                            4230 Main Street
                                        </span>
                                        <br></br>
                                        <span className='poloBlue'>
                                            Philidelphia, PA 19127
                                        </span>
                                        <br></br>
                                        <span className='poloBlue'>
                                            United States
                                        </span>
                                        <br></br>
                                        <br></br>
                                    </li>
                                    <li>
                                        <b>
                                            HOW CAN YOU REVIEW, UPDATE, OR
                                            DELETE THE DATA WE COLLECT FROM YOU?{' '}
                                        </b>
                                        <br></br>
                                        <br></br>
                                        Based on the applicable laws of your
                                        country, you may have the right to
                                        request access to the personal
                                        information we collect from you, change
                                        that information, or delete it in some
                                        circumstances. To request to review,
                                        update, or delete your personal
                                        information, please email us at
                                        info@yingme.co.
                                    </li>
                                </ol>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                    <IonGrid className='about__manifesto--container'>
                        <IonRow className='about__manifesto--wrap'>
                            <IonCol
                                sizeXs='12'
                                sizeSm='12'
                                className='about__manifesto--contactUs'
                            >
                                <p className='about__manifesto--text'>
                                    For questions, feedback & suggestions
                                    contact us at{' '}
                                    <span>
                                        <a href='mailto:info@yingme.co'>
                                            info@yingme.co
                                        </a>
                                    </span>
                                </p>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonGrid>
            </IonContent>
        </IonModal>
    );
};

export default PrivacyPolicyModal;
