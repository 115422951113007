// importing modules
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'context/firebase/authContext';
// importing components
import {
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonPage,
    IonRow,
} from '@ionic/react';
import UserImg from '../RequestSkill/UserImg/UserImg';
import RequestTaskTabs from '../RequestSkill/UI/RequestTaskTabs';
import Strokes from 'components/Strokes';
// importing assets
// importing styles
import './Settings.css';

const OPTIONS = [
    {
        id: '1',
        name: 'Profile',
        description: 'Timezone',
        href: '/settings/profile',
    },
    {
        id: '2',
        name: 'Gratitude Pot',
        description: 'Send & Recieve Payments',
        href: '/settings/gratitudePot',
    },
];

const Settings: React.FC = () => {
    const history = useHistory();
    const { currentUser } = useAuth();
    return (
        <IonPage>
            <IonContent>
                <IonGrid>
                    <IonRow className='settings__header'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='settings__header--container'
                        >
                            <Strokes top={true} />
                            <RequestTaskTabs
                                prevPage={`/home`}
                                disablePrevStep={false}
                            />
                            <UserImg providerUid={currentUser.uid} />
                        </IonCol>
                    </IonRow>
                    {/* title */}
                    <IonRow className='settings__textContent'>
                        <IonCol
                            className='settings__textContent--container'
                            sizeSm='12'
                            sizeXs='12'
                        >
                            <h1 className='settings__textContent--title'>
                                <span>Customize</span> your settings
                            </h1>
                        </IonCol>
                    </IonRow>
                    {/* setting options */}
                    <IonGrid className='settings__form--container'>
                        <IonRow className='settings__form--row'>
                            <IonRow className='settings__col--wrap'>
                                {OPTIONS.map((option) => (
                                    <IonCol
                                        onClick={() =>
                                            history.push(option.href)
                                        }
                                        key={option.id}
                                        className='settings__col'
                                        sizeXs='12'
                                        sizeSm='12'
                                    >
                                        <IonCol
                                            sizeXs='12'
                                            sizeSm='12'
                                            className='settings__content'
                                        >
                                            <p className='settings__content--text'>
                                                {option.name}
                                            </p>
                                            <p className='settings__content--description'>
                                                {option.description}
                                            </p>
                                        </IonCol>
                                        <br />
                                        <br />
                                    </IonCol>
                                ))}
                            </IonRow>
                        </IonRow>
                    </IonGrid>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default Settings;
