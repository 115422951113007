// importing modules
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
// importing components
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonLabel,
    IonRow,
    IonModal,
    IonIcon,
} from '@ionic/react';
import UserImg from '../../../../components/UserImg/UserImg';
import Strokes from 'components/Strokes';
// importing assets
import { arrowBackOutline, closeOutline } from 'ionicons/icons';
// importing styles
import '../../TaskCredits/TaskCreditsInput';

type CompletionTime = {
    hours: number;
    minutes: number;
};

const ParticipantCreditsModal: React.FC<{
    showCreditsModal: boolean;
    handleCreditsModal: () => void;
    selectedParticipant: {};
}> = ({ showCreditsModal, handleCreditsModal, selectedParticipant }) => {
    const history = useHistory();
    const { register, handleSubmit, errors } = useForm<CompletionTime>();
    const [mins, setMins] = useState<string>('');
    const [hrs, setHrs] = useState<string>('');

    const handleMins = (event: React.ChangeEvent<HTMLInputElement>) =>
        setMins(event.target.value);
    const handleHours = (event: React.ChangeEvent<HTMLInputElement>) =>
        setHrs(event.target.value);

    const handleOnSubmit = (data: CompletionTime) => {
        console.log(data);
    };

    // pre populate participant credits based on graphql data

    return (
        <IonModal isOpen={showCreditsModal}>
            <IonContent>
                <IonGrid>
                    {/* completeTask header */}
                    <IonRow className='completeTask__header'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='completeTask__header--container'
                        >
                            <Strokes top={true} />
                            <IonCol
                                sizeXs='12'
                                sizeSm='12'
                                sizeMd='12'
                                className='completeTaskNav__btns--wrap'
                            >
                                <IonButton
                                    className='completeTaskNav__btn'
                                    onClick={handleCreditsModal}
                                    fill='clear'
                                    color='ion-primary'
                                >
                                    <IonIcon
                                        className='completeTaskNav__btn--icon'
                                        icon={arrowBackOutline}
                                    />
                                </IonButton>
                                <IonButton
                                    className='completeTaskNav__btn'
                                    onClick={() => {
                                        history.push('/home');
                                        handleCreditsModal();
                                    }}
                                    color='ion-primary'
                                    fill='clear'
                                >
                                    <IonIcon
                                        className='completeTaskNav__btn--icon'
                                        icon={closeOutline}
                                    />
                                </IonButton>
                            </IonCol>
                            <UserImg />
                        </IonCol>
                    </IonRow>
                    {/* completeTask header */}

                    {/* text content */}
                    <IonRow className='completeTask__textContent'>
                        <IonCol
                            className='completeTask__textContent--container'
                            sizeSm='12'
                            sizeXs='12'
                        >
                            <h1 className='completeTask__textContent--title'>
                                How long did <span>Meghan work</span> for?
                            </h1>
                        </IonCol>
                    </IonRow>
                    {/* text content */}

                    {/* time form */}
                    <IonGrid className='completeTask__form--container'>
                        <IonRow className='completeTask__form--row'>
                            <form
                                onSubmit={handleSubmit(handleOnSubmit)}
                                className='completeTask__col'
                            >
                                <IonCol
                                    sizeXs='6'
                                    sizeSm='6'
                                    className='completeTask__input--wrap'
                                >
                                    <input
                                        onChange={handleHours}
                                        name='hours'
                                        className='completeTask__input'
                                        placeholder='-'
                                        type='number'
                                        min={1}
                                        max={24}
                                        ref={register({
                                            required: 'Hours are required',
                                        })}
                                        pattern='\d*'
                                    />
                                    <IonLabel className='completeTask__input--label'>
                                        Hours
                                    </IonLabel>
                                </IonCol>
                                <IonCol
                                    sizeXs='6'
                                    sizeSm='6'
                                    className='completeTask__input--wrap'
                                >
                                    <input
                                        onChange={handleMins}
                                        name='minutes'
                                        className='completeTask__input'
                                        placeholder='-'
                                        type='number'
                                        min={1}
                                        max={60}
                                        ref={register({
                                            required: 'Minutes are required',
                                        })}
                                        pattern='\d*'
                                    />
                                    <IonLabel className='completeTask__input--label'>
                                        Minutes
                                    </IonLabel>
                                </IonCol>
                            </form>
                        </IonRow>

                        <p className='completeTask__textContent--text'>
                            You can change by tapping and editing. We will
                            confirm this time with the other person before
                            adding credits to your account.{' '}
                        </p>
                    </IonGrid>

                    <IonRow className='completeTask__btn--container'>
                        <IonCol
                            className='completeTask__btn--wrap'
                            sizeXs='12'
                            sizeSm='12'
                        >
                            {/* <IonButton
                                onClick={() => {
                                    handleCreditUpdate()
                                    handleCreditsModal()
                                }}
                                fill="clear"
                                disabled={!(mins.length > 0 && hrs.length > 0)}
                                color="ion-primary"
                                className="createTask__btn"
                            >
                                Continue
                            </IonButton> */}
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonModal>
    );
};

export default ParticipantCreditsModal;
