export const time_credit_conversion = (num) => {
    const hours = Math.floor(num / 60);
    let minutes;
    if (num % 60 === 0) {
        minutes = '00';
    } else if (num % 60 >= 1 && num % 60 <= 9) {
        minutes = '0' + (num % 60);
    } else {
        minutes = num % 60;
    }
    return `${hours}:${minutes}`;
};

export const hrs_mins_to_credits = (hrs, mins) => {
    return parseInt(hrs * 60 + mins);
};

export const int_to_credits = (hrs, mins) => {
    const num = hrs_mins_to_credits(hrs, mins);
    return time_credit_conversion(num);
};

export { time_credit_conversion as default };
