// importing modules
import React from 'react';
import moment from 'moment-timezone';
import { NavLink, useHistory } from 'react-router-dom';
// importing components
import { IonButton, IonCol, IonImg, IonSpinner, IonIcon } from '@ionic/react';
// importing assets
import { informationCircleOutline } from 'ionicons/icons';
// importing utilities
import { getLocalStorage } from '../../helpers/localStorage/localStorageHelper';
// importing graphql
import {
    GetProfileJobs_profile_outgoingParticipationRequests_recipient,
    GetProfileJobs_profile_incomingParticipationRequests_provider,
    GetProfileJobs_profile_jobsPosted,
} from '../../GraphQL/__generated__/GetProfileJobs';
// improting styles
import './ProviderRequestItem.css';
import { ADMIN_GROUP_PROFILE_PICTURE } from 'GraphQL/Admin/adminGroupProfilePicture/adminGroupProfilePicture';
import { useQuery } from '@apollo/react-hooks';

const ReceivingTaskItem: React.FC<{
    task: GetProfileJobs_profile_jobsPosted;
    otherProfile:
        | GetProfileJobs_profile_outgoingParticipationRequests_recipient
        | GetProfileJobs_profile_incomingParticipationRequests_provider;
}> = ({ task, otherProfile }) => {
    const { data, loading, error } = useQuery(ADMIN_GROUP_PROFILE_PICTURE, {
        variables: { adminId: task.adminId },
    });

    const hasAdmin = task.adminId;

    const history = useHistory();
    const taskStartTime = moment(task.startTime).tz(
        getLocalStorage('tz'),
        false
    );
    const taskEndTime = moment(task.endTime).tz(getLocalStorage('tz'), false);
    let taskTime;
    let btns;

    if (moment() < taskStartTime) {
        // check if the task hasnt started
        taskTime = (
            <p className='taskItem__info--time'>
                &#x02022;&nbsp;Starts {taskStartTime.fromNow()} &nbsp;
                <span>- Posted Task</span>
            </p>
        );
        btns = (
            <React.Fragment>
                <IonButton
                    fill='clear'
                    color='ion-primary'
                    className='taskItem__btn--cancel taskItem__btn--cancel--recipient'
                    onClick={() => history.push(`/delete/jobPost/${task.id}`)}
                >
                    Delete Task
                </IonButton>
            </React.Fragment>
        );
    } else if (moment().isBetween(taskStartTime, taskEndTime)) {
        // check if the task is in progress
        taskTime = (
            <p className='taskItem__info--time'>
                &#x02022;&nbsp;Ends {taskEndTime.fromNow()} &nbsp;
                <span>- Posted Task</span>
            </p>
        );
        btns = (
            <IonButton
                fill='clear'
                color='ion-primary'
                className='completeTask__btn'
            >
                <NavLink
                    to={{
                        pathname: `/completeTaskPosted/recipient/${task.id}`,
                        state: {
                            id: task.id,
                        },
                    }}
                    className='completeTask__btn--link'
                >
                    Complete This Task
                </NavLink>
            </IonButton>
        );
    } else if (moment() > taskEndTime) {
        // check if the task has ended
        taskTime = (
            <p className='taskItem__info--time'>
                &#x02022;&nbsp;Ended {taskEndTime.fromNow()} &nbsp;
                <span>- Posted Task</span>
            </p>
        );
        btns = (
            <IonButton
                fill='clear'
                color='ion-primary'
                className='completeTask__btn'
            >
                <NavLink
                    to={{
                        pathname: `/completeTaskPosted/recipient/${task.id}`,
                        state: {
                            id: task.id,
                        },
                    }}
                    className='completeTask__btn--link'
                >
                    Complete This Task
                </NavLink>
            </IonButton>
        );
    }

    return (
        <IonCol className='taskItem' sizeSm='11' sizeXs='11'>
            <IonIcon
                onClick={() => history.push(`/task/${task.id}`)}
                icon={informationCircleOutline}
                className='taskItem__info--icon'
            />
            <IonCol sizeXs='12' sizeSm='12' className='taskItem__info'>
                <IonCol sizeXl='3' sizeLg='3' sizeMd='3' sizeXs='3' sizeSm='3'>
                    <div className='taskItem__img--wrap'>
                        <IonImg
                            onClick={() =>
                                hasAdmin
                                    ? {}
                                    : history.push(
                                          `/portfolio/${otherProfile.uid}`
                                      )
                            }
                            className='taskItem__img'
                            src={
                                hasAdmin
                                    ? !loading
                                        ? data.AdminGroupProfilePicture
                                              .orgPicture
                                        : null
                                    : otherProfile.profilePicture
                            }
                        />
                    </div>
                </IonCol>
                <IonCol className='taskItem__info--wrap' sizeXs='9' sizeSm='9'>
                    <h3 className='taskItem__info--name'>{task.title}</h3>
                    {taskTime}
                </IonCol>
            </IonCol>
            <IonCol sizeXs='12' sizeSm='12' className='taskItem__btn--wrap'>
                {btns}
            </IonCol>
        </IonCol>
    );
};

export default ReceivingTaskItem;
