// importing modules
import React from 'react';
// importing components
import { IonRow } from '@ionic/react';
import ReceivingTaskItem from '../taskItems/ReceivingTaskItem';
// importing types
import { GetProfileSkillRequests_profile_upcomingJobsReceive } from '../../../GraphQL/__generated__/GetProfileSkillRequests';

const OutgoingSkillRequests: React.FC<{
    skillRequests: GetProfileSkillRequests_profile_upcomingJobsReceive[];
}> = ({ skillRequests }) => {
    return (
        <IonRow className='completedTasksItem__row'>
            <IonRow className='tasks__row'>
                {skillRequests.length > 0 ? (
                    skillRequests.map((task, id) => {
                        return (
                            <ReceivingTaskItem
                                key={id}
                                otherProfile={task.provider}
                                task={task}
                            />
                        );
                    })
                ) : (
                    <IonRow className='tasksPosted__text'>
                        <p>
                            Accepted skill requests that you sent to
                            <br />
                            group members will appear here.
                        </p>
                    </IonRow>
                )}
            </IonRow>
        </IonRow>
    );
};

export default OutgoingSkillRequests;
