import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/firestore';
import { firebaseConfig, firebaseConfigTesting } from 'firebaseConfig';

//if environment is production, use firebase production instance
//else use firebase testing instance (for every environment other than production)
const app = firebase.initializeApp(
    process.env.NODE_ENV === 'production'
        ? firebaseConfig
        : firebaseConfigTesting
);

export const auth = app.auth();
export const store = app.storage();
export const firestore = app.firestore();

export default app;
