// imporing modules
import React, { useState, useEffect } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
// importing components
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonIcon,
    IonImg,
    IonLabel,
    IonModal,
    IonRow,
} from '@ionic/react';
import UserImg from '../../UserImg/UserImg';
import TaskLocationItem from './TaskLocationItem/TaskLocationItem';
import Strokes from 'components/Strokes';
// importing assets
import { arrowBackOutline } from 'ionicons/icons';
// importing helpers
import { getLocalStorage } from '../../../../helpers/localStorage/localStorageHelper';
// importing styles
import './LocationsModal.css';
import '../../../../components/GooglePlacesDropDown/GooglePlacesDropDown.css';

const TaskLocation: React.FC<{
    locationStr: string;
    modalStatus: boolean;
    handleModal: () => void;
    handleLocation: Function;
    provider: string;
    providerName: string;
}> = ({
    modalStatus,
    handleModal,
    handleLocation,
    provider,
    locationStr,
    providerName,
}) => {
    const [taskLocation, setTaskLocation] = useState<string>('');
    const [address, setAddress] = useState<string>('');

    useEffect(() => {
        if (getLocalStorage('requestSkill')) {
            const requestSkillObj = getLocalStorage('requestSkill');
            setTaskLocation(requestSkillObj.location);
            setAddress(
                locationStr === requestSkillObj.location
                    ? ''
                    : requestSkillObj.location
            );
        }
    }, []);

    const handleAddress = (address) => {
        setAddress(address);
        setTaskLocation(address);
    };

    return (
        <IonModal isOpen={modalStatus}>
            <IonContent>
                <IonGrid>
                    <IonRow className='taskLocation__header'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='taskLocation__header--container'
                        >
                            <Strokes top={true} />
                            <IonCol
                                sizeXs='12'
                                sizeSm='12'
                                sizeMd='12'
                                className='reqTaskNav__btns--wrap'
                            >
                                <IonButton
                                    onClick={handleModal}
                                    className='reqTaskNav__btn'
                                    fill='clear'
                                    color='ion-primary'
                                >
                                    <IonIcon
                                        className='reqTaskNav__btn--icon'
                                        icon={arrowBackOutline}
                                    />
                                </IonButton>
                            </IonCol>
                            <UserImg providerUid={provider} />
                        </IonCol>
                    </IonRow>
                    {/* text content */}
                    <IonRow className='taskLocation__textContent'>
                        <IonCol
                            className='taskLocation__textContent--container'
                            sizeSm='12'
                            sizeXs='12'
                        >
                            <h1 className='taskLocation__textContent--title'>
                                Choose a <span>location</span>
                            </h1>
                            <p className='taskLocation__textContent--text'>
                                Select the provided location, or enter a custom
                                location of your own.
                            </p>
                        </IonCol>
                    </IonRow>
                    {/* locations selection */}
                    <IonGrid className='taskLocation__form--container'>
                        <IonRow className='taskLocation__form--row'>
                            <p className='taskLocation__form--row--title'>
                                Provided Location
                            </p>
                            <IonRow className='reqTaskLoc__col--wrap'>
                                <TaskLocationItem
                                    taskLocation={taskLocation}
                                    setTaskLocation={setTaskLocation}
                                    locationStr={locationStr}
                                    providerName={providerName}
                                />
                            </IonRow>
                            {/* section divider */}
                            <IonRow className='taskLocation__typeDivider'>
                                <IonCol
                                    sizeXs='12'
                                    sizeSm='12'
                                    className='taskLocation__typeDivider--container'
                                >
                                    <p className='taskLocation__typeDivider__text'>
                                        <span className='taskLocation__typeDivider__text--span'>
                                            or
                                        </span>
                                    </p>
                                </IonCol>
                            </IonRow>
                            <IonCol
                                className='taskLocation__form--input--wrap'
                                sizeXs='12'
                                sizeSm='12'
                            >
                                <IonLabel className='taskLocation__form--label'>
                                    Set Specific Location
                                </IonLabel>
                            </IonCol>
                            {/* google places */}
                            <PlacesAutocomplete
                                value={address}
                                onChange={handleAddress}
                                shouldFetchSuggestions={address.length > 3}
                            >
                                {({
                                    getInputProps,
                                    suggestions,
                                    getSuggestionItemProps,
                                    loading,
                                }) => (
                                    <IonCol
                                        className='taskLocation__form--input--wrap'
                                        sizeXs='12'
                                        sizeSm='12'
                                    >
                                        <input
                                            onClick={
                                                address.length === 0
                                                    ? () => setTaskLocation('')
                                                    : null
                                            }
                                            className='taskLocation__form--input'
                                            placeholder='Enter address here...'
                                            value={address}
                                            type='text'
                                            {...getInputProps({
                                                placeholder:
                                                    'Enter address here...',
                                                className:
                                                    'location-search-input taskLocation__form--input',
                                            })}
                                        />
                                        {suggestions.length > 0 && (
                                            <div className='autocomplete__dropdown--container'>
                                                {loading && (
                                                    <div>Loading...</div>
                                                )}
                                                {suggestions.map(
                                                    (suggestion, index) => {
                                                        const className =
                                                            'autocomplete__dropdown--item';
                                                        return (
                                                            <div
                                                                {...getSuggestionItemProps(
                                                                    suggestion,
                                                                    {
                                                                        className,
                                                                        key: index,
                                                                    }
                                                                )}
                                                            >
                                                                <p className='autocomplete__dropdown--item--text'>
                                                                    {
                                                                        suggestion.description
                                                                    }
                                                                </p>
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </div>
                                        )}
                                    </IonCol>
                                )}
                            </PlacesAutocomplete>
                        </IonRow>
                    </IonGrid>
                    {/* btn */}
                    <IonRow className='taskLocation__btn--container'>
                        <IonCol
                            className='taskLocation__btn--wrap'
                            sizeXs='12'
                            sizeSm='12'
                        >
                            <IonButton
                                fill='clear'
                                onClick={() => {
                                    handleModal();
                                    handleLocation(taskLocation);
                                }}
                                disabled={taskLocation.length === 0}
                                color='ion-primary'
                                className='createTask__btn'
                            >
                                Confirm Location
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonModal>
    );
};

export default TaskLocation;
