import React, { useState } from 'react';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';
// import { GRAPHQL_URL } from './configs';
import MACRO from './MACROS.json';
import { yingInfo } from './utils/debug/YingDebug';

export const GraphQLProvider = ({ children, token }) => {
    // const { user, loading, isAuthenticated, getTokenSilently } = useAuth0();
    // const { user, loading, isAuthenticated } = useAuth();

    const api_uri = process.env.REACT_APP_GRAPHQL_URI
        ? process.env.REACT_APP_GRAPHQL_URI
        : MACRO.graphql_uri;
    // const api_uri = MACRO.graphql_uri_local

    const client = new ApolloClient({
        uri: api_uri, // localhost
        // uri: 'http://192.168.0.14:4000/graphql', // emulator
        request: (operation) => {
            if (token) {
                operation.setContext({
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                });
            } else {
                // deal with not having a token?
            }
        },
    });

    return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
