import React, { useState, useEffect } from 'react';
import {
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonPage,
    IonRow,
    IonSpinner,
    IonIcon,
    IonButton,
} from '@ionic/react';
import strokes from '../../assets/color-background.svg';
import { useAuth } from 'context/firebase/authContext';
import RequestTaskTabs from '../RequestSkill/UI/RequestTaskTabs';
import UserImg from '../RequestSkill/UserImg/UserImg';
import balanceToken from '../../assets/balance-token.png';
import Dollar from '../../assets/dollar.png';
import { PROFILE_JOBS_POSTED } from '../../GraphQL/Profile/PROFILE_JOBS_POSTED/PROFILE_JOBS_POSTED';
import {
    GetProfileJobsPosted,
    GetProfileJobsPostedVariables,
} from '../../GraphQL/__generated__/GetProfileJobsPosted';
import { useLazyQuery } from '@apollo/react-hooks';
import TransactionCard from '../../components/TransactionCard/TransactionCard';
import { getLocalStorage } from '../../helpers/localStorage/localStorageHelper';
import { GetOrgTransactions } from '../../GraphQL/organization/GET_ORG_TRANSACTIONS/GET_ORG_TRANSACTIONS';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import { useHistory } from 'react-router-dom';
import './TransactionHistory.css';
import { GetStripeAccountBalance } from '../Settings/StripeSettings/StripeHelpers';
import { formatAmount } from '../../utils/formatFiat';
import { GetCurrentBalance } from '../../GraphQL/StripeConnect/StripeConnect';
import { GetBalanceTokens } from '../../utils/getBalanceTokens';
import { arrowForwardOutline } from 'ionicons/icons';
import { GetStripeTransfer } from '../../GraphQL/StripeConnect/StripeConnect';

import { GET_USER_PARTY_INFO } from 'GraphQL/parties/GET_USER_PARTY_INFO';
import {
    GetUserPartyInfo,
    GetUserPartyInfoVariables,
} from 'GraphQL/__generated__/GetUserPartyInfo';
import { USER_PROFILE_BY_UID } from 'GraphQL/Profile/UserProfile/USER_PROFILE_BY_UID';
import {
    UserProfile_byID,
    UserProfile_byIDVariables,
} from 'GraphQL/__generated__/UserProfile_byID';
import { useQuery } from '@apollo/react-hooks';

const TransactionHistory: React.FC = () => {
    const history = useHistory();
    const { currentUser } = useAuth();
    const accountBalance = GetStripeAccountBalance();
    const balanceExists = !!accountBalance;

    // Get token balance
    const [receiverId, receiverIdData] = useState(0);
    const {
        data: dataReceiverOne,
        error: something,
        loading: stillLoading,
    } = useQuery<UserProfile_byID, UserProfile_byIDVariables>(
        USER_PROFILE_BY_UID,
        {
            variables: { subId: currentUser?.uid },
            fetchPolicy: 'no-cache',
        }
    );
    const { data: dataReceiverTwo, loading: receiverloading } = useQuery<
        GetUserPartyInfo,
        GetUserPartyInfoVariables
    >(GET_USER_PARTY_INFO, {
        skip: !dataReceiverOne,
        variables: { userId: dataReceiverOne && dataReceiverOne?.profile.id },
    });

    useEffect(() => {
        dataReceiverTwo?.showAllParties.map((thing) => {
            if (thing.bankAccountrelation[0].accountType == 'BALANCE') {
                receiverIdData(thing.bankAccountrelation[0].balance);
            }
        });
    });

    // Controls tab switch for transaction type token vs. cash
    const [showCash, setShowCash] = useState<boolean>(false);

    // Gets ingoing/outgoing participation requests, and skill requests
    // Todo: Implement pagination
    const [
        getProfileTasks,
        {
            data: profileTaskData,
            error: profileDataError,
            loading: profileDataLoading,
        },
    ] = useLazyQuery<GetProfileJobsPosted, GetProfileJobsPostedVariables>(
        PROFILE_JOBS_POSTED
    );

    // Gets group tasks if currently is signed in as group admin
    const isGroup: boolean = getLocalStorage('currentAdminGroupId') > 0;
    const [
        getGroupTasks,
        {
            data: groupTaskData,
            error: groupDataError,
            loading: groupDataLoading,
        },
    ] = useLazyQuery(GetOrgTransactions);

    //  Get the stripe balance if signed in as individual
    const [
        getStripeBalance,
        { data: stripeData, error: stripeError, loading: stripeLoading },
    ] = useLazyQuery(GetCurrentBalance);

    // Controls Error Message output, and extended error message
    const [errorCode, setErrorCode] = useState<string>('');
    const [extendedError, setExtendedError] = useState<string>('');

    // Array of transactions used to control type of transactions displayed
    // Admin vs. Individual
    const [transactionHistory, setTransactionHistory] = useState<Array<any>>(
        []
    );

    const [stripeLoaded, setStripeLoaded] = useState<Boolean>(false);
    //  Sort tasks based on last updated time
    const sortTasks = (transA, transB): any => {
        const timeOne = new Date(transA.lastUpdatedAt);
        const timeTwo = new Date(transB.lastUpdatedAt);

        if (timeOne < timeTwo) return 1;
        else if (timeOne > timeTwo) return -1;
        else return 0;
    };
    //  Filters, sorts, and switches transactions
    const filterAndSwitch = (tasks) => {
        if (tasks?.profile) {
            const allTransactions = [
                ...tasks?.profile?.incomingParticipationRequests,
                ...tasks?.profile?.jobsPosted,
                ...tasks?.profile?.outgoingParticipationRequests,
                ...tasks?.profile?.upcomingJobsServe,
                ...tasks?.profile?.upcomingJobsReceive,
            ];
            const filteredTransactions = allTransactions.filter(
                (transaction) => {
                    return (
                        transaction.status == 'COMPLETED' ||
                        transaction.Status == 'COMPLETED'
                    );
                }
            );
            const sortedTransactions = filteredTransactions.sort(sortTasks);
            setTransactionHistory(sortedTransactions);
        } else if (tasks?.getGroupTasks) {
            const allTransactions = [...tasks?.getGroupTasks?.groupTasks];
            const filteredTransactions = allTransactions.filter(
                (transaction) => {
                    return transaction.status == 'COMPLETED';
                }
            );
            const sortedTransactions = filteredTransactions.sort(sortTasks);
            setTransactionHistory(sortedTransactions);
        }
    };

    //  Get the stripe balance for user if they are an individual
    useEffect(() => {
        if (!stripeData && !stripeLoading && !isGroup)
            getStripeBalance({
                variables: {
                    uid: currentUser?.uid,
                },
            });
    }, [isGroup]);

    //  Gets task data for either group or individual
    useEffect(() => {
        if (
            transactionHistory?.length != 0 ||
            groupDataLoading ||
            profileDataLoading
        )
            return;
        if (isGroup) {
            getGroupTasks({
                variables: {
                    //todo: change this to current group id
                    groupId: getLocalStorage('currentAdminGroupId'),
                },
            });
        } else {
            getProfileTasks({
                variables: {
                    subId: currentUser.uid,
                },
            });
        }
    }, [isGroup]);

    //  Handles return of data and sets the relevant task
    useEffect(() => {
        if (!profileTaskData && !groupTaskData) return;
        if (profileTaskData) {
            filterAndSwitch(profileTaskData);
        } else if (groupTaskData) {
            filterAndSwitch(groupTaskData);
        } else {
            setErrorCode('2.01');
            setExtendedError(
                JSON.stringify(profileDataError || groupDataError)
            );
        }
    }, [profileTaskData, groupTaskData]);

    return (
        <IonPage>
            <IonContent>
                <IonGrid>
                    <IonRow className='settings__header'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='settings__header--container'
                        >
                            <IonImg
                                className='settings__header--strokes'
                                src={strokes}
                                alt='strokes'
                            />
                            <RequestTaskTabs
                                prevPage={`/home`}
                                disablePrevStep={false}
                            />
                            {isGroup ? (
                                <IonImg
                                    className='reqTaskAvl__header--img'
                                    src={
                                        groupTaskData?.getGroupTasks?.orgPicture
                                    }
                                    alt='user'
                                />
                            ) : (
                                <UserImg providerUid={currentUser.uid} />
                            )}
                        </IonCol>
                    </IonRow>
                    {/* title */}
                    <IonRow className='settings__textContent'>
                        <IonCol
                            className='settings__textContent--container'
                            sizeSm='12'
                            sizeXs='12'
                        >
                            <h1 className='settings__textContent--title'>
                                <span>Transaction</span> History
                            </h1>
                        </IonCol>
                    </IonRow>
                    <IonCol
                        className='currencyDisplay__container'
                        sizeXs='12'
                        sizeSm='12'
                    >
                        <IonCol
                            className='currencyDisplay__col--mid seg'
                            sizeXs='12'
                            sizeSm='12'
                        >
                            <IonCol
                                onClick={() => setShowCash(false)}
                                className={`currencyDisplay__currency--btn ${
                                    !showCash
                                        ? 'currencyDisplay__currency--btn--active'
                                        : null
                                }`}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '60px',
                                }}
                            >
                                <IonRow>
                                    <IonImg
                                        className='currencyDisplay__img'
                                        src={balanceToken}
                                        alt='balance token'
                                    />
                                </IonRow>
                                <IonRow>
                                    <p className='balance-heading'>
                                        {receiverId
                                            ? (receiverId / 60).toFixed(2)
                                            : 0}
                                    </p>
                                </IonRow>
                            </IonCol>
                            <IonCol
                                onClick={() => setShowCash(true)}
                                className={`currencyDisplay__currency--btn ${
                                    showCash
                                        ? 'currencyDisplay__currency--btn--active'
                                        : null
                                }`}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '60px',
                                }}
                            >
                                <IonRow>
                                    <IonImg
                                        className='currencyDisplay__img-large'
                                        src={Dollar}
                                        alt='dollar sign'
                                    />
                                </IonRow>
                                <IonRow></IonRow>
                            </IonCol>
                        </IonCol>
                    </IonCol>
                    <IonRow>
                        {showCash ? (
                            <p className='settings__textContent--title pending'>
                                <span>Pending</span> transfer:
                                {!balanceExists ||
                                accountBalance.pending == null
                                    ? 'N/A'
                                    : ' ' +
                                      formatAmount(
                                          accountBalance?.pending[0].amount.toFixed(
                                              2
                                          )
                                      )}
                            </p>
                        ) : (
                            ' '
                        )}
                    </IonRow>
                    {/* If there is at least one transaction, render the list */}
                    {transactionHistory?.length > 0 &&
                        transactionHistory.map((transaction, index) => (
                            <TransactionCard
                                showCash={showCash}
                                transaction={transaction}
                                isGroup={isGroup}
                                key={index}
                            />
                        ))}

                    {/* If data is being loaded, show the spinner */}
                    {(groupDataLoading || profileDataLoading) && (
                        <div className='schedule__spinner--wrap modified-spinner'>
                            <IonSpinner
                                className='schedule__spinner'
                                name='crescent'
                            />
                        </div>
                    )}
                    {transactionHistory?.length == 0 &&
                        (profileTaskData || groupTaskData) &&
                        !profileDataLoading &&
                        !groupDataLoading && (
                            <IonRow>
                                <IonButton
                                    fill='clear'
                                    onClick={() => history.push('/create-task')}
                                    color='ion-primary'
                                    className='homeInterface__btn'
                                    style={{
                                        margin: 'auto',
                                        marginTop: '35%',
                                    }}
                                >
                                    Create A Task{' '}
                                    <IonIcon
                                        icon={arrowForwardOutline}
                                        slot='end'
                                    />
                                </IonButton>
                                <p className='empty-message'>
                                    Your transaction history is empty, try
                                    creating a task to start.{' '}
                                </p>
                            </IonRow>
                        )}
                    {/* Shows error message, if there is one */}
                    {errorCode && (
                        <ErrorMessage
                            errorCode={errorCode}
                            extendedError={extendedError}
                        />
                    )}
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};
export default TransactionHistory;
