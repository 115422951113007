// importing modules
import React, { useState } from 'react';
import moment from 'moment-timezone';
import { useParams, useHistory } from 'react-router-dom';
// importing components
import {
    IonButton,
    IonCol,
    IonImg,
    IonRow,
    IonAlert,
    IonIcon,
    IonGrid,
} from '@ionic/react';
import { arrowDownOutline, arrowUpOutline } from 'ionicons/icons';
// importing utils
import { getLocalStorage } from '../../../helpers/localStorage/localStorageHelper';
// importing types & graphql
import { useMutation } from '@apollo/react-hooks';
import { GET_JOB_POST_BY_ID } from '../../../GraphQL/JobPost/GET_JOB_POST_BY_ID/GET_JOB_POST_BY_ID';
import {
    GetJobPostById_jobPost_skillsNeeded,
    GetJobPostById_jobPost_skillsNeeded_participationRequests,
} from '../../../GraphQL/__generated__/GetJobPostById';
import { UPDATE_ONE_PARTICIPATION_REQUEST_STATUS } from '../../../GraphQL/ParticipationRequest/UPDATE_ONE_PARTICIPATION_REQUEST_STATUS/UPDATE_ONE_PARTICIPATION_REQUEST_STATUS';
import {
    UpdateOneParticipationRequestStatus,
    UpdateOneParticipationRequestStatusVariables,
} from '../../../GraphQL/__generated__/UpdateOneParticipationRequestStatus';
import { UPDATE_ONE_PARTICIPATION_REQUEST_STATUS_NON_ADMIN } from '../../../GraphQL/ParticipationRequest/UPDATE_ONE_PARTICIPATION_REQUEST_STATUS_NON_ADMIN/UPDATE_ONE_PARTICIPATION_REQUEST_STATUS_NON_ADMIN';
import {
    UpdateOneParticipationRequestStatusNonAdmin,
    UpdateOneParticipationRequestStatusNonAdminVariables,
} from '../../../GraphQL/__generated__/UpdateOneParticipationRequestStatusNonAdmin';
import { Status } from '../../../GraphQL/__generated__/globalTypes';

const ParticipationRequestItem: React.FC<{
    skillNeeded: GetJobPostById_jobPost_skillsNeeded;
}> = ({ skillNeeded }) => {
    const history = useHistory();
    const { taskId } = useParams<{ taskId: string }>();
    const [showAcceptAlert, setShowAcceptAlert] = useState<boolean>();
    const [showDeclineAlert, setShowDeclineAlert] = useState<boolean>();

    const [participationRequest, participationRequestDatas] = useMutation<
        UpdateOneParticipationRequestStatus,
        UpdateOneParticipationRequestStatusVariables
    >(UPDATE_ONE_PARTICIPATION_REQUEST_STATUS);

    const [participationRequestNonAdmin, participationRequestNonAdminDatas] =
        useMutation<
            UpdateOneParticipationRequestStatusNonAdmin,
            UpdateOneParticipationRequestStatusNonAdminVariables
        >(UPDATE_ONE_PARTICIPATION_REQUEST_STATUS_NON_ADMIN);

    const handleParticipationRequest = (
        participationRequestId: number,
        status: Status
    ) => {
        if (Number(getLocalStorage('currentAdminGroupId')) !== 0) {
            participationRequest({
                variables: {
                    id: participationRequestId,
                    status: status,
                    adminId: 1,
                    groupId: 2,
                },
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_JOB_POST_BY_ID,
                        variables: {
                            jobPostId: parseInt(taskId),
                        },
                    },
                ],
            }).catch((err) => console.error(err));
        } else {
            participationRequestNonAdmin({
                variables: {
                    id: participationRequestId,
                    status: status,
                },
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_JOB_POST_BY_ID,
                        variables: {
                            jobPostId: parseInt(taskId),
                        },
                    },
                ],
            }).catch((err) => console.error(err));
        }
    };

    const pendingRequests: GetJobPostById_jobPost_skillsNeeded_participationRequests[] =
        skillNeeded.participationRequests.filter(
            (p) => p.status === Status.REQUESTED
        );
    const participantsAccepted: GetJobPostById_jobPost_skillsNeeded_participationRequests[] =
        skillNeeded.participationRequests.filter(
            (p) =>
                p.status === Status.ACCEPTED ||
                p.status === Status.RECIPIENT_HAS_PROPOSED_OFFER ||
                p.status === Status.COMPLETED
        );
    const isFull =
        participantsAccepted.length === skillNeeded.peopleNeededCount;

    return (
        <IonCol
            // className='manageReqItem__container'
            sizeSm='11'
            sizeXs='11'
            key={skillNeeded.id}
        >
            <IonCol
                sizeSm='12'
                sizeXs='12'
                className='manageReqItem__tag--container'
            >
                <h3 className='manageReqItem__tag--name'>{skillNeeded.name}</h3>
                <div className='manageReqItem__tag--wrap'>
                    <h3 className='manageReqItem__tag--name'>
                        {participantsAccepted.length}/
                        {skillNeeded.peopleNeededCount} roles filled
                    </h3>
                </div>
            </IonCol>
            {pendingRequests.length > 0 && (
                <IonCol sizeSm='12' sizeXs='12'>
                    <h1 className='manageReqItem__title'>
                        Participation Requests
                    </h1>
                </IonCol>
            )}
            {pendingRequests.length > 0 ? (
                pendingRequests.map((participationReq) => {
                    const requestedAt = moment(participationReq.createdAt).tz(
                        getLocalStorage('tz'),
                        false
                    );
                    return (
                        <IonCol
                            key={participationReq.id}
                            className='manageReqItem'
                            sizeSm='12'
                            sizeXs='12'
                        >
                            <IonAlert
                                isOpen={showDeclineAlert}
                                onDidDismiss={() => setShowDeclineAlert(false)}
                                header={'Participation Request'}
                                message={
                                    'Are you sure you want to decline this participation request?'
                                }
                                buttons={[
                                    {
                                        text: 'Cancel',
                                        role: 'cancel',
                                        handler: () =>
                                            setShowDeclineAlert(false),
                                    },
                                    {
                                        text: 'Decline',
                                        handler: () =>
                                            handleParticipationRequest(
                                                participationReq.id,
                                                Status.DENIED
                                            ),
                                    },
                                ]}
                            />
                            <IonAlert
                                isOpen={showAcceptAlert}
                                onDidDismiss={() => setShowAcceptAlert(false)}
                                header={'Participation Request'}
                                message={
                                    'Are you sure you want to accept this participation request?'
                                }
                                buttons={[
                                    {
                                        text: 'Cancel',
                                        role: 'cancel',
                                        handler: () =>
                                            setShowAcceptAlert(false),
                                    },
                                    {
                                        text: 'Accept',
                                        handler: () =>
                                            handleParticipationRequest(
                                                participationReq.id,
                                                Status.ACCEPTED
                                            ),
                                    },
                                ]}
                            />
                            <IonCol
                                sizeXs='12'
                                sizeSm='12'
                                className='manageReqItem__info'
                            >
                                <IonCol
                                    sizeXl='3'
                                    sizeLg='3'
                                    sizeMd='3'
                                    sizeXs='3'
                                    sizeSm='3'
                                >
                                    <div className='manageReqItem__img--wrap'>
                                        <IonImg
                                            className='manageReqItem__img'
                                            src={
                                                participationReq.provider
                                                    .profilePicture
                                            }
                                        />
                                    </div>
                                </IonCol>
                                <IonCol
                                    className='manageReqItem__info--wrap'
                                    sizeXs='9'
                                    sizeSm='9'
                                >
                                    <h3 className='manageReqItem__info--name'>
                                        {participationReq.provider.firstName}{' '}
                                        {participationReq.provider.lastName}
                                    </h3>
                                    <p className='manageReqItem__info--skill'>
                                        {skillNeeded.name}{' '}
                                        <span>{requestedAt.fromNow()}</span>
                                    </p>
                                </IonCol>
                            </IonCol>
                            <IonCol
                                sizeXs='12'
                                sizeSm='12'
                                className='manageReqItem__btn--wrap'
                            >
                                <IonButton
                                    onClick={() => setShowAcceptAlert(true)}
                                    fill='clear'
                                    color='ion-primary'
                                    className='manageReqItem__btn--accept'
                                >
                                    Accept
                                </IonButton>
                                <IonButton
                                    onClick={() => setShowDeclineAlert(true)}
                                    fill='clear'
                                    color='ion-primary'
                                    className='manageReqItem__btn--cancel'
                                >
                                    Next Time
                                </IonButton>
                            </IonCol>
                        </IonCol>
                    );
                })
            ) : (
                <IonCol sizeSm='10' sizeXs='10'>
                    <h1 className='manageReq__users--title'>
                        Participation Requests
                    </h1>
                    <IonCol
                        sizeXs='12'
                        sizeSm='12'
                        className='manageReqItem__fallback'
                    >
                        <p className='manageReqItem__fallback--text'>
                            Participation requests for {skillNeeded.name} will
                            appear here.
                        </p>
                    </IonCol>
                </IonCol>
            )}
            {
                <IonGrid className='manageReq__users--container'>
                    <IonRow className='manageReq__users'>
                        <IonCol sizeSm='12' sizeXs='12'>
                            <h1 className='manageReq__users--title'>
                                Current Participants
                            </h1>
                        </IonCol>
                        {participantsAccepted.length > 0 ? (
                            participantsAccepted.map((participantReq) => {
                                return (
                                    <button
                                        style={{
                                            backgroundColor: 'transparent',
                                        }}
                                        onClick={() =>
                                            history.push(
                                                `/user/${participantReq.provider.uid}`
                                            )
                                        }
                                    >
                                        <div
                                            key={participantReq.provider.id}
                                            className='manageReq__user'
                                        >
                                            <IonImg
                                                onClick={() =>
                                                    history.push(
                                                        `/user/${participantReq.provider.uid}`
                                                    )
                                                }
                                                className='manageReq__user--img'
                                                src={
                                                    participantReq.provider
                                                        .profilePicture
                                                }
                                                alt='user'
                                            />
                                            <p className='manageReq__user--name'>
                                                {
                                                    participantReq.provider
                                                        .firstName
                                                }
                                            </p>
                                        </div>
                                    </button>
                                );
                            })
                        ) : (
                            <IonCol
                                sizeXs='12'
                                sizeSm='12'
                                className='manageReq__users--fallback'
                            >
                                <p className='manageReq__users--fallback--text'>
                                    Accepted participants for {skillNeeded.name}{' '}
                                    will appear here.
                                </p>
                            </IonCol>
                        )}
                    </IonRow>
                </IonGrid>
            }
        </IonCol>
    );
};

export default ParticipationRequestItem;
