// importing modules
import React from 'react';
import { useHistory } from 'react-router-dom';
// importing components
import { IonButton, IonCol, IonIcon } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import BackButton from 'components/BackButton';
// importing styles
import './FlowNavigator.css';

const FlowNavigation: React.FC<{
    prevPage: string;
    disablePrevStep: boolean;
}> = ({ disablePrevStep, prevPage }) => {
    const history = useHistory();
    return (
        <IonCol
            sizeXs='12'
            sizeSm='12'
            sizeMd='12'
            className='flowNav__btns--wrap'
        >
            {disablePrevStep ? <p></p> : <BackButton color='white' />}
            <IonButton
                className='flowNav__btn'
                onClick={() => history.push('/home')}
                color='ion-primary'
                fill='clear'
            >
                <IonIcon className='flowNav__btn--icon' icon={closeOutline} />
            </IonButton>
        </IonCol>
    );
};

export default FlowNavigation;
