// importing modules
import React from 'react';
import { useParams } from 'react-router-dom';
// importing components
import { IonImg } from '@ionic/react';
import Loading from 'components/status/Loading';
// importing graphql
import { useQuery } from '@apollo/react-hooks';
import { GET_ORG_BY_ID } from 'GraphQL/organization/GET_ORG_BY_ID/GET_ORG_BY_ID';
import {
    GetOrgById,
    GetOrgByIdVariables,
} from 'GraphQL/__generated__/GetOrgById';
import {
    getLocalStorage,
    setLocalStorage,
    updateLocalStorage,
} from 'helpers/localStorage/localStorageHelper';

export default () => {
    let { groupId } = useParams<{ groupId: string }>();
    if (getLocalStorage('currentAdminGroupId') !== 0) {
        groupId = getLocalStorage('currentAdminGroupId');
    }

    const { data, loading } = useQuery<GetOrgById, GetOrgByIdVariables>(
        GET_ORG_BY_ID,
        {
            variables: {
                id: parseInt(groupId),
            },
        }
    );

    if (loading) {
        return <Loading />;
    }

    return (
        <IonImg
            className='createTask__header--img'
            src={
                data.organization.orgPicture === null
                    ? 'https://is3-ssl.mzstatic.com/image/thumb/Purple123/v4/3e/62/e2/3e62e239-f433-bc84-3775-21af60499ec7/source/512x512bb.jpg'
                    : data.organization.orgPicture
            }
            alt='group'
        />
    );
};
