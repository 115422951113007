import React from 'react';
import { IonCol, IonImg, IonRow } from '@ionic/react';
import './GreenStrokesHeader.css';
import { NamedPictureItem } from '../PictureItem/PictureItem';
import {
    setLocalStorage,
    getLocalStorage,
} from '../../helpers/localStorage/localStorageHelper';
import Strokes from 'components/Strokes';
const GreenStrokesHeader = ({
    profileImg,
    isHome = false,
}: {
    profileImg: string;
    isHome?: boolean;
}) => {
    return (
        <IonRow
            className={`greenStrokesHeader ${
                isHome ? 'greenStrokesHeader__Home' : ''
            }`}
        >
            {/*Ion row has greenStrokesHeader class...If it is home it will take the new css class in */}
            <IonCol
                sizeXs='12'
                sizeSm='12'
                className='greenStrokesHeader__col--top'
            >
                <Strokes top={true} />
            </IonCol>
            {getLocalStorage('currentAdminGroupId') == 0 ? (
                <NamedPictureItem
                    profileImg={profileImg}
                    // firstName={firstName}
                    // lastName={lastName}
                />
            ) : (
                <NamedPictureItem
                    profileImg={profileImg}
                    // firstName={'Group'}
                    // lastName={'Admin'}
                />
            )}
        </IonRow>
    );
};

export default GreenStrokesHeader;
