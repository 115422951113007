import React from 'react';
import { IonCol } from '@ionic/react';

const TaskLocationItem: React.FC<{
    taskLocation: string;
    providerName: string;
    setTaskLocation: Function;
    locationStr: string;
}> = ({ taskLocation, providerName, setTaskLocation, locationStr }) => {
    let locationItem;

    if (locationStr.length > 5) {
        locationItem = (
            <IonCol
                onClick={() => setTaskLocation(locationStr)}
                className={
                    taskLocation === locationStr
                        ? 'taskLocation__col taskLocation__col--active'
                        : 'taskLocation__col'
                }
                sizeXs='12'
                sizeSm='12'
            >
                <IonCol
                    sizeXs='12'
                    sizeSm='12'
                    className={
                        taskLocation === locationStr
                            ? 'taskLocation__content taskLocation__content--active'
                            : 'taskLocation__content'
                    }
                >
                    <p
                        className={
                            taskLocation === locationStr
                                ? 'taskLocation__content--place taskLocation__content--place--active'
                                : 'taskLocation__content--place'
                        }
                    >
                        {providerName}'s Set Location
                    </p>
                    <p
                        className={
                            taskLocation === locationStr
                                ? 'taskLocation__content--address taskLocation__content--address--active'
                                : 'taskLocation__content--address'
                        }
                    >
                        {locationStr}
                    </p>
                </IonCol>
            </IonCol>
        );
    } else {
        locationItem = (
            <IonCol className='taskLocation__col' sizeXs='12' sizeSm='12'>
                <IonCol
                    sizeXs='12'
                    sizeSm='12'
                    className='taskLocation__content'
                >
                    <p className='taskLocation__content--place'>
                        {providerName} did not set a location
                    </p>
                    <p className='taskLocation__content--address'>
                        Please provide an address of your own below
                    </p>
                </IonCol>
            </IonCol>
        );
    }
    return <React.Fragment>{locationItem}</React.Fragment>;
};

export default TaskLocationItem;
