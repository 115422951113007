// importing modules
import React from 'react';
import { useHistory } from 'react-router-dom';
// importing components
import { IonCol } from '@ionic/react';
import BackButton from 'components/BackButton';
import CancelButton from 'components/CancelButton/CancelButton';
import {
    deleteLocalStorage,
    setLocalStorage,
} from '../../../helpers/localStorage/localStorageHelper';
// importing styles
import './RequestTaskTabs.css';

const RequestTaskTabs: React.FC<{
    disablePrevStep: boolean;
    prevPage: string;
}> = ({ disablePrevStep, prevPage }) => {
    const history = useHistory();
    return (
        <IonCol
            sizeXs='12'
            sizeSm='12'
            sizeMd='12'
            className='reqTaskNav__btns--wrap'
        >
            {disablePrevStep ? (
                <p></p>
            ) : (
                <span>
                    <BackButton color='white' />
                </span>
            )}
            <CancelButton />
        </IonCol>
    );
};

export default RequestTaskTabs;
