import React from 'react';
import moment from 'moment-timezone';
import { useParams, useHistory } from 'react-router-dom';
// importing components
import { IonCol, IonImg, IonRow, IonButton } from '@ionic/react';
// importing utilities
import { getLocalStorage } from '../../../helpers/localStorage/localStorageHelper';
// importing types
import { GetCompletedJobsById_completedJobs } from '../../../GraphQL/__generated__/GetCompletedJobsById';

const CompletedJobsList: React.FC<{
    completedTasks: GetCompletedJobsById_completedJobs[];
}> = ({ completedTasks }) => {
    const { userId } = useParams<{ userId: string }>();
    const history = useHistory();

    return (
        <IonRow className='completedTasksItem__row'>
            {completedTasks.length > 0 ? (
                completedTasks.map((job) => {
                    const profilePic =
                        job.recipient.id === parseInt(userId)
                            ? job.provider.profilePicture
                            : job.recipient.profilePicture;
                    const participantName =
                        job.recipient.id === parseInt(userId)
                            ? `${job.provider.firstName} ${job.provider.lastName}`
                            : `${job.recipient.firstName} ${job.recipient.lastName}`;
                    return (
                        <IonCol
                            key={job.id}
                            className='completedTasksItem'
                            sizeSm='11'
                            sizeXs='11'
                        >
                            <IonCol
                                sizeXs='12'
                                sizeSm='12'
                                className='completedTasksItem__info'
                            >
                                <IonCol
                                    sizeXl='3'
                                    sizeLg='3'
                                    sizeMd='3'
                                    sizeXs='3'
                                    sizeSm='3'
                                >
                                    <div className='completedTasksItem__img--wrap'>
                                        <IonImg
                                            className='completedTasksItem__img'
                                            src={profilePic}
                                        />
                                    </div>
                                </IonCol>
                                <IonCol
                                    className='completedTasksItem__info--wrap'
                                    sizeXs='9'
                                    sizeSm='9'
                                >
                                    <h3 className='completedTasksItem__info--name'>
                                        {job.Skill.name} w/ {participantName}
                                    </h3>
                                    <p className='completedTasksItem__info--date'>
                                        {moment(job.endTime)
                                            .tz(getLocalStorage('tz'), false)
                                            .format('dddd, MMMM Do YYYY')}
                                    </p>
                                </IonCol>
                            </IonCol>
                            <IonCol
                                sizeXs='12'
                                sizeSm='12'
                                className='completedTasksItem__btn--wrap'
                            >
                                <IonButton
                                    onClick={() =>
                                        history.push(`/skillRequest/${job.id}`)
                                    }
                                    fill='clear'
                                    color='ion-primary'
                                    className='completedTasksItem__btn--view'
                                >
                                    View Task
                                </IonButton>
                                <IonButton
                                    fill='clear'
                                    color='ion-primary'
                                    className='completedTasksItem__btn--chat'
                                >
                                    Enter Chatroom
                                </IonButton>
                            </IonCol>
                        </IonCol>
                    );
                })
            ) : (
                <p>You currently haven't completed any jobs!</p>
            )}
        </IonRow>
    );
};

export default CompletedJobsList;
