import React from 'react';
import { useHistory } from 'react-router-dom';
import { IonButton, IonCol, IonIcon, IonRow } from '@ionic/react';
import { arrowForwardOutline } from 'ionicons/icons';
import Linkify from 'react-linkify';
import './ProfileDetails.css';

const ProfileDetails: React.FC<{
    userBio: String;
    userLocation: String;
    uid: string;
}> = ({ userBio, userLocation, uid }) => {
    const history = useHistory();
    return (
        <IonRow className='profileDetails'>
            <IonCol
                sizeXs='12'
                sizeSm='12'
                className='profileDetails__edit--col--btn'
            >
                <IonButton
                    fill='clear'
                    onClick={() =>
                        history.push(`/user/${uid}/edit`, { edit: true })
                    }
                    expand='block'
                    className='profileDetails__edit--btn'
                    color='ion-primary'
                >
                    Edit Profile{' '}
                </IonButton>
            </IonCol>
            <IonRow className='profileDetails__row--bio'>
                <IonCol
                    sizeXs='11'
                    sizeSm='11'
                    className='profileDetails__col--bio'
                >
                    {/* <p className='profileDetails__location'>
                        <span>
                            <IonIcon icon={locationOutline} />
                        </span>
                        &nbsp;{userLocation}
                    </p> */}
                    <p className='profileDetails__bio'>
                        <Linkify>{userBio}</Linkify>
                    </p>
                </IonCol>
            </IonRow>
        </IonRow>
    );
};

export default ProfileDetails;
