// importing modules
import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
// importing components
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonIcon,
    IonImg,
    IonRow,
    IonPage,
} from '@ionic/react';
import GroupImg from '../UI/GroupImg/GroupImg';
import CreateTaskTabs from '../UI/CreateTaskTabs/CreateTaskTabs';
import TaskInviteMember from '../UI/MemberInviteItem/MemberInviteItem';
import CreateTaskFooter from '../../../components/FooterWithChildren/FooterWithChildren';
import Loading from '../../../components/status/Loading';
import Strokes from 'components/Strokes';
// importing assets
import { searchOutline, closeOutline } from 'ionicons/icons';
// importing utilities
import {
    getLocalStorage,
    deleteMemberInviteFromLocalStorage,
} from '../../../helpers/localStorage/localStorageHelper';
import filterAuthUserOut from '../../../utils/filterOutAuthUser';
// importing graphql utilities - types
import { useQuery } from '@apollo/react-hooks';
import { SKILL_POOL_FROM_ONE_ORG } from '../../../GraphQL/Skill/SKILL_POOL_FROM_ONE_ORG/SKILL_POOL_FROM_ONE_ORG';
import {
    SkillPoolFromOneOrg,
    SkillPoolFromOneOrgVariables,
    SkillPoolFromOneOrg_profilesFromOneOrg,
} from '../../../GraphQL/__generated__/SkillPoolFromOneOrg';
// importing styles
import './TaskInvite.css';
import { useAuth } from '../../../context/firebase/authContext';

const TaskInvite: React.FC<{ taskType: string }> = ({ taskType }) => {
    const history = useHistory();

    const { currentUser } = useAuth();

    const { groupId, groupCode } = useParams<{
        groupId: string;
        groupCode: string;
    }>();
    const [memberInvites, setMemberInvites] = useState<
        { name: string; uid: string }[]
    >([]);
    const [searchInput, setSearchInput] = useState<string>('');

    useEffect(() => {
        if (getLocalStorage('createTask')) {
            const taskInfo = getLocalStorage('createTask');
            setMemberInvites(taskInfo.memberInvites);
        } else {
            history.push('/home');
        }
    }, [history]);

    const { data, loading } = useQuery<
        SkillPoolFromOneOrg,
        SkillPoolFromOneOrgVariables
    >(SKILL_POOL_FROM_ONE_ORG, { variables: { groupCode: groupCode } });

    if (loading) {
        return <Loading />;
    }

    const handleRemoveInvite = (uid: string) => {
        deleteMemberInviteFromLocalStorage('groupTask', 'memberInvites', uid);
        setMemberInvites(memberInvites.filter((member) => member.uid !== uid));
    };

    let filteredMembers: SkillPoolFromOneOrg_profilesFromOneOrg[] = [];
    if (searchInput.length > 0 && !loading) {
        filteredMembers = data.profilesFromOneOrg.filter((member) =>
            `${member.firstName} ${member.lastName}`
                .toLowerCase()
                .includes(searchInput.toLowerCase())
        ); // filter users based on first name & last name
        filteredMembers = filterAuthUserOut(filteredMembers, currentUser.uid); // filters out the current authenticated user from the members lists
    } else {
        filteredMembers = [];
    }

    return (
        <IonPage>
            <IonContent>
                <IonGrid>
                    {/* taskInvite header */}
                    <IonRow className='taskInvite__header'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='taskInvite__header--container'
                        >
                            <Strokes top={true} />
                            <CreateTaskTabs disablePrevStep={false} />
                            <GroupImg />
                        </IonCol>
                    </IonRow>
                    {/* text content */}
                    <IonRow className='taskInvite__textContent'>
                        <IonCol
                            className='taskInvite__textContent--container'
                            sizeSm='12'
                            sizeXs='12'
                        >
                            <h1 className='taskInvite__textContent--title'>
                                Would you like to <span>invite someone</span>{' '}
                                from the group to participate?
                            </h1>
                            <p className='taskInvite__textContent--text'>
                                You can search for members by their name or
                                filter people by their skill set.
                            </p>
                        </IonCol>
                    </IonRow>
                    <IonRow className='skillSearch__item--container'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='skillSearch__item--col'
                        >
                            {memberInvites.length > 0
                                ? memberInvites.map((member) => (
                                      <div
                                          key={member.uid}
                                          onClick={() =>
                                              handleRemoveInvite(member.uid)
                                          }
                                          className='skillSearch__item--keyword'
                                      >
                                          {member.name}&nbsp;
                                          <IonIcon icon={closeOutline} />
                                      </div>
                                  ))
                                : null}
                        </IonCol>
                    </IonRow>
                    {/* user invite searchbar */}
                    <IonRow className='skillSelection__searchbar'>
                        <IonCol
                            sizeXs='12'
                            sizeSm='12'
                            className='skillSelection__searchbar--container'
                        >
                            <div className='skillSelection__searchbar--input--wrap'>
                                <IonIcon
                                    className='skillSelection__searchbar--input--icon'
                                    icon={searchOutline}
                                />
                                <input
                                    onChange={(event) =>
                                        setSearchInput(event.target.value)
                                    }
                                    value={searchInput}
                                    className='skillSelection__searchbar--input'
                                    type='text'
                                    placeholder='Search by name or skill'
                                />
                            </div>
                            <IonButton
                                fill='clear'
                                color='ion-primary'
                                className='onBio__skip--btn'
                                style={{ borderBottom: 'solid 1px #5f5eac' }}
                            >
                                Add All Members
                            </IonButton>
                        </IonCol>
                    </IonRow>
                    {/* filtered members list */}
                    {filteredMembers.length > 0 && (
                        <IonGrid>
                            <IonRow className='taskInvite__members--row'>
                                {filteredMembers.length > 0
                                    ? filteredMembers
                                          .splice(0, 5)
                                          .map((member) => {
                                              return (
                                                  <TaskInviteMember
                                                      key={member.id}
                                                      member={member}
                                                      memberInvites={
                                                          memberInvites
                                                      }
                                                      setMemberInvites={
                                                          setMemberInvites
                                                      }
                                                  />
                                              );
                                          })
                                    : null}
                            </IonRow>
                        </IonGrid>
                    )}
                </IonGrid>
            </IonContent>
            <CreateTaskFooter>
                <IonButton
                    id='invite-continue-btn'
                    fill='clear'
                    color='ion-primary'
                    className='footerWithChild__btn'
                    onClick={() =>
                        history.push(
                            `/create-task/${groupId}/${groupCode}/confirmation`
                        )
                    }
                >
                    Continue
                </IonButton>
            </CreateTaskFooter>
        </IonPage>
    );
};

export default TaskInvite;
