// importing modules
import React, { useState, useEffect } from 'react';
// imoporting components
import { IonCol, IonImg, IonIcon } from '@ionic/react';
import { star } from 'ionicons/icons';
// importing helpers
import {
    getLocalStorage,
    updateLocalStorage,
} from '../../../../helpers/localStorage/localStorageHelper';

const ParticipantReviewItem: React.FC<{
    firstName: string;
    lastName: string;
    profileImg: string;
    id: number;
    skill: string;
    review: string;
    setReview: Function;
}> = ({ id, firstName, lastName, profileImg, skill, review, setReview }) => {
    const [rt, setRt] = useState<number>(0);

    useEffect(() => {
        if (getLocalStorage('completeTaskViaRecipient')) {
            setRt(getLocalStorage('completeTaskViaRecipient').rating); // set the review in here for peer to peer
            setReview(getLocalStorage('completeTaskViaRecipient').review);
        }
    }, []);

    const handleParticipantReview = () => {
        updateLocalStorage('completeTaskViaRecipient', 'review', review);
    };

    return (
        <IonCol className='taskPartcItem' sizeSm='12' sizeXs='12'>
            <IonCol sizeXs='12' sizeSm='12' className='taskPartcItem__review'>
                <IonCol sizeXl='3' sizeLg='3' sizeMd='3' sizeXs='3' sizeSm='3'>
                    <div className='taskPartcItem__img--wrap'>
                        <IonImg
                            className='taskPartcItem__img'
                            src={profileImg}
                        />
                    </div>
                </IonCol>
                <IonCol
                    className='taskPartcItem__info--wrap'
                    sizeXs='9'
                    sizeSm='9'
                >
                    <h3 className='taskPartcItem__info--name'>
                        {firstName} {lastName}
                    </h3>
                    <p className='taskPartcItem__info--cred'>{skill}</p>
                    <IonCol className='taskPartcStars__container'>
                        {[...Array(5)].map((s, i) => {
                            const ratingVal = i + 1;
                            return (
                                <label key={i}>
                                    <input
                                        type='radio'
                                        name='rating'
                                        value={ratingVal}
                                    />
                                    <IonIcon
                                        style={{
                                            color:
                                                ratingVal <= rt
                                                    ? '#f7b500'
                                                    : '#d3d3d3',
                                        }}
                                        icon={star}
                                        className='taskPartcStars__icon'
                                    />
                                </label>
                            );
                        })}
                    </IonCol>
                </IonCol>
            </IonCol>
            <IonCol
                className='taskPartcReview__textarea--wrap'
                sizeXs='12'
                sizeSm='12'
            >
                <textarea
                    onChange={(e) => setReview(e.target.value)}
                    onFocus={() =>
                        console.log(
                            `textarea for participant -> ${id} is focused`
                        )
                    }
                    onBlur={handleParticipantReview}
                    className='taskPartcReview__textarea'
                    placeholder='Say something nice..'
                    required
                    value={review}
                ></textarea>
            </IonCol>
        </IonCol>
    );
};

export default ParticipantReviewItem;

// const ParticipantReviewItem:React.FC<{
//     id: number,
//     firstName: string,
//     lastName: string,
//     credits: number,
//     profileImg: string,
//     skill: string,
//     rating: number
// }> = ({ id, firstName, lastName, profileImg, rating }) => {
//     const [ rt, setRt ] = useState<number>(rating);
//     const [ review, setReview ] = useState<string>('');

//     useEffect(() => {
//         if(getLocalStorage('completeTaskViaRecipient')) {
//             setRt(getLocalStorage('completeTaskViaRecipient').rating); // set the review in here for peer to peer
//             setReview(getLocalStorage('completeTaskViaRecipient').review);            } else {
//         }
//     }, []);

//     const handleParticipantReview = () => {
//         // fire a mutation when the user exits the text area
//         console.log(`participant ${id} review is updated!`);
//         updateLocalStorage('completeTaskViaRecipient', 'review', review);
//     };

//     return (
//         <IonCol className="taskPartcItem" sizeSm="12" sizeXs="12">
//             <IonCol sizeXs="12" sizeSm="12" className="taskPartcItem__review">
//                 <IonCol sizeXl="3" sizeLg="3" sizeMd="3" sizeXs="3" sizeSm="3">
//                 <div className="taskPartcItem__img--wrap">
//                     <IonImg
//                         className="taskPartcItem__img"
//                         src={profileImg} />
//                 </div>
//                 </IonCol>
//                 <IonCol className="taskPartcItem__info--wrap" sizeXs="9" sizeSm="9">
//                     <h3 className="taskPartcItem__info--name">{firstName} {lastName}</h3>
//                     <IonCol className="taskPartcStars__container">
//                         {
//                             [...Array(5)].map((s, i) => {
//                                 const ratingVal = i + 1;

//                                 return (<label key={i}>
//                                     <input
//                                         type="radio"
//                                         name="rating"
//                                         value={ratingVal}
//                                     />
//                                     <IonIcon style={{ color: ratingVal <= rt ? "#f7b500" : "#d3d3d3" }} icon={star} className="taskPartcStars__icon" />
//                                 </label>
//                                 );
//                             })
//                         }
//                     </IonCol>
//                 </IonCol>
//             </IonCol>
//                 <IonCol className="taskPartcReview__textarea--wrap" sizeXs="12" sizeSm="12">
//                     <textarea
//                         onChange={(e) => setReview(e.target.value)}
//                         onFocus={() => console.log(`textarea for participant -> ${id} is focused`)}
//                         onBlur={handleParticipantReview}
//                         className="taskPartcReview__textarea"
//                         placeholder="Say something nice.."
//                         required
//                         value={review}
//                     ></textarea>
//                 </IonCol>
//         </IonCol>
//     )
// };

// export default ParticipantReviewItem;
