// importing modules
import React from 'react';
import { useHistory, NavLink } from 'react-router-dom';
import moment from 'moment-timezone';
// importing components
import { IonCol, IonImg, IonButton, IonIcon } from '@ionic/react';
// importing assets
import { informationCircleOutline } from 'ionicons/icons';
// importing utilities
import { getLocalStorage } from '../../../helpers/localStorage/localStorageHelper';
// importing types
import { GetProfileJobsPosted_profile_jobsPosted } from '../../../GraphQL/__generated__/GetProfileJobsPosted';

const ParticipationRequestItem: React.FC<{
    jobPost: GetProfileJobsPosted_profile_jobsPosted;
}> = ({ jobPost }) => {
    const history = useHistory();
    const taskStartTime = moment(jobPost.startTime).tz(
        getLocalStorage('tz'),
        false
    );
    const taskEndTime = moment(jobPost.endTime).tz(
        getLocalStorage('tz'),
        false
    );
    let taskTime;
    let btns;

    if (moment() < taskStartTime) {
        // check if the task hasnt started
        taskTime = (
            <p className='taskItem__info--time'>
                &#x02022;&nbsp;Starts {taskStartTime.fromNow()} &nbsp;
                <span>- Posted Task</span>
            </p>
        );
        btns = (
            <React.Fragment>
                <IonButton
                    fill='clear'
                    color='ion-primary'
                    className='taskItem__btn--cancel taskItem__btn--cancel--recipient'
                >
                    Delete Task
                </IonButton>
            </React.Fragment>
        );
    } else if (moment().isBetween(taskStartTime, taskEndTime)) {
        // check if the task is in progress
        taskTime = (
            <p className='taskItem__info--time'>
                &#x02022;&nbsp;Ends {taskEndTime.fromNow()} &nbsp;
                <span>- Posted Task</span>
            </p>
        );
        btns = (
            <IonButton
                fill='clear'
                color='ion-primary'
                className='completeTask__btn'
            >
                <NavLink
                    to={{
                        pathname: `/completeTaskPosted/recipient/${jobPost.id}`,
                        state: {
                            id: jobPost.id,
                        },
                    }}
                    className='completeTask__btn--link'
                >
                    Complete This Task
                </NavLink>
            </IonButton>
        );
    } else if (moment() > taskEndTime) {
        // check if the task has ended
        taskTime = (
            <p className='taskItem__info--time'>
                &#x02022;&nbsp;Ended {taskEndTime.fromNow()} &nbsp;
                <span>- Posted Task</span>
            </p>
        );
        btns = (
            <IonButton
                fill='clear'
                color='ion-primary'
                className='completeTask__btn'
            >
                <NavLink
                    to={{
                        pathname: `/completeTaskPosted/recipient/${jobPost.id}`,
                        state: {
                            id: jobPost.id,
                        },
                    }}
                    className='completeTask__btn--link'
                >
                    Complete This Task
                </NavLink>
            </IonButton>
        );
    }

    return (
        <IonCol className='taskItem' sizeSm='11' sizeXs='11'>
            <IonIcon
                onClick={() => history.push(`/task/${jobPost.id}`)}
                icon={informationCircleOutline}
                className='taskItem__info--icon'
            />
            <IonCol sizeXs='12' sizeSm='12' className='taskItem__info'>
                <IonCol sizeXl='3' sizeLg='3' sizeMd='3' sizeXs='3' sizeSm='3'>
                    <div className='taskItem__img--wrap'>
                        <IonImg
                            onClick={() =>
                                history.push(`/user/${jobPost.recipient.uid}`)
                            }
                            className='taskItem__img'
                            src={jobPost.recipient.profilePicture}
                        />
                    </div>
                </IonCol>
                <IonCol className='taskItem__info--wrap' sizeXs='9' sizeSm='9'>
                    <h3 className='taskItem__info--name'>{jobPost.title}</h3>
                    {taskTime}
                </IonCol>
            </IonCol>
            <IonCol sizeXs='12' sizeSm='12' className='taskItem__btn--wrap'>
                {btns}
            </IonCol>
        </IonCol>
    );
};

export default ParticipationRequestItem;

// const ParticipationRequestItem: React.FC<{
//     participationRequest: GetProfileJobsPosted_profile_incomingParticipationRequests
// }> = ({ participationRequest }) => {
//     const history = useHistory();

//     // TODO: should display what the schedule displays

//     return (
//         <IonCol className='tasksPostedItem' sizeSm='11' sizeXs='11'>
//             <IonCol sizeXs='12' sizeSm='12' className='tasksPostedItem__info'>
//                 <IonCol sizeXl='3' sizeLg='3' sizeMd='3' sizeXs='3' sizeSm='3'>
//                     <div className='tasksPostedItem__img--wrap'>
//                         <IonImg
//                             className='tasksPostedItem__img'
//                             src={participationRequest.provider.profilePicture}
//                         />
//                     </div>
//                 </IonCol>
//                 <IonCol
//                     className='tasksPostedItem__info--wrap'
//                     sizeXs='9'
//                     sizeSm='9'
//                 >
//                     <h3 className='tasksPostedItem__info--name'>{participationRequest.skill.description} w/ {participationRequest.provider.firstName}</h3>
//                     <p className='tasksPostedItem__info--date'>
//                         {moment(participationRequest.skill.jobPost.startTime)
//                             .tz(getLocalStorage('tz'), false)
//                             .format('dddd, MMMM Do YYYY')}
//                     </p>
//                 </IonCol>
//             </IonCol>
//             <IonCol sizeXs='12' sizeSm='12'>
//                 <p className='tasksPostedItem__info--descr'>{participationRequest.skill.additionalDetails}</p>
//             </IonCol>
//             <IonCol
//                 className='tasksPosted__item--btn--wrap'
//                 sizeSm='12'
//                 sizeXs='12'
//             >
//                 <IonButton
//                     onClick={() => history.push(`/manage/${participationRequest.skill.jobPost.id}/participation`)}
//                     color='ion-primary'
//                     fill='clear'
//                     className='tasksPosted__item--btn--manage'
//                 >
//                     Manage
//                 </IonButton>
//                 <IonButton
//                     onClick={() => history.push(`/task/${participationRequest.skill.jobPost.id}`)}
//                     color='ion-primary'
//                     fill='clear'
//                     className='tasksPosted__item--btn--view'
//                 >
//                     View
//                 </IonButton>
//             </IonCol>
//         </IonCol>
//     );
// };
