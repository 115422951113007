// importing modules
import React from 'react';
// importing styles
import './InputErrorMsg.css';

const InputErrorMsg: React.FC<{
    errorMsg: string;
}> = ({ errorMsg }) => {
    return (
        <p className='inputError__error'>
            <span className='inputError__error--span'>x</span>&nbsp;{errorMsg}
        </p>
    );
};

export const InputErrorMsgCentered: React.FC<{
    errorMsg: string;
}> = ({ errorMsg }) => {
    return (
        <p className='inputError__error inputError__error--center'>
            <span className='inputError__error--span'>x</span>&nbsp;{errorMsg}
        </p>
    );
};

export default InputErrorMsg;
