// hooks & modules
import React, { createContext, useContext, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useLazyQuery } from '@apollo/react-hooks';
import { useAuth } from '../context/firebase/authContext';
// onboarding query
import { gql_USER_PROFILE_ONBOARDING_REQS_BYID } from 'GraphQL/Profile/USER_PROFILE_ONBOARDING_REQS_BYID/USER_PROFILE_ONBOARDING_REQS_BYID';
import {
    UserProfile_onboarding_byID,
    UserProfile_onboarding_byIDVariables,
} from 'GraphQL/__generated__/UserProfile_onboarding_byID';
// component(s)
import Loading from 'components/status/LoadingScreen';

const isAllowedPath = (path: string): boolean => {
    switch (path) {
        case '/joinGroup':
        case '/onboarding/imageUpload':
        case '/onboarding/bio':
        case '/skillSelection':
        case '/skillsConfirmation':
            return true;
        default:
            if (
                path.match(/^\/joinGroup\/[^\/]+\/[^\/]+\/confirmation$/) ||
                path.match(/^\/skillCreation\/.*$/)
            ) {
                return true;
            } else {
                return false;
            }
    }
};

const OnboardingContext = createContext(undefined);

export const OnboardingContextProvider = ({
    maxRetries,
    children,
}: {
    maxRetries: number;
    children: React.ReactNode;
}) => {
    const { loading: authLoading, currentUser: firebaseUser } = useAuth();
    const [runQuery, { data, loading, error, refetch }] = useLazyQuery<
        UserProfile_onboarding_byID,
        UserProfile_onboarding_byIDVariables
    >(gql_USER_PROFILE_ONBOARDING_REQS_BYID, {
        variables: {
            subId: firebaseUser?.uid,
        },
        notifyOnNetworkStatusChange: true, // changes behavior so that loading
        // is also true when refetching,
        // as well as re-rendering when the
        // network status changes
    });
    const lastUid = useRef<string | null>(null);
    const retryCount = useRef<number>(0); // re-renders when network status changes
    const stageRef = useRef<string[]>([]);
    const initRef = useRef<boolean>(false);

    if (authLoading || loading) return <Loading />;

    // run query & unset init if user changed
    if (firebaseUser && lastUid.current !== firebaseUser.uid) {
        initRef.current = false;
        lastUid.current = firebaseUser.uid;
        runQuery();
        return <Loading />;
    }

    // when we create a new user, the data don't exist on the backend
    // immediately so this polls until they do //(or until we time out)
    if (data && !data.profile) {
        if (maxRetries > 0 && retryCount.current > maxRetries) {
            return <div>Context Error</div>;
        } else {
            setTimeout(() => {
                ++retryCount.current;
                refetch();
            }, 500); // every half second

            return <Loading />;
        }
    }

    // set stage
    if (!initRef.current && firebaseUser && data) {
        const user = data.profile;
        stageRef.current = [];

        // reverse order because stack
        if (!user.skills.length) {
            // if length is falsy
            stageRef.current.push('/skillSelection');
        }
        if (user.bio === null) {
            // '' means they skipped bio in onboarding
            stageRef.current.push('/onboarding/bio');
        }
        if (!user.profilePicture || user.profilePicture === 'PHOTO_FALLBACK') {
            stageRef.current.push('/onboarding/imageUpload');
        }
        if (!user.organizations.length) {
            stageRef.current.push('/joinGroup');
        }

        initRef.current = true;
    }

    return (
        <OnboardingContext.Provider value={stageRef}>
            {children}
        </OnboardingContext.Provider>
    );
};

export interface OnboardingFlow {
    go: () => void;
    next: (path: string) => boolean;
    pop: (path: string) => boolean;
    isOnboarding: () => boolean;
}

export const useOnboardingFlow = (): OnboardingFlow => {
    const history = useHistory();
    const stageRef = useContext(OnboardingContext);

    const go = () => {
        const head = stageRef.current.length - 1;
        if (head < 0) {
            history.push('/', { onboarding: false });
        } else {
            history.push(stageRef.current[head], { onboarding: true });
        }
    };
    const pop = (path: string) => {
        let head = stageRef.current.length - 1;

        // only pop if head matches expected (given) path
        if (head >= 0 && stageRef.current[head] === path) {
            stageRef.current.pop();
            return true;
        } else if (head >= 0) {
            const headPath = stageRef.current[head];
            console.warn(
                `Attempted to pop '${path}' when head is '${headPath}'`
            );
        } else {
            console.warn(
                'Attempted to pop onboarding stage outside of onboarding'
            );
        }

        return false;
    };
    const next = (path: string) => {
        const res = pop(path);
        go();
        return res;
    };
    const isOnboarding = () => stageRef.current.length > 0;

    return { go, isOnboarding, next, pop };
};

export const useHijacker = () => {
    const stageRef = useContext(OnboardingContext);
    const history = useHistory();
    const idRef = useRef<number | null>(null);

    useEffect(() => {
        idRef.current = window.setInterval(() => {
            if (stageRef.current.length <= 0) {
                window.clearInterval(idRef.current);
                idRef.current = null;
            } else if (!isAllowedPath(history.location.pathname)) {
                const head = stageRef.current.length - 1;
                history.replace(stageRef.current[head], { onboarding: true });
            }
        }, 10);
    }, []);
};
