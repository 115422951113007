// importing modules
import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
// importing components
import {
    IonCol,
    IonContent,
    IonGrid,
    IonIcon,
    IonImg,
    IonPage,
    IonRow,
} from '@ionic/react';
import SkillSelectionItem from './SkillSelectionItem.tsx/SkillSelectionItem';
import Loading from 'components/status/Loading';
import SkillCreationUserImg from 'components/SkillCreationUserImg/SkillCreationUserImg';
import BackButton from 'components/BackButton';
import Strokes from 'components/Strokes';
// importing assets
import { searchOutline } from 'ionicons/icons';
// importing utils
import {
    getLocalStorage,
    setLocalStorage,
} from 'helpers/localStorage/localStorageHelper';
// importing graphql
import { GET_SKILL_KEYWORDS } from 'GraphQL/Keywords/GET_SKILL_KEYWORDS';
import {
    GetSkillKeywords,
    GetSkillKeywords_keywords,
} from 'GraphQL/__generated__/GetSkillKeywords';
// importing utilities
import filterKeywordsArr from 'utils/filterKeywordsArr';
// importing styles
import './SkillSelectionPage.css';

const FEATURED_SKILLS_DATA = [
    'Web Development',
    'Fitness Classes',
    'Cooking',
    'Grocery Shopping',
    'Ride / Transportation',
];

const SkillSelectionPage: React.FC = () => {
    const { data, loading } = useQuery<GetSkillKeywords>(GET_SKILL_KEYWORDS);
    const [searchInput, setSearchInput] = useState<string>('');

    useEffect(() => {
        if (!getLocalStorage('skillColors')) {
            setLocalStorage('skillColors', { color: '', backgroundColor: '' });
        } else {
            setLocalStorage('skillColors', { color: '', backgroundColor: '' });
        }
    }, []);

    if (loading) {
        return <Loading />;
    }

    let filteredKeywords: GetSkillKeywords_keywords[] = []; // initial filtered array
    let keywords = []; // keywords

    if (searchInput.length > 0 && !loading) {
        filteredKeywords = data.keywords.filter((kw) =>
            kw.keyword.toLowerCase().includes(searchInput.toLowerCase())
        );
        keywords = filterKeywordsArr(filteredKeywords);
    } else {
        filteredKeywords = [];
    }

    return (
        <IonPage>
            <IonContent>
                <IonGrid>
                    {/* skillSelection header */}
                    <IonRow className='skillSelection__header'>
                        <BackButton />
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='skillSelection__header--container'
                        >
                            <Strokes top={true} green={false} />
                            <SkillCreationUserImg />
                        </IonCol>
                    </IonRow>
                    <IonRow className='skillSelection__textContent'>
                        <IonCol
                            className='skillSelection__textContent--container'
                            sizeSm='12'
                            sizeXs='12'
                        >
                            <h1 className='skillSelection__textContent--title'>
                                What is a <span>skill</span> that you can offer
                                to the group?
                            </h1>
                            <p className='skillSelection__textContent--text'>
                                As a hobby or as one gift of your expertise, it
                                counts as a skill you can provide to others on
                                YING.
                            </p>
                        </IonCol>
                    </IonRow>
                    {/* skillSelection searchbar and list */}
                    <IonRow className='skillSelection__searchbar'>
                        <IonCol
                            sizeXs='12'
                            sizeSm='12'
                            className='skillSelection__searchbar--container'
                        >
                            <div className='skillSelection__searchbar--input--wrap'>
                                <IonIcon
                                    className='skillSelection__searchbar--input--icon'
                                    icon={searchOutline}
                                />
                                <input
                                    className='skillSelection__searchbar--input'
                                    type='text'
                                    placeholder='Search for a skill here...'
                                    onChange={(event) =>
                                        setSearchInput(event.target.value)
                                    }
                                    value={searchInput}
                                />
                            </div>
                        </IonCol>
                    </IonRow>
                    <IonGrid>
                        {searchInput.length === 0 ? (
                            <IonRow className='skillSelection__list'>
                                <IonCol
                                    sizeXs='12'
                                    sizeSm='12'
                                    className='skillSelection__list--title--wrap'
                                >
                                    <h6 className='skillSelection__list--title'>
                                        FEATURED SKILLS ON YING
                                    </h6>
                                </IonCol>
                                {FEATURED_SKILLS_DATA &&
                                    FEATURED_SKILLS_DATA.map((kw, index) => (
                                        <SkillSelectionItem
                                            key={index}
                                            setSearchInput={setSearchInput}
                                            keywordName={kw}
                                        />
                                    ))}
                            </IonRow>
                        ) : filteredKeywords.length > 0 ? (
                            <IonRow className='skillSelection__list'>
                                <IonCol
                                    sizeXs='12'
                                    sizeSm='12'
                                    className='skillSelection__list--title--wrap'
                                >
                                    <h6 className='skillSelection__list--title'>
                                        SEARCH RESULTS
                                    </h6>
                                </IonCol>
                                {keywords.map((kw, index) => (
                                    <SkillSelectionItem
                                        key={index}
                                        setSearchInput={setSearchInput}
                                        keywordName={kw}
                                    />
                                ))}
                            </IonRow>
                        ) : (
                            <IonRow className='skillSelection__list'>
                                <IonCol
                                    sizeXs='12'
                                    sizeSm='12'
                                    className='skillSelection__list--title--wrap'
                                >
                                    <h6 className='skillSelection__list--title'>
                                        SKILL NOT FOUND
                                    </h6>
                                </IonCol>
                            </IonRow>
                        )}
                    </IonGrid>
                    <Strokes bot={true} green={false} />
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default SkillSelectionPage;
