// importing modules
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
// importing components
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonPage,
    IonRow,
    IonIcon,
} from '@ionic/react';
import InputErrorMsg, {
    InputErrorMsgCentered,
} from '../../components/InputErrorMsg/InputErrorMsg';
import Strokes from 'components/Strokes';
import BackButton from 'components/BackButton';
// importing assets
import yingLogo from '../../assets/ying-logo-large-blk.png';
import { chevronBackOutline, eyeOffOutline, eyeOutline } from 'ionicons/icons';
// import logoGoogle from '../../assets/google-icon.png';
// importing utilities
import { yingDebug } from '../../utils/debug/YingDebug';
import { setLocalStorage } from '../../helpers/localStorage/localStorageHelper';
// importing styles
import './Login.css';
// firebase context
import { useAuth } from '../../context/firebase/authContext';

type userLogin = {
    email: string;
    password: string;
};

const Login: React.FC = () => {
    const { login } = useAuth();
    const history = useHistory();
    const { register, handleSubmit, errors } = useForm<userLogin>();
    const [loginErrorCode, setLoginErrorCode] = useState<string>('');
    const [loginErrorMsg, setLoginErrorMsg] = useState<string>('');
    const [showPassword, setShowPassword] = useState<boolean>(false);
    setLocalStorage('currentAdminGroupId', 0);

    // const resolveAuth = async () => {
    //     let provider = new firebase.auth.GoogleAuthProvider();
    //     provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
    //     firebase
    //         .auth()
    //         .signInWithPopup(provider)
    //         .then(function (result) {
    //             // This gives you a Google Access Token. You can use it to access the Google API.
    //             //@ts-ignore
    //             var token = result.credential.accessToken;
    //             // The signed-in user info.
    //             var user = result.user;
    //             // ...
    //             history.push('/home');
    //         })
    //         .catch(function (error) {
    //             // Handle Errors here.
    //             var errorCode = error.code;
    //             var errorMessage = error.message;
    //             // The email of the user's account used.
    //             var email = error.email;
    //             // The firebase.auth.AuthCredential type that was used.
    //             var credential = error.credential;
    //             // ...
    //         });
    //     // }
    // };

    const handlePasswordBasedLogin = (data: userLogin) => {
        // firebase
        //     .auth()
        //     .signInWithEmailAndPassword(data.email.trim(), data.password.trim())
        //     .then((result) => {
        //         history.push('/home');
        //     })
        //     .catch((error) => {
        //         // Handle Errors here.
        //         let errorCode = error.code;
        //         let errorMessage = error.message;
        //         yingDebug(errorCode, errorMessage);
        //         setLoginErrorCode(errorCode);
        //         setLoginErrorMsg(errorMessage);
        //     });
        login(data.email.trim(), data.password.trim())
            .then((res) => history.push('/home'))
            .catch((error) => {
                let errorCode = error.code;
                let errorMessage = error.message;
                yingDebug(errorCode, errorMessage);
                setLoginErrorCode(errorCode);
                setLoginErrorMsg(errorMessage);
            });
    };

    return (
        <IonPage>
            <Strokes top={true} green={false} />
            <IonContent fullscreen className='ionic-content' scrollY={false}>
                <BackButton />
                <IonGrid className='login__container'>
                    <IonRow className='login__wrapper'>
                        {/* <LoginSignUpPageControl activePage='login' /> */}
                        <IonRow className='login__error--container'>
                            <IonCol
                                sizeXs='10'
                                sizeSm='10'
                                className='login__error--col'
                            >
                                {loginErrorCode && (
                                    <InputErrorMsgCentered
                                        errorMsg={loginErrorCode}
                                    />
                                )}
                                {loginErrorMsg && (
                                    <InputErrorMsgCentered
                                        errorMsg={loginErrorMsg}
                                    />
                                )}
                            </IonCol>
                        </IonRow>
                        <IonImg
                            className='login__logo'
                            src={yingLogo}
                            alt='ying-logo'
                        />
                        {/* login form */}
                        <IonRow className='login__form'>
                            <IonCol
                                sizeXs='10'
                                sizeSm='10'
                                className='login__form--wrap'
                            >
                                <form
                                    onSubmit={handleSubmit(
                                        handlePasswordBasedLogin
                                    )}
                                    className='login__form--container'
                                >
                                    <div className='login__form--input--wrap'>
                                        {errors.email &&
                                            errors.email.type ===
                                                'required' && (
                                                <InputErrorMsg errorMsg='Email is required' />
                                            )}
                                        {errors.email &&
                                            errors.email.type === 'pattern' && (
                                                <InputErrorMsg errorMsg='Email is not valid' />
                                            )}
                                        <input
                                            ref={register({
                                                required: 'Email is required',
                                                pattern:
                                                    /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                            })}
                                            type='email'
                                            className={`${
                                                errors.email
                                                    ? 'login__form--input login__form--input--error'
                                                    : 'login__form--input'
                                            }`}
                                            name='email'
                                            placeholder='Email Address'
                                            autoComplete='off'
                                            id='signInEmail'
                                            formNoValidate
                                        />
                                    </div>
                                    <div className='login__form--input--wrap'>
                                        {errors.password && (
                                            <InputErrorMsg errorMsg='Password is required' />
                                        )}
                                        <div className='login__form--input--password--wrap'>
                                            <input
                                                ref={register({
                                                    required:
                                                        'Password is required',
                                                })}
                                                type={
                                                    showPassword
                                                        ? 'text'
                                                        : 'password'
                                                }
                                                className={`${
                                                    errors.password
                                                        ? 'login__form--input login__form--input--error'
                                                        : 'login__form--input'
                                                }`}
                                                name='password'
                                                placeholder='Password'
                                                autoComplete='off'
                                                id='signInPassword'
                                                formNoValidate
                                            />
                                            <IonIcon
                                                onClick={() =>
                                                    setShowPassword(
                                                        !showPassword
                                                    )
                                                }
                                                icon={
                                                    showPassword
                                                        ? eyeOutline
                                                        : eyeOffOutline
                                                }
                                                className='login__form--input--password--icon'
                                            />
                                        </div>
                                    </div>
                                    <IonButton
                                        fill='clear'
                                        color='ion-primary'
                                        type='submit'
                                        className='login__form--submit'
                                        id='submitSigninForm'
                                    >
                                        Log In
                                    </IonButton>
                                </form>
                            </IonCol>
                            <IonCol
                                sizeXs='8'
                                sizeSm='8'
                                className='login__forgotPassword'
                            >
                                <IonButton
                                    routerLink='/forgotPassword'
                                    fill='clear'
                                    className='login__forgotPassword--btn'
                                >
                                    Forgot Password?
                                </IonButton>
                            </IonCol>
                        </IonRow>
                        {/* google-auth provider login */}
                        {/* <IonRow className='login__typeDivider'>
                            <IonCol
                                sizeXs='10'
                                sizeSm='10'
                                className='login__typeDivider--container'
                            >
                                <p className='login__typeDivider__text'>
                                    <span className='login__typeDivider__text--span'>
                                        Or
                                    </span>
                                </p>
                            </IonCol>
                        </IonRow>
                        <IonRow className='login__btns'>
                            <IonCol
                                sizeXs='10'
                                sizeSm='10'
                                className='login__btn--container'
                            >
                                <IonButton
                                    fill='clear'
                                    onClick={() => resolveAuth()}
                                    className='login__btn--google'
                                    color='ion-primary'
                                >
                                    <IonImg
                                        className='login__btn--google--icon'
                                        src={logoGoogle}
                                        alt='google icon'
                                    />
                                    &nbsp; Continue with Google
                                </IonButton>
                            </IonCol>
                        </IonRow> */}
                    </IonRow>
                </IonGrid>
            </IonContent>
            <Strokes bot={true} green={false} />
        </IonPage>
    );
};

export default Login;
