// importing modules
import React from 'react';
// importing components
import { IonRow } from '@ionic/react';
import TaskPostedItem from '../TaskPostedItem/TaskPostedItem';
// importing types
import { GetProfileJobsPosted_profile_jobsPosted } from '../../../GraphQL/__generated__/GetProfileJobsPosted';

const TasksPostedList: React.FC<{
    jobsPosted: GetProfileJobsPosted_profile_jobsPosted[];
}> = ({ jobsPosted }) => {
    return (
        <>
            {jobsPosted.length > 0 ? (
                jobsPosted.map((taskPosted) => {
                    return (
                        <TaskPostedItem key={taskPosted.id} task={taskPosted} />
                    );
                })
            ) : (
                <IonRow className='tasksPosted__text'>
                    <p>When you create a task it will appear here.</p>
                    <br />
                    <p>Create your first task today!</p>
                </IonRow>
            )}
        </>
    );
};

export default TasksPostedList;
