const getLocationPreferenceStr = (pref) => {
    if (pref === 'VIRTUAL') {
        return 'Virtual Meeting';
    } else if (pref === 'INPERSON') {
        return 'In Person Meeting';
    } else if (pref === 'OPENTOALL') {
        return 'Open To All';
    }
};

export { getLocationPreferenceStr as default };
