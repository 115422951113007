// importing modules
import React from 'react';
import moment from 'moment-timezone';
import { useHistory, NavLink } from 'react-router-dom';
// importing components
import { IonButton, IonCol, IonImg, IonSpinner, IonIcon } from '@ionic/react';
// importing assets
import { informationCircleOutline } from 'ionicons/icons';
// importing utilities
import { getLocalStorage } from '../../../helpers/localStorage/localStorageHelper';
// importing graphql
import {
    GetPendingJobsById_pendingJobs,
    GetPendingJobsById_pendingJobs_recipient,
    GetPendingJobsById_pendingJobs_provider,
} from '../../../GraphQL/__generated__/GetPendingJobsById';

const ReceivingTaskItem: React.FC<{
    task: GetPendingJobsById_pendingJobs;
    otherProfile:
        | GetPendingJobsById_pendingJobs_recipient
        | GetPendingJobsById_pendingJobs_provider;
}> = ({ task, otherProfile }) => {
    const history = useHistory();
    const taskStartTime = moment(task.startTime).tz(
        getLocalStorage('tz'),
        false
    );
    const taskEndTime = moment(task.endTime).tz(getLocalStorage('tz'), false);
    let taskTime;
    let btns;

    // conditionals to render component based on task time/status
    if (moment() < taskStartTime) {
        // check if the task hasnt started
        taskTime = (
            <p className='completedTasksItem__info--time'>
                &#x02022;&nbsp;Starts {taskStartTime.fromNow()} &nbsp;{' '}
                <span>- Receiving</span>
            </p>
        );
        btns = (
            <React.Fragment>
                <IonButton
                    fill='clear'
                    color='ion-primary'
                    className='completedTasksItem__btn--send'
                >
                    Send Message
                </IonButton>
                <IonButton
                    onClick={() => history.push(`/delete/${task.id}`)}
                    fill='clear'
                    color='ion-primary'
                    className='completedTasksItem__btn--cancel'
                >
                    Delete Task
                </IonButton>
            </React.Fragment>
        );
    } else if (moment().isBetween(taskStartTime, taskEndTime)) {
        // check if the task is in progress
        taskTime = (
            <p className='completedTasksItem__info--time'>
                &#x02022;&nbsp;Ends {taskEndTime.fromNow()} &nbsp;{' '}
                <span>- Receiving</span>
            </p>
        );
        if (task.Status.toString() === 'RECIPIENT_HAS_PROPOSED_OFFER') {
            btns = (
                <div className='completeTask__btn--waiting'>
                    <IonSpinner
                        className='completeTask__btn--waiting--spinner'
                        name='crescent'
                    />
                    <p className='completeTask__btn--waiting--text'>
                        Waiting on Provider
                    </p>
                </div>
            );
        } else {
            btns = (
                <IonButton
                    fill='clear'
                    color='ion-primary'
                    className='completeTask__btn'
                >
                    <NavLink
                        to={{
                            pathname: `/completeTask/recipient/${task.id}`,
                            state: {
                                id: task.id,
                            },
                        }}
                        className='completeTask__btn--link'
                    >
                        Complete This Task
                    </NavLink>
                </IonButton>
            );
        }
    } else if (moment() > taskEndTime) {
        // check if the task has ended
        taskTime = (
            <p className='completedTasksItem__info--time'>
                &#x02022;&nbsp;Ended {taskEndTime.fromNow()} &nbsp;{' '}
                <span>- Receiving</span>
            </p>
        );
        if (task.Status.toString() === 'RECIPIENT_HAS_PROPOSED_OFFER') {
            btns = (
                <div className='completeTask__btn--waiting'>
                    <IonSpinner
                        className='completeTask__btn--waiting--spinner'
                        name='crescent'
                    />
                    <p className='completeTask__btn--waiting--text'>
                        Waiting on Provider
                    </p>
                </div>
            );
        } else {
            btns = (
                <IonButton
                    fill='clear'
                    color='ion-primary'
                    className='completeTask__btn'
                >
                    <NavLink
                        to={{
                            pathname: `/completeTask/recipient/${task.id}`,
                            state: {
                                id: task.id,
                            },
                        }}
                        className='completeTask__btn--link'
                    >
                        Complete This Task
                    </NavLink>
                </IonButton>
            );
        }
    }

    return (
        <IonCol
            className='completedTasksItem'
            style={{ backgroundColor: '#fff' }}
            sizeSm='11'
            sizeXs='11'
        >
            <IonIcon
                onClick={() => history.push(`/skillRequest/${task.id}`)}
                icon={informationCircleOutline}
                className='completedTasksItem__info--icon'
            />
            <IonCol
                sizeXs='12'
                sizeSm='12'
                className='completedTasksItem__info'
            >
                <IonCol sizeXl='3' sizeLg='3' sizeMd='3' sizeXs='3' sizeSm='3'>
                    <div className='completedTasksItem__img--wrap'>
                        <IonImg
                            onClick={() =>
                                history.push(`/user/${otherProfile.uid}`)
                            }
                            className='completedTasksItem__img'
                            src={otherProfile.profilePicture}
                        />
                    </div>
                </IonCol>
                <IonCol
                    className='completedTasksItem__info--wrap'
                    sizeXs='9'
                    sizeSm='9'
                >
                    <h3 className='completedTasksItem__info--name'>
                        {task.Skill.name} w/{' '}
                        {otherProfile.firstName + ' ' + otherProfile.lastName}
                    </h3>
                    {taskTime}
                    <p className='completedTasksItem__info--date'>
                        {moment(task.endTime)
                            .tz(getLocalStorage('tz'), false)
                            .format('dddd, MMMM Do YYYY')}
                    </p>
                </IonCol>
            </IonCol>
            <IonCol
                sizeXs='12'
                sizeSm='12'
                className='completedTasksItem__btn--wrap'
            >
                {btns}
            </IonCol>
        </IonCol>
    );
};

export default ReceivingTaskItem;
