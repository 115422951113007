// importing modules
import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonLabel,
    IonRow,
    IonPage,
} from '@ionic/react';
// importing components
import UserImg from '../UI/UserImg/UserImg';
import GroupImg from '../UI/GroupImg/GroupImg';
import CreateTaskTabs from '../UI/CreateTaskTabs/CreateTaskTabs';
import CreateTaskFooter from 'components/FooterWithChildren/FooterWithChildren';
import Strokes from 'components/Strokes';
// importing assets
// importing helpers
import {
    getLocalStorage,
    setLocalStorage,
    updateLocalStorage,
} from 'helpers/localStorage/localStorageHelper';
// importing styles
import './TaskDescription.css';

export default ({ taskType }: { taskType: string }) => {
    const history = useHistory();
    const location = useLocation();
    const { groupId, groupCode } = useParams<{
        groupId: string;
        groupCode: string;
    }>();
    const [taskName, setTaskName] = useState<string>('');
    const [taskDescr, setTaskDescr] = useState<string>('');

    useEffect(() => {
        if (!getLocalStorage('createTask')) {
            const createTask = {
                taskName: '',
                taskDescr: '',
                date: null,
                startTime: '',
                endTime: '',
                location: '',
                locationPreference: '',
                skillsNeeded: [],
                groupCodes: [],
                memberInvites: [],
            };
            setLocalStorage('createTask', createTask);
        } else {
            const createTask = getLocalStorage('createTask');
            setTaskName(createTask.taskName);
            setTaskDescr(createTask.taskDescr);
        }
    }, [location]);
    // Re-render component on url location change since history.push()
    // doesn't trigger re-render

    const handleTaskDescription = () => {
        updateLocalStorage('createTask', 'taskName', taskName);
        updateLocalStorage('createTask', 'taskDescr', taskDescr);
        if (taskType === 'HOME') {
            history.push('/create-task/date');
        } else if (taskType === 'GROUP') {
            history.push(`/create-task/${groupId}/${groupCode}/date`);
        }
    };

    let img;
    if (taskType === 'HOME' && getLocalStorage('currentAdminGroupId') == 0) {
        img = <UserImg />;
    } else if (
        taskType === 'GROUP' &&
        getLocalStorage('currentAdminGroupId') == 0
    ) {
        img = <UserImg />;
    } else {
        img = <GroupImg />;
    }

    return (
        <IonPage>
            <IonContent color='ion-secondary' className='describeTask__page'>
                <IonGrid className='describeTask__page'>
                    {/* createTask header */}
                    <IonRow className='createTask__header'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='createTask__header--container'
                        >
                            <Strokes top={true} />
                            <CreateTaskTabs disablePrevStep={true} />
                            {img}
                        </IonCol>
                    </IonRow>
                    {/* text content */}
                    <IonRow className='createTask__textContent'>
                        <IonCol
                            className='createTask__textContent--container'
                            sizeSm='12'
                            sizeXs='12'
                        >
                            <h1 className='createTask__textContent--title'>
                                <span>Describe</span> your task
                            </h1>
                            <p className='createTask__textContent--text'>
                                Once posted, this request will live in the Earn
                                section of your home screen.
                            </p>
                        </IonCol>
                    </IonRow>
                    {/* task def form */}
                    <IonGrid className='createTask__form--container'>
                        <IonRow className='createTask__form--row'>
                            <IonCol
                                className='createTask__form--input--wrap'
                                sizeXs='12'
                                sizeSm='12'
                            >
                                <IonLabel className='createTask__form--label'>
                                    Task Name
                                </IonLabel>
                                <input
                                    onChange={(event) =>
                                        setTaskName(event.target.value)
                                    }
                                    value={taskName}
                                    maxLength={70} // 70 character limit on task title
                                    className='createTask__form--input'
                                    placeholder='Ex: Need help moving...'
                                    type='text'
                                    id='task_from_home_name_input'
                                    required
                                />
                            </IonCol>
                            <IonCol
                                className='createTask__form--input--wrap'
                                sizeXs='12'
                                sizeSm='12'
                            >
                                <IonLabel className='createTask__form--label'>
                                    Task Description
                                </IonLabel>
                                <textarea
                                    onChange={(event) =>
                                        setTaskDescr(event.target.value)
                                    }
                                    value={taskDescr}
                                    className='createTask__form--textarea'
                                    placeholder='Ex: would love some help, moving saturday...'
                                    id='task_from_home_textarea'
                                    required
                                ></textarea>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonGrid>
            </IonContent>
            <CreateTaskFooter>
                <IonButton
                    fill='clear'
                    disabled={!(taskName.length > 0 && taskDescr.length > 0)}
                    color='ion-primary'
                    onClick={handleTaskDescription}
                    className='footerWithChild__btn'
                    id='task_from_home_describe_btn'
                >
                    Continue
                </IonButton>
            </CreateTaskFooter>
        </IonPage>
    );
};
