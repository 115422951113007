// importing modules
import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
// importing components
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonPage,
    IonRow,
} from '@ionic/react';
import UserImg from '../CreateTask/UI/UserImg/UserImg';
import Strokes from 'components/Strokes';
import BackButton from 'components/BackButton';
// importing assets
// importing graphql
import { useMutation } from '@apollo/react-hooks';
import { DELETE_ONE_JOB_POST } from '../../GraphQL/JobPost/DELETE_ONE_JOB_POST/DELETE_ONE_JOB_POST';
import {
    DeleteOneJobPostById,
    DeleteOneJobPostByIdVariables,
} from '../../GraphQL/__generated__/DeleteOneJobPostById';
// importing styles
import './DeleteJobPost.css';

const DeleteProviderTask: React.FC = () => {
    const { recipient, taskId } = useParams<{
        recipient: string;
        taskId: string;
    }>();
    const history = useHistory();
    const [msg, setMsg] = useState('');
    const [RemoveOneJob, RemoveOneJobDatas] = useMutation<
        DeleteOneJobPostById,
        DeleteOneJobPostByIdVariables
    >(DELETE_ONE_JOB_POST);

    const handleRemoveTask = () => {
        RemoveOneJob({
            variables: {
                jobId: parseInt(taskId),
            },
        })
            .then((r) => {
                history.push(`/delete/jobPost/${taskId}/confirmation`);
            })
            .catch((err) => console.error(err));
    };

    return (
        <IonPage>
            <IonContent>
                <IonGrid>
                    <IonRow className='deleteJobPost__header'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='deleteJobPost__header--container'
                        >
                            <BackButton color='white' />
                            <Strokes top={true} />
                            <UserImg />
                        </IonCol>
                    </IonRow>
                    <IonRow className='deleteJobPost__textContent'>
                        <IonCol
                            className='deleteJobPost__textContent--container'
                            sizeSm='12'
                            sizeXs='12'
                        >
                            <h1 className='deleteJobPost__textContent--title'>
                                <span>Confirm,</span> Delete task?
                            </h1>
                            <p className='deleteJobPost__textContent--text'>
                                The members who are participating in this task
                                will recieve a notification. Once you delete
                                this task, you will have to re-post to recieve
                                this help again.
                            </p>
                        </IonCol>
                    </IonRow>
                    <IonGrid className='deleteJobPost__form--container'>
                        <IonRow className='deleteJobPost__form--row'>
                            <IonCol
                                className='deleteJobPost__form--input--wrap'
                                sizeXs='12'
                                sizeSm='12'
                            >
                                <textarea
                                    onChange={(event) =>
                                        setMsg(event.target.value)
                                    }
                                    value={msg}
                                    className='deleteJobPost__form--textarea'
                                    placeholder='Leave a message...'
                                    required
                                ></textarea>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                    <IonRow className='deleteJobPost__btn--container'>
                        <IonCol
                            className='deleteJobPost__btn--wrap'
                            sizeXs='12'
                            sizeSm='12'
                        >
                            <IonButton
                                fill='clear'
                                color='ion-primary'
                                className='deleteJobPost__btn'
                                onClick={handleRemoveTask}
                            >
                                Delete Task
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default DeleteProviderTask;
