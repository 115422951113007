import React from 'react';
import moment from 'moment';
import { IonCol } from '@ionic/react';
import './TaskScheduleControl.css';

const TaskScheduleControl: React.FC<{
    dateStr: String;
    dateDay: String;
    dateMonth: String;
    selectedDate: String;
    handleSelectedDate: Function;
}> = ({ dateStr, dateDay, dateMonth, selectedDate, handleSelectedDate }) => {
    const today = moment().format('YYYY-MM-DD');
    return (
        <IonCol
            onClick={() => handleSelectedDate(dateStr)}
            sizeSm='2'
            sizeXs='2'
            className={`schedule__day ${
                selectedDate === dateStr ? 'schedule__day--active' : null
            }`}
        >
            <p className='schedule__day--month'>
                {today === dateStr ? 'Today' : dateMonth}
            </p>
            <p className='schedule__day--num'>{dateDay}</p>
        </IonCol>
    );
};

export default TaskScheduleControl;
