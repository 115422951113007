// importing modules
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
// importing components
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonPage,
    IonRow,
    IonSpinner,
} from '@ionic/react';
import ErrorMsg, {
    InputErrorMsgCentered,
} from 'components/InputErrorMsg/InputErrorMsg';
import Strokes from 'components/Strokes';
// importing assets
// importing styles
import './PhoneNumberInput.css';
import { useAuth } from 'context/firebase/authContext';
import firebase from 'firebase/app';
import 'firebase/auth';

type phoneNum = {
    phoneNumber: number;
};

export default () => {
    const authctx = useAuth();
    const history = useHistory();
    const { register, handleSubmit, errors } = useForm<phoneNum>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [verificationErr, setVerificationError] = useState<string>('');

    useEffect(() => {
        //@ts-ignore
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
            'recaptcha-container',
            {
                size: 'invisible',
                callback: handleOnSubmit,
            }
        );
    }, []);

    const handleOnSubmit = (data: phoneNum) => {
        setIsLoading(true);
        // phone number without special characters (except leading +)
        const phoneOnlyDigits = data.phoneNumber
            .toString()
            .replace(/[0-9]/g, '');
        const phoneNumber = '+1' + data.phoneNumber.toString();
        //@ts-ignore
        const appVerifier = window.recaptchaVerifier;

        authctx
            .linkWithPhoneNumber(phoneNumber, appVerifier)
            .then(function (confirmationResult) {
                // SMS sent. Prompt user to type the code from the message, then sign the
                // user in with confirmationResult.confirm(code).
                //@ts-ignore
                window.confirmationResult = confirmationResult;
                //@ts-ignore
                history.push('/onboarding/phoneVerification', {
                    onboarding: true,
                });
            })
            .catch(function (error) {
                // Error; SMS not sent
                setIsLoading(false);
                console.error('SMS not sent :: CODE: ', error.code);
                console.error('SMS not sent :: message: ', error.message);
                appVerifier.reset();
                switch (error.code) {
                    case 'auth/invalid-phone-number':
                        setVerificationError(
                            'Phone number provided is incorrect'
                        );
                        break;
                    case 'auth/captcha-check-failed':
                        setVerificationError('Recaptcha failed, try again');
                        break;
                    //TODO add more handlers
                    default:
                        setVerificationError('Something went wrong');
                        console.error(error);
                }
            });
    };

    return (
        <IonPage>
            <IonContent>
                <IonGrid className='phoneNumInput__grid'>
                    <IonRow className='phoneNumInput__row'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='phoneNumInput__container'
                        >
                            <Strokes />
                            <IonCol
                                sizeSm='12'
                                sizeXs='12'
                                className='phoneNumInput__content--container'
                            >
                                <h1 className='phoneNumInput__content--title'>
                                    Welcome to <span>YING</span>
                                </h1>
                                <p className='phoneNumInput__content--text'>
                                    Enter your phone number so we can send you a
                                    confirmation code.
                                </p>
                                {errors.phoneNumber &&
                                    errors.phoneNumber.type === 'required' && (
                                        <ErrorMsg errorMsg='Phone number is required' />
                                    )}
                                {errors.phoneNumber &&
                                    errors.phoneNumber.type === 'pattern' && (
                                        <ErrorMsg errorMsg='Invalid Phone number format' />
                                    )}
                                {verificationErr.length > 0 && (
                                    <InputErrorMsgCentered
                                        errorMsg={verificationErr}
                                    />
                                )}
                                <form
                                    onSubmit={handleSubmit(handleOnSubmit)}
                                    className='phoneNumInput__input--container'
                                >
                                    <div className='phoneNumInput__input--wrap'>
                                        <p className='phoneNumInput__input--text'>
                                            +1
                                        </p>
                                        <input
                                            name='phoneNumber'
                                            type='tel'
                                            className={
                                                errors.phoneNumber
                                                    ? 'phoneNumInput__input phoneNumInput__input--error'
                                                    : 'phoneNumInput__input'
                                            }
                                            placeholder='(000) 000-0000'
                                            ref={register({
                                                required:
                                                    'Phone number is Required',
                                                pattern:
                                                    /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/,
                                            })}
                                            autoComplete='off'
                                        />
                                    </div>
                                    <div id='recaptcha-container' />
                                    <IonButton
                                        fill='clear'
                                        // id='sign-in-button'
                                        type='submit'
                                        color='ion-primary'
                                        className='phoneNumInput__content--btn'
                                    >
                                        {isLoading ? (
                                            <IonSpinner
                                                className='phoneNumInput__spinner'
                                                name='crescent'
                                            />
                                        ) : (
                                            'Continue'
                                        )}
                                    </IonButton>
                                </form>
                            </IonCol>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};
