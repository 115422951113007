// importing modules
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useAuth } from 'context/firebase/authContext';
import moment from 'moment-timezone';
import { Calendar } from '@ionic-native/calendar';
import {
    Plugins,
    Capacitor,
    PushNotification,
    PushNotificationToken,
    PushNotificationActionPerformed,
} from '@capacitor/core';
// importing components
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonIcon,
    IonPage,
    IonRow,
} from '@ionic/react';
import { RefresherEventDetail } from '@ionic/core';
import { arrowForwardOutline, menuOutline } from 'ionicons/icons';
import UserMenu from '../../components/UserMenu/UserMenu';
import UserMenuBackdrop from '../../components/UserMenu/UserMenuBackdrop';
import GreenStrokesHeader from '../../components/GreenStrokesHeader/GreenStrokesHeader';
import Schedule from '../../components/Schedule/Schedule';
import Loading from '../../components/status/Loading';
import NetworkError from '../../components/status/NetworkError';
import EarnSection from '../../components/EarnSection/EarnSection';
import Refresher from '../../components/Refresher';
import NavBar from '../../components/NavBar';
// importing graphql utilities - types
import { useQuery, useMutation } from '@apollo/react-hooks';
import { ADD_DEVICE_TO_PROFILE } from '../../GraphQL/Device/ADD_DEVICE_TO_PROFILE';
import {
    addDeviceToProfile,
    addDeviceToProfileVariables,
} from '../../GraphQL/__generated__/addDeviceToProfile';
import {
    Status,
    NotificationType,
} from '../../GraphQL/__generated__/globalTypes';
import {
    RETRIEVE_PROFILE__INITIALIZE_IF_NEEDED,
    RETRIEVE_PROFILE__INITIALIZE_IF_NEEDEDVariables,
} from '../../GraphQL/__generated__/RETRIEVE_PROFILE__INITIALIZE_IF_NEEDED';
import { gql_RETRIEVE_PROFILE__INITIALIZE_IF_NEEDED } from '../../GraphQL/Profile/RETRIEVE_PROFILE__INITIALIZE_IF_NEEDED/RETRIEVE_PROFILE__INITIALIZE_IF_NEEDED';
// importing helpers
import { FirebaseHelper } from '../../helpers/FirebaseHelper';
import {
    setLocalStorage,
    getLocalStorage,
} from '../../helpers/localStorage/localStorageHelper';
//import { GetStripeAccount } from '../Settings/StripeSettings/StripeHelpers'; //nbm testing stripe

// importing assets
import balanceToken from '../../assets/balance-token.png';
// importing styles
import './UserHomeProfile.css';
import gratitudePot from '../../assets/GratitudePot2.0.png';

import useTempClearLocalStorageForTasks from 'utils/useTempClearLocalStorageForTasks';

const UserHomeProfile: React.FC = () => {
    //let stripeAccount = GetStripeAccount(); //nbm testing stripe
    const { PushNotifications } = Plugins;
    //TODO delete me
    useTempClearLocalStorageForTasks();
    const [addDeviceToProfile, addDeviceToProfileDatas] = useMutation<
        addDeviceToProfile,
        addDeviceToProfileVariables
    >(ADD_DEVICE_TO_PROFILE);
    const history = useHistory();
    const fuser = new FirebaseHelper(useAuth().currentUser);
    const [toggleMenu, setToggleMenu] = useState<boolean>(false); // handles hamburger menu
    const {
        data,
        loading,
        error: error__RETRIEVE_PROFILE__INITIALIZE_IF_NEEDED,
    } = useQuery<
        RETRIEVE_PROFILE__INITIALIZE_IF_NEEDED, // query return return type (shape of data)
        RETRIEVE_PROFILE__INITIALIZE_IF_NEEDEDVariables // query request type       (shape of variables)
    >(gql_RETRIEVE_PROFILE__INITIALIZE_IF_NEEDED, {
        variables: {
            uid: fuser.uid,
            firstName: fuser.firstName ? fuser.firstName : '',
            lastName: fuser.lastName ? fuser.lastName : '',
            photoURL: fuser.photoURL ? fuser.photoURL : '',
            tz: moment.tz.guess(),
        },
        fetchPolicy: 'no-cache',
    });

    useEffect(() => {
        if (data !== null && Capacitor.platform !== 'web') {
            // get native push notifications permissions
            // get native calendar permissions
            handleRegisterPush();
            Calendar.hasReadWritePermission()
                .then((hasCalendarPermission) => {
                    if (!hasCalendarPermission) {
                        Calendar.requestReadWritePermission().catch((err) =>
                            console.error(err)
                        );
                    }
                })
                .catch((err) => console.error(err));
        }
        if (!getLocalStorage('currentAdminGroupId')) {
            setLocalStorage('currentAdminGroupId', 0);
        }

        // TO CLEAR WHAT USERS MIGHT HAVE PRE-POPULATED IN LOCALSTORAGE
        const groupTask = {
            taskName: '',
            taskDescr: '',
            date: null,
            startTime: '',
            endTime: '',
            location: '',
            locationPreference: '',
            skillsNeeded: [],
            memberInvites: [],
        };
        setLocalStorage('groupTask', groupTask);

        // TO CLEAR WHAT USERS MIGHT HAVE PRE-POPULATED IN LOCALSTORAGE
        const createTask = {
            taskName: '',
            taskDescr: '',
            date: null,
            startTime: '',
            endTime: '',
            location: '',
            locationPreference: '',
            skillsNeeded: [],
            groupCodes: [],
            memberInvites: [],
        };
        setLocalStorage('createTask', createTask);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleReload = (event: CustomEvent<RefresherEventDetail>) => {
        history.go(0); // reload page
        event.detail.complete();
    };

    const handleMenuToggle = () => setToggleMenu((prevToggle) => !prevToggle);
    const handleCreateSkillRequestCalendarEvent = (
        id: string,
        title: string,
        location: string,
        notes: string,
        startDate: string,
        endDate: string
    ) => {
        if (Capacitor.platform !== 'web') {
            const taskStartTime = moment(startDate);
            const taskStartDate = taskStartTime.tz(
                getLocalStorage('tz'),
                false
            );
            const taskEndTime = moment(endDate);
            const taskEndDate = taskEndTime.tz(getLocalStorage('tz'), false);
            Calendar.createEventWithOptions(
                title,
                location,
                notes,
                taskStartDate.toDate(),
                taskEndDate.toDate(),
                {
                    id: id.toString(),
                    firstReminderMinutes: 5,
                    secondReminderMinutes: 120,
                }
            ).catch((err) =>
                console.error('error creating calendar event', err)
            );
        }
    };

    const handleDeleteSkillRequestCalendarEvent = (
        title: string,
        location: string,
        notes: string,
        startDate: string,
        endDate: string
    ) => {
        if (Capacitor.platform !== 'web') {
            const taskStartTime = moment(startDate);
            const taskStartDate = taskStartTime.tz(
                getLocalStorage('tz'),
                false
            );
            const taskEndTime = moment(endDate);
            const taskEndDate = taskEndTime.tz(getLocalStorage('tz'), false);
            Calendar.deleteEvent(
                title,
                location,
                notes,
                taskStartDate.toDate(),
                taskEndDate.toDate()
            ).catch((err) =>
                console.error('error removing calendar event', err)
            );
        }
    };

    const handleAddDeviceToProfile = (deviceToken) => {
        addDeviceToProfile({
            variables: { uid: fuser.uid, fcmToken: deviceToken },
        }).catch((err) => console.error(err));
    };

    const handleRegisterPush = () => {
        PushNotifications.requestPermission().then((permission) => {
            if (permission.granted) {
                PushNotifications.register();
            } else {
                // no permission granted
            }
        });

        // On succcess, we should be able to receive notifications
        PushNotifications.addListener(
            'registration',
            (token: PushNotificationToken) => {
                // alert('Push registration success, token: ' + token.value);
                handleAddDeviceToProfile(token.value);
            }
        );

        // Some issue with your setup and push will not work
        PushNotifications.addListener('registrationError', (error: any) => {
            console.log('Error on registration: ' + JSON.stringify(error));
        });

        // Show us the notification payload if the app is open on our device
        PushNotifications.addListener(
            'pushNotificationReceived',
            async (notification: PushNotification) => {
                console.log('Push received', JSON.stringify(notification));
            }
        );

        // Method called when tapping on a notification
        PushNotifications.addListener(
            'pushNotificationActionPerformed',
            async (notification: PushNotificationActionPerformed) => {
                const data = notification.notification.data;
                if (data.status === Status.SCHEDULED) {
                    handleCreateSkillRequestCalendarEvent(
                        data.id,
                        data.title,
                        data.location,
                        data.notes,
                        data.startDate,
                        data.endDate
                    );
                    history.push(`/skillRequest/${data.id}`);
                } else if (data.status === Status.CANCELED) {
                    handleDeleteSkillRequestCalendarEvent(
                        data.title,
                        data.location,
                        data.notes,
                        data.startDate,
                        data.endDate
                    );
                } else if (data.status === Status.REMOVED) {
                    handleDeleteSkillRequestCalendarEvent(
                        data.title,
                        data.location,
                        data.notes,
                        data.startDate,
                        data.endDate
                    );
                } else if (data.status === Status.REQUESTED) {
                    history.push(`/accept/skillRequest/${data.id}`);
                } else if (data.status === NotificationType.NUDGE) {
                    history.push(data.path);
                } else if (data.status === NotificationType.INVITE) {
                    history.push(data.path);
                } else if (data.jobPost) {
                    history.push(data.jobPost);
                } else if (data.chatroom) {
                    history.push(
                        // `/chatroom/${data.chatId}/${data.receiverId}/${data.senderId}`
                        data.chatroom
                    );
                } else if (data.manageTask) {
                    history.push(data.manageTask);
                } else {
                    history.push('/');
                }
            }
        );
    };

    if (loading) {
        return <Loading />;
    }

    if (error__RETRIEVE_PROFILE__INITIALIZE_IF_NEEDED) {
        return <NetworkError />;
    }

    if (data.retrieveProfile__initializeIfNeeded.settings !== null) {
        setLocalStorage(
            'tz',
            data.retrieveProfile__initializeIfNeeded.settings.timezone
        );
    }

    return (
        <IonPage>
            {toggleMenu ? (
                <IonCol className='menu__sidebar--active'>
                    <UserMenu
                        handleMenuToggle={handleMenuToggle}
                        userUid={fuser.uid}
                    />
                </IonCol>
            ) : (
                <IonCol className='menu__sidebar--inactive' />
            )}
            {toggleMenu ? (
                <UserMenuBackdrop handleMenuToggle={handleMenuToggle} />
            ) : null}
            <IonContent>
                <Refresher handleReload={handleReload} />
                {/* <UserIcon onClick={handleMenuToggle} /> */}
                <IonGrid className='homeInterface__wrap'>
                    <IonRow className='profileStates__tabs--container'>
                        <IonCol
                            sizeXs='12'
                            sizeSm='12'
                            sizeMd='12'
                            className='profileStates__tabs--wrap'
                        >
                            <IonRow className='header__buttons'>
                                <IonButton
                                    fill='clear'
                                    onClick={handleMenuToggle}
                                    color='ion-primary'
                                    className='profileStates__tabs--btn'
                                >
                                    <IonIcon
                                        className='profileStates__tabs--btn--icon'
                                        icon={menuOutline}
                                    />
                                </IonButton>
                                <img
                                    className='homeGratitudePotImg'
                                    src={gratitudePot}
                                    alt='Gratitude Pot'
                                    onClick={() => {
                                        history.push('/settings/gratitudePot');
                                    }}
                                />
                            </IonRow>
                        </IonCol>
                    </IonRow>

                    {/* home profile header start */}
                    {getLocalStorage('currentAdminGroupId') == 0 ? (
                        <GreenStrokesHeader
                            isHome // this calls greenStrokesHeader__Home from greenStrokesHeader.tsx
                            profileImg={
                                !loading &&
                                data.retrieveProfile__initializeIfNeeded
                                    .profilePicture
                            }
                        />
                    ) : (
                        <GreenStrokesHeader
                            isHome
                            profileImg={
                                !loading &&
                                getLocalStorage('currentAdminGroupPicture')
                            }
                        />
                    )}
                    {/* profile bio start */}
                    <IonRow className='homeInterface__edit'>
                        <IonCol
                            sizeXs='12'
                            sizeSm='12'
                            className='homeInterface__edit--col--btn'
                        >
                            {getLocalStorage('currentAdminGroupId') == 0 ? (
                                <IonButton
                                    fill='clear'
                                    onClick={() =>
                                        history.push(`/portfolio/${fuser.uid}`)
                                    }
                                    expand='block'
                                    className='homeInterface__edit--btn'
                                    color='ion-primary'
                                >
                                    Go to profile{' '}
                                    <IonIcon
                                        icon={arrowForwardOutline}
                                        slot='end'
                                    />
                                </IonButton>
                            ) : (
                                <IonButton
                                    fill='clear'
                                    onClick={() =>
                                        history.push(
                                            `/group/${getLocalStorage(
                                                'currentAdminGroupId'
                                            )}/`
                                        )
                                    }
                                    expand='block'
                                    className='homeInterface__edit--btn'
                                    color='ion-primary'
                                >
                                    Go to group{' '}
                                    <IonIcon
                                        icon={arrowForwardOutline}
                                        slot='end'
                                    />
                                </IonButton>
                            )}
                        </IonCol>
                    </IonRow>
                    <Schedule />
                    <IonGrid className='spendAndEarn__container'>
                        <IonRow className='spendAndEarn__title--row'>
                            <IonCol sizeXs='12' sizeSm='12'>
                                {/* <IonRow className='spend__title__button'> */}
                                <h1 className='spend__title'>Spend</h1>
                                <IonRow className='spend__description--wrap'>
                                    <h6 className='spend__description'>
                                        Spend
                                        <img
                                            className='currencyDisplay__home__img__inline'
                                            src={balanceToken}
                                            alt='Balance Tokens'
                                        />
                                        Tokens by creating a task or requesting
                                        a skill from a group member
                                    </h6>
                                </IonRow>

                                <IonRow className='spendHeader'>
                                    <IonButton
                                        fill='clear'
                                        onClick={() =>
                                            history.push('/create-task')
                                        }
                                        color='ion-primary'
                                        className='homeInterface__btn'
                                    >
                                        Create A Task{' '}
                                        <IonIcon
                                            icon={arrowForwardOutline}
                                            slot='end'
                                        />
                                    </IonButton>
                                </IonRow>
                            </IonCol>
                            <IonRow className='earnHeader'>
                                <IonCol
                                    sizeXs='12'
                                    sizeSm='12'
                                    className='earn__col--wrap'
                                >
                                    <h1 className='earn--title'>Earn</h1>
                                    <IonRow className='earn__description--wrap'>
                                        <h6 className='earn__description'>
                                            Earn
                                            <img
                                                className='currencyDisplay__home__img__inline'
                                                src={balanceToken}
                                                alt='Balance Tokens'
                                            />
                                            Tokens by participating in a task
                                        </h6>
                                    </IonRow>
                                </IonCol>
                            </IonRow>
                            <EarnSection />
                        </IonRow>
                    </IonGrid>
                </IonGrid>
            </IonContent>
            <NavBar />
        </IonPage>
    );
};

export default UserHomeProfile;
