// importing modules
import React, { useState } from 'react';
// importing components
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonLabel,
    IonModal,
    IonRow,
} from '@ionic/react';
import Strokes from 'components/Strokes';
// importing assets
// importing graphql
import { useMutation } from '@apollo/react-hooks';
import {
    UPDATE_SKILL_BY_ID,
    UPDATE_SKILL_BY_IDVariables,
} from '../../../GraphQL/__generated__/UPDATE_SKILL_BY_ID';
import { gql_UPDATE_SKILL_BY_ID } from '../../../GraphQL/Skill/UPDATE_SKILL_BY_ID/UPDATE_SKILL_BY_ID';
import { yingDebug, yingInfo } from '../../../utils/debug/YingDebug';
import { USER_EDIT_PROFILE_DATA } from '../../../GraphQL/Profile/EditUserProfile/editUserProfile';
// importing styles
import './EditSkillModal.css';

const EditSkillModal: React.FC<{
    showSkillModal: boolean;
    handleSkillModal: () => void;
    id: number;
    uid: string;
    skillName: string;
    skillDescription: string;
}> = ({
    showSkillModal,
    handleSkillModal,
    id,
    uid,
    skillName,
    skillDescription,
}) => {
    const [skillDesc, setSkillDesc] = useState<string>('');
    const [skillType, setSkillType] = useState<string>('');
    const [f_UPDATE_SKILL_BY_ID, data_UPDATE_SKILL_BY_ID] = useMutation<
        UPDATE_SKILL_BY_ID,
        UPDATE_SKILL_BY_IDVariables
    >(gql_UPDATE_SKILL_BY_ID);

    const handleSkillUpdate = () => {
        f_UPDATE_SKILL_BY_ID({
            variables: {
                description: skillDesc,
                name: skillType,
                id: id,
            },
            refetchQueries: [
                {
                    query: USER_EDIT_PROFILE_DATA,
                    variables: {
                        uid: uid,
                    },
                },
            ],
        })
            .catch((e) => yingDebug(e))
            .then((r) => yingInfo(JSON.stringify(r)))
            .finally(handleSkillModal);
    };

    return (
        <IonModal isOpen={showSkillModal}>
            <IonContent scrollY={false}>
                <IonGrid className='editSkill__grid'>
                    <Strokes green={false} />
                    <IonRow className='editSkill__row'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='editSkill__container'
                        >
                            <IonCol
                                sizeSm='12'
                                sizeXs='12'
                                className='editSkill__content--container'
                            >
                                <h1 className='editSkill__content--title'>
                                    <span>Update</span> Your Skill
                                </h1>
                                <p className='editSkill__content--text'>
                                    Provide updated details
                                </p>
                                <IonGrid className='editSkill__form--container'>
                                    <IonRow className='editSkill__form--row'>
                                        <IonCol
                                            className='editSkill__form--input--wrap'
                                            sizeXs='12'
                                            sizeSm='12'
                                        >
                                            <IonLabel className='editSkill__form--label'>
                                                Skill Type (Optional)
                                            </IonLabel>
                                            <input
                                                onChange={(event) =>
                                                    setSkillType(
                                                        event.target.value
                                                    )
                                                }
                                                defaultValue={skillName}
                                                className='editSkill__form--input'
                                                placeholder='Tell people specifically what you offer'
                                                type='text'
                                                required
                                            />
                                        </IonCol>
                                        <IonCol
                                            className='editSkill__form--input--wrap'
                                            sizeXs='12'
                                            sizeSm='12'
                                        >
                                            <IonLabel className='editSkill__form--label'>
                                                Describe Your Skill (Optional)
                                            </IonLabel>
                                            <textarea
                                                className='defSkill__form--input'
                                                onChange={(event) =>
                                                    setSkillDesc(
                                                        event.target.value
                                                    )
                                                }
                                                defaultValue={skillDescription}
                                                placeholder='Tell people about your offering...'
                                                required
                                            ></textarea>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className='editSkill__btn--container'>
                                        <IonCol
                                            className='editSkill__btn--wrap'
                                            sizeXs='12'
                                            sizeSm='12'
                                        >
                                            <IonButton
                                                onClick={handleSkillModal}
                                                fill='clear'
                                                color='ion-primary'
                                                className='editSkill__btn--cancel'
                                            >
                                                Cancel
                                            </IonButton>
                                            <IonButton
                                                onClick={handleSkillUpdate}
                                                // disabled={
                                                //     skillType.length === 0 // && skillDesc.length === 0
                                                // }
                                                fill='clear'
                                                color='ion-primary'
                                                className='editSkill__btn--update'
                                            >
                                                Update Skill
                                            </IonButton>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </IonCol>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonModal>
    );
};

export default EditSkillModal;
