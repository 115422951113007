// importing modules
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import faker from 'faker';
// importing components
import {
    IonButton,
    IonCol,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonSpinner,
} from '@ionic/react';
import Loading from 'components/status/Loading';
// importing helpers
import { setLocalStorage } from 'helpers/localStorage/localStorageHelper';
// importing types & graphql
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import {
    AddAdminToRoles,
    AddAdminToRolesVariables,
} from 'GraphQL/__generated__/AddAdminToRoles';
import { gql_addUserToRoles } from 'GraphQL/addUserToRoles/addUserToRoles';
import {
    UserProfile_byID,
    UserProfile_byIDVariables,
} from 'GraphQL/__generated__/UserProfile_byID';
import { USER_PROFILE_BY_UID } from 'GraphQL/Profile/UserProfile/USER_PROFILE_BY_UID';
import {
    MatriculateOrg,
    MatriculateOrgVariables,
} from 'GraphQL/__generated__/MatriculateOrg';
import { gql_MATRICULATE_ORG } from 'GraphQL/organization/MATRICULATE_ORG/MATRICULATE_ORG';
import { MAILCHIMPCOMMUNITYONBOARDINGADMIN } from 'GraphQL/mailchimp/MailchimpCommunityOnboardingAdmin';
import { gql_ADD_GROUP_TO_PROFILE } from 'GraphQL/Profile/ADD_GROUP_TO_PROFILE/ADD_GROUP_TO_PROFILE';
import {
    ADD_GROUP_TO_PROFILE,
    ADD_GROUP_TO_PROFILEVariables,
} from 'GraphQL/__generated__/ADD_GROUP_TO_PROFILE';
import { USER_MENU_STATE } from 'GraphQL/Profile/USER_MENU_STATE/USER_MENU_STATE';
// import group bank account creation
import {
    groupAccountCreation,
    groupAccountCreationVariables,
} from 'GraphQL/__generated__/groupAccountCreation';
import { GROUP_ACCOUNT_CREATION } from 'GraphQL/groupAccountCreation/groupAccountCreation';
import { accountType } from 'GraphQL/__generated__/globalTypes';
// importing styles
import '../CommunityOnboarding.css';
// firebase Context
import { useAuth } from 'context/firebase/authContext';

type CommunityOnboardForm = {
    // form types
    yingAccessCode: string;
    orgName: string;
    orgSite: string;
    orgIndustry: string;
    orgType: string;
    orgEin: string;
    orgDescription: string;
    memberAccessCode: string;
};

const defaultVals = {
    // form default values
    yingAccessCode: '',
    orgName: '',
    orgSite: '',
    orgIndustry: '',
    orgType: 'Corporation',
    orgEin: '',
    orgDescription: '',
    memberAccessCode: '',
};

export default () => {
    const { currentUser } = useAuth();
    const history = useHistory();
    const { register, handleSubmit, errors } = useForm<CommunityOnboardForm>({
        defaultValues: defaultVals,
    });
    const [orgType, setOrgType] = useState<string>('Corporation');
    const [yingCodeError, setYingCodeError] = useState<string>('');
    const [addUserToAdmin, addGroupToAdminDatas] = useMutation<
        AddAdminToRoles,
        AddAdminToRolesVariables
    >(gql_addUserToRoles);
    const [AccountCreation, AccountCreationDatas] = useMutation<
        groupAccountCreation,
        groupAccountCreationVariables
    >(GROUP_ACCOUNT_CREATION);
    const [
        matriculateOrg,
        { data: orgData, loading: orgLoading, error: orgError },
    ] = useMutation<MatriculateOrg, MatriculateOrgVariables>(
        gql_MATRICULATE_ORG
    );
    const [addGroupToProfile, addGroupToProfileDatas] = useMutation<
        ADD_GROUP_TO_PROFILE,
        ADD_GROUP_TO_PROFILEVariables
    >(gql_ADD_GROUP_TO_PROFILE);
    const [mailchimpSend, { loading, data: chimpSend }] = useLazyQuery(
        MAILCHIMPCOMMUNITYONBOARDINGADMIN
    );
    //const [userIdLookUp, { loading: foo,  data: bar }] = useLazyQuery(GET_PROFILE_ID);
    const {
        loading: testOne,
        error: testTwo,
        data: testThree,
    } = useQuery<UserProfile_byID, UserProfile_byIDVariables>(
        USER_PROFILE_BY_UID,
        {
            variables: { subId: currentUser.uid },
        }
    );

    if (testOne) {
        return (
            <div className='communityOnBoard__spinner--wrap'>
                <IonSpinner
                    className='communityOnBoard__spinner'
                    name='crescent'
                />
            </div>
        );
    }

    const handleCommunityOnboard = (data: CommunityOnboardForm) => {
        matriculateOrg({
            variables: {
                access_code: data.yingAccessCode,
                category: orgType,
                member_code: data.memberAccessCode,
                description: data.orgDescription,
                ein: data.orgEin ? data.orgEin : '000000000',
                industry: data.orgIndustry,
                name: data.orgName,
                website: data.orgSite ? data.orgSite : 'n/a',
                contact_email: currentUser.email,
                uid: currentUser.uid,
            },
        })
            .then((result) => {
                addGroupToProfile({
                    variables: {
                        uid: currentUser.uid,
                        code: data.memberAccessCode,
                    },
                    awaitRefetchQueries: true,
                    refetchQueries: [
                        {
                            query: USER_MENU_STATE,
                            variables: { subId: currentUser.uid },
                        },
                    ],
                })
                    .then((r) => {
                        if (r.data.addGroupToProfile) {
                            setLocalStorage('groupMeta', {
                                // set in local storage
                                id: r.data.addGroupToProfile.id,
                                name: r.data.addGroupToProfile.name,
                                code: data.memberAccessCode,
                                orgPicture: r.data.addGroupToProfile.orgPicture,
                            });
                        }
                    })
                    .catch((e) => console.error(e));

                // adds a role to a user when a group is created
                addUserToAdmin({
                    variables: {
                        profile: testThree.profile.id,
                        organization: result.data.matriculateOrganization.id,
                        roles: 1,
                    },
                }).catch((e) => console.error('add user to admin', e));

                // create group accounts
                AccountCreation({
                    variables: {
                        bankaccountid: parseInt(faker.finance.account(), 10),
                        groupIdFk: result.data.matriculateOrganization.id,
                        accountType: accountType.BALANCE,
                    },
                }).then((r) => {
                    AccountCreation({
                        variables: {
                            bankaccountid: parseInt(
                                faker.finance.account(),
                                10
                            ),
                            groupIdFk: result.data.matriculateOrganization.id,
                            accountType: accountType.FIAT,
                        },
                    });
                });

                // TODO: do this asynchronously
                try {
                    mailchimpSend({
                        variables: {
                            email: currentUser.email,
                            fname: currentUser.displayName,
                            groupcode: data.memberAccessCode,
                        },
                    });
                } catch (e) {
                    console.error(e);
                }

                const regex = / /gi;
                const GroupNamepath = data.orgName.replace(regex, '%20');
                const GroupAccessCode = data.memberAccessCode.replace(
                    regex,
                    '%20'
                );
                const organizationObject = {
                    code: data.memberAccessCode,
                    url: `yingme.app/invite/${GroupNamepath}/${GroupAccessCode}`,
                    groupName: data.orgName,
                    groupId: result.data.matriculateOrganization.id,
                };
                localStorage.setItem(
                    'organizationObject',
                    JSON.stringify(organizationObject)
                );
                history.push('/communityonboarding/confirmation');
            })
            .catch((error) => console.error('creating org error: ', error));
    };

    return (
        <form
            onSubmit={handleSubmit(handleCommunityOnboard)}
            className='communityOnboard__form--container'
        >
            {/* acccess code header */}
            <IonRow className='communityOnboard__form--accessCode--title--row'>
                <IonCol
                    sizeXs='10'
                    sizeSm='10'
                    sizeMd='10'
                    sizeLg='10'
                    sizeXl='10'
                    className='communityOnboard__form--accessCode--title--col'
                >
                    <p className='communityOnboard__form--accessCode--title'>
                        Enter your unique access code which was provided to you
                        by YING.
                    </p>
                </IonCol>
            </IonRow>
            {/* access code form input */}
            <IonRow className='communityOnboard__form--input--row'>
                {orgError &&
                    orgError.graphQLErrors.map((err) => {
                        if (err.message === 'INVALID_YING_ACCESS_CODE') {
                            return (
                                <IonCol
                                    key={err.message}
                                    sizeXs='10'
                                    sizeSm='10'
                                    sizeMd='10'
                                    sizeLg='10'
                                    sizeXl='10'
                                    className='communityOnboard__form--error--wrap'
                                >
                                    <p className='communityOnboard__form--error'>
                                        OOPS! Invalid YING access code!
                                    </p>
                                </IonCol>
                            );
                        }
                    })}
                {errors.yingAccessCode && (
                    <IonCol
                        sizeXs='10'
                        sizeSm='10'
                        sizeMd='10'
                        sizeLg='10'
                        sizeXl='10'
                        className='communityOnboard__form--error--wrap'
                    >
                        <p className='communityOnboard__form--error'>
                            {errors.yingAccessCode.message}
                        </p>
                    </IonCol>
                )}
                <IonCol
                    sizeXs='10'
                    sizeSm='10'
                    sizeMd='10'
                    sizeLg='10'
                    sizeXl='10'
                    className='communityOnboard__form--input--col'
                >
                    <input
                        ref={register({ required: 'Access Code is required' })}
                        name='yingAccessCode'
                        type='text'
                        placeholder='Access code (given to you by YING)'
                        className={
                            errors.yingAccessCode || yingCodeError.length > 0
                                ? 'communityOnboard__form--input communityOnboard__form--input--error'
                                : 'communityOnboard__form--input'
                        }
                        autoComplete='off'
                        formNoValidate
                    />
                </IonCol>
            </IonRow>
            {/* organization input fields */}
            {/* organization name */}
            <IonRow className='communityOnboard__form--label--row'>
                <IonCol
                    sizeXs='10'
                    sizeSm='10'
                    sizeMd='10'
                    sizeLg='10'
                    sizeXl='10'
                    className='communityOnboard__form--label--col'
                >
                    <h1 className='communityOnboard__form--label'>
                        1. Tell us about <span>your Organization</span>
                    </h1>
                </IonCol>
            </IonRow>
            <IonRow className='communityOnboard__form--input--row'>
                {/* error msg */}
                {errors.orgName && (
                    <IonCol
                        sizeXs='10'
                        sizeSm='10'
                        sizeMd='10'
                        sizeLg='10'
                        sizeXl='10'
                        className='communityOnboard__form--error--wrap'
                    >
                        <p className='communityOnboard__form--error'>
                            {errors.orgName.message}
                        </p>
                    </IonCol>
                )}
                <IonCol
                    sizeXs='10'
                    sizeSm='10'
                    sizeMd='10'
                    sizeLg='10'
                    sizeXl='10'
                    className='communityOnboard__form--input--col'
                >
                    <input
                        ref={register({
                            required: 'Organization name is required',
                        })}
                        name='orgName'
                        type='text'
                        placeholder='Organization Name'
                        className={
                            errors.orgName
                                ? 'communityOnboard__form--input communityOnboard__form--input--error'
                                : 'communityOnboard__form--input'
                        }
                        autoComplete='off'
                        formNoValidate
                    />
                </IonCol>
                <IonCol
                    sizeXs='10'
                    sizeSm='10'
                    sizeMd='10'
                    sizeLg='10'
                    sizeXl='10'
                    className='communityOnboard__form--input--col'
                >
                    <input
                        name='orgSite'
                        type='text'
                        placeholder='Website (optional)'
                        className='communityOnboard__form--input'
                        autoComplete='off'
                        formNoValidate
                    />
                </IonCol>
            </IonRow>
            {/* organization description */}
            <IonRow className='communityOnboard__form--label--row'>
                <IonCol
                    sizeXs='10'
                    sizeSm='10'
                    sizeMd='10'
                    sizeLg='10'
                    sizeXl='10'
                    className='communityOnboard__form--label--col'
                >
                    <h1 className='communityOnboard__form--label'>
                        2. <span>Describe</span> your organization
                    </h1>
                </IonCol>
            </IonRow>
            <IonRow className='communityOnboard__form--input--row'>
                {/* error msg */}
                {errors.orgIndustry && (
                    <IonCol
                        sizeXs='10'
                        sizeSm='10'
                        sizeMd='10'
                        sizeLg='10'
                        sizeXl='10'
                        className='communityOnboard__form--error--wrap'
                    >
                        <p className='communityOnboard__form--error'>
                            {errors.orgIndustry.message}
                        </p>
                    </IonCol>
                )}
                <IonCol
                    sizeXs='10'
                    sizeSm='10'
                    sizeMd='10'
                    sizeLg='10'
                    sizeXl='10'
                    className='communityOnboard__form--input--col'
                >
                    <input
                        ref={register({ required: 'Industry is required' })}
                        name='orgIndustry'
                        type='text'
                        placeholder='Industry'
                        className={
                            errors.orgIndustry
                                ? 'communityOnboard__form--input communityOnboard__form--input--error'
                                : 'communityOnboard__form--input'
                        }
                        autoComplete='off'
                        formNoValidate
                    />
                </IonCol>
                <IonCol
                    sizeXs='10'
                    sizeSm='10'
                    sizeMd='10'
                    sizeLg='10'
                    sizeXl='10'
                    className='communityOnboard__form--input--col'
                >
                    <IonSelect
                        name='orgType'
                        onIonChange={(e) => setOrgType(e.detail.value)}
                        ok-text='Accept'
                        cancel-text='Cancel'
                        value={orgType}
                        className='communityOnboard__form--input'
                    >
                        <IonSelectOption
                            className='drop__select'
                            value='Non-Profit'
                        >
                            Non-Profit
                        </IonSelectOption>
                        <IonSelectOption value='Corporation'>
                            Corporation
                        </IonSelectOption>
                        <IonSelectOption value='Other'>Other</IonSelectOption>
                    </IonSelect>
                </IonCol>
                {/* error msg */}
                {errors.orgEin && (
                    <IonCol
                        sizeXs='10'
                        sizeSm='10'
                        sizeMd='10'
                        sizeLg='10'
                        sizeXl='10'
                        className='communityOnboard__form--error--wrap'
                    >
                        <p className='communityOnboard__form--error'>
                            {errors.orgEin.message}
                        </p>
                    </IonCol>
                )}
                {orgType === 'Non-Profit' && (
                    <IonCol
                        sizeXs='10'
                        sizeSm='10'
                        sizeMd='10'
                        sizeLg='10'
                        sizeXl='10'
                        className='communityOnboard__form--input--col'
                    >
                        <input
                            ref={register({ required: 'EIN# is required' })}
                            name='orgEin'
                            type='text'
                            placeholder='EIN #'
                            className={
                                errors.orgEin
                                    ? 'communityOnboard__form--input communityOnboard__form--input--error'
                                    : 'communityOnboard__form--input'
                            }
                            autoComplete='off'
                            formNoValidate
                        />
                    </IonCol>
                )}
                {/* error msg */}
                {errors.orgDescription && (
                    <IonCol
                        sizeXs='10'
                        sizeSm='10'
                        sizeMd='10'
                        sizeLg='10'
                        sizeXl='10'
                        className='communityOnboard__form--error--wrap'
                    >
                        <p className='communityOnboard__form--error'>
                            {errors.orgDescription.message}
                        </p>
                    </IonCol>
                )}
                <IonCol
                    sizeXs='10'
                    sizeSm='10'
                    sizeMd='10'
                    sizeLg='10'
                    sizeXl='10'
                    className='communityOnboard__form--input--col'
                >
                    <textarea
                        ref={register({ required: 'Description is required' })}
                        name='orgDescription'
                        placeholder='Description'
                        className={
                            errors.orgDescription
                                ? 'communityOnboard__form--input communityOnboard__form--input--error'
                                : 'communityOnboard__form--input'
                        }
                        autoComplete='off'
                    ></textarea>
                </IonCol>
            </IonRow>
            {/* access code for members */}
            <IonRow className='communityOnboard__form--label--row communityOnboard__form--label--row--final'>
                <IonCol
                    sizeXs='10'
                    sizeSm='10'
                    sizeMd='10'
                    sizeLg='10'
                    sizeXl='10'
                    className='communityOnboard__form--label--col'
                >
                    <h1 className='communityOnboard__form--label'>
                        3. Now, let's <span>gather</span> your group together
                    </h1>
                </IonCol>
            </IonRow>
            <IonRow className='communityOnboard__form--accessCode--title--row'>
                <IonCol
                    sizeXs='10'
                    sizeSm='10'
                    sizeMd='10'
                    sizeLg='10'
                    sizeXl='10'
                    className='communityOnboard__form--accessCode--title--col'
                >
                    <p className='communityOnboard__form--accessCode--title'>
                        Come up with an access code for your organization. You
                        will give this code to your members, they will need this
                        to join your group.
                    </p>
                </IonCol>
            </IonRow>
            <IonRow className='communityOnboard__form--input--row'>
                {/* error msg */}
                {orgError &&
                    orgError.graphQLErrors.map((err) => {
                        if (err.message === 'INVALID_ORG_ACCESS_CODE') {
                            return (
                                <IonCol
                                    key={err.message}
                                    sizeXs='10'
                                    sizeSm='10'
                                    sizeMd='10'
                                    sizeLg='10'
                                    sizeXl='10'
                                    className='communityOnboard__form--error--wrap'
                                >
                                    <p className='communityOnboard__form--error'>
                                        OOPS! That code is already taken!
                                    </p>
                                </IonCol>
                            );
                        }
                    })}
                {errors.memberAccessCode && (
                    <IonCol
                        sizeXs='10'
                        sizeSm='10'
                        sizeMd='10'
                        sizeLg='10'
                        sizeXl='10'
                        className='communityOnboard__form--error--wrap'
                    >
                        <p className='communityOnboard__form--error'>
                            {errors.memberAccessCode.message}
                        </p>
                    </IonCol>
                )}
                <IonCol
                    sizeXs='10'
                    sizeSm='10'
                    sizeMd='10'
                    sizeLg='10'
                    sizeXl='10'
                    className='communityOnboard__form--input--col'
                >
                    <input
                        ref={register({
                            required: 'Organization Access Code required',
                            minLength: {
                                value: 8,
                                message:
                                    'Access code must be at least 8 characters',
                            },
                        })}
                        name='memberAccessCode'
                        type='text'
                        placeholder='Access Code for your Members'
                        className={
                            errors.memberAccessCode
                                ? 'communityOnboard__form--input communityOnboard__form--input--error'
                                : 'communityOnboard__form--input'
                        }
                        autoComplete='off'
                        formNoValidate
                    />
                </IonCol>
            </IonRow>
            {/* submit btn */}
            <IonRow className='communityOnboard__form--input--row'>
                <IonCol
                    sizeXs='10'
                    sizeSm='10'
                    sizeMd='10'
                    sizeLg='10'
                    sizeXl='10'
                    className='communityOnboard__form--input--col'
                >
                    <IonButton
                        fill='clear'
                        color='ion-primary'
                        className='communityOnboard__form--submit'
                        type='submit'
                    >
                        Finish & Continue
                    </IonButton>
                </IonCol>
            </IonRow>
        </form>
    );
};
