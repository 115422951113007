// importing modules
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
// importing components
import { IonCol } from '@ionic/react';
import BackButton from 'components/BackButton';
import CancelButton from 'components/CancelButton/CancelButton';
// importing helpers
import { setLocalStorage } from '../../../../helpers/localStorage/localStorageHelper';
// importing styles
import './CreateTaskTabs.css';
import { Route } from '@storybook/router';

const CreateTaskTabs: React.FC<{
    disablePrevStep: boolean;
}> = ({ disablePrevStep }) => {
    const history = useHistory();

    const location = useLocation();
    const dateSelect = JSON.parse(localStorage.getItem('createTask'));
    const selectedDate = dateSelect['date'];
    const selectedTime = dateSelect['startTime'];
    console.log(dateSelect);

    const handleBackArrow = () => {
        if (
            selectedDate === 'Undecided' &&
            location.pathname.includes('/create-task/skills')
        ) {
            //if date is TBD and we're not on location page, just go back one page over as usual on back arrow click
            history.goBack();
        } else if (
            selectedDate === 'Undecided' &&
            location.pathname.includes('/create-task/location')
        ) {
            //if date is TBD and we're on location page, skip select a time page on back arrow click
            history.go(-2);
        } else {
            history.goBack();
        }
    };

    return (
        <IonCol
            sizeXs='12'
            sizeSm='12'
            sizeMd='12'
            className='completeTaskNav__btns--wrap'
        >
            {disablePrevStep ? (
                <p></p>
            ) : (
                <span>
                    <BackButton color={'white'} onClick={handleBackArrow} />
                </span>
            )}
            <CancelButton />
        </IonCol>
    );
};

export default CreateTaskTabs;
