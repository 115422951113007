// importing modules
import React from 'react';
import { useHistory } from 'react-router';
import moment from 'moment-timezone';
// importing components
import { IonCol, IonImg } from '@ionic/react';
// importing utilities
import shuffle from '../../../utils/shuffle';
import { getLocalStorage } from '../../../helpers/localStorage/localStorageHelper';
import limitStr from '../../../utils/limitString';
// importing types
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { GetJobPostsFromManyOrgs_jobPostsFromManyOrgs } from '../../../GraphQL/__generated__/GetJobPostsFromManyOrgs';
import { SkillPoolFromManyOrgs_profilesFromManyOrgs } from 'GraphQL/__generated__/SkillPoolFromManyOrgs';
import { ADMIN_GROUP_PROFILE_PICTURE } from '../../../GraphQL/Admin/adminGroupProfilePicture/adminGroupProfilePicture';

const EarnSectionTile: React.FC<{
    jobPost: GetJobPostsFromManyOrgs_jobPostsFromManyOrgs;
}> = ({ jobPost }) => {
    const history = useHistory();
    const firstThreeOrgs = shuffle(jobPost.organizations).slice(0, 3);

    const { data, loading, error } = useQuery(ADMIN_GROUP_PROFILE_PICTURE, {
        variables: { adminId: jobPost.adminId },
    });

    return (
        <IonCol
            className='earnSectionTile'
            sizeSm='11'
            sizeXs='11'
            onClick={() => history.push(`/task/${jobPost.id}`)}
        >
            <IonCol sizeXs='12' sizeSm='12' className='earnSectionTile__info'>
                <IonCol sizeXl='3' sizeLg='3' sizeMd='3' sizeXs='3' sizeSm='3'>
                    <div className='earnSectionTile__img--wrap'>
                        {/* This image shows who posted the task */}

                        <IonImg
                            className='earnSectionTile__img'
                            src={
                                jobPost.adminId
                                    ? loading
                                        ? null
                                        : data.AdminGroupProfilePicture
                                              .orgPicture
                                    : jobPost.recipient.profilePicture
                            }
                        />
                    </div>
                </IonCol>
                <IonCol
                    className='earnSectionTile__info--wrap'
                    sizeXs='9'
                    sizeSm='9'
                >
                    <h3 className='earnSectionTile__info--name'>
                        {limitStr(jobPost.title, 32)}
                    </h3>
                    <div className='earnSectionTile__info--postedBy'>
                        {' '}
                        <span>
                            <div className='earnSectionTile__group--wrap'>
                                {' '}
                                {firstThreeOrgs.map((org) => {
                                    return (
                                        // This image shows the group(s) that the task was posted to
                                        <IonImg
                                            className='earnSectionTile__info--group'
                                            src={
                                                org.orgPicture === null ||
                                                org.orgPicture.length === 0
                                                    ? 'https://is3-ssl.mzstatic.com/image/thumb/Purple123/v4/3e/62/e2/3e62e239-f433-bc84-3775-21af60499ec7/source/512x512bb.jpg'
                                                    : loading
                                                    ? null
                                                    : org.orgPicture
                                            }
                                            // alt={org.name}
                                            key={org.id}
                                        />
                                    );
                                })}
                                {/* If the task is posted to more than three groups, the user will see the additional groups represented as a number, for example "+4" */}
                                {jobPost.organizations.length > 3 && (
                                    <p className='earnSectionTile__group--list--count'>
                                        +{jobPost.organizations.length - 3}
                                    </p>
                                )}
                            </div>
                        </span>{' '}
                        {/* This shows the name of user or group admin that posted the task */}
                        Posted by{' '}
                        {jobPost.adminId
                            ? loading
                                ? null
                                : `Admin of ${data.AdminGroupProfilePicture.name}`
                            : `${jobPost.recipient.firstName} ${jobPost.recipient.lastName}`}
                    </div>
                </IonCol>
            </IonCol>
            <IonCol sizeXs='12' sizeSm='12'>
                <p className='earnSectionTile__info--descr'>
                    {limitStr(jobPost.description, 80)}
                </p>
            </IonCol>
        </IonCol>
    );
};

export default EarnSectionTile;
