import React from 'react';
import { useHistory } from 'react-router-dom';
// importing components
import { IonButton, IonCol, IonIcon } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import BackButton from 'components/BackButton';
import CancelButton from 'components/CancelButton/CancelButton';
// importing helpers
import { setLocalStorage } from '../../../../helpers/localStorage/localStorageHelper';
// importing styles
import './CompleteTaskTabs.css';

const CompleteTaskTabs: React.FC<{
    prevPage: string;
    disablePrevStep: boolean;
}> = ({ disablePrevStep, prevPage }) => {
    const history = useHistory();
    return (
        <IonCol
            sizeXs='12'
            sizeSm='12'
            sizeMd='12'
            className='completeTaskNav__btns--wrap'
        >
            {disablePrevStep ? <p></p> : <BackButton color='white' />}
            <CancelButton />
        </IonCol>
    );
};

export default CompleteTaskTabs;
