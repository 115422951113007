import React from 'react';
import strokes from 'assets/color-background.svg';
import { IonImg } from '@ionic/react';
import styles from './Strokes.module.css';

export default ({
    bot = false,
    top = false,
    green = true,
}: {
    //when green to true the background should be green an show the light colored strokes
    //when green is false the background is white and shows the dark colored strokes
    bot?: boolean;
    top?: boolean;
    green?: boolean;
}) => {
    // if both are left out then use both
    // this allows a user to specify only one or the other
    // but never neither
    if (!(top || bot)) {
        top = true;
        bot = true;
    }

    return (
        <>
            {top && (
                <>
                    {
                        <IonImg
                            className={
                                green
                                    ? styles.topStrokesForGreenScreen
                                    : styles.topStrokesForWhiteScreen
                            }
                            src={strokes}
                            // alt='strokes'
                        />
                    }
                </>
            )}
            {bot && (
                <IonImg
                    className={
                        green
                            ? styles.bottomStrokesForGreenScreen
                            : styles.bottomStrokesForWhiteScreen
                    }
                    src={strokes}
                    // alt='strokes'
                />
            )}
        </>
    );
};
