import moment from 'moment';

const getTaskTime = (dt) => {
    var date = moment(dt);
    const taskDate = date.utc().format('YYYY-MM-DD'); // constructed date / day
    const taskTime = date.utc().format('HH:mm:ss'); // constructed 24hr time
    return moment(`${taskDate}T${taskTime}`); // returns moment object of date passed in
};

export { getTaskTime as default };
