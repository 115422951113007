// importing modules
import React from 'react';
// importing components
import { IonRow } from '@ionic/react';
import HelpingTaskItem from '../taskItems/HelpingTaskItem';
import ReceivingTaskItem from '../taskItems/ReceivingTaskItem';
// importing graphql
import { GetPendingJobsById_pendingJobs } from '../../../GraphQL/__generated__/GetPendingJobsById';

const FilteredPendingTasksList: React.FC<{
    activeInbound: GetPendingJobsById_pendingJobs[];
    activeOutbound: GetPendingJobsById_pendingJobs[];
}> = ({ activeInbound, activeOutbound }) => {
    return (
        <IonRow className='completedTasksItem__row'>
            {activeOutbound.length === 0 && activeInbound.length === 0 ? (
                <p>No Pending Tasks match your search</p>
            ) : null}
            <IonRow className='tasks__row'>
                {activeInbound.length > 0 &&
                    activeInbound.map((task, id) => {
                        return (
                            <HelpingTaskItem
                                key={id}
                                otherProfile={task.recipient}
                                task={task}
                            />
                        );
                    })}
            </IonRow>
            <IonRow className='tasks__row'>
                {activeOutbound.length > 0 &&
                    activeOutbound.map((task, id) => {
                        return (
                            <ReceivingTaskItem
                                key={id}
                                otherProfile={task.provider}
                                task={task}
                            />
                        );
                    })}
            </IonRow>
        </IonRow>
    );
};

export default FilteredPendingTasksList;
