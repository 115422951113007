import React from 'react';
import { useHistory } from 'react-router-dom';
import { IonButton, IonIcon } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import { setLocalStorage } from 'helpers/localStorage/localStorageHelper';
import styles from './CancelButton.module.css';

interface CancelButtonProps {
    color?: string;
}

const CancelButton: React.FC<CancelButtonProps> = ({ color = 'white' }) => {
    const history = useHistory();

    return (
        <IonButton
            className={styles.btn}
            onClick={() => {
                const task = {
                    taskName: '',
                    taskDescr: '',
                    date: null,
                    startTime: '',
                    endTime: '',
                    location: '',
                    locationPreference: '',
                    skillsNeeded: [],
                    groupCodes: [],
                    memberInvites: [],
                };
                setLocalStorage('createTask', task);
                setLocalStorage('completeTaskViaRecipient', {
                    hrs: '',
                    mins: '',
                    review: '',
                    rating: 0,
                });
                setLocalStorage('completeTaskPostedViaRecipient', {
                    hrs: '',
                    mins: '',
                    participants: [],
                });
                setLocalStorage('completeTaskViaProvider', {
                    hrs: 0,
                    mins: 0,
                    review: '',
                    rating: 0,
                });
                history.push('/home');
            }}
            color='ion-primary'
            fill='clear'
        >
            <IonIcon
                className={styles.icon}
                icon={closeOutline}
                style={{ color }}
            />
        </IonButton>
    );
};

export default CancelButton;
