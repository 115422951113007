// importing modules
import React from 'react';
// importing components
import { IonButton, IonImg } from '@ionic/react';
// importing utilities
import limitStr from '../../../utils/limitString';

const OrganizationItem: React.FC<{
    id: number;
    name: string;
    orgPicture: string;
    handleMenuToggle: () => void;
}> = ({ id, handleMenuToggle, orgPicture, name }) => {
    return (
        <IonButton
            key={id}
            fill='clear'
            routerLink={`/group/${id}`}
            onClick={handleMenuToggle}
            color='ion-primary'
            className='menu__sidebar--item'
        >
            <span className='menu__sidebar--item--span'>
                <IonImg
                    className='menu__sidebar--item--img'
                    src={
                        orgPicture === null || orgPicture.length === 0
                            ? 'https://is3-ssl.mzstatic.com/image/thumb/Purple123/v4/3e/62/e2/3e62e239-f433-bc84-3775-21af60499ec7/source/512x512bb.jpg'
                            : orgPicture
                    }
                    alt='organization'
                />
                &nbsp;&nbsp; {limitStr(name, 15)}
            </span>
        </IonButton>
    );
};

export default OrganizationItem;
