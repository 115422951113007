// importing modules
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import moment, { min } from 'moment';
import momentTZ from 'moment-timezone';
// importing components
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonRow,
    IonPage,
    IonItem,
    IonLabel,
    IonDatetime,
    IonIcon,
} from '@ionic/react';
import UserImg from '../UserImg/UserImg';
import RequestTaskTabs from '../UI/RequestTaskTabs';
import Loading from '../../../components/status/Loading';
import Strokes from 'components/Strokes';
// importing assets
import { checkmarkOutline, colorFill } from 'ionicons/icons';
// importing helpers
import {
    getLocalStorage,
    updateLocalStorage,
    setLocalStorage,
} from '../../../helpers/localStorage/localStorageHelper';
import getTaskTime from '../../../utils/getTaskTime';
// importing graphql
import { useQuery } from '@apollo/react-hooks';
import { GET_JOBS_FOR_SCHEDULE_FILTERING } from '../../../GraphQL/Job/GET_JOBS_FOR_SCHEDULE_FILTERING/GET_JOBS_FOR_SCHEDULE_FILTERING';
import {
    GetJobsForScheduleFiltering,
    GetJobsForScheduleFilteringVariables,
} from '../../../GraphQL/__generated__/GetJobsForScheduleFiltering';
// importing styles
import './TaskTimeAvl.css';
import { useAuth } from '../../../context/firebase/authContext';
import {
    UserByUID,
    UserByUIDVariables,
} from '../../../GraphQL/__generated__/UserByUID';
import { USER_ORGANIZATIONS_BY_UID } from '../../../GraphQL/Profile/USER_ORGANIZATIONS/USER_ORGANIZATIONS_BY_UID';
import Schedule from '../TaskDayAvl/TaskDayAvl';
import { Button } from '@storybook/react/demo';
import { strict } from 'assert';
import { getClassName } from '@ionic/react/dist/types/components/utils';

const StartTime: React.FC = () => {
    const { currentUser } = useAuth();
    const history = useHistory();
    const { provider, skillName, skillId } = useParams<{
        provider: string;
        skillName: string;
        skillId: string;
    }>();

    const dateSelect = JSON.parse(localStorage.getItem('requestSkill'));
    const selectedDates = moment(dateSelect['date']).format(
        'dddd, MMMM DD, yyyy'
    );
    const previouslySelectedDate = dateSelect['startTime'];

    // const newSelectedDate = moment(baseTime).format('MMM DD')

    //console.log(dateSelect)

    const getBaseDay = () => {
        const now = new Date(selectedDates);
        const base = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate(),
            now.getHours()
        );
        //base.setHours(8);
        return base;
    };
    const [selectedStartTime, setSelectedStartTime] = useState<string>('');
    const [selectedEndTime, setSelectedEndTime] = useState<string>('');
    ////////
    const [tbd, setTBD] = useState(false);
    const [startTime, setStartTime] = useState<Date | null>(null);
    const [duration, setDuration] = useState<number | null>(null); //duration is in milliseconds
    const [baseTime, setBaseTime] = useState<Date>(getBaseDay());
    const [selectedDate, setSelectedDate] = useState<any>(null);
    const [unavailableTimes, setUnavailableTimes] = useState<UnavailableTime[]>(
        []
    );
    const newMessage: any = useRef();
    const [scrolled, setScrolled] = useState(false);
    const endTime =
        startTime && duration ? new Date(startTime.valueOf() + duration) : null;

    interface UnavailableTime {
        // this calculates the unavailable times range
        start: Date; // inclusive
        end: Date; // exclusive
    }

    useEffect(() => console.log(`Using ${baseTime} as base`), []);
    useEffect(() => console.log(`Using ${endTime} as endDuration`), []);

    let HOURS = [
        '12:00 AM',
        '12:15 AM',
        '12:30 AM',
        '12:45 AM',

        '1:00 AM',
        '1:15 AM',
        '1:30 AM',
        '1:45 AM',

        '2:00 AM',
        '2:15 AM',
        '2:30 AM',
        '2:45 AM',

        '3:00 AM',
        '3:15 AM',
        '3:30 AM',
        '3:45 AM',

        '4:00 AM',
        '4:15 AM',
        '4:30 AM',
        '4:45 AM',

        '5:00 AM',
        '5:15 AM',
        '5:30 AM',
        '5:45 AM',

        '6:00 AM',
        '6:15 AM',
        '6:30 AM',
        '6:45 AM',

        '7:00 AM',
        '7:15 AM',
        '7:30 AM',
        '7:45 AM',

        '8:00 AM',
        '8:15 AM',
        <div ref={newMessage} id='main-root'>
            {' '}
            8:30 AM
        </div>,
        '8:45 AM',

        '9:00 AM',
        '9:15 AM',
        '9:30 AM',
        '9:45 AM',

        '10:00 AM',
        '10:15 AM',
        '10:30 AM',
        '10:45 AM',

        '11:00 AM',
        '11:15 AM',
        '11:30 AM',
        '11:45 AM',

        '12:00 PM',
        '12:15 PM',
        '12:30 PM',
        '12:45 PM',

        '1:00 PM',
        '1:15 PM',
        '1:30 PM',
        '1:45 PM',

        '2:00 PM',
        '2:15 PM',
        '2:30 PM',
        '2:45 PM',

        '3:00 PM',
        '3:15 PM',
        '3:30 PM',
        '3:45 PM',

        '4:00 PM',
        '4:15 PM',
        '4:30 PM',
        '4:45 PM',

        '5:00 PM',
        '5:15 PM',
        '5:30 PM',
        '5:45 PM',

        '6:00 PM',
        '6:15 PM',
        '6:30 PM',
        '6:45 PM',

        '7:00 PM',
        '7:15 PM',
        '7:30 PM',
        '7:45 PM',

        '8:00 PM',
        '8:15 PM',
        '8:30 PM',
        '8:45 PM',

        '9:00 PM',
        '9:15 PM',
        '9:30 PM',
        '9:45 PM',

        '10:00 PM',
        '10:15 PM',
        '10:30 PM',
        '10:45 PM',

        '11:00 PM',
        '11:15 PM',
        '11:30 PM',
        '11:45 PM',
    ];

    useEffect(() => {
        //keeps 8:00AM time tile in view when page is loaded
        if (newMessage && newMessage.current && !scrolled) {
            newMessage.current.scrollIntoView({ block: 'start' });
            setScrolled(true);
        }
    });

    //newMessage.current.scrollIntoView({ block: 'center' })

    // const scrollToBottom = () => { //keeps 8:00AM time tile in view when page is loade
    //     if (newMessage.current) {
    //         newMessage.current.scrollIntoView({ block: 'center', behavior: 'smooth' })

    //     }
    // };

    let currentStartTime = moment(startTime).format('YYYY-MM-DD');

    useEffect(() => {
        const requestSkillLocal = getLocalStorage('requestSkill');
        if (requestSkillLocal) {
            const startTime = requestSkillLocal.startTime
                ? new Date(requestSkillLocal.startTime.toLocaleString())
                : null;
            const duration =
                startTime && requestSkillLocal.endTime
                    ? new Date(
                          requestSkillLocal.endTime.toLocaleString()
                      ).valueOf() - startTime.valueOf()
                    : null;
            setStartTime(startTime);
            setDuration(duration);
        } else {
            history.push('/home');
        }
    }, [history]);

    const requestSkillsObj = getLocalStorage('requestSkill');
    console.log(
        new Date(requestSkillsObj.startTime),
        new Date(requestSkillsObj.endTime)
    );

    const handleStartTimeSelected = () => {
        updateLocalStorage('requestSkill', 'date', baseTime); /// updates date selected on select a time if changed by clicking arrows
        updateLocalStorage('requestSkill', 'startTime', startTime);
        updateLocalStorage('requestSkill', 'endTime', endTime);

        history.push(
            `/requestSkill/${provider}/${skillName}/${skillId}/location`
        );
    };

    const { data, loading, error } = useQuery<
        GetJobsForScheduleFiltering, // query return return type (shape of data)
        GetJobsForScheduleFilteringVariables // query request type       (shape of variables)
    >(GET_JOBS_FOR_SCHEDULE_FILTERING, {
        variables: {
            providerUid: provider,
            recipientUid: currentUser.uid,
        },
    });

    const filteredScheduleByDate = data?.jobsForScheduleFiltering.filter(
        (job) => {
            const jobDate = momentTZ(job.startTime)
                .tz(getLocalStorage('tz'))
                .format('YYYY-MM-DD');
            const dateSelectedFormatted = moment(
                getLocalStorage('requestSkill').date
            ).format('YYYY-MM-DD');
            return jobDate === dateSelectedFormatted; // filter out all tasks from 1 particular day selected
        }
    );

    // this calculates the unavailable times
    useEffect(() => {
        console.log(filteredScheduleByDate);
        console.log(data?.jobsForScheduleFiltering);

        if (!loading && !error) {
            const startTimes = data?.jobsForScheduleFiltering.map(
                (job) => job.startTime
            );
            const endTimes = data?.jobsForScheduleFiltering.map(
                (job) => job.endTime
            );
            const unavailable: UnavailableTime[] = [];

            for (let i = 0; i < startTimes.length; ++i)
                unavailable.push({
                    start: new Date(startTimes[i]),
                    end: new Date(endTimes[i]),
                });

            setUnavailableTimes(unavailable);
        }
    }, [data, loading, error]);

    // this will determine whether a time is
    // available
    const isAvailable = (time: Date) => {
        const ts = time.valueOf(); //converts time to number

        for (const ut of unavailableTimes) {
            if (ts >= ut.start.valueOf() && ts < ut.end.valueOf()) {
                return false;
            }
        }

        return true;
    };

    //Gets organizations of both currentUser and service provider
    const { data: currentUserData, loading: currentUserDataLoading } = useQuery<
        UserByUID, // query return return type (shape of data)
        UserByUIDVariables // query request type       (shape of variables)
    >(USER_ORGANIZATIONS_BY_UID, {
        variables: {
            subId: currentUser.uid,
        },
    });
    const { data: providerUserData, loading: providerUserDataLoading } =
        useQuery<
            UserByUID, // query return return type (shape of data)
            UserByUIDVariables // query request type       (shape of variables)
        >(USER_ORGANIZATIONS_BY_UID, {
            variables: {
                subId: provider,
            },
        });

    if (loading || currentUserDataLoading || providerUserDataLoading) {
        return <Loading />;
    }

    //It checks if users have a group in common
    if (currentUserData != undefined && providerUserData != undefined) {
        const filteredArray = currentUserData.profile.organizations
            .map((orgs) => orgs.id)
            .filter((value) =>
                providerUserData.profile.organizations
                    .map((orgs) => orgs.id)
                    .includes(value)
            );

        if (filteredArray.length == 0) {
            return <Redirect to='/' />;
        }
    }

    // User cannot request himself.
    //Cannot be placed higher because hooks cannot be done condiniotaly
    if (provider == currentUser.uid) {
        return <Redirect to='/' />;
    }

    const disableDates = (jobsArr) => {
        return jobsArr.filter((job) => {
            const jobStartTimeInRecipientTZ = momentTZ(job.startTime)
                .tz(job.recipient.settings.timezone, false)
                .format('YYYY-MM-DDTHH:mm:ssZ'); // job converted to providers timezone
            const jobEndTimeInRecipientTZ = momentTZ(job.endTime)
                .tz(job.recipient.settings.timezone, false)
                .format('YYYY-MM-DDTHH:mm:ssZ'); // job converted to providers timezone
            const jobStartTimeInProviderTZ = momentTZ(job.startTime)
                .tz(job.provider.settings.timezone, false)
                .format('YYYY-MM-DDTHH:mm:ssZ'); // job converted to recipients timezone
            const jobEndTimeInProviderTZ = momentTZ(job.endTime)
                .tz(job.provider.settings.timezone, false)
                .format('YYYY-MM-DDTHH:mm:ssZ'); // job converted to recipients timezone

            const jobStartInBetweenExists = momentTZ(job.startTime).isBetween(
                momentTZ(startTime).tz(getLocalStorage('tz')),
                momentTZ(endTime).tz(getLocalStorage('tz'))
            ); // checking if a job exists in between the start&end time entered
            const jobStartExists = momentTZ(startTime)
                .tz(getLocalStorage('tz'))
                .isBetween(job.startTime, job.endTime); // checking if start/end time exists inBetween a scheduled time
            const jobStartProviderInBetweenExists = momentTZ(
                jobStartTimeInProviderTZ
            ).isBetween(
                momentTZ(startTime).tz(getLocalStorage('tz')),
                momentTZ(endTime).tz(getLocalStorage('tz'))
            ); // checking if a job exists in between the start&end time entered
            const jobStartProviderExists = momentTZ(startTime)
                .tz(getLocalStorage('tz'))
                .isBetween(jobStartTimeInProviderTZ, jobEndTimeInProviderTZ); // checking if start/end time exists inBetween a scheduled time

            ////////////////////
            //const jobStartTimeDoesExist = momentTZ(job.startTime) // giving the starttime of the providers/recipients accepted task(s)
            //.tz(job.provider.settings.timezone, false)
            // .format('ddd, MMM DD hh:mmA');

            //console.log(jobStartDoesExist);

            //const jobEndTimeDoesExist = momentTZ(job.endTime) // giving the endtime of the providers/recipients accepted task(s)
            //.tz(job.provider.settings.timezone, false)
            //.format('ddd, MMM DD hh:mmA');

            //console.log(jobStartTimeDoesExist ,'to', jobEndTimeDoesExist);
            //console.log(jobStartTimeInProviderTZ, momentTZ(selectedStartTime).tz(getLocalStorage('tz')).format('YYYY-MM-DDTHH:mm:ssZ'));
            console.log(
                'disabledDates',
                jobStartTimeInRecipientTZ,
                startTime,
                endTime
            );

            return (
                jobStartTimeInRecipientTZ ===
                    momentTZ(startTime)
                        .tz(getLocalStorage('tz'))
                        .format('YYYY-MM-DDTHH:mm:ssZ') ||
                jobStartTimeInProviderTZ ===
                    momentTZ(startTime)
                        .tz(getLocalStorage('tz'))
                        .format('YYYY-MM-DDTHH:mm:ssZ') ||
                jobStartProviderInBetweenExists ||
                jobStartProviderExists ||
                jobStartInBetweenExists ||
                jobStartExists
                //jobStartTimeDoesExist||
                // jobEndTimeDoesExist*/
            );
        });
    };

    let na = disableDates(filteredScheduleByDate);
    let content;
    let isNotValid = true;
    console.log(localStorage.getItem('jobStartTimeExist'));
    console.log(localStorage.getItem('jobEndTimeExist'));

    if (
        momentTZ(startTime)
            .tz(getLocalStorage('tz'))
            .isBefore(momentTZ().tz(getLocalStorage('tz'))) ||
        momentTZ(endTime)
            .tz(getLocalStorage('tz'))
            .isBefore(momentTZ().tz(getLocalStorage('tz')))
    ) {
        content = (
            <p className='startTime__dials--msg--na'>
                x Sorry, the "Start Time" | "End Time" you selected has already
                passed
            </p>
        );
    } /*else if (na.length > 0 && (startTime && endTime) !== null) {
        content = (
            <p className='startTime__dials--msg--na unselected'>
                x Sorry, the "Start Time" | "End Time" you selected is no longer
                available
            </p>
        );
    } */

    //function isBlocked(HOURS) {
    // const unavailableDates = { unavailableStartTime, unavailablEndTime }
    // if (HOURS === unavailableDates) {
    //  return !unavailableDates;
    //  }
    //}
    // isBlocked(HOURS);

    function disableDateRanges(
        range = { unavailableStartTime: false, unavailablEndTime: false }
    ) {
        const { unavailableStartTime, unavailablEndTime } = range;
        return function disabledDate(current) {
            let startCheck = true;
            let endCheck = true;
            if (unavailableStartTime) {
                startCheck = current && current < unavailableStartTime;
            }
            if (unavailablEndTime) {
                endCheck = current && current > unavailablEndTime;
            }
            return (
                (unavailableStartTime && startCheck) ||
                (unavailablEndTime && endCheck)
            );
        };
    }

    {
        /* if (
        na.length === 0 &&
        (selectedStartTime.length && selectedEndTime.length) !== 0
    ) {
        if (selectedEndTime < selectedStartTime) {
            content = (
                <p className='startTime__dials--msg--na'>
                    x The "End Time" must be later then the "Start Time"
                </p>
            );
        } else if (
            momentTZ(selectedStartTime)
                .tz(getLocalStorage('tz'))
                .isBefore(momentTZ().tz(getLocalStorage('tz'))) ||
            momentTZ(selectedEndTime)
                .tz(getLocalStorage('tz'))
                .isBefore(momentTZ().tz(getLocalStorage('tz')))
        ) {
            content = (
                <p className='startTime__dials--msg--na'>
                    x Sorry, the "Start Time" | "End Time" you selected has
                    already passed
                </p>
            );
        } else if (
            moment(selectedStartTime).format('h:mma') ===
            moment(selectedEndTime).format('h:mma')
        ) {
            content = (
                <p className='startTime__dials--msg--na'>
                    x The "Start Time" and "End Time" cannot be the same
                </p>
            );
        } else {
            content = (
                <p className='startTime__dials--msg--avl'>
                    The "Time" selected is available
                    <br />
                    for request{' '}
                    <IonIcon
                        className='startTime__dials--msg--avl--icon'
                        icon={checkmarkOutline}
                    />
                </p>
            );
            isNotValid = false;
        }
    } else if (
        na.length > 0 &&
        (selectedStartTime.length && selectedEndTime.length) !== 0
    ) {
        content = (
            <p className='startTime__dials--msg--na'>
                x Sorry, the "Start Time" | "End Time" you selected is no longer
                available
            </p>
        );
    } */
    }

    if ((startTime && endTime !== null) || tbd) {
        //Disables the "Continue" button if StartTime & Duration are not chosen
        isNotValid = false;
    }

    const MONTHS = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];

    const DAYS = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
    ];

    const modDay = (days) => {
        let newBase = new Date(baseTime);
        newBase.setDate(baseTime.getDate() + days);
        return newBase;
    };

    const modMonth = (months) => {
        let newBase = new Date(baseTime);
        newBase.setMonth(baseTime.getMonth() + months);
        return newBase;
    };

    const modDuration = (duration) => {
        let newDuration = new Date(baseTime);
        newDuration.setDate(baseTime.getDate() + duration);
        return newDuration;
    };

    // const setDuration = (minutes: number) => { //adds duration to startTime to return endTime
    //     const end = new Date(startTime);
    //     if (startTime !== null) {
    //     end.setMinutes(startTime.getMinutes() + minutes);
    //     setEndTime(end);
    //     }

    //   };

    const minsToMs = (mins: number) => mins * 60 * 1000;
    const msToMins = (ms: number) => Math.floor(ms / 1000 / 60);

    const isSelected = (date) => {
        if (
            startTime &&
            date.getTime() === startTime.getTime() //allows only starttime to be selected on time tiles and not endtime
            //|| (endTime && date.getTime() === endTime.getTime())
        )
            return true;

        //let afterStart = startTime && startTime.getTime() <= date.getTime();
        //let beforeEnd = endTime && date.getTime() <= endTime.getTime();
        //return afterStart;
        //&& beforeEnd;
    };

    const selectTime = (date) => {
        setTBD(false);

        if (!startTime) {
            //&& !endTime)
            setStartTime(date);
        } else if (startTime && startTime.getTime() === date.getTime()) {
            setStartTime(null);
            setDuration(null);
        } else if (
            (startTime && startTime.getTime() > date.getTime()) || //prevents endtime to be manipulated on time tiles after duration tile has set endtime
            (startTime && startTime.getTime() < date.getTime())
        ) {
            setStartTime(date);
            setDuration(null);
        }
        //else if (endTime && endTime.getTime() == date.getTime()) {
        // setEndTime(null);
        //}
        //else if (startTime && startTime.getTime() < date.getTime()) {
        //  setEndTime(date);
        //}
        //else if (startTime && date.getTime() < startTime.getTime()) {
        //if (!endTime) setEndTime(startTime);
        //setStartTime(date);
        //} else if (endTime && endTime.getTime() < date.getTime()) {
        //  if (!startTime) //setStartTime(endTime);
        //setEndTime(date);
        //}
        else if (endTime && date.getTime() === endTime.getTime()) {
            if (!startTime) {
                setStartTime(date); //setEndTime(date);
            } //else if (startTime.getTime() < date.getTime()) {
            //setEndTime(date);
            //}
            else {
                //setStartTime(date);
                //setEndTime(date);
            }
        } else {
            console.error('Unhandled state!');
            console.error('  date:     ', date);
            console.error('  startTime:', startTime);
            console.error('  endTime:  ', endTime);
        }
    };

    // const buttons = document.querySelectorAll(".portfolio-buttons");
    // buttons.forEach((button) => {
    //   button.addEventListener('click', event => {
    //     buttons.forEach(button => button.classList.remove('portfolio-buttons-active'));
    //     if (button.innerHTML !== 'reset') {
    //       button.classList.add('portfolio-buttons-active');
    //     }
    //   });
    // });

    // const buttonss = document.querySelectorAll(".portfolio-buttons-active");
    // buttonss.forEach((button) => {
    //     button.addEventListener('click', event => {
    //       buttonss.forEach(button => button.classList.remove('portfolio-buttons-active'));
    //       if (button.innerHTML !== 'reset') {
    //         button.classList.add('portfolio-buttons-normal');
    //       }
    //     });
    //   });

    const selectTBD = () => {
        //clears starttime/endtime when tbd is selected
        setStartTime(null);
        setDuration(null);
        setTBD((t) => !t);
    };

    return (
        <IonPage>
            <IonContent>
                <IonGrid>
                    {/* startTime header */}
                    <IonRow className='startTime__header'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='startTime__header--container'
                        >
                            <RequestTaskTabs
                                prevPage=''
                                disablePrevStep={false}
                            />
                            <Strokes top={true} />
                            <UserImg providerUid={provider} />
                        </IonCol>
                    </IonRow>
                    {/* text content */}
                    <IonRow className='startTime__textContent'>
                        <IonCol
                            className='startTime__textContent--container'
                            sizeSm='12'
                            sizeXs='12'
                        >
                            <h1 className='startTime__textContent--title'>
                                Choose a <span>Time</span>
                            </h1>
                            {/* <p className='startTime__textContent--text'>
                                Please select your skill request "Start Time"
                                <br />
                                and "Duration" below.
                            </p> */}
                        </IonCol>
                    </IonRow>
                    {/* start/end time dials */}
                    {/*<IonRow className='startTime__dials--row'>
                        <IonCol
                            className='startTime__dials--col'
                            sizeSm='12'
                            sizeXs='12'
                        >
                            <IonLabel className='startTime__dials--label'>
                                Start Time
                            </IonLabel>
                            <IonDatetime
                                placeholder='-'
                                displayTimezone={getLocalStorage('tz')}
                                displayFormat='h:mm A'
                                minuteValues='0,15,30,45'
                                value={selectedStartTime}
                                onIonChange={(e) => {
                                    const dateSelectedFormatted = moment(
                                        getLocalStorage('requestSkill').date
                                    ).format('YYYY-MM-DD');
                                    const startTime = momentTZ(e.detail.value)
                                        .tz(getLocalStorage('tz'))
                                        .format('HH:mm');
                                    const recipientTZ = momentTZ
                                        .tz(getLocalStorage('tz'))
                                        .format('Z');
                                    const startDateTime =
                                        dateSelectedFormatted +
                                        'T' +
                                        startTime +
                                        `:00${recipientTZ}`;
                                    setSelectedStartTime(startDateTime);
                                }}
                            ></IonDatetime>
                        </IonItem>
                    </IonRow>
                    <IonRow className='startTime__dials--row'>
                        <IonItem className='startTime__dials--item'>
                            <IonLabel className='startTime__dials--label'>
                                End Time
                            </IonLabel>
                            <IonDatetime
                                placeholder='-'
                                displayTimezone={getLocalStorage('tz')}
                                displayFormat='h:mm A'
                                minuteValues='0,15,30,45'
                                value={selectedEndTime}
                                onIonChange={(e) => {
                                    const dateSelectedFormatted = moment(
                                        getLocalStorage('requestSkill').date
                                    ).format('YYYY-MM-DD');
                                    const endTime = momentTZ(e.detail.value)
                                        .tz(getLocalStorage('tz'))
                                        .format('HH:mm');
                                    const recipientTZ = momentTZ
                                        .tz(getLocalStorage('tz'))
                                        .format('Z');
                                    const endDateTime =
                                        dateSelectedFormatted +
                                        'T' +
                                        endTime +
                                        `:00${recipientTZ}`;
                                    setSelectedEndTime(endDateTime);
                                }}
                            ></IonDatetime>
                        </IonCol>
                            </IonRow> */}

                    {/*(moment(endTime).format('MMM Do @ hh:mm a')?.toString()) oorrrr  startTime?.toString() ?? null} */}
                    <IonRow>
                        <h6 className='calendar-time-title'>
                            Start Time{' '}
                            {/* {startTime?.toLocaleString('en-us', {
                                hour: 'numeric',
                                minute: 'numeric',
                                month: 'short',
                                day: 'numeric',
                            }) ?? ''}{' '}  */}
                        </h6>
                    </IonRow>

                    {/* <IonRow >
                       <h6 className="end-time-title duration-text" >End Time:&nbsp; </h6>
                        <b>
                             <p className="duration-text" >{endTime?.toLocaleString('en-us', {
                                hour: 'numeric',
                                minute: 'numeric',
                                month: 'short',
                                day: 'numeric',
                            }) ?? ''}{' '}</p>
                        </b>
                        </IonRow>  */}

                    <IonGrid className=' height'>
                        <IonCol className='startTime__col day-picker'>
                            {/* day selector ${DAYS[dateSelected2.getDay()]}, ${MONTHS[dateSelected2.getMonth()]} ${dateSelected2.getDate()}, $dateSelected2.getFullYear()}*/}
                            <strong>
                                <button
                                    className='arrow-left'
                                    onClick={() => setBaseTime(modDay(-1))}
                                >
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='16'
                                        height='12'
                                        fill='currentColor'
                                        className='bi bi-chevron-left'
                                        viewBox='0 0 16 16'
                                    >
                                        <path
                                            fillRule='evenodd'
                                            d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'
                                        />
                                    </svg>
                                </button>
                            </strong>

                            <p className='blue'>{`${DAYS[baseTime.getDay()]}, ${
                                MONTHS[baseTime.getMonth()]
                            } ${baseTime.getDate()}, ${baseTime.getFullYear()}`}</p>

                            <button
                                className='arrow-right'
                                onClick={() => setBaseTime(modDay(1))}
                            >
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='16'
                                    height='12'
                                    fill='currentColor'
                                    className='bi bi-chevron-right'
                                    viewBox='0 0 16 16'
                                >
                                    <path
                                        fillRule='evenodd'
                                        d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'
                                    />
                                </svg>
                            </button>

                            {/* month selector */}
                            {/*<button className='blue onClick={() => setåBaseTime(modMonth())}>{'<'}</button>
                                <p className='blue'>{`${MONTHS[baseTime.getMonth()]} ${baseTime.getFullYear()}`}</p>
                               <button className='blue' onClick={() => setBaseTime(modMonth(1))}>{'>'}</button> */}
                        </IonCol>

                        <IonRow className='startTime__form--container-tbd'>
                            {/* hour buttons */}
                            <IonCol className='calendar-button white'>
                                {HOURS.map((timeString, i) => {
                                    const myTime = new Date(baseTime);
                                    myTime.setMinutes(i * 15);

                                    if (!isAvailable(myTime)) return null;
                                    return (
                                        <button
                                            //key={myTime.toISOString()}
                                            onClick={() => selectTime(myTime)}
                                            className={
                                                isSelected(myTime)
                                                    ? 'selected startTime__content--day--active'
                                                    : ''
                                            }
                                        >
                                            {timeString}
                                        </button>
                                    );
                                })}
                            </IonCol>

                            {/* tbd button*/}
                        </IonRow>
                        <IonRow className='startTime__form--row-tbd'>
                            <button
                                className={
                                    tbd === true
                                        ? 'startTime__content--day--na tbd-button startTime__content--day--active'
                                        : 'startTime__content--day--na tbd-button'
                                }
                                onClick={selectTBD}
                            >
                                Time hasn't been decided
                            </button>
                        </IonRow>
                    </IonGrid>

                    {/*  Duration */}
                    <IonRow>
                        <h6 className='duration-time-title'>Duration </h6>
                    </IonRow>

                    <IonRow className='startTime__form--container-tbd duration'>
                        {/* duration buttons */}
                        <IonCol className='calendar-button white '>
                            <React.Fragment>
                                {/*className={tbd === true ? '' : 'startTime__content--day-na portfolio-buttons portfolio-buttons-normal duration-button*/}

                                <button
                                    className={
                                        minsToMs(15) === duration
                                            ? 'selected startTime__content--day-active'
                                            : ''
                                    }
                                    onClick={() => setDuration(minsToMs(15))}
                                >
                                    {' '}
                                    15 min
                                </button>
                                <button
                                    className={
                                        minsToMs(30) === duration
                                            ? 'selected startTime__content--day-active'
                                            : ''
                                    }
                                    onClick={() => setDuration(minsToMs(30))}
                                >
                                    30 min
                                </button>
                                <button
                                    className={
                                        minsToMs(45) === duration
                                            ? 'selected startTime__content--day-active'
                                            : ''
                                    }
                                    onClick={() => setDuration(minsToMs(45))}
                                >
                                    45 min
                                </button>
                                <button
                                    className={
                                        minsToMs(60) === duration
                                            ? 'selected startTime__content--day-active'
                                            : ''
                                    }
                                    onClick={() => setDuration(minsToMs(60))}
                                >
                                    1 hr
                                </button>
                                <button
                                    className={
                                        minsToMs(90) === duration
                                            ? 'selected startTime__content--day-active'
                                            : ''
                                    }
                                    onClick={() => setDuration(minsToMs(90))}
                                >
                                    1 hr 30 min
                                </button>
                                <button
                                    className={
                                        minsToMs(120) === duration
                                            ? 'selected startTime__content--day-active'
                                            : ''
                                    }
                                    onClick={() => setDuration(minsToMs(120))}
                                >
                                    2 hr{' '}
                                </button>
                                <button
                                    className={
                                        minsToMs(150) === duration
                                            ? 'selected startTime__content--day-active'
                                            : ''
                                    }
                                    onClick={() => setDuration(minsToMs(150))}
                                >
                                    2 hr 30 min
                                </button>
                                <button
                                    className={
                                        minsToMs(180) === duration
                                            ? 'selected startTime__content--day-active'
                                            : ''
                                    }
                                    onClick={() => setDuration(minsToMs(180))}
                                >
                                    3 hr
                                </button>
                                <button
                                    className={
                                        minsToMs(240) === duration
                                            ? 'selected startTime__content--day-active'
                                            : ''
                                    }
                                    onClick={() => setDuration(minsToMs(240))}
                                >
                                    4 hr
                                </button>
                                <button
                                    className={
                                        minsToMs(300) === duration
                                            ? 'selected startTime__content--day-active'
                                            : ''
                                    }
                                    onClick={() => setDuration(minsToMs(300))}
                                >
                                    5 hr
                                </button>
                                <button
                                    className={
                                        minsToMs(360) === duration
                                            ? 'selected startTime__content--day-active'
                                            : ''
                                    }
                                    onClick={() => setDuration(minsToMs(360))}
                                >
                                    6 hr
                                </button>
                                <button
                                    className={
                                        minsToMs(420) === duration
                                            ? 'selected startTime__content--day-active'
                                            : ''
                                    }
                                    onClick={() => setDuration(minsToMs(420))}
                                >
                                    7 hr
                                </button>
                                <button
                                    className={
                                        minsToMs(480) === duration
                                            ? 'selected startTime__content--day-active'
                                            : ''
                                    }
                                    onClick={() => setDuration(minsToMs(480))}
                                >
                                    8 hr
                                </button>
                            </React.Fragment>
                        </IonCol>
                    </IonRow>

                    {/* <IonRow>
                        {<button onClick={retrieveDuration} >Get Value:</button>
                        <p id="duration" className="startTime__dials--msg--na 
                                                    startTime__dials--msg 
                                                    startTime__dials--msg--wrap">                            
                        </p>
                   </IonRow>}*/}

                    {/* message */}
                    <IonRow className='startTime__dials--msg--wrap'>
                        <IonCol
                            className='startTime__dials--msg'
                            sizeSm='12'
                            sizeXs='12'
                        >
                            {content}
                        </IonCol>
                    </IonRow>
                    {/* confirm btn */}
                    <IonRow className='startTime__btn--container'>
                        <IonCol
                            className='startTime__btn--wrap'
                            sizeXs='12'
                            sizeSm='12'
                        >
                            <IonButton
                                id='task_from_home_time_btn'
                                fill='clear'
                                disabled={isNotValid}
                                onClick={handleStartTimeSelected}
                                color='ion-primary'
                                className='startTime__btn'
                            >
                                Continue
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default StartTime;
