// importing modules
import React, { useState } from 'react';
// importing components
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonIcon,
    IonImg,
    IonLabel,
    IonModal,
    IonRow,
} from '@ionic/react';
import Strokes from 'components/Strokes';
// importing assets
import { arrowBackOutline } from 'ionicons/icons';
// importing helpers
import { Status } from '../../../GraphQL/__generated__/globalTypes';

const SkillInfoModal: React.FC<{
    userImg: string;
    handleModal: () => void;
    modalStatus: boolean;
    skill: string;
    handleAddSkill: Function;
}> = ({ modalStatus, handleModal, skill, handleAddSkill, userImg }) => {
    const [skillType, setSkillType] = useState<string>('');
    const [skillDetails, setSkillDetails] = useState<string>('');
    const [peopleNeeded, setPeopleNeeded] = useState<string>('');

    const handleSkillToAdd = () => {
        const skillObj = {
            name: skill,
            description: skillType,
            additionalDetails: skillDetails,
            peopleNeededCount: peopleNeeded,
            status: Status.OPEN,
        };
        handleAddSkill(skillObj);
        setSkillType('');
        setSkillDetails('');
        setPeopleNeeded('');
    };

    return (
        <IonModal isOpen={modalStatus}>
            <IonContent>
                <IonGrid>
                    {/* skillInfo header */}
                    <IonRow className='skillInfo__header'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='skillInfo__header--container'
                        >
                            <Strokes top={true} />
                            <IonCol
                                sizeXs='12'
                                sizeSm='12'
                                sizeMd='12'
                                className='completeTaskNav__btns--wrap'
                            >
                                <IonButton
                                    className='completeTaskNav__btn'
                                    onClick={handleModal}
                                    fill='clear'
                                    color='ion-primary'
                                >
                                    <IonIcon
                                        className='completeTaskNav__btn--icon'
                                        icon={arrowBackOutline}
                                    />
                                </IonButton>
                            </IonCol>
                            <IonImg
                                className='createTask__header--img'
                                src={userImg}
                                alt='user'
                            />
                        </IonCol>
                    </IonRow>
                    {/* text content */}
                    <IonRow className='skillInfo__textContent'>
                        <IonCol
                            className='skillInfo__textContent--container'
                            sizeSm='12'
                            sizeXs='12'
                        >
                            <h1 className='skillInfo__textContent--title'>
                                Describe your need in detail
                            </h1>
                            <div className='skillInfo__textContent--badge'>
                                <p className='skillInfo__textContent--badge--text'>
                                    {skill}
                                </p>
                            </div>
                        </IonCol>
                    </IonRow>
                    {/* skill def form */}
                    <IonGrid className='skillInfo__form--container'>
                        <IonRow className='skillInfo__form--row'>
                            <IonCol
                                className='skillInfo__form--input--wrap'
                                sizeXs='12'
                                sizeSm='12'
                            >
                                <IonLabel className='skillInfo__form--label'>
                                    Type of {skill} (optional)
                                </IonLabel>
                                <input
                                    onChange={(event) =>
                                        setSkillType(event.target.value)
                                    }
                                    className='skillInfo__form--input'
                                    placeholder='In few words, describe in more detail'
                                    type='text'
                                    required
                                />
                            </IonCol>
                            <IonCol
                                className='skillInfo__form--input--wrap'
                                sizeXs='12'
                                sizeSm='12'
                            >
                                <IonLabel className='skillInfo__form--label'>
                                    What should they know how to do (optional)
                                </IonLabel>
                                <textarea
                                    onChange={(event) =>
                                        setSkillDetails(event.target.value)
                                    }
                                    className='skillInfo__form--textarea'
                                    placeholder='Explain the specific experience required...'
                                    required
                                ></textarea>
                            </IonCol>
                            <IonCol
                                className='skillInfo__form--inputs--container'
                                sizeXs='12'
                                sizeSm='12'
                            >
                                <IonCol
                                    className='skillInfo__form--input--wrap'
                                    sizeXs='12'
                                    sizeSm='12'
                                >
                                    <IonLabel className='skillInfo__form--label'>
                                        How Many People?
                                    </IonLabel>
                                    <input
                                        onChange={(event) =>
                                            setPeopleNeeded(event.target.value)
                                        }
                                        className='skillInfo__form--input'
                                        placeholder='Ex: 2'
                                        type='number'
                                        required
                                    />
                                </IonCol>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                    {/* btn */}
                    <IonRow className='skillInfo__btn--container'>
                        <IonCol
                            className='skillInfo__btn--wrap'
                            sizeXs='12'
                            sizeSm='12'
                        >
                            <IonButton
                                onClick={() => {
                                    handleSkillToAdd();
                                    handleModal();
                                }}
                                fill='clear'
                                disabled={!(parseInt(peopleNeeded) > 0)}
                                // disabled={!(parseInt(peopleNeeded) > 0 && parseInt(taskDuration) > 0)}
                                color='ion-primary'
                                className='skillInfo__btn--next'
                            >
                                Confirm Skill
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonModal>
    );
};

export default SkillInfoModal;
