// importing modulees
import React from 'react';
import { useHistory } from 'react-router';
// importing components
import { IonButton, IonCol, IonIcon, IonRow } from '@ionic/react';
import { menuOutline, searchOutline } from 'ionicons/icons';
// importing styles
import './ProfileStateTabs.css';

const ProfileStateTabs: React.FC<{
    showSearch: boolean;
    handleMenuToggle: () => void;
}> = ({ handleMenuToggle, showSearch }) => {
    const history = useHistory();

    return (
        <IonRow className='profileStates__tabs--container'>
            <IonCol
                sizeXs='12'
                sizeSm='12'
                sizeMd='12'
                className='profileStates__tabs--wrap'
            >
                <IonButton
                    fill='clear'
                    onClick={handleMenuToggle}
                    color='ion-primary'
                    className='profileStates__tabs--btn'
                >
                    <IonIcon
                        className='profileStates__tabs--btn--icon'
                        icon={menuOutline}
                    />
                </IonButton>
                {showSearch === true ? (
                    <IonButton
                        color='ion-primary'
                        className='profileStates__tabs--btn'
                        onClick={() => history.push('/search')}
                        fill='clear'
                    >
                        <IonIcon
                            className='profileStates__tabs--btn--icon'
                            icon={searchOutline}
                        />
                    </IonButton>
                ) : (
                    <p></p>
                )}
            </IonCol>
        </IonRow>
    );
};

export default ProfileStateTabs;
