// importing modules
import React, { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useAuth } from 'context/firebase/authContext';
import moment from 'moment-timezone';
import { Calendar } from '@ionic-native/calendar';
import { Capacitor } from '@capacitor/core';
// importing components
import Loading from '../../components/status/Loading';
import UserImg from '../CreateTask/UI/UserImg/UserImg';
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonIcon,
    IonImg,
    IonRow,
    IonPage,
} from '@ionic/react';
import Strokes from 'components/Strokes';
import BackButton from 'components/BackButton';
import CancelButton from 'components/CancelButton/CancelButton';
// importing assets
import { informationCircleOutline } from 'ionicons/icons';
// importing utilities
import { getLocalStorage } from '../../helpers/localStorage/localStorageHelper';
// importing graphql
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_JOB_BY_ID } from '../../GraphQL/Job/GET_JOB_BY_ID/GET_JOB_BY_ID';
import {
    GetJobById,
    GetJobByIdVariables,
} from '../../GraphQL/__generated__/GetJobById';
import { UPDATE_ONE_JOB_STATUS } from '../../GraphQL/Job/UPDATE_ONE_JOB_STATUS/UPDATE_ONE_JOB_STATUS';
import {
    UpdateOneJobStatus,
    UpdateOneJobStatusVariables,
} from '../../GraphQL/__generated__/UpdateOneJobStatus';
import {
    createOneNotificationWithSender,
    createOneNotificationWithSenderVariables,
} from '../../GraphQL/__generated__/createOneNotificationWithSender';
import { CREATE_ONE_NOTIFICATION_WITH_SENDER } from '../../GraphQL/Notification/CREATE_ONE_NOTIFICATION_WITH_SENDER/CREATE_ONE_NOTIFICATION_WITH_SENDER';
import {
    Status,
    NotificationType,
} from '../../GraphQL/__generated__/globalTypes';
import gql from 'graphql-tag';
// importing styles
import './UserServiceRequest.css';

const UserServiceRequestAcceptance: React.FC = () => {
    const { currentUser } = useAuth();
    const { jobId } = useParams<{ jobId: string }>();
    const history = useHistory();
    const [disableOnClick, setDisableOnClick] = useState<boolean>(false);
    const chatLink = useRef(null);

    const nonAdminNotification = gql`
        mutation createOneNotificationWithSender(
            $title: String!
            $subTitle: String!
            $body: String!
            $additionalDetails: String
            $expires: DateTime
            $path: String
            $notificationType: NotificationType
            $uid: String!
            $senderUid: String!
        ) {
            createOneNotification(
                data: {
                    title: $title
                    subTitle: $subTitle
                    body: $body
                    additionalDetails: $additionalDetails
                    expires: $expires
                    path: $path
                    notificationType: $notificationType
                    receiverProfile: { connect: { uid: $uid } }
                    senderProfile: { connect: { uid: $senderUid } }
                }
            ) {
                id
            }
        }
    `;

    const handleChatroom = () => chatLink.current?.click();

    const [UpdateSkillRequest, UpdateSkillRequestDatas] = useMutation<
        UpdateOneJobStatus,
        UpdateOneJobStatusVariables
    >(UPDATE_ONE_JOB_STATUS);
    const [createNotification, createNotificationDatas] = useMutation<
        createOneNotificationWithSender,
        createOneNotificationWithSenderVariables
    >(CREATE_ONE_NOTIFICATION_WITH_SENDER);
    const [createNonAdminNotification, createNonAdminNotificationsDatas] =
        useMutation(nonAdminNotification);

    const { data, loading, error, fetchMore } = useQuery<
        GetJobById, // query return return type (shape of data)
        GetJobByIdVariables // query request type       (shape of variables)
    >(GET_JOB_BY_ID, {
        variables: {
            jobId: parseInt(jobId),
        },
        fetchPolicy: 'no-cache',
    });

    if (loading) {
        return <Loading />;
    }
    let notificationSelector;
    getLocalStorage('currentAdminGroupId') == 0
        ? (notificationSelector = createNonAdminNotification)
        : (notificationSelector = createNotification);

    const handleUpdateSkillRequest = (reqStatus: string) => {
        setDisableOnClick(true); // disable btn

        UpdateSkillRequest({
            variables: {
                jobId: parseInt(jobId),
                status: reqStatus,
            },
        })
            .then((r) => {
                if (reqStatus === 'SCHEDULED') {
                    notificationSelector({
                        variables: {
                            title: 'Yay! Accepted Request',
                            subTitle: data.job.Skill.category,
                            body: `${data.job.provider.firstName} has confirmed your session.`,
                            additionalDetails: `Feel free to reach out to ${data.job.provider.firstName}`,
                            expires: data.job.startTime,
                            path: `/skillRequest/${data.job.id}`,
                            notificationType:
                                NotificationType.TASK_REQUEST_ACCEPTED,
                            uid: data.job.recipient.uid,
                            senderUid: currentUser.uid,
                            adminId: parseInt(
                                getLocalStorage('currentAdminGroupId')
                            ),
                            groupId: parseInt(
                                getLocalStorage('currentAdminGroupId')
                            ),
                        },
                    }).catch((err) => console.error(err));

                    if (Capacitor.platform !== 'web') {
                        // if native device run the code inside this block
                        const taskStartTime = moment(data.job.startTime);
                        const taskStartDate = taskStartTime.tz(
                            getLocalStorage('tz'),
                            false
                        );
                        const taskEndTime = moment(data.job.endTime);
                        const taskEndDate = taskEndTime.tz(
                            getLocalStorage('tz'),
                            false
                        );
                        // creates an event on the devices native calendar (if calendar permission was granted)
                        Calendar.createEventWithOptions(
                            `YING - ${data.job.Skill.category} w/ ${data.job.recipient.firstName}`,
                            data.job.location.locationString,
                            'No notes available',
                            taskStartDate.toDate(),
                            taskEndDate.toDate(),
                            {
                                id: data.job.id.toString(),
                                firstReminderMinutes: 5,
                                secondReminderMinutes: 120,
                            }
                        ).catch((err) =>
                            console.error('error creating calendar event', err)
                        );
                        history.push(
                            `/accept/skillRequest/${jobId}/confirmation`
                        ); // routing will only work (if calendar permission was granted)
                    } else {
                        history.push(
                            `/accept/skillRequest/${jobId}/confirmation`
                        );
                    }
                } else if (reqStatus === 'DENIED') {
                    // no calendar event will be created ...
                    notificationSelector({
                        variables: {
                            title: 'Request to participate next time',
                            subTitle: data.job.Skill.category,
                            body: `This opportunity is not available`,
                            additionalDetails: null,
                            expires: data.job.startTime,
                            path: `/skillRequest/${data.job.id}`,
                            notificationType:
                                NotificationType.TASK_REQUEST_DENIED,
                            uid: data.job.recipient.uid,
                            senderUid: currentUser.uid,
                            adminId: parseInt(
                                getLocalStorage('currentAdminGroupId')
                            ),
                            groupId: parseInt(
                                getLocalStorage('currentAdminGroupId')
                            ),
                        },
                    }).catch((err) => console.error(err));
                    history.push(`/deny/skillRequest/${jobId}/confirmation`);
                }
            })
            .catch((err) => console.error(err));
    };

    const taskDT = moment(data.job.startTime);
    const taskDate = taskDT.tz(getLocalStorage('tz'), false); // transformed timeslots from NY -> LA

    return (
        <IonPage>
            <IonContent>
                <IonGrid>
                    {/* skillRequest header */}
                    <IonRow className='serviceRequest__header'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='serviceRequest__header--container'
                        >
                            <BackButton color='white' />
                            <Strokes top={true} />
                            <UserImg />
                        </IonCol>
                    </IonRow>
                    {/* text content */}
                    <IonRow className='serviceRequest__textContent'>
                        <IonCol
                            className='serviceRequest__textContent--container'
                            sizeSm='12'
                            sizeXs='12'
                        >
                            <h1 className='serviceRequest__textContent--title'>
                                New Request of your service{' '}
                                <span>{data.job.Skill.category}</span>
                            </h1>
                            {data.job.Status === Status.DENIED && (
                                <p className='serviceRequest__textContent--text--denied'>
                                    You already declined this task with{' '}
                                    {data.job.recipient.firstName}.
                                </p>
                            )}
                            {data.job.Status === Status.SCHEDULED && (
                                <p className='serviceRequest__textContent--text--scheduled'>
                                    You already scheduled this task with{' '}
                                    {data.job.recipient.firstName}.
                                </p>
                            )}
                            {data.job.Status === Status.REQUESTED && (
                                <p className='serviceRequest__textContent--text--requested'>
                                    An event will be created on your calendar if
                                    you choose to accept the request.
                                </p>
                            )}
                            {(data.job.Status === Status.CANCELED ||
                                data.job.Status === Status.REMOVED) && (
                                <p className='serviceRequest__textContent--text--denied'>
                                    This task was either cancelled or removed by
                                    the participants.
                                </p>
                            )}
                        </IonCol>
                    </IonRow>
                    {/* service request details */}
                    <IonGrid className='serviceRequest__request--container'>
                        <IonRow className='serviceRequest__request--row'>
                            <IonCol
                                sizeXs='12'
                                sizeSm='12'
                                className='serviceRequest__request--wrap'
                            >
                                <IonCol
                                    className='serviceRequest__details--wrap'
                                    sizeXs='12'
                                    sizeSm='12'
                                >
                                    <IonCol
                                        className='serviceRequest__details--wrap'
                                        sizeXl='3'
                                        sizeLg='3'
                                        sizeMd='3'
                                        sizeXs='3'
                                        sizeSm='3'
                                    >
                                        <div className='serviceRequest__details--img--wrap'>
                                            <IonImg
                                                className='serviceRequest__details--img'
                                                alt='user'
                                                src={
                                                    data.job.recipient
                                                        .profilePicture
                                                }
                                            />
                                        </div>
                                    </IonCol>
                                    <IonCol
                                        className='serviceRequest__text--wrap'
                                        sizeXs='9'
                                        sizeSm='9'
                                    >
                                        <h3 className='serviceRequest__details--name'>{`${data.job.recipient.firstName} ${data.job.recipient.lastName}`}</h3>
                                        <p className='serviceRequest__details--time'>
                                            {taskDate.format('MMMM Do, h:mma')}
                                        </p>
                                        <IonIcon
                                            onClick={() =>
                                                history.push(
                                                    `/skillRequest/${jobId}`
                                                )
                                            }
                                            icon={informationCircleOutline}
                                            className='serviceRequest__text--icon'
                                        />
                                    </IonCol>
                                </IonCol>
                                <IonCol
                                    className='serviceRequest__msg--wrap'
                                    sizeXs='12'
                                    sizeSm='12'
                                >
                                    <p className='serviceRequest__msg'>
                                        {data.job.requestMessage}
                                    </p>
                                </IonCol>
                            </IonCol>
                            <IonCol
                                sizeXs='12'
                                sizeSm='12'
                                className='serviceRequest__btn--wrap'
                            >
                                <IonButton
                                    fill='clear'
                                    color='ion-primary'
                                    className='serviceRequest__btn--decline'
                                    onClick={() =>
                                        handleUpdateSkillRequest('DENIED')
                                    }
                                    disabled={
                                        !(
                                            Status.REQUESTED === data.job.Status
                                        ) || disableOnClick
                                    }
                                >
                                    Decline
                                </IonButton>
                                <IonButton
                                    fill='clear'
                                    color='ion-primary'
                                    className='serviceRequest__btn--send'
                                    onClick={handleChatroom}
                                    // onClick={() => history.push(`/chatroom/${currentUser.uid}/${data.job.recipient.uid}`)}
                                >
                                    <a
                                        ref={chatLink}
                                        href={`/chatroom/${currentUser.uid}/${data.job.recipient.uid}`}
                                        type='hidden'
                                    ></a>
                                    Message
                                </IonButton>
                                <IonButton
                                    onClick={() =>
                                        handleUpdateSkillRequest('SCHEDULED')
                                    }
                                    fill='clear'
                                    color='ion-primary'
                                    className='serviceRequest__btn--accept'
                                    disabled={
                                        !(
                                            Status.REQUESTED === data.job.Status
                                        ) || disableOnClick
                                    }
                                >
                                    Accept
                                </IonButton>
                            </IonCol>
                            <IonCol
                                className='serviceRequest__footer--wrap'
                                sizeSm='12'
                                sizeXs='12'
                            >
                                <p className='serviceRequest__footer--text'>
                                    Feel free to message{' '}
                                    {data.job.recipient.firstName} with any
                                    questions. Make sure to come back and click
                                    accept to add this to both of your
                                    schedules.
                                </p>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default UserServiceRequestAcceptance;
