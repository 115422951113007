import React, { useRef, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
// importing components
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonPage,
    IonRow,
} from '@ionic/react';
// importing assets
import yingLogo from '../../assets/ying-logo-black@3x.png';
// importing helpers
import { getLocalStorage } from '../../helpers/localStorage/localStorageHelper';
// importing styles
import './CommunityOnboardConfirmation.css';
import { trashOutline } from 'ionicons/icons';

const CommunityOnboardingConfirmation: React.FC = () => {
    const history = useHistory();
    const { code, url, groupName, groupId } =
        getLocalStorage('organizationObject');
    const [isOnboarding, setIsOnboarding] = useState<boolean>(false);
    const onboardImageLink = useRef(null);
    const joinedGroupLink = useRef(null);

    useEffect(() => {
        const isOnB = JSON.parse(localStorage.getItem('isOnboarding'));
        setIsOnboarding(isOnB);
    }, []);

    const handlePush = () => {
        if (isOnboarding === true) {
            localStorage.setItem('isOnboarding', JSON.stringify(false));
            onboardImageLink.current?.click();
        } else {
            // history.push(`/group/${groupId}`)
            joinedGroupLink.current?.click();
        }
    };
    return (
        <IonPage>
            <IonContent>
                <IonGrid>
                    {/* header */}
                    <IonRow className='onboardingC__header'>
                        <IonCol
                            className='onboardingC__header--logo--wrap'
                            sizeXs='12'
                            sizeSm='12'
                            sizeMd='12'
                            sizeLg='12'
                            sizeXl='12'
                        >
                            <img
                                className='onboardingC__header--logo'
                                src={yingLogo}
                                alt='ying logo'
                            />
                        </IonCol>
                    </IonRow>
                    {/* content */}
                    <IonRow className='onboardingC__title--row'>
                        <IonCol
                            className='onboardingC__title--wrap'
                            sizeXs='10'
                            sizeSm='10'
                            sizeMd='10'
                            sizeLg='10'
                            sizeXl='10'
                        >
                            <h1 className='onboardingC__text--title'>
                                <span className='onboardingC__title--span'>
                                    Boom!
                                </span>
                                , Meet your new group HQ at
                                <span className='onboardingC__title--span'>
                                    {' '}
                                    {groupName}
                                </span>
                            </h1>
                        </IonCol>
                    </IonRow>
                    <IonRow className='onboardingC__text--row'>
                        <IonCol
                            sizeXs='10'
                            sizeSm='10'
                            sizeMd='10'
                            sizeLg='10'
                            sizeXl='10'
                            className='onboardingC__text--wrap'
                        >
                            <p className='onboardingC__text'>
                                Setup is done! Use your group invite link or
                                unique group code to invite members into your
                                private skill sharing community.
                            </p>
                            <p className='onboardingC__text'>
                                Your link for your group is
                                <br />
                                <span className='onboardingC__text--break'>
                                    {url}
                                </span>
                                <br />
                                Your unique group code is
                                <br />
                                <span className='onboardingC__text--span'>
                                    {code}
                                </span>
                            </p>
                        </IonCol>
                    </IonRow>
                    {/* continue btn */}
                    <IonRow className='onboardingPolicy__content--row'>
                        <IonCol
                            sizeXs='10'
                            sizeSm='10'
                            sizeMd='10'
                            sizeLg='10'
                            sizeXl='10'
                            className='onboardingPolicy__content--col'
                        >
                            <a
                                href='/onboarding/imageupload'
                                ref={onboardImageLink}
                                type='hidden'
                                style={{ display: 'none' }}
                            />
                            <a
                                href={`/group/${groupId}`}
                                ref={joinedGroupLink}
                                type='hidden'
                                style={{ display: 'none' }}
                            />
                            <IonButton
                                onClick={handlePush}
                                fill='clear'
                                color='ion-primary'
                                className='onboardingC__content--btn'
                            >
                                {JSON.parse(
                                    localStorage.getItem('isOnboarding')
                                )
                                    ? 'Continue To Personal Account Setup'
                                    : `Visit ${groupName}`}
                            </IonButton>
                        </IonCol>
                    </IonRow>
                    {/* policy links */}
                    <IonRow className='onboardingPolicy__content--row'>
                        <IonCol
                            sizeXs='11'
                            sizeSm='11'
                            sizeMd='11'
                            sizeLg='11'
                            sizeXl='11'
                            className='onboardingPolicy__links'
                        >
                            <a className='onboardingC__policy--link' href='#'>
                                Privacy Policy
                            </a>
                            &nbsp;&nbsp;
                            <a className='onboardingC__policy--link' href='#'>
                                Terms & Conditions
                            </a>
                            &nbsp;&nbsp;
                            <a className='onboardingC__policy--link' href='#'>
                                Contact Us
                            </a>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default CommunityOnboardingConfirmation;
