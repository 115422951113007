// this file is accessible through profile dm and messaging on tasks.
// importing modules
import React, { useState, useEffect, useRef } from 'react';
import { Redirect, useHistory, useParams } from 'react-router';
// importing components
import {
    IonButton,
    IonContent,
    IonIcon,
    IonPage,
    IonImg,
    IonList,
    useIonViewDidEnter,
} from '@ionic/react';
import Loading from '../../../components/status/Loading';
import NavBar from '../../../components/NavBar';
import Strokes from 'components/Strokes';
// importing assets
import { closeOutline, arrowUpOutline } from 'ionicons/icons';
import SenderChatBubble from '../UI/SenderChatBubble';
import ReceiverChatBubble from '../UI/ReceiverChatBubble';
// importing graphql utilities - types
import { useQuery, useMutation } from '@apollo/react-hooks';
import { PUBLISH_CHAT_NOTIFICATION } from '../../../GraphQL/Device/PUBLISH_CHAT_NOTIFICATION/PUBLISH_CHAT_NOTIFICATION';
import {
    PublishChatNotification,
    PublishChatNotificationVariables,
} from '../../../GraphQL/__generated__/PublishChatNotification';
import { USER_PROFILE_IMG_ORGS } from '../../../GraphQL/Profile/USER_PROFILE_IMG_ORGS/USER_PROFILE_IMG_ORGS';
import {
    UserProfileImgOrgs,
    UserProfileImgOrgsVariables,
} from '../../../GraphQL/__generated__/UserProfileImgOrgs';
// importing utilities
import { FirebaseHelper } from '../../../helpers/FirebaseHelper';
import limitStr from '../../../utils/limitString';
// importing styles
import './ChatRoom.css';
// firebase context
import firebase from 'firebase/app';
import 'firebase/firestore';
import { useAuth } from '../../../context/firebase/authContext';
interface Messages {
    id: string;
    sentAt: any;
    message: string;
    sender: string;
    senderName: string;
    seen: boolean;
}

const ChatRoom: React.FC = () => {
    const { currentUser } = useAuth();
    const fuser = new FirebaseHelper(currentUser);
    const firestore = firebase.firestore();
    const { sender, receiver } = useParams<{
        sender: string;
        receiver: string;
    }>();
    const history = useHistory();
    const [chatroomId, setChatroomId] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [messages, setMessages] = useState<Messages[]>([]);
    const [isLoadingMessages, setIsLoadingMessages] = useState<boolean>(true);

    const [publishNotification, publishNotificationDatas] = useMutation<
        PublishChatNotification,
        PublishChatNotificationVariables
    >(PUBLISH_CHAT_NOTIFICATION);

    const { data: otherUserData, loading: otherUserLoading } = useQuery<
        UserProfileImgOrgs,
        UserProfileImgOrgsVariables
    >(USER_PROFILE_IMG_ORGS, {
        variables: { uid: receiver },
    });
    const { data, loading } = useQuery<
        UserProfileImgOrgs,
        UserProfileImgOrgsVariables
    >(USER_PROFILE_IMG_ORGS, {
        variables: { uid: sender },
    });

    const scrollToBottom = () => {
        // ! making the change from <Switch /> to <IonRouterOutlet />
        // ! disables the scrollToBottom behavior
        let content = document.querySelector('ion-content');
        if (content === null) return;
        content.scrollToBottom();
    };

    if (loading || otherUserLoading) {
        return <Loading />;
    }

    //sender has to be user and receiver cannot be user
    if (sender != currentUser.uid || receiver == currentUser.uid) {
        return <Redirect to='/' />;
    }
    //Checks to see if users have at least 1 group in common
    if (data != undefined && otherUserData != undefined) {
        const filteredGroups = data.profile.organizations
            .map((orgs) => orgs.id)
            .filter((value) =>
                otherUserData.profile.organizations
                    .map((orgs) => orgs.id)
                    .includes(value)
            );
        if (filteredGroups.length == 0) {
            return <Redirect to='/' />;
        }
    }

    const { profile: senderProfile } = data;
    const { profile: receiverProfile } = otherUserData;

    const handleSendMessage = async (e) => {
        e.preventDefault();
        if (message.length === 0 || message === '' || message === null) return;
        const { serverTimestamp } = firebase.firestore.FieldValue;
        const chatroomRef = firestore.collection('chatrooms');

        chatroomRef
            .where(`users.${sender}.id`, '==', sender)
            .where(`users.${receiver}.id`, '==', receiver)
            .get()
            .then((docSnapshot) => {
                if (docSnapshot.docs.length > 0) {
                    setChatroomId(docSnapshot.docs[0].id);

                    firestore
                        .collection('chatrooms')
                        .doc(docSnapshot.docs[0].id)
                        .collection('messages')
                        .add({
                            // check if document exists , if true send message
                            sentAt: serverTimestamp(),
                            message: message,
                            sender: fuser.uid,
                            senderFirstName: data.profile.firstName,
                            senderLastName: data.profile.lastName,
                            senderImg: data.profile.profilePicture,
                            seen: false,
                        })
                        .then(() => {
                            publishNotification({
                                variables: {
                                    senderId: sender,
                                    recieverId: receiver,
                                    textMessage: message,
                                    chatId: docSnapshot.docs[0].id,
                                },
                            }).catch((err) => console.error(err));
                            setMessage('');
                            scrollToBottom();
                        })
                        .catch((err) => console.error(err));
                }
            })
            .catch((error) =>
                console.error('Error getting documents: ', error)
            );
    };

    return (
        <IonPage>
            <div>
                <div>
                    <div className='chatRoom__header'>
                        <Strokes top={true} />
                    </div>
                </div>
                <div>
                    <div className='chatRoom__header--details--col'>
                        <button
                            style={{ backgroundColor: 'transparent' }}
                            onClick={() =>
                                history.push(
                                    `/user/${otherUserData.profile.uid}`
                                )
                            }
                        >
                            <div className='chatRoom__details--img--wrap'>
                                <IonImg
                                    className='chatRoom__details--img'
                                    src={otherUserData.profile.profilePicture}
                                    alt='user'
                                />
                            </div>
                        </button>
                        <p className='chatRoom__details--name'>
                            {limitStr(
                                `${otherUserData.profile.firstName} ${otherUserData.profile.lastName}`,
                                15
                            )}
                        </p>
                        <IonIcon
                            onClick={() => history.goBack()}
                            icon={closeOutline}
                            className='chatRoom__details--icon'
                        />
                    </div>
                </div>
            </div>
            <IonContent>
                <Messages
                    chatroomId={chatroomId}
                    senderProfile={senderProfile}
                    recieverProfile={receiverProfile}
                />
            </IonContent>
            <div className='chatRoom__input--form'>
                <textarea
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    // type="text"
                    autoComplete='off'
                    className='chatRoom__input'
                    placeholder='Message...'
                ></textarea>
                <IonButton
                    onClick={handleSendMessage}
                    type='submit'
                    fill='clear'
                    color='ion-primary'
                    className='chatRoom__submit'
                >
                    <IonIcon
                        className='chatRoom__submit--icon'
                        icon={arrowUpOutline}
                    />
                </IonButton>
            </div>
            <NavBar />
        </IonPage>
    );
};

export default ChatRoom;

const Messages: React.FC<{
    senderProfile: any;
    recieverProfile: any;
    chatroomId: string;
}> = ({ senderProfile, recieverProfile, chatroomId }) => {
    const fuser = new FirebaseHelper(useAuth().currentUser);
    const firestore = firebase.firestore();
    const { sender, receiver } = useParams<{
        sender: string;
        receiver: string;
    }>();
    const [messages, setMessages] = useState<Messages[]>([]);
    const [isLoadingMessages, setIsLoadingMessages] = useState<boolean>(true);
    const history = useHistory();
    const scrollToBottom = () => {
        // ! making the change from <Switch /> to <IonRouterOutlet />
        // ! disables the scrollToBottom behavior
        let content = document.querySelector('ion-content');
        if (content === null) return;
        content.scrollToBottom();
    };
    const newMessage: any = useRef(null);
    useEffect(() => {
        if (newMessage && newMessage.current) {
            newMessage.current.scrollIntoView({ block: 'center' });
        }
    }, [messages]);

    useEffect(() => {
        if (history.location.pathname.includes('chatroom')) {
            const { serverTimestamp } = firebase.firestore.FieldValue;
            scrollToBottom();

            const unsubscribe = firestore
                .collection('chatrooms')
                .where(`users.${sender}.id`, '==', sender)
                .where(`users.${receiver}.id`, '==', receiver)
                .onSnapshot((querySnapShot) => {
                    if (querySnapShot.docs.length > 0) {
                        firestore
                            .collection('chatrooms')
                            .doc(querySnapShot.docs[0].id)
                            .collection('messages')
                            .orderBy('sentAt')
                            .onSnapshot(
                                (snapshot) => {
                                    const msgs = [];
                                    snapshot.forEach((doc) => {
                                        const { id } = doc;
                                        const msgObj = { ...doc.data(), id };
                                        msgs.push(msgObj);
                                    });
                                    setMessages(msgs);
                                    setIsLoadingMessages(false);
                                    scrollToBottom();
                                },
                                (err) => console.error(err)
                            );
                    } else {
                        firestore
                            .collection('chatrooms')
                            .add({
                                createdAt: serverTimestamp(),
                                usersArr: [sender, receiver],
                                users: {
                                    [sender]: {
                                        id: senderProfile.uid,
                                        imageUrl: senderProfile.profilePicture,
                                        firstName: senderProfile.firstName,
                                        lastName: senderProfile.lastName,
                                    },
                                    [receiver]: {
                                        id: receiver,
                                        imageUrl:
                                            recieverProfile.profilePicture,
                                        firstName: recieverProfile.firstName,
                                        lastName: recieverProfile.lastName,
                                    },
                                },
                            })
                            .catch((err) =>
                                console.error('Error writing document!', err)
                            );
                    }
                });

            return () => unsubscribe();
        }
    }, [history.location.pathname]);

    return (
        <IonContent>
            <IonList className='chatRoom__convo'>
                {messages.length > 0 &&
                    messages.map((message) => {
                        const chatBubble =
                            message.sender === fuser.uid ? (
                                <SenderChatBubble
                                    key={message.id}
                                    {...message}
                                    chatroomId={chatroomId}
                                />
                            ) : (
                                <ReceiverChatBubble
                                    key={message.id}
                                    {...message}
                                />
                            );
                        return chatBubble;
                    })}
            </IonList>
            <div ref={newMessage}></div>
        </IonContent>
    );
};
