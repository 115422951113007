// importing modules
import React, { useRef, useState } from 'react';
import moment from 'moment-timezone';
import { useHistory, NavLink } from 'react-router-dom';
// importing components
import {
    IonButton,
    IonCol,
    IonImg,
    IonSpinner,
    IonIcon,
    IonToast,
} from '@ionic/react';
// importing assets
import { informationCircleOutline } from 'ionicons/icons';
// importing utilities
import { getLocalStorage } from '../../helpers/localStorage/localStorageHelper';
// importing graphql
import { useMutation } from '@apollo/react-hooks';
import {
    GetProfileJobs_profile_upcomingJobsReceive,
    GetProfileJobs_profile_upcomingJobsServe_recipient,
    GetProfileJobs_profile_upcomingJobsReceive_provider,
    GetProfileJobs_profile_outgoingParticipationRequests_recipient,
    GetProfileJobs_profile_incomingParticipationRequests_provider,
    GetProfileJobs_profile_outgoingParticipationRequests,
} from '../../GraphQL/__generated__/GetProfileJobs';

import { PARTICIPATION_REQUEST_NUDGE } from '../../GraphQL/ParticipationRequest/PARTICIPATION_REQUEST_NUDGE/PARTICIPATION_REQUEST_NUDGE';
import {
    ParticipationRequestNudge,
    ParticipationRequestNudgeVariables,
} from '../../GraphQL/__generated__/ParticipationRequestNudge';

import { NUDGE_VIA_SKILL_REQUEST } from '../../GraphQL/Job/NUDGE_VIA_SKILL_REQUEST/NUDGE_VIA_SKILL_REQUEST';
import {
    NudgeViaSkillRequest,
    NudgeViaSkillRequestVariables,
} from '../../GraphQL/__generated__/NudgeViaSkillRequest';
// importing styles
import './UserTaskItem.css';
// firebase context
import { useAuth } from '../../context/firebase/authContext';

const ReceivingTaskItem: React.FC<{
    task: any;
    otherProfile:
        | GetProfileJobs_profile_upcomingJobsServe_recipient
        | GetProfileJobs_profile_upcomingJobsReceive_provider;
}> = ({ task, otherProfile }) => {
    const { currentUser } = useAuth();
    const history = useHistory();
    const chatLink = useRef(null);
    const handleChatroom = () => chatLink.current?.click();
    const [disableClick, setDisableClick] = useState<boolean>(false);
    const [showToast, setShowToast] = useState<boolean>(false);

    const [participationRequestNudge, participationRequestNudgeDatas] =
        useMutation<
            ParticipationRequestNudge,
            ParticipationRequestNudgeVariables
        >(PARTICIPATION_REQUEST_NUDGE);
    const [skillRequestNudge, skillRequestNudgeDatas] = useMutation<
        NudgeViaSkillRequest,
        NudgeViaSkillRequestVariables
    >(NUDGE_VIA_SKILL_REQUEST);

    const handleNudge = () => {
        setDisableClick(true);
        if (task.skill === null) {
            skillRequestNudge({
                variables: { id: task.id, nudgeWho: 'provider' },
            })
                .then((r) => setShowToast(true))
                .catch((err) => console.error(err));
        } else {
            participationRequestNudge({
                variables: { id: task.id, nudgeWho: 'provider' },
            })
                .then((r) => setShowToast(true))
                .catch((err) => console.error(err));
        }
        // disable nudge button for 30secs after click
        setTimeout(() => {
            setDisableClick(false);
        }, 30000);
    };
    const taskStartTime = moment(
        task.Status === undefined
            ? task.skill.jobPost.startTime
            : task.startTime
    ).tz(getLocalStorage('tz'), false);
    const taskEndTime = moment(
        task.Status === undefined ? task.skill.jobPost.endTime : task.endTime
    ).tz(getLocalStorage('tz'), false);
    let taskTime;
    let btns;

    // conditionals to render component based on task time/status
    if (moment() < taskStartTime) {
        // check if the task hasnt started
        taskTime = (
            <p className='taskItem__info--time'>
                &#x02022;&nbsp;Starts {taskStartTime.fromNow()} &nbsp;{' '}
                <span>- Receiving</span>
            </p>
        );
        btns = (
            <React.Fragment>
                <IonButton
                    fill='clear'
                    color='ion-primary'
                    className='taskItem__btn--send'
                    // onClick={() => history.push(`/chatroom/${currentUser.uid}/${otherProfile.uid}`)}
                    onClick={handleChatroom}
                >
                    <a
                        ref={chatLink}
                        href={`/chatroom/${currentUser.uid}/${otherProfile.uid}`}
                        type='hidden'
                    ></a>
                    Send Message
                </IonButton>
                <IonButton
                    onClick={() => history.push(`/delete/${task.id}`)}
                    fill='clear'
                    color='ion-primary'
                    className='taskItem__btn--cancel'
                >
                    Delete Task
                </IonButton>
            </React.Fragment>
        );
    } else if (moment().isBetween(taskStartTime, taskEndTime)) {
        // check if the task is in progress
        taskTime = (
            <p className='taskItem__info--time'>
                &#x02022;&nbsp;Ends {taskEndTime.fromNow()} &nbsp;{' '}
                <span>- Receiving</span>
            </p>
        );
        if (
            task.Status === undefined
                ? task.status.toString() === 'RECIPIENT_HAS_PROPOSED_OFFER'
                : task.Status.toString() === 'RECIPIENT_HAS_PROPOSED_OFFER'
        ) {
            btns = (
                <>
                    <div className='completeTask__btn--waiting'>
                        <IonSpinner
                            className='completeTask__btn--waiting--spinner'
                            name='crescent'
                        />
                        <p className='completeTask__btn--waiting--text'>
                            Waiting on Provider
                        </p>
                    </div>
                    <IonButton
                        fill='clear'
                        color='ion-primary'
                        onClick={handleNudge}
                        disabled={disableClick}
                        className='completeTask__btn--nudge'
                    >
                        Nudge
                    </IonButton>
                </>
            );
        } else {
            btns = (
                <IonButton
                    fill='clear'
                    color='ion-primary'
                    className='completeTask__btn'
                >
                    <NavLink
                        to={{
                            pathname: `/completeTask/recipient/${task.id}`,
                            state: {
                                id: task.id,
                            },
                        }}
                        className='completeTask__btn--link'
                    >
                        Complete This Task
                    </NavLink>
                </IonButton>
            );
        }
    } else if (moment() > taskEndTime) {
        // check if the task has ended
        taskTime = (
            <p className='taskItem__info--time'>
                &#x02022;&nbsp;Ended {taskEndTime.fromNow()} &nbsp;{' '}
                <span>- Receiving</span>
            </p>
        );
        if (
            task.Status === undefined
                ? task.status.toString() === 'RECIPIENT_HAS_PROPOSED_OFFER'
                : task.Status.toString() === 'RECIPIENT_HAS_PROPOSED_OFFER'
        ) {
            btns = (
                <>
                    <div className='completeTask__btn--waiting'>
                        <IonSpinner
                            className='completeTask__btn--waiting--spinner'
                            name='crescent'
                        />
                        <p className='completeTask__btn--waiting--text'>
                            Waiting on Provider
                        </p>
                    </div>
                    <IonButton
                        fill='clear'
                        color='ion-primary'
                        onClick={handleNudge}
                        disabled={disableClick}
                        className='completeTask__btn--nudge'
                    >
                        Nudge
                    </IonButton>
                </>
            );
        } else {
            btns = (
                <IonButton
                    fill='clear'
                    color='ion-primary'
                    className='completeTask__btn'
                >
                    <NavLink
                        to={{
                            pathname: `/completeTask/recipient/${task.id}`,
                            state: {
                                id: task.id,
                            },
                        }}
                        className='completeTask__btn--link'
                    >
                        Complete This Task
                    </NavLink>
                </IonButton>
            );
        }
    }

    return (
        <IonCol className='taskItem' sizeSm='11' sizeXs='11'>
            <IonToast
                cssClass='completeTask__nudge--toast'
                isOpen={showToast}
                onDidDismiss={() => setShowToast(false)}
                message='Transaction Reminder Sent!'
                duration={2500}
            />
            <IonIcon
                onClick={() => {
                    console.debug('Hello: ', task);
                    history.push(
                        task.Status === undefined
                            ? `/task/${task.id}`
                            : `/skillRequest/${task.id}`
                    );
                }}
                icon={informationCircleOutline}
                className='taskItem__info--icon'
            />
            <IonCol sizeXs='12' sizeSm='12' className='taskItem__info'>
                <IonCol sizeXl='3' sizeLg='3' sizeMd='3' sizeXs='3' sizeSm='3'>
                    <div className='taskItem__img--wrap'>
                        <IonImg
                            onClick={() =>
                                history.push(`/user/${otherProfile.uid}`)
                            }
                            className='taskItem__img'
                            src={otherProfile.profilePicture}
                        />
                    </div>
                </IonCol>
                <IonCol className='taskItem__info--wrap' sizeXs='9' sizeSm='9'>
                    <h3 className='taskItem__info--name'>
                        {task.Status === undefined
                            ? task.skill.category
                            : task.Skill.category}{' '}
                        w/{' '}
                        {otherProfile.firstName + ' ' + otherProfile.lastName}
                    </h3>
                    {taskTime}
                </IonCol>
            </IonCol>
            <IonCol sizeXs='12' sizeSm='12' className='taskItem__btn--wrap'>
                {btns}
            </IonCol>
        </IonCol>
    );
};

export default ReceivingTaskItem;
