// importing modules
import React, { useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import {
    LocationPreference,
    Status,
} from '../../../GraphQL/__generated__/globalTypes';

interface LocationObj {
    address: string;
    lat: number;
    lng: number;
    status: Status;
    locationPreference: LocationPreference;
}

const Map: React.FC<LocationObj> = ({ address, lat, lng, status }) => {
    const [isRefreshingMap, setIsRefreshingMap] = useState<boolean>(true);

    const refreshMap = async () => {
        setIsRefreshingMap(true);
        setTimeout(() => {
            setIsRefreshingMap(false);
        }, 100);
    };

    useEffect(() => {
        refreshMap();
    }, []);

    const handleRadiusCircle = (map, maps) => {
        const circle = new google.maps.Circle({
            strokeColor: '#1aa086',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#1aa086',
            fillOpacity: 0.3,
            map,
            center: { lat: lat, lng: lng }, // lat & lng must be passed in from the task
            radius: 2000,
        });
        circle.setVisible(true);
        circle.setMap(map);
    };

    return (
        <React.Fragment>
            {!isRefreshingMap && (
                <GoogleMapReact
                    bootstrapURLKeys={{
                        key: 'AIzaSyD7B3yKTqxrAYt-HWajE5mGW8z2k2Z2TyQ',
                    }}
                    center={{ lat: lat, lng: lng }}
                    defaultZoom={13}
                    options={{
                        streetViewControl: false,
                        styles: [
                            {
                                featureType: 'poi',
                                elementType: 'labels',
                                stylers: [{ visibility: 'on' }],
                            },
                        ],
                    }}
                    onGoogleApiLoaded={({ map, maps }) => {
                        handleRadiusCircle(map, maps);
                    }}
                    yesIWantToUseGoogleMapApiInternals
                ></GoogleMapReact>
            )}
        </React.Fragment>
    );
};

export default Map;
