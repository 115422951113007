// importing modules
import React, { useRef } from 'react';
import moment from 'moment-timezone';
import { useAuth } from 'context/firebase/authContext';
import { useHistory } from 'react-router-dom';
// importing components
import { IonRow, IonCol, IonButton, IonImg } from '@ionic/react';
// importing utilities
import { getLocalStorage } from '../../../helpers/localStorage/localStorageHelper';
import { time_credit_conversion } from '../../../utils/timeConversion';
// importing types
import { GetProfileSkillRequests_profile_upcomingJobsServe } from '../../../GraphQL/__generated__/GetProfileSkillRequests';

const CompletedIncomingSkillRequests: React.FC<{
    completedSkillRequests: GetProfileSkillRequests_profile_upcomingJobsServe[];
}> = ({ completedSkillRequests }) => {
    const { currentUser } = useAuth();
    const history = useHistory();
    const chatLink = useRef(null);
    const handleChatroom = () => chatLink.current?.click();

    return (
        <IonRow className='completedTasksItem__row'>
            {completedSkillRequests.length > 0 ? (
                completedSkillRequests.map((skillReq) => {
                    return (
                        <IonCol
                            key={skillReq.id}
                            className='completedTasksItem'
                            sizeSm='11'
                            sizeXs='11'
                        >
                            <IonCol
                                sizeXs='12'
                                sizeSm='12'
                                className='completedTasksItem__info'
                            >
                                <IonCol
                                    sizeXl='3'
                                    sizeLg='3'
                                    sizeMd='3'
                                    sizeXs='3'
                                    sizeSm='3'
                                >
                                    <div className='completedTasksItem__img--wrap'>
                                        <IonImg
                                            className='completedTasksItem__img'
                                            src={
                                                skillReq.recipient
                                                    .profilePicture
                                            }
                                        />
                                    </div>
                                </IonCol>
                                <IonCol
                                    className='completedTasksItem__info--wrap'
                                    sizeXs='9'
                                    sizeSm='9'
                                >
                                    <h3 className='completedTasksItem__info--name'>
                                        {skillReq.Skill.category} w/{' '}
                                        {skillReq.recipient.firstName}
                                    </h3>
                                    <p className='completedTasksItem__info--date'>
                                        {moment(skillReq.endTime)
                                            .tz(getLocalStorage('tz'), false)
                                            .format('dddd, MMMM Do YYYY')}
                                    </p>
                                    <p className='completedTasksItem__info--credits--earned'>
                                        +
                                        {time_credit_conversion(
                                            skillReq.CREDITS
                                        )}
                                    </p>
                                </IonCol>
                            </IonCol>
                            <IonCol
                                sizeXs='12'
                                sizeSm='12'
                                className='completedTasksItem__btn--wrap'
                            >
                                <IonButton
                                    onClick={() =>
                                        history.push(
                                            `/skillRequest/${skillReq.id}`
                                        )
                                    }
                                    fill='clear'
                                    color='ion-primary'
                                    className='completedTasksItem__btn--view'
                                >
                                    View Task
                                </IonButton>
                                <IonButton
                                    fill='clear'
                                    color='ion-primary'
                                    className='completedTasksItem__btn--chat'
                                    // onClick={() => history.push(`/chatroom/${currentUser.uid}/${skillReq.recipient.uid}`)}
                                    onClick={handleChatroom}
                                >
                                    <a
                                        ref={chatLink}
                                        href={`/chatroom/${currentUser.uid}/${skillReq.recipient.uid}`}
                                        type='hidden'
                                    ></a>
                                    Enter Chatroom
                                </IonButton>
                            </IonCol>
                        </IonCol>
                    );
                })
            ) : (
                <IonRow className='tasksPosted__text'>
                    <p>
                        Completed skill requests that you accepted
                        <br /> will appear here.
                    </p>
                </IonRow>
            )}
        </IonRow>
    );
};

export default CompletedIncomingSkillRequests;
