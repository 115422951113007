// importing modules
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
// importing components
import {
    IonButton,
    IonCol,
    IonImg,
    IonContent,
    IonRow,
    IonModal,
    IonIcon,
} from '@ionic/react';
import UserImg from '../../../../components/UserImg/UserImg';
import Strokes from 'components/Strokes';
// importing assets
import { arrowBackOutline, closeOutline } from 'ionicons/icons';
// importing utils
import {
    setLocalStorage,
    getLocalStorage,
    updateLocalStorage,
} from '../../../../helpers/localStorage/localStorageHelper';
// importing types
import { Status } from '../../../../GraphQL/__generated__/globalTypes';
//importing styles
import '../TaskParticipants.css';

const RemoveParticipant: React.FC<{
    showParticipantModal: boolean;
    handleParticipantModal: () => void;
    selectedParticipant: any;
}> = ({
    showParticipantModal,
    handleParticipantModal,
    selectedParticipant,
}) => {
    const history = useHistory();

    const handleRemoveParticipant = () => {
        const currentParticipants = getLocalStorage(
            'completeTaskPostedViaRecipient'
        ).participants;
        const updatedParticipant = currentParticipants.map((p) =>
            p.id === selectedParticipant.id
                ? {
                      ...p,
                      status: Status.DENIED,
                      hrs: 0,
                      mins: 0,
                      rating: 0,
                      review: '',
                  }
                : p
        );

        updateLocalStorage(
            'completeTaskPostedViaRecipient',
            'participants',
            updatedParticipant
        );
    };
    return (
        <IonModal isOpen={showParticipantModal}>
            <IonContent>
                <IonContent>
                    <IonRow className='taskPartc__header'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='taskPartc__header--container'
                        >
                            <Strokes top={true} />
                            <IonCol
                                sizeXs='12'
                                sizeSm='12'
                                sizeMd='12'
                                className='completeTaskNav__btns--wrap'
                            >
                                <IonButton
                                    className='completeTaskNav__btn'
                                    onClick={handleParticipantModal}
                                    fill='clear'
                                    color='ion-primary'
                                >
                                    <IonIcon
                                        className='completeTaskNav__btn--icon'
                                        icon={arrowBackOutline}
                                    />
                                </IonButton>
                                <IonButton
                                    className='completeTaskNav__btn'
                                    onClick={() => {
                                        handleParticipantModal();
                                        history.push('/home');
                                        setLocalStorage(
                                            'completeTaskPostedViaRecipient',
                                            {
                                                hrs: '',
                                                mins: '',
                                                participants: [],
                                            }
                                        );
                                    }}
                                    color='ion-primary'
                                    fill='clear'
                                >
                                    <IonIcon
                                        className='completeTaskNav__btn--icon'
                                        icon={closeOutline}
                                    />
                                </IonButton>
                            </IonCol>
                            <UserImg />
                        </IonCol>
                    </IonRow>
                    <IonRow className='taskPartc__textContent'>
                        <IonCol
                            className='taskPartc__textContent--container'
                            sizeSm='12'
                            sizeXs='12'
                        >
                            <h1 className='taskPartc__textContent--title'>
                                <span>Confirm,</span> remove member?
                            </h1>
                            <p className='completeTask__textContent--text'>
                                This member will no longer participate in this
                                task.
                            </p>
                        </IonCol>
                    </IonRow>
                    <IonRow className='taskPartcItem__row'>
                        <IonCol
                            className='taskPartcItem'
                            sizeSm='11'
                            sizeXs='11'
                        >
                            <IonCol
                                sizeXs='12'
                                sizeSm='12'
                                className='taskPartcItem__info'
                            >
                                <IonCol
                                    sizeXl='3'
                                    sizeLg='3'
                                    sizeMd='3'
                                    sizeXs='3'
                                    sizeSm='3'
                                >
                                    <div className='taskPartcItem__img--wrap'>
                                        <IonImg
                                            className='taskPartcItem__img'
                                            src={
                                                selectedParticipant.profilePicture
                                            }
                                        />
                                    </div>
                                </IonCol>
                                <IonCol
                                    className='taskPartcItem__info--wrap'
                                    sizeXs='9'
                                    sizeSm='9'
                                >
                                    <h3 className='taskPartcItem__info--name'>
                                        {selectedParticipant.firstName}{' '}
                                        {selectedParticipant.lastName}
                                    </h3>
                                    <p className='taskPartcItem__info--cred'>
                                        {selectedParticipant.skill}
                                    </p>
                                </IonCol>
                            </IonCol>
                        </IonCol>
                    </IonRow>
                    <IonRow className='completeTask__btn--container'>
                        <IonCol
                            className='completeTask__btn--wrap'
                            sizeXs='12'
                            sizeSm='12'
                        >
                            <IonButton
                                onClick={() => {
                                    handleRemoveParticipant();
                                    handleParticipantModal();
                                }}
                                fill='clear'
                                color='ion-primary'
                                className='taskPartc__btn--remove'
                            >
                                Remove Member
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonContent>
            </IonContent>
        </IonModal>
    );
};

export default RemoveParticipant;
