// importing modules
import React from 'react';
// importing components
import { IonRow } from '@ionic/react';
import ParticipationRequestItem from '../OutgoingParticipationRequestItem/OutgoingParticipationRequestItem';
// importing types
import { GetProfileJobsPosted_profile_outgoingParticipationRequests } from '../../../GraphQL/__generated__/GetProfileJobsPosted';

const ParticipationRequestList: React.FC<{
    participationRequests: GetProfileJobsPosted_profile_outgoingParticipationRequests[];
}> = ({ participationRequests }) => {
    return (
        <>
            {participationRequests.length > 0 ? (
                participationRequests.map((pr) => {
                    return (
                        <ParticipationRequestItem
                            key={pr.id}
                            participationRequest={pr}
                        />
                    );
                })
            ) : (
                <IonRow className='tasksPosted__text'>
                    <p>
                        Tasks that you request to participate in
                        <br />
                        will appear here.
                    </p>
                </IonRow>
            )}
        </>
    );
};

export default ParticipationRequestList;
