const theme = {
    colors: {
        green: {
            light: 'hsl(148, 55%, 53%)',
            dark: 'hsl(168, 72%, 36%)',
            forest: 'hsl(180, 43%, 1%)',
            teal: 'hsl(179, 88%, 35%)',
        },
        gradients: {
            v1: 'linear-gradient(155deg, #0ba9a7, 2ebd91 36%, #44c983 100%)',
            v2: 'linear-gradient(135deg, #0ba9a7, #44c983)',
        },
        white: {
            regular: 'hsl(0, 0%, 94%)',
            off: 'hsl(0, 0%, 89%)',
            light: 'hsl(212, 8%, 69%)',
        },
        gray: {
            light: 'hsl(0,0%,82%)',
            inactive: 'hsla(40, 41%, 42%, 0.2)',
        },
        purple: 'hsl(241, 32%, 52%)',
        orange: 'hsl(24, 100%, 49%)',
        red: 'hsl(0, 88%, 68%)',
        black: 'hsl(210, 2%, 16%)',
        yellow: 'hsl(44, 100%, 48%)',
        zeplin: 'hsl(0, 0%, 96%)',
    },
    font_weight: {
        bold: '800',
        semiBold: '600',
        regular: '500',
        light: '300',
    },
    font_size: {
        xsmall: '0.85rem',
        small: '0.95rem',
        med: '2rem',
        lg: '3rem',
    },
    font_family: 'Poppins, sans-serif',
};

export default theme;
