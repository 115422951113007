import gql from 'graphql-tag';

export const CreateStripeAccount = gql`
    query createStripeAccount($uid: String!) {
        createStripeAccount(uid: $uid) {
            onboardingURL
        }
    }
`;
export const GetStripeAccount = gql`
    query getStripeAccount($uid: String!) {
        getStripeAccount(uid: $uid) {
            account_status
            id
            charges_enabled
            country
            created
            default_currency
            details_submitted
            email
            object
            payouts_enabled
            express
            loginURL
            external_accounts {
                bank_name
                account
                account_holder_name
                account_holder_type
                country
                currency
                default_for_currency
                fingerprint
                id
                last4
                object
                routing_number
                status
                brand
            }
        }
    }
`;
export const GetLoginURL = gql`
    query getLoginURL($uid: String!) {
        getLoginURL(uid: $uid) {
            loginURL
        }
    }
`;
export const GetUserAccount = gql`
    query profile($uid: String!) {
        profile(where: { uid: $uid }) {
            firstName
            profilePicture
        }
    }
`;

export const GetStripeCustomerAccount = gql`
    query getStripeCustomerAccount($uid: String!) {
        getStripeCustomerAccount(uid: $uid) {
            id
            object
            address
            balance
            created
            currency
            default_source
            description
            email
            invoice_prefix
            payment_methods {
                id
                object
                created
                customer
                type
                card {
                    brand
                    country
                    funding
                    last4
                    exp_month
                    exp_year
                }
            }
        }
    }
`;

export const AttachPaymentMethod = gql`
    mutation attachPaymentMethod($uid: String!, $paymentMethodId: String!) {
        attachPaymentMethod(uid: $uid, paymentMethodId: $paymentMethodId) {
            status
            error
        }
    }
`;
export const DetachPaymentMethod = gql`
    mutation detachPaymentMethod($paymentMethodId: String!) {
        detachPaymentMethod(paymentMethodId: $paymentMethodId) {
            status
        }
    }
`;
export const GetCurrentBalance = gql`
    query getCurrentBalance($uid: String!) {
        getCurrentBalance(uid: $uid) {
            object
            available {
                amount
                currency
            }
            instant_available {
                amount
                currency
            }
            pending {
                amount
                currency
            }
            livemode
        }
    }
`;

export const CreateStripeTransaction = gql`
    mutation createStripeTransaction(
        $uid: String!
        $total: Int!
        $providerAccounts: String!
        $paymentMethod: String!
        $taskId: Int!
    ) {
        createStripeTransaction(
            uid: $uid
            total: $total
            providerAccounts: $providerAccounts
            paymentMethod: $paymentMethod
            taskId: $taskId
        ) {
            status
            error
            responses
        }
    }
`;

export const GetStripeTransfer = gql`
    query stripeTransfer($stripeTransferId: String!) {
        stripeTransfer(stripeTransferId: $stripeTransferId) {
            amount
            created
        }
    }
`;
