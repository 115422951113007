// importing modules
import React from 'react';
import { useHistory } from 'react-router';
// importing components
import { IonAvatar, IonButton, IonCol, IonImg } from '@ionic/react';
// importing utilities
import filterCompletedTasks from '../../utils/filterCompletedTasks';
import genCreatedAt from '../../utils/genCreatedAt';
import limitStr from '../../utils/limitString';
import capitalize from '../../utils/capitalize';
// importing types
import { GetSkillPoolByOrganizationCode_organization_members } from '../../GraphQL/__generated__/GetSkillPoolByOrganizationCode';

const MemberTile: React.FC<GetSkillPoolByOrganizationCode_organization_members> =
    (member) => {
        const history = useHistory();
        const timeOnYing = genCreatedAt(member.createdAt);
        const completedTasksCount: number = filterCompletedTasks(
            member.upcomingJobsReceive,
            member.upcomingJobsServe
        );

        let skillsArr: string[] = [];
        member.skills.forEach((skill) => {
            // check for duplicates
            if (!skillsArr.includes(skill.category))
                skillsArr.push(skill.category);
        });

        return (
            <IonCol
                onClick={() => history.push('/user/' + member.uid)}
                key={member.id}
                className='nearbyMember'
                sizeSm='11'
                sizeXs='11'
            >
                <IonCol sizeXs='12' sizeSm='12' className='nearbyMember__info'>
                    <IonCol
                        sizeXl='3'
                        sizeLg='3'
                        sizeMd='3'
                        sizeXs='3'
                        sizeSm='3'
                    >
                        <IonAvatar className='groupTaskItem__img--wrap'>
                            <IonImg
                                className='groupTaskItem__img'
                                src={member.profilePicture}
                            />
                        </IonAvatar>
                    </IonCol>
                    <IonCol
                        className='nearbyMember__info--wrap'
                        sizeXs='9'
                        sizeSm='9'
                    >
                        <h3 className='nearbyMember__info--name'>
                            {capitalize(member.firstName) +
                                ' ' +
                                capitalize(member.lastName)}
                        </h3>
                        <p className='nearbyMember__info--reviews'>
                            {timeOnYing} | {completedTasksCount} tasks completed
                        </p>
                    </IonCol>
                </IonCol>
                <IonCol
                    sizeXs='12'
                    sizeSm='12'
                    className='nearbyMember__skills--wrap'
                >
                    {skillsArr.length > 0
                        ? skillsArr.map((skill, id) => (
                              <IonButton
                                  fill='clear'
                                  key={id}
                                  color='ion-primary'
                                  className='nearbyMember__skill'
                              >
                                  {limitStr(skill, 45)}
                              </IonButton>
                          ))
                        : null}
                </IonCol>
            </IonCol>
        );
    };

export default MemberTile;
