// importing modules
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
// importing components
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonIcon,
    IonImg,
    IonPage,
    IonRow,
} from '@ionic/react';
import UserImg from '../UI/UserImg/UserImg';
import CompleteTaskNavTabs from '../UI/CompleteTaskNavTabs/CompleteTaskNavTabs';
import CompleteTaskFooter from '../../../components/FooterWithChildren/FooterWithChildren';
import Strokes from 'components/Strokes';
// importing assets
import { star } from 'ionicons/icons';
import Loading from '../../../components/status/Loading';
// importing helpers
import { getLocalStorage } from '../../../helpers/localStorage/localStorageHelper';
import { yingDebug } from '../../../utils/debug/YingDebug';
import capitalize from '../../../utils/capitalize';
// importing graphql
import { useMutation, useQuery } from '@apollo/react-hooks';
import { gql_PROVIDER_REQUEST } from '../../../GraphQL/Job/PROVIDER_REQUEST/PROVIDER_REQUEST';
import {
    PROVIDER_REQUEST,
    PROVIDER_REQUESTVariables,
} from '../../../GraphQL/__generated__/PROVIDER_REQUEST';
import { GET_JOB_BY_ID } from '../../../GraphQL/Job/GET_JOB_BY_ID/GET_JOB_BY_ID';
import {
    GetJobById,
    GetJobByIdVariables,
} from '../../../GraphQL/__generated__/GetJobById';
import { GET_TIME_CREDITS_BY_UID } from '../../../GraphQL/Ledger/GET_TIME_CREDITS_BY_UID';
// importing styles
import './ReviewTask.css';
import { useAuth } from '../../../context/firebase/authContext';

const ReviewTaskConfirm: React.FC = () => {
    const { currentUser } = useAuth();
    const history = useHistory();
    const { taskId } = useParams<{ taskId: string }>();
    const [rt, setRating] = useState<number>(0);
    const [reviewDescr, setReviewDescr] = useState<string>('');

    useEffect(() => {
        if (getLocalStorage('completeTaskViaProvider')) {
            const completeTaskObj = getLocalStorage('completeTaskViaProvider');
            setRating(completeTaskObj.rating);
        } else {
            history.push('/home');
        }
    });

    const { data, loading, error, fetchMore } = useQuery<
        GetJobById, // query return return type (shape of data)
        GetJobByIdVariables // query request type       (shape of variables)
    >(GET_JOB_BY_ID, {
        variables: {
            jobId: parseInt(taskId),
        },
        fetchPolicy: 'no-cache',
    });

    const [
        exe__PROVIDER_REQUEST,
        {
            data: data__PROVIDER_REQUEST,
            loading: loading__PROVIDER_REQUEST,
            error: error__PROVIDER_REQUEST,
        },
    ] = useMutation<PROVIDER_REQUEST, PROVIDER_REQUESTVariables>(
        gql_PROVIDER_REQUEST
    );

    const handleCompleteTask = () => {
        const completeTaskObj = getLocalStorage('completeTaskViaProvider');
        exe__PROVIDER_REQUEST({
            variables: {
                id_job: parseInt(taskId),
                credits: 0,
                review: reviewDescr,
                rating: parseInt(completeTaskObj.rating),
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GET_TIME_CREDITS_BY_UID,
                    variables: {
                        uid: currentUser.uid,
                    },
                },
            ],
        })
            .then((result) => {
                history.push(`/completeTask/provider/${taskId}/confirmation`);
            })
            .catch((e) => yingDebug(e));
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <IonPage>
            <IonContent fullscreen>
                <IonGrid>
                    {/* reviewTask header */}
                    <IonRow className='reviewTask__header'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='reviewTask__header--container'
                        >
                            <Strokes top={true} />
                            <CompleteTaskNavTabs
                                disablePrevStep={false}
                                prevPage={`/completeTask/provider/${taskId}/rating`}
                            />
                            <UserImg />
                        </IonCol>
                    </IonRow>
                    {/* text content */}
                    <IonRow className='reviewTask__textContent'>
                        <IonCol
                            className='reviewTask__textContent--container'
                            sizeSm='12'
                            sizeXs='12'
                        >
                            <h1 className='reviewTask__textContent--title'>
                                Nice! Please write a <span>review</span> of your
                                experience helping{' '}
                                <span>
                                    {capitalize(data.job.recipient.firstName)}
                                </span>
                            </h1>
                        </IonCol>
                    </IonRow>
                    {/* star rating */}
                    <IonGrid className='taskReview__container'>
                        <IonRow className='stars'>
                            <IonCol
                                sizeXs='10'
                                sizeSm='10'
                                className='stars__container'
                            >
                                {[...Array(5)].map((s, i) => {
                                    const ratingVal: number = i + 1;
                                    return (
                                        <label key={i}>
                                            <input
                                                type='radio'
                                                name='rating'
                                                value={ratingVal}
                                            />
                                            <IonIcon
                                                style={{
                                                    color:
                                                        ratingVal <= rt
                                                            ? '#f7b500'
                                                            : '#d3d3d3',
                                                }}
                                                icon={star}
                                                className='star'
                                            />
                                        </label>
                                    );
                                })}
                            </IonCol>
                            <IonCol
                                className='reviewTask__textarea--wrap'
                                sizeXs='12'
                                sizeSm='12'
                            >
                                <textarea
                                    onChange={(event) =>
                                        setReviewDescr(event.target.value)
                                    }
                                    value={reviewDescr}
                                    className='reviewTask__textarea'
                                    placeholder='Say something nice...'
                                    required
                                ></textarea>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonGrid>
            </IonContent>
            <CompleteTaskFooter>
                <IonButton
                    fill='clear'
                    onClick={handleCompleteTask}
                    disabled={reviewDescr.length === 0}
                    type='submit'
                    color='ion-primary'
                    className='footerWithChild__btn'
                >
                    Complete Task
                </IonButton>
            </CompleteTaskFooter>
        </IonPage>
    );
};

export default ReviewTaskConfirm;
