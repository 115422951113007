// importing modules & hooks
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { useAuth } from 'context/firebase/authContext';
import { useOnboardingFlow } from 'utils/onboarding';
// components
import {
    IonAvatar,
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonPage,
    IonRow,
} from '@ionic/react';
// importing assets
// importing components
import SkillConfirmItem from './SkillConfirmItem/SkillConfirmItem';
import EditSkillModal from 'pages/EditProfile/EditSkillModal/EditSkillModal';
import Loading from 'components/status/Loading';
import Strokes from 'components/Strokes';
// importing styles
import './SkillConfirmation.css';
// importing graphql ...
import {
    SkillsById,
    SkillsByIdVariables,
    SkillsById_profile_skills,
} from 'GraphQL/__generated__/SkillsById';
import { SKILL_BY_UID } from 'GraphQL/Skill/SKILL_BY_UID/SkillBy_subId';

const SkillConfirmation = () => {
    const {
        currentUser: { uid },
    } = useAuth();
    const history = useHistory();
    const [showSkillModal, setShowSkillModal] = useState<boolean>(false);
    const [skillName, setSkillName] = useState<string>('');
    const [skillDescription, setSkillDescription] = useState<string>('');
    const [skillId, setSkillId] = useState<number>(-1);
    const handleSkillModal = () => setShowSkillModal(!showSkillModal);
    const { isOnboarding, next, pop } = useOnboardingFlow();

    const {
        data: data__SKILL_BY_UID,
        loading: loading__SKILL_BY_UID,
        error: error__SKILL_BY_UID,
        fetchMore: fetchMore__SKILL_BY_UID,
    } = useQuery<
        SkillsById, // query return return type (shape of data)
        SkillsByIdVariables // query request type       (shape of variables)
    >(SKILL_BY_UID, {
        variables: {
            uid: uid,
        },
    });

    if (loading__SKILL_BY_UID) {
        return <Loading />;
    }

    const redirectToHomeOrEdit = () => {
        if (isOnboarding()) {
            // next takes the onboarding stage we expect to be on now, so that if
            // it gets called multiple times side effects only happen once
            next('/skillSelection');
        } else {
            // pop takes the onboarding stage we expect to be on now, so that if
            // it gets called multiple times side effects only happen once.
            // it's okay that we aren't necessarily in onboarding when this is
            // called
            pop('/skillSelection');
            history.push(`/user/${uid}/edit`);
        }
    };

    const activeSkills: SkillsById_profile_skills[] =
        data__SKILL_BY_UID.profile.skills.filter(
            (skill) => skill.status === 'ACTIVE'
        );

    return (
        <IonPage>
            <IonContent>
                <IonGrid>
                    <EditSkillModal
                        handleSkillModal={handleSkillModal}
                        showSkillModal={showSkillModal}
                        id={skillId}
                        skillName={skillName}
                        skillDescription={skillDescription}
                        uid={uid}
                    />
                    {/* skillConfirm header */}
                    <IonRow className='skillConfirm__header'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='skillConfirm__header--container'
                        >
                            <Strokes top={true} green={false} />
                            <IonAvatar className='skillConfirm__header--img--wrap'>
                                <IonImg
                                    className='skillConfirm__header--img'
                                    src={
                                        data__SKILL_BY_UID.profile
                                            .profilePicture
                                    }
                                    alt='user'
                                />
                            </IonAvatar>
                        </IonCol>
                    </IonRow>
                    {/* skillConfirm header */}

                    {/* text content */}
                    <IonRow className='skillConfirm__textContent'>
                        <IonCol
                            className='skillConfirm__textContent--container'
                            sizeSm='12'
                            sizeXs='12'
                        >
                            <h1 className='skillConfirm__textContent--title'>
                                Congrats, you’re a
                                <span> Skill sharing member</span>
                            </h1>
                            <p className='skillConfirm__textContent--text'>
                                Below is what will be seen on your profile by
                                others in your group
                            </p>
                        </IonCol>
                    </IonRow>
                    {/* text content */}

                    <IonGrid className='skillConfirm__list--container'>
                        <IonRow className='skillConfirm__list'>
                            {activeSkills.length > 0 &&
                                activeSkills.map((skillConfirmed) => (
                                    <SkillConfirmItem
                                        skillfullPersonsName={''}
                                        setSkillId={setSkillId}
                                        setSkillName={setSkillName}
                                        setSkillDescription={
                                            setSkillDescription
                                        }
                                        handleSkillModal={handleSkillModal}
                                        key={skillConfirmed.id}
                                        {...skillConfirmed}
                                    />
                                ))}
                            {/* newSKill btn */}
                            <IonCol
                                onClick={() =>
                                    history.push(
                                        '/skillSelection',
                                        history.location.state
                                    )
                                }
                                className='skillConfirm__newSkill--btn'
                                sizeXs='12'
                                sizeSm='12'
                            >
                                <IonCol
                                    sizeXs='12'
                                    sizeSm='12'
                                    className='ion-padding skillConfirm__newSkill--content'
                                >
                                    <p className='skillConfirm__newSkill--btn--text'>
                                        + Add Another Skill
                                    </p>
                                </IonCol>
                            </IonCol>
                        </IonRow>
                    </IonGrid>

                    <IonRow className='skillConfirm__btn--container'>
                        <IonCol
                            className='skillConfirm__btn--wrap'
                            sizeXs='12'
                            sizeSm='12'
                        >
                            <IonButton
                                fill='clear'
                                id='confirm_skill_btn'
                                onClick={() => redirectToHomeOrEdit()}
                                color='ion-primary'
                                className='skillConfirm__btn'
                            >
                                Continue
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default SkillConfirmation;
