// importing modules
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import { useAuth } from 'context/firebase/authContext';
// importing components
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonPage,
    IonRow,
} from '@ionic/react';
import RequestTaskTabs from '../UI/RequestTaskTabs';
import UserImg from '../UserImg/UserImg';
import Loading from 'components/status/Loading';
import Strokes from 'components/Strokes';
// importing assets
// importing helpers
import capitalize from 'utils/capitalize';
import {
    setLocalStorage,
    deleteLocalStorage,
    getLocalStorage,
} from 'helpers/localStorage/localStorageHelper';
// importing graphql utilities - types
import { useMutation, useQuery } from '@apollo/react-hooks';
import { TASK_PROVIDER_INFO } from 'GraphQL/RequestTaskConfirmation/getProviderTaskInfo';
import {
    GetProviderTaskInfo,
    GetProviderTaskInfoVariables,
} from 'GraphQL/__generated__/GetProviderTaskInfo';
import {
    CreateOneJob,
    CreateOneJobVariables,
} from 'GraphQL/__generated__/CreateOneJob';
import { CREATE_ONE_JOB } from 'GraphQL/Job/CreateOneJob';
import {
    createOneNotificationWithSender,
    createOneNotificationWithSenderVariables,
} from 'GraphQL/__generated__/createOneNotificationWithSender';
import { CREATE_ONE_NOTIFICATION_WITH_SENDER } from 'GraphQL/Notification/CREATE_ONE_NOTIFICATION_WITH_SENDER/CREATE_ONE_NOTIFICATION_WITH_SENDER';
import { GET_JOBS_FOR_SCHEDULE_FILTERING } from 'GraphQL/Job/GET_JOBS_FOR_SCHEDULE_FILTERING/GET_JOBS_FOR_SCHEDULE_FILTERING';
import { PUBLISH_SKILL_REQUEST_NOTIFICATION } from 'GraphQL/Job/publishSkillRequestNotification/publishSkillRequestNotification';
import {
    publishSkillRequestNotification,
    publishSkillRequestNotificationVariables,
} from 'GraphQL/__generated__/publishSkillRequestNotification';
import { NotificationType } from 'GraphQL/__generated__/globalTypes';
import { USER_PROFILE_BY_UID } from 'GraphQL/Profile/UserProfile/USER_PROFILE_BY_UID';
import {
    UserProfile_byID,
    UserProfile_byIDVariables,
} from 'GraphQL/__generated__/UserProfile_byID';
import { GET_USER_PARTY_INFO } from 'GraphQL/parties/GET_USER_PARTY_INFO';
import {
    GetUserPartyInfo,
    GetUserPartyInfoVariables,
} from 'GraphQL/__generated__/GetUserPartyInfo';
// importing styles
import './TaskMsg.css';
import gql from 'graphql-tag';

export default () => {
    const { currentUser } = useAuth();
    const fakeGQL = gql`
        mutation CreateOneJob(
            $startTime: DateTime!
            $endTime: DateTime!
            $providerID: String!
            $recipientID: String!
            $skillOwnerID: Int!
            $requestMessage: String!
            $locationString: String
            $locationPreference: LocationPreference
            $sender: Int
            $receiver: Int
        ) {
            createOneJob(
                data: {
                    startTime: $startTime
                    endTime: $endTime
                    provider: { connect: { uid: $providerID } }
                    recipient: { connect: { uid: $recipientID } }
                    Skill: { connect: { id: $skillOwnerID } }
                    sender: { connect: { partyId: $sender } }
                    receiver: { connect: { partyId: $receiver } }
                    requestMessage: $requestMessage
                    location: {
                        create: {
                            locationString: $locationString
                            locationPreference: $locationPreference
                        }
                    }
                }
            ) {
                id
                provider {
                    id
                    uid
                    lastName
                    firstName
                }
                recipient {
                    id
                    uid
                    lastName
                    firstName
                }
                Skill {
                    name
                }
            }
        }
    `;
    const nonAdminNotification = gql`
        mutation createOneNotificationWithSender(
            $title: String!
            $subTitle: String!
            $body: String!
            $additionalDetails: String
            $expires: DateTime
            $path: String
            $notificationType: NotificationType
            $uid: String!
            $senderUid: String!
        ) {
            createOneNotification(
                data: {
                    title: $title
                    subTitle: $subTitle
                    body: $body
                    additionalDetails: $additionalDetails
                    expires: $expires
                    path: $path
                    notificationType: $notificationType
                    receiverProfile: { connect: { uid: $uid } }
                    senderProfile: { connect: { uid: $senderUid } }
                }
            ) {
                id
            }
        }
    `;

    const history = useHistory();
    const { provider, skillName, skillId } = useParams<{
        provider: string;
        skillName: string;
        skillId: string;
    }>();
    const decodedSkillName = decodeURIComponent(skillName);
    const [msg, setMsg] = useState<string>('');
    const [senderId, senderIdData] = useState(0);
    const [receiverId, receiverIdData] = useState(0);
    const [createJob, createJobDatas] = useMutation<
        CreateOneJob,
        CreateOneJobVariables
    >(CREATE_ONE_JOB);
    const [createNonAdminJob, createNonAdminJobDatas] = useMutation(fakeGQL);
    const [createNonAdminNotification, createNonAdminNotificationsDatas] =
        useMutation(nonAdminNotification);
    const [createNotification, createNotificationDatas] = useMutation<
        createOneNotificationWithSender,
        createOneNotificationWithSenderVariables
    >(CREATE_ONE_NOTIFICATION_WITH_SENDER);
    const [publishNotification, publishNotificationDatas] = useMutation<
        publishSkillRequestNotification,
        publishSkillRequestNotificationVariables
    >(PUBLISH_SKILL_REQUEST_NOTIFICATION);

    //Grabbing the receiverId
    const {
        data: dataReceiverOne,
        error: something,
        loading: stillLoading,
    } = useQuery<UserProfile_byID, UserProfile_byIDVariables>(
        USER_PROFILE_BY_UID,
        {
            variables: { subId: currentUser.uid },
            fetchPolicy: 'no-cache',
        }
    );

    const { data: dataReceiverTwo, loading: receiverloading } = useQuery<
        GetUserPartyInfo,
        GetUserPartyInfoVariables
    >(GET_USER_PARTY_INFO, {
        skip: !dataReceiverOne,
        variables: { userId: dataReceiverOne && dataReceiverOne?.profile.id },
    });

    //Grabbing the senderId
    const {
        data: dataSenderId,
        error: somethingSender,
        loading: stillLoadingSender,
    } = useQuery<UserProfile_byID, UserProfile_byIDVariables>(
        USER_PROFILE_BY_UID,
        {
            skip: !dataReceiverTwo,
            variables: { subId: provider },
            fetchPolicy: 'no-cache',
        }
    );

    const { data: dataSenderTwo, loading: loadingsender } = useQuery<
        GetUserPartyInfo,
        GetUserPartyInfoVariables
    >(GET_USER_PARTY_INFO, {
        skip: !dataSenderId,
        variables: { userId: dataSenderId && dataSenderId?.profile.id },
    });

    useEffect(() => {
        dataSenderTwo?.showAllParties.map((thing) => {
            if (thing.bankAccountrelation[0].accountType == 'BALANCE') {
                senderIdData(thing.partyId);
            }
        });
        dataReceiverTwo?.showAllParties.map((thing) => {
            if (thing.bankAccountrelation[0].accountType == 'BALANCE') {
                receiverIdData(thing.partyId);
            }
        });
    }, [dataSenderTwo, dataReceiverTwo]);

    /// DAYLIGHT SAVINGS
    function isDST(d) {
        let jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
        let jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
        return Math.max(jan, jul) != d.getTimezoneOffset();
    }

    const handleRequestTask = () => {
        const localStorageObj = getLocalStorage('requestSkill'); // get values from local storage

        const skillRequestObj = {
            skillName: decodedSkillName,
            date: moment(localStorageObj.date).format('YYYY-MM-DD'),
            // startTime: momentTZ(localStorageObj.startTime)
            //     .tz(getLocalStorage('tz'))
            //     .format('HH:mm'),
            // endTime: momentTZ(localStorageObj.endTime)
            //     .tz(getLocalStorage('tz'))
            //     .format('HH:mm'),
            location: localStorageObj.location,
            msg: msg,

            /// DAYLIGHT SAVINGS
            startTime:
                localStorageObj.startTime.length > 0
                    ? isDST(new Date(localStorageObj.startTime)) !==
                      isDST(new Date())
                        ? momentTZ(localStorageObj.startTime)
                              .add(-1, 'hours')
                              .tz(getLocalStorage('tz'))
                              .format('HH:mm')
                        : momentTZ(localStorageObj.startTime)
                              .tz(getLocalStorage('tz'))
                              .format('HH:mm')
                    : null, // startime
            endTime:
                localStorageObj.endTime.length > 0
                    ? isDST(new Date(localStorageObj.endTime)) !==
                      isDST(new Date())
                        ? momentTZ(localStorageObj.endTime)
                              .add(-1, 'hours')
                              .tz(getLocalStorage('tz'))
                              .format('HH:mm')
                        : momentTZ(localStorageObj.endTime)
                              .tz(getLocalStorage('tz'))
                              .format('HH:mm')
                    : null, // endtime
        };

        const recipientTZ = momentTZ.tz(getLocalStorage('tz')).format('Z');
        const startDateTime =
            skillRequestObj.date +
            'T' +
            skillRequestObj.startTime +
            `:00${recipientTZ}`;
        const endDateTime =
            skillRequestObj.date +
            'T' +
            skillRequestObj.endTime +
            `:00${recipientTZ}`;

        let testSelector;
        let notificationSelector;

        getLocalStorage('currentAdminGroupId') == 0
            ? (testSelector = createNonAdminJob)
            : (testSelector = createJob);

        getLocalStorage('currentAdminGroupId') == 0
            ? (notificationSelector = createNonAdminNotification)
            : (notificationSelector = createNotification);

        testSelector({
            variables: {
                startTime: startDateTime,
                endTime: endDateTime,
                providerID: provider,
                recipientID: currentUser.uid,
                skillOwnerID: parseInt(skillId),
                requestMessage: msg,
                locationPreference: localStorageObj.locationType,
                locationString: localStorageObj.location,
                adminId: parseInt(getLocalStorage('currentAdminId')),
                groupId: parseInt(getLocalStorage('currentAdminGroupId')),
                sender: senderId,
                receiver: receiverId,
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GET_JOBS_FOR_SCHEDULE_FILTERING,
                    variables: {
                        providerUid: provider,
                        recipientUid: currentUser.uid,
                    },
                },
            ],
        })
            .then((result) => {
                const { data } = result;
                notificationSelector({
                    variables: {
                        title: 'New Request Of Your Service',
                        subTitle: decodedSkillName,
                        body: `${data.createOneJob.recipient.firstName} has requested your service.`,
                        additionalDetails: 'click here to manage this request',
                        expires: startDateTime,
                        path: `/accept/skillRequest/${data.createOneJob.id}`,
                        notificationType: NotificationType.TASK_REQUEST,
                        uid: provider,
                        senderUid: currentUser.uid,
                        adminId: parseInt(getLocalStorage('currentAdminId')),
                        groupId: parseInt(
                            getLocalStorage('currentAdminGroupId')
                        ),
                    },
                }).catch((err) => console.error(err));

                publishNotification({
                    variables: {
                        providerId: provider,
                        jobId: data.createOneJob.id,
                    },
                }).catch((err) => console.error(err));

                // deleteLocalStorage('requestSkill');
                const requestSkillObj = {
                    date: null,
                    time: '',
                    location: '',
                    locationType: '',
                    message: '',
                    timezone: '',
                    startTime: '',
                    endTime: '',
                };
                setLocalStorage('requestSkill', requestSkillObj);
                history.push(
                    `/requestSkill/${provider}/${skillName}/confirmation`
                );
            })
            .catch((error) => console.error(error));
    };

    const { data, loading, error, fetchMore } = useQuery<
        GetProviderTaskInfo,
        GetProviderTaskInfoVariables
    >(TASK_PROVIDER_INFO, {
        variables: {
            uid: provider,
        },
    });

    if (loading) {
        return <Loading />;
    }

    return (
        <IonPage>
            <IonContent>
                <IonGrid>
                    {/* taskMsg header */}
                    <IonRow className='taskMsg__header'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='taskMsg__header--container'
                        >
                            <Strokes top={true} />
                            <RequestTaskTabs
                                prevPage={`/requestSkill/${provider}/${skillName}/${skillId}/location`}
                                disablePrevStep={false}
                            />
                            <UserImg providerUid={provider} />
                        </IonCol>
                    </IonRow>
                    {/* text content */}
                    <IonRow className='taskMsg__textContent'>
                        <IonCol
                            className='taskMsg__textContent--container'
                            sizeSm='12'
                            sizeXs='12'
                        >
                            <h1 className='taskMsg__textContent--title'>
                                Lastly, <span>leave a message</span> for{' '}
                                {capitalize(data.profile.firstName)} if you like
                            </h1>
                            <p className='taskMsg__textContent--text'>
                                You can use this as an opportunity to explain
                                what you need with more detail.
                            </p>
                        </IonCol>
                    </IonRow>
                    {/* message input */}
                    <IonGrid className='taskMsg__form--container'>
                        <IonRow className='taskMsg__form--row'>
                            <IonCol
                                className='taskMsg__form--input--wrap'
                                sizeXs='12'
                                sizeSm='12'
                            >
                                <textarea
                                    onChange={(event) =>
                                        setMsg(event.target.value)
                                    }
                                    value={msg}
                                    className='taskMsg__form--textarea'
                                    placeholder='Start typing here...'
                                    required
                                ></textarea>
                            </IonCol>
                            <IonCol
                                className='calendar__text--wrap'
                                sizeSm='12'
                                sizeXs='12'
                            >
                                <p className='calendar__text'>
                                    This message will go to{' '}
                                    {capitalize(data.profile.firstName)}'s inbox
                                    You will be able to talk further once you
                                    finish
                                </p>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                    {/* btn */}
                    <IonRow className='taskMsg__btn--container'>
                        <IonCol
                            className='taskMsg__btn--wrap'
                            sizeXs='12'
                            sizeSm='12'
                        >
                            <IonButton
                                fill='clear'
                                disabled={msg.length === 0}
                                onClick={handleRequestTask}
                                color='ion-primary'
                                className='taskMsg__btn'
                            >
                                Finish & Schedule
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};
