import React, { useState } from 'react';
import {
    IonCol,
    IonGrid,
    IonImg,
    IonRow,
    IonIcon,
    IonInput,
    IonSpinner,
} from '@ionic/react';
import { star } from 'ionicons/icons';
import './GratitudePot.css';
import { GetProviderStripeAccount } from '../../Settings/StripeSettings/StripeHelpers';

const ProviderGratitudeCard: React.FC<{
    id: number;
    firstName: string;
    lastName: string;
    profilePicture: string;
    review: string;
    rating: number;
    skill: string;
    uid: string;
    paymentIntents: [];
    setIntents: Function;
    participationReqId: number;
}> = ({
    firstName,
    lastName,
    profilePicture,
    rating,
    uid,
    paymentIntents,
    setIntents,
    participationReqId,
}) => {
    let stripeAccount = GetProviderStripeAccount(uid);

    //  controls error state for invalid input
    const [invalidInput, setInvalidInput] = useState<boolean>(false);

    //  keeps record of what tipping option is selected (#1-4)
    const [activeSelection, setActiveSelection] = useState<number>(0);
    const [inputAmount, setInputAmount] = useState('');

    const handleUpdate = (currentTip: number, currentSelection: number) => {
        //  accounts for bug where a false selection is triggered when updating the inputAmount to empty
        if (
            currentSelection == 4 &&
            inputAmount == '' &&
            activeSelection != 4 &&
            isNaN(currentTip)
        ) {
            return;
        }

        //  only selection 4 has user input, selections 1-3 have predetermined amounts
        //  this clears the input amount if other options are selected
        if (currentSelection != 4) {
            setInputAmount('');
        }

        if (inputAmount == '') {
            setInvalidInput(false);
        }
        //updates the highlighted selection if user clicks on it
        setActiveSelection(currentSelection);

        //if user deletes their input, enters 0 or not a number, remove the intent from the dictionary
        if (isNaN(currentTip) || currentTip === 0 || !currentTip) {
            let newIntents = {
                ...paymentIntents,
            };
            delete newIntents[stripeAccount.id];
            setIntents(newIntents);
        } else {
            //if less than $1, delete input
            if (currentTip < 0) {
                setInvalidInput(true);
                let newIntents = {
                    ...paymentIntents,
                };
                delete newIntents[stripeAccount.id];
                setIntents(newIntents);
            }

            //  switch the invalid input state to false
            //  add existing and new intents to the intent dictionary state
            else {
                setInvalidInput(false);
                if (stripeAccount.id) {
                    setIntents({
                        ...paymentIntents,
                        [stripeAccount.id]: {
                            amount: currentTip,
                            uid: uid,
                            firstName: firstName,
                            lastName: lastName,
                            participationReqId: participationReqId,
                        },
                    });
                }
            }
        }
    };
    if (!stripeAccount) {
        return (
            <div className='schedule__spinner--wrap'>
                <IonSpinner className='schedule__spinner' name='crescent' />
            </div>
        );
    }
    return (
        <IonGrid
            className='backgroundContainer'
            style={{ padding: '10px 0px' }}
        >
            <IonRow>
                <IonCol>
                    <div>
                        <IonImg
                            className='taskPartcItem__img'
                            src={profilePicture}
                        />
                    </div>
                </IonCol>
                <IonCol
                    className='taskPartcItem__info--wrap'
                    sizeXs='9'
                    sizeSm='9'
                >
                    <h3 className='taskPartcItem__info--name'>
                        {firstName} {lastName}
                    </h3>
                    {/* <p className='taskPartcItem__info--cred'>{skill}</p> */}
                    <IonCol className='taskPartcStars__container'>
                        {[...Array(5)].map((s, i) => {
                            const ratingVal = i + 1;
                            return (
                                <label key={i}>
                                    <input
                                        type='radio'
                                        name='rating'
                                        value={ratingVal}
                                    />
                                    <IonIcon
                                        style={{
                                            color:
                                                ratingVal <= rating
                                                    ? '#f7b500'
                                                    : '#d3d3d3',
                                        }}
                                        icon={star}
                                        className='taskPartcStars__icon'
                                    />
                                </label>
                            );
                        })}
                    </IonCol>
                </IonCol>
            </IonRow>
            {stripeAccount.account_status === 'complete' ? (
                <IonRow className='gratitude_card_container'>
                    <IonRow className='gratitude_card_row'>
                        <IonCol className='option_selection'>
                            <IonInput
                                onIonChange={(e) => {
                                    handleUpdate(
                                        parseFloat(e.detail.value!) * 100,
                                        4
                                    );
                                    setInputAmount(e.detail.value);
                                }}
                                type='number'
                                value={inputAmount}
                                className='custom_input_field'
                                placeholder='0.00'
                                min='0'
                            >
                                $
                            </IonInput>
                        </IonCol>
                    </IonRow>
                    <IonRow className='or_text'>
                        <IonCol>
                            <p>or</p>
                        </IonCol>
                    </IonRow>
                    <IonRow className='gratitude_card_row'>
                        <IonCol
                            className={
                                activeSelection === 1
                                    ? 'option_selection active'
                                    : 'option_selection'
                            }
                            onClick={() => handleUpdate(500, 1)}
                        >
                            $5
                        </IonCol>
                        <IonCol
                            className={
                                activeSelection === 2
                                    ? 'option_selection active'
                                    : 'option_selection'
                            }
                            onClick={() => handleUpdate(1000, 2)}
                        >
                            $10
                        </IonCol>
                        <IonCol
                            className={
                                activeSelection === 3
                                    ? 'option_selection active'
                                    : 'option_selection'
                            }
                            onClick={() => handleUpdate(2000, 3)}
                        >
                            $20
                        </IonCol>
                    </IonRow>

                    {invalidInput && (
                        <IonRow className='error_message'>
                            <p className='error_message'>
                                Gratitude amount must be a valid number greater
                                than $1{' '}
                            </p>
                        </IonRow>
                    )}
                </IonRow>
            ) : (
                <IonRow className='gratitude_card_container'>
                    <p className='disabled_grat_pot'>
                        Gratitude pot not enabled
                    </p>
                </IonRow>
            )}
        </IonGrid>
    );
};
export default ProviderGratitudeCard;
