import React from 'react';
import { IonCol, IonRow } from '@ionic/react';
import './SkillReviewItemFallback.css';

const ReviewsFallback: React.FC = () => {
    return (
        <IonRow className='ion-padding reviews'>
            <IonRow className='reviews__row'>
                <IonCol
                    className='ion-padding reviews__col--header'
                    sizeSm='12'
                    sizeXs='12'
                >
                    <h1 className='reviews__title'>Reviews</h1>
                </IonCol>
                <IonCol className='reviewsFallback'>
                    <h3 className='reviewsFallback__title'>
                        {' '}
                        Request them for a task or skill to
                        <br />
                        give them their first review!
                    </h3>
                    {/* <p className='reviewsFallback__text'>
                        This person hasn't performed any skills for
                        <br />
                        anyone in the YING community yet.
                    </p> */}
                </IonCol>
            </IonRow>
        </IonRow>
    );
};

export default ReviewsFallback;
