// importing modules
import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import getTimezoneOffset from 'moment-timezone';
// importing components
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonRow,
    IonPage,
    IonLabel,
    IonDatetime,
    IonIcon,
    IonItem,
} from '@ionic/react';
import CreateTaskTabs from '../UI/CreateTaskTabs/CreateTaskTabs';
import UserImg from '../UI/UserImg/UserImg';
import GroupImg from '../UI/GroupImg/GroupImg';
import CreateTaskFooter from '../../../components/FooterWithChildren/FooterWithChildren';
import Strokes from 'components/Strokes';
// importing assets
import { checkmarkOutline } from 'ionicons/icons';
// importing helpers
import {
    getLocalStorage,
    updateLocalStorage,
} from '../../../helpers/localStorage/localStorageHelper';
// importing graphql
import { useQuery } from '@apollo/react-hooks';
import { GET_JOBS_FOR_SCHEDULE_FILTERING } from '../../../GraphQL/Job/GET_JOBS_FOR_SCHEDULE_FILTERING/GET_JOBS_FOR_SCHEDULE_FILTERING';
import {
    GetJobsForScheduleFiltering,
    GetJobsForScheduleFilteringVariables,
} from '../../../GraphQL/__generated__/GetJobsForScheduleFiltering';

import { useAuth } from '../../../context/firebase/authContext';
//import ParticipationRequestItem from '../../IncomingParticipationRequestItem/ParticipationRequestItem';
import { GetProfileJobsPosted_profile_jobsPosted } from '../../../GraphQL/__generated__/GetProfileJobsPosted';
import { PROFILE_JOBS_POSTED } from 'GraphQL/Profile/PROFILE_JOBS_POSTED/PROFILE_JOBS_POSTED';
import {
    GetProfileJobsPosted,
    GetProfileJobsPostedVariables,
} from 'GraphQL/__generated__/GetProfileJobsPosted';

import {
    UserByUID,
    UserByUIDVariables,
} from '../../../GraphQL/__generated__/UserByUID';
import { USER_ORGANIZATIONS_BY_UID } from '../../../GraphQL/Profile/USER_ORGANIZATIONS/USER_ORGANIZATIONS_BY_UID';

// importing styles
import './StartTime.css';
import ConfirmationScreenLogo from 'components/ConfirmationScreenLogo/ConfirmationScreenLogo';
import CompletedJobsList from 'pages/CompletedTasks/CompletedTasksList/CompletedTasksList';

const StartTime: React.FC<{ taskType: string }> = ({ taskType }) => {
    const { currentUser } = useAuth();
    const history = useHistory();
    const { provider, groupId, groupCode } = useParams<{
        provider: string;
        groupId: string;
        groupCode: string;
    }>();
    const [selectedStartTime, setSelectedStartTime] = useState<string>('');
    const [selectedEndTime, setSelectedEndTime] = useState<string>('');
    const [isUndecided, setIsUndecided] = useState<boolean>(false);

    ///////////////
    const dateSelect = JSON.parse(localStorage.getItem('createTask'));
    const selectedDates = moment(dateSelect['date']).format(
        'dddd, MMMM DD, yyyy'
    );
    const previouslySelectedDate = dateSelect['startTime'];

    const getBaseDay = () => {
        //getting the date selected from choose a date screen
        const now = new Date(selectedDates);
        const base = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate(),
            now.getHours()
        );
        return base;
    };

    const [tbd, setTBD] = useState(false);
    const [startTime, setStartTime] = useState<any | null>(null);
    const [duration, setDuration] = useState<any | null>(null); //duration is in milliseconds
    const [baseTime, setBaseTime] = useState<Date>(getBaseDay());
    const [selectedDate, setSelectedDate] = useState<any>(null);
    const [unavailableTimes, setUnavailableTimes] = useState<UnavailableTime[]>(
        []
    );
    const newMessage: any = useRef(null); //useRef attached to time tiles to scroll 8a.m in view
    const endTime =
        startTime && duration ? new Date(startTime.valueOf() + duration) : null; //calculating endtime by adding duration to starttime
    interface UnavailableTime {
        // this calculates the unavailable time range
        start: Date; // inclusive
        end: Date; // exclusive
    }

    useEffect(() => console.log(`Using ${baseTime} as base`), []);
    useEffect(() => console.log(`Using ${endTime} as endDuration`), []);

    let HOURS = [
        '12:00 AM',
        '12:15 AM',
        '12:30 AM',
        '12:45 AM',

        '1:00 AM',
        '1:15 AM',
        '1:30 AM',
        '1:45 AM',

        '2:00 AM',
        '2:15 AM',
        '2:30 AM',
        '2:45 AM',

        '3:00 AM',
        '3:15 AM',
        '3:30 AM',
        '3:45 AM',

        '4:00 AM',
        '4:15 AM',
        '4:30 AM',
        '4:45 AM',

        '5:00 AM',
        '5:15 AM',
        '5:30 AM',
        '5:45 AM',

        '6:00 AM',
        '6:15 AM',
        '6:30 AM',
        '6:45 AM',

        '7:00 AM',
        '7:15 AM',
        '7:30 AM',
        '7:45 AM',

        '8:00 AM',
        '8:15 AM',
        <div ref={newMessage}> 8:30 AM</div>,
        '8:45 AM',

        '9:00 AM',
        '9:15 AM',
        '9:30 AM',
        '9:45 AM',

        '10:00 AM',
        '10:15 AM',
        '10:30 AM',
        '10:45 AM',

        '11:00 AM',
        '11:15 AM',
        '11:30 AM',
        '11:45 AM',

        '12:00 PM',
        '12:15 PM',
        '12:30 PM',
        '12:45 PM',

        '1:00 PM',
        '1:15 PM',
        '1:30 PM',
        '1:45 PM',

        '2:00 PM',
        '2:15 PM',
        '2:30 PM',
        '2:45 PM',

        '3:00 PM',
        '3:15 PM',
        '3:30 PM',
        '3:45 PM',

        '4:00 PM',
        '4:15 PM',
        '4:30 PM',
        '4:45 PM',

        '5:00 PM',
        '5:15 PM',
        '5:30 PM',
        '5:45 PM',

        '6:00 PM',
        '6:15 PM',
        '6:30 PM',
        '6:45 PM',

        '7:00 PM',
        '7:15 PM',
        '7:30 PM',
        '7:45 PM',

        '8:00 PM',
        '8:15 PM',
        '8:30 PM',
        '8:45 PM',

        '9:00 PM',
        '9:15 PM',
        '9:30 PM',
        '9:45 PM',

        '10:00 PM',
        '10:15 PM',
        '10:30 PM',
        '10:45 PM',

        '11:00 PM',
        '11:15 PM',
        '11:30 PM',
        '11:45 PM',
    ];

    useEffect(() => {
        //keeps 8:00AM time tile in view when page is loaded
        if (newMessage && newMessage.current) {
            newMessage.current.scrollIntoView({ block: 'start' });
        }
    }, []);

    const scrollToBottom = () => {
        // DONT'T DELETE (keeps 8:00AM time tile in view when page is loaded)
        if (newMessage.current) {
            newMessage.current.scrollIntoView({
                block: 'center',
                behavior: 'smooth',
            });
        }
    };

    // useEffect(() => { //keeps 8:00AM time tile in view when page is loaded
    //     if ( topPage && topPage.current ) {
    //        return window.scrollTo(0, 0);
    //     }
    // }, [topPage])

    useEffect(() => {
        const createTaskLocal = getLocalStorage('createTask');
        if (createTaskLocal) {
            const startTime = createTaskLocal.startTime
                ? new Date(createTaskLocal.startTime)
                : null;
            const duration =
                startTime && createTaskLocal.endTime
                    ? new Date(createTaskLocal.endTime).valueOf() -
                      startTime.valueOf()
                    : null;
            setStartTime(startTime);
            setDuration(duration);
        } else {
            history.push('/home');
        }
    }, []);

    const handleStartTimeSelected = () => {
        updateLocalStorage('createTask', 'date', baseTime);

        if (selectedDates === 'Undecided') {
            updateLocalStorage('createTask', 'startTime', '');
            updateLocalStorage('createTask', 'endTime', '');
        }

        updateLocalStorage(
            'createTask',
            'startTime',
            isUndecided === true ? '' : startTime
        );
        updateLocalStorage(
            'createTask',
            'endTime',
            isUndecided === true ? '' : endTime
        );
        if (taskType === 'HOME') {
            history.push('/create-task/location');
        } else if (taskType === 'GROUP') {
            history.push(`/create-task/${groupId}/${groupCode}/location`);
        }
    };

    let content;
    let isNotValid = true;
    //let na = disableDates(filteredScheduleByDate);

    /*if ((selectedStartTime.length && selectedEndTime.length) !== 0) { //OLD CODE
        if (selectedEndTime < selectedStartTime) {
            content = (
                <p className='startTime__dials--msg--na'>
                    x The "End Time" must be later then the "Start Time"
                </p>
            );
        } else if (
            momentTZ(selectedStartTime)
                .tz(getLocalStorage('tz'))
                .isBefore(momentTZ().tz(getLocalStorage('tz'))) ||
            momentTZ(selectedEndTime)
                .tz(getLocalStorage('tz'))
                .isBefore(momentTZ().tz(getLocalStorage('tz')))
        ) {
            content = (
                <p className='startTime__dials--msg--na'>
                    x Sorry the "Start Time" | "End Time" you selected has
                    passed
                </p>
            );
        } else if (
            moment(selectedStartTime).format('h:mma') ===
            moment(selectedEndTime).format('h:mma')
        ) {
            content = (
                <p className='startTime__dials--msg--na'>
                    x The "Start Time" and "End Time" cannot be the same
                </p>
            );
        } else {
            content = (
                <p className='startTime__dials--msg--avl'>
                    The "Time" selected is available{' '}
                    <IonIcon
                        className='startTime__dials--msg--avl--icon'
                        icon={checkmarkOutline}
                    />
                </p>
            );
            isNotValid = false;
        }
    } */

    let img;
    if (taskType === 'HOME' && getLocalStorage('currentAdminGroupId') == 0) {
        img = <UserImg />;
    } else if (
        taskType === 'GROUP' &&
        getLocalStorage('currentAdminGroupId') == 0
    ) {
        img = <UserImg />;
    } else {
        img = <GroupImg />;
    }

    // // //VIVIAN DAYLIGHT SAVINGS
    // const updateStartTime = (e) => {
    //     const dateSelectedFormatted =
    //         getLocalStorage('createTask').date === 'Undecided'
    //             ? moment(new Date()).format('YYYY-MM-DD')
    //             : moment(getLocalStorage('createTask').date).format(
    //                   'YYYY-MM-DD'
    //               );

    //     const daylightStartTime = momentTZ(e.detail.value)
    //         .tz(getLocalStorage('tz'))
    //         .format('HH:mm');
    //     const recipientTZ = momentTZ.tz(getLocalStorage('tz')).format('Z');
    //     const startDateTime =
    //         dateSelectedFormatted + 'T' + startTime  + `:00${recipientTZ}`;
    //     //setSelectedStartTime(startDateTime);
    //     setStartTime(startDateTime);
    // };
    // const updateEndTime = (e) => {
    //     const dateSelectedFormatted =
    //         getLocalStorage('createTask').date === 'Undecided'
    //             ? moment(new Date()).format('YYYY-MM-DD')
    //             : moment(getLocalStorage('createTask').date).format(
    //                   'YYYY-MM-DD'
    //               );

    //     const daylightEndTime = momentTZ(e.detail.value)
    //         .tz(getLocalStorage('tz'))
    //         .format('HH:mm');
    //     const recipientTZ = momentTZ.tz(getLocalStorage('tz')).format('Z');
    //     const endDateTime =
    //         dateSelectedFormatted + 'T' + duration + `:00${recipientTZ}`;

    //     //setSelectedEndTime(endDateTime);
    //     setDuration(endDateTime);
    // };

    //// New Code for Time Tiles ///

    //Gets organizations of both currentUser and service provider
    const { data: currentUserData, loading: currentUserDataLoading } = useQuery<
        UserByUID, // query return return type (shape of data)
        UserByUIDVariables // query request type       (shape of variables)
    >(USER_ORGANIZATIONS_BY_UID, {
        variables: {
            subId: currentUser.uid,
        },
    });

    const { data: providerUserData, loading: providerUserDataLoading } =
        useQuery<
            UserByUID, // query return return type (shape of data)
            UserByUIDVariables // query request type       (shape of variables)
        >(USER_ORGANIZATIONS_BY_UID, {
            variables: {
                subId: provider,
            },
        });

    const { data, loading, error } = useQuery<
        GetProfileJobsPosted,
        GetProfileJobsPostedVariables
    >(PROFILE_JOBS_POSTED, {
        variables: { subId: currentUser.uid },
        fetchPolicy: 'no-cache',
    });

    // const filteredScheduleByDate = data?.profile?.jobsPosted.filter((job) => {
    //     const jobDate = momentTZ(job.startTime)
    //         .tz(getLocalStorage('tz'))
    //         .format('YYYY-MM-DD');
    //     const dateSelectedFormatted = moment(
    //         getLocalStorage('requestSkill').date
    //     ).format('YYYY-MM-DD');
    //     return jobDate === dateSelectedFormatted; // filter out all tasks from 1 particular day selected
    // });

    // this calculates the unavailable times
    useEffect(() => {
        // console.log(filteredScheduleByDate);
        console.log(data?.profile?.jobsPosted);

        if (!loading && !error) {
            const startTimes = data?.profile?.jobsPosted.map(
                (job) => job.startTime
            );
            const endTimes = data?.profile?.jobsPosted.map(
                (job) => job.endTime
            );
            const unavailable: UnavailableTime[] = [];

            for (let i = 0; i < startTimes.length; ++i)
                unavailable.push({
                    start: new Date(startTimes[i]),
                    end: new Date(endTimes[i]),
                });

            setUnavailableTimes(unavailable);
        }
    }, [data, loading, error]);

    // this will determine whether a time is
    // available
    const isAvailable = (time: Date) => {
        const ts = time.valueOf(); //converts time to number

        for (const ut of unavailableTimes) {
            if (ts >= ut.start.valueOf() && ts < ut.end.valueOf()) {
                return false;
            }
        }

        return true;
    };

    if (
        momentTZ(startTime) //Error message when start or end time has passed
            .tz(getLocalStorage('tz'))
            .isBefore(momentTZ().tz(getLocalStorage('tz'))) ||
        momentTZ(endTime)
            .tz(getLocalStorage('tz'))
            .isBefore(momentTZ().tz(getLocalStorage('tz')))
    ) {
        content = (
            <p className='startTime__dials--msg--na'>
                x Sorry, the "Start Time" | "End Time" you selected has already
                passed
            </p>
        );
    } /* else if (/*na.length > 0 &&*/ /* (startTime && endTime) !== null) {
        content = (
            <p className='startTime__dials--msg--na unselected'>
                x Sorry, the "Start Time" | "End Time" you selected is no longer
                available
            </p>
        );
    } */

    if ((startTime && endTime !== null) || tbd) {
        //Disables the "Continue" button if StartTime & Duration are not chosen
        isNotValid = false;
    }

    const MONTHS = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];

    const DAYS = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
    ];

    const modDay = (days) => {
        //calculates the Days based on DAYS array (in between the arrows)
        let newBase = new Date(baseTime);
        newBase.setDate(baseTime.getDate() + days);
        return newBase;
    };

    const modMonth = (months) => {
        //calculates the months based on MONTHS array (in between the arrows)
        let newBase = new Date(baseTime);
        newBase.setMonth(baseTime.getMonth() + months);
        return newBase;
    };

    const minsToMs = (mins: number) => mins * 60 * 1000; //converting minutes to milliseconds for calculating duration
    const msToMins = (ms: number) => Math.floor(ms / 1000 / 60); //converting milliseconds to minutes for calculating duration

    const isSelected = (date) => {
        if (
            startTime &&
            date.getTime() === startTime.getTime() //allows only starttime to be selected on time tiles and not endtime
            //|| (endTime && date.getTime() === endTime.getTime())  //OLD CODE
        )
            return true;

        if (startTime && date.getTime() === true) {
            return setIsUndecided(() => null); //setTBD(null);
        }

        //let afterStart = startTime && startTime.getTime() <= date.getTime(); //OLD CODE
        //let beforeEnd = endTime && date.getTime() <= endTime.getTime();
        //return afterStart;
        //&& beforeEnd;
    };

    const selectTime = (date) => {
        setTBD(false);

        if (!startTime) {
            //&& !endTime)
            setStartTime(date);
        } else if (startTime && startTime.getTime() === date.getTime()) {
            setStartTime(null);
            setDuration(null);
        } else if (
            (startTime && startTime.getTime() > date.getTime()) || //prevents endtime to be manipulated on time tiles after duration tile has set endtime
            (startTime && startTime.getTime() < date.getTime())
        ) {
            setStartTime(date);
            setDuration(null);
        }
        //else if (endTime && endTime.getTime() == date.getTime()) {
        // setEndTime(null);
        //}
        //else if (startTime && startTime.getTime() < date.getTime()) {
        //  setEndTime(date);
        //}
        //else if (startTime && date.getTime() < startTime.getTime()) {
        //if (!endTime) setEndTime(startTime);
        //setStartTime(date);
        //} //else if (endTime && endTime.getTime() < date.getTime()) {
        //  if (!startTime) //setStartTime(endTime);
        //setEndTime(date);
        //}
        else if (endTime && date.getTime() === endTime.getTime()) {
            if (!startTime) {
                setStartTime(date);
            } //else if (startTime.getTime() < date.getTime()) {
            //setEndTime(date);
            //}
            else {
                //setStartTime(date);
                //setEndTime(startTime);
            }
        } else {
            console.error('Unhandled state!');
            console.error('  date:     ', date);
            console.error('  startTime:', startTime);
            console.error('  endTime:  ', endTime);
        }
    };

    return (
        <IonPage>
            <IonContent>
                <IonGrid>
                    {/* startTime header */}
                    <IonRow className='startTime__header'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='startTime__header--container'
                        >
                            <div id='content'></div>
                            <Strokes top={true} />
                            <CreateTaskTabs disablePrevStep={false} />
                            {img}
                        </IonCol>
                    </IonRow>
                    {/* text content */}
                    <IonRow className='startTime__textContent'>
                        <IonCol
                            className='startTime__textContent--container'
                            sizeSm='12'
                            sizeXs='12'
                        >
                            <h1 className='startTime__textContent--title'>
                                Choose a <span>Time</span>
                            </h1>
                            {/* <p className='startTime__textContent--text'>
                                Please select your task's "Start Time"
                                <br />
                                and "Duration" below.
                            </p> */}
                        </IonCol>
                    </IonRow>
                    {/* start/end time dials *}        //PREVIOUS CODE FOR TIME DIALS
                    <IonRow className='startTime__dials--row'>
                        <IonItem className='startTime__dials--item'>
                            <IonLabel className='startTime__dials--label'>
                                Start Time
                            </IonLabel>
                            <IonDatetime
                                id='task_home_start_time'
                                placeholder='-'
                                displayTimezone={getLocalStorage('tz')}
                                displayFormat='h:mm A'
                                minuteValues='0,15,30,45'
                                onClick={() => setIsUndecided(false)}
                                onIonChange={updateStartTime}
                            ></IonDatetime>
                        </IonItem>
                    </IonRow>
                    <IonRow className='startTime__dials--row'>
                        <IonItem className='startTime__dials--item'>
                            <IonLabel className='startTime__dials--label'>
                                End Time
                            </IonLabel>
                            <IonDatetime
                                id='task_home_end_time'
                                placeholder='-'
                                displayTimezone={getLocalStorage('tz')}
                                displayFormat='h:mm A'
                                minuteValues='0,15,30,45'
                                onClick={() => setIsUndecided(false)}
                                onIonChange={updateEndTime}
                            ></IonDatetime>
                        </IonItem>

                        <IonRow className='startTime__form--row-tbd'>
                            <div
                                onClick={() => {
                                    setIsUndecided(!isUndecided);
                                }}
                                className={
                                    isUndecided === true
                                        ? 'startTime__content--day--na startTime__content--day--active'
                                        : 'startTime__content--day--na'
                                }
                            >
                                Time hasn't been decided
                            </div>
                        </IonRow>
                            </IonRow>*/}

                    <IonRow>
                        <h6 className='calendar-time-title'>
                            Start Time{' '}
                            {/* {startTime?.toLocaleString('en-us', {  //Uncomment if you want to see the starttime string displayed
                                hour: 'numeric',
                                minute: 'numeric',
                                month: 'short',
                                day: 'numeric',
                            }) ?? ''}{' '}  */}
                        </h6>
                    </IonRow>

                    {/* <IonRow >
                       <h6 className="end-time-title duration-text" >End Time:&nbsp; </h6>
                        <b>
                             <p className="duration-text" >{endTime?.toLocaleString('en-us', {  //Uncomment if you want to see the endtime string displayed
                                hour: 'numeric',
                                minute: 'numeric',
                                month: 'short',
                                day: 'numeric',
                            }) ?? ''}{' '}</p>
                        </b>
                        </IonRow>  */}

                    <IonGrid className=' height'>
                        <IonCol className='startTime__col day-picker'>
                            <strong>
                                <button
                                    className='arrow-left'
                                    onClick={() => setBaseTime(modDay(-1))} //left arrow to change date
                                >
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='16'
                                        height='12'
                                        fill='currentColor'
                                        className='bi bi-chevron-left'
                                        viewBox='0 0 16 16'
                                    >
                                        <path
                                            fillRule='evenodd'
                                            d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'
                                        />
                                    </svg>
                                </button>
                            </strong>

                            <p className='blue'>{`${DAYS[baseTime.getDay()]}, ${
                                MONTHS[baseTime.getMonth()]
                            } ${baseTime.getDate()} , ${baseTime.getFullYear()}`}</p>

                            <button
                                className='arrow-right'
                                onClick={() => setBaseTime(modDay(1))} //right arrow to change date
                            >
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='16'
                                    height='12'
                                    fill='currentColor'
                                    className='bi bi-chevron-right'
                                    viewBox='0 0 16 16'
                                >
                                    <path
                                        fillRule='evenodd'
                                        d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'
                                    />
                                </svg>
                            </button>

                            {/* month selector */}
                            {/*<button className='blue' onClick={() => setåBaseTime(modMonth())}>{'<'}</button>
                                <p className='blue'>{`${MONTHS[baseTime.getMonth()]} ${baseTime.getFullYear()}`}</p>
                               <button className='blue' onClick={() => setBaseTime(modMonth(1))}>{'>'}</button> */}
                        </IonCol>

                        <IonRow className='startTime__form--container-tbd'>
                            {/* hour buttons */}
                            <IonCol className='calendar-button white'>
                                {HOURS.map((timeString, i) => {
                                    const myTime = new Date(baseTime);
                                    myTime.setMinutes(i * 15); //setting the start time dials to 15 minutes each

                                    if (!isAvailable(myTime)) return null; //removes time tiles for unavailable time range

                                    return (
                                        <button
                                            //key={myTime.toISOString()}
                                            onClick={() => {
                                                selectTime(myTime);
                                                setIsUndecided(() => null);
                                            }} //if time is selected unselect TBD
                                            //onChange={updateStartTime}
                                            className={
                                                isSelected(myTime)
                                                    ? 'selected startTime__content--day--active'
                                                    : ''
                                            }
                                        >
                                            {timeString}
                                        </button>
                                    );
                                })}
                            </IonCol>

                            {/* tbd button*/}
                        </IonRow>
                        <IonRow className='startTime__form--row-tbd'>
                            <button
                                className={
                                    isUndecided === true
                                        ? 'startTime__content--day--na tbd-button startTime__content--day--active'
                                        : 'startTime__content--day--na tbd-button'
                                }
                                onClick={() => {
                                    setIsUndecided(!isUndecided); //if TBD is selected, unselect starttime and duration
                                    setStartTime(null);
                                    setDuration(null);
                                }}
                            >
                                Time hasn't been decided
                            </button>
                        </IonRow>
                    </IonGrid>

                    {/*  Duration */}
                    <IonRow>
                        <h6 className='duration-time-title'>Duration </h6>
                    </IonRow>

                    <IonRow className='startTime__form--container-tbd duration'>
                        {/* duration buttons */}
                        <IonCol className='calendar-button white '>
                            <React.Fragment>
                                <button
                                    className={
                                        minsToMs(15) === duration
                                            ? 'selected startTime__content--day-active '
                                            : ''
                                    }
                                    onClick={() => setDuration(minsToMs(15))}
                                    //onChange={updateEndTime}
                                >
                                    {' '}
                                    15 min
                                </button>
                                <button
                                    className={
                                        minsToMs(30) === duration
                                            ? 'selected startTime__content--day-active'
                                            : ''
                                    }
                                    onClick={() => setDuration(minsToMs(30))}
                                >
                                    30 min
                                </button>
                                <button
                                    className={
                                        minsToMs(45) === duration
                                            ? 'selected startTime__content--day-active'
                                            : ''
                                    }
                                    onClick={() => setDuration(minsToMs(45))}
                                >
                                    45 min
                                </button>
                                <button
                                    className={
                                        minsToMs(60) === duration
                                            ? 'selected startTime__content--day-active'
                                            : ''
                                    }
                                    onClick={() => setDuration(minsToMs(60))}
                                    //onChange={updateEndTime}
                                >
                                    1 hr
                                </button>
                                <button
                                    className={
                                        minsToMs(90) === duration
                                            ? 'selected startTime__content--day-active'
                                            : ''
                                    }
                                    onClick={() => setDuration(minsToMs(90))}
                                >
                                    1 hr 30 min
                                </button>
                                <button
                                    className={
                                        minsToMs(120) === duration
                                            ? 'selected startTime__content--day-active'
                                            : ''
                                    }
                                    onClick={() => setDuration(minsToMs(120))}
                                >
                                    2 hr{' '}
                                </button>
                                <button
                                    className={
                                        minsToMs(150) === duration
                                            ? 'selected startTime__content--day-active'
                                            : ''
                                    }
                                    onClick={() => setDuration(minsToMs(150))}
                                >
                                    2 hr 30 min
                                </button>
                                <button
                                    className={
                                        minsToMs(180) === duration
                                            ? 'selected startTime__content--day-active'
                                            : ''
                                    }
                                    onClick={() => setDuration(minsToMs(180))}
                                >
                                    3 hr
                                </button>
                                <button
                                    className={
                                        minsToMs(240) === duration
                                            ? 'selected startTime__content--day-active'
                                            : ''
                                    }
                                    onClick={() => setDuration(minsToMs(240))}
                                >
                                    4 hr
                                </button>
                                <button
                                    className={
                                        minsToMs(300) === duration
                                            ? 'selected startTime__content--day-active'
                                            : ''
                                    }
                                    onClick={() => setDuration(minsToMs(300))}
                                >
                                    5 hr
                                </button>
                                <button
                                    className={
                                        minsToMs(360) === duration
                                            ? 'selected startTime__content--day-active'
                                            : ''
                                    }
                                    onClick={() => setDuration(minsToMs(360))}
                                >
                                    6 hr
                                </button>
                                <button
                                    className={
                                        minsToMs(420) === duration
                                            ? 'selected startTime__content--day-active'
                                            : ''
                                    }
                                    onClick={() => setDuration(minsToMs(420))}
                                >
                                    7 hr
                                </button>
                                <button
                                    className={
                                        minsToMs(480) === duration
                                            ? 'selected startTime__content--day-active'
                                            : ''
                                    }
                                    onClick={() => setDuration(minsToMs(480))}
                                >
                                    8 hr
                                </button>
                            </React.Fragment>
                        </IonCol>
                    </IonRow>

                    {/* message  */}
                    <IonRow className='startTime__dials--msg--wrap'>
                        <IonCol
                            className='startTime__dials--msg'
                            sizeSm='12'
                            sizeXs='12'
                        >
                            {content}
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
            <CreateTaskFooter>
                <IonButton
                    fill='clear'
                    disabled={isNotValid && !isUndecided}
                    onClick={handleStartTimeSelected}
                    color='ion-primary'
                    className='footerWithChild__btn'
                    id='task_from_home_time_btn'
                >
                    Continue
                </IonButton>
            </CreateTaskFooter>
        </IonPage>
    );
};

export default StartTime;
