// importing modules
import React from 'react';
import { Calendar } from '@ionic-native/calendar';
import { Capacitor } from '@capacitor/core';
import momentTZ from 'moment-timezone';
// * importing components
import {
    IonCol,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonButton,
} from '@ionic/react';
// importing utilities
import { getLocalStorage } from 'helpers/localStorage/localStorageHelper';
// importing graphql & types
import { useQuery } from '@apollo/react-hooks';
import { GET_JOB_POST_BY_ID } from 'GraphQL/JobPost/GET_JOB_POST_BY_ID/GET_JOB_POST_BY_ID';
import {
    GetJobPostById,
    GetJobPostByIdVariables,
} from 'GraphQL/__generated__/GetJobPostById';
import {
    GetNotificationsByUser_notificationsByUserUid,
    GetNotificationsByUser_profile_organizations,
} from 'GraphQL/__generated__/GetNotificationsByUser';

export default ({
    notification,
    organizations,
    setShowActionCard,
}: {
    notification: GetNotificationsByUser_notificationsByUserUid;
    organizations: GetNotificationsByUser_profile_organizations[];
    setShowActionCard: Function;
}) => {
    let task;

    if (notification.notificationType === 'TASK_POST_UPDATED') {
        task = JSON.parse(notification.additionalDetails);
    } else if (notification.notificationType === 'PARTICIPATION_ACCEPTED') {
        task = notification.additionalDetails;
    } else {
        task = null;
    }

    const { data, loading } = useQuery<GetJobPostById, GetJobPostByIdVariables>(
        GET_JOB_POST_BY_ID,
        {
            variables: {
                jobPostId:
                    notification.notificationType === 'PARTICIPATION_ACCEPTED'
                        ? parseInt(task)
                        : parseInt(task.options.id),
            },
        }
    );

    if (loading) {
        return <div></div>;
    }

    const handleCalendarEvent = () => {
        // creates an event on the devices native calendar (if calendar permission was granted)
        if (
            Capacitor.platform !== 'web' &&
            data.jobPost.startDate !== null &&
            data.jobPost.startTime !== null &&
            data.jobPost.endTime !== null
        ) {
            // if native device run the code inside this block
            const prevStartTime = momentTZ(task.startTime);
            const prevStartDate = prevStartTime.tz(
                getLocalStorage('tz'),
                false
            );
            const prevEndTime = momentTZ(task.endTime);
            const prevEndDate = prevEndTime.tz(getLocalStorage('tz'), false);

            const tStartTime = momentTZ(data.jobPost.startTime);
            const tStartDate = tStartTime.tz(getLocalStorage('tz'), false);
            const tEndTime = momentTZ(data.jobPost.endTime);
            const tEndDate = tEndTime.tz(getLocalStorage('tz'), false);

            if (notification.notificationType === 'PARTICIPATION_ACCEPTED') {
                Calendar.findEventWithOptions(
                    `YING - ${data.jobPost.title}`,
                    data.jobPost.location.locationString,
                    'No notes available',
                    tStartDate.toDate(),
                    tEndDate.toDate(),
                    {
                        id: data.jobPost.id.toString(),
                        firstReminderMinutes: 5,
                        secondReminderMinutes: 120,
                    }
                )
                    .then((res) => {
                        if (res.length === 0) {
                            // creates a new updated calendar event
                            Calendar.createEventWithOptions(
                                `YING - ${data.jobPost.title}`,
                                data.jobPost.location.locationString,
                                'No notes available',
                                tStartDate.toDate(),
                                tEndDate.toDate(),
                                {
                                    id: data.jobPost.id.toString(),
                                    firstReminderMinutes: 5,
                                    secondReminderMinutes: 120,
                                }
                            )
                                .then((calendEvent) => {})
                                .catch((err) =>
                                    console.error(
                                        'error creating calendar event',
                                        err
                                    )
                                );
                        }
                    })
                    .catch(() => {
                        console.error('no event found!');
                    });
            } else if (notification.notificationType === 'TASK_POST_UPDATED') {
                // removes a scheduled event from the devices native calendar
                Calendar.deleteEvent(
                    task.title,
                    task.location,
                    task.notes,
                    prevStartDate.toDate(),
                    prevEndDate.toDate()
                )
                    .then((calendEvent) => {
                        console.log('removed calendar event', calendEvent);
                    })
                    .catch((err) =>
                        console.error('error creating calendar event', err)
                    );

                // creates a new updated calendar event
                Calendar.createEventWithOptions(
                    `YING - ${data.jobPost.title}`,
                    data.jobPost.location.locationString,
                    'No notes available',
                    tStartDate.toDate(),
                    tEndDate.toDate(),
                    {
                        id: data.jobPost.id.toString(),
                        firstReminderMinutes: 5,
                        secondReminderMinutes: 120,
                    }
                )
                    .then((calendEvent) => {
                        console.log('created calendar event', calendEvent);
                    })
                    .catch((err) =>
                        console.error('error creating calendar event', err)
                    );
            }
        }

        setShowActionCard(false);
    };

    let cardTitle;
    let cardMsg;
    let cardActionText;
    let btnText;

    if (notification.notificationType === 'TASK_POST_UPDATED') {
        cardTitle = (
            <h4 className='notificationTile__actionCard--title'>
                Task Updated - {data.jobPost.title}
            </h4>
        );
        cardMsg = (
            <p className='notificationTile__actionCard--text'>
                The date and/or time of the task <b>{notification.subTitle} </b>
                was recently updated by {data.jobPost.recipient.firstName}.
            </p>
        );
        cardActionText = (
            <p className='notificationTile__actionCard--text'>
                Please tap to update the calendar event on your mobile device.
            </p>
        );
        btnText = 'Update';
    } else if (notification.notificationType === 'PARTICIPATION_ACCEPTED') {
        cardTitle = (
            <h4 className='notificationTile__actionCard--title'>
                Participation Request Accepted - {data.jobPost.title}
            </h4>
        );
        cardMsg = <p></p>;
        cardActionText = (
            <p className='notificationTile__actionCard--text'>
                Please tap to create the calendar event on your mobile device.
            </p>
        );
        btnText = 'Create';
    }

    return (
        <IonCard className='notificationTile__actionCard'>
            <IonCardHeader>{cardTitle}</IonCardHeader>
            <IonCardContent>
                {cardMsg}
                <br />
                {cardActionText}
                <IonCol
                    sizeXs='12'
                    sizeSm='12'
                    className='notificationTile__actionCard--btn--wrap'
                >
                    <IonButton
                        onClick={() => setShowActionCard(false)}
                        color='ion-primary'
                        fill='clear'
                        className='notificationTile__actionCard--btn--cancel'
                    >
                        No Thanks
                    </IonButton>
                    <IonButton
                        onClick={handleCalendarEvent}
                        color='ion-primary'
                        fill='clear'
                        className='notificationTile__actionCard--btn--update'
                    >
                        {btnText}
                    </IonButton>
                </IonCol>
            </IonCardContent>
        </IonCard>
    );
};
