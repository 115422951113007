// importing modules
import React from 'react';
import { useHistory } from 'react-router-dom';
// import components
import {
    IonContent,
    IonPage,
    IonImg,
    IonGrid,
    IonCol,
    IonRow,
    IonText,
    IonButton,
} from '@ionic/react';
import Strokes from 'components/Strokes';
// importing assets
import yingLogo from 'assets/ying-logo-large-blk.png';
// importing styles
import './Landing.css';

export default () => {
    const history = useHistory();

    return (
        <IonPage>
            <IonContent fullscreen>
                <Strokes green={false} />
                <IonGrid className='authLanding__container'>
                    <IonRow className='authLanding__row'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='authLanding__col'
                        >
                            <IonImg
                                className='authLanding__img'
                                alt='ying-logo'
                                src={yingLogo}
                            />
                            <IonText>
                                <h3 className='authLanding__title'>
                                    Welcome to{' '}
                                    <span className='authLanding__title--span'>
                                        YING
                                    </span>
                                </h3>
                            </IonText>
                            <IonButton
                                onClick={() => history.push('/getStarted')}
                                fill='clear'
                                color='ion-primary'
                                className='authLanding__btn--solid'
                                id='getStarted'
                            >
                                Get Started
                            </IonButton>
                            <IonButton
                                onClick={() => history.push('/login')}
                                fill='clear'
                                color='ion-primary'
                                className='authLanding__btn--transparent'
                                id='landingLoginBtn'
                            >
                                Log In
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};
