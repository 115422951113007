export function yingDebug(contextInfo: string, ...args: any[]) {
    const PREFIX = 'YING :: ';

    let errorHeader = PREFIX + contextInfo;
    console.error(errorHeader);

    for (let i = 1; i < arguments.length; i++) {
        console.error(arguments[i]);
    }
}

export function yingInfo(contextInfo: string, ...args: any[]) {
    const PREFIX = 'YING :: ';

    let infoHeader = PREFIX + contextInfo;
    console.log(infoHeader);

    for (let i = 1; i < arguments.length; i++) {
        console.log(arguments[i]);
    }
}
