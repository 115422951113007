import styled from 'styled-components';
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonPage,
    IonRow,
    IonIcon,
} from '@ionic/react';

// Containers and Wrappers
export const SignUpContainer = styled(IonGrid)`
    align-items: center;
    background: none;
    display: flex;
    justify-content: center;
    flex-direction: column;
`;

export const SignUpWrapper = styled(IonRow)`
    align-items: center;
    background: none;
    display: flex;
    justify-content: center;
    flex-direction: column;
`;

export const SignUpForm = styled(IonRow)`
    background-color: ${({ theme }) => theme.colors.white.regular};
    background: none;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top: 25px;
    width: 100%;
    z-index: 2;

    & .signup__form--wrap {
        background-color: transparent;
        margin: 0;
        padding: 0;
        z-index: 99;
    }
`;

// Icons, Buttons and Screen Effects
export const TopStrokes = styled(IonImg)`
    filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(0deg)
        brightness(0%) contrast(119%);
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
`;

export const BottomStrokes = styled(IonImg)`
    filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(0deg)
        brightness(0%) contrast(119%);
    position: fixed;
    left: 0;
    bottom: 0;
    transform: rotate(180deg);
    z-index: 1;
`;

export const Logo = styled(IonImg)`
    margin-bottom: 50px;
    height: 75px;
    width: 75px;
`;

export const BackArrowBtn = styled(IonButton)`
    position: absolute;
    left: 0%;
    top: 2%;
    color: var(--primary-color-black);
    z-index: 3;
`;

export const BackArrowIcon = styled(IonIcon)`
    font-size: 2rem;
    z-index: 3;
`;

export const SubmitButton = styled(IonButton)`
    background: ${({ theme }) => theme.colors.gradients.v2};
    color: ${({ theme }) => theme.colors.white.regular};
    border-radius: 8px;
    font-family: ${({ theme }) => theme.font_family};
    font-weight: ${({ theme }) => theme.font_weight.semiBold};
    font-size: ${({ theme }) => theme.font_size.small};
    height: 40px;
    margin: 0;
    outline: none;
    text-align: center;
    width: 80%;
`;

// Form
export const Form = styled.form`
    align-items: center;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    padding-bottom: 10px;
    width: 100%;

    & .signup__form--input {
        background-color: ${({ theme }) =>
            theme.colors.zeplin}; /* zeplin input bg-color */
        border: none;
        border-radius: 8px;
        color: ${({ theme }) => theme.colors.black};
        outline: none;
        padding: 12px 16px;
        margin-bottom: 10px;
        width: 100%;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        -ms-border-radius: 8px;
        -o-border-radius: 8px;

        &:focus {
            background-color: ${({ theme }) => theme.colors.gray.inactive};
        }
    }
`;

export const InputWrap = styled.div`
    width: 80%;

    & .signup__form--input--password--wrap {
        position: relative;
    }

    & .signup__form--input--password--icon {
        color: ${({ theme }) => theme.colors.black};
        position: absolute;
        top: 25%;
        right: 5%;
    }

    & .signup__form--input::placeholder {
        color: ${({ theme }) => theme.colors.gray.light};
        font-size: ${({ theme }) => theme.font_size.small};
        font-weight: ${({ theme }) => theme.font_weight.semiBold};
    }

    & .signup__error--col {
        text-align: center;
        margin: 0;
        padding: 15px 0;
    }

    & .signup__form--input--error {
        border: 1px solid ${({ theme }) => theme.colors.red};
    }

    & .signup__error {
        color: ${({ theme }) => theme.colors.red};
        font-size: ${({ theme }) => theme.font_size.xsmall};
        font-weight: ${({ theme }) => theme.font_weight.semiBold};
        margin: 0;
        padding: 0;
    }

    & .signup__error--span {
        color: ${({ theme }) => theme.colors.red};
        font-size: ${({ theme }) => theme.font_size.xsmall};
        font-weight: ${({ theme }) => theme.font_weight.light};
    }

    & .signup__error--underline {
        border-bottom: 1px solid ${({ theme }) => theme.colors.red};
    }
`;

// CopyRight

export const CopyRightRow = styled(IonRow)`
    align-items: center;
    background-color: #fff;
    display: flex;
    justify-content: center;
    /* padding-bottom: 100px; */
    position: relative;
    width: 100%;

    & .signup__copyright--text {
        color: ${({ theme }) => theme.colors.black};
        font-weight: ${({ theme }) => theme.font_weight.light};
        font-size: ${({ theme }) => theme.font_size.xsmall};
    }

    & .signup__copyright--link {
        color: ${({ theme }) => theme.colors.green.light};
        font-weight: ${({ theme }) => theme.font_weight.semiBold};
        border-bottom: solid 1px ${({ theme }) => theme.colors.green.light};
    }
`;

export const CopyRightCol = styled(IonCol)`
    text-align: center;
`;
