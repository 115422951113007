import React from 'react';
import { IonButton, IonCol } from '@ionic/react';
import './ScheduledTaskItemFallback.css';

const ScheduledItemFallback: React.FC<{ isEmpty: boolean }> = ({ isEmpty }) => {
    return (
        <IonCol className='tasksFallback'>
            <h3
                className={
                    isEmpty
                        ? 'tasksFallback__title_empty'
                        : 'tasksFallback__title'
                }
            >
                Schedule a task for this day
            </h3>
            {/* <IonButton fill="clear" className="tasksFallback__btn" color="ion-primary">Give Help Get Help</IonButton> */}
        </IonCol>
    );
};

export default ScheduledItemFallback;
