// importing modules
import React, { useState } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { useParams, useHistory } from 'react-router';
import momentTZ from 'moment-timezone';
import { Calendar } from '@ionic-native/calendar';
import { Capacitor } from '@capacitor/core';
// importing components
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonLabel,
    IonRow,
    IonModal,
} from '@ionic/react';
import Strokes from 'components/Strokes';
import BackButton from 'components/BackButton';
// importing assets
// importing utilities
import { getLocalStorage } from 'helpers/localStorage/localStorageHelper';
// importing types & graphql
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_ONE_JOB_POST_LOCATION } from 'GraphQL/JobPostLocation/UPDATE_ONE_JOB_POST_LOCATION/UPDATE_ONE_JOB_POST_LOCATION';
import {
    UpdateOneJobPostLocation,
    UpdateOneJobPostLocationVariables,
} from 'GraphQL/__generated__/UpdateOneJobPostLocation';
import { PUBLISH_JOB_POST_UPDATED } from 'GraphQL/JobPost/PUBLISH_JOB_POST_UPDATED/PUBLISH_JOB_POST_UPDATED';
import {
    PublishJobPostUpdatedNotification,
    PublishJobPostUpdatedNotificationVariables,
} from 'GraphQL/__generated__/PublishJobPostUpdatedNotification';
import { LocationPreference } from 'GraphQL/__generated__/globalTypes';
// importing styles
import 'components/GooglePlacesDropDown/GooglePlacesDropDown.css';

const SAVED_LOCATIONS = [
    {
        id: 1,
        name: 'VIRTUAL',
        address:
            'Send the skill provider a video conference link in the chat once you complete this request',
    },
];

export default ({
    taskPostId,
    title,
    taskStartDate,
    taskStartTime,
    taskEndTime,
    locationId,
    locationPref,
    locationStr,
    userImg,
    isEditLocationModalActive,
    setIsEditLocationModalActive,
}: {
    userImg: string;
    taskPostId: number;
    title: string;
    taskStartDate: any;
    taskStartTime: any;
    taskEndTime: any;
    locationId: number;
    locationPref: string;
    locationStr: string;
    isEditLocationModalActive: boolean;
    setIsEditLocationModalActive: Function;
}) => {
    const history = useHistory();
    const { taskId } = useParams<{ taskId: string }>();
    const [locationType, setLocationType] = useState<string>(
        locationPref.toString()
    );
    const [taskLocation, setTaskLocation] = useState<string>(locationStr);
    const [address, setAddress] = useState<string>(
        locationStr === 'VIRTUAL' ? '' : locationStr
    );

    const [updateLocation, updateLocationDatas] = useMutation<
        UpdateOneJobPostLocation,
        UpdateOneJobPostLocationVariables
    >(UPDATE_ONE_JOB_POST_LOCATION);

    const [publishUpdateNotification, publishUpdateNotificationDatas] =
        useMutation<
            PublishJobPostUpdatedNotification,
            PublishJobPostUpdatedNotificationVariables
        >(PUBLISH_JOB_POST_UPDATED);

    const handleLocationUpdate = () => {
        updateLocation({
            variables: {
                id: locationId,
                locationString:
                    locationType === 'VIRTUAL' ? 'VIRTUAL' : address,
                locationPreference:
                    locationType === 'VIRTUAL'
                        ? LocationPreference.VIRTUAL
                        : LocationPreference.INPERSON,
            },
        })
            .then((result) => {
                const tStartTime = momentTZ(taskStartTime);
                const tStartDate = tStartTime.tz(getLocalStorage('tz'), false);
                const tEndTime = momentTZ(taskEndTime);
                const tEndDate = tEndTime.tz(getLocalStorage('tz'), false);

                const prevJobData = {
                    title: `YING - ${title}`,
                    location: locationStr,
                    notes: 'No notes available',
                    startTime: taskStartTime.toDate(),
                    endTime: taskEndTime.toDate(),
                    options: {
                        id: taskPostId.toString(),
                        firstReminderMinutes: 5,
                        secondReminderMinutes: 120,
                    },
                };

                publishUpdateNotification({
                    variables: {
                        jobId: parseInt(taskId),
                        prevJobInfoData: JSON.stringify(prevJobData),
                    },
                }).catch((err) => console.error(err));

                // creates an event on the devices native calendar (if calendar permission was granted)
                if (
                    Capacitor.platform !== 'web' &&
                    taskStartDate !== null &&
                    taskStartTime !== null &&
                    taskEndTime !== null
                ) {
                    // if native device run the code inside this block

                    // removes a scheduled event from the devices native calendar
                    Calendar.deleteEvent(
                        `YING - ${title}`,
                        locationStr,
                        'No notes available',
                        tStartDate.toDate(),
                        tEndDate.toDate()
                    ).catch((err) =>
                        console.error('error creating calendar event', err)
                    );

                    // creates a new updated calendar event
                    Calendar.createEventWithOptions(
                        `YING - ${title}`,
                        taskLocation,
                        'No notes available',
                        tStartDate.toDate(),
                        tEndDate.toDate(),
                        {
                            id: taskPostId.toString(),
                            firstReminderMinutes: 5,
                            secondReminderMinutes: 120,
                        }
                    ).catch((err) =>
                        console.error('error creating calendar event', err)
                    );
                }
                history.go(0); // reload page
            })
            .catch((error) => console.error(error));
    };

    const handleAddress = (address) => {
        setAddress(address);
        setTaskLocation(address);
    };

    return (
        <IonModal isOpen={isEditLocationModalActive}>
            <IonContent>
                <IonGrid>
                    <IonRow className='taskLocation__header'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='taskLocation__header--container'
                        >
                            <Strokes top={true} />
                            <IonCol
                                sizeXs='12'
                                sizeSm='12'
                                sizeMd='12'
                                className='completeTaskNav__btns--wrap'
                            >
                                <BackButton color='white' />
                            </IonCol>
                            <IonImg
                                className='createTask__header--img'
                                src={userImg}
                                alt='user'
                            />
                        </IonCol>
                    </IonRow>
                    {/* text content */}
                    <IonRow className='taskLocation__textContent'>
                        <IonCol
                            className='taskLocation__textContent--container'
                            sizeSm='12'
                            sizeXs='12'
                        >
                            <h1 className='taskLocation__textContent--title'>
                                Choose a <span>location</span>
                            </h1>
                            <p className='taskLocation__textContent--text'>
                                If you don’t know, tap “time hasn’t been
                                decided”.
                            </p>
                        </IonCol>
                    </IonRow>
                    {/* task location form */}
                    <IonGrid className='taskLocation__form--container'>
                        <IonRow className='taskLocation__form--row'>
                            <p className='taskLocation__form--row--title'>
                                Your Saved Locations
                            </p>
                            <IonRow className='taskLocation__col--row'>
                                {SAVED_LOCATIONS.map((location) => (
                                    <IonCol
                                        key={location.id}
                                        onClick={() => {
                                            setLocationType(location.name);
                                            setTaskLocation(location.name);
                                        }}
                                        className={
                                            locationType === location.name
                                                ? 'taskLocation__col taskLocation__col--active'
                                                : 'taskLocation__col'
                                        }
                                        sizeXs='12'
                                        sizeSm='12'
                                    >
                                        <IonCol
                                            sizeXs='12'
                                            sizeSm='12'
                                            className={
                                                locationType === location.name
                                                    ? 'taskLocation__content taskLocation__content--active'
                                                    : 'taskLocation__content'
                                            }
                                        >
                                            <p
                                                className={
                                                    locationType ===
                                                    location.name
                                                        ? 'taskLocation__content--place taskLocation__content--place--active'
                                                        : 'taskLocation__content--place'
                                                }
                                            >
                                                {location.name}
                                            </p>
                                            <p
                                                className={
                                                    locationType ===
                                                    location.name
                                                        ? 'taskLocation__content--address taskLocation__content--address--active'
                                                        : 'taskLocation__content--address'
                                                }
                                            >
                                                {location.address}
                                            </p>
                                        </IonCol>
                                    </IonCol>
                                ))}
                            </IonRow>
                            {/* section divider */}
                            <IonRow className='taskLocation__typeDivider'>
                                <IonCol
                                    sizeXs='12'
                                    sizeSm='12'
                                    className='taskLocation__typeDivider--container'
                                >
                                    <p className='taskLocation__typeDivider__text'>
                                        <span className='taskLocation__typeDivider__text--span'>
                                            or
                                        </span>
                                    </p>
                                </IonCol>
                            </IonRow>
                            {/* section divider */}
                            <IonCol
                                className='taskLocation__form--input--wrap'
                                sizeXs='12'
                                sizeSm='12'
                            >
                                <IonLabel className='taskLocation__form--label'>
                                    New Location
                                </IonLabel>
                            </IonCol>
                            <PlacesAutocomplete
                                value={address}
                                onChange={handleAddress}
                                shouldFetchSuggestions={address.length > 3}
                                // onSelect={handleSelect}
                            >
                                {({
                                    getInputProps,
                                    suggestions,
                                    getSuggestionItemProps,
                                    loading,
                                }) => (
                                    <IonCol
                                        className='taskLocation__form--input--wrap'
                                        sizeXs='12'
                                        sizeSm='12'
                                    >
                                        <input
                                            onClick={() => {
                                                setLocationType(
                                                    LocationPreference.INPERSON
                                                );
                                                if (address.length === 0) {
                                                    setTaskLocation('');
                                                } else {
                                                    setTaskLocation(address);
                                                }
                                            }}
                                            className={
                                                suggestions.length > 0
                                                    ? 'taskLocation__form--input taskLocation__form--input--focus'
                                                    : 'taskLocation__form--input'
                                            }
                                            placeholder='Enter address here...'
                                            value={address}
                                            type='text'
                                            {...getInputProps({
                                                placeholder:
                                                    'Enter address here...',
                                                className:
                                                    'location-search-input taskLocation__form--input',
                                            })}
                                        />
                                        {suggestions.length > 0 && (
                                            <div className='autocomplete__dropdown--container'>
                                                {loading && (
                                                    <div>Loading...</div>
                                                )}
                                                {suggestions.map(
                                                    (suggestion, index) => {
                                                        const className =
                                                            'autocomplete__dropdown--item';
                                                        return (
                                                            <div
                                                                {...getSuggestionItemProps(
                                                                    suggestion,
                                                                    {
                                                                        className,
                                                                        key: index,
                                                                    }
                                                                )}
                                                            >
                                                                <p className='autocomplete__dropdown--item--text'>
                                                                    {
                                                                        suggestion.description
                                                                    }
                                                                </p>
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </div>
                                        )}
                                    </IonCol>
                                )}
                            </PlacesAutocomplete>
                        </IonRow>
                    </IonGrid>
                    {/* btn */}
                    <IonRow className='taskLocation__btn--container'>
                        <IonCol
                            className='taskLocation__btn--wrap'
                            sizeXs='12'
                            sizeSm='12'
                        >
                            <IonButton
                                onClick={handleLocationUpdate}
                                fill='clear'
                                disabled={taskLocation.length === 0}
                                color='ion-primary'
                                className='createTask__btn'
                            >
                                Update Location
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonModal>
    );
};
