// importing modules
import React, { useState } from 'react';
import { useAuth } from 'context/firebase/authContext';
import { useHistory } from 'react-router-dom';
// importing components
import {
    IonCol,
    IonIcon,
    IonPage,
    IonRow,
    IonGrid,
    IonButton,
    IonContent,
    IonSearchbar,
    IonHeader,
    IonToolbar,
} from '@ionic/react';
import IncomingSkillRequests from './IncomingSkillRequests/IncomingSkillRequests';
import OutgoingSkillRequests from './OutgoingSkillRequests/OutgoingSkillRequests';
import CompletedIncomingSkillRequests from './CompletedIncomingSkillRequests/CompletedIncomingSkillRequests';
import CompletedOutgoingSkillRequests from './CompletedOutgoingSkillRequests/CompletedOutgoingSkillRequests';
import Loading from 'components/status/Loading';
import NavBar from 'components/NavBar';
// importing assets
import {
    searchOutline,
    closeOutline,
    swapVerticalOutline,
    arrowBackOutline,
} from 'ionicons/icons';
// importing graphql
import { useQuery } from '@apollo/react-hooks';
import { PROFILE_SKILL_REQUESTS } from 'GraphQL/Profile/PROFILE_SKILL_REQUESTS/PROFILE_SKILL_REQUESTS';
import {
    GetProfileSkillRequests,
    GetProfileSkillRequestsVariables,
} from 'GraphQL/__generated__/GetProfileSkillRequests';
//importing styles
import './SkillRequests.css';
import { Status } from 'GraphQL/__generated__/globalTypes';

export default () => {
    const history = useHistory();
    const { currentUser } = useAuth();
    const [searchInput, setSearchInput] = useState<string>('');
    const [activeFilter, setActiveFilter] =
        useState<string>('incomingRequests');

    const { data, loading } = useQuery<
        GetProfileSkillRequests,
        GetProfileSkillRequestsVariables
    >(PROFILE_SKILL_REQUESTS, {
        variables: { subId: currentUser.uid },
        fetchPolicy: 'no-cache',
    });

    if (loading) {
        return <Loading />;
    }

    let activeList;
    let title;
    let skillRequests: any;
    let filteredSkillRequests: any;

    // logic for filtering items to render
    if (activeFilter === 'incomingRequests') {
        title = (
            <h1 className='skillRequests__title'>Incoming Skill Requests</h1>
        );

        skillRequests = data.profile.upcomingJobsServe.filter(
            (pr) =>
                pr.Status === Status.SCHEDULED ||
                pr.Status === Status.RECIPIENT_HAS_PROPOSED_OFFER ||
                Status.REQUESTED
        );
        filteredSkillRequests = skillRequests.filter((skillReq) => {
            return skillReq.Skill.name
                .toLowerCase()
                .includes(searchInput.toLowerCase());
        });

        // render active filtered list
        if (searchInput.length > 0 && filteredSkillRequests.length > 0) {
            activeList = (
                <IncomingSkillRequests skillRequests={filteredSkillRequests} />
            );
        } else {
            activeList = (
                <IncomingSkillRequests skillRequests={skillRequests} />
            );
        }
    } else if (activeFilter === 'outgoingRequests') {
        title = (
            <h1 className='skillRequests__title'>Outgoing Skill Requests</h1>
        );

        skillRequests = data.profile.upcomingJobsReceive.filter(
            (pr) =>
                pr.Status === Status.SCHEDULED ||
                pr.Status === Status.RECIPIENT_HAS_PROPOSED_OFFER
        );
        filteredSkillRequests = skillRequests.filter((skillReq) => {
            return skillReq.Skill.name
                .toLowerCase()
                .includes(searchInput.toLowerCase());
        });

        // render active filtered list
        if (searchInput.length > 0 && filteredSkillRequests.length > 0) {
            activeList = (
                <OutgoingSkillRequests skillRequests={filteredSkillRequests} />
            );
        } else {
            activeList = (
                <OutgoingSkillRequests skillRequests={skillRequests} />
            );
        }
    } else if (activeFilter === 'completedIncomingRequests') {
        title = (
            <h1 className='skillRequests__title'>
                Completed Incoming Skill Requests
            </h1>
        );

        skillRequests = data.profile.upcomingJobsServe.filter(
            (pr) => pr.Status === Status.COMPLETED
        );
        filteredSkillRequests = skillRequests.filter((skillReq) => {
            return skillReq.Skill.name
                .toLowerCase()
                .includes(searchInput.toLowerCase());
        });

        skillRequests = data.profile.upcomingJobsServe.filter(
            (pr) => pr.Status === Status.COMPLETED
        );
        filteredSkillRequests = skillRequests.filter((skillReq) => {
            return skillReq.Skill.name
                .toLowerCase()
                .includes(searchInput.toLowerCase());
        });

        // render active filtered list
        if (searchInput.length > 0 && filteredSkillRequests.length > 0) {
            activeList = (
                <CompletedIncomingSkillRequests
                    completedSkillRequests={filteredSkillRequests}
                />
            );
        } else {
            activeList = (
                <CompletedIncomingSkillRequests
                    completedSkillRequests={skillRequests}
                />
            );
        }
    } else if (activeFilter === 'completedOutgoingRequests') {
        title = (
            <h1 className='skillRequests__title'>
                Completed Outgoing Skill Requests
            </h1>
        );

        skillRequests = data.profile.upcomingJobsReceive.filter(
            (pr) => pr.Status === Status.COMPLETED
        );
        filteredSkillRequests = skillRequests.filter((skillReq) => {
            return skillReq.Skill.name
                .toLowerCase()
                .includes(searchInput.toLowerCase());
        });

        skillRequests = data.profile.upcomingJobsReceive.filter(
            (pr) => pr.Status === Status.COMPLETED
        );
        filteredSkillRequests = skillRequests.filter((skillReq) => {
            return skillReq.Skill.name
                .toLowerCase()
                .includes(searchInput.toLowerCase());
        });

        // render active filtered list
        if (searchInput.length > 0 && filteredSkillRequests.length > 0) {
            activeList = (
                <CompletedOutgoingSkillRequests
                    completedSkillRequests={filteredSkillRequests}
                />
            );
        } else {
            activeList = (
                <CompletedOutgoingSkillRequests
                    completedSkillRequests={skillRequests}
                />
            );
        }
    }

    return (
        <IonPage className='skillRequests__page'>
            <IonHeader className='skillRequests-header'>
                <IonToolbar className='skillRequests-toolbar'>
                    <IonSearchbar
                        className='skillRequests-ionSearchBar'
                        search-icon={searchOutline}
                        clear-icon={closeOutline}
                        placeholder='Search by name or skill'
                        value={searchInput}
                        onIonChange={(event) =>
                            setSearchInput(event.detail.value)
                        }
                    >
                        <IonIcon
                            onClick={() => history.goBack()}
                            className='skillRequests__close--btn'
                            icon={arrowBackOutline}
                        />
                    </IonSearchbar>
                    <IonCol
                        sizeSm='12'
                        sizeXs='12'
                        className='skillRequests__filters--container'
                    >
                        <div className='skillRequests__filters--wrap'>
                            <IonButton
                                onClick={() =>
                                    setActiveFilter('incomingRequests')
                                }
                                className={
                                    activeFilter === 'incomingRequests'
                                        ? 'skillRequests__filters--btn--active'
                                        : 'skillRequests__filters--btn'
                                }
                                color='ion-primary'
                                fill='clear'
                            >
                                Scheduled Incoming Skill Requests
                            </IonButton>
                            <IonButton
                                onClick={() =>
                                    setActiveFilter('outgoingRequests')
                                }
                                className={
                                    activeFilter === 'outgoingRequests'
                                        ? 'skillRequests__filters--btn--active'
                                        : 'skillRequests__filters--btn'
                                }
                                color='ion-primary'
                                fill='clear'
                            >
                                Scheduled Outgoing Skill Requests
                            </IonButton>
                            <IonButton
                                onClick={() =>
                                    setActiveFilter('completedIncomingRequests')
                                }
                                className={
                                    activeFilter === 'completedIncomingRequests'
                                        ? 'skillRequests__filters--btn--active'
                                        : 'skillRequests__filters--btn'
                                }
                                color='ion-primary'
                                fill='clear'
                            >
                                Completed Incoming Skill Requests
                            </IonButton>
                            <IonButton
                                onClick={() =>
                                    setActiveFilter('completedOutgoingRequests')
                                }
                                className={
                                    activeFilter === 'completedOutgoingRequests'
                                        ? 'skillRequests__filters--btn--active'
                                        : 'skillRequests__filters--btn'
                                }
                                color='ion-primary'
                                fill='clear'
                            >
                                Completed Outgoing Skill Requests
                            </IonButton>
                        </div>
                    </IonCol>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonGrid className='skillRequests__container'>
                    {/* <IonRow className='skillRequests__searchbar'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='skillRequests__searchbar--container'
                        >
                            <div className='skillRequests__searchbar--input--wrap'>
                                <IonIcon
                                    className='skillRequests__searchbar--input--icon'
                                    icon={searchOutline}
                                />
                                <input
                                    onChange={(event) =>
                                        setSearchInput(event.target.value)
                                    }
                                    value={searchInput}
                                    type='text'
                                    placeholder='Search by name or skill'
                                    className='skillRequests__searchbar--input'
                                />
                            </div>
                            <IonButton
                                className='skillRequests__close--btn'
                                onClick={() => {
                                    setSearchInput('');
                                }}
                                color='ion-primary'
                            >
                                <IonIcon
                                    icon={closeOutline}
                                />
                            </IonButton>
                        </IonCol>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className="skillRequests__filters--container"
                        >
                            <div className="skillRequests__filters--wrap">
                                <IonButton 
                                    onClick={() => setActiveFilter('incomingRequests')}
                                    className={
                                        activeFilter === 'incomingRequests' 
                                        ? 'skillRequests__filters--btn--active' 
                                        : 'skillRequests__filters--btn'
                                    }
                                    color="ion-primary" 
                                    fill="clear"
                                >
                                    Scheduled Incoming Skill Requests
                                </IonButton>
                                <IonButton
                                    onClick={() => setActiveFilter('outgoingRequests')}
                                    className={
                                        activeFilter === 'outgoingRequests' 
                                        ? 'skillRequests__filters--btn--active' 
                                        : 'skillRequests__filters--btn'
                                    }
                                    color="ion-primary" 
                                    fill="clear"
                                >
                                    Scheduled Outgoing Skill Requests
                                </IonButton>
                                <IonButton 
                                    onClick={() => setActiveFilter('completedIncomingRequests')}
                                    className={
                                        activeFilter === 'completedIncomingRequests' 
                                        ? 'skillRequests__filters--btn--active' 
                                        : 'skillRequests__filters--btn'
                                    }
                                    color="ion-primary" 
                                    fill="clear"
                                >
                                    Completed Incoming Skill Requests
                                </IonButton>
                                <IonButton
                                    onClick={() => setActiveFilter('completedOutgoingRequests')}
                                    className={
                                        activeFilter === 'completedOutgoingRequests' 
                                        ? 'skillRequests__filters--btn--active' 
                                        : 'skillRequests__filters--btn'
                                    }
                                    color="ion-primary" 
                                    fill="clear"
                                >
                                    Completed Outgoing Skill Requests
                                </IonButton>
                            </div>
                        </IonCol>
                    </IonRow> */}
                    <IonRow className='tasksPostedItem__row'>
                        <IonCol
                            className='skillRequests__title--wrap'
                            sizeSm='12'
                            sizeXs='12'
                        >
                            {title}
                            <IonButton
                                color='ion-primary'
                                className='skillRequests__btn'
                                fill='clear'
                            >
                                <IonIcon
                                    className='skillRequests__btn--icon'
                                    icon={swapVerticalOutline}
                                />
                                Sort
                            </IonButton>
                        </IonCol>
                        {activeList}
                    </IonRow>
                </IonGrid>
                <NavBar />
            </IonContent>
        </IonPage>
    );
};
