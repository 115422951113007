// importing modules
import React, { useState, useEffect } from 'react';
// imoporting components
import { IonCol, IonImg, IonIcon } from '@ionic/react';
import { star } from 'ionicons/icons';
// importing helpers
import {
    getLocalStorage,
    updateLocalStorage,
} from '../../../../helpers/localStorage/localStorageHelper';

const ParticipantReviewItem: React.FC<{
    id: number;
    firstName: string;
    lastName: string;
    profilePicture: string;
    review: string;
    rating: number;
    skill: string;
}> = ({ id, firstName, lastName, profilePicture, rating, review, skill }) => {
    const [rt, setRt] = useState<number>(rating);
    const [userReview, setReview] = useState<string>(
        review.length > 0 ? review : ''
    );

    const handleParticipantReview = () => {
        const currentParticipants = getLocalStorage(
            'completeTaskPostedViaRecipient'
        ).participants;
        const updatedReviews = currentParticipants.map((p) =>
            p.id === id ? { ...p, review: userReview } : p
        );

        updateLocalStorage(
            'completeTaskPostedViaRecipient',
            'participants',
            updatedReviews
        );
    };

    return (
        <IonCol className='taskPartcItem' sizeSm='12' sizeXs='12'>
            <IonCol sizeXs='12' sizeSm='12' className='taskPartcItem__review'>
                <IonCol sizeXl='3' sizeLg='3' sizeMd='3' sizeXs='3' sizeSm='3'>
                    <div className='taskPartcItem__img--wrap'>
                        <IonImg
                            className='taskPartcItem__img'
                            src={profilePicture}
                        />
                    </div>
                </IonCol>
                <IonCol
                    className='taskPartcItem__info--wrap'
                    sizeXs='9'
                    sizeSm='9'
                >
                    <h3 className='taskPartcItem__info--name'>
                        {firstName} {lastName}
                    </h3>
                    <p className='taskPartcItem__info--cred'>{skill}</p>
                    <IonCol className='taskPartcStars__container'>
                        {[...Array(5)].map((s, i) => {
                            const ratingVal = i + 1;

                            return (
                                <label key={i}>
                                    <input
                                        type='radio'
                                        name='rating'
                                        value={ratingVal}
                                    />
                                    <IonIcon
                                        style={{
                                            color:
                                                ratingVal <= rt
                                                    ? '#f7b500'
                                                    : '#d3d3d3',
                                        }}
                                        icon={star}
                                        className='taskPartcStars__icon'
                                    />
                                </label>
                            );
                        })}
                    </IonCol>
                </IonCol>
            </IonCol>
            <IonCol
                className='taskPartcReview__textarea--wrap'
                sizeXs='12'
                sizeSm='12'
            >
                <textarea
                    onChange={(e) => {
                        setReview(e.target.value);
                        handleParticipantReview();
                    }}
                    onFocus={() =>
                        console.log(
                            `textarea for participant -> ${id} is focused`
                        )
                    }
                    onBlur={handleParticipantReview}
                    className='taskPartcReview__textarea'
                    placeholder='Say something nice..'
                    required
                    value={userReview}
                ></textarea>
            </IonCol>
        </IonCol>
    );
};

export default ParticipantReviewItem;
