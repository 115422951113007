// importing modules
import React, { ReactElement, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useAuth } from 'context/firebase/authContext';
import { Plugins } from '@capacitor/core';
import Geocode from 'react-geocode';
import moment from 'moment-timezone';
import Linkify from 'react-linkify';
// importing components
import {
    IonCol,
    IonContent,
    IonGrid,
    IonIcon,
    IonImg,
    IonRow,
    IonPage,
    IonToast,
} from '@ionic/react';
import GoogleMap from '../../components/GoogleMap/PublicMap/Map';
import PrivateGoogleMap from '../../components/GoogleMap/PrivateMap/Map';
import Loading from '../../components/status/Loading';
import Strokes from 'components/Strokes';
import BackButton from 'components/BackButton';
// importing assets
import { videocam, copy } from 'ionicons/icons';
// importing utilites
import limitStr from '../../utils/limitString';
import getLocationPreferenceStr from '../../utils/getLocationPreferenceStr';
import { getLocalStorage } from '../../helpers/localStorage/localStorageHelper';
// importing graphql
import { useQuery } from '@apollo/react-hooks';
import { GET_JOB_POST_VIA_PR } from '../../GraphQL/JobPost/GET_JOB_POST_VIA_PARTICIPATION_REQUEST_ID/GET_JOB_POST_VIA_PARTICIPATION_REQUEST_ID';
import {
    GetJobPostViaPR,
    GetJobPostViaPRVariables,
    GetJobPostViaPR_participationRequest_skill_jobPost_skillsNeeded_participationRequests_provider,
} from '../../GraphQL/__generated__/GetJobPostViaPR';
import {
    LocationPreference,
    Status,
} from '../../GraphQL/__generated__/globalTypes';
// importing styles
import './TaskPostedDetails.css';

Geocode.setApiKey('AIzaSyD7B3yKTqxrAYt-HWajE5mGW8z2k2Z2TyQ');

const TaskPostedDetails: React.FC = () => {
    const history = useHistory();
    const { currentUser } = useAuth();
    const { id } = useParams<{ id: string }>();
    const [showToast, setShowToast] = useState(false);
    const [location, setLocation] = useState({ address: '', lat: 0, lng: 0 });
    const [getLocationOnce, setGetLocationOnce] = useState<boolean>(true);

    const { data, loading } = useQuery<
        GetJobPostViaPR,
        GetJobPostViaPRVariables
    >(GET_JOB_POST_VIA_PR, {
        variables: { id: parseInt(id) },
        fetchPolicy: 'cache-and-network',
    });

    const handleCopyToClip = (str: string) => {
        const { Clipboard } = Plugins; // get GeoLocation plugin from Plugins
        Clipboard.write({ string: str });
        setShowToast(true);
    };

    if (loading) {
        return <Loading />;
    } else if (!loading && getLocationOnce) {
        if (
            data.participationRequest.skill.jobPost.location.locationString
                .length > 0 &&
            data.participationRequest.skill.jobPost.location
                .locationPreference !== LocationPreference.VIRTUAL
        ) {
            Geocode.fromAddress(
                data.participationRequest.skill.jobPost.location.locationString
            ) // get the tasks current location
                .then((position) => {
                    setLocation({
                        address: position.results[0].formatted_address, // formatted google address
                        lat: position.results[0].geometry.location.lat, // location -> lat
                        lng: position.results[0].geometry.location.lng, // location -> lng
                    });
                    setGetLocationOnce(false);
                })
                .catch((err) => console.error(err));
        }
    }

    const participants: GetJobPostViaPR_participationRequest_skill_jobPost_skillsNeeded_participationRequests_provider[] =
        [];
    data.participationRequest.skill.jobPost.skillsNeeded.forEach((skill) => {
        skill.participationRequests.forEach((request) => {
            if (
                request.status === Status.ACCEPTED ||
                request.status === Status.RECIPIENT_HAS_PROPOSED_OFFER ||
                request.status === Status.COMPLETED
            ) {
                participants.push(request.provider);
            }
        });
    });

    const locationStruct = {
        ...location,
        locationPreference:
            data.participationRequest.skill.jobPost.location.locationPreference,
        status: data.participationRequest.skill.jobPost.status,
    };
    let locationText: string;
    let locationDetail: ReactElement;

    if (
        data.participationRequest.skill.jobPost.location.locationPreference ===
        LocationPreference.VIRTUAL
    ) {
        locationDetail = (
            <div className='requestDetails__icon--container'>
                <div className='requestDetails__icon--wrap'>
                    <IonIcon className='requestDetails__icon' icon={videocam} />
                </div>
            </div>
        );
        locationText =
            'Send the skill provider a video conference link in the chat once you complete this request';
    } else if (
        (data.participationRequest.skill.jobPost.location.locationPreference ===
            LocationPreference.INPERSON &&
            data.participationRequest.skill.jobPost.recipient.uid ===
                currentUser.uid) ||
        (data.participationRequest.skill.jobPost.location.locationPreference ===
            LocationPreference.INPERSON &&
            participants.find((p) => p.uid === currentUser.uid))
    ) {
        locationDetail = (
            <div className='requestDetails__map--container'>
                <GoogleMap {...locationStruct} />
            </div>
        );
        locationText =
            data.participationRequest.skill.jobPost.location.locationString;
    } else if (
        data.participationRequest.skill.jobPost.location.locationPreference ===
        LocationPreference.INPERSON
    ) {
        locationDetail = (
            <div className='requestDetails__map--container'>
                <PrivateGoogleMap {...locationStruct} />
            </div>
        );
        locationText =
            'Exact address will be shown once the participation request has been accepted.';
    }

    let scheduledDetails;
    const startDate = moment(data.participationRequest.skill.jobPost.startDate)
        .tz(getLocalStorage('tz'), false)
        .format('MMM Do');
    const startTime = moment(data.participationRequest.skill.jobPost.startTime)
        .tz(getLocalStorage('tz'), false)
        .format('h:mma');
    const endTime = moment(data.participationRequest.skill.jobPost.endTime)
        .tz(getLocalStorage('tz'), false)
        .format('h:mma');

    if (
        data.participationRequest.skill.jobPost.startDate === null &&
        data.participationRequest.skill.jobPost.startTime === null
    ) {
        scheduledDetails = (
            <p className='requestDetails__header--desc--taskDescr'>
                <span className='requestDetails__header--desc--taskDescr--span'>
                    Scheduled for
                </span>
                <br />
                TBD - @TBD
            </p>
        );
    } else if (
        data.participationRequest.skill.jobPost.startDate !== null &&
        data.participationRequest.skill.jobPost.startTime === null
    ) {
        scheduledDetails = (
            <p className='requestDetails__header--desc--taskDescr'>
                <span className='requestDetails__header--desc--taskDescr--span'>
                    Scheduled for
                </span>
                <br />
                {startDate} @TBD
            </p>
        );
    } else if (
        data.participationRequest.skill.jobPost.startDate === null &&
        data.participationRequest.skill.jobPost.startTime !== null
    ) {
        scheduledDetails = (
            <p className='requestDetails__header--desc--taskDescr'>
                <span className='requestDetails__header--desc--taskDescr--span'>
                    Scheduled for
                </span>
                <br />
                TBD @{startTime} - {endTime}
            </p>
        );
    } else if (
        data.participationRequest.skill.jobPost.startDate !== null &&
        data.participationRequest.skill.jobPost.startTime !== null
    ) {
        scheduledDetails = (
            <p className='requestDetails__header--desc--taskDescr'>
                <span className='requestDetails__header--desc--taskDescr--span'>
                    Scheduled for
                </span>
                <br />
                {startDate} @{startTime} - {endTime}
            </p>
        );
    }

    return (
        <IonPage>
            <IonContent>
                <IonGrid>
                    <IonToast
                        cssClass='requestDetails__location--clipboard--toast'
                        isOpen={showToast}
                        onDidDismiss={() => setShowToast(false)}
                        message='Address Copied to Clipboard!'
                        duration={1000}
                    />
                    {/* taskDetails header */}
                    <IonRow className='tpDetails__header'>
                        <Strokes top={true} />
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='tpDetails__header--container'
                        >
                            <BackButton color='white' />
                            <IonCol
                                sizeSm='12'
                                sizeXs='12'
                                className='requestDetails__header--desc--wrap'
                            >
                                <h1 className='tpDetails__header--desc--taskName'>
                                    {
                                        data.participationRequest.skill.jobPost
                                            .title
                                    }
                                </h1>
                                <p className='requestDetails__header--desc--taskDescr'>
                                    <span className='requestDetails__header--desc--taskDescr--span'>
                                        Description
                                    </span>
                                    <br />
                                    {data.participationRequest.skill.jobPost
                                        .description.length > 0 ? (
                                        <Linkify>
                                            {
                                                data.participationRequest.skill
                                                    .jobPost.description
                                            }
                                        </Linkify>
                                    ) : (
                                        'No description available for this job post'
                                    )}
                                </p>
                                {scheduledDetails}
                            </IonCol>
                            <IonCol
                                sizeSm='12'
                                sizeXs='12'
                                className='tpDetails__header--img--wrap'
                            >
                                <IonImg
                                    className='tpDetails__header--descr--img'
                                    src={
                                        data.participationRequest.skill.jobPost
                                            .recipient.profilePicture
                                    }
                                    alt='user'
                                />
                                <p className='tpDetails__header--desc--user'>
                                    Posted By{' '}
                                    {
                                        data.participationRequest.skill.jobPost
                                            .recipient.firstName
                                    }{' '}
                                    {
                                        data.participationRequest.skill.jobPost
                                            .recipient.lastName
                                    }
                                </p>
                            </IonCol>
                        </IonCol>
                    </IonRow>

                    {/* taskDetails skills needed */}
                    <IonRow className='tpDetails__skills'>
                        <IonCol
                            className='tpDetails__skills--container'
                            sizeSm='12'
                            sizeXs='12'
                        >
                            <h1 className='tpDetails__skills--title'>
                                What's Needed
                            </h1>
                        </IonCol>
                        <IonGrid className='tpSkills__container'>
                            <IonRow className='tpSkills__row'>
                                {data.participationRequest.skill.jobPost.skillsNeeded.map(
                                    (skill) => {
                                        const isCurrentUsersPost =
                                            data.participationRequest.skill
                                                .jobPost.recipient.uid ===
                                            currentUser.uid;
                                        const participationRequest =
                                            skill.participationRequests.find(
                                                (pr) =>
                                                    pr.provider.uid ===
                                                    currentUser.uid
                                            );
                                        const skillParticipants =
                                            skill.participationRequests.filter(
                                                (p) =>
                                                    p.status ===
                                                        Status.ACCEPTED ||
                                                    p.status ===
                                                        Status.RECIPIENT_HAS_PROPOSED_OFFER ||
                                                    p.status ===
                                                        Status.COMPLETED
                                            );
                                        let participateBtn: ReactElement;

                                        if (
                                            participationRequest &&
                                            participationRequest.status ===
                                                Status.ACCEPTED
                                        ) {
                                            participateBtn = (
                                                <IonCol
                                                    sizeXs='3'
                                                    sizeSm='3'
                                                    className='tpSkills__request--btn'
                                                >
                                                    <p className='tpSkills__btn--text--accepted'>
                                                        Accepted
                                                    </p>
                                                </IonCol>
                                            );
                                        } else if (
                                            participationRequest &&
                                            participationRequest.status ===
                                                Status.REQUESTED
                                        ) {
                                            participateBtn = (
                                                <IonCol
                                                    sizeXs='3'
                                                    sizeSm='3'
                                                    className='tpSkills__request--btn'
                                                >
                                                    <p className='tpSkills__btn--text--warning'>
                                                        Cancel
                                                        <br />
                                                        Request
                                                    </p>
                                                </IonCol>
                                            );
                                        } else if (
                                            !participationRequest &&
                                            skillParticipants.length ===
                                                skill.peopleNeededCount
                                        ) {
                                            participateBtn = (
                                                <IonCol
                                                    sizeXs='3'
                                                    sizeSm='3'
                                                    className='tpSkills__request--btn'
                                                >
                                                    <p className='tpSkills__btn--text--warning'>
                                                        Full
                                                    </p>
                                                </IonCol>
                                            );
                                        } else if (
                                            participationRequest &&
                                            participationRequest.status ===
                                                Status.RECIPIENT_HAS_PROPOSED_OFFER
                                        ) {
                                            participateBtn = (
                                                <IonCol
                                                    sizeXs='3'
                                                    sizeSm='3'
                                                    className='tpSkills__request--btn'
                                                >
                                                    <p className='tpSkills__btn--text'>
                                                        Credits Proposed
                                                    </p>
                                                </IonCol>
                                            );
                                        } else if (
                                            participationRequest &&
                                            participationRequest.status ===
                                                Status.COMPLETED
                                        ) {
                                            participateBtn = (
                                                <IonCol
                                                    sizeXs='3'
                                                    sizeSm='3'
                                                    className='tpSkills__request--btn'
                                                >
                                                    <p className='tpSkills__btn--text'>
                                                        Completed
                                                    </p>
                                                </IonCol>
                                            );
                                        } else {
                                            participateBtn = (
                                                <IonCol
                                                    sizeXs='3'
                                                    sizeSm='3'
                                                    className='tpSkills__request--btn'
                                                    onClick={() =>
                                                        history.push(
                                                            `/request/${skill.id}/participation`
                                                        )
                                                    }
                                                >
                                                    <p className='tpSkills__btn--text'>
                                                        Participate
                                                    </p>
                                                </IonCol>
                                            );
                                        }
                                        return (
                                            <IonCol
                                                key={skill.id}
                                                className='tpSkills__col'
                                                sizeXs='12'
                                                sizeSm='12'
                                            >
                                                {isCurrentUsersPost ? (
                                                    <>
                                                        <IonCol
                                                            sizeXs='9'
                                                            sizeSm='9'
                                                            className='ion-padding tpSkills__content'
                                                        >
                                                            <p className='tpSkills__content--skill'>
                                                                {skill.name}
                                                            </p>
                                                            {/* <p className="tpSkills__content--completed">Worth 2 Balance Tokens</p> */}
                                                            <p className='tpSkills__content--completed'>
                                                                {
                                                                    skillParticipants.length
                                                                }
                                                                /
                                                                {
                                                                    skill.peopleNeededCount
                                                                }{' '}
                                                                roles filled
                                                            </p>
                                                            <p className='tpSkills__content--text'>
                                                                {
                                                                    skill.additionalDetails
                                                                }
                                                            </p>
                                                        </IonCol>
                                                        <IonCol
                                                            sizeXs='3'
                                                            sizeSm='3'
                                                            className='tpSkills__request--btn'
                                                        >
                                                            <p className='tpSkills__btn--text--disabled'>
                                                                Participate
                                                            </p>
                                                        </IonCol>
                                                    </>
                                                ) : (
                                                    <>
                                                        <IonCol
                                                            sizeXs='9'
                                                            sizeSm='9'
                                                            className='ion-padding tpSkills__content'
                                                        >
                                                            <p className='tpSkills__content--skill'>
                                                                {skill.name}
                                                            </p>
                                                            {/* <p className="tpSkills__content--completed">Worth 2 Balance Tokens</p> */}
                                                            <p className='tpSkills__content--completed'>
                                                                {
                                                                    skillParticipants.length
                                                                }
                                                                /
                                                                {
                                                                    skill.peopleNeededCount
                                                                }{' '}
                                                                roles filled
                                                            </p>
                                                            <p className='tpSkills__content--text'>
                                                                {
                                                                    skill.additionalDetails
                                                                }
                                                            </p>
                                                        </IonCol>
                                                        {participateBtn}
                                                    </>
                                                )}
                                            </IonCol>
                                        );
                                    }
                                )}
                            </IonRow>
                        </IonGrid>
                    </IonRow>
                    <IonGrid className='tpDetails__users--container'>
                        <IonRow className='tpDetails__users'>
                            <IonCol sizeSm='12' sizeXs='12'>
                                <h1 className='tpDetails__skills--title'>
                                    Current Participants
                                </h1>
                            </IonCol>
                            {participants.length > 0 ? (
                                participants.map((participant) => (
                                    <div
                                        key={participant.id}
                                        className='tpDetails__user'
                                    >
                                        <button
                                            style={{
                                                backgroundColor: 'transparent',
                                            }}
                                            onClick={() =>
                                                history.push(
                                                    `/user/${participant.uid}`
                                                )
                                            }
                                        >
                                            <IonImg
                                                className='tpDetails__user--img'
                                                src={participant.profilePicture}
                                                alt='user'
                                            />
                                        </button>
                                        <p className='tpDetails__user--name'>
                                            {limitStr(
                                                participant.firstName,
                                                11
                                            )}
                                        </p>
                                    </div>
                                ))
                            ) : (
                                <IonCol className='tasksFallback'>
                                    <h3 className='tasksFallback__title'>
                                        This job post currently has no
                                        participants
                                    </h3>
                                </IonCol>
                            )}
                        </IonRow>
                    </IonGrid>
                    <IonRow className='requestDetails__location'>
                        <IonCol sizeSm='12' sizeXs='12'>
                            <h1 className='requestDetails__skills--title'>
                                Location
                            </h1>
                        </IonCol>
                    </IonRow>
                    {locationDetail}

                    <IonRow className='requestDetails__location'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='requestDetails__location--wrap'
                        >
                            {data.participationRequest.skill.jobPost.location
                                .locationPreference !==
                                LocationPreference.VIRTUAL && (
                                <IonIcon
                                    className='requestDetails__location--clipboard'
                                    icon={copy}
                                    onClick={() =>
                                        handleCopyToClip(locationText)
                                    }
                                />
                            )}
                            <h4 className='requestDetails__location--title'>
                                {getLocationPreferenceStr(
                                    data.participationRequest.skill.jobPost
                                        .location.locationPreference
                                )}
                            </h4>
                            <p className='requestDetails__location--text'>
                                {locationText}
                            </p>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default TaskPostedDetails;
