// importing modules
import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { useHistory } from 'react-router-dom';
import GratitudePot from '../../assets/gratitude-pot.png';
import { useAuth } from 'context/firebase/authContext';
// importing components
import {
    IonButton,
    IonContent,
    IonGrid,
    IonImg,
    IonModal,
    IonRow,
    IonToast,
} from '@ionic/react';
import UserImg from '../../components/UserImg/UserImg';
// importing utilities
// importing assets
// importing graphql & types
import { useLazyQuery } from '@apollo/react-hooks';
import { CreateStripeAccount as CSAQuery } from '../../GraphQL/StripeConnect/StripeConnect';
import './StripeOnboarding.css';
import { Plugins } from '@capacitor/core';

const { Browser } = Plugins;

interface Props {
    showModal: boolean;
    setModal: Function;
}

const StripeOnboarding: React.FC<Props> = ({ showModal, setModal }) => {
    const history = useHistory();
    const [showToast, setShowToast] = useState(false);
    const timezonesList = moment.tz.names();
    const [getSignUpUrl, { data: signupData, refetch }] =
        useLazyQuery(CSAQuery);
    const [isDisabled, setisDisabled] = useState(false);
    const { currentUser } = useAuth();

    // create an external onboarding url on stripe for the user
    // conditional so that if stale data exists, refetch. (onboarding url expires)
    // this function indirectly redirects the user to stripe through a use effect
    const redirectToStripe = async () => {
        setisDisabled(true);
        if (signupData) {
            refetch();
        } else {
            getSignUpUrl({
                variables: {
                    fetchPolicy: 'no-cache',
                    uid: currentUser.uid,
                },
            });
        }
    };

    // open browser only when the onboarding url is initially created
    // and when it is refetched
    useEffect(() => {
        if (signupData) {
            Browser.open({ url: signupData.createStripeAccount.onboardingURL });
            setisDisabled(false);
            setModal(false);
        }
    }, [signupData]);

    return (
        <IonModal isOpen={showModal}>
            <IonContent>
                <IonGrid className='stripe_onboarding_grid'>
                    <IonToast
                        cssClass='requestDetails__location--clipboard--toast'
                        isOpen={showToast}
                        onDidDismiss={() => setShowToast(false)}
                        message='Profile Settings Updated!'
                        duration={1000}
                    />
                    <IonRow class='stripe_onboarding_userimg--container'>
                        <UserImg />
                    </IonRow>
                    <IonRow className='gratitude_pot_header--container'>
                        <h1 className='gratitude_pot_header'>
                            Activate <span>Gratitude Pot</span>
                        </h1>
                    </IonRow>
                    <IonRow className='gratitude_pot_text'>
                        <p>
                            Add a payment method to send and receive tips after
                            tasks are completed
                        </p>
                    </IonRow>

                    <IonRow className='gratitude_pot_img--container'>
                        <IonImg
                            className='gratitude_pot_img'
                            src={GratitudePot}
                        />
                    </IonRow>
                    <IonRow className='gratitude_pot_text'>
                        <p>
                            We use Stripe to make sure you get paid on time and
                            to keep your personal bank and details secure. Click{' '}
                            <span> Activate</span> to set up your payments on
                            Stripe.
                        </p>
                    </IonRow>
                    <IonRow className='stipe_activate_btn--container'>
                        <IonButton
                            onClick={redirectToStripe}
                            fill='clear'
                            style={{ color: '#fff' }}
                            disabled={isDisabled}
                            className='stipe_activate_btn'
                        >
                            Activate
                        </IonButton>
                    </IonRow>
                    <IonRow
                        onClick={() => setModal(false)}
                        className='stripe_skip_btn--container'
                    >
                        <IonButton
                            fill='clear'
                            className='stripe_skip_btn--container'
                        >
                            Skip for now
                        </IonButton>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonModal>
    );
};

export default StripeOnboarding;
