// importing modules
import React, { useEffect } from 'react';
import { useParams, useHistory, Redirect } from 'react-router-dom';
// importing components
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonLabel,
    IonPage,
    IonRow,
} from '@ionic/react';
import CompleteTaskNavTabs from '../UI/CompleteTaskNavTabs/CompleteTaskNavTabs';
import Loading from '../../../components/status/Loading';
import Strokes from 'components/Strokes';
// importing assets
// importing helpers
import time_credit_conversion from '../../../utils/timeConversion';
import {
    setLocalStorage,
    getLocalStorage,
    updateLocalStorage,
} from '../../../helpers/localStorage/localStorageHelper';
// importing graphql
import { useQuery } from '@apollo/react-hooks';
import { GET_PARTICIPATION_REQUEST_BY_ID } from '../../../GraphQL/ParticipationRequest/GET_PARTICIPATION_REQUEST_BY_ID/GET_PARTICIPATION_REQUEST_BY_ID';
import {
    GetParticipationRequestById,
    GetParticipationRequestByIdVariables,
} from '../../../GraphQL/__generated__/GetParticipationRequestById';
// importing styles
import './CompleteTask.css';
import { useAuth } from '../../../context/firebase/authContext';

const CompleteTask: React.FC = () => {
    const { currentUser } = useAuth();
    const history = useHistory();
    const { taskId } = useParams<{ taskId: string }>();

    useEffect(() => {
        if (!getLocalStorage('completeTaskPostedViaProvider')) {
            setLocalStorage('completeTaskPostedViaProvider', {
                hrs: 0,
                mins: 0,
                review: '',
                rating: 0,
            });
        }
    }, []);

    const { data, loading } = useQuery<
        GetParticipationRequestById,
        GetParticipationRequestByIdVariables
    >(GET_PARTICIPATION_REQUEST_BY_ID, {
        variables: { id: parseInt(taskId) },
        fetchPolicy: 'no-cache',
    });

    if (loading) {
        return <Loading />;
    }

    // current user uid has to match the jobpost poster's uid
    if (currentUser.uid != data.participationRequest.provider.uid) {
        return <Redirect to='/' />;
    }

    const convertedCredits = time_credit_conversion(
        data.participationRequest.recipientOfferCredits
    ).split(':'); // accessing the hrs & mins
    const creditHrs: string = convertedCredits[0]; // hrs
    const creditMins: string = convertedCredits[1]; // mins

    const handleAcceptCredits = () => {
        updateLocalStorage('completeTaskPostedViaProvider', 'hrs', creditHrs);
        updateLocalStorage('completeTaskPostedViaProvider', 'mins', creditMins);
        history.push(`/completeTaskPosted/provider/${taskId}/rating`);
    };

    return (
        <IonPage>
            <IonContent>
                <IonGrid>
                    <IonRow className='completeTask__header'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='completeTask__header--container'
                        >
                            <Strokes top={true} />
                            <CompleteTaskNavTabs
                                disablePrevStep={true}
                                prevPage=''
                            />
                            <IonImg
                                className='createTask__header--img'
                                src={
                                    data.participationRequest.provider
                                        .profilePicture
                                }
                                alt='user'
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow className='completeTask__textContent'>
                        <IonCol
                            className='completeTask__textContent--container'
                            sizeSm='12'
                            sizeXs='12'
                        >
                            <h1 className='completeTask__textContent--title'>
                                Confirm the <span>duration</span> of your{' '}
                                <span>
                                    {data.participationRequest.skill.name}
                                </span>{' '}
                                exchange
                            </h1>
                        </IonCol>
                    </IonRow>
                    <IonGrid className='completeTask__form--container'>
                        <IonRow className='completeTask__form--row'>
                            <form className='completeTask__col'>
                                <IonCol
                                    sizeXs='6'
                                    sizeSm='6'
                                    className='completeTask__input--wrap'
                                >
                                    <input
                                        className='completeTask__input'
                                        placeholder='-'
                                        type='number'
                                        min={1}
                                        max={24}
                                        value={creditHrs}
                                        disabled
                                        pattern='\d*'
                                    />
                                    <IonLabel className='completeTask__input--label'>
                                        Hours
                                    </IonLabel>
                                </IonCol>
                                <IonCol
                                    sizeXs='6'
                                    sizeSm='6'
                                    className='completeTask__input--wrap'
                                >
                                    <input
                                        className='completeTask__input'
                                        placeholder='-'
                                        type='number'
                                        min={1}
                                        max={60}
                                        value={creditMins}
                                        disabled
                                        pattern='\d*'
                                    />
                                    <IonLabel className='completeTask__input--label'>
                                        Minutes
                                    </IonLabel>
                                </IonCol>
                            </form>
                        </IonRow>
                        <IonRow className='completeTask__btn--row'>
                            <IonCol
                                sizeSm='12'
                                sizeXs='12'
                                className='completeTask__btn--wrap'
                            >
                                <IonButton
                                    fill='clear'
                                    color='ion-primary'
                                    className='completeTask__btn--msg'
                                >
                                    Send Message
                                </IonButton>
                                <IonButton
                                    fill='clear'
                                    color='ion-primary'
                                    className='completeTask__btn--accept'
                                    onClick={handleAcceptCredits}
                                >
                                    Accept
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default CompleteTask;
