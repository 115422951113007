import React, { useState } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useAuth } from 'context/firebase/authContext';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
    GetUserAccount as GUAQuery,
    AttachPaymentMethod as APMQuery,
} from '../../GraphQL/StripeConnect/StripeConnect';
import { useHistory } from 'react-router-dom';
import { checkmark } from 'ionicons/icons';
import {
    IonContent,
    IonPage,
    IonRow,
    IonCol,
    IonImg,
    IonButton,
    IonIcon,
    IonToast,
} from '@ionic/react';
import Loading from '../../components/status/Loading';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import Strokes from 'components/Strokes';
import BackButton from 'components/BackButton';
import CancelButton from 'components/CancelButton/CancelButton';
import './AddPaymentMethod.css';

const AddPaymentMethod: React.FC = () => {
    const stripe = useStripe();
    const elements = useElements();
    const history = useHistory();
    const [hasClicked, sethasClicked] = useState<boolean>(false);
    const [showToast, setShowToast] = useState<boolean>(false);
    const [isSuccess, setisSuccess] = useState<boolean>(false);
    const [errorCode, setErrorCode] = useState<string>('');
    const { currentUser } = useAuth();
    const { data, loading, error, fetchMore } = useQuery(GUAQuery, {
        variables: {
            uid: currentUser.uid,
        },
    });
    const [attachPaymentMethod, { data: attachedResponse }] = useMutation(
        APMQuery,
        {
            fetchPolicy: 'no-cache',
            onCompleted: (attachedResponse) => {
                if (
                    attachedResponse?.attachPaymentMethod?.status == 'success'
                ) {
                    setisSuccess(true);
                    setShowToast(true);
                    setErrorCode('');
                    setTimeout(() => {
                        history.goBack();
                    }, 3000);
                } else {
                    const errorMessage = JSON.parse(
                        attachedResponse?.attachPaymentMethod?.error
                    );
                    sethasClicked(false);
                    switch (errorMessage?.code) {
                        case 'lost_card':
                            setErrorCode('7.04');
                            break;
                        case 'stolen_card':
                            setErrorCode('7.04');
                            break;
                        case 'expired_card':
                            setErrorCode('7.03');
                            break;
                        case 'incorrect_cvc':
                            setErrorCode('7.05');
                            break;
                        case 'incorrect_number':
                            setErrorCode('7.01');
                            break;
                        default:
                            setErrorCode('7.10');
                    }
                }
            },
        }
    );

    const handleSubmit = async () => {
        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
        }
        //  used as a defensive strategy to disable save button
        //  and prevent user spamming the button
        sethasClicked(true);

        //  get the embedded stripe element component which is used
        //  for making the request to attach the card to the customer
        const cardElement = elements.getElement(CardElement);

        //  Make the request to create a payment method
        //  returns a payment method object with a
        //  payment method id we use to attach to the customer
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if (error) {
            console.error('[error]', error);
            setisSuccess(false);
            setErrorCode('7.02');
        } else {
            //attach payment method to customer
            attachPaymentMethod({
                variables: {
                    uid: currentUser.uid,
                    paymentMethodId: paymentMethod.id,
                },
            });
        }
    };
    if (loading) {
        return <Loading />;
    }
    return (
        <IonPage className='stripe_page_container'>
            <IonContent>
                <IonToast
                    cssClass='requestDetails__location--clipboard--toast'
                    isOpen={showToast}
                    onDidDismiss={() => setShowToast(false)}
                    message='Profile Method Added!'
                    duration={2000}
                />
                <IonCol
                    sizeXs='12'
                    sizeSm='12'
                    sizeMd='12'
                    className='reqTaskNav__btns--wrap'
                >
                    <BackButton />
                    <CancelButton color='black' />
                </IonCol>
                <IonRow className=''>
                    <IonCol
                        sizeSm='12'
                        sizeXs='12'
                        className='add_payment__header--container'
                    >
                        <Strokes top={true} green={false} />
                        <IonImg
                            className='payment_method__header--img'
                            src={data.profile.profilePicture}
                            alt='user'
                        />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <h1 className='heading'>
                        Add <span>Payment</span> Method
                    </h1>
                </IonRow>
                <IonRow>
                    <p className='heading_description'>
                        Add a payment method to send tips after tasks are
                        completed
                    </p>
                </IonRow>
                <IonRow className='form_container'>
                    <IonRow className='form_element'>
                        <h5 className='form_label'>
                            <span>*</span> Card Information
                        </h5>
                        <div className='form_field'>
                            <CardElement
                                onChange={() => sethasClicked(false)}
                                options={{
                                    style: {
                                        base: {
                                            fontSize: '16px',
                                            backgroundColor: 'white',
                                            color: '#424770',
                                            '::placeholder': {
                                                color: '#aab7c4',
                                            },
                                        },
                                        invalid: {
                                            color: '#9e2146',
                                        },
                                    },
                                }}
                            />
                        </div>
                    </IonRow>

                    <IonButton
                        className={'footerWithChild__btn'}
                        fill={'clear'}
                        style={{ margin: '20px' }}
                        disabled={hasClicked}
                        onClick={handleSubmit}
                    >
                        Save Payment Method
                    </IonButton>
                    {errorCode && <ErrorMessage errorCode={errorCode} />}
                    {isSuccess && (
                        <IonCol className='stripe_response_message'>
                            <IonRow>
                                <p style={{ width: '100%' }}>
                                    {'Card has been successfully added'}
                                </p>
                            </IonRow>
                            <IonRow
                                style={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <IonIcon
                                    className='success_icon'
                                    icon={checkmark}
                                />
                            </IonRow>
                        </IonCol>
                    )}
                </IonRow>
                <Strokes bot={true} green={false} />
            </IonContent>
        </IonPage>
    );
};

export default AddPaymentMethod;
