// importing modules
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import {
    getLocalStorage,
    updateLocalStorage,
    setLocalStorage,
} from 'helpers/localStorage/localStorageHelper';
// importing components
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonPage,
    IonRow,
    IonIcon,
} from '@ionic/react';
import Loading from 'components/status/Loading';
import Strokes from 'components/Strokes';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import BackButton from 'components/BackButton';
// importing assets
// importing graphql
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_SKILL_NEEDED_BY_ID } from 'GraphQL/SkillNeeded/GET_SKILL_NEEDED_BY_ID/GET_SKILL_NEEDED_BY_ID';
import {
    GetSkillNeededById,
    GetSkillNeededByIdVariables,
} from 'GraphQL/__generated__/GetSkillNeededById';
import { JOB_POST_PARTICIPATION_REQUEST } from 'GraphQL/SkillNeeded/JOB_POST_PARTICIPATION_REQUEST/JOB_POST_PARTICIPATION_REQUEST';
import {
    CreateOneJobPostParticipationRequest,
    CreateOneJobPostParticipationRequestVariables,
} from 'GraphQL/__generated__/CreateOneJobPostParticipationRequest';

import {
    CreateOneJobPostParticipationRequestNonAdmin,
    CreateOneJobPostParticipationRequestNonAdminVariables,
} from 'GraphQL/__generated__/CreateOneJobPostParticipationRequestNonAdmin';

import { PUBLISH_PARTICIPATION_REQUEST_NOTIFICATION } from 'GraphQL/ParticipationRequest/PUBLISH_PARTICIPATION_REQUEST_NOTIFICATION/PUBLISH_PARTICIPATION_REQUEST_NOTIFICATION';
import {
    PublishParticipationRequestNotification,
    PublishParticipationRequestNotificationVariables,
} from 'GraphQL/__generated__/PublishParticipationRequestNotification';
import { Status } from 'GraphQL/__generated__/globalTypes';
import { JOB_POST_PARTICIPATION_REQUEST_NON_ADMIN } from 'GraphQL/SkillNeeded/JOB_POST_PARTICIPATION_REQUEST_NON_ADMIN/JOB_POST_PARTICIPATION_REQUEST_NON_ADMIN';
import { useAuth } from 'context/firebase/authContext';
import { USER_PROFILE_BY_UID } from 'GraphQL/Profile/UserProfile/USER_PROFILE_BY_UID';
import {
    UserProfile_byID,
    UserProfile_byIDVariables,
} from 'GraphQL/__generated__/UserProfile_byID';
import { GET_USER_PARTY_INFO } from 'GraphQL/parties/GET_USER_PARTY_INFO';
import {
    GetUserPartyInfo,
    GetUserPartyInfoVariables,
} from 'GraphQL/__generated__/GetUserPartyInfo';
// importing styles
import './ConfirmTaskPartication.css';
import { ADMIN_GROUP_PROFILE_PICTURE } from 'GraphQL/Admin/adminGroupProfilePicture/adminGroupProfilePicture';

export default () => {
    const history = useHistory();
    const { skillId } = useParams<{ skillId: string }>();
    const { currentUser } = useAuth();
    const [senderId, senderIdData] = useState(0);
    const [receiverId, receiverIdData] = useState(0);
    const [hasClicked, setHasClicked] = useState<boolean>(false);
    const [errorCode, setErrorCode] = useState<string>('');
    const [extendedError, setExtendedError] = useState<string>('');
    const [participationRequest, participationRequestDatas] = useMutation<
        CreateOneJobPostParticipationRequest,
        CreateOneJobPostParticipationRequestVariables
    >(JOB_POST_PARTICIPATION_REQUEST);

    const [participationRequestNonAdmin, participationRequestNonAdminDatas] =
        useMutation<
            CreateOneJobPostParticipationRequestNonAdmin,
            CreateOneJobPostParticipationRequestNonAdminVariables
        >(JOB_POST_PARTICIPATION_REQUEST_NON_ADMIN);

    const [publishNotification, publishNotificationDatas] = useMutation<
        PublishParticipationRequestNotification,
        PublishParticipationRequestNotificationVariables
    >(PUBLISH_PARTICIPATION_REQUEST_NOTIFICATION);

    const { data, loading } = useQuery<
        GetSkillNeededById,
        GetSkillNeededByIdVariables
    >(GET_SKILL_NEEDED_BY_ID, {
        variables: { id: parseInt(skillId) },
        fetchPolicy: 'no-cache',
    });

    //Grabbing the receiverId
    const {
        data: dataReceiverOne,
        error: something,
        loading: stillLoading,
    } = useQuery<UserProfile_byID, UserProfile_byIDVariables>(
        USER_PROFILE_BY_UID,
        {
            variables: { subId: currentUser.uid },
            fetchPolicy: 'no-cache',
        }
    );
    const { data: dataReceiverTwo, loading: receiverloading } = useQuery<
        GetUserPartyInfo,
        GetUserPartyInfoVariables
    >(GET_USER_PARTY_INFO, {
        skip: !dataReceiverOne,
        variables: { userId: dataReceiverOne && dataReceiverOne?.profile.id },
    });

    //Grabbing the senderId
    const {
        data: dataSenderId,
        error: somethingSender,
        loading: stillLoadingSender,
    } = useQuery<UserProfile_byID, UserProfile_byIDVariables>(
        USER_PROFILE_BY_UID,
        {
            skip: !dataReceiverTwo,
            variables: { subId: data?.skillNeeded.jobPost.recipient.uid },
            fetchPolicy: 'no-cache',
        }
    );

    const { data: dataSenderTwo, loading: loadingsender } = useQuery<
        GetUserPartyInfo,
        GetUserPartyInfoVariables
    >(GET_USER_PARTY_INFO, {
        skip: !dataSenderId,
        variables: { userId: dataSenderId && dataSenderId?.profile.id },
    });

    const { data: adminData, loading: adminLoading } = useQuery(
        ADMIN_GROUP_PROFILE_PICTURE,
        {
            variables: { adminId: data?.skillNeeded.jobPost.adminId },
        }
    );
    const hasAdmin = data?.skillNeeded.jobPost.adminId;
    useEffect(() => {
        dataSenderTwo?.showAllParties.map((thing) => {
            if (thing.bankAccountrelation[0].accountType == 'BALANCE') {
                senderIdData(thing.partyId);
            }
        });
        dataReceiverTwo?.showAllParties.map((thing) => {
            if (thing.bankAccountrelation[0].accountType == 'BALANCE') {
                receiverIdData(thing.partyId);
            }
        });
    });

    if (loading) {
        return <Loading />;
    }

    const handleParticipationRequest = () => {
        setHasClicked(true);
        let keyName = getLocalStorage('currentAdminGroupId');

        if (getLocalStorage('currentAdminGroupId') !== 0) {
            participationRequest({
                variables: {
                    skillId: parseInt(skillId),
                    participantUid: currentUser.uid,
                    recipientUid: data.skillNeeded.jobPost.recipient.uid,
                    status: Status.REQUESTED,
                    adminId: getLocalStorage(keyName).admin,
                    groupId: getLocalStorage('currentAdminGroupId'),

                    sender: senderId,
                    receiver: receiverId,
                },
            })
                .then((res) => {
                    const participationRequestId =
                        res.data.updateOneSkillNeeded.participationRequests.find(
                            (pr) => pr.providerId === currentUser.uid
                        );
                    publishNotification({
                        variables: {
                            id: participationRequestId.id,
                        },
                    })
                        .then((res) => {
                            history.push(
                                `/request/${skillId}/participation/confirmation`
                            );
                        })
                        .then((res) => {
                            history.push(
                                `/request/${skillId}/participation/confirmation`
                            );
                        })
                        .catch((err) => {
                            setErrorCode('1.01');
                            setExtendedError(`1. ${err}`);
                            console.error(`${err}`);
                        });
                })
                .catch((err) => {
                    setErrorCode('1.01');
                    setExtendedError(`2. ${err}`);
                    console.error(`${err}`);
                });
        } else {
            participationRequestNonAdmin({
                variables: {
                    skillId: parseInt(skillId),
                    participantUid: currentUser.uid,
                    recipientUid: data.skillNeeded.jobPost.recipient.uid,
                    status: Status.REQUESTED,
                    sender: senderId,
                    receiver: receiverId,
                },
            })
                .then((res) => {
                    const participationRequestId =
                        res.data.updateOneSkillNeeded.participationRequests.find(
                            (pr) => pr.providerId === currentUser.uid
                        );
                    publishNotification({
                        variables: {
                            id: participationRequestId.id,
                        },
                    })
                        .then((res) => {
                            history.push(
                                `/request/${skillId}/participation/confirmation`
                            );
                        })
                        .then((res) => {
                            history.push(
                                `/request/${skillId}/participation/confirmation`
                            );
                        })
                        .catch((err) => {
                            setErrorCode('1.01');
                            setExtendedError(`3. ${err}`);
                            console.error(`${err}`);
                        });
                })
                .catch((err) => {
                    setErrorCode('1.01');
                    setExtendedError(`4. ${err}`);
                    console.error(`${err}`);
                });
        }
    };

    return (
        <IonPage>
            <IonContent>
                <IonGrid>
                    <IonRow className='confirmTask__header'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='confirmTask__header--container'
                        >
                            <BackButton color='white' />
                            <Strokes top={true} />
                            <IonImg
                                className='createTask__header--img'
                                src={
                                    data.skillNeeded.jobPost.adminId
                                        ? adminLoading
                                            ? null
                                            : adminData.AdminGroupProfilePicture
                                                  .orgPicture
                                        : data.skillNeeded.jobPost.recipient
                                              .profilePicture
                                }
                                alt=''
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow className='confirmTask__textContent'>
                        <IonCol
                            className='confirmTask__textContent--container'
                            sizeSm='12'
                            sizeXs='12'
                        >
                            <h1 className='confirmTask__textContent--title'>
                                <span>Confirm,</span> Participation Request
                            </h1>
                            <p className='confirmTask__textContent--text'>
                                You are requesting to participate by providing
                                the following skill. Once the task owner accepts
                                your request you will be able to see the exact
                                location. Upon completion of the task, Balance
                                Tokens will be exchanged.
                            </p>
                        </IonCol>
                    </IonRow>
                    <IonGrid className='confirmTask__container'>
                        <IonRow className='confirmTask__row'>
                            <IonCol
                                className='confirmTask__col'
                                sizeXs='12'
                                sizeSm='12'
                            >
                                <IonCol
                                    sizeXs='12'
                                    sizeSm='12'
                                    className='ion-padding confirmTask__content'
                                >
                                    <p className='confirmTask__content--skill'>
                                        {data.skillNeeded.name}
                                    </p>
                                    {/* <p className="confirmTask__content--completed">Worth 2 Balance Tokens</p> */}
                                    <p className='confirmTask__content--text'>
                                        {data.skillNeeded.additionalDetails}
                                    </p>
                                </IonCol>
                            </IonCol>
                        </IonRow>
                    </IonGrid>

                    <IonRow className='confirmTask__btn--container'>
                        <IonCol
                            className='confirmTask__btn--wrap'
                            sizeXs='12'
                            sizeSm='12'
                        >
                            <IonButton
                                fill='clear'
                                color='ion-primary'
                                className='confirmTask__btn'
                                onClick={handleParticipationRequest}
                                disabled={hasClicked}
                            >
                                Request Participation
                            </IonButton>
                        </IonCol>
                    </IonRow>
                    {errorCode && (
                        <ErrorMessage
                            errorCode={errorCode}
                            extendedError={extendedError}
                        />
                    )}
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};
