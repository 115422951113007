// importing modules
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
// importing components
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonPage,
    IonRow,
} from '@ionic/react';
import ErrorMsg, {
    InputErrorMsgCentered,
} from '../../components/InputErrorMsg/InputErrorMsg';
import Strokes from 'components/Strokes';
import BackButton from 'components/BackButton';
// importing assets
import yingLogo from '../../assets/ying-logo-large-blk.png';
// importing styles
import './ForgotPassword.css';
// firebase context
import { useAuth } from '../../context/firebase/authContext';

type userEmail = {
    email: string;
};

const ForgotPassword: React.FC = () => {
    const history = useHistory();
    const { resetPassword } = useAuth();
    const [err, setErr] = useState<string>('');
    const { register, handleSubmit, errors } = useForm<userEmail>();

    const handleOnSubmit = (data: userEmail) => {
        resetPassword(data.email)
            .then(function () {
                history.push(`/forgotPassword/${data.email}/confirmation`);
            })
            .catch(function (error) {
                setErr(error.message);
            });
    };

    return (
        <IonPage>
            <IonContent scrollY={false}>
                <IonGrid className='forgotPassword__grid'>
                    <Strokes green={false} />
                    <IonRow className='forgotPassword'>
                        <IonCol
                            sizeXs='12'
                            sizeSm='12'
                            className='forgotPassword__container'
                        >
                            <BackButton />
                            <IonCol
                                sizeXs='12'
                                sizeSm='12'
                                className='forgotPassword__container'
                            >
                                <IonImg
                                    className='forgotPassword__img'
                                    src={yingLogo}
                                    alt='ying logo'
                                />
                                <h1 className='forgotPassword__title'>
                                    Reset{' '}
                                    <span className='forgotPassword__title--span'>
                                        Password
                                    </span>
                                </h1>
                                <p className='forgotPassword__text'>
                                    Enter the email address associated with your
                                    YING account so we can send you a reset
                                    password link
                                </p>
                                {errors.email &&
                                    errors.email.type === 'required' && (
                                        <ErrorMsg errorMsg='Email is required' />
                                    )}
                                {errors.email &&
                                    errors.email.type === 'pattern' && (
                                        <ErrorMsg errorMsg='Email is not valid' />
                                    )}
                                {err && (
                                    <InputErrorMsgCentered errorMsg={err} />
                                )}
                                <form
                                    onSubmit={handleSubmit(handleOnSubmit)}
                                    className='forgotPassword__form'
                                >
                                    <input
                                        ref={register({
                                            required: 'Email is required',
                                            pattern:
                                                /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                        })}
                                        name='email'
                                        className={`${
                                            errors.email
                                                ? 'forgotPassword__form--input forgotPassword__form--input--error'
                                                : 'forgotPassword__form--input'
                                        }`}
                                        placeholder='user@example.com'
                                        type='email'
                                        formNoValidate
                                        autoComplete='off'
                                    />
                                    <IonButton
                                        fill='clear'
                                        color='ion-primary'
                                        className='forgotPassword__form--submit'
                                        type='submit'
                                    >
                                        Send Link
                                    </IonButton>
                                </form>
                            </IonCol>
                        </IonCol>
                    </IonRow>
                    <Strokes bot={true} />
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default ForgotPassword;
