// importing modules
import React from 'react';
// importing components
import EarnSectionTile from '../EarnSectionTile/EarnSectionTile';
// importing types
import { GetJobPostsFromManyOrgs_jobPostsFromManyOrgs } from '../../../GraphQL/__generated__/GetJobPostsFromManyOrgs';
import moment from 'moment';

const EarnSectionTileList: React.FC<{
    jobsPosted: GetJobPostsFromManyOrgs_jobPostsFromManyOrgs[];
}> = ({ jobsPosted }) => {
    // Sorts jobs posted by time created.
    //@ts-ignore
    jobsPosted = jobsPosted
        //    @ts-ignore
        .sort((a: any, b: any) => moment(b.createdAt) - moment(a.createdAt));
    console.debug(jobsPosted);
    return (
        <>
            {jobsPosted.length > 0 ? (
                jobsPosted.map((jobPost) => {
                    return (
                        <EarnSectionTile key={jobPost.id} jobPost={jobPost} />
                    );
                })
            ) : (
                <div>
                    <p>Be the first to create a group task!</p>
                </div>
            )}
        </>
    );
};

export default EarnSectionTileList;
