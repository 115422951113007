const LINKS = [
    /*
    {
        id: 0,
        path: '/#',
        name: 'Tutorial',
    },
    */
    {
        id: 1,
        path: '/about',
        name: 'About YING',
    },
    {
        id: 2,
        path: '/payments',
        name: 'Support YING',
    },
    {
        id: 3,
        path: '/settings',
        name: 'Settings',
    },
    {
        id: 4,
        path: '/loggedInFaq',
        name: 'FAQ',
    },
    {
        id: 5,
        path: '/loggedInPrivacyPolicy',
        name: 'Privacy Policy',
    },
    {
        id: 6,
        path: '/loggedInTermsOfUse',
        name: 'Terms of Use',
    },
];

export { LINKS as default };
