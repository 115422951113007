// importing modules
import React, { useState } from 'react';
import { useParams } from 'react-router';
import moment from 'moment-timezone';
// importing components
import {
    IonCol,
    IonIcon,
    IonImg,
    IonPage,
    IonContent,
    IonRow,
} from '@ionic/react';
import ProfileStateTabs from '../../components/ProfileStateTabs/ProfileStateTabs';
import UserMenu from '../../components/UserMenu/UserMenu';
import UserImg from '../CreateTask/UI/UserImg/UserImg';
import UserMenuBackdrop from '../../components/UserMenu/UserMenuBackdrop';
import CompletedJobsList from './CompletedTasksList/CompletedTasksList';
import FilteredCompletedJobsList from './FilteredCompletedTasksList/FilteredCompletedTasksList';
import Loading from '../../components/status/Loading';
import NavBar from '../../components/NavBar';
import Strokes from 'components/Strokes';
// importing assets
import { searchOutline } from 'ionicons/icons';
// importing utilities
import { FirebaseHelper } from '../../helpers/FirebaseHelper';
import { getLocalStorage } from '../../helpers/localStorage/localStorageHelper';
// importing graphql
import { useQuery } from '@apollo/react-hooks';
import { GET_COMPLETED_JOBS_BY_ID } from '../../GraphQL/Job/GET_COMPLETED_JOBS/GET_COMPLETED_JOBS_BY_ID';
import {
    GetCompletedJobsById,
    GetCompletedJobsByIdVariables,
    GetCompletedJobsById_completedJobs,
} from '../../GraphQL/__generated__/GetCompletedJobsById';
//importing styles
import './CompletedTasks.css';
// firebase context
import { useAuth } from '../../context/firebase/authContext';

const CompletedTasks: React.FC = () => {
    const { currentUser } = useAuth();
    const { userId } = useParams<{ userId: string }>();
    const fuser = new FirebaseHelper(currentUser);
    const [searchInput, setSearchInput] = useState<string>('');
    const [toggleMenu, setToggleMenu] = useState<Boolean>(false); // handles hamburger menu
    const handleMenuToggle = () => setToggleMenu((prevToggle) => !prevToggle);

    const { data, loading } = useQuery<
        GetCompletedJobsById,
        GetCompletedJobsByIdVariables
    >(GET_COMPLETED_JOBS_BY_ID, { variables: { id: parseInt(userId) } });

    if (loading) {
        return <Loading />;
    }

    let completedTasks: GetCompletedJobsById_completedJobs[] =
        data.completedJobs;
    if (searchInput.length > 0 && !loading) {
        completedTasks = data.completedJobs.filter(
            (task) =>
                task.recipient.firstName.toLowerCase().includes(searchInput) || // search by recipient name
                task.recipient.lastName.toLowerCase().includes(searchInput) || // search by recipient name
                task.provider.firstName.toLowerCase().includes(searchInput) || // search by provider name
                task.provider.lastName.toLowerCase().includes(searchInput) || // search by provider name
                (task.recipient.firstName + ' ' + task.recipient.lastName)
                    .toLowerCase()
                    .includes(searchInput) || // search by recipient name
                (task.provider.firstName + ' ' + task.provider.lastName)
                    .toLowerCase()
                    .includes(searchInput) || // search by provider name
                task.Skill.name.toLowerCase().includes(searchInput) || // search by skill
                moment(task.endTime)
                    .tz(getLocalStorage('tz'), false)
                    .format('MMMM Do YYYY')
                    .toLowerCase()
                    .includes(searchInput)
        ); // search by date
    }

    return (
        <IonPage>
            <IonContent>
                <IonContent>
                    {toggleMenu ? (
                        <IonCol className='menu__sidebar--active'>
                            <UserMenu
                                handleMenuToggle={handleMenuToggle}
                                userUid={fuser.uid}
                            />
                        </IonCol>
                    ) : (
                        <IonCol className='menu__sidebar--inactive' />
                    )}
                    {toggleMenu ? (
                        <UserMenuBackdrop handleMenuToggle={handleMenuToggle} />
                    ) : null}
                    {/* completedTask header */}
                    <ProfileStateTabs
                        showSearch={false}
                        handleMenuToggle={handleMenuToggle}
                    />
                    <IonRow className='completedTasks__header'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='completedTasks__header--container'
                        >
                            <Strokes top={true} />
                            <UserImg />
                        </IonCol>
                    </IonRow>
                    {/* text content */}
                    <IonRow className='completedTasks__textContent'>
                        <IonCol
                            className='completedTasks__textContent--container'
                            sizeSm='12'
                            sizeXs='12'
                        >
                            <h1 className='completedTasks__textContent--title'>
                                Completed<span> Tasks</span>
                            </h1>
                        </IonCol>
                    </IonRow>
                    {/* task searchbar */}
                    <IonRow className='completedTasks__searchbar'>
                        <IonCol
                            sizeXs='12'
                            sizeSm='12'
                            className='completedTasks__searchbar--container'
                        >
                            <div className='completedTasks__searchbar--input--wrap'>
                                <IonIcon
                                    className='completedTasks__searchbar--input--icon'
                                    icon={searchOutline}
                                />
                                <input
                                    onChange={(e) =>
                                        setSearchInput(
                                            e.target.value.toLowerCase()
                                        )
                                    }
                                    className='completedTasks__searchbar--input'
                                    type='text'
                                    placeholder='Search for a task here...'
                                />
                            </div>
                        </IonCol>
                    </IonRow>
                    {/* completed Tasks list */}
                    {searchInput.length > 0 ? (
                        <FilteredCompletedJobsList
                            completedTasks={completedTasks}
                        />
                    ) : (
                        <CompletedJobsList completedTasks={completedTasks} />
                    )}
                </IonContent>
                <NavBar />
            </IonContent>
        </IonPage>
    );
};

export default CompletedTasks;
