// importing modules
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'context/firebase/authContext';
// importing components
import {
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonRow,
    IonPage,
} from '@ionic/react';
import ConfirmationScreenLogo from '../../../components/ConfirmationScreenLogo/ConfirmationScreenLogo';
import Strokes from 'components/Strokes';
// importing assets
import Loading from '../../../components/status/Loading';
// importing graphql utilities - types
import { useQuery } from '@apollo/react-hooks';
import {
    GetUserHomePage,
    GetUserHomePageVariables,
} from '../../../GraphQL/__generated__/GetUserHomePage';
import { USER_HOME_DATA } from '../../../GraphQL/Profile/Home/home';
import { FirebaseHelper } from '../../../helpers/FirebaseHelper';
// importing styles
import './CancelHelpingRecipientConfirmationScreen.css';

const TaskConfirmation: React.FC = () => {
    const history = useHistory();
    const { currentUser } = useAuth();
    const fuser = new FirebaseHelper(currentUser);

    useEffect(() => {
        setTimeout(() => {
            history.push('/home');
        }, 2000);
    }, []);

    const { data, loading } = useQuery<
        GetUserHomePage, // query return return type (shape of data)
        GetUserHomePageVariables // query request type       (shape of variables)
    >(USER_HOME_DATA, {
        variables: {
            uid: fuser.uid,
        },
    });

    if (loading) {
        return <Loading />;
    }

    return (
        <IonPage>
            <IonContent>
                <IonGrid className='taskParCancel__grid'>
                    <IonRow className='taskParCancel'>
                        <IonCol
                            sizeXs='12'
                            sizeSm='12'
                            className='taskParCancel__container'
                        >
                            <ConfirmationScreenLogo />
                            <Strokes />
                            <div className='taskParCancel__img--wrap'>
                                <IonImg
                                    src={data.profile.profilePicture}
                                    className='taskParCancel__img'
                                />
                            </div>
                            <h1 className='taskParCancel__title'>
                                Your participation has been cancelled!
                            </h1>
                            <p className='taskParCancel__text'>
                                Look for other tasks to help members
                            </p>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default TaskConfirmation;
