// importing modules
import React, { useState, useEffect, useRef } from 'react';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import moment from 'moment';
// importing components
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonRow,
    IonPage,
} from '@ionic/react';
import RequestTaskTabs from '../UI/RequestTaskTabs';
import Calendar from 'react-calendar';
import UserImg from '../UserImg/UserImg';
import Loading from '../../../components/status/Loading';
import Strokes from 'components/Strokes';
// importing assets
// importing helpers
import {
    getLocalStorage,
    setLocalStorage,
    updateLocalStorage,
} from '../../../helpers/localStorage/localStorageHelper';
// importing graphql
import { useQuery } from '@apollo/react-hooks';
import {
    GetSettingsByUID,
    GetSettingsByUIDVariables,
} from '../../../GraphQL/__generated__/GetSettingsByUID';
import { GET_SETTINGS_BY_UID } from '../../../GraphQL/Setting/GET_SETTINGS/GET_SETTINGS_BY_UID';
// importing styles
import '../TaskDayAvl/TaskDayAvl.css';
import { useAuth } from '../../../context/firebase/authContext';
import {
    UserByUID,
    UserByUIDVariables,
} from '../../../GraphQL/__generated__/UserByUID';
import { USER_ORGANIZATIONS_BY_UID } from '../../../GraphQL/Profile/USER_ORGANIZATIONS/USER_ORGANIZATIONS_BY_UID';

const Schedule: React.FC = () => {
    const { currentUser } = useAuth();
    const history = useHistory();
    const chatLink = useRef(null);
    const handleChatroom = () => chatLink.current?.click();
    const { provider, skillName, skillId } = useParams<{
        provider: string;
        skillName: string;
        skillId: string;
    }>();
    const [selectedDate, setSelectedDate] = useState<any>(null);

    useEffect(() => {
        if (!getLocalStorage('requestSkill')) {
            const requestSkillObj = {
                date: null,
                time: '',
                location: '',
                locationType: '',
                message: '',
                timezone: '',
                startTime: '',
                endTime: '',
            };
            setLocalStorage('requestSkill', requestSkillObj);
        } else {
            const requestSkillObj = getLocalStorage('requestSkill');
            setSelectedDate(
                requestSkillObj.date === 'Undecided'
                    ? 'Undecided'
                    : new Date(requestSkillObj.date)
            );
        }
    }, []);

    const { data, loading } = useQuery<
        GetSettingsByUID, // query return return type (shape of data)
        GetSettingsByUIDVariables // query request type       (shape of variables)
    >(GET_SETTINGS_BY_UID, {
        variables: {
            uid: provider,
        },
    });

    //Gets organizations of both currentUser and service provider
    const { data: currentUserData, loading: currentUserDataLoading } = useQuery<
        UserByUID, // query return return type (shape of data)
        UserByUIDVariables // query request type       (shape of variables)
    >(USER_ORGANIZATIONS_BY_UID, {
        variables: {
            subId: currentUser.uid,
        },
    });
    const { data: providerUserData, loading: providerUserDataLoading } =
        useQuery<
            UserByUID, // query return return type (shape of data)
            UserByUIDVariables // query request type       (shape of variables)
        >(USER_ORGANIZATIONS_BY_UID, {
            variables: {
                subId: provider,
            },
        });

    if (loading || currentUserDataLoading || providerUserDataLoading) {
        return <Loading />;
    }

    //It checks if users have a group in common
    if (currentUserData != undefined && providerUserData != undefined) {
        const filteredArray = currentUserData.profile.organizations
            .map((orgs) => orgs.id)
            .filter((value) =>
                providerUserData.profile.organizations
                    .map((orgs) => orgs.id)
                    .includes(value)
            );

        if (filteredArray.length == 0) {
            return <Redirect to='/' />;
        }
    }

    // User cannot request himself.
    //Cannot be placed higher because hooks cannot be done condiniotaly
    if (provider == currentUser.uid) {
        return <Redirect to='/' />;
    }

    const handleSelectedDate = (date: Date) => setSelectedDate(date);
    const handleDateConfirmation = () => {
        selectedDate === 'Undecided'
            ? updateLocalStorage('requestSkill', 'date', 'Undecided')
            : updateLocalStorage('requestSkill', 'date', selectedDate);
        history.push(`/requestSkill/${provider}/${skillName}/${skillId}/time`);
        updateLocalStorage(
            'requestSkill',
            'timezone',
            data.getSettingsByUID.timezone
        );
    };

    return (
        <IonPage>
            <IonContent>
                <IonGrid>
                    {/* header */}
                    <IonRow className='calend__header'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='calend__header--container'
                        >
                            <Strokes top={true} />
                            <RequestTaskTabs
                                prevPage=''
                                disablePrevStep={true}
                            />
                            <UserImg providerUid={provider} />
                        </IonCol>
                    </IonRow>
                    {/* text content */}
                    <IonRow className='calend__textContent'>
                        <IonCol
                            className='calend__textContent--container'
                            sizeSm='12'
                            sizeXs='12'
                        >
                            <h1 className='calend__textContent--title'>
                                Choose a <span>date</span>
                            </h1>
                        </IonCol>
                    </IonRow>
                    {/* calendar */}
                    <IonGrid>
                        <IonRow className='calendar__container'>
                            <IonCol
                                sizeXs='12'
                                sizeSm='12'
                                className='calendar__wrap'
                            >
                                <IonRow className='calendar__row'>
                                    <Calendar
                                        onChange={handleSelectedDate}
                                        value={
                                            selectedDate === 'Undecided'
                                                ? null
                                                : selectedDate
                                        }
                                        minDate={new Date()}
                                        //maxDate={moment().add(13, 'd').toDate()} //Took out maxDate to remove 2 week restriction//
                                        prev2Label=''
                                        next2Label=''
                                        defaultValue={
                                            selectedDate === 'Undecided'
                                                ? null
                                                : selectedDate
                                        }
                                        className='react-calendar'
                                    />
                                </IonRow>
                            </IonCol>

                            {/* when ready, uncomment the below code to put the tbd button on the calendar screen for skill requests */}
                            {/* <IonRow className='startDate__form--row-tbd'>
                                <div
                                    onClick={() => setSelectedDate('Undecided')}
                                    className={
                                        selectedDate === 'Undecided'
                                            ? 'startTime__content--day--na startTime__content--day--active'
                                            : 'startTime__content--day--na'
                                    }
                                >
                                    Date hasn't been decided
                                </div>
                            </IonRow> */}

                            <IonCol
                                className='calendar__text--wrap'
                                sizeSm='12'
                                sizeXs='12'
                            >
                                <p className='calendar__text'>
                                    <span
                                        // onClick={() => history.push(`/chatroom/${currentUser.uid}/${provider}`)}
                                        onClick={handleChatroom}
                                        className='calendar__text--span'
                                    >
                                        <a
                                            ref={chatLink}
                                            href={`/chatroom/${currentUser.uid}/${provider}`}
                                            type='hidden'
                                        ></a>
                                        Message me for further questions
                                    </span>
                                </p>
                            </IonCol>
                            {/* </IonCol> */}
                        </IonRow>
                    </IonGrid>
                    {/* btn */}
                    <IonRow className='calend__btn--container'>
                        <IonCol
                            className='calend__btn--wrap'
                            sizeXs='12'
                            sizeSm='12'
                        >
                            <IonButton
                                fill='clear'
                                disabled={selectedDate === null}
                                onClick={handleDateConfirmation}
                                color='ion-primary'
                                className='calend__btn'
                            >
                                Continue
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default Schedule;
