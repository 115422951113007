// importing modules
import React from 'react';
import { useParams, useHistory, Redirect } from 'react-router-dom';
// importing components
import {
    IonButton,
    IonCol,
    IonImg,
    IonPage,
    IonContent,
    IonRow,
} from '@ionic/react';
import CompleteTaskNavTabs from '../UI/CompleteTaskTabs/CompleteTaskTabs';
import UserImg from '../../../components/UserImg/UserImg';
import ParticipantItem from './ParticipantRatingItem/ParticipantRatingItem';
import CompleteTaskFooter from '../../../components/FooterWithChildren/FooterWithChildren';
import Strokes from 'components/Strokes';
// importing assets
// importing utils
import { getLocalStorage } from '../../../helpers/localStorage/localStorageHelper';
// import capitalize from '../../../utils/capitalize';
//importing styles
import './ParticipantRating.css';
import { useAuth } from '../../../context/firebase/authContext';
import { useQuery } from '@apollo/react-hooks';
import {
    GetJobPostRecipient,
    GetJobPostRecipientVariables,
} from '../../../GraphQL/__generated__/GetJobPostRecipient';
import { GET_JOB_POST_RECIPIENT } from '../../../GraphQL/JobPost/GET_JOB_POST_RECEPIENT/GET_JOB_POST_RECIPIENT';
import Loading from '../../../components/status/Loading';
import GroupImg from 'pages/CreateTask/UI/GroupImg/GroupImg';
import {
    GetJobPostById,
    GetJobPostByIdVariables,
} from 'GraphQL/__generated__/GetJobPostById';
import { GET_JOB_POST_BY_ID } from 'GraphQL/JobPost/GET_JOB_POST_BY_ID/GET_JOB_POST_BY_ID';
import { ADMIN_GROUP_PROFILE_PICTURE } from 'GraphQL/Admin/adminGroupProfilePicture/adminGroupProfilePicture';

const ParticipantRating: React.FC = () => {
    const { currentUser } = useAuth();
    const history = useHistory();
    const { taskId } = useParams<{ taskId: string }>();
    const currentParticipants = getLocalStorage(
        'completeTaskPostedViaRecipient'
    ).participants;

    const { data, loading } = useQuery<GetJobPostById, GetJobPostByIdVariables>(
        GET_JOB_POST_BY_ID,
        {
            variables: { jobPostId: parseInt(taskId) },
            fetchPolicy: 'cache-and-network',
        }
    );

    const { data: adminData, loading: adminLoading } = useQuery(
        ADMIN_GROUP_PROFILE_PICTURE,
        {
            variables: { adminId: data?.jobPost.adminId },
        }
    );
    return (
        <IonPage>
            <IonContent fullscreen>
                <IonRow className='taskPartc__header'>
                    <IonCol
                        sizeSm='12'
                        sizeXs='12'
                        className='taskPartc__header--container'
                    >
                        <Strokes top={true} />
                        <CompleteTaskNavTabs
                            disablePrevStep={false}
                            prevPage={`/completeTaskPosted/recipient/${taskId}/participants`}
                        />
                        <IonImg
                            className='createTask__header--img'
                            src={
                                loading
                                    ? null
                                    : data.jobPost.adminId
                                    ? adminLoading
                                        ? null
                                        : adminData.AdminGroupProfilePicture
                                              .orgPicture
                                    : data.jobPost.recipient.profilePicture
                            }
                            alt='user'
                        />
                    </IonCol>
                </IonRow>
                <IonRow className='taskPartc__textContent'>
                    <IonCol
                        className='taskPartc__textContent--container'
                        sizeSm='12'
                        sizeXs='12'
                    >
                        <h1 className='taskPartc__textContent--title'>
                            How would you <span>rate</span> their{' '}
                            <span>participation</span> in the task?
                        </h1>
                        <p className='completeTask__textContent--text'>
                            All tasks on YING require a rating from both people
                            involved.
                        </p>
                    </IonCol>
                </IonRow>
                <IonRow className='taskPartcItem__row'>
                    {currentParticipants.map((participant) => (
                        <ParticipantItem
                            {...participant}
                            key={participant.id}
                        />
                    ))}
                </IonRow>
            </IonContent>
            <CompleteTaskFooter>
                <IonButton
                    onClick={() =>
                        history.push(
                            `/completeTaskPosted/recipient/${taskId}/review`
                        )
                    }
                    fill='clear'
                    color='ion-primary'
                    className='footerWithChild__btn'
                >
                    Continue
                </IonButton>
            </CompleteTaskFooter>
        </IonPage>
    );
};

export default ParticipantRating;
