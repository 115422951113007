// importing modules
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

// importing components
import {
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonPage,
    IonRow,
    IonIcon,
} from '@ionic/react';
import BackButton from 'components/BackButton';
import Strokes from 'components/Strokes';
// importing assets
import { chevronDown, chevronForwardOutline } from 'ionicons/icons';
// importing styles
import styles from './FAQStyle.module.css'; // Implementing a module.css file to allow hiding and styling of divs

const FAQPage: React.FC = () => {
    const history = useHistory();
    // Setting vision useState variables for each question dropdown menu.
    // When that specific button is pressed the corresponding div will appear or disappear
    const [vis1, setVis1] = useState(true),
        [vis2, setVis2] = useState(true),
        [vis3, setVis3] = useState(true),
        [vis4, setVis4] = useState(true),
        [vis5, setVis5] = useState(true),
        [vis6, setVis6] = useState(true),
        [vis7, setVis7] = useState(true),
        [vis8, setVis8] = useState(true),
        [vis9, setVis9] = useState(true),
        [vis10, setVis10] = useState(true);
    // Setting cheveron useState variables for each expand question button (in the main/title div)....This will allow us to animate the cheveron
    const [chev1, setChev1] = useState<boolean>(false),
        [chev2, setChev2] = useState<boolean>(false),
        [chev3, setChev3] = useState<boolean>(false),
        [chev4, setChev4] = useState<boolean>(false),
        [chev5, setChev5] = useState<boolean>(false),
        [chev6, setChev6] = useState<boolean>(false),
        [chev7, setChev7] = useState<boolean>(false),
        [chev8, setChev8] = useState<boolean>(false),
        [chev9, setChev9] = useState<boolean>(false),
        [chev10, setChev10] = useState<boolean>(false);

    return (
        <IonPage>
            <IonContent>
                <Strokes green={false} />
                <IonGrid>
                    <BackButton onClick={() => history.push('/')} />
                    <div
                        style={{
                            height: '6rem',
                        }}
                    />
                    <IonRow className='faq__title--container'>
                        <IonCol
                            className='about__title--wrap'
                            sizeXs='12'
                            sizeSm='12'
                        >
                            <h1 className='about__title'>
                                YING <span>FAQ</span>
                            </h1>
                        </IonCol>
                    </IonRow>
                    <IonGrid className={styles.faqContainer}>
                        {/*First FAQ expansive div*/}
                        <IonRow className={styles.faqWrap}>
                            <IonCol
                                sizeXs='12'
                                sizeSm='12'
                                className={styles.faqContent}
                            >
                                <h3 className={styles.faqTitle}>
                                    <b>
                                        Introduction to
                                        <br />
                                        YING
                                    </b>
                                </h3>
                                <button
                                    className={styles.expandQuestionBtn}
                                    onClick={() => {
                                        setChev1(!chev1); // onClick of the button: 1. Change this specific cheverons position
                                        setVis1((vis) => !vis); //2. Change this specific divs vision/hidden useState variable
                                    }}
                                >
                                    <IonIcon
                                        className={
                                            chev1
                                                ? 'transition--down'
                                                : 'transition--up'
                                        }
                                        icon={chevronForwardOutline}
                                        style={{ fontSize: '30px' }}
                                    />
                                    {/* Use case for Icon: Depending on useState boolean, 
                                        change the css class; FAQStyle.module.css */}
                                </button>
                            </IonCol>
                        </IonRow>
                        {/* This is where vis1 div is generated */}
                        <div hidden={vis1} className={styles.faqWrap}>
                            <h3 hidden={vis1}>
                                <b>A. What is Ying?</b>
                            </h3>
                            <p hidden={vis1}>
                                &nbsp;&nbsp;&nbsp;&nbsp;Ying is a skillsharing
                                platform that creates value in the connections
                                of our networks and matches everyday
                                service-oriented needs with resources available
                                in our communities.
                            </p>
                            <h3 hidden={vis1}>
                                <b>
                                    B. What type of communities can onboard with
                                    YING?
                                </b>
                            </h3>
                            <p hidden={vis1}>
                                &nbsp;&nbsp;&nbsp;&nbsp;Enterprises, nonprofits,
                                colleges, religious institutions, clubs, etc.
                                Any group that wants to come together to
                                skillshare can onboard! You can reach out to us
                                at{' '}
                                <span>
                                    <a href='mailto:info@YINGme.co'>
                                        info@YINGme.co
                                    </a>
                                </span>{' '}
                                if you’re not sure that your organization will
                                fit.
                            </p>
                            <div className={styles.faqInnerBtn}>
                                <button
                                    hidden={vis1}
                                    className={styles.faqInnerBtn}
                                    onClick={() => {
                                        setVis1((vis) => !vis);
                                        setChev1(!chev1);
                                    }}
                                >
                                    <IonIcon
                                        icon={chevronDown}
                                        style={{ fontSize: '30px' }}
                                    />
                                    {/* No animation on bottom button, div disappears */}
                                </button>
                            </div>
                        </div>
                        <IonRow className={styles.faqWrap}>
                            {/*Second FAQ expansive div...Rinse and repeat*/}
                            <IonCol
                                sizeXs='12'
                                sizeSm='12'
                                className={styles.faqContent}
                            >
                                <h3 className={styles.faqTitle}>
                                    <b>
                                        Explore Plans <br />& Features
                                    </b>
                                </h3>
                                <button
                                    className={styles.expandQuestionBtn}
                                    onClick={() => {
                                        setChev2(!chev2);
                                        setVis2((vis) => !vis);
                                    }}
                                >
                                    <IonIcon
                                        className={
                                            chev2
                                                ? 'transition--down'
                                                : 'transition--up'
                                        }
                                        icon={chevronForwardOutline}
                                        style={{ fontSize: '30px' }}
                                    />
                                </button>
                            </IonCol>
                        </IonRow>
                        <div hidden={vis2} className={styles.faqWrap}>
                            <h6
                                hidden={vis2}
                                className={styles.faqContactCenter}
                            >
                                Contact us at{' '}
                                <span>
                                    <a href='mailto:info@YINGme.co'>
                                        info@YINGme.co
                                    </a>
                                </span>
                                .
                            </h6>
                            <div className={styles.faqInnerBtn}>
                                <button
                                    hidden={vis2}
                                    className={styles.faqInnerBtn}
                                    onClick={() => {
                                        setVis2((vis) => !vis);
                                        setChev2(!chev2);
                                    }}
                                >
                                    <IonIcon
                                        icon={chevronDown}
                                        style={{ fontSize: '30px' }}
                                    />
                                </button>
                            </div>
                        </div>
                        <IonRow className={styles.faqWrap}>
                            {/*Third FAQ expansive div...*/}
                            <IonCol
                                sizeXs='12'
                                sizeSm='12'
                                className={styles.faqContent}
                            >
                                <h3 className={styles.faqTitle}>
                                    <b>
                                        Create a New
                                        <br />
                                        Group
                                    </b>
                                </h3>
                                <button
                                    className={styles.expandQuestionBtn}
                                    onClick={() => {
                                        setChev3(!chev3);
                                        setVis3((vis) => !vis);
                                    }}
                                >
                                    <IonIcon
                                        className={
                                            chev3
                                                ? 'transition--down'
                                                : 'transition--up'
                                        }
                                        icon={chevronForwardOutline}
                                        style={{ fontSize: '30px' }}
                                    />
                                </button>
                            </IonCol>
                        </IonRow>
                        <div hidden={vis3} className={styles.faqWrap}>
                            <h3 hidden={vis3}>
                                <b>
                                    A. How can I get my group onboarded with
                                    YING?
                                </b>
                            </h3>
                            <h6
                                hidden={vis3}
                                className={styles.faqContactCenter}
                            >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <b>Enterprise and Community Groups:</b> Please
                                contact us at{' '}
                                <span>
                                    <a href='mailto:info@YINGme.co'>
                                        info@YINGme.co
                                    </a>
                                </span>
                                .
                            </h6>
                            {/* <h3 hidden={vis3}><b>B. How do I create another group?</b></h3>
                                <p hidden={vis3}>
                                    A group owner/administrator can click on “Create a New Group” from the dashboard to go to the group 
                                    onboarding page. Other members who wish to create another group can go to yingme.app/landonboard.
                                </p> */}
                            <div className={styles.faqInnerBtn}>
                                <button
                                    hidden={vis3}
                                    className={styles.faqInnerBtn}
                                    onClick={() => {
                                        setVis3((vis) => !vis);
                                        setChev3(!chev3);
                                    }}
                                >
                                    <IonIcon
                                        icon={chevronDown}
                                        style={{ fontSize: '30px' }}
                                    />
                                </button>
                            </div>
                        </div>
                        <IonRow className={styles.faqWrap}>
                            {/*Fourth FAQ expansive div...*/}
                            <IonCol
                                sizeXs='12'
                                sizeSm='12'
                                className={styles.faqContent}
                            >
                                <h3 className={styles.faqTitle}>
                                    <b>
                                        Join an Existing
                                        <br />
                                        Group
                                    </b>
                                </h3>
                                <button
                                    className={styles.expandQuestionBtn}
                                    onClick={() => {
                                        setChev4(!chev4);
                                        setVis4((vis) => !vis);
                                    }}
                                >
                                    <IonIcon
                                        className={
                                            chev4
                                                ? 'transition--down'
                                                : 'transition--up'
                                        }
                                        icon={chevronForwardOutline}
                                        style={{ fontSize: '30px' }}
                                    />
                                </button>
                            </IonCol>
                        </IonRow>
                        <div hidden={vis4} className={styles.faqWrap}>
                            <h3 hidden={vis4}>
                                <b>
                                    A. How do I invite individuals to my group
                                    after my group has onboarded?
                                </b>
                            </h3>
                            <p hidden={vis4}>
                                &nbsp;&nbsp;&nbsp;&nbsp;Please reach out to your
                                group administrator who can invite individuals
                                by providing them with a group code to onboard
                                on the app.
                            </p>
                            <h3 hidden={vis4}>
                                <b>
                                    B. Can my friend use my group code to sign
                                    up?
                                </b>
                            </h3>
                            <p hidden={vis4}>
                                &nbsp;&nbsp;&nbsp;&nbsp;Your group’s Referral
                                Code is meant to be used by specific individuals
                                belonging to your group. Please DO NOT share
                                your group’s Referral Code with outside
                                individuals unless you receive explicit
                                permission from your Group Administrator. Your
                                group administrator is directly responsible for
                                administering the group code.
                            </p>
                            <div className={styles.faqInnerBtn}>
                                <button
                                    hidden={vis4}
                                    className={styles.faqInnerBtn}
                                    onClick={() => {
                                        setVis4((vis) => !vis);
                                        setChev4(!chev4);
                                    }}
                                >
                                    <IonIcon
                                        icon={chevronDown}
                                        style={{ fontSize: '30px' }}
                                    />
                                </button>
                            </div>
                        </div>
                        <IonRow className={styles.faqWrap}>
                            {/*Fifth FAQ expansive div...*/}
                            <IonCol
                                sizeXs='12'
                                sizeSm='12'
                                className={styles.faqContent}
                            >
                                <h3 className={styles.faqTitle}>
                                    <b>
                                        Managing an Existing
                                        <br />
                                        Group
                                    </b>
                                </h3>
                                <button
                                    className={styles.expandQuestionBtn}
                                    onClick={() => {
                                        setChev5(!chev5);
                                        setVis5((vis) => !vis);
                                    }}
                                >
                                    <IonIcon
                                        className={
                                            chev5
                                                ? 'transition--down'
                                                : 'transition--up'
                                        }
                                        icon={chevronForwardOutline}
                                        style={{ fontSize: '30px' }}
                                    />
                                </button>
                            </IonCol>
                        </IonRow>
                        <div hidden={vis5} className={styles.faqWrap}>
                            <h3 hidden={vis5}>
                                <b>
                                    A. How do I remove individuals from a group?
                                </b>
                            </h3>
                            <p hidden={vis5}>
                                &nbsp;&nbsp;&nbsp;&nbsp;To remove an individual
                                from a group, a group can contact us at&nbsp;
                                <span>
                                    <a href='mailto:info@YINGme.co'>
                                        info@YINGme.co
                                    </a>
                                </span>
                                &nbsp;and our team will remove the individual
                                within 24 hours.
                            </p>
                            <h3 hidden={vis5}>
                                <b>
                                    B. If I remove an individual from a group,
                                    can they join my group again?
                                </b>
                            </h3>
                            <p hidden={vis5}>
                                &nbsp;&nbsp;&nbsp;&nbsp;If an individual is
                                removed from or leaves a group, they can rejoin
                                as long as they were not removed for any
                                violations on the platform or “report” issues.
                                To join the group again, they will need to enter
                                the group code, in “Join a New Group.”
                            </p>
                            <h3 hidden={vis5}>
                                <b>
                                    C. How do I assign an individual in my group
                                    individual as an admin or as a coordinator?
                                </b>
                            </h3>
                            <p hidden={vis5}>
                                &nbsp;&nbsp;&nbsp;&nbsp;To assign an individual,
                                please contact us at{' '}
                                <span>
                                    <a href='mailto:info@YINGme.co'>
                                        info@YINGme.co
                                    </a>
                                </span>
                                .
                            </p>
                            <h3 hidden={vis5}>
                                <b>
                                    D. Can I see Balance Token and Gratitude Pot
                                    balances of individuals within my group?
                                </b>
                            </h3>
                            <p hidden={vis5}>
                                &nbsp;&nbsp;&nbsp;&nbsp;Groups and
                                administrators cannot see balances as this is a
                                privacy issue. Only individuals can see their
                                balances.
                            </p>
                            <div className={styles.faqInnerBtn}>
                                <button
                                    hidden={vis5}
                                    className={styles.faqInnerBtn}
                                    onClick={() => {
                                        setVis5((vis) => !vis);
                                        setChev5(!chev5);
                                    }}
                                >
                                    <IonIcon
                                        icon={chevronDown}
                                        style={{ fontSize: '30px' }}
                                    />
                                </button>
                            </div>
                        </div>
                        <IonRow className={styles.faqWrap}>
                            {/*Sixth FAQ expansive div...*/}
                            <IonCol
                                sizeXs='12'
                                sizeSm='12'
                                className={styles.faqContent}
                            >
                                <h3 className={styles.faqTitle}>
                                    <b>Using YING</b>
                                </h3>
                                <button
                                    className={styles.expandQuestionBtn}
                                    onClick={() => {
                                        setChev6(!chev6);
                                        setVis6((vis) => !vis);
                                    }}
                                >
                                    <IonIcon
                                        className={
                                            chev6
                                                ? 'transition--down'
                                                : 'transition--up'
                                        }
                                        icon={chevronForwardOutline}
                                        style={{ fontSize: '30px' }}
                                    />
                                </button>
                            </IonCol>
                        </IonRow>
                        <div hidden={vis6} className={styles.faqWrap}>
                            <h3 hidden={vis6}>
                                <b>A. General Information</b>
                            </h3>
                            <h6 hidden={vis6}>
                                <ol type='i'>
                                    <li>
                                        <b>
                                            How do I edit my Profile and my
                                            skills?
                                        </b>
                                        <ul>
                                            <li>
                                                <p>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;When
                                                    a user opens the
                                                    application, you will be
                                                    brought to your home page.
                                                    Tap on “Go to Profile” once
                                                    on the profile page - The
                                                    user can tap on “Edit” if
                                                    interested in updating your
                                                    bio or skills. From here a
                                                    user can also add new
                                                    skills.
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;Users
                                                    can tap on the hamburger
                                                    menu on the top left, and
                                                    then tap on “My Profile”.
                                                    From there the user can tap
                                                    on “Edit Portfolio”. The
                                                    user can tap on “Edit” if
                                                    they want to edit their bio
                                                    or skills. From here a user
                                                    can also add new skills.
                                                </p>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <b>
                                            How do I: Search for specific
                                            individuals on the platform?
                                            Find/request individuals offering a
                                            skill that I need? Find/request
                                            individuals who need a skill that I
                                            am offering?
                                        </b>
                                        <br />
                                        <br />
                                        &nbsp;&nbsp;&nbsp;&nbsp;Look for the
                                        search icon on the navigation bar.
                                        Search for a person’s name or a skill
                                        you may be looking for. The results will
                                        yield individuals with those skills.
                                        <br />
                                        <br />
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a.&nbsp;&nbsp;&nbsp;Once
                                        the skill appears, tap on it to see
                                        individuals in your group(s) offering
                                        that skill. The user can also see
                                        related skills to the one they searched
                                        for and individuals who offer it.
                                        <br />
                                        <br />
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b.&nbsp;&nbsp;&nbsp;Once
                                        an individual’s name appears, tap on it
                                        to see their profile page. From here you
                                        can message the individual, request a
                                        skill, and see reviews.
                                        <br />
                                        <br />
                                    </li>
                                    <li>
                                        <b>
                                            How can I transfer my gratitude pot
                                            balance into my bank account?
                                        </b>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;Tap on the
                                            hamburger menu, to view and toggle
                                            between Balance Tokens and cash.
                                            Select the cash tab, to view your
                                            balance and tap on “Transfer to
                                            Bank”. Select how much you would
                                            like to transfer and enter your bank
                                            details to complete the transfer.
                                        </p>
                                    </li>
                                    <li>
                                        <b>
                                            Can I give Balance Tokens for tasks
                                            that weren't originally created in
                                            YING?
                                        </b>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;Out of
                                            fairness to all our users, Balance
                                            Tokens can only be earned and spent
                                            if they were originally created on
                                            the YING app.
                                        </p>
                                    </li>
                                    <li>
                                        <b>
                                            Can I give Balance Tokens to someone
                                            who is not a YING user?
                                        </b>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;Balance
                                            Tokens can only be earned and spent
                                            by YING users.{' '}
                                        </p>
                                    </li>
                                    <li>
                                        <b>How do I contact YING?</b>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;You can
                                            email us at{' '}
                                            <span>
                                                <a href='mailto:info@YINGme.co'>
                                                    info@YINGme.co
                                                </a>
                                            </span>
                                            .
                                            {/* or use the contact form in the dashboard “Help” sections.*/}
                                        </p>
                                    </li>
                                    <li>
                                        <b>Do my Balance Tokens ever expire?</b>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;No, Balance
                                            Tokens never expire!
                                        </p>
                                    </li>
                                    <li>
                                        <b>How does YING work with taxes?</b>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;Helping a
                                            family individual, friend or
                                            colleague is not taxable, which is
                                            why skillsharing on YING is the way
                                            to go! Cash gratuities (tips from
                                            the Gratitude pot) are taxable. YING
                                            will provide you with a year-end
                                            transaction history.
                                        </p>
                                    </li>
                                    <li>
                                        <b>
                                            Are there any skills or time sharing
                                            activities prohibited on YING?
                                        </b>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;YING has a{' '}
                                            <b>ZERO TOLERANCE</b> policy for any
                                            form of hate speech or hate group
                                            activity. Any promotion of illegal,
                                            harmful, obscene, or inappropriate
                                            activity is strictly prohibited,
                                            subject to YING’s sole discretion.
                                            {/* Please see our Community Agreements (LINK) for more information. */}
                                        </p>
                                    </li>
                                </ol>
                                <label hidden={vis6}>
                                    <b>B. TASKS</b>
                                </label>
                                <ol type='i'>
                                    <li>
                                        <b>
                                            How do I create a task if I don’t
                                            have a specific user in mind?
                                        </b>
                                        <ul>
                                            <li>
                                                <p>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;A
                                                    user can request a task from
                                                    their profile page, in the
                                                    process they can pick date,
                                                    time or select time/date
                                                    hasn’t been decided. A user
                                                    can post a task to the
                                                    group(s) they are part of to
                                                    see who can help them.
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;A
                                                    user can also request a task
                                                    from a group’s profile page,
                                                    making a task open for all
                                                    individuals in that group to
                                                    participate in.
                                                </p>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <b>
                                            How do I request to offer my skill
                                            for a posted task?
                                        </b>
                                        <ul>
                                            <li>
                                                <p>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;A
                                                    user can scroll their home
                                                    page and look under “EARN”
                                                    and this shows all different
                                                    opportunities that have been
                                                    posted by individuals from
                                                    the groups they are in. From
                                                    here a user can tap on a
                                                    task that they wish to
                                                    participate in. This will
                                                    show all details about this
                                                    task and the skills that the
                                                    task has requested. From
                                                    here the user can tap
                                                    “Participate”, this will
                                                    take them to another screen
                                                    to confirm that this is the
                                                    skill they wish to
                                                    participate for. Once the
                                                    user taps “Request
                                                    Participation” their request
                                                    gets sent to the task .
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;A
                                                    user can tap on a task from
                                                    any group’s page. From here
                                                    a user can select a task
                                                    that they wish to
                                                    participate in. This will
                                                    show all details about this
                                                    task and the skills that the
                                                    task owner has requested.
                                                    From here the user can tap
                                                    “Participate,” this will
                                                    take them to another screen
                                                    to confirm that this is the
                                                    skill they wish to
                                                    participate for. Once the
                                                    user taps “Request
                                                    Participation” their request
                                                    gets sent to the task owner.
                                                </p>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <b>How can I complete a task?</b>
                                        <ul>
                                            <li>
                                                <p>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;Once
                                                    the task has begun, the task
                                                    owner will see “Complete
                                                    Task” in their schedule.
                                                    When they tap on this, it
                                                    will take them to the next
                                                    screen where they can adjust
                                                    the time the task took. When
                                                    they tap continue, the task
                                                    owner will be able to see
                                                    the participants. From here
                                                    they can do three things:
                                                    change Balance Tokens if an
                                                    individual came late or
                                                    stayed for an extended
                                                    amount of time, remove
                                                    individuals if they didn’t
                                                    show, and add individuals.
                                                    After this the task owner
                                                    provides bi- directional
                                                    rating as well as add to
                                                    their individuals gratitude
                                                    pot if they like.
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;After
                                                    the task owner completes the
                                                    task on their end, the
                                                    individual who provided the
                                                    service will see that the
                                                    “Complete Task” is active.
                                                    From here the service
                                                    provider can confirm the
                                                    amount of time the task took
                                                    or message the task owner.
                                                    After this, the service
                                                    provider can provide a
                                                    rating and a review if they
                                                    would like.
                                                </p>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <b>
                                            As a Task Owner, how can I edit the
                                            amount of time the task took after
                                            ”completing task?”
                                        </b>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;Tap on the
                                            hamburger menu, then select
                                            “Completed Tasks” to view. When the
                                            user taps on “View Task” they can
                                            see the task description. The task
                                            owner will see “Edit Task Details”.
                                            Once they tap on this, they will be
                                            taken back to the “Completing Task”
                                            flow where they can edit the time
                                            the task took to complete.
                                        </p>
                                    </li>
                                    <li>
                                        <b>
                                            How do I rate and comment on a
                                            completed task?
                                        </b>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;While
                                            completing the task, individuals can
                                            provide bi-directional ratings and
                                            leave a comment.
                                        </p>
                                    </li>
                                    <li>
                                        <b>
                                            Can I cancel my request to
                                            participate in a task?
                                        </b>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;To cancel
                                            your request to participate in a
                                            task, tap on the task details again.
                                            Next to the skill the user
                                            requested, tap on “Cancel Request”.
                                            This will take you to a confirmation
                                            screen. From here, you can leave a
                                            message if you like before tapping
                                            “Cancel Request” a second time.
                                        </p>
                                    </li>
                                    <li>
                                        <b>
                                            Can I cancel my approval (or
                                            declining) of another user’s
                                            Participation Request?
                                        </b>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;As YING is a
                                            welcoming community for all
                                            individuals, a task owner can accept
                                            the request or select next time.
                                        </p>
                                    </li>
                                </ol>
                            </h6>
                            <div className={styles.faqInnerBtn}>
                                <button
                                    hidden={vis6}
                                    className={styles.faqInnerBtn}
                                    onClick={() => {
                                        setVis6((vis) => !vis);
                                        setChev6(!chev6);
                                    }}
                                >
                                    <IonIcon
                                        icon={chevronDown}
                                        style={{ fontSize: '30px' }}
                                    />
                                </button>
                            </div>
                        </div>
                        <IonRow className={styles.faqWrap}>
                            {/*Seventh FAQ expansive div...*/}
                            <IonCol
                                sizeXs='12'
                                sizeSm='12'
                                className={styles.faqContent}
                            >
                                <h3 className={styles.faqTitle}>
                                    <b>
                                        Understanding Roles <br />& Permissions
                                    </b>
                                </h3>
                                <button
                                    className={styles.expandQuestionBtn}
                                    onClick={() => {
                                        setChev7(!chev7);
                                        setVis7((vis) => !vis);
                                    }}
                                >
                                    <IonIcon
                                        className={
                                            chev7
                                                ? 'transition--down'
                                                : 'transition--up'
                                        }
                                        icon={chevronForwardOutline}
                                        style={{ fontSize: '30px' }}
                                    />
                                </button>
                            </IonCol>
                        </IonRow>
                        <div hidden={vis7} className={styles.faqWrap}>
                            {/* <h3 hidden={vis7}><b>A. What can an owner do?</b></h3>
                                <p hidden={vis7}>
                                An owner can access the dashboard, add members, remove members, post tasks, edit tasks, manage tasks requests, and complete tasks for the group.
                                </p>
                                <h3 hidden={vis7}><b>B. What can an admin do?</b></h3>
                                <p hidden={vis7}>
                                An admin can access the dashboard, add members, post tasks, edit tasks, manage tasks requests, and complete tasks for the group.
                                </p>
                                <h3 hidden={vis7}><b>C. What can a user/member do?</b></h3>
                                <p hidden={vis7}>
                                Users can skill share only for themselves and not for the groups.
                                </p>
                                <h3 hidden={vis7}><b>D. Who is the task owner?</b></h3>
                                <p hidden={vis7}>
                                A task owner is the person or group who posted the task. They are asking for members in their community to help them out. This person gives out Balance Tokens for the task they requested. 
                                </p> */}
                            <h3 hidden={vis7}>
                                <b>Who is a service provider/skill sharer</b>
                            </h3>
                            <p hidden={vis7}>
                                &nbsp;&nbsp;&nbsp;&nbsp;A service provider is
                                the person offering skills and/or helping
                                another individual. This person receives a
                                balance token for their efforts.
                                {/* A task owner is the person or group who posted the task. They are asking for members in their community to help them out. This person gives out Balance Tokens for the task they requested.  */}
                            </p>
                            <div className={styles.faqInnerBtn}>
                                <button
                                    hidden={vis7}
                                    className={styles.faqInnerBtn}
                                    onClick={() => {
                                        setVis7((vis) => !vis);
                                        setChev7(!chev7);
                                    }}
                                >
                                    <IonIcon
                                        icon={chevronDown}
                                        style={{ fontSize: '30px' }}
                                    />
                                </button>
                            </div>
                        </div>
                        <IonRow className={styles.faqWrap}>
                            {/*Eighth FAQ expansive div...*/}
                            <IonCol
                                sizeXs='12'
                                sizeSm='12'
                                className={styles.faqContent}
                            >
                                <h3 className={styles.faqTitle}>
                                    <b>
                                        Balance Tokens
                                        <br />& Money
                                    </b>
                                </h3>
                                <button
                                    className={styles.expandQuestionBtn}
                                    onClick={() => {
                                        setChev8(!chev8);
                                        setVis8((vis) => !vis);
                                    }}
                                >
                                    <IonIcon
                                        className={
                                            chev8
                                                ? 'transition--down'
                                                : 'transition--up'
                                        }
                                        icon={chevronForwardOutline}
                                        style={{ fontSize: '30px' }}
                                    />
                                </button>
                            </IonCol>
                        </IonRow>
                        <div hidden={vis8} className={styles.faqWrap}>
                            <h3 hidden={vis8}>
                                <b>A. What are Balance Tokens?</b>
                            </h3>
                            <p hidden={vis8}>
                                &nbsp;&nbsp;&nbsp;&nbsp;An internal “note” that
                                signifies the skillsharing transaction over a
                                period of time (1hour = 1balance token).
                            </p>
                            <h3 hidden={vis8}>
                                <b>
                                    B. Can I give Balance Tokens for tasks that
                                    weren't originally created in YING?
                                </b>
                            </h3>
                            <p hidden={vis8}>
                                &nbsp;&nbsp;&nbsp;&nbsp;Out of fairness to all
                                our users, Balance Tokens can only be earned and
                                spent if they were originally created on the
                                YING app.
                            </p>
                            <h3 hidden={vis8}>
                                <b>
                                    C. Can I give Balance Tokens to someone who
                                    is not a YING user?
                                </b>
                            </h3>
                            <p hidden={vis8}>
                                &nbsp;&nbsp;&nbsp;&nbsp;Balance Tokens can only
                                be earned and spent by YING users.
                            </p>
                            <h3 hidden={vis8}>
                                <b>D. Do my Balance Tokens ever expire?</b>
                            </h3>
                            <p hidden={vis8}>
                                &nbsp;&nbsp;&nbsp;&nbsp;No, Balance Tokens never
                                expire!
                            </p>
                            <h3 hidden={vis8}>
                                <b>E. How does time banking work with taxes?</b>
                            </h3>
                            <p hidden={vis8}>
                                &nbsp;&nbsp;&nbsp;&nbsp;Helping a family
                                individual, friend or colleague is not taxable,
                                which is why skillsharing on YING is the way to
                                go! Cash gratuities (tips from the Gratitude
                                pot) are taxable. YING will provide you with a
                                year-end transaction history.
                            </p>
                            <h3 hidden={vis8}>
                                <b>
                                    F. Can I see my member’s Balance Tokens and
                                    gratitude pot balance?
                                </b>
                            </h3>
                            <p hidden={vis8}>
                                &nbsp;&nbsp;&nbsp;&nbsp;Unfortunately, groups
                                and administrators cannot see this as it is a
                                privacy issue. Please reach out to the member.
                            </p>
                            <div className={styles.faqInnerBtn}>
                                <button
                                    hidden={vis8}
                                    className={styles.faqInnerBtn}
                                    onClick={() => {
                                        setVis8((vis) => !vis);
                                        setChev8(!chev8);
                                    }}
                                >
                                    <IonIcon
                                        icon={chevronDown}
                                        style={{ fontSize: '30px' }}
                                    />
                                </button>
                            </div>
                        </div>
                        <IonRow className={styles.faqWrap}>
                            {' '}
                            {/*Ninth FAQ expansive div...*/}
                            <IonCol
                                sizeXs='12'
                                sizeSm='12'
                                className={styles.faqContent}
                            >
                                <h3 className={styles.faqTitle}>
                                    <b>
                                        Managing Payments
                                        <br />& Payouts
                                    </b>
                                </h3>
                                <button
                                    className={styles.expandQuestionBtn}
                                    onClick={() => {
                                        setChev9(!chev9);
                                        setVis9((vis) => !vis);
                                    }}
                                >
                                    <IonIcon
                                        className={
                                            chev9
                                                ? 'transition--down'
                                                : 'transition--up'
                                        }
                                        icon={chevronForwardOutline}
                                        style={{ fontSize: '30px' }}
                                    />
                                </button>
                            </IonCol>
                        </IonRow>
                        <div hidden={vis9} className={styles.faqWrap}>
                            <h3 hidden={vis9}>
                                <b>How do I manage payments and payouts?</b>
                            </h3>
                            <h6 hidden={vis9}>
                                <ol type='i'>
                                    <li>
                                        &nbsp;&nbsp;&nbsp;&nbsp;YING partners
                                        with Stripe to manage payouts and
                                        payments for users.
                                    </li>
                                    <br />
                                    <li>
                                        &nbsp;&nbsp;&nbsp;&nbsp;By using Stripe,
                                        the user agrees to follow US tax laws
                                        and take any action to ensure laws are
                                        not broken.
                                    </li>
                                    <br />
                                    <li>
                                        &nbsp;&nbsp;&nbsp;&nbsp;At the beginning
                                        of the year, user's will have the
                                        ability to access their 1099 form to use
                                        for filing taxes. (The first business
                                        day on or after January 31).
                                    </li>
                                    <br />
                                    <li>
                                        &nbsp;&nbsp;&nbsp;&nbsp;Access to these
                                        forms will be made possible directly
                                        through the YING app/website, or through
                                        the email used to onboard onto the
                                        platform.
                                    </li>
                                    <li>
                                        <br />
                                        &nbsp;&nbsp;&nbsp;&nbsp;If you set the
                                        value of
                                        settings[payouts][schedule][interval] to
                                        manual using the Accounts API, Stripe
                                        will hold funds in the account holder’s
                                        balance until told to pay them out for
                                        up to 90 days. The exception to this
                                        limitation is the U.S., where funds can
                                        be held in their balance for up to two
                                        years. To trigger a payout of these
                                        funds, use the Payouts API.
                                    </li>
                                </ol>
                            </h6>
                            <div className={styles.faqInnerBtn}>
                                <button
                                    hidden={vis9}
                                    className={styles.faqInnerBtn}
                                    onClick={() => {
                                        setVis9((vis) => !vis);
                                        setChev9(!chev9);
                                    }}
                                >
                                    <IonIcon
                                        icon={chevronDown}
                                        style={{ fontSize: '30px' }}
                                    />
                                </button>
                            </div>
                        </div>
                        <IonRow className={styles.faqWrap}>
                            {' '}
                            {/*Ninth FAQ expansive div...*/}
                            <IonCol
                                sizeXs='12'
                                sizeSm='12'
                                className={styles.faqContent}
                            >
                                <h3 className={styles.faqTitle}>
                                    <b>
                                        Trust
                                        <br />& Safety
                                    </b>
                                </h3>
                                <button
                                    className={styles.expandQuestionBtn}
                                    onClick={() => {
                                        setChev10(!chev10);
                                        setVis10((vis) => !vis);
                                    }}
                                >
                                    <IonIcon
                                        className={
                                            chev10
                                                ? 'transition--down'
                                                : 'transition--up'
                                        }
                                        icon={chevronForwardOutline}
                                        style={{ fontSize: '30px' }}
                                    />
                                </button>
                            </IonCol>
                        </IonRow>
                        <div hidden={vis10} className={styles.faqWrap}>
                            <h3 hidden={vis10}>
                                <b>
                                    A. Are there skills or time sharing
                                    activities prohibited on YING?
                                </b>
                            </h3>
                            <p hidden={vis10}>
                                &nbsp;&nbsp;&nbsp;&nbsp;YING has a{' '}
                                <b>ZERO TOLERENCE</b> policy for any form of
                                hate speech or hate group activity. Any
                                promotion of illegal, harmful, obscene, or
                                inappropriate activity is strictly prohibited,
                                subject to YING’s sole discretion.{' '}
                                {/*Please see our Community Agreements (LINK) for more information.*/}
                            </p>
                            <h3 hidden={vis10}>
                                <b>
                                    B. What can I do to help keep my experiences
                                    on YING safe and enjoyable?
                                </b>
                            </h3>
                            <h6 hidden={vis10}>
                                &nbsp;&nbsp;&nbsp;&nbsp;Although you’ll begin
                                using the app with a trusted group of
                                colleagues, you will also become part of YING’s
                                greater online network. In addition to trusting
                                your own judgment, here are some practical tips
                                for anyone participating in online sharing
                                economies:
                                <ul>
                                    <li>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;Never give
                                            out personal information like social
                                            security numbers or passwords.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;Never
                                            respond to requests for payments or
                                            financial information.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;When meeting
                                            for the first time, do so in a
                                            public place, and let friends or
                                            family know where you are.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;Take time to
                                            review users' previous activities
                                            and comments, and always communicate
                                            through the app before meeting.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;Trust your
                                            instincts—you are the best judge of
                                            your own comfort and safety!
                                        </p>
                                    </li>
                                </ul>
                            </h6>
                            <h3 hidden={vis10}>
                                <b>
                                    C. What does YING do to support trust and
                                    safety for all its users?
                                </b>
                            </h3>
                            <h6 hidden={vis10}>
                                &nbsp;&nbsp;&nbsp;&nbsp;The safety of our YING
                                community is our priority. Although we’re not in
                                the business of micromanaging users—out of
                                respect for their good judgment and good will—we
                                have several important policies in place to
                                support safe and respectful online and offline
                                experiences:
                                <ul>
                                    <li>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <b>
                                            Bi-directional Ratings and feedback.
                                        </b>{' '}
                                        Users are encouraged to rate and provide
                                        feedback for all completed skillsharing
                                        exchanges so that others can make
                                        informed and confident decisions while
                                        using YING.
                                    </li>
                                    <br />
                                    <li>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <b>
                                            Zero tolerance for hate speech and
                                            harmful activity.
                                        </b>{' '}
                                        All users are held to the same high
                                        standard of respect for others in the
                                        community. Anyone found to engage in
                                        hate speech or harmful activity will be
                                        banned indefinitely.
                                    </li>
                                    <br />
                                    <li>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <b>Reporting.</b> Online sharing
                                        economies rely on individual users to
                                        report any safety concerns, negative
                                        experiences, or suspicious behavior.
                                        Speaking up in these situations is key
                                        to current and future YING users’ safety
                                        and well-being. Users can report issues
                                        using the YING app (“Menu” → “Settings”
                                        → “Report Abusive Behavior”) or via
                                        email at{' '}
                                        <span>
                                            <a href='mailto:info@YINGme.co'>
                                                info@YINGme.co
                                            </a>
                                        </span>
                                        .
                                    </li>
                                    <br />
                                    <li>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <b>Task transparency.</b> All YING tasks
                                        are public to the community, and users
                                        are encouraged to abide by the rule, “If
                                        you see something, say something.”
                                    </li>
                                </ul>
                            </h6>
                            <h3 hidden={vis10}>
                                <b>
                                    D. What happens if a skill provider does not
                                    show?
                                </b>
                            </h3>
                            <p hidden={vis10}>
                                &nbsp;&nbsp;&nbsp;&nbsp;We’re sorry to hear that
                                your skill provider did not show. If your task
                                hasn't been canceled yet, please try contacting
                                the skill sharer. If they don't reply, cancel
                                the task. If working with a group, please
                                contact your group admin and let them know that
                                the task was not completed. When you complete a
                                task, you can remove this skill provider so the
                                Balance Tokens won’t be disbursed. You can then
                                repost your task and find a new skill provider.
                            </p>
                            <h3 hidden={vis10}>
                                <b>
                                    E. Do skill sharers have special training or
                                    background checks?
                                </b>
                            </h3>
                            <p hidden={vis10}>
                                &nbsp;&nbsp;&nbsp;&nbsp;We do not offer special
                                training or background checks but an individual
                                can always reach out to the group owner and
                                admins. We offer bi-directional ratings, so
                                please check those out before requesting an
                                individual as well as before accepting a
                                participation request. An individual can also
                                report any instances that happen.
                            </p>
                            <div className={styles.faqInnerBtn}>
                                <button
                                    hidden={vis10}
                                    className={styles.faqInnerBtn}
                                    onClick={() => {
                                        setVis10((vis) => !vis);
                                        setChev10(!chev10);
                                    }}
                                >
                                    <IonIcon
                                        icon={chevronDown}
                                        style={{ fontSize: '30px' }}
                                    />
                                </button>
                            </div>
                        </div>
                    </IonGrid>
                    <IonGrid className='about__manifesto--container'>
                        <IonRow className='about__manifesto--wrap'>
                            <IonCol
                                sizeXs='12'
                                sizeSm='12'
                                className='about__manifesto--contactUs'
                            >
                                <p className='about__manifesto--text'>
                                    For questions, feedback & suggestions
                                    contact us at &nbsp;
                                    <span>
                                        <a href='mailto:info@YINGme.co'>
                                            info@YINGme.co
                                        </a>
                                    </span>
                                </p>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default FAQPage;
