// importing modules
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useAuth } from 'context/firebase/authContext';
// importing components
import {
    IonCol,
    IonIcon,
    IonPage,
    IonRow,
    IonGrid,
    IonButton,
    IonContent,
    IonSearchbar,
    IonHeader,
    IonToolbar,
} from '@ionic/react';
import TasksPostedList from './TasksPostedList/TasksPostedList';
import IncomingRequestList from './IncomingParticipationRequestList/ParticipationRequestList';
import CompletedIncomingRequestsList from './CompletedIncomingParticipationRequestsList/CompletedIncomingParticipationRequestList';
import OutgoingRequestList from './OutgoingParticipationRequestList/OutgoingParticipationRequestList';
import CompletedOutgoingRequestList from './CompletedOutgoingParticipationRequestList/CompletedOutgoingParticipationRequestList';
import Loading from '../../components/status/Loading';
import NavBar from '../../components/NavBar';
// importing assets
import {
    searchOutline,
    closeOutline,
    swapVerticalOutline,
    arrowBackOutline,
} from 'ionicons/icons';
// importing graphql
import { useQuery } from '@apollo/react-hooks';
import { PROFILE_JOBS_POSTED } from '../../GraphQL/Profile/PROFILE_JOBS_POSTED/PROFILE_JOBS_POSTED';
import {
    GetProfileJobsPosted,
    GetProfileJobsPostedVariables,
} from '../../GraphQL/__generated__/GetProfileJobsPosted';
//importing styles
import './TasksPosted.css';
import { Status } from '../../GraphQL/__generated__/globalTypes';

const TasksPosted: React.FC = () => {
    const history = useHistory();
    const { currentUser } = useAuth();
    const { userId } = useParams<{ userId: string }>();
    const [searchInput, setSearchInput] = useState<string>('');
    const [activeFilter, setActiveFilter] = useState<string>('posted');

    const { data, loading } = useQuery<
        GetProfileJobsPosted,
        GetProfileJobsPostedVariables
    >(PROFILE_JOBS_POSTED, {
        variables: { subId: currentUser.uid },
        fetchPolicy: 'no-cache',
    });

    if (loading) {
        return <Loading />;
    }

    let activeList;
    let title;
    let jobPostList: any;
    let filteredPostsList: any;
    // logic for filtering items to render
    if (activeFilter === 'posted') {
        title = <h1 className='tasksPosted__title'>Tasks Posted</h1>;

        jobPostList = data.profile.jobsPosted.filter(
            (pr) => pr.status !== Status.REMOVED
        );
        filteredPostsList = jobPostList.filter((jobPost) => {
            return jobPost.title
                .toLowerCase()
                .includes(searchInput.toLowerCase());
        });

        // render active filtered list
        if (searchInput.length > 0 && filteredPostsList.length > 0) {
            activeList = <TasksPostedList jobsPosted={filteredPostsList} />;
        } else {
            activeList = <TasksPostedList jobsPosted={jobPostList} />;
        }
    } else if (activeFilter === 'incoming') {
        title = <h1 className='tasksPosted__title'>Scheduled Tasks</h1>;

        jobPostList = data?.profile.jobsPosted.filter(
            (pr) =>
                pr.status === Status.ACTIVE &&
                pr.startDate !== null &&
                pr.startTime !== null
        );
        filteredPostsList = jobPostList.filter((jobPost) => {
            return jobPost.title
                .toLowerCase()
                .includes(searchInput.toLowerCase());
        });

        // render active filtered list
        if (searchInput.length > 0 && filteredPostsList.length > 0) {
            activeList = <IncomingRequestList jobsPosts={filteredPostsList} />;
        } else {
            activeList = <IncomingRequestList jobsPosts={jobPostList} />;
        }
    } else if (activeFilter === 'outgoing') {
        title = <h1 className='tasksPosted__title'>Outgoing Requests</h1>;

        jobPostList = data?.profile.outgoingParticipationRequests.filter(
            (pr) =>
                pr.status === Status.ACCEPTED ||
                pr.status === Status.RECIPIENT_HAS_PROPOSED_OFFER
        );
        filteredPostsList = jobPostList.filter((jobPost) => {
            return jobPost.skill.name
                .toLowerCase()
                .includes(searchInput.toLowerCase());
        });

        // render active filtered list
        if (searchInput.length > 0 && filteredPostsList.length > 0) {
            activeList = (
                <OutgoingRequestList
                    participationRequests={filteredPostsList}
                />
            );
        } else {
            activeList = (
                <OutgoingRequestList participationRequests={jobPostList} />
            );
        }
    } else if (activeFilter === 'completedIncoming') {
        title = <h1 className='tasksPosted__title'>Completed Tasks</h1>;

        jobPostList = data?.profile.incomingParticipationRequests.filter(
            (pr) =>
                pr.status === Status.COMPLETED ||
                pr.status === Status.RECIPIENT_HAS_PROPOSED_OFFER
        );
        filteredPostsList = jobPostList.filter((jobPost) => {
            return jobPost.skill.name
                .toLowerCase()
                .includes(searchInput.toLowerCase());
        });

        // render active filtered list
        if (searchInput.length > 0 && filteredPostsList.length > 0) {
            activeList = (
                <CompletedIncomingRequestsList
                    participationRequests={filteredPostsList}
                />
            );
        } else {
            activeList = (
                <CompletedIncomingRequestsList
                    participationRequests={jobPostList}
                />
            );
        }
    } else if (activeFilter === 'completedOutgoing') {
        title = <h1 className='tasksPosted__title'>Completed Requests</h1>;

        jobPostList = data?.profile.outgoingParticipationRequests.filter(
            (pr) =>
                pr.status === Status.COMPLETED ||
                pr.status === Status.RECIPIENT_HAS_PROPOSED_OFFER
        );
        filteredPostsList = jobPostList.filter((jobPost) => {
            return jobPost.skill.name
                .toLowerCase()
                .includes(searchInput.toLowerCase());
        });

        // render active filtered list
        if (searchInput.length > 0 && filteredPostsList.length > 0) {
            activeList = (
                <CompletedOutgoingRequestList
                    participationRequests={filteredPostsList}
                />
            );
        } else {
            activeList = (
                <CompletedOutgoingRequestList
                    participationRequests={jobPostList}
                />
            );
        }
    }

    return (
        <IonPage>
            <IonHeader className='tasksPosted-header'>
                <IonToolbar className='tasksPosted-toolbar'>
                    <IonSearchbar
                        className='tasksPosted-ionSearchBar'
                        search-icon={searchOutline}
                        clear-icon={closeOutline}
                        placeholder='Search by name or skill'
                        value={searchInput}
                        onIonChange={(event) =>
                            setSearchInput(event.detail.value)
                        }
                    >
                        <IonIcon
                            onClick={() => history.goBack()}
                            className='tasksPosted__close--btn'
                            icon={arrowBackOutline}
                        />
                    </IonSearchbar>
                    <IonCol
                        sizeSm='12'
                        sizeXs='12'
                        className='tasksPosted__filters--container'
                    >
                        <div className='tasksPosted__filters--wrap'>
                            <IonButton
                                onClick={() => setActiveFilter('posted')}
                                className={
                                    activeFilter === 'posted'
                                        ? 'tasksPosted__filters--btn--active'
                                        : 'tasksPosted__filters--btn'
                                }
                                color='ion-primary'
                                fill='clear'
                            >
                                Tasks Posted
                            </IonButton>
                            <IonButton
                                onClick={() => setActiveFilter('incoming')}
                                className={
                                    activeFilter === 'incoming'
                                        ? 'tasksPosted__filters--btn--active'
                                        : 'tasksPosted__filters--btn'
                                }
                                color='ion-primary'
                                fill='clear'
                            >
                                Scheduled Tasks
                            </IonButton>
                            <IonButton
                                onClick={() => setActiveFilter('outgoing')}
                                className={
                                    activeFilter === 'outgoing'
                                        ? 'tasksPosted__filters--btn--active'
                                        : 'tasksPosted__filters--btn'
                                }
                                color='ion-primary'
                                fill='clear'
                            >
                                Scheduled Outgoing Participation Requests
                            </IonButton>
                            <IonButton
                                onClick={() =>
                                    setActiveFilter('completedIncoming')
                                }
                                className={
                                    activeFilter === 'completedIncoming'
                                        ? 'tasksPosted__filters--btn--active'
                                        : 'tasksPosted__filters--btn'
                                }
                                color='ion-primary'
                                fill='clear'
                            >
                                Completed Tasks Posted
                            </IonButton>
                            <IonButton
                                onClick={() =>
                                    setActiveFilter('completedOutgoing')
                                }
                                className={
                                    activeFilter === 'completedOutgoing'
                                        ? 'tasksPosted__filters--btn--active'
                                        : 'tasksPosted__filters--btn'
                                }
                                color='ion-primary'
                                fill='clear'
                            >
                                Completed Outgoing Participation Requests
                            </IonButton>
                        </div>
                    </IonCol>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonGrid className='tasksPosted__container'>
                    {/* <IonRow className='tasksPosted__searchbar'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='tasksPosted__searchbar--container'
                        >
                            <div className='tasksPosted__searchbar--input--wrap'>
                                <IonIcon
                                    className='tasksPosted__searchbar--input--icon'
                                    icon={searchOutline}
                                />
                                <input
                                    onChange={(event) =>
                                        setSearchInput(event.target.value)
                                    }
                                    value={searchInput}
                                    type='text'
                                    placeholder='Search by name or skill'
                                    className='tasksPosted__searchbar--input'
                                />
                            </div>
                            <IonButton
                                className='tasksPosted__close--btn'
                                onClick={() => {
                                    setSearchInput('');
                                }}
                                color='ion-primary'
                            >
                                <IonIcon
                                    icon={closeOutline}
                                />
                            </IonButton>
                        </IonCol>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className="tasksPosted__filters--container"
                        >
                            <div className="tasksPosted__filters--wrap">
                                <IonButton 
                                    onClick={() => setActiveFilter('posted')}
                                    className={
                                        activeFilter === 'posted' 
                                        ? 'tasksPosted__filters--btn--active' 
                                        : 'tasksPosted__filters--btn'
                                    }
                                    color="ion-primary" 
                                    fill="clear"
                                >
                                    Tasks Posted
                                </IonButton>
                                <IonButton
                                    onClick={() => setActiveFilter('incoming')}
                                    className={
                                        activeFilter === 'incoming' 
                                        ? 'tasksPosted__filters--btn--active' 
                                        : 'tasksPosted__filters--btn'
                                    }
                                    color="ion-primary" 
                                    fill="clear"
                                >
                                    Scheduled Tasks
                                </IonButton>
                                <IonButton 
                                    onClick={() => setActiveFilter('outgoing')}
                                    className={
                                        activeFilter === 'outgoing' 
                                        ? 'tasksPosted__filters--btn--active' 
                                        : 'tasksPosted__filters--btn'
                                    }
                                    color="ion-primary" 
                                    fill="clear"
                                >
                                    Scheduled Outgoing Participation Requests
                                </IonButton>
                                <IonButton
                                    onClick={() => setActiveFilter('completedIncoming')}
                                    className={
                                        activeFilter === 'completedIncoming' 
                                        ? 'tasksPosted__filters--btn--active' 
                                        : 'tasksPosted__filters--btn'
                                    }
                                    color="ion-primary" 
                                    fill="clear"
                                >
                                    Completed Tasks Posted
                                </IonButton>
                                <IonButton
                                    onClick={() => setActiveFilter('completedOutgoing')}
                                    className={
                                        activeFilter === 'completedOutgoing' 
                                        ? 'tasksPosted__filters--btn--active' 
                                        : 'tasksPosted__filters--btn'
                                    }
                                    color="ion-primary" 
                                    fill="clear"
                                >
                                    Completed Outgoing Participation Requests
                                </IonButton>
                            </div>
                        </IonCol>
                    </IonRow> */}
                    <IonRow className='tasksPostedItem__row'>
                        <IonCol
                            className='tasksPosted__title--wrap'
                            sizeSm='12'
                            sizeXs='12'
                        >
                            {title}
                            <IonButton
                                color='ion-primary'
                                className='tasksPosted__btn'
                                fill='clear'
                            >
                                <IonIcon
                                    className='tasksPosted__btn--icon'
                                    icon={swapVerticalOutline}
                                />
                                Sort
                            </IonButton>
                        </IonCol>
                        {activeList}
                    </IonRow>
                </IonGrid>
                <NavBar />
            </IonContent>
        </IonPage>
    );
};

export default TasksPosted;
