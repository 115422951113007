// importing modules & hooks
import React, { useRef, useState } from 'react';
import { useAuth } from 'context/firebase/authContext';
import { useOnboardingFlow } from 'utils/onboarding';
import { useMutation } from '@apollo/react-hooks';
// importing components
import {
    IonButton,
    IonCol,
    IonGrid,
    IonIcon,
    IonRow,
    IonSpinner,
} from '@ionic/react';
import ImageCropperModal from 'components/ImgCropper/ImageCropperModal';
import BackButton from 'components/BackButton';
import Strokes from 'components/Strokes';
// importing assets
import { cloudUpload } from 'ionicons/icons';
// graphql queries
import {
    UPDATE_PHOTO_BY_UID,
    UPDATE_PHOTO_BY_UIDVariables,
} from 'GraphQL/__generated__/UPDATE_PHOTO_BY_UID';
import { gql_UPDATE_PHOTO_BY_UID } from 'GraphQL/Profile/UPDATE_PHOTO_BY_UID/UPDATE_PHOTO_BY_UID';
import { handleImageUpload } from 'helpers/imageHandling/ImageHandlerForCropper';
// importing styles
import styles from './ImageUpload.module.css';

// edit me to change me
const PROFILE_PIC_SZ = 400;

const averagePixels = (
    data: Uint8ClampedArray, // the pixels, as in an ImageData
    indices: number[] // indices of the first field of each pixel to avg
) =>
    indices
        // make tuple of indices of each pixel field
        .map((i) => [i, i + 1, i + 2, i + 3])
        // replace the tuple's indices with the data at those indices
        .map((is) => is.map((i) => data[i]))
        // get the average pixel value
        .reduce(
            // px is each pixel in the set
            // a is the accumulator pixel (the one holding the running average)
            // x is each field in the accumulator pixel
            // i is 0, 1, 2, 3 ==> the index of that accumulator field
            (a, px) => a.map((x, i) => x + px[i] / indices.length),
            [0, 0, 0, 0]
        );

const squarifyImageFromDataURL = async (dataURL: string) => {
    // get loaded image element for access to data and width/height
    const img: HTMLImageElement = await new Promise((resolve, reject) => {
        const img = document.createElement('img');
        img.onload = (e) => resolve(e.target as HTMLImageElement);
        img.src = dataURL;
        setTimeout(reject, 30000); // fail after 30 seconds
    });

    // get the ImageData from the canvas
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);
    const idata = ctx.getImageData(0, 0, img.width, img.height);

    // get the indices of the image edge pixels
    // (width is *4 because they're in the ImageData array as
    //  just [r, g, b, a, r, g, b, a, ...]
    const rowWidth = img.width * 4;
    const leftPxs = () =>
        // start with array of row indices
        Array.from(Array(img.height).keys())
            // get beginning index of each row
            .map((i) => i * rowWidth);

    const rightPxs = () =>
        Array.from(Array(img.height).keys())
            // get index 4 away from end of row for each row
            .map((i) => i * rowWidth + rowWidth - 4);

    const topPxs = () =>
        Array.from(Array(img.width).keys())
            // get every fourth i
            .map((i) => i * 4);

    const bottomPxs = () =>
        Array.from(Array(img.width).keys())
            .map((i) => i * 4)
            // add index of beginning of last row
            .map((i) => i + img.height * rowWidth - rowWidth);

    // make it square
    canvas.width = canvas.height = PROFILE_PIC_SZ;
    const half = PROFILE_PIC_SZ / 2;

    // we're taking the average of the whole border, so merge all
    // of the indices
    // (having duplicated corners is ok lol)
    const pxs = leftPxs().concat(rightPxs(), topPxs(), bottomPxs());
    const bgC = averagePixels(idata.data, pxs);

    // fill bg
    ctx.fillStyle = `rgb(${bgC[0]}, ${bgC[1]}, ${bgC[2]})`;
    ctx.fillRect(0, 0, PROFILE_PIC_SZ, PROFILE_PIC_SZ);

    // landscape
    if (img.width > img.height) {
        /*
        // draw top bg
        const topC = averagePixels(idata.data, topPxs());
        ctx.fillStyle = `rgb(${topC[0]}, ${topC[1]}, ${topC[2]})`;
        ctx.fillRect(0, 0, PROFILE_PIC_SZ, half);
        // draw bottom bg
        const botC = averagePixels(idata.data, bottomPxs());
        ctx.fillStyle = `rgb(${botC[0]}, ${botC[1]}, ${botC[2]})`;
        ctx.fillRect(0, half, PROFILE_PIC_SZ, half);
        */
        // draw image
        const dHeight = (img.height / img.width) * PROFILE_PIC_SZ;
        const dy = half - dHeight / 2;
        ctx.drawImage(img, 0, dy, PROFILE_PIC_SZ, dHeight);
        // portrait
    } else {
        /*
        // draw left bg
        const leftC = averagePixels(idata.data, leftPxs());
        ctx.fillStyle = `rgb(${leftC[0]}, ${leftC[1]}, ${leftC[2]})`;
        ctx.fillRect(0, 0, half, PROFILE_PIC_SZ);
        // draw right bg
        const rightC = averagePixels(idata.data, rightPxs());
        ctx.fillStyle = `rgb(${rightC[0]}, ${rightC[1]}, ${rightC[2]})`;
        ctx.fillRect(half, 0, half, PROFILE_PIC_SZ);
        */
        // draw image
        const dWidth = (img.width / img.height) * PROFILE_PIC_SZ;
        const dx = half - dWidth / 2;
        ctx.drawImage(img, dx, 0, dWidth, PROFILE_PIC_SZ);
    }

    return canvas.toDataURL();
};

const ImageUpload = () => {
    const { currentUser } = useAuth();
    // reference file input
    const filePickerRef = useRef<HTMLInputElement>(null);
    // image-cropper modal state
    const [showImageCropper, setShowImageCropper] = useState<boolean>(false);
    // img preview (as data url)
    const [imgPreview, setImgPreview] = useState<string>('');
    // img blob -> blob to upload to server
    //const [imgBlob, setImgBlob] = useState<Blob>();
    // whether to disable buttons and add spinner
    const [uploadingImage, setUploadingImage] = useState<boolean>(false);
    // this is a kludge for a weird bug I don't understand
    const [showContinueBtn, setShowContinueBtn] = useState<boolean>(true);
    const { next } = useOnboardingFlow();

    const [
        updatePhoto,
        {
            data: updatePhotoData,
            loading: updatePhotoLoading,
            error: updatePhotoError,
        },
    ] = useMutation<UPDATE_PHOTO_BY_UID, UPDATE_PHOTO_BY_UIDVariables>(
        gql_UPDATE_PHOTO_BY_UID
    );

    // handles image cropper
    const handleCropperModal = () => setShowImageCropper((show) => !show);
    // references file input on click
    const handleOpenFilePicker = () => filePickerRef.current?.click();

    const handlePickFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        // handles selecting a file
        const file = event.target!.files![0];
        // returning nothing if no file was selected
        if (!file) return;
        const fr = new FileReader();
        fr.onload = async () => {
            const dataURL = fr.result!.toString();
            const squarifiedDataURL = await squarifyImageFromDataURL(dataURL);
            // @ts-ignore
            setImgPreview(squarifiedDataURL);
        };
        fr.readAsDataURL(file);
    };

    const handleCroppedImageUpload = async () => {
        setUploadingImage(true);

        // convert to image blob
        const imgBlob = await fetch(imgPreview).then((res) => res.blob());

        // handles uploading an image to the server
        handleImageUpload(currentUser, imgBlob, updatePhoto)
            .then(() => setShowContinueBtn(false))
            .then(() => {
                // next takes the onboarding section that we're on now, so that in
                // case this gets called multiple times it only does side effects
                // once
                next('/onboarding/imageUpload');
            })
            .catch((e) => console.error(e));
    };

    const continueBtn = (
        <IonButton
            fill='clear'
            disabled={imgPreview.length === 0}
            color='ion-primary'
            onClick={() => {
                if (!uploadingImage) handleCroppedImageUpload();
            }}
            className={styles.continueBtn}
        >
            {uploadingImage ? (
                <>
                    Uploading...&nbsp;
                    <IonSpinner color='ion-primary' />
                </>
            ) : (
                <>Continue</>
            )}
        </IonButton>
    );

    return (
        <>
            <Strokes green={false} />
            {/*<BackButton count={2} />*/}
            <IonGrid className={styles.grid}>
                {/* row stretched 100% */}
                <IonRow className={styles.row}>
                    <ImageCropperModal
                        //setImgBlob={blob => setImgPreview(blob.toDataURL())}
                        setImgBlob={() => {}}
                        imgToCrop={imgPreview}
                        setImgToCrop={() => {}}
                        setImgPreview={setImgPreview}
                        showCropperModal={showImageCropper}
                        handleCropperModal={handleCropperModal}
                    />
                    {/* content centered via container */}
                    <IonCol
                        sizeSm='12'
                        sizeXs='12'
                        className={styles.container}
                    >
                        {/* content starts here */}
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className={styles.content}
                        >
                            <h1>
                                Next,<span> Upload a Pic</span>
                            </h1>
                            <p className={styles.text}>
                                Since YING is a community-based platform, we
                                require everyone to upload a pic.
                            </p>
                            {imgPreview ? (
                                <div
                                    onClick={handleOpenFilePicker}
                                    className={styles.preview}
                                >
                                    <img src={imgPreview} />
                                </div>
                            ) : (
                                <IonButton
                                    fill='clear'
                                    onClick={handleOpenFilePicker}
                                    color='ion-primary'
                                    className={styles.uploadBtn}
                                >
                                    <IonIcon
                                        className={styles.uploadIcon}
                                        icon={cloudUpload}
                                    />
                                </IonButton>
                            )}
                            <input
                                type='file'
                                name='fileUpload'
                                accept='image/*'
                                hidden
                                ref={filePickerRef}
                                onChange={handlePickFile}
                            />
                            {imgPreview.length > 0 ? (
                                <p className={styles.uploadText}>
                                    TAP ON IMAGE ABOVE
                                </p>
                            ) : (
                                <p className={styles.uploadText}>
                                    TAP ON ICON ABOVE
                                </p>
                            )}
                            <IonButton
                                fill='clear'
                                disabled={
                                    uploadingImage || imgPreview.length === 0
                                }
                                color='ion-primary'
                                onClick={() => setShowImageCropper(true)}
                            >
                                Crop Image
                            </IonButton>
                            {showContinueBtn && continueBtn}
                        </IonCol>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </>
    );
};

export default ImageUpload;
