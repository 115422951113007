// importing modules
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
// importing components
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonIcon,
    IonImg,
    IonLabel,
    IonModal,
    IonRow,
} from '@ionic/react';
import UserImg from '../UI/UserImg/UserImg';
import GroupImg from '../UI/GroupImg/GroupImg';
import Strokes from 'components/Strokes';
// importing assets
import { arrowBackOutline, closeOutline } from 'ionicons/icons';
// importing helpers
import {
    getLocalStorage,
    setLocalStorage,
} from '../../../helpers/localStorage/localStorageHelper';
import { Status } from '../../../GraphQL/__generated__/globalTypes';
// importing styles
import '../SkillInfoModal/SkillInfoModal.css';

type Skill = {
    name: string;
    description: string;
    additionalDetails: string;
    peopleNeededCount: string;
    // taskDuration: string
};

const SkillInfoModal: React.FC<{
    handleModal: () => void;
    modalStatus: boolean;
    skillToUpdate: Skill;
    handleUpdateMySkill: Function;
    taskType: string;
}> = ({
    modalStatus,
    handleModal,
    skillToUpdate,
    handleUpdateMySkill,
    taskType,
}) => {
    const history = useHistory();
    const [skillT, setSkillT] = useState<string>('');
    const [skillD, setSkillD] = useState<string>('');
    const [peopleN, setPeopleN] = useState<number>(
        parseInt(skillToUpdate.peopleNeededCount)
    );
    const [taskD, setTaskD] = useState<string>('');

    useEffect(() => {
        setSkillT(skillToUpdate.description);
        setSkillD(skillToUpdate.additionalDetails);
        setPeopleN(parseInt(skillToUpdate.peopleNeededCount));
        return () => {};
    }, [
        skillToUpdate.additionalDetails,
        skillToUpdate.description,
        skillToUpdate.peopleNeededCount,
    ]);

    const handleSkillType = (event: React.ChangeEvent<HTMLInputElement>) =>
        setSkillT(event.target.value);
    const handleSkillDetails = (
        event: React.ChangeEvent<HTMLTextAreaElement>
    ) => setSkillD(event.target.value);
    const handlePeopleNeeded = (event: React.ChangeEvent<HTMLInputElement>) =>
        setPeopleN(parseInt(event.target.value));
    const handleTaskDuration = (event: React.ChangeEvent<HTMLInputElement>) =>
        setTaskD(event.target.value);

    const handleSkillUpdate = () => {
        const updatedSkill = {
            description: skillT,
            additionalDetails: skillD,
            peopleNeededCount: peopleN,
            status: Status.OPEN,
        };
        handleUpdateMySkill(skillToUpdate.name, updatedSkill);
    };

    let img;
    if (taskType === 'HOME' && getLocalStorage('currentAdminGroupId') == 0) {
        img = <UserImg />;
    } else if (
        taskType === 'GROUP' &&
        getLocalStorage('currentAdminGroupId') == 0
    ) {
        img = <UserImg />;
    } else {
        img = <GroupImg />;
    }

    return (
        <IonModal isOpen={modalStatus}>
            <IonContent>
                <IonGrid>
                    {/* skillInfo header */}
                    <IonRow className='skillInfo__header'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='skillInfo__header--container'
                        >
                            <Strokes top={true} />
                            <IonCol
                                sizeXs='12'
                                sizeSm='12'
                                sizeMd='12'
                                className='completeTaskNav__btns--wrap'
                            >
                                <IonButton
                                    className='completeTaskNav__btn'
                                    onClick={handleModal}
                                    fill='clear'
                                    color='ion-primary'
                                >
                                    <IonIcon
                                        className='completeTaskNav__btn--icon'
                                        icon={arrowBackOutline}
                                    />
                                </IonButton>
                                <IonButton
                                    className='completeTaskNav__btn'
                                    onClick={() => {
                                        handleModal();
                                        const createTask = {
                                            taskName: '',
                                            taskDescr: '',
                                            date: null,
                                            startTime: '',
                                            endTime: '',
                                            location: '',
                                            locationPreference: '',
                                            skillsNeeded: [],
                                            groupCodes: [],
                                            memberInvites: [],
                                        };
                                        setLocalStorage(
                                            'createTask',
                                            createTask
                                        );
                                        history.push('/home');
                                    }}
                                    color='ion-primary'
                                    fill='clear'
                                >
                                    <IonIcon
                                        className='completeTaskNav__btn--icon'
                                        icon={closeOutline}
                                    />
                                </IonButton>
                            </IonCol>
                            {img}
                        </IonCol>
                    </IonRow>
                    {/* text content */}
                    <IonRow className='skillInfo__textContent'>
                        <IonCol
                            className='skillInfo__textContent--container'
                            sizeSm='12'
                            sizeXs='12'
                        >
                            <h1 className='skillInfo__textContent--title'>
                                Describe your need in detail
                            </h1>
                            <div className='skillInfo__textContent--badge'>
                                <p className='skillInfo__textContent--badge--text'>
                                    {skillToUpdate?.name}
                                </p>
                            </div>
                        </IonCol>
                    </IonRow>
                    {/* skill def form */}
                    <IonGrid className='skillInfo__form--container'>
                        <IonRow className='skillInfo__form--row'>
                            <IonCol
                                className='skillInfo__form--input--wrap'
                                sizeXs='12'
                                sizeSm='12'
                            >
                                <IonLabel className='skillInfo__form--label'>
                                    Type of {skillToUpdate?.name}
                                </IonLabel>
                                <input
                                    onChange={handleSkillType}
                                    defaultValue={skillToUpdate.description}
                                    className='skillInfo__form--input'
                                    placeholder='Ex: Instagram post designer...'
                                    type='text'
                                    required
                                    name='skillType'
                                />
                            </IonCol>
                            <IonCol
                                className='skillInfo__form--input--wrap'
                                sizeXs='12'
                                sizeSm='12'
                            >
                                <IonLabel className='skillInfo__form--label'>
                                    What should they know how to do?
                                </IonLabel>
                                <textarea
                                    onChange={handleSkillDetails}
                                    className='skillInfo__form--textarea'
                                    placeholder='Ex: Experience with social posting…'
                                    defaultValue={
                                        skillToUpdate.additionalDetails
                                    }
                                    required
                                ></textarea>
                            </IonCol>
                            <IonCol
                                className='skillInfo__form--inputs--container'
                                sizeXs='12'
                                sizeSm='12'
                            >
                                <IonCol
                                    className='skillInfo__form--input--wrap'
                                    sizeXs='12'
                                    sizeSm='12'
                                >
                                    <IonLabel className='skillInfo__form--label'>
                                        How Many People?
                                    </IonLabel>
                                    <input
                                        onChange={handlePeopleNeeded}
                                        defaultValue={
                                            skillToUpdate.peopleNeededCount
                                        }
                                        className='skillInfo__form--input'
                                        placeholder='Ex: 2'
                                        type='number'
                                        required
                                    />
                                </IonCol>
                                {/* <IonCol className="skillInfo__form--input--wrap" sizeXs="6" sizeSm="6">
                                    <IonLabel className="skillInfo__form--label">For How Long?</IonLabel>
                                    <input 
                                        onChange={handleTaskDuration}
                                        defaultValue={skillToUpdate.taskDuration}
                                        className="skillInfo__form--input" 
                                        placeholder="Ex: 1" 
                                        type="number" 
                                        required
                                    />
                                </IonCol> */}
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                    {/* btn */}
                    <IonRow className='skillInfo__btn--container'>
                        <IonCol
                            className='skillInfo__btn--wrap'
                            sizeXs='12'
                            sizeSm='12'
                        >
                            <IonButton
                                onClick={() => {
                                    handleSkillUpdate();
                                    handleModal();
                                }}
                                fill='clear'
                                // disabled={!(parseInt(peopleN) > 0 && parseInt(taskD) > 0)}
                                disabled={!(peopleN > 0)}
                                color='ion-primary'
                                className='skillInfo__btn--add'
                            >
                                Update
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonModal>
    );
};

export default SkillInfoModal;
