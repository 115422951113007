// importing modules
import React from 'react';
// importing components
import { IonButton, IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react';
// importing assets
import { funnelOutline } from 'ionicons/icons';
import MemberTile from '../../../components/Tile/MemberTile';
// importing types
import { SkillPoolFromOneOrg_profilesFromOneOrg } from '../../../GraphQL/__generated__/SkillPoolFromOneOrg';

const MemberList: React.FC<{
    communityMembers: SkillPoolFromOneOrg_profilesFromOneOrg[];
}> = ({ communityMembers }) => {
    return (
        <IonGrid>
            <IonRow className='genSearch__nearbyMembers--container'>
                <IonCol
                    className='genSearch__nearbyMembers--text--wrap'
                    sizeSm='8'
                    sizeXs='8'
                >
                    <h3 className='genSearch__nearbyMembers--title'>
                        Members in Your{' '}
                        <span
                            style={{ color: '#44c983', borderBottom: 'none' }}
                        >
                            Community
                        </span>
                    </h3>
                </IonCol>
                <IonCol
                    className='genSearch__nearbyMembers--btn--wrap'
                    sizeSm='3'
                    sizeXs='3'
                >
                    <IonButton
                        fill='clear'
                        color='ion-primary'
                        className='genSearch__nearbyMembers--btn'
                    >
                        <IonIcon slot='start' icon={funnelOutline} /> Filter
                    </IonButton>
                </IonCol>
            </IonRow>
            {/*community members*/}
            <IonRow className='nearbyMember__row'>
                {communityMembers.length > 0 ? (
                    communityMembers.map((member) => (
                        <MemberTile key={member.id} {...member} />
                    ))
                ) : (
                    <IonRow className='nearbyMember__row'>
                        <p>Invite members to join your group!</p>
                    </IonRow>
                )}
            </IonRow>
        </IonGrid>
    );
};

export default MemberList;
