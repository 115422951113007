// importing modules
import React from 'react';
import { useHistory } from 'react-router-dom';
// importing components
import {
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonRow,
    IonPage,
} from '@ionic/react';
import ConfirmationScreenLogo from '../../components/ConfirmationScreenLogo/ConfirmationScreenLogo';
import FlowNavigation from '../../components/FlowNavigator/FlowNavigator';
// importing assets
// importing styles
import './TransactionWall.css';

import Strokes from '../../components/Strokes';

const TransactionWall: React.FC<{
    title: string;
    text: string;
}> = ({ title, text }) => {
    const history = useHistory();

    return (
        <IonPage>
            <IonContent>
                <IonGrid className='transactionWall__grid'>
                    <FlowNavigation disablePrevStep={true} prevPage='' />
                    <IonRow className='transactionWall'>
                        <IonCol
                            sizeXs='12'
                            sizeSm='12'
                            className='transactionWall__container'
                        >
                            <ConfirmationScreenLogo />
                            <Strokes />
                            <h1 className='transactionWall__title'>{title}</h1>
                            <p className='transactionWall__text'>{text}</p>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default TransactionWall;
