import React from 'react';
import { useHistory } from 'react-router-dom';
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonPage,
    IonRow,
} from '@ionic/react';
import yingLogo from '../../assets/ying-logo-black@3x.png';
import '../LandingCommunityOnboard/CommunityOnboardLanding.css';

const CommunityOnboardingLanding: React.FC = () => {
    const history = useHistory();
    return (
        <IonPage>
            <IonContent>
                <IonGrid>
                    {/* header */}
                    <IonRow className='landing__header'>
                        <IonCol
                            className='landing__header--logo--wrap'
                            sizeXs='12'
                            sizeSm='12'
                            sizeMd='12'
                            sizeLg='12'
                            sizeXl='12'
                        >
                            <img
                                className='landing__header--logo'
                                src={yingLogo}
                                alt='ying logo'
                            />
                        </IonCol>
                    </IonRow>
                    {/* content */}
                    <div className='landing__content--container'>
                        <div className='landing__text--wrap'>
                            <h1 className='landing__text--title'>
                                <span className='landing__title--span'>
                                    Welcome
                                </span>
                                , begin your
                                <br />
                                group’s skill sharing
                                <br />
                                journey on YING
                            </h1>
                            <p className='landing__text--text'>
                                Create an admin account as well as a<br />
                                group. You will be required to enter an
                                <br />
                                access code to do this.
                            </p>
                            <IonButton
                                onClick={() =>
                                    history.push('/communityOnboarding')
                                }
                                fill='clear'
                                color='ion-primary'
                                className='landing__content--btn'
                            >
                                Lets Get Started
                            </IonButton>
                        </div>
                    </div>
                    <div className='landing__policy--link--container'>
                        <div className='landing__policy--link--wrap'>
                            <a className='landing__policy--link' href='#'>
                                Privacy Policy
                            </a>
                            &nbsp;&nbsp;
                            <a className='landing__policy--link' href='#'>
                                Terms & Conditions
                            </a>
                            &nbsp;&nbsp;
                            <a className='landing__policy--link' href='#'>
                                Contact Us
                            </a>
                        </div>
                    </div>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};
export default CommunityOnboardingLanding;
