// importing modules
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
// importing components
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonPage,
    IonRow,
} from '@ionic/react';
import Loading from 'components/status/Loading';
import BackButton from 'components/BackButton';
import Strokes from 'components/Strokes';
// importing assets
// importing types & graphql
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_PARTICIPATION_REQUEST_BY_ID } from 'GraphQL/ParticipationRequest/GET_PARTICIPATION_REQUEST_BY_ID/GET_PARTICIPATION_REQUEST_BY_ID';
import {
    GetParticipationRequestById,
    GetParticipationRequestByIdVariables,
} from 'GraphQL/__generated__/GetParticipationRequestById';
import { GET_JOB_POST_BY_ID } from 'GraphQL/JobPost/GET_JOB_POST_BY_ID/GET_JOB_POST_BY_ID';
import { UPDATE_ONE_PARTICIPATION_REQUEST_STATUS } from 'GraphQL/ParticipationRequest/UPDATE_ONE_PARTICIPATION_REQUEST_STATUS/UPDATE_ONE_PARTICIPATION_REQUEST_STATUS';
import {
    UpdateOneParticipationRequestStatus,
    UpdateOneParticipationRequestStatusVariables,
} from 'GraphQL/__generated__/UpdateOneParticipationRequestStatus';
import { Status } from 'GraphQL/__generated__/globalTypes';
// importing styles
import './CancelParticipationRequest.css';

export default () => {
    const { taskId } = useParams<{ taskId: string }>();
    const history = useHistory();

    const [participationRequest, participationRequestDatas] = useMutation<
        UpdateOneParticipationRequestStatus,
        UpdateOneParticipationRequestStatusVariables
    >(UPDATE_ONE_PARTICIPATION_REQUEST_STATUS);

    const handleParticipationRequest = () => {
        participationRequest({
            variables: {
                id: parseInt(taskId),
                status: Status.CANCELED,
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GET_JOB_POST_BY_ID,
                    variables: {
                        jobPostId: parseInt(taskId),
                    },
                },
            ],
        })
            .then((res) => {
                history.push(
                    `/cancel/participationRequest/${taskId}/confirmation`
                );
            })
            .catch((err) => console.error(err));
    };

    const { data, loading } = useQuery<
        GetParticipationRequestById,
        GetParticipationRequestByIdVariables
    >(GET_PARTICIPATION_REQUEST_BY_ID, {
        variables: { id: parseInt(taskId) },
        fetchPolicy: 'cache-and-network',
    });

    if (loading) {
        return <Loading />;
    }

    return (
        <IonPage>
            <IonContent>
                <IonGrid>
                    <IonRow className='cancelTaskP__header'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='cancelTaskP__header--container'
                        >
                            <BackButton color='white' />
                            <Strokes top={true} />
                            <IonImg
                                className='createTask__header--img'
                                src={
                                    data.participationRequest.recipient
                                        .profilePicture
                                }
                                alt='user'
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow className='cancelTaskP__textContent'>
                        <IonCol
                            className='cancelTaskP__textContent--container'
                            sizeSm='12'
                            sizeXs='12'
                        >
                            <h1 className='cancelTaskP__textContent--title'>
                                <span>Confirm,</span> Cancel Request To
                                Participate
                            </h1>
                            <p className='cancelTaskP__textContent--text'>
                                The task owner will be notified and if you wish
                                to participate you will need to request
                                participation again.
                            </p>
                        </IonCol>
                    </IonRow>
                    <IonGrid className='cancelTaskP__container'>
                        <IonRow className='cancelTaskP__row'>
                            <IonCol
                                className='cancelTaskP__col'
                                sizeXs='12'
                                sizeSm='12'
                            >
                                <IonCol
                                    sizeXs='12'
                                    sizeSm='12'
                                    className='ion-padding cancelTaskP__content'
                                >
                                    <p className='cancelTaskP__content--skill'>
                                        {data.participationRequest.skill.name}
                                    </p>
                                    <p className='cancelTaskP__content--text'>
                                        {
                                            data.participationRequest.skill
                                                .additionalDetails
                                        }
                                    </p>
                                </IonCol>
                                {/* <IonCol
                                    sizeXs='3'
                                    sizeSm='3'
                                    className='cancelTaskP__request--btn'
                                >
                                    <p
                                        className='skillsOffered__request--btn--text'
                                        style={{ color: '#f56666' }}
                                    >
                                        Remove
                                    </p>
                                </IonCol> */}
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                    <IonRow className='cancelTaskP__btn--container'>
                        <IonCol
                            className='cancelTaskP__btn--wrap'
                            sizeXs='12'
                            sizeSm='12'
                        >
                            <IonButton
                                fill='clear'
                                color='ion-primary'
                                className='cancelTaskP__btn'
                                onClick={handleParticipationRequest}
                            >
                                Cancel Participation
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};
