import React from 'react';
import { useHistory } from 'react-router-dom';
import yingLogo from '../../assets/ying-logo-low-opacity.png';
import './NetworkError.css';

const NetworkError = () => {
    const history = useHistory();
    return (
        <div className='networkErr'>
            <div className='networkErr__img--wrap'>
                <img
                    className='networkErr__img'
                    src={yingLogo}
                    alt='ying logo'
                />
            </div>
            <h4 className='networkErr__title'>No connection</h4>
            <p className='networkErr__text'>
                Please check your internet connection
                <br />
                and try again.
            </p>
            <button className='networkErr__btn' onClick={() => history.go(0)}>
                Try Again
            </button>
        </div>
    );
};

export default NetworkError;
