import moment from 'moment';

const filterParticipationRequestsByDay = (
    participationRequests = [],
    selectedDate
) => {
    return participationRequests.filter((p) =>
        moment(p.skill.jobPost.startTime).isSame(moment(selectedDate), 'day')
    );
};

export { filterParticipationRequestsByDay as default };
