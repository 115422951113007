// importing modules
import React, { useState } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
// importing components
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonIcon,
    IonImg,
    IonLabel,
    IonModal,
    IonRow,
} from '@ionic/react';
import TextContent from '../UI/TextContent';
import LocationItem from './LocationItem/LocationItem';
import Strokes from 'components/Strokes';
// importing assets
import { closeOutline } from 'ionicons/icons';
// importing utilities
import { LocationPreference } from '../../../GraphQL/__generated__/globalTypes';
import { getLocalStorage } from '../../../helpers/localStorage/localStorageHelper';
// importing styles
import './LocationPreferenceModal.css';
import '../../../components/GooglePlacesDropDown/GooglePlacesDropDown.css';

const locationsPreference = [
    {
        id: 1,
        location: LocationPreference.OPENTOALL,
        text: "I'm Open for All",
        description:
            'This means that people can enter custom places for you to meet at for tasks and/or video calls',
    },
    {
        id: 2,
        location: LocationPreference.VIRTUAL,
        text: 'Virtual',
        description:
            'This means that people will only be able to book sessions with you via video/conference call',
    },
];

const LocationPreferenceModal: React.FC<{
    locationPref: string;
    slug: string;
    showLocationModal: boolean;
    handleLocationModal: () => void;
    handleLocationPreference: Function;
    address: String;
    setAddress: Function;
}> = ({
    showLocationModal,
    handleLocationModal,
    handleLocationPreference,
    locationPref,
    slug,
    address,
    setAddress,
}) => {
    // const [ address, setAddress ] = useState<string>('');

    const handleAddress = (address) => {
        setAddress(address);
        // handleLocationPreference(address);
    };

    return (
        <IonModal isOpen={showLocationModal}>
            <IonContent>
                <IonGrid>
                    {/* locationModal header */}
                    <IonRow className='locationModal__header'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='locationModal__header--container'
                        >
                            <IonButton
                                fill='clear'
                                color='ion-primary'
                                className='locationModal__btn--close'
                                onClick={handleLocationModal}
                            >
                                <IonIcon
                                    className='locationModal__btn--close--icon'
                                    icon={closeOutline}
                                />
                            </IonButton>
                            <Strokes top={true} green={false} />
                        </IonCol>
                    </IonRow>
                    {/* locationModal header */}

                    {/* locationModal badge */}
                    <IonRow className='locationModal__badge--container'>
                        <IonCol
                            style={{
                                color: getLocalStorage('skillColors').color,
                                backgroundColor:
                                    getLocalStorage('skillColors')
                                        .backgroundColor,
                            }}
                            sizeSm='10'
                            sizeXs='10'
                            className='locationModal__badge'
                        >
                            <p>{slug}</p>
                        </IonCol>
                    </IonRow>
                    {/* locationModal badge */}

                    {/* text content */}
                    <TextContent
                        h1TextLeft='Choose your'
                        spanText='location preferences'
                        h1TextRight=''
                        pText='You have the option to set specific places
                        to provide your skill to people if you’d like '
                    />
                    {/* text content */}

                    {/* location selection form */}
                    <IonGrid className='locationModal__form--container'>
                        <IonRow className='locationModal__form--row'>
                            {locationsPreference.map((location) => (
                                <LocationItem
                                    key={location.id}
                                    {...location}
                                    locationPref={locationPref}
                                    handleLocationPreference={
                                        handleLocationPreference
                                    }
                                />
                            ))}
                            {/* section divider */}
                            <IonRow className='locationModal__typeDivider'>
                                <IonCol
                                    sizeXs='12'
                                    sizeSm='12'
                                    className='locationModal__typeDivider--container'
                                >
                                    <p className='locationModal__typeDivider__text'>
                                        <span className='locationModal__typeDivider__text--span'>
                                            or
                                        </span>
                                    </p>
                                </IonCol>
                            </IonRow>
                            {/* section divider */}
                            <IonCol
                                className='defSkill__form--input--wrap'
                                sizeXs='12'
                                sizeSm='12'
                            >
                                <IonLabel className='defSkill__form--label'>
                                    Set Specific Location (In Person)
                                </IonLabel>
                            </IonCol>
                            <PlacesAutocomplete
                                value={address}
                                onChange={handleAddress}
                                shouldFetchSuggestions={address.length > 3}
                                // onSelect={handleSelect}
                            >
                                {({
                                    getInputProps,
                                    suggestions,
                                    getSuggestionItemProps,
                                    loading,
                                }) => (
                                    <IonCol
                                        className='taskLocation__form--input--wrap'
                                        sizeXs='12'
                                        sizeSm='12'
                                    >
                                        <input
                                            onClick={
                                                address.length === 0
                                                    ? () =>
                                                          handleLocationPreference(
                                                              LocationPreference.INPERSON
                                                          )
                                                    : null
                                            }
                                            className={
                                                suggestions.length > 0
                                                    ? 'taskLocation__form--input taskLocation__form--input--focus'
                                                    : 'taskLocation__form--input'
                                            }
                                            placeholder='Enter address here...'
                                            value={address}
                                            type='text'
                                            {...getInputProps({
                                                placeholder:
                                                    'Enter address here...',
                                                className:
                                                    'location-search-input taskLocation__form--input',
                                            })}
                                        />
                                        {suggestions.length > 0 && (
                                            <div className='autocomplete__dropdown--container'>
                                                {loading && (
                                                    <div>Loading...</div>
                                                )}
                                                {suggestions.map(
                                                    (suggestion, index) => {
                                                        const className =
                                                            'autocomplete__dropdown--item';
                                                        return (
                                                            <div
                                                                {...getSuggestionItemProps(
                                                                    suggestion,
                                                                    {
                                                                        className,
                                                                        key: index,
                                                                    }
                                                                )}
                                                            >
                                                                <p className='autocomplete__dropdown--item--text'>
                                                                    {
                                                                        suggestion.description
                                                                    }
                                                                </p>
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </div>
                                        )}
                                    </IonCol>
                                )}
                            </PlacesAutocomplete>
                        </IonRow>
                    </IonGrid>

                    <IonRow className='locationModal__btn--container'>
                        <IonCol
                            className='locationModal__btn--wrap'
                            sizeXs='12'
                            sizeSm='12'
                        >
                            <IonButton
                                onClick={handleLocationModal}
                                disabled={locationPref.length === 0}
                                fill='clear'
                                className='locationModal__btn'
                            >
                                Confirm Location
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonModal>
    );
};

export default LocationPreferenceModal;
