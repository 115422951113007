// importing modules
import React from 'react';
import { useHistory } from 'react-router';
import moment from 'moment-timezone';
// importing components
import { IonCol, IonImg, IonButton } from '@ionic/react';
// importing utilities
import { getLocalStorage } from '../../../helpers/localStorage/localStorageHelper';
// importing types
import { GetProfileJobsPosted_profile_jobsPosted } from '../../../GraphQL/__generated__/GetProfileJobsPosted';
import { Status } from '../../../GraphQL/__generated__/globalTypes';

const TaskPostedItem: React.FC<{
    task: GetProfileJobsPosted_profile_jobsPosted;
}> = ({ task }) => {
    const history = useHistory();

    const startDt =
        task.startDate === null
            ? 'TBD'
            : moment(task.startDate)
                  .tz(getLocalStorage('tz'), false)
                  .format('dddd, MMMM Do YYYY');

    return (
        <IonCol className='tasksPostedItem' sizeSm='11' sizeXs='11'>
            <IonCol sizeXs='12' sizeSm='12' className='tasksPostedItem__info'>
                <IonCol sizeXl='3' sizeLg='3' sizeMd='3' sizeXs='3' sizeSm='3'>
                    <div className='tasksPostedItem__img--wrap'>
                        <IonImg
                            className='tasksPostedItem__img'
                            src={task.recipient.profilePicture}
                        />
                    </div>
                </IonCol>
                <IonCol
                    className='tasksPostedItem__info--wrap'
                    sizeXs='9'
                    sizeSm='9'
                >
                    <h3 className='tasksPostedItem__info--name'>
                        {task.title}
                    </h3>
                    <p className='tasksPostedItem__info--date'>{startDt}</p>
                </IonCol>
            </IonCol>
            <IonCol sizeXs='12' sizeSm='12'>
                <p className='tasksPostedItem__info--descr'>
                    {task.description}
                </p>
            </IonCol>
            <IonCol
                className='tasksPosted__item--btn--wrap'
                sizeSm='12'
                sizeXs='12'
            >
                {task.status.toString() === Status.COMPLETED.toString() ||
                task.status.toString() ===
                    Status.RECIPIENT_HAS_PROPOSED_OFFER.toString() ||
                (moment().isBetween(task.startTime, task.endTime) &&
                    task.startDate !== null &&
                    task.startTime !== null) ||
                (moment() > task.endTime &&
                    task.startDate !== null &&
                    task.endTime !== null) ? null : (
                    <IonButton
                        onClick={() =>
                            history.push(`/manage/${task.id}/participation`)
                        }
                        color='ion-primary'
                        fill='clear'
                        className='tasksPosted__item--btn--manage'
                    >
                        Manage
                    </IonButton>
                )}
                <IonButton
                    onClick={() => history.push(`/task/${task.id}`)}
                    color='ion-primary'
                    fill='clear'
                    className='tasksPosted__item--btn--view'
                >
                    View
                </IonButton>
            </IonCol>
        </IonCol>
    );
};

export default TaskPostedItem;
