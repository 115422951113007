// importing modules
import React from 'react';
// importing components
import { IonButton, IonImg, IonIcon } from '@ionic/react';
import { removeOutline } from 'ionicons/icons';

const MenuItem: React.FC<{
    path: string;
    name: string;
    handleMenuToggle: () => void;
}> = ({ path, handleMenuToggle, name }) => {
    return (
        <IonButton
            routerLink={path}
            onClick={handleMenuToggle}
            fill='clear'
            color='ion-primary'
            className='menu__sidebar--item menu__sidebar--item--outlineOnly'
        >
            <span className='menu__sidebar--item--span'>
                <IonIcon
                    className='menu__sidebar--item--icon--dash'
                    icon={removeOutline}
                    slot='start'
                />
                {name}
            </span>
        </IonButton>
    );
};

export default MenuItem;
