// importing modules
import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
// importing components
import {
    IonCol,
    IonIcon,
    IonImg,
    IonItemSliding,
    IonItemOptions,
    IonItemOption,
    IonItem,
    IonAlert,
} from '@ionic/react';
// importing assets
import { trashOutline } from 'ionicons/icons';
// importing utilities
import limitStr from '../../../utils/limitString';

const ChatroomPreview: React.FC<{
    latestMessage: string;
    roomId: string;

    currentUserUid: string;

    seenStatus: boolean;

    messageUid: string;
    otherUserUid: string;
    otherUserFirstName: string;
    otherUserLastName: string;
    otherUserImg: string;

    handleDeleteChatRoom: Function;
}> = ({
    currentUserUid,
    otherUserFirstName,
    otherUserLastName,
    otherUserImg,
    otherUserUid,
    latestMessage,
    messageUid,
    seenStatus,
    roomId,
    handleDeleteChatRoom,
}) => {
    const history = useHistory();
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const chatroomLink = useRef(null);

    const handleChatRoom = () => chatroomLink.current?.click();

    return (
        <>
            <IonAlert
                isOpen={showAlert}
                onDidDismiss={() => setShowAlert(false)}
                cssClass='msgChatroom__alert'
                header={'Delete Conversation'}
                message={'Are you sure you want to delete this conversation?'}
                buttons={[
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        handler: () => setShowAlert(false),
                        cssClass: 'msgChatroom__alert--btn--delete',
                    },
                    {
                        text: 'Delete',
                        handler: () => handleDeleteChatRoom(roomId),
                        cssClass: 'msgChatroom__alert--btn--delete',
                    },
                ]}
            />
            <IonItemSliding className='msgChatroom__wrap'>
                <IonItemOptions side='end'>
                    <IonItemOption
                        className='msgChatroom__delete--btn'
                        onClick={() => setShowAlert(true)}
                    >
                        <IonIcon icon={trashOutline} slot='icon-only' />
                    </IonItemOption>
                </IonItemOptions>
                <IonItem
                    // onClick={() =>
                    //     history.push(
                    //         `/chatroom/${roomId}/${currentUserUid}/${otherUserUid}`
                    //     )
                    // }
                    onClick={handleChatRoom}
                    className='msgChatroom'
                >
                    <a
                        ref={chatroomLink}
                        href={`/chatroom/${roomId}/${currentUserUid}/${otherUserUid}`}
                        type='hidden'
                    ></a>
                    <IonCol
                        sizeXs='2'
                        sizeSm='2'
                        className='msgChatroom__img--wrap'
                    >
                        <IonImg
                            className='msgChatroom__img'
                            alt='user'
                            src={otherUserImg}
                        />
                        {/* <IonIcon 
                        className={status === "online" ? "msgChatroom__icon msgChatroom__icon--online" : "msgChatroom__icon msgChatroom__icon--offline"} 
                        icon={ellipse}/> */}
                    </IonCol>
                    <IonCol
                        className='msgChatroom__preview'
                        sizeXs='10'
                        sizeSm='10'
                    >
                        <h3 className='msgChatroom__preview--title'>
                            {limitStr(
                                `${otherUserFirstName} ${otherUserLastName}`,
                                30
                            )}
                        </h3>
                        <p className='msgChatroom__preview--text'>
                            {limitStr(latestMessage, 36)}
                        </p>
                    </IonCol>
                    {seenStatus || messageUid === currentUserUid ? (
                        ''
                    ) : (
                        <div className='msgChatroom__bluedot'></div>
                    )}
                </IonItem>
            </IonItemSliding>
        </>
    );
};

export default ChatroomPreview;
