// importing modules
import React, { useState } from 'react';
import { useAuth } from 'context/firebase/authContext';
import moment from 'moment-timezone';
import { useHistory } from 'react-router-dom';
// importing components
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonItem,
    IonLabel,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonToast,
} from '@ionic/react';
import UserImg from '../../RequestSkill/UserImg/UserImg';
import BackButton from 'components/BackButton';
import CancelButton from 'components/CancelButton/CancelButton';
// importing utilities
import { setLocalStorage } from '../../../helpers/localStorage/localStorageHelper';
import Strokes from 'components/Strokes';
// importing assets
// importing graphql & types
import { useMutation, useQuery } from '@apollo/react-hooks';
import { UPDATE_SETTINGS } from '../../../GraphQL/Setting/UPDATE_SETTINGS/UPDATE_SETTINGS';
import {
    UpdateProfileSettings,
    UpdateProfileSettingsVariables,
} from '../../../GraphQL/__generated__/UpdateProfileSettings';
// importing styles
import '../Settings.css';
import {
    GetSettingsByUID,
    GetSettingsByUIDVariables,
} from '../../../GraphQL/__generated__/GetSettingsByUID';
import { GET_SETTINGS_BY_UID } from '../../../GraphQL/Setting/GET_SETTINGS/GET_SETTINGS_BY_UID';
import Loading from '../../../components/status/Loading';

const OPTIONS = [
    {
        id: '1',
        name: 'Public',
        description: 'Anyone can view your Profile',
    },
    {
        id: '2',
        name: 'Private',
        description: 'Only members in your groups can view your Profile',
    },
];

const ProfileSettings: React.FC = () => {
    const { currentUser } = useAuth();
    const history = useHistory();
    const [showToast, setShowToast] = useState(false);
    const { data, loading, error, fetchMore } = useQuery<
        GetSettingsByUID,
        GetSettingsByUIDVariables
    >(GET_SETTINGS_BY_UID, {
        variables: {
            uid: currentUser.uid,
            tz: moment.tz.guess(),
        },
    });

    const timezone = data?.getSettingsByUID.timezone;
    const settingsId = data?.getSettingsByUID.id;
    const [tz, setTz] = useState(timezone);
    const timezonesList = moment.tz.names();
    const [updateSettings, updateSettingsDatas] = useMutation<
        UpdateProfileSettings,
        UpdateProfileSettingsVariables
    >(UPDATE_SETTINGS);

    const handleUpdateProfileSettings = () => {
        updateSettings({
            variables: { id: settingsId, tz: tz },
        })
            .then(() => {
                setLocalStorage('tz', tz);
                setShowToast(true);
            })
            .catch((err) => console.error(err));
    };
    if (loading) {
        return <Loading />;
    }
    return (
        <IonPage>
            <IonContent>
                <IonGrid>
                    <IonToast
                        cssClass='requestDetails__location--clipboard--toast'
                        isOpen={showToast}
                        onDidDismiss={() => setShowToast(false)}
                        message='Profile Settings Updated!'
                        duration={1000}
                    />
                    <IonRow className='settings__header'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='settings__header--container'
                        >
                            <Strokes top={true} />
                            <IonCol
                                sizeXs='12'
                                sizeSm='12'
                                sizeMd='12'
                                className='reqTaskNav__btns--wrap'
                            >
                                <BackButton color='white' />
                                <CancelButton />
                            </IonCol>
                            <UserImg providerUid={currentUser.uid} />
                        </IonCol>
                    </IonRow>
                    {/* title */}
                    <IonRow className='settings__textContent'>
                        <IonCol
                            className='settings__textContent--container'
                            sizeSm='12'
                            sizeXs='12'
                        >
                            <h1 className='settings__textContent--title'>
                                <span>Customize</span> your settings
                            </h1>
                        </IonCol>
                    </IonRow>
                    {/* setting options */}
                    <IonGrid className='settings__form--container'>
                        <IonRow className='settings__form--row'>
                            <IonRow className='settings__col--wrap'>
                                {/*Commenting out currently unused options.
                                 See clubhouse story 1317 -nbm*/}

                                {/*
                                {OPTIONS.map((option) => (
                                    <IonCol
                                        key={option.id}
                                        className='settings__col'
                                        sizeXs='12'
                                        sizeSm='12'
                                    >
                                        <IonCol
                                            sizeXs='12'
                                            sizeSm='12'
                                            className='settings__content'
                                        >
                                            <p className='settings__content--text'>
                                                {option.name}
                                            </p>
                                            <p className='settings__content--description'>
                                                {option.description}
                                            </p>
                                        </IonCol>
                                    </IonCol>
                                ))}
                                <IonRow className='settings__typeDivider'>
                                    <IonCol
                                        sizeXs='12'
                                        sizeSm='12'
                                        className='settings__typeDivider--container'
                                    >
                                        <p className='settings__typeDivider__text'>
                                            <span className='settings__typeDivider__text--span'>
                                                and
                                            </span>
                                        </p>
                                    </IonCol>
                                </IonRow>                                
                                */}

                                <IonCol
                                    className='taskLocation__form--input--wrap'
                                    sizeXs='12'
                                    sizeSm='12'
                                >
                                    <IonLabel className='taskLocation__form--label'>
                                        Set Your Timezone
                                    </IonLabel>
                                </IonCol>
                                <IonCol
                                    className='settings__tz--col'
                                    sizeXs='12'
                                    sizeSm='12'
                                >
                                    <IonItem
                                        lines='none'
                                        className='settings__tz--wrap'
                                    >
                                        <IonLabel className='settings__tz--label'>
                                            Timezone
                                        </IonLabel>
                                        <IonSelect
                                            placeholder='ex: America/Los_Angeles'
                                            className='settings__tz--select'
                                            value={tz}
                                            onIonChange={(e) =>
                                                setTz(e.detail.value)
                                            }
                                            ok-text='Accept'
                                            cancel-text='Cancel'
                                        >
                                            {timezonesList.length > 0 &&
                                                timezonesList.map(
                                                    (timezone) => (
                                                        <IonSelectOption
                                                            key={timezone}
                                                            value={timezone}
                                                        >
                                                            {timezone}
                                                        </IonSelectOption>
                                                    )
                                                )}
                                        </IonSelect>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                        </IonRow>
                    </IonGrid>
                    {/* update button */}
                    <IonRow className='settings__btn--container'>
                        <IonCol
                            className='settings__btn--wrap'
                            sizeXs='12'
                            sizeSm='12'
                        >
                            <IonButton
                                onClick={handleUpdateProfileSettings}
                                fill='clear'
                                color='ion-primary'
                                className='settings__btn'
                            >
                                Update
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default ProfileSettings;
