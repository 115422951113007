// importing modules
import React from 'react';
import { IonImg } from '@ionic/react';
// importing graphql utilities - types
import { useQuery } from '@apollo/react-hooks';
import {
    GetProviderTaskInfo,
    GetProviderTaskInfoVariables,
} from '../../../GraphQL/__generated__/GetProviderTaskInfo';
import { TASK_PROVIDER_INFO } from '../../../GraphQL/RequestTaskConfirmation/getProviderTaskInfo';

const UserImg: React.FC<{
    providerUid: string;
}> = ({ providerUid }) => {
    const { data, loading } = useQuery<
        GetProviderTaskInfo,
        GetProviderTaskInfoVariables
    >(TASK_PROVIDER_INFO, {
        variables: {
            uid: providerUid,
        },
    });

    if (loading) {
        return <p>...loading</p>;
    }

    return (
        <IonImg
            className='reqTaskAvl__header--img'
            src={data.profile.profilePicture}
            alt='user'
        />
    );
};

export default UserImg;
