// importing modules
import React from 'react';
// importing components
import {
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonPage,
    IonRow,
} from '@ionic/react';
import BackButton from 'components/BackButton';
import Strokes from 'components/Strokes';
// importing assets
import { MANIFESTO_TEXT_VALUES, CORE_VALUES } from '../../data/manifesto';
// importing styles
import './AboutUs.css';

const AboutUs: React.FC = () => {
    return (
        <IonPage>
            <IonContent>
                <Strokes top={true} green={false} />
                <IonGrid>
                    <BackButton />

                    <IonRow className='about__title--container'>
                        <IonCol
                            className='about__title--wrap'
                            sizeXs='12'
                            sizeSm='12'
                        >
                            <h1 className='about__title'>
                                Contact <span>Us</span>
                            </h1>
                        </IonCol>
                    </IonRow>
                    <IonGrid className='about__manifesto--container'>
                        <IonRow className='about__manifesto--wrap'>
                            <IonCol
                                sizeXs='12'
                                sizeSm='12'
                                className='about__manifesto--contactUs'
                            >
                                <p className='about__manifesto--text'>
                                    For questions, feedback & skill suggestions
                                    contact us at{' '}
                                    <span>
                                        <a href='mailto:info@yingme.co'>
                                            info@yingme.co
                                        </a>
                                    </span>
                                </p>
                            </IonCol>
                        </IonRow>
                    </IonGrid>

                    <IonRow className='about__title--container about__values--container'>
                        <IonCol
                            className='about__title--wrap'
                            sizeXs='12'
                            sizeSm='12'
                        >
                            <h1 className='about__title'>
                                Our <span>Manifesto</span>
                            </h1>
                        </IonCol>
                    </IonRow>
                    <IonGrid className='about__manifesto--container'>
                        <IonRow className='about__manifesto--wrap'>
                            <IonCol
                                sizeXs='12'
                                sizeSm='12'
                                className='about__manifesto--content'
                            >
                                {MANIFESTO_TEXT_VALUES.map((manifesto) => (
                                    <p
                                        key={manifesto.id}
                                        className='about__manifesto--text'
                                    >
                                        • {manifesto.text}
                                    </p>
                                ))}
                            </IonCol>
                        </IonRow>
                    </IonGrid>

                    <IonRow className='about__title--container about__values--container'>
                        <IonCol
                            className='about__title--wrap'
                            sizeXs='12'
                            sizeSm='12'
                        >
                            <h1 className='about__title'>
                                Our <span>Core Values</span>
                            </h1>
                        </IonCol>
                    </IonRow>
                    <IonGrid className='about__core--container'>
                        <IonRow className='about__core--wrap'>
                            {CORE_VALUES.map((coreVals) => (
                                <IonCol
                                    key={coreVals.id}
                                    sizeXs='12'
                                    sizeSm='12'
                                    className='about__core--content'
                                >
                                    <h1 className='about__core--title'>
                                        {coreVals.title}
                                    </h1>
                                    <p className='about__core--text'>
                                        {coreVals.text}
                                    </p>
                                </IonCol>
                            ))}
                        </IonRow>
                    </IonGrid>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default AboutUs;
