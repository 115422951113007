// importing modules
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
// importing components
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonIcon,
    IonImg,
    IonPage,
    IonRow,
} from '@ionic/react';
import UserImg from '../UI/UserImg/UserImg';
import CompleteTaskNavTabs from '../UI/CompleteTaskNavTabs/CompleteTaskNavTabs';
import CompleteTaskFooter from '../../../components/FooterWithChildren/FooterWithChildren';
import Loading from '../../../components/status/Loading';
import Strokes from 'components/Strokes';
// importing assets
import { star } from 'ionicons/icons';
// importing helpers
import {
    getLocalStorage,
    updateLocalStorage,
} from '../../../helpers/localStorage/localStorageHelper';
import capitalize from '../../../utils/capitalize';
// importing graphql
import { useQuery } from '@apollo/react-hooks';
import { GET_JOB_BY_ID } from '../../../GraphQL/Job/GET_JOB_BY_ID/GET_JOB_BY_ID';
import {
    GetJobById,
    GetJobByIdVariables,
} from '../../../GraphQL/__generated__/GetJobById';
// importing styles
import './TaskRating.css';

const ReviewTaskStars: React.FC = () => {
    const history = useHistory();
    const { taskId } = useParams<{ taskId: string }>();
    const [rating, setRating] = useState<number>(0);

    useEffect(() => {
        if (getLocalStorage('completeTaskViaProvider')) {
            const completeTaskObj = getLocalStorage('completeTaskViaProvider');
            setRating(completeTaskObj.rating);
        } else {
            history.push('/home');
        }
    }, []);

    const { data, loading, error, fetchMore } = useQuery<
        GetJobById, // query return return type (shape of data)
        GetJobByIdVariables // query request type       (shape of variables)
    >(GET_JOB_BY_ID, {
        variables: {
            jobId: parseInt(taskId),
        },
    });

    if (loading) {
        return <Loading />;
    }

    const handleRating = () => {
        updateLocalStorage('completeTaskViaProvider', 'rating', rating);
        history.push(`/completeTask/provider/${taskId}/review`);
    };

    return (
        <IonPage>
            <IonContent fullscreen>
                <IonGrid>
                    {/* reviewTask header */}
                    <IonRow className='reviewTask__header'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='reviewTask__header--container'
                        >
                            <CompleteTaskNavTabs
                                disablePrevStep={false}
                                prevPage={`/completeTask/provider/${taskId}`}
                            />
                            <Strokes top={true} />
                            <UserImg />
                        </IonCol>
                    </IonRow>
                    {/* text content */}
                    <IonRow className='reviewTask__textContent'>
                        <IonCol
                            className='reviewTask__textContent--container'
                            sizeSm='12'
                            sizeXs='12'
                        >
                            <h1 className='reviewTask__textContent--title'>
                                How would you <span>rate</span> your experience
                                helping{' '}
                                <span>
                                    {!loading &&
                                        capitalize(
                                            data.job.recipient.firstName
                                        )}
                                </span>{' '}
                                with{' '}
                                <span>
                                    {!loading && data.job.Skill.category}
                                </span>{' '}
                                ?
                            </h1>
                        </IonCol>
                    </IonRow>
                    {/* star rating */}
                    <IonRow className='stars--row'>
                        <IonCol
                            sizeXs='10'
                            sizeSm='10'
                            className='stars__container'
                        >
                            {[...Array(5)].map((s, i) => {
                                const ratingVal: number = i + 1;

                                return (
                                    <label key={i}>
                                        <input
                                            type='radio'
                                            name='rating'
                                            value={ratingVal}
                                            onClick={() => setRating(ratingVal)}
                                        />
                                        <IonIcon
                                            style={{
                                                color:
                                                    ratingVal <= rating
                                                        ? '#f7b500'
                                                        : '#d3d3d3',
                                            }}
                                            icon={star}
                                            className='star'
                                        />
                                    </label>
                                );
                            })}
                        </IonCol>
                    </IonRow>
                    {/* text content */}
                    <IonRow className='reviewTask__textContent'>
                        <IonCol
                            className='reviewTask__textContent--wrap'
                            sizeSm='12'
                            sizeXs='12'
                        >
                            <p className='reviewTask__textContent--text'>
                                All tasks on YING require a rating from both
                                people involved.
                            </p>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
            <CompleteTaskFooter>
                <IonButton
                    onClick={handleRating}
                    fill='clear'
                    disabled={rating === 0}
                    color='ion-primary'
                    className='footerWithChild__btn'
                >
                    Continue
                </IonButton>
            </CompleteTaskFooter>
        </IonPage>
    );
};

export default ReviewTaskStars;
