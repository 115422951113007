import React from 'react';
import { IonCol } from '@ionic/react';

const TaskLocationItem: React.FC<{
    taskLocation: string;
    name: string;
    address: string;
    setTaskLocation: Function;
    locationType: string;
    setLocationType: Function;
}> = ({ name, address, setTaskLocation, locationType, setLocationType }) => {
    return (
        <IonCol
            onClick={() => {
                setLocationType(name);
                setTaskLocation(name);
            }}
            className={
                locationType === name
                    ? 'taskLocation__col taskLocation__col--active'
                    : 'taskLocation__col'
            }
            sizeXs='12'
            sizeSm='12'
        >
            <IonCol
                sizeXs='12'
                sizeSm='12'
                className={
                    locationType === name
                        ? 'taskLocation__content taskLocation__content--active'
                        : 'taskLocation__content'
                }
            >
                <p
                    className={
                        locationType === name
                            ? 'taskLocation__content--place taskLocation__content--place--active'
                            : 'taskLocation__content--place'
                    }
                >
                    {name}
                </p>
                <p
                    className={
                        locationType === name
                            ? 'taskLocation__content--address taskLocation__content--address--active'
                            : 'taskLocation__content--address'
                    }
                >
                    {address}
                </p>
            </IonCol>
        </IonCol>
    );
};

export default TaskLocationItem;
