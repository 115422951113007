import React from 'react';
import { IonContent, IonPage, IonRow, IonCol, IonImg } from '@ionic/react';
import yingLogo from '../../assets/ying-white-logo-3x.png';
import './Loading.css';

const Loading: React.FC = () => (
    <IonPage>
        <IonContent fullscreen>
            <IonRow className='loadingScreen'>
                <IonCol className='loadingScreen__col' sizeXs='12' sizeSm='12'>
                    <IonImg
                        className='yingLoader__logo'
                        src={yingLogo}
                        alt='ying logo'
                    />
                </IonCol>
            </IonRow>
        </IonContent>
    </IonPage>
);

export default Loading;
