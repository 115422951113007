// importing modules
import React, { useState } from 'react';
// imoporting components
import { IonCol, IonImg, IonRow, IonIcon } from '@ionic/react';
import { star } from 'ionicons/icons';
// importing helpers
import {
    getLocalStorage,
    updateLocalStorage,
} from '../../../../helpers/localStorage/localStorageHelper';

const ParticipantRatingItem: React.FC<{
    id: number;
    firstName: string;
    lastName: string;
    profilePicture: string;
    skill: string;
    rating: number;
}> = ({ id, firstName, lastName, profilePicture, rating, skill }) => {
    const [rt, setRt] = useState<number>(rating > 0 ? rating : 0);

    const handleParticipantRating = (
        participantId: number,
        updatedRating: number
    ) => {
        const currentParticipants = getLocalStorage(
            'completeTaskPostedViaRecipient'
        ).participants;
        const updatedSkills = currentParticipants.map((p) =>
            p.id === participantId ? { ...p, rating: updatedRating } : p
        );

        updateLocalStorage(
            'completeTaskPostedViaRecipient',
            'participants',
            updatedSkills
        );
    };

    return (
        <IonCol className='taskPartcItem' sizeSm='12' sizeXs='12'>
            <IonCol sizeXs='12' sizeSm='12' className='taskPartcItem__content'>
                <IonCol sizeXs='12' sizeSm='12' className='taskPartcItem__info'>
                    <IonCol
                        sizeXl='3'
                        sizeLg='3'
                        sizeMd='3'
                        sizeXs='3'
                        sizeSm='3'
                    >
                        <div className='taskPartcItem__img--wrap'>
                            <IonImg
                                className='taskPartcItem__img'
                                src={profilePicture}
                            />
                        </div>
                    </IonCol>
                    <IonCol
                        className='taskPartcItem__info--wrap'
                        sizeXs='9'
                        sizeSm='9'
                    >
                        <h3 className='taskPartcItem__info--name'>
                            {firstName} {lastName}
                        </h3>
                        <p className='taskPartcItem__info--cred'>{skill}</p>
                    </IonCol>
                </IonCol>
                {/* stars system*/}
                <IonRow className='stars--row'>
                    <IonCol
                        sizeXs='11'
                        sizeSm='11'
                        className='stars__container'
                    >
                        {[...Array(5)].map((s, i) => {
                            const ratingVal = i + 1;

                            return (
                                <label key={i}>
                                    <input
                                        type='radio'
                                        name='rating'
                                        value={ratingVal}
                                        onClick={() => {
                                            setRt(ratingVal);
                                            handleParticipantRating(
                                                id,
                                                ratingVal
                                            );
                                        }}
                                    />
                                    <IonIcon
                                        style={{
                                            color:
                                                ratingVal <= rt
                                                    ? '#f7b500'
                                                    : '#d3d3d3',
                                        }}
                                        icon={star}
                                        className='star'
                                    />
                                </label>
                            );
                        })}
                    </IonCol>
                </IonRow>
            </IonCol>
        </IonCol>
    );
};

export default ParticipantRatingItem;
