// importing modules
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'context/firebase/authContext';
import moment from 'moment-timezone';
// importing components
import {
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonPage,
    IonRow,
} from '@ionic/react';
import RequestTaskTabs from '../RequestSkill/UI/RequestTaskTabs';
import Loading from '../../components/status/Loading';
import Strokes from 'components/Strokes';
// importing assets
import { useQuery } from '@apollo/react-hooks';
import {
    GetSettingsByUID,
    GetSettingsByUIDVariables,
} from '../../GraphQL/__generated__/GetSettingsByUID';
import { GET_SETTINGS_BY_UID } from '../../GraphQL/Setting/GET_SETTINGS/GET_SETTINGS_BY_UID';
import gratitudePot from '../../assets/GratitudePot2.0.png';
import YINGLogoLightPurple from '../../assets/YINGLogoLightPurple.png';
// importing styles
import '../Settings/Settings.css';
import '../GratitudeSettings/GratitudeSettings.css';

const OPTIONS = [
    {
        id: '1',
        name: 'Add a Card',
        description: 'Add a card to make payments',
        href: '/settings/paymentmethods',
        title: 'Give',
    },
    {
        id: '2',
        name: 'Bank information',
        description: 'Add bank account to receive payments',
        href: '/settings/payoutmethods',
        title: 'Receive',
    },
];

const GratitudeSettings: React.FC = () => {
    const history = useHistory();
    const { currentUser } = useAuth();

    const { data, loading, error, fetchMore } = useQuery<
        GetSettingsByUID,
        GetSettingsByUIDVariables
    >(GET_SETTINGS_BY_UID, {
        variables: {
            uid: currentUser.uid,
            tz: moment.tz.guess(),
        },
    });

    if (loading) {
        return <Loading />;
    }

    return (
        <IonPage>
            <IonContent>
                <IonGrid>
                    <IonRow className='settings__header'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='settings__header--container'
                        >
                            <Strokes top={true} />
                            <RequestTaskTabs
                                prevPage={`/settings`}
                                disablePrevStep={false}
                            />
                            <IonImg
                                className='gratitudePotHeaderImg'
                                src={gratitudePot}
                                alt='Gratitude Pot'
                            />
                        </IonCol>
                    </IonRow>
                    {/* title */}
                    <IonRow className='settings__textContent'>
                        <IonCol
                            className='settings__textContent--container'
                            sizeSm='12'
                            sizeXs='12'
                        >
                            <h1 className='gratitudeSettingsTitle'>
                                <span>Gratitude Pot</span>
                            </h1>
                            <br />
                        </IonCol>
                    </IonRow>
                    {/* setting options */}
                    <IonGrid className='settings__form--container'>
                        <IonRow className='settings__form--row'>
                            <IonRow className='settings__col--wrap'>
                                <h1 className='settingsGratitudePotTitlePurple'>
                                    Give
                                </h1>
                                <IonCol
                                    onClick={() =>
                                        history.push('/settings/paymentmethods')
                                    }
                                    className='gratitudeSettings__col'
                                    sizeXs='12'
                                    sizeSm='12'
                                >
                                    <IonCol
                                        sizeXs='12'
                                        sizeSm='12'
                                        className='settings__content'
                                    >
                                        <p className='settings__content--text'>
                                            Add a Card
                                        </p>
                                        <p className='settings__content--description'>
                                            Add a card to make payments
                                        </p>
                                    </IonCol>
                                </IonCol>
                                <h1 className='settingsGratitudePotTitlePurple'>
                                    Receive
                                </h1>
                                <IonCol
                                    onClick={() =>
                                        history.push('/settings/payoutmethods')
                                    }
                                    className='gratitudeSettings__col'
                                    sizeXs='12'
                                    sizeSm='12'
                                >
                                    <IonCol
                                        sizeXs='12'
                                        sizeSm='12'
                                        className='settings__content'
                                    >
                                        <p className='settings__content--text'>
                                            Bank Information
                                        </p>
                                        <p className='settings__content--description'>
                                            Add bank account to receive payments
                                        </p>
                                    </IonCol>
                                </IonCol>
                            </IonRow>
                        </IonRow>
                        <img
                            className='gratitudeSettingsYINGLogoLightPurple'
                            src={YINGLogoLightPurple}
                            alt='Gratitude Pot'
                        />
                    </IonGrid>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default GratitudeSettings;
