// importing modules
import React, { ReactElement, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Plugins } from '@capacitor/core';
import Geocode from 'react-geocode';
import moment from 'moment-timezone';
import Linkify from 'react-linkify';
// importing components
import {
    IonCol,
    IonContent,
    IonGrid,
    IonIcon,
    IonImg,
    IonRow,
    IonPage,
    IonToast,
} from '@ionic/react';
import Loading from '../../components/status/Loading';
import Strokes from 'components/Strokes';
import BackButton from 'components/BackButton';
// importing assets
import { videocam, copy } from 'ionicons/icons';
// importing utilites
import limitStr from '../../utils/limitString';
import getLocationPreferenceStr from '../../utils/getLocationPreferenceStr';
import GoogleMap from '../../components/GoogleMap/PublicMap/Map';
import PrivateGoogleMap from '../../components/GoogleMap/PrivateMap/Map';
import { getLocalStorage } from '../../helpers/localStorage/localStorageHelper';
// importing graphql
import { useQuery } from '@apollo/react-hooks';
import { GET_JOB_BY_ID } from '../../GraphQL/Job/GET_JOB_BY_ID/GET_JOB_BY_ID';
import {
    GetJobById,
    GetJobByIdVariables,
} from '../../GraphQL/__generated__/GetJobById';
import {
    LocationPreference,
    Status,
} from '../../GraphQL/__generated__/globalTypes';
// importing styles
import './SkillRequestDetails.css';
Geocode.setApiKey('AIzaSyD7B3yKTqxrAYt-HWajE5mGW8z2k2Z2TyQ');

const TaskPostedDetails: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const [showToast, setShowToast] = useState(false);
    const [location, setLocation] = useState({ address: '', lat: 0, lng: 0 });
    const [getLocationOnce, setGetLocationOnce] = useState<boolean>(true);

    const { data, loading, error, fetchMore } = useQuery<
        GetJobById, // query return return type (shape of data)
        GetJobByIdVariables // query request type       (shape of variables)
    >(GET_JOB_BY_ID, {
        variables: {
            jobId: parseInt(id),
        },
        fetchPolicy: 'no-cache',
    });

    const handleCopyToClip = (str: string) => {
        const { Clipboard } = Plugins; // get GeoLocation plugin from Plugins
        Clipboard.write({ string: str });
        setShowToast(true);
    };

    if (loading) {
        return <Loading />;
    } else if (!loading && getLocationOnce) {
        if (
            data.job.location.locationString.length > 0 &&
            data.job.location.locationPreference !== LocationPreference.VIRTUAL
        ) {
            Geocode.fromAddress(data.job.location.locationString) // get the tasks current location
                .then((position) => {
                    setLocation({
                        address: position.results[0].formatted_address, // formatted google address
                        lat: position.results[0].geometry.location.lat, // location -> lat
                        lng: position.results[0].geometry.location.lng, // location -> lng
                    });
                    setGetLocationOnce(false);
                })
                .catch((err) => console.error(err));
        }
    }

    const locationStruct = {
        ...location,
        locationPreference: data.job.location.locationPreference,
        status: data.job.Status,
    };

    let locationText: string;
    let locationDetail: ReactElement;

    if (data.job.location.locationPreference === LocationPreference.VIRTUAL) {
        locationDetail = (
            <div className='requestDetails__icon--container'>
                <div className='requestDetails__icon--wrap'>
                    <IonIcon className='requestDetails__icon' icon={videocam} />
                </div>
            </div>
        );
        locationText =
            'Send the skill provider a video conference link in the chat once you complete this request';
    } else if (
        data.job.location.locationPreference === LocationPreference.INPERSON &&
        data.job.Status === Status.REQUESTED
    ) {
        locationDetail = (
            <div className='requestDetails__map--container'>
                <PrivateGoogleMap {...locationStruct} />
            </div>
        );
        locationText =
            'Exact address will be shown once the request has been accepted.';
    } else {
        locationDetail = (
            <div className='requestDetails__map--container'>
                <GoogleMap {...locationStruct} />
            </div>
        );
        locationText = data.job.location.locationString;
    }

    const startTime = moment(data.job.startTime)
        .tz(getLocalStorage('tz'), false)
        .format('MMM Do @h:mma');
    const endTime = moment(data.job.endTime)
        .tz(getLocalStorage('tz'), false)
        .format('h:mma');

    return (
        <IonPage>
            <IonContent>
                <IonGrid>
                    <IonToast
                        cssClass='requestDetails__location--clipboard--toast'
                        isOpen={showToast}
                        onDidDismiss={() => setShowToast(false)}
                        message='Address Copied to Clipboard!'
                        duration={1000}
                    />
                    {/* taskDetails header */}
                    <IonRow className='requestDetails__header'>
                        <Strokes top={true} />
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='requestDetails__header--container'
                        >
                            <BackButton color='white' />
                            <IonCol
                                sizeSm='12'
                                sizeXs='12'
                                className='requestDetails__header--desc--wrap'
                            >
                                <h1 className='requestDetails__header--desc--taskName'>
                                    <span className='requestDetails__header--desc--taskName--span'>
                                        Skill requested
                                    </span>
                                    <br />
                                    {data.job.Skill.category}
                                </h1>
                                <p className='requestDetails__header--desc--taskDescr'>
                                    <span className='requestDetails__header--desc--taskDescr--span'>
                                        Description
                                    </span>
                                    <br />
                                    {data.job.Skill.description.length > 0 ? (
                                        <Linkify>
                                            {data.job.Skill.description}
                                        </Linkify>
                                    ) : (
                                        'No description available for this skill'
                                    )}
                                </p>
                                <p className='requestDetails__header--desc--taskDescr'>
                                    <span className='requestDetails__header--desc--taskDescr--span'>
                                        Scheduled for
                                    </span>
                                    <br />
                                    {startTime} - {endTime}
                                </p>
                            </IonCol>
                            <IonCol
                                sizeSm='12'
                                sizeXs='12'
                                className='requestDetails__header--img--wrap'
                            >
                                <button
                                    style={{ backgroundColor: 'transparent' }}
                                    onClick={() =>
                                        history.push(
                                            `/user/${data.job.provider.uid}`
                                        )
                                    }
                                >
                                    <IonImg
                                        className='requestDetails__header--descr--img'
                                        src={data.job.provider.profilePicture}
                                        alt='user'
                                    />
                                </button>

                                <p className='requestDetails__header--desc--user'>
                                    Provided By{' '}
                                    {`${data.job.provider.firstName} ${data.job.provider.lastName}`}
                                </p>
                            </IonCol>
                        </IonCol>
                    </IonRow>
                    {/* taskDetails skills needed */}
                    <IonRow className='requestDetails__skills'>
                        <IonCol
                            className='requestDetails__skills--container'
                            sizeSm='12'
                            sizeXs='12'
                        >
                            <h1 className='requestDetails__skills--title'>
                                Recipient
                            </h1>
                        </IonCol>
                        <IonRow className='requestDetails__users'>
                            <div className='requestDetails__user'>
                                <button
                                    style={{ backgroundColor: 'transparent' }}
                                    onClick={() =>
                                        history.push(
                                            `/user/${data.job.recipient.uid}`
                                        )
                                    }
                                >
                                    <IonImg
                                        className='requestDetails__user--img'
                                        src={data.job.recipient.profilePicture}
                                        alt='user'
                                    />
                                </button>
                                <p className='requestDetails__user--name'>
                                    {limitStr(data.job.recipient.firstName, 11)}
                                </p>
                            </div>
                        </IonRow>
                    </IonRow>
                    <IonRow className='requestDetails__location'>
                        <IonCol sizeSm='12' sizeXs='12'>
                            <h1 className='requestDetails__skills--title'>
                                Location
                            </h1>
                        </IonCol>
                    </IonRow>
                    {locationDetail}
                    <IonRow className='requestDetails__location'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='requestDetails__location--wrap'
                        >
                            {data.job.Status === Status.SCHEDULED &&
                                data.job.location.locationPreference !==
                                    LocationPreference.VIRTUAL && (
                                    <IonIcon
                                        className='requestDetails__location--clipboard'
                                        icon={copy}
                                        onClick={() =>
                                            handleCopyToClip(locationText)
                                        }
                                    />
                                )}
                            <h4 className='requestDetails__location--title'>
                                {getLocationPreferenceStr(
                                    data.job.location.locationPreference
                                )}
                            </h4>
                            <p className='requestDetails__location--text'>
                                {locationText}
                            </p>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default TaskPostedDetails;
