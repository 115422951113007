// importing modules
import React from 'react';
// importing components
import { IonAvatar, IonImg } from '@ionic/react';
// importing graphql utilities - types
import { FirebaseHelper } from '../../helpers/FirebaseHelper';
// importing styles
import './SkillCreationUserImg.css';
// firebase context
import { useAuth } from '../../context/firebase/authContext';

const SkillSelectionPage: React.FC = () => {
    const { currentUser } = useAuth();
    const fuser = new FirebaseHelper(currentUser);

    return (
        <IonAvatar className='skillCreation__user--img--wrap'>
            <IonImg
                className='skillCreation__user--img'
                src={fuser.photoURL}
                alt='user'
            />
        </IonAvatar>
    );
};

export default SkillSelectionPage;
