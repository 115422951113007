// importing modules
import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import _ from 'lodash';
// importing components
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonIcon,
    IonImg,
    IonRow,
    IonPage,
} from '@ionic/react';
import SkillInfoModal from '../SkillInfoModal/SkillInfoModal';
import CreateTaskTabs from '../UI/CreateTaskTabs/CreateTaskTabs';
import UserImg from '../UI/UserImg/UserImg';
import GroupImg from '../UI/GroupImg/GroupImg';
import Loading from '../../../components/status/Loading';
import Strokes from 'components/Strokes';
// importing assets
import { closeOutline, searchOutline } from 'ionicons/icons';
// importing helpers
import { Status } from '../../../GraphQL/__generated__/globalTypes';
import {
    addSkillToLocalStorage,
    deleteSkillFromLocalStorage,
    getLocalStorage,
} from '../../../helpers/localStorage/localStorageHelper';
// importing graphql
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
// importing styles
import './SkillSearch.css';

const DEFAULT_SKILLS = [
    {
        id: '123',
        category: 'Professional Services',
        keysWords: [
            'Copywriting',
            'Graphic Design',
            'Career Coaching',
            'Accounting',
            'Resume Services',
            'Event Planning',
            'Car Detailing ',
        ],
    },
    {
        id: '456',
        category: 'Help At Home',
        keysWords: [
            'Errands',
            'House Painting',
            'Landscaping',
            'Gardening',
            'Interior Design & Decorating',
            'Grocery Shopping',
            'Moving Services',
        ],
    },
];

type Skill = {
    name: string;
    description: string;
    additonalDetails: string;
    peopleNeededCount: string;
    status: Status.OPEN;
};
interface SkillKeywords {
    id: number;
    keyword?: string;
    keywordArray?: string[];
    category: string;
}
interface SkillKeywordsData {
    keywords: SkillKeywords[];
}
const GET_KEYWORDS = gql`
    query getKeywords {
        keywords {
            id
            keyword
            category
        }
    }
`;

const SkillSearch: React.FC<{ taskType: string }> = ({ taskType }) => {
    const history = useHistory();
    const location = useLocation();
    const { groupId, groupCode } = useParams<{
        groupId: string;
        groupCode: string;
    }>();
    const [searchInput, setSearchInput] = useState<string>('');
    const [categoryInitialState, setCategoryInitialState] = useState([]); // needs to typed later
    const [categoryArr, setCategoryArr] = useState<SkillKeywords[]>([]);
    const [filteredKeywords, setFilteredKeywords] = useState<SkillKeywords[]>(
        []
    );
    const [skill, setSkill] = useState<string>('');
    const [skillsNeeded, setSkillsNeeded] = useState<Skill[]>([]);
    const [modalStatus, setModalStatus] = useState<boolean>(false);

    useEffect(() => {
        if (getLocalStorage('createTask')) {
            const createTaskObj = getLocalStorage('createTask');
            setSkillsNeeded(createTaskObj.skillsNeeded);
        } else {
            history.push('/home');
        }
    }, [history, location]);

    const handleAddSkill = (skillObj: Skill) => {
        setSkillsNeeded((prevSkills) => [...prevSkills, skillObj]);
        addSkillToLocalStorage('createTask', 'skillsNeeded', skillObj);
    };
    const handleRemoveSkill = (skillName: string) => {
        setSkillsNeeded(
            skillsNeeded.filter((skill) => skill.name !== skillName)
        );
        deleteSkillFromLocalStorage('createTask', 'skillsNeeded', skillName);
    };

    const handleModal = () => setModalStatus(!modalStatus);

    const handleConfirmation = () => {
        if (taskType === 'HOME') {
            history.push('/create-task/invites');
        } else if (taskType === 'GROUP') {
            history.push(`/create-task/${groupId}/${groupCode}/invites`);
        }
    };

    // --------------- keywords logic ---------------
    const { loading, data } = useQuery<SkillKeywordsData>(GET_KEYWORDS);

    if (loading) {
        return <Loading />;
    }

    const categoriesRefObj = {
        professionalservices: [0, 'Professional Services'],
        helpathome: [1, 'Help At Home'],
        education: [2, 'Education'],
        healthwellness: [3, 'Health & Wellness'],
        communityservice: [4, 'Community Service'],
        elderlysupport: [5, 'Elderly Support'],
        disasterresponse: [6, 'Disaster Response'],
        hobbies: [7, 'Hobbies'],
    };

    const categories = [
        'professionalservices',
        'helpathome',
        'education',
        'healthwellness',
        'communityservice',
        'elderlysupport',
        'disasterresponse',
        'hobbies',
    ];

    if (searchInput.length === 0 && categoryInitialState.length === 0) {
        categories.forEach((category, index) => {
            // creates an object with the category name & empty array of keywords
            // const obj = { title: category, keyword: [] }
            const obj: SkillKeywords = {
                id: index,
                category: category,
                keywordArray: [],
            };
            categoryArr.push(obj);
        });

        setCategoryInitialState(_.cloneDeep(categoryArr));
    }

    function inputStateAndCategoriesSetter(input, categoryArray) {
        setSearchInput(input);
        setCategoryArr(categoryArray);
    }

    function keywordAssign(input) {
        let filterByInput = data.keywords.filter((kw) =>
            kw.keyword.toLowerCase().includes(input.toLowerCase())
        ); // pair down keywords from database based on input from searchbar
        let template = _.cloneDeep(categoryInitialState); // make a clone of category template to fill in results later

        filterByInput.forEach((fkw) => {
            // loop through keywords to assign to appropriate category in template
            let categoryName = fkw.category;
            let categoryIndex = categoriesRefObj[`${categoryName}`][0];

            if (template[categoryIndex].category === fkw.category) {
                template[categoryIndex].keywordArray.push(fkw.keyword); //filling in our template based on placing keywords in correct category
            }
        });
        inputStateAndCategoriesSetter(input, template); //post compiling filled in template THEN AND ONLY THEN SETSTATE.
        /* W A R N I N G   ==>  DO NOT PLACE  setSearchInput(input) &&  setCategoryArr(categoryArray)  INSIDE THIS FUNCTION
        IT WILL! ==> RUN ASYNC AND YOU WILL LOSE THE POPULATED TEMPLATE DATA BEFORE IT GETS SET TO STATE DUE TO RE-RENDER
        */
    }

    let img;
    if (taskType === 'HOME' && getLocalStorage('currentAdminGroupId') == 0) {
        img = <UserImg />;
    } else if (
        taskType === 'GROUP' &&
        getLocalStorage('currentAdminGroupId') == 0
    ) {
        img = <UserImg />;
    } else {
        img = <GroupImg />;
    }

    return (
        <IonPage>
            <IonContent>
                <IonGrid>
                    <SkillInfoModal
                        taskType={taskType}
                        modalStatus={modalStatus}
                        handleModal={handleModal}
                        handleAddSkill={handleAddSkill}
                        handleConfirmation={handleConfirmation}
                        skill={skill}
                    />
                    {/* skillSearch header */}
                    <IonRow className='skillSearch__header'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='skillSearch__header--container'
                        >
                            <Strokes top={true} />
                            <CreateTaskTabs disablePrevStep={false} />
                            {img}
                        </IonCol>
                    </IonRow>
                    {/* text content */}
                    <IonRow className='skillSearch__textContent'>
                        <IonCol
                            className='skillSearch__textContent--container'
                            sizeSm='12'
                            sizeXs='12'
                        >
                            {skillsNeeded.length > 0 ? (
                                <h1 className='skillSearch__textContent--title'>
                                    What other <span>skills</span> are needed?
                                </h1>
                            ) : (
                                <h1 className='skillSearch__textContent--title'>
                                    What kind of <span>skills</span> are needed?
                                </h1>
                            )}
                            <p className='skillSearch__textContent--text'>
                                Members will apply for this task by the skill
                                that’s needed so make sure to be as detailed as
                                possible
                            </p>
                        </IonCol>
                    </IonRow>
                    {/* skills currently selected */}
                    <IonRow className='skillSearch__item--container'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='skillSearch__item--col'
                        >
                            {skillsNeeded.length > 0
                                ? skillsNeeded.map((skill, index) => (
                                      <div
                                          key={index}
                                          onClick={() =>
                                              handleRemoveSkill(skill.name)
                                          }
                                          className='skillSearch__item--keyword'
                                      >
                                          {skill.name}&nbsp;
                                          <IonIcon icon={closeOutline} />
                                      </div>
                                  ))
                                : null}
                        </IonCol>
                    </IonRow>
                    {/* skillSearch searchbar */}
                    <IonRow className='skillSearch__searchbar'>
                        <IonCol
                            sizeXs='12'
                            sizeSm='12'
                            className='skillSearch__searchbar--container'
                        >
                            <div className='skillSearch__searchbar--input--wrap'>
                                <IonIcon
                                    className='skillSearch__searchbar--input--icon'
                                    icon={searchOutline}
                                />
                                <input
                                    className='skillSearch__searchbar--input'
                                    type='text'
                                    placeholder='Search by name or skill'
                                    onChange={(event) => {
                                        keywordAssign(event.target.value);
                                    }}
                                    value={searchInput}
                                />
                            </div>
                        </IonCol>
                    </IonRow>
                    <IonGrid>
                        {searchInput.length === 0
                            ? DEFAULT_SKILLS.map((skill) => (
                                  <IonRow
                                      className='skillSearch__item--container'
                                      key={skill.id}
                                  >
                                      <IonCol
                                          className='skillSearch__item--col'
                                          sizeXs='12'
                                          sizeSm='12'
                                      >
                                          <h1 className='skillSearch__item--title'>
                                              {skill.category}
                                          </h1>
                                      </IonCol>
                                      <IonCol
                                          className='skillSearch__item--col'
                                          sizeXs='12'
                                          sizeSm='12'
                                      >
                                          {skill.keysWords.map((keyword) => (
                                              <div
                                                  key={keyword}
                                                  onClick={() => {
                                                      setSkill(keyword);
                                                      handleModal();
                                                  }}
                                                  className='skillSearch__item--keyword'
                                              >
                                                  {keyword}
                                              </div>
                                          ))}
                                      </IonCol>
                                  </IonRow>
                              ))
                            : categoryArr.map((category) => {
                                  if (category.keywordArray.length > 0) {
                                      return (
                                          <IonRow
                                              className='skillSearch__item--container'
                                              key={`categoryId:${category.id}`}
                                          >
                                              <IonCol
                                                  className='skillSearch__item--col'
                                                  sizeXs='12'
                                                  sizeSm='12'
                                              >
                                                  <h1 className='skillSearch__item--title'>
                                                      {
                                                          categoriesRefObj[
                                                              `${category.category}`
                                                          ][1]
                                                      }
                                                  </h1>
                                              </IonCol>
                                              <IonCol
                                                  className='skillSearch__item--col'
                                                  sizeXs='12'
                                                  sizeSm='12'
                                              >
                                                  {category.keywordArray.map(
                                                      (kw) => (
                                                          <div
                                                              key={kw}
                                                              onClick={() => {
                                                                  setSkill(kw);
                                                                  handleModal();
                                                              }}
                                                              className='skillSearch__item--keyword'
                                                          >
                                                              {kw}
                                                          </div>
                                                      )
                                                  )}
                                              </IonCol>
                                          </IonRow>
                                      );
                                  }
                              })}
                    </IonGrid>
                    <IonRow className='skillSearch__btn--container'>
                        <IonCol
                            className='skillSearch__btn--wrap'
                            sizeXs='12'
                            sizeSm='12'
                        >
                            <IonButton
                                fill='clear'
                                disabled={skillsNeeded.length === 0}
                                color='ion-primary'
                                className='createTask__btn'
                                onClick={handleConfirmation}
                            >
                                Continue
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default SkillSearch;
