import React from 'react';
import { ThemeProvider } from 'styled-components';
import GlobalStyles from '../../theme/GlobalStyles';
import theme from '../../theme/theme';

const Layout: React.FC = ({ children }) => {
    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles />
            {children}
        </ThemeProvider>
    );
};

export default Layout;
