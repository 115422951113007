export const firebaseConfig = {
    apiKey: 'AIzaSyAFpACthFen5ezD7DqMv6oxuuMODb5riJI',
    authDomain: 'ying2-54b82.firebaseapp.com',
    databaseURL: 'https://ying2-54b82.firebaseio.com',
    projectId: 'ying2-54b82',
    storageBucket: 'ying2-54b82.appspot.com',
    messagingSenderId: '113559282527',
    appId: '1:113559282527:web:6ae2a718b257a83c310cea',
    measurementId: 'G-CXDZW5RWQD',
};

export const firebaseConfigTesting = {
    apiKey: 'AIzaSyC-6J3PABNmp_SnkEx5fCCgN5mh5oxTb2c',
    authDomain: 'yingtesting-f8e17.firebaseapp.com',
    projectId: 'yingtesting-f8e17',
    storageBucket: 'yingtesting-f8e17.appspot.com',
    messagingSenderId: '48314543836',
    appId: '1:48314543836:web:9f25828e271873f9578e19',
    measurementId: 'G-JN06JDG859',
};
