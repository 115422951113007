import React from 'react';

const UserMenuBackdrop: React.FC<{
    handleMenuToggle: () => void;
}> = ({ handleMenuToggle }) => {
    return (
        <div onClick={handleMenuToggle} className='menu__sidebar--backdrop' />
    );
};

export default UserMenuBackdrop;
