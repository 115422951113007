// importing modules
import React from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
// importing components
import {
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonPage,
    IonRow,
} from '@ionic/react';
import ParticipationRequestItemList from './ParticipationRequestItemList/ParticipationRequestItemList';
import Loading from '../../components/status/Loading';
import Strokes from 'components/Strokes';
import BackButton from 'components/BackButton';
// importing assets
// importing types & graphql
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_JOB_POST_BY_ID } from '../../GraphQL/JobPost/GET_JOB_POST_BY_ID/GET_JOB_POST_BY_ID';
import {
    GetJobPostById,
    GetJobPostByIdVariables,
} from '../../GraphQL/__generated__/GetJobPostById';
// importing styles
import './ManageRequests.css';
import { useAuth } from '../../context/firebase/authContext';
import { ADMIN_GROUP_PROFILE_PICTURE } from 'GraphQL/Admin/adminGroupProfilePicture/adminGroupProfilePicture';

const ManageRequests: React.FC = () => {
    const { currentUser } = useAuth();
    const { taskId } = useParams<{ taskId: string }>();
    const history = useHistory();

    const { data, loading } = useQuery<GetJobPostById, GetJobPostByIdVariables>(
        GET_JOB_POST_BY_ID,
        {
            variables: { jobPostId: parseInt(taskId) },
            fetchPolicy: 'cache-and-network',
        }
    );

    console.debug(data);
    const { data: adminData, loading: adminLoading } = useQuery(
        ADMIN_GROUP_PROFILE_PICTURE,
        {
            variables: { adminId: data?.jobPost.adminId },
        }
    );

    if (loading) {
        return <Loading />;
    }

    // current user uid has to match the jobpost poster's uid
    if (currentUser.uid != data.jobPost.recipient.uid) {
        return <Redirect to='/' />;
    }
    console.debug(data);

    return (
        <IonPage>
            <IonContent>
                <IonGrid>
                    <IonRow className='manageReq__header'>
                        {console.debug(data.jobPost)}
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='manageReq__header--container'
                        >
                            <BackButton color='white' />
                            <Strokes top={true} />
                            <IonImg
                                className='createTask__header--img'
                                src={
                                    data.jobPost.adminId
                                        ? adminLoading
                                            ? null
                                            : adminData.AdminGroupProfilePicture
                                                  .orgPicture
                                        : data.jobPost.recipient.profilePicture
                                }
                                alt=''
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow className='manageReq__textContent'>
                        <IonCol
                            className='manageReq__textContent--container'
                            sizeSm='12'
                            sizeXs='12'
                        >
                            <h1 className='manageReq__textContent--title'>
                                Manage participation requests for{' '}
                                <span>{data.jobPost.title}</span>
                            </h1>
                            <p className='manageReq__textContent--text'>
                                By accepting members for your task, members will
                                be able to see the exact location of this task.
                                Upon completion of the task, Balance Tokens will
                                be exchanged.
                            </p>
                        </IonCol>
                    </IonRow>
                    <ParticipationRequestItemList
                        skillsNeeded={data.jobPost.skillsNeeded}
                    />
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default ManageRequests;
