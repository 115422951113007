// importing modules
import React, { useState, useRef, useEffect } from 'react';
// importing components
import { IonButton, IonCol, IonIcon, IonImg } from '@ionic/react';
import {
    addOutline,
    closeOutline,
    listOutline,
    people,
    chevronForwardOutline,
} from 'ionicons/icons';
import CurrencyDisplay from './CurrencyDisplay/CurrencyDisplay';
import OrganizationItem from './OrganizationItem/OrganizationItem';
import { useHistory } from 'react-router-dom';
import { FirebaseHelper } from 'helpers/FirebaseHelper';
import {
    getLocalStorage,
    updateLocalStorage,
    deleteLocalStorage,
} from 'helpers/localStorage/localStorageHelper';
import moment from 'moment-timezone';
import MenuItem from './MenuItem/MenuItem';
import Loading from 'components/status/Loading';
import Logout from 'auth/Logout';
// importing utilites
import links from './MenuItem/links';
// importing graphql
import { useQuery } from '@apollo/react-hooks';
import {
    RETRIEVE_PROFILE__INITIALIZE_IF_NEEDED,
    RETRIEVE_PROFILE__INITIALIZE_IF_NEEDEDVariables,
} from 'GraphQL/__generated__/RETRIEVE_PROFILE__INITIALIZE_IF_NEEDED';
import { gql_RETRIEVE_PROFILE__INITIALIZE_IF_NEEDED } from 'GraphQL/Profile/RETRIEVE_PROFILE__INITIALIZE_IF_NEEDED/RETRIEVE_PROFILE__INITIALIZE_IF_NEEDED';
import { USER_MENU_STATE } from 'GraphQL/Profile/USER_MENU_STATE/USER_MENU_STATE';
import {
    UserMenuStateVariables,
    UserMenuState,
} from 'GraphQL/__generated__/UserMenuState';
import { gql_ADMIN_CHECK } from 'GraphQL/Admin/adminCheck';
import {
    ListOfOrgs,
    ListOfOrgsVariables,
} from 'GraphQL/__generated__/ListOfOrgs';
import { GET_TIME_CREDITS_BY_UID } from 'GraphQL/Ledger/GET_TIME_CREDITS_BY_UID';
import {
    GetTimeCreditsByUid,
    GetTimeCreditsByUidVariables,
} from 'GraphQL/__generated__/GetTimeCreditsByUid';
import { USER_PROFILE_BY_UID } from 'GraphQL/Profile/UserProfile/USER_PROFILE_BY_UID';
import {
    UserProfile_byID,
    UserProfile_byIDVariables,
} from 'GraphQL/__generated__/UserProfile_byID';
// user party info
import { GET_USER_PARTY_INFO } from 'GraphQL/parties/GET_USER_PARTY_INFO';
import {
    GetUserPartyInfo,
    GetUserPartyInfoVariables,
} from 'GraphQL/__generated__/GetUserPartyInfo';
// group party info
import { GET_GROUP_PARTY_INFO } from 'GraphQL/groupParties/GET_GROUP_PARTY_INFO';
import {
    GetGroupPartyInfo,
    GetGroupPartyInfoVariables,
} from 'GraphQL/__generated__/GetGroupPartyInfo';
// importing styles
import './UserMenu.css';

// firebase
import { useAuth } from '../../context/firebase/authContext';

export default ({
    handleMenuToggle,
    userUid,
}: {
    handleMenuToggle: () => void;
    userUid: string;
}) => {
    const [currencyShown, setCurrencyShown] =
        useState<string>('balance-tokens');
    const [orgDetailOpen, setOrgDetailOpen] = useState<boolean>(false);

    const { currentUser } = useAuth();
    const [receiverId, receiverIdData] = useState(0);

    const {
        data: orgsData,
        loading: orgsLoading,
        error: orgsError,
    } = useQuery<ListOfOrgs, ListOfOrgsVariables>(gql_ADMIN_CHECK, {
        variables: { uid: currentUser.uid },
    });

    const { data, loading, error } = useQuery<
        UserMenuState, // query return return type (shape of data)
        UserMenuStateVariables // query request type (shape of variables)
    >(USER_MENU_STATE, {
        variables: {
            subId: userUid,
        },
    });

    let adminTestAdmin = 0;

    if (getLocalStorage('currentAdminGroupId') !== 0) {
        adminTestAdmin = getLocalStorage('currentAdminGroupId');
    }

    //Grabbing the userId
    const {
        data: dataReceiverOne,
        error: something,
        loading: stillLoading,
    } = useQuery<UserProfile_byID, UserProfile_byIDVariables>(
        USER_PROFILE_BY_UID,
        {
            variables: { subId: userUid },
            fetchPolicy: 'no-cache',
        }
    );

    const { data: dataReceiverTwo, loading: receiverloading } = useQuery<
        GetUserPartyInfo,
        GetUserPartyInfoVariables
    >(GET_USER_PARTY_INFO, {
        skip: !dataReceiverOne,
        variables: { userId: dataReceiverOne && dataReceiverOne?.profile.id },
    });

    const { data: dataGroup, loading: groupLoading } = useQuery<
        GetGroupPartyInfo,
        GetGroupPartyInfoVariables
    >(GET_GROUP_PARTY_INFO, {
        variables: { groupIdFk: adminTestAdmin },
    });

    // find the correct balance account
    useEffect(() => {
        if (getLocalStorage('currentAdminGroupId') !== 0) {
            dataGroup?.showAllParties.map((thing) => {
                if (thing.bankAccountrelation[0].accountType == 'BALANCE') {
                    receiverIdData(thing.bankAccountrelation[0].balance);
                }
            });
        } else {
            dataReceiverTwo?.showAllParties.map((thing) => {
                if (thing.bankAccountrelation[0].accountType == 'BALANCE') {
                    receiverIdData(thing.bankAccountrelation[0].balance);
                }
            });
        }
    });

    const { data: currencyDisplayData, loading: currencyLoading } = useQuery<
        GetTimeCreditsByUid, // query return return type (shape of data)
        GetTimeCreditsByUidVariables // query request type (shape of variables)
    >(GET_TIME_CREDITS_BY_UID, {
        variables: {
            uid: userUid,
            admin: adminTestAdmin,
        },
        pollInterval: 5000,
    });

    const history = useHistory();

    if (error) {
        console.error(error);
    }

    if (loading || currencyLoading) {
        return <Loading />;
    }

    return (
        <>
            <IonCol sizeXs='12' sizeSm='12' className='menu__header'>
                <IonButton
                    fill='clear'
                    color='ion-primary'
                    className='menu__btn--close'
                    style={{ color: '#000' }}
                    onClick={handleMenuToggle}
                >
                    <IonIcon
                        className='menu__btn--close--icon'
                        icon={closeOutline}
                    />
                </IonButton>
            </IonCol>

            {/* admin switch */}
            <IonButton
                fill='clear'
                routerLink='/accountswitch'
                onClick={handleMenuToggle}
                color='#ffffff'
                className='menu__sidebar--item flexWrap'
            >
                <span className='menu__sidebar--item--span otherPercent'>
                    {(!loading &&
                        getLocalStorage('currentAdminGroupId') == 0) ||
                    !getLocalStorage('currentAdminGroupId') ? (
                        <IonImg
                            className='menu__sidebar--item--img'
                            src={!loading && data?.profile?.profilePicture}
                            alt='user'
                        />
                    ) : (
                        <IonImg
                            className='menu__sidebar--item--img'
                            src={
                                !loading &&
                                getLocalStorage('currentAdminGroupPicture')
                            }
                            alt='user'
                        />
                    )}
                </span>

                <span className='menu__sidebar--item-span smallIconFlex'>
                    {(!loading &&
                        getLocalStorage('currentAdminGroupId') == 0) ||
                    !getLocalStorage('currentAdminGroupId') ? (
                        !orgsLoading &&
                        orgsData?.listOfOrgs?.map((org) => (
                            <span key={org.organization.id}>
                                <IonImg
                                    className='menu__sidebar--item--img'
                                    src={org.organization.orgPicture}
                                    alt='user'
                                />
                            </span>
                        ))
                    ) : (
                        <IonImg
                            className='menu__sidebar--item--img'
                            src={!loading && data?.profile?.profilePicture}
                            alt='user'
                        />
                    )}
                </span>
            </IonButton>

            <p className='paddingRight'>
                {!loading && data?.profile?.firstName}{' '}
                {!loading && data?.profile?.lastName}{' '}
                {(!loading && getLocalStorage('currentAdminGroupId') == 0) ||
                !getLocalStorage('currentAdminGroupId')
                    ? ''
                    : `(admin)`}
            </p>

            {/* currency display  currencyDisplayData */}

            <CurrencyDisplay
                handleCurrencyShown={setCurrencyShown}
                currencyShown={currencyShown}
                data={{ balanceByUID: receiverId }}
                loading={currencyLoading}
            />
            {/* menu sidebar nav list */}
            <IonCol sizeXs='12' sizeSm='12' className='menu__sidebar--list'>
                {currencyShown === 'cash' ? (
                    <IonButton
                        fill='clear'
                        color='ion-primary'
                        className='menu__sidebar--item--cashout'
                        onClick={() => {
                            history.push('/settings/payoutmethods');
                        }}
                    >
                        Manage Funds
                    </IonButton>
                ) : null}

                {/* profile */}
                {(!loading && getLocalStorage('currentAdminGroupId') == 0) ||
                !getLocalStorage('currentAdminGroupId') ? (
                    <IonButton
                        fill='clear'
                        routerLink={`/portfolio/${userUid}`}
                        onClick={handleMenuToggle}
                        color='ion-primary'
                        className='menu__sidebar--item'
                    >
                        <span className='menu__sidebar--item--span'>
                            <IonImg
                                className='menu__sidebar--item--img'
                                src={!loading && data?.profile?.profilePicture}
                                alt='user'
                            />
                            {/* <IonImg className="menu__sidebar--item--img" src={!loading && data?.profile?.profilePicture} alt="user" /> */}
                            &nbsp;&nbsp; My Profile
                        </span>
                    </IonButton>
                ) : (
                    <></>
                )}

                {/* user organizations items */}
                <details
                    open={orgDetailOpen}
                    onClick={(e) => {
                        e.preventDefault();
                        setOrgDetailOpen(!orgDetailOpen);
                    }}
                >
                    <summary>
                        <IonButton
                            fill='clear'
                            color='ion-primary'
                            className='menu__sidebar--item'
                        >
                            <span className='menu__sidebar--item--span'>
                                <IonIcon
                                    className='menu__sidebar--item--icon'
                                    icon={people}
                                    slot='start'
                                />
                                My Groups
                            </span>
                            {/* ARROW ICON */}
                            <IonIcon
                                className={
                                    orgDetailOpen
                                        ? 'transition--down'
                                        : 'transition--up'
                                }
                                icon={chevronForwardOutline}
                                style={{ fontSize: '24px' }}
                            />
                        </IonButton>
                    </summary>

                    {!loading &&
                        getLocalStorage('currentAdminGroupId') === 0 &&
                        data?.profile?.organizations?.length > 0 &&
                        data.profile.organizations.map((org) => (
                            <OrganizationItem
                                key={org.id}
                                {...org}
                                handleMenuToggle={handleMenuToggle}
                            />
                        ))}
                    {!loading &&
                        getLocalStorage('currentAdminGroupId') !== 0 &&
                        data?.profile?.organizations?.length > 0 &&
                        data.profile.organizations.map((org) => {
                            if (
                                org.id ===
                                getLocalStorage('currentAdminGroupId')
                            ) {
                                return (
                                    <OrganizationItem
                                        key={org.id}
                                        {...org}
                                        handleMenuToggle={handleMenuToggle}
                                    />
                                );
                            }
                        })}
                </details>

                {/* Transaction History*/}
                <IonButton
                    routerLink={`/transactionHistory`}
                    onClick={handleMenuToggle}
                    fill='clear'
                    color='ion-primary'
                    className='menu__sidebar--item'
                >
                    <span className='menu__sidebar--item--span'>
                        <IonIcon
                            className='menu__sidebar--item--icon--list'
                            icon={listOutline}
                            slot='start'
                        />
                        Transaction History
                    </span>
                </IonButton>

                {/* links list */}
                {links.map((link) => (
                    <MenuItem
                        key={link.id}
                        {...link}
                        handleMenuToggle={handleMenuToggle}
                    />
                ))}
            </IonCol>

            {/* sidebar footer */}
            <IonCol className='menu__sidebar--footer'>
                {/* admin group tiles */}
                {/* { MOCK_ORGS.length > 0 && MOCK_ORGS.map((org) => <AdminOrganizationItem key={org.id} {...org} />) } */}
                <IonButton
                    routerLink='/joinGroup'
                    onClick={handleMenuToggle}
                    fill='clear'
                    color='ion-primary'
                    className='menu__sidebar--footer--item'
                >
                    <span className='menu__sidebar--footer--item--span'>
                        <IonIcon
                            className='menu__sidebar--item--icon'
                            icon={addOutline}
                            slot='start'
                        />
                        Join a New Group
                    </span>
                </IonButton>
                <Logout />
            </IonCol>
        </>
    );
};
