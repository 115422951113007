import React, { useState } from 'react';
import yingLogo from '../../../assets/ying-logo-green.png';
import './LocationPin.css';

const LocationPin = ({ text }) => {
    const [showAddress, setShowAddress] = useState<boolean>(false);
    return (
        <div className='pin'>
            {showAddress && (
                <div className='pin__text--wrap speech-bubble'>
                    <p className='pin__text'>{text}</p>
                    <p
                        className='pin__text--btn'
                        onClick={() => setShowAddress(false)}
                    >
                        x
                    </p>
                </div>
            )}
            <div className='pin__icon--wrap'>
                <img
                    onClick={() => setShowAddress(!showAddress)}
                    className='pin__icon'
                    src={yingLogo}
                    alt='ying logo'
                />
            </div>
        </div>
    );
};

export default LocationPin;
