// importing modules
import React, { useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
// importing components
import {
    IonButton,
    IonCol,
    IonImg,
    IonPage,
    IonContent,
    IonRow,
    // IonIcon,
} from '@ionic/react';
import CompleteTaskNavTabs from '../UI/CompleteTaskTabs/CompleteTaskTabs';
import UserImg from '../../../components/UserImg/UserImg';
import GroupImg from 'pages/CreateTask/UI/GroupImg/GroupImg';
import TaskParticipantItem from './TaskParticipantItem/TaskParticipantItem';
import CreditsModal from './ParticipantCreditsModal/ParticipantCreditModal';
import RemoveParticipantModal from './RemoveParticipantModal/RemoveParticipantModal';
import InviteModal from './ParticipantInviteModal/TaskInviteModal';
import CompleteTaskFooter from '../../../components/FooterWithChildren/FooterWithChildren';
import Strokes from 'components/Strokes';
// importing assets
// import { arrowForwardOutline } from 'ionicons/icons';
// importing utils
import { getLocalStorage } from '../../../helpers/localStorage/localStorageHelper';
// importing types
import { Status } from '../../../GraphQL/__generated__/globalTypes';
//importing styles
import './TaskParticipants.css';
import { GET_JOB_POST_RECIPIENT } from '../../../GraphQL/JobPost/GET_JOB_POST_RECEPIENT/GET_JOB_POST_RECIPIENT';
import {
    GetJobPostRecipient,
    GetJobPostRecipientVariables,
} from '../../../GraphQL/__generated__/GetJobPostRecipient';
import { useQuery } from '@apollo/react-hooks';
import Loading from '../../../components/status/Loading';
import { useAuth } from '../../../context/firebase/authContext';
import {
    GetJobPostById,
    GetJobPostByIdVariables,
} from 'GraphQL/__generated__/GetJobPostById';
import { GET_JOB_POST_BY_ID } from 'GraphQL/JobPost/GET_JOB_POST_BY_ID/GET_JOB_POST_BY_ID';
import { ADMIN_GROUP_PROFILE_PICTURE } from 'GraphQL/Admin/adminGroupProfilePicture/adminGroupProfilePicture';

const TaskParticipants: React.FC = () => {
    const { currentUser } = useAuth();
    const { taskId } = useParams<{ taskId: string }>();
    const history = useHistory();
    const currentParticipants = getLocalStorage(
        'completeTaskPostedViaRecipient'
    ).participants;

    // const { data, loading } = useQuery<
    //     GetJobPostRecipient,
    //     GetJobPostRecipientVariables
    // >(GET_JOB_POST_RECIPIENT, {
    //     variables: { jobPostId: parseInt(taskId) },
    // });

    const { data, loading } = useQuery<GetJobPostById, GetJobPostByIdVariables>(
        GET_JOB_POST_BY_ID,
        {
            variables: { jobPostId: parseInt(taskId) },
            fetchPolicy: 'cache-and-network',
        }
    );
    const { data: adminData, loading: adminLoading } = useQuery(
        ADMIN_GROUP_PROFILE_PICTURE,
        {
            variables: { adminId: data?.jobPost.adminId },
        }
    );

    const [selectedParticipant, setSelectedParticipant] = useState<any>({});

    const [showCreditsModal, setShowCreditsModal] = useState<boolean>(false);
    const [showParticipantModal, setShowParticipantModal] =
        useState<boolean>(false);
    const [showInviteModal, setShowInviteModal] = useState<boolean>(false);

    if (loading) {
        return <Loading />;
    }

    // current user uid has to match the jobpost poster's uid
    if (currentUser.uid != data.jobPost.recipient.uid) {
        return <Redirect to='/' />;
    }

    const handleCreditsModal = () => setShowCreditsModal(!showCreditsModal);
    const handleParticipantModal = () =>
        setShowParticipantModal(!showParticipantModal);
    const handleInviteModal = () => setShowInviteModal(!showInviteModal);

    const handleSelectParticipant = (id: number) =>
        setSelectedParticipant(currentParticipants.find((p) => p.id === id));

    return (
        <IonPage>
            <IonContent fullscreen>
                <IonContent>
                    <CreditsModal
                        selectedParticipant={selectedParticipant}
                        showCreditsModal={showCreditsModal}
                        handleCreditsModal={handleCreditsModal}
                    />
                    <RemoveParticipantModal
                        selectedParticipant={selectedParticipant}
                        showParticipantModal={showParticipantModal}
                        handleParticipantModal={handleParticipantModal}
                    />
                    <InviteModal
                        showInviteModal={showInviteModal}
                        handleInviteModal={handleInviteModal}
                    />
                    <IonRow className='taskPartc__header'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='taskPartc__header--container'
                        >
                            <Strokes top={true} />
                            <CompleteTaskNavTabs
                                disablePrevStep={false}
                                prevPage={`/completeTaskPosted/recipient/${taskId}`}
                            />
                            <IonImg
                                className='createTask__header--img'
                                src={
                                    data.jobPost.adminId
                                        ? adminLoading
                                            ? null
                                            : adminData.AdminGroupProfilePicture
                                                  .orgPicture
                                        : data.jobPost.recipient.profilePicture
                                }
                                alt='user'
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow className='taskPartc__textContent'>
                        <IonCol
                            className='taskPartc__textContent--container'
                            sizeSm='12'
                            sizeXs='12'
                        >
                            <h1 className='taskPartc__textContent--title'>
                                Did all of these people
                                <span> participate?</span>
                            </h1>
                        </IonCol>
                    </IonRow>
                    <IonRow className='taskPartcItem__row'>
                        {currentParticipants.map((participant) => {
                            if (participant.status === Status.DENIED) {
                                return null;
                            } else {
                                return (
                                    <TaskParticipantItem
                                        handleSelectParticipant={
                                            handleSelectParticipant
                                        }
                                        handleParticipantModal={
                                            handleParticipantModal
                                        }
                                        handleCreditsModal={handleCreditsModal}
                                        key={participant.id}
                                        {...participant}
                                    />
                                );
                            }
                        })}
                    </IonRow>

                    {/* 
                    // the feature to add participants is currently not available
                    <IonRow className="taskPartc__btn--container">
                        <div onClick={handleInviteModal} className="taskPartc__btn--wrap">
                            <p className="taskPartc__btn--text">Add A New Participant</p>
                            <IonIcon icon={arrowForwardOutline} />
                        </div>
                    </IonRow> */}
                </IonContent>
            </IonContent>
            <CompleteTaskFooter>
                <IonButton
                    fill='clear'
                    color='ion-primary'
                    onClick={() =>
                        history.push(
                            `/completeTaskPosted/recipient/${taskId}/rating`
                        )
                    }
                    className='footerWithChild__btn'
                >
                    Continue
                </IonButton>
            </CompleteTaskFooter>
        </IonPage>
    );
};

export default TaskParticipants;
