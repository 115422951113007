// importing modules
import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
// importing components
import {
    IonButton,
    IonContent,
    IonIcon,
    IonPage,
    IonImg,
    IonList,
} from '@ionic/react';
import NavBar from '../../../components/NavBar';
import Strokes from 'components/Strokes';
// importing assets
import { closeOutline, arrowUpOutline } from 'ionicons/icons';
import SenderChatBubble from '../UI/SenderChatBubble';
import ReceiverChatBubble from '../UI/ReceiverChatBubble';
// importing graphql utilities - types
import { useMutation } from '@apollo/react-hooks';
import { PUBLISH_CHAT_NOTIFICATION } from '../../../GraphQL/Device/PUBLISH_CHAT_NOTIFICATION/PUBLISH_CHAT_NOTIFICATION';
import {
    PublishChatNotification,
    PublishChatNotificationVariables,
} from '../../../GraphQL/__generated__/PublishChatNotification';
// importing utilities
import { FirebaseHelper } from '../../../helpers/FirebaseHelper';
import limitStr from '../../../utils/limitString';
// importing styles
import './ChatRoom.css';
// firebase context
import { useAuth } from '../../../context/firebase/authContext';
import firebase from 'firebase/app';
import 'firebase/firestore';

interface UserDetails {
    id: string;
    imageUrl: string;
    firstName: string;
    lastName: string;
}

interface Users {
    currentUser: UserDetails;
    otherUser: UserDetails;
}

interface Messages {
    id: string;
    sentAt: any;
    message: string;
    sender: string;
    senderName: string;
    seen: boolean;
}

const ChatRoom: React.FC = () => {
    const fuser = new FirebaseHelper(useAuth().currentUser);
    const firestore = firebase.firestore();
    const history = useHistory();
    const location = useLocation<Users>();
    const { currentUser, otherUser } = location.state;
    const [chatroomId, setChatroomId] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [messages, setMessages] = useState<Messages[]>([]);
    const [isLoadingMessages, setIsLoadingMessages] = useState<boolean>(true);
    const [publishNotification, publishNotificationDatas] = useMutation<
        PublishChatNotification,
        PublishChatNotificationVariables
    >(PUBLISH_CHAT_NOTIFICATION);

    useEffect(() => {
        const { serverTimestamp } = firebase.firestore.FieldValue;
        scrollToBottom();

        const unsubscribe = firestore
            .collection('chatrooms')
            .where(
                `users.${location.state.currentUser.id}.id`,
                '==',
                location.state.currentUser.id
            )
            .where(
                `users.${location.state.otherUser.id}.id`,
                '==',
                location.state.otherUser.id
            )
            .onSnapshot((querySnapShot) => {
                if (querySnapShot.docs.length > 0) {
                    firestore
                        .collection('chatrooms')
                        .doc(querySnapShot.docs[0].id)
                        .collection('messages')
                        .orderBy('sentAt')
                        .onSnapshot(
                            (snapshot) => {
                                const msgs = [];
                                snapshot.forEach((doc) => {
                                    const { id } = doc;
                                    const msgObj = { ...doc.data(), id };
                                    msgs.push(msgObj);
                                });
                                setMessages(msgs);
                                setIsLoadingMessages(false);
                                scrollToBottom();
                            },
                            (err) => console.error(err)
                        );
                } else {
                    firestore
                        .collection('chatrooms')
                        .add({
                            createdAt: serverTimestamp(),
                            usersArr: [
                                location.state.currentUser.id,
                                location.state.otherUser.id,
                            ],
                            users: {
                                [location.state.currentUser.id]: {
                                    ...currentUser,
                                },
                                [location.state.otherUser.id]: {
                                    ...otherUser,
                                },
                            },
                        })
                        .catch((err) =>
                            console.error('Error writing document!', err)
                        );
                }
            });

        return () => unsubscribe();
    }, []);

    const handleSendMessage = async (e) => {
        e.preventDefault();
        if (message.length === 0 || message === '' || message === null) return;
        const { serverTimestamp } = firebase.firestore.FieldValue;
        const chatroomRef = firestore.collection('chatrooms');

        chatroomRef
            .where(
                `users.${location.state.currentUser.id}.id`,
                '==',
                location.state.currentUser.id
            )
            .where(
                `users.${location.state.otherUser.id}.id`,
                '==',
                location.state.otherUser.id
            )
            .get()
            .then((docSnapshot) => {
                if (docSnapshot.docs.length > 0) {
                    setChatroomId(docSnapshot.docs[0].id);
                    firestore
                        .collection('chatrooms')
                        .doc(docSnapshot.docs[0].id)
                        .collection('messages')
                        .add({
                            // check if document exists , if true send message
                            sentAt: serverTimestamp(),
                            message: message,
                            sender: fuser.uid,
                            senderFirstName: currentUser.firstName,
                            senderLastName: currentUser.lastName,
                            senderImg: currentUser.imageUrl,
                            seen: false,
                        })
                        .then(() => {
                            publishNotification({
                                variables: {
                                    senderId: location.state.currentUser.id,
                                    recieverId: location.state.otherUser.id,
                                    textMessage: message,
                                    chatId: docSnapshot.docs[0].id,
                                },
                            }).catch((err) => console.error(err));
                            setMessage('');
                            scrollToBottom();
                        })
                        .catch((err) => console.error(err));
                }
            })
            .catch((error) =>
                console.error('Error getting documents: ', error)
            );
    };

    const scrollToBottom = () => {
        let content = document.querySelector('ion-content');
        content.scrollToBottom();
    };

    return (
        <IonPage>
            <div>
                <div>
                    <div className='chatRoom__header'>
                        <Strokes top={true} />
                    </div>
                </div>
                <div>
                    <div className='chatRoom__header--details--col'>
                        <div className='chatRoom__details--img--wrap'>
                            <IonImg
                                className='chatRoom__details--img'
                                src={location.state.otherUser.imageUrl}
                                alt='user'
                            />
                        </div>
                        <p className='chatRoom__details--name'>
                            {limitStr(
                                `${location.state.otherUser.firstName} ${location.state.otherUser.lastName}`,
                                15
                            )}
                        </p>
                        <IonIcon
                            onClick={() => history.goBack()}
                            icon={closeOutline}
                            className='chatRoom__details--icon'
                        />
                    </div>
                </div>
            </div>
            <IonContent>
                <IonList className='chatRoom__convo'>
                    {messages.length > 0 &&
                        messages.map((message) => {
                            const chatBubble =
                                message.sender === fuser.uid ? (
                                    <SenderChatBubble
                                        key={message.id}
                                        {...message}
                                        chatroomId={chatroomId}
                                    />
                                ) : (
                                    <ReceiverChatBubble
                                        key={message.id}
                                        {...message}
                                    />
                                );
                            return chatBubble;
                        })}
                </IonList>
            </IonContent>
            <div className='chatRoom__input--form'>
                <textarea
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    // type="text"
                    autoComplete='off'
                    className='chatRoom__input'
                    placeholder='Message...'
                ></textarea>
                <IonButton
                    onClick={handleSendMessage}
                    type='submit'
                    fill='clear'
                    color='ion-primary'
                    className='chatRoom__submit'
                >
                    <IonIcon
                        className='chatRoom__submit--icon'
                        icon={arrowUpOutline}
                    />
                </IonButton>
            </div>
            <NavBar />
        </IonPage>
    );
};

export default ChatRoom;
