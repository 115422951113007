// importing modules
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
// importing components
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonPage,
    IonRow,
} from '@ionic/react';
import Strokes from 'components/Strokes';
// importing assets
import yingLogo from '../../../assets/ying-logo-large-blk.png';
// importing helpers
import { getLocalStorage } from '../../../helpers/localStorage/localStorageHelper';
// importing styles
import '../JoinGroup.css';
import { useOnboardingFlow } from '../../../utils/onboarding';

interface GroupMeta {
    id: number;
    code: string;
    name: string;
    orgPicture: string;
}

const JoinGroupConfirmation: React.FC = () => {
    const { name, groupId } = useParams<{ name: string; groupId: string }>();
    const [isOnboarding, setIsOnboarding] = useState<boolean>(
        JSON.parse(localStorage.getItem('isOnboarding'))
    );
    const [groupMeta, setGroupMeta] = useState<GroupMeta>(
        localStorage.getItem('groupMeta')
            ? JSON.parse(localStorage.getItem('groupMeta'))
            : undefined
    );
    const onboardImageLink = useRef(null);
    const joinedGroupLink = useRef(null);
    const [showContinueButton, setShowContinueButton] = useState(true);
    const { next } = useOnboardingFlow();

    useEffect(() => {
        const isOnB = JSON.parse(localStorage.getItem('isOnboarding'));
        setIsOnboarding(isOnB);
    }, []);

    const handleConfirmation = () => {
        setShowContinueButton(false);
        if (isOnboarding === true) {
            localStorage.setItem('isOnboarding', JSON.stringify(false));
            setIsOnboarding(false);
            // onboardImageLink.current?.click();
        } else {
            //joinedGroupLink.current?.click();
        }
        // next takes the onboarding section that we expect ourselves to be on
        // now, so that if this gets called multiple times it only does side
        // effects once
        next('/joinGroup');
    };

    return (
        <IonPage>
            <IonContent scrollY={false}>
                <IonGrid className='joinGroup__grid'>
                    <IonRow className='joinGroup'>
                        <IonCol
                            sizeXs='12'
                            sizeSm='12'
                            className='joinGroup__container'
                        >
                            <Strokes top={true} green={false} />
                            <IonCol
                                sizeXs='10'
                                sizeSm='10'
                                className='joinGroup__container'
                            >
                                <IonImg
                                    className='joinGroup__img'
                                    alt='ying-logo'
                                    src={yingLogo}
                                />
                                <h1 className='joinGroup__title'>
                                    <span className='joinGroup__title--span'>
                                        Boom!{' '}
                                    </span>
                                    Welcome to{' '}
                                    <span className='joinGroup__title--span'>
                                        {name}
                                    </span>
                                </h1>
                                <p className='joinGroup__text'>
                                    You have successfully joined
                                    <br />a group!
                                </p>
                                <a
                                    href='/onboarding/imageupload'
                                    ref={onboardImageLink}
                                    type='hidden'
                                    style={{ display: 'none' }}
                                />
                                <a
                                    href={`/group/${groupId}`}
                                    ref={joinedGroupLink}
                                    type='hidden'
                                    style={{ display: 'none' }}
                                />
                                {showContinueButton && (
                                    <IonButton
                                        onClick={handleConfirmation}
                                        fill='clear'
                                        color='ion-primary'
                                        className='joinGroup__btn--confirm'
                                    >
                                        Continue
                                    </IonButton>
                                )}
                            </IonCol>
                        </IonCol>
                    </IonRow>
                    <Strokes bot={true} />
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default JoinGroupConfirmation;
