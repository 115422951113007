import React from 'react';
import { IonCol, IonImg, IonRow } from '@ionic/react';
import './ProfileHeader.css';
import Strokes from 'components/Strokes';

const ProfileHeader: React.FC<{
    firstName: String;
    lastName: String;
    profileImg: String;
}> = ({ firstName, lastName, profileImg }) => {
    return (
        <IonRow className='profileHeader'>
            <IonCol sizeXs='12' sizeSm='12' className='profileHeader__col--top'>
                <Strokes top={true} />
            </IonCol>
            <IonRow className='profileHeader__row'>
                <IonCol
                    sizeSm='4'
                    sizeXs='4'
                    className='profileHeader__col--img'
                >
                    <div className='profileHeader__img--wrap'>
                        <IonImg
                            className='profileHeader__img'
                            src={`${profileImg}`}
                        />
                    </div>
                </IonCol>
                <IonCol
                    sizeSm='8'
                    sizeXs='8'
                    className='profileHeader__col--bottom'
                >
                    <h1 className='profileHeader__name'>
                        {firstName}
                        <br />
                        {lastName}
                    </h1>
                </IonCol>
            </IonRow>
        </IonRow>
    );
};

export default ProfileHeader;
