// importing modules
import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useAuth } from 'context/firebase/authContext';
// importing components
import {
    IonButton,
    IonIcon,
    IonCol,
    IonContent,
    IonGrid,
    IonPage,
    IonRow,
} from '@ionic/react';
import UserMenu from '../../components/UserMenu/UserMenu';
import UserMenuBackdrop from '../../components/UserMenu/UserMenuBackdrop';
import ProfileHeader from '../../components/ProfileHeader/ProfileHeader';
import SkillsOfferedByUser from '../../components/SkillsOfferedByUser/SkillsOfferedByUser';
import SkillReviews from '../../components/SkillReviews/SkillReview';
import ReviewsFallback from '../../components/SkillReviewItemFallback/ReviewsFallback';
import ProfileDetails from '../../components/ProfileDetails/ProfileDetails';
import ProfileStateTabs from '../../components/ProfileStateTabs/ProfileStateTabs';
import Loading from '../../components/status/Loading';
import NavBar from '../../components/NavBar';
import { menuOutline, searchOutline } from 'ionicons/icons';
// importing graphql utilities - types
import { useQuery } from '@apollo/react-hooks';
import {
    UserProfile_byID,
    UserProfile_byIDVariables,
    UserProfile_byID_profile_skills_reviews,
    UserProfile_byID_profile_skills,
} from '../../GraphQL/__generated__/UserProfile_byID';
import { USER_PROFILE_BY_UID } from '../../GraphQL/Profile/UserProfile/USER_PROFILE_BY_UID';
import { USER_PROFILE_IMG } from '../../GraphQL/Profile/USER_PROFILE_IMG/USER_PROFILE_IMG';
import {
    UserProfileImg,
    UserProfileImgVariables,
} from '../../GraphQL/__generated__/UserProfileImg';
// importing utilities
import { FirebaseHelper } from '../../helpers/FirebaseHelper';
// importing styles
import './UserProfile.css';
import '../../components/SkillReviewControls/SkillReviewControls.css';

const UserProfile: React.FC = () => {
    const { uid } = useParams<{ uid: string }>();
    const { currentUser } = useAuth();
    const fuser = new FirebaseHelper(currentUser);
    const history = useHistory();
    const [selectedSkill, setSelectedSkill] = useState<string>('');
    const [selectedSkillId, setSelectedSkillId] = useState<number>(0);
    const [skillReviews, setSkillReviews] = useState<
        UserProfile_byID_profile_skills_reviews[]
    >([]);
    const [toggleMenu, setToggleMenu] = useState<boolean>(false); // handles hamburger menu

    const handleMenuToggle = () => setToggleMenu((prevToggle) => !prevToggle);

    const { data, loading } = useQuery<
        UserProfile_byID, // query return return type (shape of data)
        UserProfile_byIDVariables // query request type       (shape of variables)
    >(USER_PROFILE_BY_UID, {
        variables: {
            subId: uid,
        },
    });

    const { data: currentUserData, loading: currentUserDataLoading } = useQuery<
        UserProfileImg, // query return return type (shape of data)
        UserProfileImgVariables // query request type       (shape of variables)
    >(USER_PROFILE_IMG, {
        variables: {
            uid: currentUser.uid,
        },
        fetchPolicy: 'no-cache',
    });

    if (loading || currentUserDataLoading) {
        return <Loading />;
    }

    const activeSkills: UserProfile_byID_profile_skills[] =
        data.profile.skills.filter((skill) => skill.status === 'ACTIVE');

    const handleSelectedSkill = (skillName: string, skillId: number) => {
        setSelectedSkill(skillName);
        setSelectedSkillId(skillId);
        const filteredSkill = activeSkills.find(
            (item) => item.name === skillName
        );
        setSkillReviews([...filteredSkill.reviews]);
    };

    return (
        <IonPage>
            <IonContent>
                <IonGrid className='userProfile__wrap'>
                    {/* user menu */}
                    {toggleMenu ? (
                        <IonCol className='menu__sidebar--active'>
                            <UserMenu
                                handleMenuToggle={handleMenuToggle}
                                userUid={fuser.uid}
                            />
                        </IonCol>
                    ) : (
                        <IonCol className='menu__sidebar--inactive' />
                    )}
                    {toggleMenu ? (
                        <UserMenuBackdrop handleMenuToggle={handleMenuToggle} />
                    ) : null}
                    <IonRow className='profileStates__tabs--container'>
                        <IonCol
                            sizeXs='12'
                            sizeSm='12'
                            sizeMd='12'
                            className='profileStates__tabs--wrap'
                        >
                            <IonButton
                                fill='clear'
                                onClick={handleMenuToggle}
                                color='ion-primary'
                                className='profileStates__tabs--btn'
                            >
                                <IonIcon
                                    className='profileStates__tabs--btn--icon'
                                    icon={menuOutline}
                                />
                            </IonButton>
                        </IonCol>
                    </IonRow>
                    {/* profile header */}
                    <ProfileHeader
                        firstName={!loading && data.profile.firstName}
                        lastName={!loading && data.profile.lastName}
                        profileImg={!loading && data.profile.profilePicture}
                    />
                    {/* profile details */}
                    <ProfileDetails
                        currentUserUid={currentUser.uid}
                        otherUserUid={data.profile.uid}
                        otherUserBio={data.profile.bio}
                    />
                    {/* profile skills list*/}
                    <IonRow className='skills'>
                        <IonRow className='skills__row'>
                            <IonCol
                                className='skills__col--title'
                                sizeXs='12'
                                sizeSm='12'
                            >
                                <h2 className='skills__title'>
                                    What I can offer
                                </h2>
                            </IonCol>
                        </IonRow>
                        <IonGrid className='groupSkills__container'>
                            <IonRow className='groupSkills__row'>
                                {!loading && activeSkills.length > 0 ? (
                                    activeSkills.map((skill) => (
                                        <SkillsOfferedByUser
                                            key={skill.id}
                                            {...skill}
                                            jobs={
                                                data.profile.upcomingJobsServe
                                            }
                                            skillfullPersonsName={
                                                data.profile.firstName
                                            }
                                        />
                                    ))
                                ) : (
                                    <IonCol
                                        sizeSm='12'
                                        sizeXs='12'
                                        className='tasksFallback'
                                        style={{ backgroundColor: '#fff' }}
                                    >
                                        <h3 className='tasksFallback__title'>
                                            Encourage {data.profile.firstName}{' '}
                                            to add skills
                                            <br />
                                            to their profile.
                                        </h3>
                                    </IonCol>
                                )}
                            </IonRow>
                        </IonGrid>
                    </IonRow>
                    {/* skill reviews */}
                    {activeSkills.length > 0 ? (
                        <SkillReviews
                            userSkills={!loading && activeSkills}
                            handleSelectedSkill={handleSelectedSkill}
                            selectedSkill={selectedSkill}
                            selectedSkillId={selectedSkillId}
                            userName={!loading && data.profile.firstName}
                            skillReviews={skillReviews}
                        />
                    ) : (
                        <ReviewsFallback />
                    )}
                </IonGrid>
            </IonContent>
            <NavBar />
        </IonPage>
    );
};

export default UserProfile;
