// importing modules
import React, { useEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
// importing components
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonLabel,
    IonPage,
    IonRow,
} from '@ionic/react';
import CompleteTaskNavTabs from '../UI/CompleteTaskNavTabs/CompleteTaskNavTabs';
import TransactionWall from '../../../components/TransactionWall/TransactionWall';
import Loading from '../../../components/status/Loading';
import Strokes from 'components/Strokes';
// importing assets
import UserImg from '../UI/UserImg/UserImg';
// importing helpers
import time_credit_conversion from '../../../utils/timeConversion';
import {
    setLocalStorage,
    getLocalStorage,
    updateLocalStorage,
} from '../../../helpers/localStorage/localStorageHelper';
// importing graphql
import { useQuery } from '@apollo/react-hooks';
import { GET_JOB_BY_ID } from '../../../GraphQL/Job/GET_JOB_BY_ID/GET_JOB_BY_ID';
import {
    GetJobById,
    GetJobByIdVariables,
} from '../../../GraphQL/__generated__/GetJobById';
// importing styles
import './CompleteTask.css';
import { Status } from '../../../GraphQL/__generated__/globalTypes';
// firebase context
import { useAuth } from '../../../context/firebase/authContext';

const CompleteTask: React.FC = () => {
    const { currentUser } = useAuth();
    const history = useHistory();
    const chatLink = useRef(null);
    const handleChatroom = () => chatLink.current?.click();
    const { taskId } = useParams<{ taskId: string }>();

    useEffect(() => {
        if (!getLocalStorage('completeTaskViaProvider')) {
            setLocalStorage('completeTaskViaProvider', {
                hrs: 0,
                mins: 0,
                review: '',
                rating: 0,
            });
        }
    }, []);

    const { data, loading, error, fetchMore } = useQuery<
        GetJobById, // query return return type (shape of data)
        GetJobByIdVariables // query request type       (shape of variables)
    >(GET_JOB_BY_ID, {
        variables: {
            jobId: parseInt(taskId),
        },
        fetchPolicy: 'no-cache',
    });

    if (loading) {
        return <Loading />;
    }

    const convertedCredits = time_credit_conversion(
        data.job.recipientOfferCredits
    ).split(':'); // accessing the hrs & mins
    const creditHrs: string = convertedCredits[0]; // hrs
    const creditMins: string = convertedCredits[1]; // mins

    const handleAcceptCredits = () => {
        updateLocalStorage('completeTaskViaProvider', 'hrs', creditHrs);
        updateLocalStorage('completeTaskViaProvider', 'mins', creditMins);
        history.push(`/completeTask/provider/${taskId}/rating`);
    };

    return (
        <>
            {data.job.Status === Status.COMPLETED ? (
                <TransactionWall
                    title='This transaction has already been completed'
                    text={`Please dismiss the notification for ${data.job.Skill.category} with ${data.job.recipient.firstName}.`}
                />
            ) : (
                <IonPage>
                    <IonContent>
                        <IonGrid>
                            {/* completeTask header */}
                            <IonRow className='completeTask__header'>
                                <IonCol
                                    sizeSm='12'
                                    sizeXs='12'
                                    className='completeTask__header--container'
                                >
                                    <Strokes top={true} />
                                    <CompleteTaskNavTabs
                                        disablePrevStep={true}
                                        prevPage=''
                                    />
                                    <UserImg />
                                </IonCol>
                            </IonRow>
                            {/* text content */}
                            <IonRow className='completeTask__textContent'>
                                <IonCol
                                    className='completeTask__textContent--container'
                                    sizeSm='12'
                                    sizeXs='12'
                                >
                                    <h1 className='completeTask__textContent--title'>
                                        Confirm the <span>duration</span> of
                                        your{' '}
                                        <span>{data.job.Skill.category}</span>{' '}
                                        exchange
                                    </h1>
                                </IonCol>
                            </IonRow>
                            {/* balance tokens form */}
                            <IonGrid className='completeTask__form--container'>
                                <IonRow className='completeTask__form--row'>
                                    <form className='completeTask__col'>
                                        <IonCol
                                            sizeXs='6'
                                            sizeSm='6'
                                            className='completeTask__input--wrap'
                                        >
                                            <input
                                                className='completeTask__input'
                                                placeholder='-'
                                                type='number'
                                                min={1}
                                                max={24}
                                                value={creditHrs}
                                                disabled
                                                pattern='\d*'
                                            />
                                            <IonLabel className='completeTask__input--label'>
                                                Hours
                                            </IonLabel>
                                        </IonCol>
                                        <IonCol
                                            sizeXs='6'
                                            sizeSm='6'
                                            className='completeTask__input--wrap'
                                        >
                                            <input
                                                className='completeTask__input'
                                                placeholder='-'
                                                type='number'
                                                min={1}
                                                max={60}
                                                value={creditMins}
                                                disabled
                                                pattern='\d*'
                                            />
                                            <IonLabel className='completeTask__input--label'>
                                                Minutes
                                            </IonLabel>
                                        </IonCol>
                                    </form>
                                </IonRow>
                                {/* btn */}
                                <IonRow className='completeTask__btn--row'>
                                    <IonCol
                                        sizeSm='12'
                                        sizeXs='12'
                                        className='completeTask__btn--wrap'
                                    >
                                        <IonButton
                                            fill='clear'
                                            color='ion-primary'
                                            className='completeTask__btn--msg'
                                            onClick={handleChatroom}
                                            // onClick={() => history.push(`/chatroom/${currentUser.uid}/${data.job.recipient.uid}`)}
                                        >
                                            <a
                                                ref={chatLink}
                                                href={`/chatroom/${currentUser.uid}/${data.job.recipient.uid}`}
                                                type='hidden'
                                            ></a>
                                            Send Message
                                        </IonButton>
                                        <IonButton
                                            fill='clear'
                                            color='ion-primary'
                                            className='completeTask__btn--accept'
                                            onClick={handleAcceptCredits}
                                        >
                                            Accept
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        </IonGrid>
                    </IonContent>
                </IonPage>
            )}
        </>
    );
};

export default CompleteTask;
