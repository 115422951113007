// importing modules
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'context/firebase/authContext';
import moment from 'moment';
// importing components
import {
    IonCol,
    IonContent,
    IonGrid,
    IonPage,
    IonRow,
    IonSearchbar,
    IonHeader,
    IonToolbar,
} from '@ionic/react';
// importing assets
import { closeOutline, searchOutline } from 'ionicons/icons';
import NewMemberTile from './UI/NewMemberTile';
import MemberList from './MemberList/MemberList';
import FilteredMemberList from './FilteredMemberList/FilteredMemberList';
import Loading from '../../components/status/Loading';
import NavBar from '../../components/NavBar';
// importing graphql utilities - types
import { useQuery } from '@apollo/react-hooks';
import { SKILL_POOL_FROM_MANY_ORGS } from '../../GraphQL/Skill/SKILL_POOL_FROM_MANY_ORGS/SKILL_POOL_FROM_MANY_ORGS';
import {
    SkillPoolFromManyOrgs,
    SkillPoolFromManyOrgsVariables,
    SkillPoolFromManyOrgs_profilesFromManyOrgs,
} from '../../GraphQL/__generated__/SkillPoolFromManyOrgs';
import { GET_SKILL_KEYWORDS } from '../../GraphQL/Keywords/GET_SKILL_KEYWORDS';
import {
    GetSkillKeywords,
    GetSkillKeywords_keywords,
} from '../../GraphQL/__generated__/GetSkillKeywords';
// importing utilities
import shuffle from '../../utils/shuffle';
import filterKeywordsArr from '../../utils/filterKeywordsArr';
import filterAuthUserOut from '../../utils/filterOutAuthUser';
// importing styles
import './UniversalSearch.css';

const GeneralSearch: React.FC = () => {
    const history = useHistory();
    const [searchInput, setSearchInput] = useState<string>('');
    const { currentUser } = useAuth();

    const { data, loading } = useQuery<
        SkillPoolFromManyOrgs,
        SkillPoolFromManyOrgsVariables
    >(SKILL_POOL_FROM_MANY_ORGS, {
        variables: { uid: currentUser.uid },
    });
    const { data: keywordData, loading: keywordLoading } =
        useQuery<GetSkillKeywords>(GET_SKILL_KEYWORDS);

    if (loading) {
        return <Loading />;
    }

    let filteredKeywords: GetSkillKeywords_keywords[] = [];
    let filteredMembers: SkillPoolFromManyOrgs_profilesFromManyOrgs[] = [];
    let keywords = [];
    if (searchInput.length > 0 && !loading) {
        filteredKeywords = keywordData.keywords.filter((kw) =>
            kw.keyword.toLowerCase().includes(searchInput.toLowerCase())
        ); // filters keywords based on current search
        keywords = filterKeywordsArr(filteredKeywords); // filters out any duplicate keywords

        filteredMembers = data.profilesFromManyOrgs.filter((member) =>
            `${member.firstName} ${member.lastName}`
                .toLowerCase()
                .includes(searchInput.toLowerCase())
        ); // filter users based on first name & last name
        filteredMembers = filterAuthUserOut(filteredMembers, currentUser.uid); // filters out the current authenticated user from the members lists
    } else {
        filteredKeywords = [];
        filteredMembers = [];
    }

    const communityMembers = loading
        ? []
        : filterAuthUserOut(data.profilesFromManyOrgs, currentUser.uid);

    let newMembers = loading
        ? []
        : filterAuthUserOut(data.profilesFromManyOrgs, currentUser.uid);

    // @ts-ignore
    newMembers = newMembers
        // @ts-ignore
        .sort((a: any, b: any) => moment(b.createdAt) - moment(a.createdAt))
        .splice(0, 10);

    return (
        <IonPage>
            <IonHeader className='uniSearch-header'>
                <IonToolbar className='uniSearch-toolbar'>
                    <IonSearchbar
                        className='uniSearch-ionSearchBar'
                        search-icon={searchOutline}
                        clear-icon={closeOutline}
                        placeholder='Search by name or skill'
                        value={searchInput}
                        onIonChange={(event) =>
                            setSearchInput(event.detail.value)
                        }
                    ></IonSearchbar>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonGrid className='uniSearch__container'>
                    {/* <IonRow className='uniSearch__searchbar'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='uniSearch__searchbar--container'
                        >
                            <div className='uniSearch__searchbar--input--wrap'>
                                <IonIcon
                                    className='uniSearch__searchbar--input--icon'
                                    icon={searchOutline}
                                />
                                <input
                                    onChange={(event) =>
                                        setSearchInput(event.target.value)
                                    }
                                    value={searchInput}
                                    type='text'
                                    placeholder='Search by name or skill'
                                    className='uniSearch__searchbar--input'
                                />
                            </div>
                            <IonButton
                                className='uniSearch__close--btn'
                                onClick={() => {
                                    setSearchInput('');
                                }}
                                color='ion-primary'
                            >
                                <IonIcon
                                    icon={closeOutline}
                                />
                            </IonButton>
                        </IonCol>
                    </IonRow> */}
                    {/* skills list */}
                    {keywords.length > 0 && (
                        <IonGrid>
                            <IonRow className='uniSearch__sectionTitle'>
                                <IonCol
                                    sizeSm='12'
                                    sizeXs='12'
                                    className='uniSearch__sectionTitle--wrap'
                                >
                                    <h3 className='uniSearch__sectionTitle--text'>
                                        Skill List
                                    </h3>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                {keywords.length > 0
                                    ? keywords.map((kw, index) => (
                                          // (/) symbol causes url to not update correctly, hot fix -> switch symbol to (&)
                                          <IonCol
                                              onClick={() =>
                                                  history.push(
                                                      `/search/${encodeURIComponent(
                                                          kw
                                                      )}`
                                                  )
                                              }
                                              key={index}
                                              className='uniSearch__sectionTitle--wrap'
                                          >
                                              <h3 className='uniSearch__sectionTitle--text uniSearch__sectionTitle--skillName'>
                                                  {kw}
                                              </h3>
                                          </IonCol>
                                      ))
                                    : null}
                            </IonRow>
                        </IonGrid>
                    )}
                    {/* filtered Members List */}
                    {filteredMembers.length > 0 && (
                        <IonGrid>
                            <IonRow className='uniSearch__sectionTitle'>
                                <IonCol
                                    sizeSm='12'
                                    sizeXs='12'
                                    className='uniSearch__sectionTitle--wrap'
                                >
                                    <h3 className='uniSearch__sectionTitle--text'>
                                        Member's named{' '}
                                        <span>{searchInput}</span>
                                    </h3>
                                </IonCol>
                            </IonRow>
                            <FilteredMemberList
                                filteredMembers={filteredMembers}
                            />
                        </IonGrid>
                    )}
                    {/* new members */}
                    <IonRow className='uniSearch__sectionTitle'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='uniSearch__sectionTitle--wrap'
                        >
                            <h3 className='uniSearch__sectionTitle--text'>
                                New{' '}
                                <span
                                    style={{
                                        color: '#44c983',
                                        borderBottom: 'none',
                                    }}
                                >
                                    Community
                                </span>{' '}
                                Members
                            </h3>
                        </IonCol>
                    </IonRow>
                    <IonGrid className='uniSearch__newMembers--container'>
                        <IonRow className='uniSearch__newMembers--list'>
                            {!loading && newMembers.length > 0 ? (
                                newMembers.map((member) => (
                                    <NewMemberTile
                                        key={member.id}
                                        {...member}
                                    />
                                ))
                            ) : (
                                <IonRow className='nearbyMember__row'>
                                    <p>Invite members to join your group!</p>
                                </IonRow>
                            )}
                        </IonRow>
                    </IonGrid>
                    <MemberList communityMembers={shuffle(communityMembers)} />
                </IonGrid>
                <NavBar />
            </IonContent>
        </IonPage>
    );
};

export default GeneralSearch;
