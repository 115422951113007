// importing modules
import React, { useRef, useState } from 'react';
import moment from 'moment-timezone';
import { NavLink, useHistory } from 'react-router-dom';
// importing components
import {
    IonButton,
    IonCol,
    IonImg,
    IonSpinner,
    IonIcon,
    IonToast,
} from '@ionic/react';
// importing assets
import { informationCircleOutline } from 'ionicons/icons';
// importing utilities
import { time_credit_conversion } from '../../utils/timeConversion';
import { getLocalStorage } from '../../helpers/localStorage/localStorageHelper';
// importing graphql
import { useMutation } from '@apollo/react-hooks';
import {
    GetProfileJobs_profile_upcomingJobsServe,
    GetProfileJobs_profile_upcomingJobsServe_recipient,
    GetProfileJobs_profile_upcomingJobsReceive_provider,
} from '../../GraphQL/__generated__/GetProfileJobs';
import { NUDGE_VIA_SKILL_REQUEST } from '../../GraphQL/Job/NUDGE_VIA_SKILL_REQUEST/NUDGE_VIA_SKILL_REQUEST';
import {
    NudgeViaSkillRequest,
    NudgeViaSkillRequestVariables,
} from '../../GraphQL/__generated__/NudgeViaSkillRequest';
// improting styles
import './UserTaskItem.css';
// firebase
import { useAuth } from '../../context/firebase/authContext';

const HelpingTaskItem: React.FC<{
    task: GetProfileJobs_profile_upcomingJobsServe;
    otherProfile:
        | GetProfileJobs_profile_upcomingJobsServe_recipient
        | GetProfileJobs_profile_upcomingJobsReceive_provider;
}> = ({ task, otherProfile }) => {
    const { currentUser } = useAuth();
    const history = useHistory();
    const chatLink = useRef(null);
    const [disableClick, setDisableClick] = useState<boolean>(false);
    const [showToast, setShowToast] = useState<boolean>(false);

    const [skillRequestNudge, skillRequestNudgeDatas] = useMutation<
        NudgeViaSkillRequest,
        NudgeViaSkillRequestVariables
    >(NUDGE_VIA_SKILL_REQUEST);

    const handleNudge = () => {
        setDisableClick(true);
        setShowToast(true);

        skillRequestNudge({
            variables: { id: task.id, nudgeWho: 'recipient' },
        })
            .then((r) => setShowToast(true))
            .catch((err) => console.error(err));
        // disable nudge button for 30secs after click
        setTimeout(() => {
            setDisableClick(false);
        }, 30000);
    };

    const handleChatroom = () => chatLink.current?.click();
    const taskStartTime = moment(task.startTime).tz(
        getLocalStorage('tz'),
        false
    );
    const taskEndTime = moment(task.endTime).tz(getLocalStorage('tz'), false);
    let taskTime;
    let btns;

    // conditionals to render component based on task time/status
    if (moment() < taskStartTime) {
        // check if the task hasnt started
        taskTime = (
            <p className='taskItem__info--time'>
                &#x02022;&nbsp;Starts {taskStartTime.fromNow()} &nbsp;{' '}
                <span>- Providing</span>
            </p>
        );
        btns = (
            <React.Fragment>
                <IonButton
                    fill='clear'
                    color='ion-primary'
                    className='taskItem__btn--send'
                    // onClick={() => history.push(`/chatroom/${currentUser.uid}/${otherProfile.uid}`)}
                    onClick={handleChatroom}
                >
                    <a
                        ref={chatLink}
                        href={`/chatroom/${currentUser.uid}/${otherProfile.uid}`}
                        type='hidden'
                    ></a>
                    Send Message
                </IonButton>
                <IonButton
                    onClick={() =>
                        history.push(`/cancel/helping/recipient/${task.id}`)
                    }
                    fill='clear'
                    color='ion-primary'
                    className='taskItem__btn--cancel'
                >
                    Cancel Task
                </IonButton>
            </React.Fragment>
        );
    } else if (moment().isBetween(taskStartTime, taskEndTime)) {
        // check if the task is in progress
        taskTime = (
            <p className='taskItem__info--time'>
                &#x02022;&nbsp;Ends {taskEndTime.fromNow()} &nbsp;{' '}
                <span>- Providing</span>
            </p>
        );
        if (task.Status.toString() === 'RECIPIENT_HAS_PROPOSED_OFFER') {
            btns = (
                <IonButton
                    fill='clear'
                    color='ion-primary'
                    className='completeTask__btn'
                >
                    <NavLink
                        to={{
                            pathname: `/completeTask/provider/${task.id}`,
                            state: {
                                id: task.id,
                            },
                        }}
                        className='completeTask__btn--link'
                    >
                        Complete This Task
                    </NavLink>
                </IonButton>
            );
        } else {
            btns = (
                <>
                    <div className='completeTask__btn--waiting'>
                        <IonSpinner
                            className='completeTask__btn--waiting--spinner'
                            name='crescent'
                        />
                        <p className='completeTask__btn--waiting--text'>
                            Waiting on Recipient
                        </p>
                    </div>
                    <IonButton
                        fill='clear'
                        color='ion-primary'
                        onClick={handleNudge}
                        disabled={disableClick}
                        className='completeTask__btn--nudge'
                    >
                        Nudge
                    </IonButton>
                </>
            );
        }
    } else if (moment() > taskEndTime) {
        // check if the task has ended
        taskTime = (
            <p className='taskItem__info--time'>
                &#x02022;&nbsp;Ended {taskEndTime.fromNow()} &nbsp;{' '}
                <span>- Providing</span>
            </p>
        );
        if (task.Status.toString() === 'RECIPIENT_HAS_PROPOSED_OFFER') {
            btns = (
                <IonButton
                    fill='clear'
                    color='ion-primary'
                    className='completeTask__btn'
                >
                    <NavLink
                        to={{
                            pathname: `/completeTask/provider/${task.id}`,
                            state: {
                                id: task.id,
                            },
                        }}
                        className='completeTask__btn--link'
                    >
                        Complete This Task
                    </NavLink>
                </IonButton>
            );
        } else {
            btns = (
                <>
                    <div className='completeTask__btn--waiting'>
                        <IonSpinner
                            className='completeTask__btn--waiting--spinner'
                            name='crescent'
                        />
                        <p className='completeTask__btn--waiting--text'>
                            Waiting on Recipient
                        </p>
                    </div>
                    <IonButton
                        fill='clear'
                        color='ion-primary'
                        onClick={handleNudge}
                        disabled={disableClick}
                        className='completeTask__btn--nudge'
                    >
                        Nudge
                    </IonButton>
                </>
            );
        }
    }

    return (
        <IonCol className='taskItem' sizeSm='11' sizeXs='11'>
            <IonToast
                cssClass='completeTask__nudge--toast'
                isOpen={showToast}
                onDidDismiss={() => setShowToast(false)}
                message='Transaction Reminder Sent!'
                duration={2500}
            />
            <IonIcon
                onClick={() => history.push(`/skillRequest/${task.id}`)}
                icon={informationCircleOutline}
                className='taskItem__info--icon'
            />
            <IonCol sizeXs='12' sizeSm='12' className='taskItem__info'>
                <IonCol sizeXl='3' sizeLg='3' sizeMd='3' sizeXs='3' sizeSm='3'>
                    <div className='taskItem__img--wrap'>
                        <IonImg
                            onClick={() =>
                                history.push(`/user/${otherProfile.uid}`)
                            }
                            className='taskItem__img'
                            src={otherProfile.profilePicture}
                        />
                    </div>
                </IonCol>
                <IonCol className='taskItem__info--wrap' sizeXs='9' sizeSm='9'>
                    <h3 className='taskItem__info--name'>
                        {task.Skill.category} w/{' '}
                        {otherProfile.firstName + ' ' + otherProfile.lastName}
                    </h3>
                    {taskTime}
                </IonCol>
            </IonCol>
            {task.requestMessage.length > 0 &&
            task.Status.toString() !== 'RECIPIENT_HAS_PROPOSED_OFFER' ? (
                <IonCol sizeXs='12' sizeSm='12'>
                    <p className='taskItem__info--time'>
                        <span>&quot; {task.requestMessage} &quot;</span>
                    </p>
                </IonCol>
            ) : null}
            {task.Status.toString() === 'RECIPIENT_HAS_PROPOSED_OFFER' && (
                <IonCol sizeXs='12' sizeSm='12'>
                    <p className='taskItem__info--time'>
                        <span>
                            Recipient has proposed{' '}
                            {time_credit_conversion(task.recipientOfferCredits)}{' '}
                            Balance Tokens
                        </span>
                    </p>
                </IonCol>
            )}
            <IonCol sizeXs='12' sizeSm='12' className='taskItem__btn--wrap'>
                {btns}
            </IonCol>
        </IonCol>
    );
};

export default HelpingTaskItem;
