import React, { useState } from 'react';
// importing components
import Loading from '../../../../components/status/Loading';
import { IonImg } from '@ionic/react';
// importing graphql utilities - types
import { useQuery } from '@apollo/react-hooks';
import { USER_PROFILE_IMG } from '../../../../GraphQL/Profile/USER_PROFILE_IMG/USER_PROFILE_IMG';
import {
    UserProfileImg,
    UserProfileImgVariables,
} from '../../../../GraphQL/__generated__/UserProfileImg';
import { useAuth } from '../../../../context/firebase/authContext';

const UserImg: React.FC = () => {
    const { currentUser } = useAuth();
    const { data, loading } = useQuery<
        UserProfileImg, // query return return type (shape of data)
        UserProfileImgVariables // query request type       (shape of variables)
    >(USER_PROFILE_IMG, {
        variables: {
            uid: currentUser.uid,
        },
        fetchPolicy: 'no-cache',
    });

    if (loading) {
        return <Loading />;
    }

    return (
        <IonImg
            className='createTask__header--img'
            src={!loading && data.profile.profilePicture}
            alt='user'
        />
    );
};

export default UserImg;
