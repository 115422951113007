// importing modules
import React, { useState, useEffect } from 'react';
// imoporting components
import { IonCol, IonImg, IonRow, IonIcon } from '@ionic/react';
import { star } from 'ionicons/icons';
// importing helpers
import {
    getLocalStorage,
    updateLocalStorage,
} from '../../../../helpers/localStorage/localStorageHelper';
import capitalize from '../../../../utils/capitalize';

const ParticipantRatingItem: React.FC<{
    firstName: string;
    lastName: string;
    profileImg: string;
    skill: string;
    id: number;
}> = ({ id, firstName, lastName, profileImg, skill }) => {
    const [rt, setRt] = useState<number>(0);

    useEffect(() => {
        if (getLocalStorage('completeTaskViaRecipient')) {
            setRt(getLocalStorage('completeTaskViaRecipient').rating);
        }
    }, []);

    const handleParticipantRating = (id: number, rating: number) => {
        // fire mutation to update participants rating based on id
        updateLocalStorage('completeTaskViaRecipient', 'rating', rating);
    };
    return (
        <IonCol className='taskPartcItem' sizeSm='12' sizeXs='12'>
            <IonCol sizeXs='12' sizeSm='12' className='taskPartcItem__content'>
                <IonCol sizeXs='12' sizeSm='12' className='taskPartcItem__info'>
                    <IonCol
                        sizeXl='3'
                        sizeLg='3'
                        sizeMd='3'
                        sizeXs='3'
                        sizeSm='3'
                    >
                        <div className='taskPartcItem__img--wrap'>
                            <IonImg
                                className='taskPartcItem__img'
                                src={profileImg}
                            />
                        </div>
                    </IonCol>
                    <IonCol
                        className='taskPartcItem__info--wrap'
                        sizeXs='9'
                        sizeSm='9'
                    >
                        <h3 className='taskPartcItem__info--name'>
                            {capitalize(firstName)} {capitalize(lastName)}
                        </h3>
                        <p className='taskPartcItem__info--cred'>{skill}</p>
                    </IonCol>
                </IonCol>
                {/* rating */}
                <IonRow className='stars--row'>
                    <IonCol
                        sizeXs='11'
                        sizeSm='11'
                        className='stars__container'
                    >
                        {[...Array(5)].map((s, i) => {
                            const ratingVal = i + 1;
                            return (
                                <label key={i}>
                                    <input
                                        type='radio'
                                        name='rating'
                                        value={ratingVal}
                                        onClick={() => {
                                            setRt(ratingVal);
                                            handleParticipantRating(
                                                id,
                                                ratingVal
                                            );
                                        }}
                                    />
                                    <IonIcon
                                        style={{
                                            color:
                                                ratingVal <= rt
                                                    ? '#f7b500'
                                                    : '#d3d3d3',
                                        }}
                                        icon={star}
                                        className='star'
                                    />
                                </label>
                            );
                        })}
                    </IonCol>
                </IonRow>
            </IonCol>
        </IonCol>
    );
};

export default ParticipantRatingItem;

// const ParticipantRatingItem:React.FC<{
//     id: number,
//     firstName: string,
//     lastName: string,
//     credits: number,
//     profileImg: string,
//     skill: string,
//     rating: number
// }> = ({ id, firstName, lastName, credits, profileImg, rating, skill }) => {
//     const [ rt, setRt ] = useState<number>(0);

//     useEffect(() => {
//         if(getLocalStorage('completeTaskViaRecipient')) {
//             setRt(getLocalStorage('completeTaskViaRecipient').rating);            } else {
//         }
//     }, []);

//     const handleParticipantRating = (id: number, rating: number) => {
//         // fire mutation to update participants rating based on id
//         updateLocalStorage('completeTaskViaRecipient', 'rating', rating);
//     };
//     return (
//         <IonCol className="taskPartcItem" sizeSm="12" sizeXs="12">
//             <IonCol sizeXs="12" sizeSm="12"  className="taskPartcItem__content">
//                 <IonCol sizeXs="12" sizeSm="12" className="taskPartcItem__info">
//                     <IonCol sizeXl="3" sizeLg="3" sizeMd="3" sizeXs="3" sizeSm="3">
//                     <div className="taskPartcItem__img--wrap">
//                         <IonImg
//                             className="taskPartcItem__img"
//                             src={profileImg} />
//                     </div>
//                     </IonCol>
//                     <IonCol className="taskPartcItem__info--wrap" sizeXs="9" sizeSm="9">
//                         <h3 className="taskPartcItem__info--name">{firstName} {lastName}</h3>
//                         <p className="taskPartcItem__info--cred">{skill}</p>
//                     </IonCol>
//                 </IonCol>
//                 {/* stars system*/}
//                 <IonRow className="stars--row">
//                     <IonCol sizeXs="11" sizeSm="11" className="stars__container">
//                         {
//                             [...Array(5)].map((s, i) => {
//                                 const ratingVal = i + 1;

//                                 return (<label key={i}>
//                                     <input
//                                         type="radio"
//                                         name="rating"
//                                         value={ratingVal}
//                                         onClick={() => {
//                                             setRt(ratingVal)
//                                             handleParticipantRating(id, ratingVal);
//                                         }}
//                                     />
//                                     <IonIcon style={{ color: ratingVal <= rt ? "#f7b500" : "#d3d3d3" }} icon={star} className="star" />
//                                 </label>
//                                 );
//                             })
//                         }
//                     </IonCol>
//                 </IonRow>
//             </IonCol>
//         </IonCol>
//     )
// };

// export default ParticipantRatingItem;
