import React from 'react';
import { IonCol, IonImg, IonRow } from '@ionic/react';

export const NamedPictureItem: React.FC<{
    profileImg: string;
    // firstName: string;
    // lastName: string;
}> = ({
    profileImg,
    // firstName, lastName
}) => {
    return (
        <IonRow className='greenStrokesHeader__row'>
            <IonCol
                sizeSm='4'
                sizeXs='4'
                className='greenStrokesHeader__col--img'
            >
                <div className='greenStrokesHeader__img--wrap'>
                    <IonImg
                        className='greenStrokesHeader__img'
                        src={`${profileImg}`}
                    />
                </div>
            </IonCol>
            <IonCol
                sizeSm='8'
                sizeXs='8'
                className='greenStrokesHeader__col--bottom'
            >
                {/* <h1 className='greenStrokesHeader__name'>
                    {firstName}
                    <br />
                    {lastName}
                </h1> */}
            </IonCol>
        </IonRow>
    );
};
