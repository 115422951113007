// importing modules
import React from 'react';
// importing components
import { IonImg } from '@ionic/react';
// importing assets
import yingLogo from '../../assets/ying-logo-low-opacity.png';
// importing styles
import './ConfirmationScreenLogo.css';

const ConfirmationScreenLogo: React.FC = () => {
    return (
        <IonImg src={yingLogo} alt='ying logo' className='confirmation__logo' />
    );
};

export default ConfirmationScreenLogo;
