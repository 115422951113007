// importing modules
import React from 'react';
import { useHistory } from 'react-router';
// importing components
import { IonAvatar, IonButton, IonCol, IonImg } from '@ionic/react';
// importing utilities
import shuffle from '../../../utils/shuffle';
import filterCompletedTasks from '../../../utils/filterCompletedTasks';
import genCreatedAt from '../../../utils/genCreatedAt';
import limitStr from '../../../utils/limitString';
import capitalize from '../../../utils/capitalize';
// importing types
import { SkillPoolFromManyOrgs_profilesFromManyOrgs } from '../../../GraphQL/__generated__/SkillPoolFromManyOrgs';

const MemberTile: React.FC<SkillPoolFromManyOrgs_profilesFromManyOrgs> = (
    member
) => {
    const history = useHistory();
    const firstFiveOrgs = shuffle(member.organizations).slice(0, 5);
    const timeOnYing = genCreatedAt(member.createdAt);

    let skillsArr: string[] = [];
    member.skills.forEach((skill) => {
        // check for duplicates
        if (!skillsArr.includes(skill.category)) skillsArr.push(skill.category);
    });

    const completedTasksCount: number = filterCompletedTasks(
        member.upcomingJobsReceive,
        member.upcomingJobsServe
    );

    return (
        <IonCol
            onClick={() => history.push('/user/' + member.uid)}
            key={member.id}
            className='uniMember'
            sizeSm='11'
            sizeXs='11'
        >
            <IonCol sizeXs='12' sizeSm='12' className='uniMember__info'>
                <IonCol sizeXl='3' sizeLg='3' sizeMd='3' sizeXs='3' sizeSm='3'>
                    <IonAvatar className='groupTaskItem__img--wrap'>
                        <IonImg
                            className='groupTaskItem__img'
                            src={member.profilePicture}
                        />
                    </IonAvatar>
                </IonCol>
                <IonCol className='uniMember__info--wrap' sizeXs='9' sizeSm='9'>
                    <div className='uniMember__group--list'>
                        {firstFiveOrgs.map((org) => {
                            return (
                                <IonImg
                                    className='uniSearch__newMembers--group--item'
                                    src={
                                        org.orgPicture === null ||
                                        org.orgPicture.length === 0
                                            ? 'https://is3-ssl.mzstatic.com/image/thumb/Purple123/v4/3e/62/e2/3e62e239-f433-bc84-3775-21af60499ec7/source/512x512bb.jpg'
                                            : org.orgPicture
                                    }
                                    alt={org.name}
                                    key={org.id}
                                />
                            );
                        })}
                        {member.organizations.length > 5 && (
                            <p className='uniMember__group--list--count'>
                                +{member.organizations.length - 5}
                            </p>
                        )}
                    </div>
                    <h3 className='uniMember__info--name'>
                        {capitalize(member.firstName) +
                            ' ' +
                            capitalize(member.lastName)}
                    </h3>
                    <p className='uniMember__info--reviews'>
                        {timeOnYing} | {completedTasksCount} tasks completed
                    </p>
                </IonCol>
            </IonCol>
            <IonCol sizeXs='12' sizeSm='12' className='uniMember__skills--wrap'>
                {skillsArr.length > 0
                    ? skillsArr.map((skill, id) => (
                          <IonButton
                              fill='clear'
                              key={id}
                              color='ion-primary'
                              className='uniMember__skill'
                          >
                              {limitStr(skill, 45)}
                          </IonButton>
                      ))
                    : null}
            </IonCol>
        </IonCol>
    );
};

export default MemberTile;
