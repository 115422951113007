import React from 'react';
import { IonButton, IonCol } from '@ionic/react';
import limitStr from '../../utils/limitString';
import './SkillReviewControls.css';

const SkillReviewControls: React.FC<{
    handleSelectedSkill: Function;
    skillTitle: string;
    selectedSkill: string;
    reviewsAvg: number;
}> = ({ handleSelectedSkill, selectedSkill, skillTitle, reviewsAvg }) => {
    return (
        <div className='reviews__btn--wrap'>
            <IonButton
                fill='clear'
                onClick={() => handleSelectedSkill(skillTitle)}
                className={
                    selectedSkill === skillTitle
                        ? 'reviews__btn reviews__btn--active'
                        : 'reviews__btn'
                }
                color='ion-primary'
            >
                {limitStr(skillTitle, 20)} ({reviewsAvg})
            </IonButton>
        </div>
    );
};

export default SkillReviewControls;
