// importing modules
import React, { useState, useEffect } from 'react';
import { useHistory, useParams, Redirect } from 'react-router-dom';
// importing components
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonPage,
    IonRow,
    IonIcon,
    IonToast,
    IonSpinner,
} from '@ionic/react';
import CompleteTaskNavTabs from '../UI/CompleteTaskTabs/CompleteTaskTabs';
import Strokes from 'components/Strokes';
import Loading from 'components/status/Loading';
import { card, chevronForwardOutline, checkmark } from 'ionicons/icons';
// importing assets
// importing helpers
import { getLocalStorage } from 'helpers/localStorage/localStorageHelper';
// importing types & graphql
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { GET_JOB_POST_BY_ID } from 'GraphQL/JobPost/GET_JOB_POST_BY_ID/GET_JOB_POST_BY_ID';
import {
    GetJobPostById,
    GetJobPostByIdVariables,
} from 'GraphQL/__generated__/GetJobPostById';
import { Status } from 'GraphQL/__generated__/globalTypes';
import gratitudePotImg from 'assets/gratitude-pot.png';
import ProviderGratitudeCard from './ProviderGratitudeCard';
import MethodSelectionModal from './MethodSelectionModal';
import { CreateStripeTransaction } from 'GraphQL/StripeConnect/StripeConnect';
import './GratitudePot.css';
import { useAuth } from 'context/firebase/authContext';
import { GetStripeCustomerAccount as GSCAQuery } from 'GraphQL/StripeConnect/StripeConnect';
import { hrs_mins_to_credits } from 'utils/timeConversion';
import {
    UpdatManyParticipationRequests,
    UpdatManyParticipationRequestsVariables,
} from 'GraphQL/__generated__/UpdatManyParticipationRequests';
import { UPDATE_MANY_PARTICIPATION_REQUESTS } from 'GraphQL/ParticipationRequest/UPDATE_ONE_PARTICIPATION_REQUEST/UPDATE_MANY_PARTICIPATION_REQUESTS/UPDATE_MANY_PARTICIPATION_REQUESTS';
import { UPDATE_ONE_JOB_POST_STATUS } from 'GraphQL/JobPost/UPDATE_ONE_JOB_POST_STATUS/UPDATE_ONE_JOB_POST_STATUS';
import {
    UpdateOneJobPostStatus,
    UpdateOneJobPostStatusVariables,
} from 'GraphQL/__generated__/UpdateOneJobPostStatus';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import { ADMIN_GROUP_PROFILE_PICTURE } from 'GraphQL/Admin/adminGroupProfilePicture/adminGroupProfilePicture';

// importing styles

export default () => {
    const history = useHistory();
    const { currentUser } = useAuth();
    const { taskId } = useParams<{ taskId: string }>();
    const [paymentIntents, setPaymentIntents] = useState({});
    const [showModal, setModal] = useState<boolean>(false);
    const [
        getStripeCustomerAccount,
        { data: customerData, refetch, loading: customerLoading },
    ] = useLazyQuery(GSCAQuery);
    const [selectedMethod, setSelectedMethod] = useState(null);
    const [isActiveCustomer, setIsActiveCustomer] = useState<boolean>(false);
    const [hasBeenSubmitted, sethasBeenSubmitted] = useState<boolean>(false);
    const [wasSuccessful, setWasSuccessful] = useState<boolean>(false);
    const [errorCode, setErrorCode] = useState<string>('');

    let stripeCustomerAccount;
    if (customerData) {
        stripeCustomerAccount = customerData.getStripeCustomerAccount;
    }

    const [participationRequest, participationRequestDatas] = useMutation<
        UpdatManyParticipationRequests,
        UpdatManyParticipationRequestsVariables
    >(UPDATE_MANY_PARTICIPATION_REQUESTS);
    const [updateJobStatus, updateJobStatusDatas] = useMutation<
        UpdateOneJobPostStatus,
        UpdateOneJobPostStatusVariables
    >(UPDATE_ONE_JOB_POST_STATUS);
    const currentParticipants = getLocalStorage(
        'completeTaskPostedViaRecipient'
    ).participants;
    const { data, loading } = useQuery<GetJobPostById, GetJobPostByIdVariables>(
        GET_JOB_POST_BY_ID,
        {
            variables: { jobPostId: parseInt(taskId) },
            fetchPolicy: 'cache-and-network',
        }
    );
    const { data: adminData, loading: adminLoading } = useQuery(
        ADMIN_GROUP_PROFILE_PICTURE,
        {
            variables: { adminId: data?.jobPost.adminId },
        }
    );

    const [
        createStripeTransaction,
        {
            data: transactionResponse,
            error: transactionError,
            loading: transactionLoading,
        },
    ] = useMutation(CreateStripeTransaction, {
        fetchPolicy: 'no-cache',
        onCompleted: (transactionResponse) => {
            const transaction = transactionResponse?.createStripeTransaction;
            if (transaction?.status == 'success') {
                setWasSuccessful(true);
                completeTask();
            } else {
                const error = JSON.parse(transaction?.error);
                switch (error?.decline_code) {
                    case 'generic_decline':
                        setErrorCode('7.02');
                        break;
                    case 'insufficient_funds':
                        setErrorCode('7.02');
                        break;
                    case 'lost_card':
                        setErrorCode('7.04');
                        break;
                    case 'stolen_card':
                        setErrorCode('7.04');
                        break;
                    case 'expired_card':
                        setErrorCode('7.03');
                        break;
                    case 'incorrect_cvc':
                        setErrorCode('7.05');
                        break;
                    case 'processing_error':
                        setErrorCode('7.07');
                        break;
                    case 'incorrect_number':
                        setErrorCode('7.01');
                        break;
                    default:
                        setErrorCode('7.08');
                }
            }
        },
    });
    useEffect(() => {
        if (
            stripeCustomerAccount &&
            history.location.pathname ===
                `/completeTaskPosted/recipient/${taskId}/gratitudePot`
        ) {
            refetch();
        } else if (!stripeCustomerAccount) {
            getStripeCustomerAccount({
                variables: {
                    uid: currentUser.uid,
                },
            });
        }
    }, [history.location.pathname]);

    //Allows user to try a different card if payment fails
    useEffect(() => {
        const transactionFailed =
            transactionResponse?.createStripeTransaction?.status == 'failure';
        if (hasBeenSubmitted && transactionFailed) {
            sethasBeenSubmitted(false);
            setErrorCode('');
        }
    }, [selectedMethod]);
    //  subtotal amount before fees
    const getCurrentTotal = () => {
        let total = 0;
        Object.keys(paymentIntents).map((key, index) => {
            total += paymentIntents[key].amount;
        });
        return total;
    };
    //  total amount after all fees
    const getUserTotal = () => {
        let userTotal =
            getCurrentTotal() + calculateStripeFees() + calculatePlatformFee();
        userTotal = Math.round(userTotal);
        return userTotal;
    };
    //  Calculates YING platform fee
    //  15% for transactions under $4.99, $1 for transactions over $5
    const calculatePlatformFee = () => {
        if (getCurrentTotal() >= 500) {
            return 100;
        } else {
            return getCurrentTotal() * 0.15;
        }
    };
    //  Stripe Fees are 2.9% of transaction total + 30¢ per transaction
    const calculateStripeFees = () => {
        let fees = (getCurrentTotal() + calculatePlatformFee()) * 0.029 + 30;
        fees = (getCurrentTotal() + calculatePlatformFee() + fees) * 0.029 + 30;
        return fees;
    };
    //  creates a payment intent object
    //  if successful, it will trigger transfers to each provider
    const handleStripeTransaction = () => {
        sethasBeenSubmitted(true);
        createStripeTransaction({
            variables: {
                uid: currentUser.uid,
                total: getUserTotal(),
                providerAccounts: JSON.stringify(paymentIntents),
                paymentMethod: JSON.stringify(selectedMethod),
                taskId: parseInt(taskId),
            },
        });
    };

    //  helper function that translates the line item numbers to readable money amount
    const formatAmount = (amount: number) => {
        //  input: 100000 (penny/cent)
        //  output: $1,000.00 (dollar amount formatted)
        return (
            '$' +
            (amount / 100)
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        );
    };

    if (
        stripeCustomerAccount?.payment_methods?.length > 0 &&
        selectedMethod === null
    ) {
        setIsActiveCustomer(true);
        setSelectedMethod(stripeCustomerAccount.payment_methods[0]);
    }

    if (loading || customerLoading) {
        return <Loading />;
    }
    // current user uid has to match the jobpost poster's uid
    if (currentUser.uid != data.jobPost.recipient.uid) {
        return <Redirect to='/' />;
    }

    //closes out task on YING backend
    const completeTask = () => {
        const refetchedCurrentParticipants = getLocalStorage(
            'completeTaskPostedViaRecipient'
        ).participants;

        const payouts = [];
        refetchedCurrentParticipants.forEach((p) => {
            if (p.status === Status.ACCEPTED) {
                const transactionObj = {
                    id: parseInt(p.participationReqId),
                    recipientOfferCredits: hrs_mins_to_credits(
                        parseInt(p.hrs),
                        parseInt(p.mins)
                    ),
                    recipientsReviewOfProvider: p.review,
                    recipientsRatingOfProvider: p.rating,
                    status: 'RECIPIENT_HAS_PROPOSED_OFFER',
                };
                payouts.push(transactionObj);
            }
        });

        participationRequest({
            variables: {
                data: payouts,
            },
        })
            .then((res) => {
                updateJobStatus({
                    variables: {
                        jobId: parseInt(taskId),
                        status: Status.RECIPIENT_HAS_PROPOSED_OFFER,
                    },
                })
                    .then(() => {
                        history.push(
                            `/completeTaskPosted/recipient/${taskId}/confirmation`
                        );
                    })
                    .catch((err) => console.error(err));
            })
            .catch((err) => console.error(err));
    };

    return (
        <>
            <IonPage>
                <IonContent>
                    <IonToast
                        cssClass='requestDetails__location--clipboard--toast'
                        isOpen={wasSuccessful}
                        message='Payment was successful'
                        duration={2000}
                    />
                    <MethodSelectionModal
                        paymentMethods={stripeCustomerAccount?.payment_methods}
                        setPaymentMethod={setSelectedMethod}
                        setModal={setModal}
                        showModal={showModal}
                        currentActive={selectedMethod}
                    />
                    <IonGrid className='gratitude-grid-container'>
                        <IonRow className='completeTask__header'>
                            <IonCol
                                sizeSm='12'
                                sizeXs='12'
                                className='completeTask__header--container'
                            >
                                <Strokes top={true} />
                                <CompleteTaskNavTabs
                                    disablePrevStep={false}
                                    prevPage=''
                                />
                                <IonImg
                                    className='createTask__header--img'
                                    src={
                                        data.jobPost.adminId
                                            ? adminLoading
                                                ? null
                                                : adminData
                                                      .AdminGroupProfilePicture
                                                      .orgPicture
                                            : data.jobPost.recipient
                                                  .profilePicture
                                    }
                                    alt='user'
                                />
                            </IonCol>
                        </IonRow>

                        <IonRow className='completeTask__textContent'>
                            <IonCol
                                className='completeTask__textContent--container'
                                sizeSm='12'
                                sizeXs='12'
                            >
                                <h1 className='completeTask__textContent--title'>
                                    Would you like to add to their{' '}
                                    <span> Gratitude Pot?</span>
                                </h1>
                                <IonRow className='gratitude_pot_img--container'>
                                    <IonImg
                                        className='gratitude_pot_img'
                                        src={gratitudePotImg}
                                    />
                                </IonRow>
                                <p className='completeTask__textContent--text'>
                                    Would you like to leave a little{' '}
                                    <span style={{ fontWeight: 'bold' }}>
                                        EXTRA EXTRA.
                                    </span>{' '}
                                    Feel the love and leave any amount of
                                    gratuity that feels right to you.
                                </p>
                            </IonCol>
                        </IonRow>

                        {isActiveCustomer ? (
                            //Payment Methods
                            <IonRow>
                                <h3 className='section-heading'>
                                    Select a payment method
                                </h3>
                                <IonGrid className='backgroundContainer'>
                                    <IonRow className='completeTask__form--row'>
                                        <IonRow
                                            className='paymentMethodInnerContainer'
                                            onClick={() => setModal(true)}
                                        >
                                            <IonRow>
                                                <IonCol class='cardIcon'>
                                                    <IonIcon
                                                        size='lg'
                                                        class='cardIcon'
                                                        icon={card}
                                                    ></IonIcon>
                                                </IonCol>
                                                <IonCol>
                                                    <p className='paymentMethodName'>
                                                        {
                                                            selectedMethod?.card
                                                                ?.brand
                                                        }
                                                    </p>
                                                </IonCol>
                                            </IonRow>
                                            <IonCol className='paymentMethodNumber'>
                                                <p>
                                                    {'*' +
                                                        selectedMethod?.card
                                                            ?.last4}{' '}
                                                    <span>
                                                        <IonIcon
                                                            style={{
                                                                paddingTop:
                                                                    '2px',
                                                            }}
                                                            icon={
                                                                chevronForwardOutline
                                                            }
                                                        />
                                                    </span>
                                                </p>
                                            </IonCol>
                                        </IonRow>
                                    </IonRow>
                                </IonGrid>
                            </IonRow>
                        ) : (
                            // Add a payment section
                            <IonGrid className='backgroundContainer'>
                                <IonRow className='completeTask__form--row'>
                                    <h1 className='setup_heading'>
                                        Enable Gratitude Sending
                                    </h1>
                                </IonRow>
                                <IonRow className='completeTask__form--row'>
                                    <p className='setup_text'>
                                        We partner with Stripe to ensure all
                                        your payments and personal banking
                                        details are secure. Add a payment method
                                        to begin sending and receiving
                                        gratitude.
                                    </p>
                                </IonRow>
                                <IonRow className='completeTask__form--row'>
                                    <IonButton
                                        fill='clear'
                                        // disabled={!(mins.length > 0 && hrs.length > 0)}
                                        color='ion-primary'
                                        onClick={() =>
                                            history.push(
                                                '/settings/paymentmethods/add'
                                            )
                                        }
                                        className='footerWithChild__btn setup_tipping'
                                    >
                                        Add Payment Method
                                    </IonButton>
                                </IonRow>
                            </IonGrid>
                        )}

                        {/* Tip selection section */}
                        {isActiveCustomer && (
                            <IonCol>
                                <h3 className='section-heading'>
                                    Enter or select gratitude amount
                                </h3>
                                {currentParticipants &&
                                    currentParticipants.map((participant) => (
                                        <ProviderGratitudeCard
                                            key={participant.id}
                                            {...participant}
                                            paymentIntents={paymentIntents}
                                            setIntents={setPaymentIntents}
                                        />
                                    ))}
                            </IonCol>
                        )}

                        {/* Transaction Summary Section */}
                        {/* Only renders when there is at least 1 payment method */}
                        {paymentIntents &&
                        Object.keys(paymentIntents).length ? (
                            <IonCol className='summary_container'>
                                {Object.keys(paymentIntents).map(
                                    (key, index) => {
                                        return (
                                            <IonRow
                                                key={paymentIntents[key].uid}
                                            >
                                                <IonCol className='line_item_name'>
                                                    <p>
                                                        {paymentIntents[key]
                                                            .firstName +
                                                            "'s Gratuity"}
                                                    </p>
                                                </IonCol>
                                                <IonCol className='line_item_amount'>
                                                    <p>
                                                        {formatAmount(
                                                            paymentIntents[key]
                                                                .amount
                                                        )}
                                                    </p>
                                                </IonCol>
                                            </IonRow>
                                        );
                                    }
                                )}
                                <IonRow>
                                    <IonCol className='line_item_name'>
                                        <p>{'Service Fee'}</p>
                                    </IonCol>
                                    <IonCol className='line_item_amount'>
                                        <p>
                                            {formatAmount(
                                                calculatePlatformFee()
                                            )}
                                        </p>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol className='line_item_name'>
                                        <p>{'Processing Fee'}</p>
                                    </IonCol>
                                    <IonCol className='line_item_amount'>
                                        <p>
                                            {formatAmount(
                                                calculateStripeFees()
                                            )}
                                        </p>
                                    </IonCol>
                                </IonRow>

                                <IonRow>
                                    <IonCol className='total_line_item'>
                                        <p>{'Your Total'}</p>
                                    </IonCol>
                                    <IonCol className='total_line_item_amount'>
                                        <p>{formatAmount(getUserTotal())}</p>
                                    </IonCol>
                                </IonRow>
                            </IonCol>
                        ) : (
                            <></>
                        )}
                        {transactionLoading && (
                            <div className='schedule__spinner--wrap modified-spinner'>
                                <IonSpinner
                                    className='schedule__spinner'
                                    name='crescent'
                                />
                            </div>
                        )}
                        {wasSuccessful && (
                            <IonCol className='stripe_response_message'>
                                <IonRow>
                                    <p style={{ width: '100%' }}>
                                        {'Payments have been successfully sent'}
                                    </p>
                                </IonRow>
                                <IonRow
                                    style={{
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <IonIcon
                                        className='success_icon'
                                        icon={checkmark}
                                    />
                                </IonRow>
                            </IonCol>
                        )}
                        <ErrorMessage errorCode={errorCode} />

                        {/* Stripe Disclaimer Text */}
                        <IonRow>
                            <p className='disclaimer_text'>
                                As a service fee, YING deducts 15% of all
                                gratuity under $4.99 and $1 for gratuity over $5
                            </p>
                        </IonRow>
                        {isActiveCustomer && (
                            <IonRow style={{ margin: '10px 10px' }}>
                                <IonButton
                                    fill='clear'
                                    disabled={
                                        Object.keys(paymentIntents).length <
                                            1 || hasBeenSubmitted
                                    }
                                    color='ion-primary'
                                    onClick={handleStripeTransaction}
                                    className='footerWithChild__btn'
                                >
                                    Send & Finish Task
                                </IonButton>
                            </IonRow>
                        )}

                        {!hasBeenSubmitted && (
                            <IonRow>
                                <a
                                    className='skip_button'
                                    onClick={completeTask}
                                >
                                    Skip Gratitude & Finish Task
                                </a>
                            </IonRow>
                        )}
                    </IonGrid>
                </IonContent>
            </IonPage>
        </>
    );
};
