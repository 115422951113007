import gql from 'graphql-tag';

export const GetOrgTransactions = gql`
    query GetOrgTransactions($groupId: Int!) {
        getGroupTasks(groupId: $groupId) {
            groupTasks {
                id
                skill {
                    name
                    description
                    additionalDetails
                    jobPost {
                        id
                        title
                        startDate
                        startTime
                        endTime
                    }
                }
                provider {
                    uid
                    firstName
                    lastName
                    profilePicture
                }
                recipient {
                    uid
                    firstName
                    lastName
                    profilePicture
                }
                recipientOfferCredits
                credits
                status
                paymentIntent
                stripeTransfer
                lastUpdatedAt
            }
            orgPicture
        }
    }
`;
