/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * The Location's Preference
 */
export enum LocationPreference {
    INPERSON = 'INPERSON',
    OPENTOALL = 'OPENTOALL',
    VIRTUAL = 'VIRTUAL',
}

/**
 * The type of notification
 */
export enum NotificationType {
    GENERAL = 'GENERAL',
    INVITE = 'INVITE',
    NUDGE = 'NUDGE',
    PARTICIPATION_ACCEPTED = 'PARTICIPATION_ACCEPTED',
    PARTICIPATION_CANCELED = 'PARTICIPATION_CANCELED',
    PARTICIPATION_DENIED = 'PARTICIPATION_DENIED',
    PARTICIPATION_REQUEST = 'PARTICIPATION_REQUEST',
    REMINDER = 'REMINDER',
    TASK_POSTED = 'TASK_POSTED',
    TASK_POST_DELETED = 'TASK_POST_DELETED',
    TASK_POST_UPDATED = 'TASK_POST_UPDATED',
    TASK_REQUEST = 'TASK_REQUEST',
    TASK_REQUEST_ACCEPTED = 'TASK_REQUEST_ACCEPTED',
    TASK_REQUEST_CANCELED = 'TASK_REQUEST_CANCELED',
    TASK_REQUEST_DENIED = 'TASK_REQUEST_DENIED',
    TASK_REQUEST_REMOVED = 'TASK_REQUEST_REMOVED',
}

/**
 * The Job's current status.
 */
export enum Status {
    ACCEPTED = 'ACCEPTED',
    ACTIVE = 'ACTIVE',
    CANCELED = 'CANCELED',
    COMPLETED = 'COMPLETED',
    CONTESTED = 'CONTESTED',
    DENIED = 'DENIED',
    DISABLED = 'DISABLED',
    EXPIRED = 'EXPIRED',
    FULL = 'FULL',
    INPROGRESS = 'INPROGRESS',
    OPEN = 'OPEN',
    PENDING = 'PENDING',
    RECIPIENT_HAS_PROPOSED_OFFER = 'RECIPIENT_HAS_PROPOSED_OFFER',
    REMOVED = 'REMOVED',
    REQUESTED = 'REQUESTED',
    SCHEDULED = 'SCHEDULED',
}

export enum accountType {
    BALANCE = 'BALANCE',
    FIAT = 'FIAT',
}

export interface AvailabilityCreateOneWithoutSkillInput {
    create?: AvailabilityCreateWithoutSkillInput | null;
    connect?: AvailabilityWhereUniqueInput | null;
}

export interface AvailabilityCreateWithoutSkillInput {
    daysAvailable?: AvailabilityCreatedaysAvailableInput | null;
    hoursAvailable?: AvailabilityCreatehoursAvailableInput | null;
}

export interface AvailabilityCreatedaysAvailableInput {
    set?: boolean[] | null;
}

export interface AvailabilityCreatehoursAvailableInput {
    set?: boolean[] | null;
}

export interface AvailabilityWhereUniqueInput {
    id?: number | null;
}

export interface BowelCreateOneWithoutProfilesInput {
    create?: BowelCreateWithoutProfilesInput | null;
    connect?: BowelWhereUniqueInput | null;
}

export interface BowelCreateWithoutProfilesInput {
    name: string;
}

export interface BowelWhereUniqueInput {
    id?: number | null;
}

export interface DeviceCreateManyWithoutOwnedByProfileInput {
    create?: DeviceCreateWithoutOwnedByProfileInput[] | null;
    connect?: DeviceWhereUniqueInput[] | null;
}

export interface DeviceCreateWithoutOwnedByProfileInput {
    fcmToken: string;
}

export interface DeviceWhereUniqueInput {
    id?: number | null;
}

export interface GroupCodeInput {
    code: string;
}

export interface JobCreateManyWithoutAdminInput {
    create?: JobCreateWithoutAdminInput[] | null;
    connect?: JobWhereUniqueInput[] | null;
}

export interface JobCreateManyWithoutGroupInput {
    create?: JobCreateWithoutGroupInput[] | null;
    connect?: JobWhereUniqueInput[] | null;
}

export interface JobCreateManyWithoutProviderInput {
    create?: JobCreateWithoutProviderInput[] | null;
    connect?: JobWhereUniqueInput[] | null;
}

export interface JobCreateManyWithoutReceiverInput {
    create?: JobCreateWithoutReceiverInput[] | null;
    connect?: JobWhereUniqueInput[] | null;
}

export interface JobCreateManyWithoutRecipientInput {
    create?: JobCreateWithoutRecipientInput[] | null;
    connect?: JobWhereUniqueInput[] | null;
}

export interface JobCreateManyWithoutSenderInput {
    create?: JobCreateWithoutSenderInput[] | null;
    connect?: JobWhereUniqueInput[] | null;
}

export interface JobCreateManyWithoutSkillInput {
    create?: JobCreateWithoutSkillInput[] | null;
    connect?: JobWhereUniqueInput[] | null;
}

export interface JobCreateWithoutAdminInput {
    Status?: Status | null;
    startTime: any;
    endTime: any;
    requestMessage?: string | null;
    cancelationNote?: string | null;
    providersReviewOfRecipient?: string | null;
    recipientsReviewOfProvider?: string | null;
    providersRatingOfRecipient?: number | null;
    recipientsRatingOfProvider?: number | null;
    recipientOfferCredits?: number | null;
    providerRequestCredits?: number | null;
    CREDITS?: number | null;
    Skill: SkillCreateOneWithoutJobInput;
    location?: JobLocationCreateOneWithoutJobInput | null;
    provider: ProfileCreateOneWithoutUpcomingJobsServeInput;
    recipient: ProfileCreateOneWithoutUpcomingJobsReceiveInput;
    group?: OrganizationCreateOneWithoutJobsPosted3Input | null;
    sender?: partyCreateOneWithoutSenderInput | null;
    receiver?: partyCreateOneWithoutReceiverInput | null;
}

export interface JobCreateWithoutGroupInput {
    Status?: Status | null;
    startTime: any;
    endTime: any;
    requestMessage?: string | null;
    cancelationNote?: string | null;
    providersReviewOfRecipient?: string | null;
    recipientsReviewOfProvider?: string | null;
    providersRatingOfRecipient?: number | null;
    recipientsRatingOfProvider?: number | null;
    recipientOfferCredits?: number | null;
    providerRequestCredits?: number | null;
    CREDITS?: number | null;
    Skill: SkillCreateOneWithoutJobInput;
    location?: JobLocationCreateOneWithoutJobInput | null;
    provider: ProfileCreateOneWithoutUpcomingJobsServeInput;
    recipient: ProfileCreateOneWithoutUpcomingJobsReceiveInput;
    admin?: ProfilesOrganizationsRolesxCreateOneWithoutJobyInput | null;
    sender?: partyCreateOneWithoutSenderInput | null;
    receiver?: partyCreateOneWithoutReceiverInput | null;
}

export interface JobCreateWithoutProviderInput {
    Status?: Status | null;
    startTime: any;
    endTime: any;
    requestMessage?: string | null;
    cancelationNote?: string | null;
    providersReviewOfRecipient?: string | null;
    recipientsReviewOfProvider?: string | null;
    providersRatingOfRecipient?: number | null;
    recipientsRatingOfProvider?: number | null;
    recipientOfferCredits?: number | null;
    providerRequestCredits?: number | null;
    CREDITS?: number | null;
    Skill: SkillCreateOneWithoutJobInput;
    location?: JobLocationCreateOneWithoutJobInput | null;
    recipient: ProfileCreateOneWithoutUpcomingJobsReceiveInput;
    admin?: ProfilesOrganizationsRolesxCreateOneWithoutJobyInput | null;
    group?: OrganizationCreateOneWithoutJobsPosted3Input | null;
    sender?: partyCreateOneWithoutSenderInput | null;
    receiver?: partyCreateOneWithoutReceiverInput | null;
}

export interface JobCreateWithoutReceiverInput {
    Status?: Status | null;
    startTime: any;
    endTime: any;
    requestMessage?: string | null;
    cancelationNote?: string | null;
    providersReviewOfRecipient?: string | null;
    recipientsReviewOfProvider?: string | null;
    providersRatingOfRecipient?: number | null;
    recipientsRatingOfProvider?: number | null;
    recipientOfferCredits?: number | null;
    providerRequestCredits?: number | null;
    CREDITS?: number | null;
    Skill: SkillCreateOneWithoutJobInput;
    location?: JobLocationCreateOneWithoutJobInput | null;
    provider: ProfileCreateOneWithoutUpcomingJobsServeInput;
    recipient: ProfileCreateOneWithoutUpcomingJobsReceiveInput;
    admin?: ProfilesOrganizationsRolesxCreateOneWithoutJobyInput | null;
    group?: OrganizationCreateOneWithoutJobsPosted3Input | null;
    sender?: partyCreateOneWithoutSenderInput | null;
}

export interface JobCreateWithoutRecipientInput {
    Status?: Status | null;
    startTime: any;
    endTime: any;
    requestMessage?: string | null;
    cancelationNote?: string | null;
    providersReviewOfRecipient?: string | null;
    recipientsReviewOfProvider?: string | null;
    providersRatingOfRecipient?: number | null;
    recipientsRatingOfProvider?: number | null;
    recipientOfferCredits?: number | null;
    providerRequestCredits?: number | null;
    CREDITS?: number | null;
    Skill: SkillCreateOneWithoutJobInput;
    location?: JobLocationCreateOneWithoutJobInput | null;
    provider: ProfileCreateOneWithoutUpcomingJobsServeInput;
    admin?: ProfilesOrganizationsRolesxCreateOneWithoutJobyInput | null;
    group?: OrganizationCreateOneWithoutJobsPosted3Input | null;
    sender?: partyCreateOneWithoutSenderInput | null;
    receiver?: partyCreateOneWithoutReceiverInput | null;
}

export interface JobCreateWithoutSenderInput {
    Status?: Status | null;
    startTime: any;
    endTime: any;
    requestMessage?: string | null;
    cancelationNote?: string | null;
    providersReviewOfRecipient?: string | null;
    recipientsReviewOfProvider?: string | null;
    providersRatingOfRecipient?: number | null;
    recipientsRatingOfProvider?: number | null;
    recipientOfferCredits?: number | null;
    providerRequestCredits?: number | null;
    CREDITS?: number | null;
    Skill: SkillCreateOneWithoutJobInput;
    location?: JobLocationCreateOneWithoutJobInput | null;
    provider: ProfileCreateOneWithoutUpcomingJobsServeInput;
    recipient: ProfileCreateOneWithoutUpcomingJobsReceiveInput;
    admin?: ProfilesOrganizationsRolesxCreateOneWithoutJobyInput | null;
    group?: OrganizationCreateOneWithoutJobsPosted3Input | null;
    receiver?: partyCreateOneWithoutReceiverInput | null;
}

export interface JobCreateWithoutSkillInput {
    Status?: Status | null;
    startTime: any;
    endTime: any;
    requestMessage?: string | null;
    cancelationNote?: string | null;
    providersReviewOfRecipient?: string | null;
    recipientsReviewOfProvider?: string | null;
    providersRatingOfRecipient?: number | null;
    recipientsRatingOfProvider?: number | null;
    recipientOfferCredits?: number | null;
    providerRequestCredits?: number | null;
    CREDITS?: number | null;
    location?: JobLocationCreateOneWithoutJobInput | null;
    provider: ProfileCreateOneWithoutUpcomingJobsServeInput;
    recipient: ProfileCreateOneWithoutUpcomingJobsReceiveInput;
    admin?: ProfilesOrganizationsRolesxCreateOneWithoutJobyInput | null;
    group?: OrganizationCreateOneWithoutJobsPosted3Input | null;
    sender?: partyCreateOneWithoutSenderInput | null;
    receiver?: partyCreateOneWithoutReceiverInput | null;
}

export interface JobLocationCreateOneWithoutJobInput {
    create?: JobLocationCreateWithoutJobInput | null;
    connect?: JobLocationWhereUniqueInput | null;
}

export interface JobLocationCreateWithoutJobInput {
    createdAt?: any | null;
    lastUpdatedAt?: any | null;
    locationString?: string | null;
    locationPreference?: LocationPreference | null;
}

export interface JobLocationWhereUniqueInput {
    id?: number | null;
}

export interface JobPostCreateManyWithoutAdminInput {
    create?: JobPostCreateWithoutAdminInput[] | null;
    connect?: JobPostWhereUniqueInput[] | null;
}

export interface JobPostCreateManyWithoutGroupInput {
    create?: JobPostCreateWithoutGroupInput[] | null;
    connect?: JobPostWhereUniqueInput[] | null;
}

export interface JobPostCreateManyWithoutOrganizationsInput {
    create?: JobPostCreateWithoutOrganizationsInput[] | null;
    connect?: JobPostWhereUniqueInput[] | null;
}

export interface JobPostCreateManyWithoutRecipientInput {
    create?: JobPostCreateWithoutRecipientInput[] | null;
    connect?: JobPostWhereUniqueInput[] | null;
}

export interface JobPostCreateOneWithoutSkillsNeededInput {
    create?: JobPostCreateWithoutSkillsNeededInput | null;
    connect?: JobPostWhereUniqueInput | null;
}

export interface JobPostCreateWithoutAdminInput {
    createdAt?: any | null;
    lastUpdatedAt?: any | null;
    title: string;
    description: string;
    startDate?: any | null;
    startTime?: any | null;
    endTime?: any | null;
    status?: Status | null;
    recipient: ProfileCreateOneWithoutJobsPostedInput;
    skillsNeeded?: SkillNeededCreateManyWithoutJobPostInput | null;
    organizations?: OrganizationCreateManyWithoutJobsPostedInput | null;
    location?: JobPostLocationCreateOneWithoutJobInput | null;
    group?: OrganizationCreateOneWithoutJobsPosted2Input | null;
}

export interface JobPostCreateWithoutGroupInput {
    createdAt?: any | null;
    lastUpdatedAt?: any | null;
    title: string;
    description: string;
    startDate?: any | null;
    startTime?: any | null;
    endTime?: any | null;
    status?: Status | null;
    recipient: ProfileCreateOneWithoutJobsPostedInput;
    skillsNeeded?: SkillNeededCreateManyWithoutJobPostInput | null;
    organizations?: OrganizationCreateManyWithoutJobsPostedInput | null;
    location?: JobPostLocationCreateOneWithoutJobInput | null;
    admin?: ProfilesOrganizationsRolesxCreateOneWithoutJobpostyInput | null;
}

export interface JobPostCreateWithoutOrganizationsInput {
    createdAt?: any | null;
    lastUpdatedAt?: any | null;
    title: string;
    description: string;
    startDate?: any | null;
    startTime?: any | null;
    endTime?: any | null;
    status?: Status | null;
    recipient: ProfileCreateOneWithoutJobsPostedInput;
    skillsNeeded?: SkillNeededCreateManyWithoutJobPostInput | null;
    location?: JobPostLocationCreateOneWithoutJobInput | null;
    admin?: ProfilesOrganizationsRolesxCreateOneWithoutJobpostyInput | null;
    group?: OrganizationCreateOneWithoutJobsPosted2Input | null;
}

export interface JobPostCreateWithoutRecipientInput {
    createdAt?: any | null;
    lastUpdatedAt?: any | null;
    title: string;
    description: string;
    startDate?: any | null;
    startTime?: any | null;
    endTime?: any | null;
    status?: Status | null;
    skillsNeeded?: SkillNeededCreateManyWithoutJobPostInput | null;
    organizations?: OrganizationCreateManyWithoutJobsPostedInput | null;
    location?: JobPostLocationCreateOneWithoutJobInput | null;
    admin?: ProfilesOrganizationsRolesxCreateOneWithoutJobpostyInput | null;
    group?: OrganizationCreateOneWithoutJobsPosted2Input | null;
}

export interface JobPostCreateWithoutSkillsNeededInput {
    createdAt?: any | null;
    lastUpdatedAt?: any | null;
    title: string;
    description: string;
    startDate?: any | null;
    startTime?: any | null;
    endTime?: any | null;
    status?: Status | null;
    recipient: ProfileCreateOneWithoutJobsPostedInput;
    organizations?: OrganizationCreateManyWithoutJobsPostedInput | null;
    location?: JobPostLocationCreateOneWithoutJobInput | null;
    admin?: ProfilesOrganizationsRolesxCreateOneWithoutJobpostyInput | null;
    group?: OrganizationCreateOneWithoutJobsPosted2Input | null;
}

export interface JobPostLocationCreateOneWithoutJobInput {
    create?: JobPostLocationCreateWithoutJobInput | null;
    connect?: JobPostLocationWhereUniqueInput | null;
}

export interface JobPostLocationCreateWithoutJobInput {
    createdAt?: any | null;
    lastUpdatedAt?: any | null;
    locationString?: string | null;
    locationPreference?: LocationPreference | null;
}

export interface JobPostLocationWhereUniqueInput {
    id?: number | null;
}

export interface JobPostWhereUniqueInput {
    id?: number | null;
}

export interface JobWhereUniqueInput {
    id?: number | null;
}

export interface LocationCreateOneWithoutProfileInput {
    create?: LocationCreateWithoutProfileInput | null;
    connect?: LocationWhereUniqueInput | null;
}

export interface LocationCreateWithoutProfileInput {
    createdAt?: any | null;
    lastUpdatedAt?: any | null;
    locationString?: string | null;
}

export interface LocationWhereUniqueInput {
    id?: number | null;
}

export interface NotificationCreateManyWithoutAdminInput {
    create?: NotificationCreateWithoutAdminInput[] | null;
    connect?: NotificationWhereUniqueInput[] | null;
}

export interface NotificationCreateManyWithoutGroupInput {
    create?: NotificationCreateWithoutGroupInput[] | null;
    connect?: NotificationWhereUniqueInput[] | null;
}

export interface NotificationCreateManyWithoutReceiverProfileInput {
    create?: NotificationCreateWithoutReceiverProfileInput[] | null;
    connect?: NotificationWhereUniqueInput[] | null;
}

export interface NotificationCreateManyWithoutSenderProfileInput {
    create?: NotificationCreateWithoutSenderProfileInput[] | null;
    connect?: NotificationWhereUniqueInput[] | null;
}

export interface NotificationCreateWithoutAdminInput {
    createdAt?: any | null;
    lastUpdatedAt?: any | null;
    expires?: any | null;
    title: string;
    subTitle: string;
    body: string;
    additionalDetails?: string | null;
    seen?: boolean | null;
    read?: boolean | null;
    dismissed?: boolean | null;
    path?: string | null;
    notificationType?: NotificationType | null;
    receiverProfile: ProfileCreateOneWithoutNotificationsInput;
    senderProfile?: ProfileCreateOneWithoutNotification2Input | null;
    group?: OrganizationCreateOneWithoutNotificationsInput | null;
}

export interface NotificationCreateWithoutGroupInput {
    createdAt?: any | null;
    lastUpdatedAt?: any | null;
    expires?: any | null;
    title: string;
    subTitle: string;
    body: string;
    additionalDetails?: string | null;
    seen?: boolean | null;
    read?: boolean | null;
    dismissed?: boolean | null;
    path?: string | null;
    notificationType?: NotificationType | null;
    receiverProfile: ProfileCreateOneWithoutNotificationsInput;
    senderProfile?: ProfileCreateOneWithoutNotification2Input | null;
    admin?: ProfilesOrganizationsRolesxCreateOneWithoutNotificationsInput | null;
}

export interface NotificationCreateWithoutReceiverProfileInput {
    createdAt?: any | null;
    lastUpdatedAt?: any | null;
    expires?: any | null;
    title: string;
    subTitle: string;
    body: string;
    additionalDetails?: string | null;
    seen?: boolean | null;
    read?: boolean | null;
    dismissed?: boolean | null;
    path?: string | null;
    notificationType?: NotificationType | null;
    senderProfile?: ProfileCreateOneWithoutNotification2Input | null;
    admin?: ProfilesOrganizationsRolesxCreateOneWithoutNotificationsInput | null;
    group?: OrganizationCreateOneWithoutNotificationsInput | null;
}

export interface NotificationCreateWithoutSenderProfileInput {
    createdAt?: any | null;
    lastUpdatedAt?: any | null;
    expires?: any | null;
    title: string;
    subTitle: string;
    body: string;
    additionalDetails?: string | null;
    seen?: boolean | null;
    read?: boolean | null;
    dismissed?: boolean | null;
    path?: string | null;
    notificationType?: NotificationType | null;
    receiverProfile: ProfileCreateOneWithoutNotificationsInput;
    admin?: ProfilesOrganizationsRolesxCreateOneWithoutNotificationsInput | null;
    group?: OrganizationCreateOneWithoutNotificationsInput | null;
}

export interface NotificationWhereUniqueInput {
    id?: number | null;
}

export interface OrganizationCreateManyWithoutJobsPostedInput {
    create?: OrganizationCreateWithoutJobsPostedInput[] | null;
    connect?: OrganizationWhereUniqueInput[] | null;
}

export interface OrganizationCreateManyWithoutMembersInput {
    create?: OrganizationCreateWithoutMembersInput[] | null;
    connect?: OrganizationWhereUniqueInput[] | null;
}

export interface OrganizationCreateOneWithoutJobsPosted2Input {
    create?: OrganizationCreateWithoutJobsPosted2Input | null;
    connect?: OrganizationWhereUniqueInput | null;
}

export interface OrganizationCreateOneWithoutJobsPosted3Input {
    create?: OrganizationCreateWithoutJobsPosted3Input | null;
    connect?: OrganizationWhereUniqueInput | null;
}

export interface OrganizationCreateOneWithoutNotificationsInput {
    create?: OrganizationCreateWithoutNotificationsInput | null;
    connect?: OrganizationWhereUniqueInput | null;
}

export interface OrganizationCreateOneWithoutPartiesInput {
    create?: OrganizationCreateWithoutPartiesInput | null;
    connect?: OrganizationWhereUniqueInput | null;
}

export interface OrganizationCreateOneWithoutPartrequestInput {
    create?: OrganizationCreateWithoutPartrequestInput | null;
    connect?: OrganizationWhereUniqueInput | null;
}

export interface OrganizationCreateOneWithoutPresentationsInput {
    create?: OrganizationCreateWithoutPresentationsInput | null;
    connect?: OrganizationWhereUniqueInput | null;
}

export interface OrganizationCreateOneWithoutProxInput {
    create?: OrganizationCreateWithoutProxInput | null;
    connect?: OrganizationWhereUniqueInput | null;
}

export interface OrganizationCreateWithoutJobsPosted2Input {
    active?: boolean | null;
    category: string;
    code: string;
    contactEmail: string;
    contactPhone: string;
    createdAt?: any | null;
    description?: string | null;
    ein?: string | null;
    industry: string;
    name: string;
    totalMembers: number;
    website: string;
    orgPicture?: string | null;
    parties?: partyCreateManyWithoutGroupIdFkInput | null;
    members?: ProfileCreateManyWithoutOrganizationsInput | null;
    jobsPosted?: JobPostCreateManyWithoutOrganizationsInput | null;
    jobsPosted3?: JobCreateManyWithoutGroupInput | null;
    partrequest?: ParticipationRequestCreateManyWithoutGroupInput | null;
    notifications?: NotificationCreateManyWithoutGroupInput | null;
    prox?: ProfilesOrganizationsRolesxCreateManyWithoutOrganizationInput | null;
    presentations?: PresentationCreateManyWithoutOrganizationInput | null;
}

export interface OrganizationCreateWithoutJobsPosted3Input {
    active?: boolean | null;
    category: string;
    code: string;
    contactEmail: string;
    contactPhone: string;
    createdAt?: any | null;
    description?: string | null;
    ein?: string | null;
    industry: string;
    name: string;
    totalMembers: number;
    website: string;
    orgPicture?: string | null;
    parties?: partyCreateManyWithoutGroupIdFkInput | null;
    members?: ProfileCreateManyWithoutOrganizationsInput | null;
    jobsPosted?: JobPostCreateManyWithoutOrganizationsInput | null;
    jobsPosted2?: JobPostCreateManyWithoutGroupInput | null;
    partrequest?: ParticipationRequestCreateManyWithoutGroupInput | null;
    notifications?: NotificationCreateManyWithoutGroupInput | null;
    prox?: ProfilesOrganizationsRolesxCreateManyWithoutOrganizationInput | null;
    presentations?: PresentationCreateManyWithoutOrganizationInput | null;
}

export interface OrganizationCreateWithoutJobsPostedInput {
    active?: boolean | null;
    category: string;
    code: string;
    contactEmail: string;
    contactPhone: string;
    createdAt?: any | null;
    description?: string | null;
    ein?: string | null;
    industry: string;
    name: string;
    totalMembers: number;
    website: string;
    orgPicture?: string | null;
    parties?: partyCreateManyWithoutGroupIdFkInput | null;
    members?: ProfileCreateManyWithoutOrganizationsInput | null;
    jobsPosted2?: JobPostCreateManyWithoutGroupInput | null;
    jobsPosted3?: JobCreateManyWithoutGroupInput | null;
    partrequest?: ParticipationRequestCreateManyWithoutGroupInput | null;
    notifications?: NotificationCreateManyWithoutGroupInput | null;
    prox?: ProfilesOrganizationsRolesxCreateManyWithoutOrganizationInput | null;
    presentations?: PresentationCreateManyWithoutOrganizationInput | null;
}

export interface OrganizationCreateWithoutMembersInput {
    active?: boolean | null;
    category: string;
    code: string;
    contactEmail: string;
    contactPhone: string;
    createdAt?: any | null;
    description?: string | null;
    ein?: string | null;
    industry: string;
    name: string;
    totalMembers: number;
    website: string;
    orgPicture?: string | null;
    parties?: partyCreateManyWithoutGroupIdFkInput | null;
    jobsPosted?: JobPostCreateManyWithoutOrganizationsInput | null;
    jobsPosted2?: JobPostCreateManyWithoutGroupInput | null;
    jobsPosted3?: JobCreateManyWithoutGroupInput | null;
    partrequest?: ParticipationRequestCreateManyWithoutGroupInput | null;
    notifications?: NotificationCreateManyWithoutGroupInput | null;
    prox?: ProfilesOrganizationsRolesxCreateManyWithoutOrganizationInput | null;
    presentations?: PresentationCreateManyWithoutOrganizationInput | null;
}

export interface OrganizationCreateWithoutNotificationsInput {
    active?: boolean | null;
    category: string;
    code: string;
    contactEmail: string;
    contactPhone: string;
    createdAt?: any | null;
    description?: string | null;
    ein?: string | null;
    industry: string;
    name: string;
    totalMembers: number;
    website: string;
    orgPicture?: string | null;
    parties?: partyCreateManyWithoutGroupIdFkInput | null;
    members?: ProfileCreateManyWithoutOrganizationsInput | null;
    jobsPosted?: JobPostCreateManyWithoutOrganizationsInput | null;
    jobsPosted2?: JobPostCreateManyWithoutGroupInput | null;
    jobsPosted3?: JobCreateManyWithoutGroupInput | null;
    partrequest?: ParticipationRequestCreateManyWithoutGroupInput | null;
    prox?: ProfilesOrganizationsRolesxCreateManyWithoutOrganizationInput | null;
    presentations?: PresentationCreateManyWithoutOrganizationInput | null;
}

export interface OrganizationCreateWithoutPartiesInput {
    active?: boolean | null;
    category: string;
    code: string;
    contactEmail: string;
    contactPhone: string;
    createdAt?: any | null;
    description?: string | null;
    ein?: string | null;
    industry: string;
    name: string;
    totalMembers: number;
    website: string;
    orgPicture?: string | null;
    members?: ProfileCreateManyWithoutOrganizationsInput | null;
    jobsPosted?: JobPostCreateManyWithoutOrganizationsInput | null;
    jobsPosted2?: JobPostCreateManyWithoutGroupInput | null;
    jobsPosted3?: JobCreateManyWithoutGroupInput | null;
    partrequest?: ParticipationRequestCreateManyWithoutGroupInput | null;
    notifications?: NotificationCreateManyWithoutGroupInput | null;
    prox?: ProfilesOrganizationsRolesxCreateManyWithoutOrganizationInput | null;
    presentations?: PresentationCreateManyWithoutOrganizationInput | null;
}

export interface OrganizationCreateWithoutPartrequestInput {
    active?: boolean | null;
    category: string;
    code: string;
    contactEmail: string;
    contactPhone: string;
    createdAt?: any | null;
    description?: string | null;
    ein?: string | null;
    industry: string;
    name: string;
    totalMembers: number;
    website: string;
    orgPicture?: string | null;
    parties?: partyCreateManyWithoutGroupIdFkInput | null;
    members?: ProfileCreateManyWithoutOrganizationsInput | null;
    jobsPosted?: JobPostCreateManyWithoutOrganizationsInput | null;
    jobsPosted2?: JobPostCreateManyWithoutGroupInput | null;
    jobsPosted3?: JobCreateManyWithoutGroupInput | null;
    notifications?: NotificationCreateManyWithoutGroupInput | null;
    prox?: ProfilesOrganizationsRolesxCreateManyWithoutOrganizationInput | null;
    presentations?: PresentationCreateManyWithoutOrganizationInput | null;
}

export interface OrganizationCreateWithoutPresentationsInput {
    active?: boolean | null;
    category: string;
    code: string;
    contactEmail: string;
    contactPhone: string;
    createdAt?: any | null;
    description?: string | null;
    ein?: string | null;
    industry: string;
    name: string;
    totalMembers: number;
    website: string;
    orgPicture?: string | null;
    parties?: partyCreateManyWithoutGroupIdFkInput | null;
    members?: ProfileCreateManyWithoutOrganizationsInput | null;
    jobsPosted?: JobPostCreateManyWithoutOrganizationsInput | null;
    jobsPosted2?: JobPostCreateManyWithoutGroupInput | null;
    jobsPosted3?: JobCreateManyWithoutGroupInput | null;
    partrequest?: ParticipationRequestCreateManyWithoutGroupInput | null;
    notifications?: NotificationCreateManyWithoutGroupInput | null;
    prox?: ProfilesOrganizationsRolesxCreateManyWithoutOrganizationInput | null;
}

export interface OrganizationCreateWithoutProxInput {
    active?: boolean | null;
    category: string;
    code: string;
    contactEmail: string;
    contactPhone: string;
    createdAt?: any | null;
    description?: string | null;
    ein?: string | null;
    industry: string;
    name: string;
    totalMembers: number;
    website: string;
    orgPicture?: string | null;
    parties?: partyCreateManyWithoutGroupIdFkInput | null;
    members?: ProfileCreateManyWithoutOrganizationsInput | null;
    jobsPosted?: JobPostCreateManyWithoutOrganizationsInput | null;
    jobsPosted2?: JobPostCreateManyWithoutGroupInput | null;
    jobsPosted3?: JobCreateManyWithoutGroupInput | null;
    partrequest?: ParticipationRequestCreateManyWithoutGroupInput | null;
    notifications?: NotificationCreateManyWithoutGroupInput | null;
    presentations?: PresentationCreateManyWithoutOrganizationInput | null;
}

export interface OrganizationWhereUniqueInput {
    code?: string | null;
    id?: number | null;
}

export interface ParticipationReqInput {
    id: number;
    recipientsReviewOfProvider: string;
    recipientsRatingOfProvider: number;
    recipientOfferCredits: number;
    status: string;
}

export interface ParticipationRequestCreateManyWithoutAdminInput {
    create?: ParticipationRequestCreateWithoutAdminInput[] | null;
    connect?: ParticipationRequestWhereUniqueInput[] | null;
}

export interface ParticipationRequestCreateManyWithoutGroupInput {
    create?: ParticipationRequestCreateWithoutGroupInput[] | null;
    connect?: ParticipationRequestWhereUniqueInput[] | null;
}

export interface ParticipationRequestCreateManyWithoutProviderInput {
    create?: ParticipationRequestCreateWithoutProviderInput[] | null;
    connect?: ParticipationRequestWhereUniqueInput[] | null;
}

export interface ParticipationRequestCreateManyWithoutReceiverInput {
    create?: ParticipationRequestCreateWithoutReceiverInput[] | null;
    connect?: ParticipationRequestWhereUniqueInput[] | null;
}

export interface ParticipationRequestCreateManyWithoutRecipientInput {
    create?: ParticipationRequestCreateWithoutRecipientInput[] | null;
    connect?: ParticipationRequestWhereUniqueInput[] | null;
}

export interface ParticipationRequestCreateManyWithoutSenderInput {
    create?: ParticipationRequestCreateWithoutSenderInput[] | null;
    connect?: ParticipationRequestWhereUniqueInput[] | null;
}

export interface ParticipationRequestCreateManyWithoutSkillInput {
    create?: ParticipationRequestCreateWithoutSkillInput[] | null;
    connect?: ParticipationRequestWhereUniqueInput[] | null;
}

export interface ParticipationRequestCreateWithoutAdminInput {
    createdAt?: any | null;
    lastUpdatedAt?: any | null;
    providersReviewOfRecipient?: string | null;
    recipientsReviewOfProvider?: string | null;
    providersRatingOfRecipient?: number | null;
    recipientsRatingOfProvider?: number | null;
    recipientOfferCredits?: number | null;
    providerRequestCredits?: number | null;
    credits?: number | null;
    status?: Status | null;
    paymentIntent?: string | null;
    stripeTransfer?: string | null;
    skill: SkillNeededCreateOneWithoutParticipationRequestsInput;
    recipient: ProfileCreateOneWithoutIncomingParticipationRequestsInput;
    provider: ProfileCreateOneWithoutOutgoingParticipationRequestsInput;
    group?: OrganizationCreateOneWithoutPartrequestInput | null;
    sender?: partyCreateOneWithoutSenderTwoInput | null;
    receiver?: partyCreateOneWithoutReceiverTwoInput | null;
}

export interface ParticipationRequestCreateWithoutGroupInput {
    createdAt?: any | null;
    lastUpdatedAt?: any | null;
    providersReviewOfRecipient?: string | null;
    recipientsReviewOfProvider?: string | null;
    providersRatingOfRecipient?: number | null;
    recipientsRatingOfProvider?: number | null;
    recipientOfferCredits?: number | null;
    providerRequestCredits?: number | null;
    credits?: number | null;
    status?: Status | null;
    paymentIntent?: string | null;
    stripeTransfer?: string | null;
    skill: SkillNeededCreateOneWithoutParticipationRequestsInput;
    recipient: ProfileCreateOneWithoutIncomingParticipationRequestsInput;
    provider: ProfileCreateOneWithoutOutgoingParticipationRequestsInput;
    admin?: ProfilesOrganizationsRolesxCreateOneWithoutPrequestInput | null;
    sender?: partyCreateOneWithoutSenderTwoInput | null;
    receiver?: partyCreateOneWithoutReceiverTwoInput | null;
}

export interface ParticipationRequestCreateWithoutProviderInput {
    createdAt?: any | null;
    lastUpdatedAt?: any | null;
    providersReviewOfRecipient?: string | null;
    recipientsReviewOfProvider?: string | null;
    providersRatingOfRecipient?: number | null;
    recipientsRatingOfProvider?: number | null;
    recipientOfferCredits?: number | null;
    providerRequestCredits?: number | null;
    credits?: number | null;
    status?: Status | null;
    paymentIntent?: string | null;
    stripeTransfer?: string | null;
    skill: SkillNeededCreateOneWithoutParticipationRequestsInput;
    recipient: ProfileCreateOneWithoutIncomingParticipationRequestsInput;
    admin?: ProfilesOrganizationsRolesxCreateOneWithoutPrequestInput | null;
    group?: OrganizationCreateOneWithoutPartrequestInput | null;
    sender?: partyCreateOneWithoutSenderTwoInput | null;
    receiver?: partyCreateOneWithoutReceiverTwoInput | null;
}

export interface ParticipationRequestCreateWithoutReceiverInput {
    createdAt?: any | null;
    lastUpdatedAt?: any | null;
    providersReviewOfRecipient?: string | null;
    recipientsReviewOfProvider?: string | null;
    providersRatingOfRecipient?: number | null;
    recipientsRatingOfProvider?: number | null;
    recipientOfferCredits?: number | null;
    providerRequestCredits?: number | null;
    credits?: number | null;
    status?: Status | null;
    paymentIntent?: string | null;
    stripeTransfer?: string | null;
    skill: SkillNeededCreateOneWithoutParticipationRequestsInput;
    recipient: ProfileCreateOneWithoutIncomingParticipationRequestsInput;
    provider: ProfileCreateOneWithoutOutgoingParticipationRequestsInput;
    admin?: ProfilesOrganizationsRolesxCreateOneWithoutPrequestInput | null;
    group?: OrganizationCreateOneWithoutPartrequestInput | null;
    sender?: partyCreateOneWithoutSenderTwoInput | null;
}

export interface ParticipationRequestCreateWithoutRecipientInput {
    createdAt?: any | null;
    lastUpdatedAt?: any | null;
    providersReviewOfRecipient?: string | null;
    recipientsReviewOfProvider?: string | null;
    providersRatingOfRecipient?: number | null;
    recipientsRatingOfProvider?: number | null;
    recipientOfferCredits?: number | null;
    providerRequestCredits?: number | null;
    credits?: number | null;
    status?: Status | null;
    paymentIntent?: string | null;
    stripeTransfer?: string | null;
    skill: SkillNeededCreateOneWithoutParticipationRequestsInput;
    provider: ProfileCreateOneWithoutOutgoingParticipationRequestsInput;
    admin?: ProfilesOrganizationsRolesxCreateOneWithoutPrequestInput | null;
    group?: OrganizationCreateOneWithoutPartrequestInput | null;
    sender?: partyCreateOneWithoutSenderTwoInput | null;
    receiver?: partyCreateOneWithoutReceiverTwoInput | null;
}

export interface ParticipationRequestCreateWithoutSenderInput {
    createdAt?: any | null;
    lastUpdatedAt?: any | null;
    providersReviewOfRecipient?: string | null;
    recipientsReviewOfProvider?: string | null;
    providersRatingOfRecipient?: number | null;
    recipientsRatingOfProvider?: number | null;
    recipientOfferCredits?: number | null;
    providerRequestCredits?: number | null;
    credits?: number | null;
    status?: Status | null;
    paymentIntent?: string | null;
    stripeTransfer?: string | null;
    skill: SkillNeededCreateOneWithoutParticipationRequestsInput;
    recipient: ProfileCreateOneWithoutIncomingParticipationRequestsInput;
    provider: ProfileCreateOneWithoutOutgoingParticipationRequestsInput;
    admin?: ProfilesOrganizationsRolesxCreateOneWithoutPrequestInput | null;
    group?: OrganizationCreateOneWithoutPartrequestInput | null;
    receiver?: partyCreateOneWithoutReceiverTwoInput | null;
}

export interface ParticipationRequestCreateWithoutSkillInput {
    createdAt?: any | null;
    lastUpdatedAt?: any | null;
    providersReviewOfRecipient?: string | null;
    recipientsReviewOfProvider?: string | null;
    providersRatingOfRecipient?: number | null;
    recipientsRatingOfProvider?: number | null;
    recipientOfferCredits?: number | null;
    providerRequestCredits?: number | null;
    credits?: number | null;
    status?: Status | null;
    paymentIntent?: string | null;
    stripeTransfer?: string | null;
    recipient: ProfileCreateOneWithoutIncomingParticipationRequestsInput;
    provider: ProfileCreateOneWithoutOutgoingParticipationRequestsInput;
    admin?: ProfilesOrganizationsRolesxCreateOneWithoutPrequestInput | null;
    group?: OrganizationCreateOneWithoutPartrequestInput | null;
    sender?: partyCreateOneWithoutSenderTwoInput | null;
    receiver?: partyCreateOneWithoutReceiverTwoInput | null;
}

export interface ParticipationRequestWhereUniqueInput {
    id?: number | null;
}

export interface PresentationCreateManyWithoutOrganizationInput {
    create?: PresentationCreateWithoutOrganizationInput[] | null;
    connect?: PresentationWhereUniqueInput[] | null;
}

export interface PresentationCreateManyWithoutProfilesInput {
    create?: PresentationCreateWithoutProfilesInput[] | null;
    connect?: PresentationWhereUniqueInput[] | null;
}

export interface PresentationCreateManyWithoutSkillsInput {
    create?: PresentationCreateWithoutSkillsInput[] | null;
    connect?: PresentationWhereUniqueInput[] | null;
}

export interface PresentationCreateWithoutOrganizationInput {
    Skills?: SkillCreateManyWithoutPresentationInput | null;
    profiles?: ProfileCreateManyWithoutPresentationsInput | null;
}

export interface PresentationCreateWithoutProfilesInput {
    Skills?: SkillCreateManyWithoutPresentationInput | null;
    Organization: OrganizationCreateOneWithoutPresentationsInput;
}

export interface PresentationCreateWithoutSkillsInput {
    Organization: OrganizationCreateOneWithoutPresentationsInput;
    profiles?: ProfileCreateManyWithoutPresentationsInput | null;
}

export interface PresentationWhereUniqueInput {
    id?: number | null;
}

export interface ProfileCreateManyWithoutOrganizationsInput {
    create?: ProfileCreateWithoutOrganizationsInput[] | null;
    connect?: ProfileWhereUniqueInput[] | null;
}

export interface ProfileCreateManyWithoutPresentationsInput {
    create?: ProfileCreateWithoutPresentationsInput[] | null;
    connect?: ProfileWhereUniqueInput[] | null;
}

export interface ProfileCreateOneWithoutIncomingParticipationRequestsInput {
    create?: ProfileCreateWithoutIncomingParticipationRequestsInput | null;
    connect?: ProfileWhereUniqueInput | null;
}

export interface ProfileCreateOneWithoutJobsPostedInput {
    create?: ProfileCreateWithoutJobsPostedInput | null;
    connect?: ProfileWhereUniqueInput | null;
}

export interface ProfileCreateOneWithoutNotification2Input {
    create?: ProfileCreateWithoutNotification2Input | null;
    connect?: ProfileWhereUniqueInput | null;
}

export interface ProfileCreateOneWithoutNotificationsInput {
    create?: ProfileCreateWithoutNotificationsInput | null;
    connect?: ProfileWhereUniqueInput | null;
}

export interface ProfileCreateOneWithoutOutgoingParticipationRequestsInput {
    create?: ProfileCreateWithoutOutgoingParticipationRequestsInput | null;
    connect?: ProfileWhereUniqueInput | null;
}

export interface ProfileCreateOneWithoutPartiesInput {
    create?: ProfileCreateWithoutPartiesInput | null;
    connect?: ProfileWhereUniqueInput | null;
}

export interface ProfileCreateOneWithoutPorxInput {
    create?: ProfileCreateWithoutPorxInput | null;
    connect?: ProfileWhereUniqueInput | null;
}

export interface ProfileCreateOneWithoutReviewsInput {
    create?: ProfileCreateWithoutReviewsInput | null;
    connect?: ProfileWhereUniqueInput | null;
}

export interface ProfileCreateOneWithoutSkillsInput {
    create?: ProfileCreateWithoutSkillsInput | null;
    connect?: ProfileWhereUniqueInput | null;
}

export interface ProfileCreateOneWithoutUpcomingJobsReceiveInput {
    create?: ProfileCreateWithoutUpcomingJobsReceiveInput | null;
    connect?: ProfileWhereUniqueInput | null;
}

export interface ProfileCreateOneWithoutUpcomingJobsServeInput {
    create?: ProfileCreateWithoutUpcomingJobsServeInput | null;
    connect?: ProfileWhereUniqueInput | null;
}

export interface ProfileCreateWithoutIncomingParticipationRequestsInput {
    uid: string;
    createdAt?: any | null;
    firstName: string;
    lastName: string;
    displayName?: string | null;
    email?: string | null;
    bio?: string | null;
    profilePicture?: string | null;
    stripeAccount?: string | null;
    stripeCustomerAccount?: string | null;
    parties?: partyCreateManyWithoutUserIdFkInput | null;
    location?: LocationCreateOneWithoutProfileInput | null;
    skills?: SkillCreateManyWithoutOwnedByPortfolioInput | null;
    reviews?: ReviewCreateManyWithoutAuthorInput | null;
    upcomingJobsServe?: JobCreateManyWithoutProviderInput | null;
    upcomingJobsReceive?: JobCreateManyWithoutRecipientInput | null;
    jobsPosted?: JobPostCreateManyWithoutRecipientInput | null;
    outgoingParticipationRequests?: ParticipationRequestCreateManyWithoutProviderInput | null;
    organizations?: OrganizationCreateManyWithoutMembersInput | null;
    Presentations?: PresentationCreateManyWithoutProfilesInput | null;
    settings?: SettingCreateOneWithoutOwnedByProfileInput | null;
    devices?: DeviceCreateManyWithoutOwnedByProfileInput | null;
    notifications?: NotificationCreateManyWithoutReceiverProfileInput | null;
    notification2?: NotificationCreateManyWithoutSenderProfileInput | null;
    porx?: ProfilesOrganizationsRolesxCreateManyWithoutProfileInput | null;
}

export interface ProfileCreateWithoutJobsPostedInput {
    uid: string;
    createdAt?: any | null;
    firstName: string;
    lastName: string;
    displayName?: string | null;
    email?: string | null;
    bio?: string | null;
    profilePicture?: string | null;
    stripeAccount?: string | null;
    stripeCustomerAccount?: string | null;
    parties?: partyCreateManyWithoutUserIdFkInput | null;
    location?: LocationCreateOneWithoutProfileInput | null;
    skills?: SkillCreateManyWithoutOwnedByPortfolioInput | null;
    reviews?: ReviewCreateManyWithoutAuthorInput | null;
    upcomingJobsServe?: JobCreateManyWithoutProviderInput | null;
    upcomingJobsReceive?: JobCreateManyWithoutRecipientInput | null;
    outgoingParticipationRequests?: ParticipationRequestCreateManyWithoutProviderInput | null;
    incomingParticipationRequests?: ParticipationRequestCreateManyWithoutRecipientInput | null;
    organizations?: OrganizationCreateManyWithoutMembersInput | null;
    Presentations?: PresentationCreateManyWithoutProfilesInput | null;
    settings?: SettingCreateOneWithoutOwnedByProfileInput | null;
    devices?: DeviceCreateManyWithoutOwnedByProfileInput | null;
    notifications?: NotificationCreateManyWithoutReceiverProfileInput | null;
    notification2?: NotificationCreateManyWithoutSenderProfileInput | null;
    porx?: ProfilesOrganizationsRolesxCreateManyWithoutProfileInput | null;
}

export interface ProfileCreateWithoutNotification2Input {
    uid: string;
    createdAt?: any | null;
    firstName: string;
    lastName: string;
    displayName?: string | null;
    email?: string | null;
    bio?: string | null;
    profilePicture?: string | null;
    stripeAccount?: string | null;
    stripeCustomerAccount?: string | null;
    parties?: partyCreateManyWithoutUserIdFkInput | null;
    location?: LocationCreateOneWithoutProfileInput | null;
    skills?: SkillCreateManyWithoutOwnedByPortfolioInput | null;
    reviews?: ReviewCreateManyWithoutAuthorInput | null;
    upcomingJobsServe?: JobCreateManyWithoutProviderInput | null;
    upcomingJobsReceive?: JobCreateManyWithoutRecipientInput | null;
    jobsPosted?: JobPostCreateManyWithoutRecipientInput | null;
    outgoingParticipationRequests?: ParticipationRequestCreateManyWithoutProviderInput | null;
    incomingParticipationRequests?: ParticipationRequestCreateManyWithoutRecipientInput | null;
    organizations?: OrganizationCreateManyWithoutMembersInput | null;
    Presentations?: PresentationCreateManyWithoutProfilesInput | null;
    settings?: SettingCreateOneWithoutOwnedByProfileInput | null;
    devices?: DeviceCreateManyWithoutOwnedByProfileInput | null;
    notifications?: NotificationCreateManyWithoutReceiverProfileInput | null;
    porx?: ProfilesOrganizationsRolesxCreateManyWithoutProfileInput | null;
}

export interface ProfileCreateWithoutNotificationsInput {
    uid: string;
    createdAt?: any | null;
    firstName: string;
    lastName: string;
    displayName?: string | null;
    email?: string | null;
    bio?: string | null;
    profilePicture?: string | null;
    stripeAccount?: string | null;
    stripeCustomerAccount?: string | null;
    parties?: partyCreateManyWithoutUserIdFkInput | null;
    location?: LocationCreateOneWithoutProfileInput | null;
    skills?: SkillCreateManyWithoutOwnedByPortfolioInput | null;
    reviews?: ReviewCreateManyWithoutAuthorInput | null;
    upcomingJobsServe?: JobCreateManyWithoutProviderInput | null;
    upcomingJobsReceive?: JobCreateManyWithoutRecipientInput | null;
    jobsPosted?: JobPostCreateManyWithoutRecipientInput | null;
    outgoingParticipationRequests?: ParticipationRequestCreateManyWithoutProviderInput | null;
    incomingParticipationRequests?: ParticipationRequestCreateManyWithoutRecipientInput | null;
    organizations?: OrganizationCreateManyWithoutMembersInput | null;
    Presentations?: PresentationCreateManyWithoutProfilesInput | null;
    settings?: SettingCreateOneWithoutOwnedByProfileInput | null;
    devices?: DeviceCreateManyWithoutOwnedByProfileInput | null;
    notification2?: NotificationCreateManyWithoutSenderProfileInput | null;
    porx?: ProfilesOrganizationsRolesxCreateManyWithoutProfileInput | null;
}

export interface ProfileCreateWithoutOrganizationsInput {
    uid: string;
    createdAt?: any | null;
    firstName: string;
    lastName: string;
    displayName?: string | null;
    email?: string | null;
    bio?: string | null;
    profilePicture?: string | null;
    stripeAccount?: string | null;
    stripeCustomerAccount?: string | null;
    parties?: partyCreateManyWithoutUserIdFkInput | null;
    location?: LocationCreateOneWithoutProfileInput | null;
    skills?: SkillCreateManyWithoutOwnedByPortfolioInput | null;
    reviews?: ReviewCreateManyWithoutAuthorInput | null;
    upcomingJobsServe?: JobCreateManyWithoutProviderInput | null;
    upcomingJobsReceive?: JobCreateManyWithoutRecipientInput | null;
    jobsPosted?: JobPostCreateManyWithoutRecipientInput | null;
    outgoingParticipationRequests?: ParticipationRequestCreateManyWithoutProviderInput | null;
    incomingParticipationRequests?: ParticipationRequestCreateManyWithoutRecipientInput | null;
    Presentations?: PresentationCreateManyWithoutProfilesInput | null;
    settings?: SettingCreateOneWithoutOwnedByProfileInput | null;
    devices?: DeviceCreateManyWithoutOwnedByProfileInput | null;
    notifications?: NotificationCreateManyWithoutReceiverProfileInput | null;
    notification2?: NotificationCreateManyWithoutSenderProfileInput | null;
    porx?: ProfilesOrganizationsRolesxCreateManyWithoutProfileInput | null;
}

export interface ProfileCreateWithoutOutgoingParticipationRequestsInput {
    uid: string;
    createdAt?: any | null;
    firstName: string;
    lastName: string;
    displayName?: string | null;
    email?: string | null;
    bio?: string | null;
    profilePicture?: string | null;
    stripeAccount?: string | null;
    stripeCustomerAccount?: string | null;
    parties?: partyCreateManyWithoutUserIdFkInput | null;
    location?: LocationCreateOneWithoutProfileInput | null;
    skills?: SkillCreateManyWithoutOwnedByPortfolioInput | null;
    reviews?: ReviewCreateManyWithoutAuthorInput | null;
    upcomingJobsServe?: JobCreateManyWithoutProviderInput | null;
    upcomingJobsReceive?: JobCreateManyWithoutRecipientInput | null;
    jobsPosted?: JobPostCreateManyWithoutRecipientInput | null;
    incomingParticipationRequests?: ParticipationRequestCreateManyWithoutRecipientInput | null;
    organizations?: OrganizationCreateManyWithoutMembersInput | null;
    Presentations?: PresentationCreateManyWithoutProfilesInput | null;
    settings?: SettingCreateOneWithoutOwnedByProfileInput | null;
    devices?: DeviceCreateManyWithoutOwnedByProfileInput | null;
    notifications?: NotificationCreateManyWithoutReceiverProfileInput | null;
    notification2?: NotificationCreateManyWithoutSenderProfileInput | null;
    porx?: ProfilesOrganizationsRolesxCreateManyWithoutProfileInput | null;
}

export interface ProfileCreateWithoutPartiesInput {
    uid: string;
    createdAt?: any | null;
    firstName: string;
    lastName: string;
    displayName?: string | null;
    email?: string | null;
    bio?: string | null;
    profilePicture?: string | null;
    stripeAccount?: string | null;
    stripeCustomerAccount?: string | null;
    location?: LocationCreateOneWithoutProfileInput | null;
    skills?: SkillCreateManyWithoutOwnedByPortfolioInput | null;
    reviews?: ReviewCreateManyWithoutAuthorInput | null;
    upcomingJobsServe?: JobCreateManyWithoutProviderInput | null;
    upcomingJobsReceive?: JobCreateManyWithoutRecipientInput | null;
    jobsPosted?: JobPostCreateManyWithoutRecipientInput | null;
    outgoingParticipationRequests?: ParticipationRequestCreateManyWithoutProviderInput | null;
    incomingParticipationRequests?: ParticipationRequestCreateManyWithoutRecipientInput | null;
    organizations?: OrganizationCreateManyWithoutMembersInput | null;
    Presentations?: PresentationCreateManyWithoutProfilesInput | null;
    settings?: SettingCreateOneWithoutOwnedByProfileInput | null;
    devices?: DeviceCreateManyWithoutOwnedByProfileInput | null;
    notifications?: NotificationCreateManyWithoutReceiverProfileInput | null;
    notification2?: NotificationCreateManyWithoutSenderProfileInput | null;
    porx?: ProfilesOrganizationsRolesxCreateManyWithoutProfileInput | null;
}

export interface ProfileCreateWithoutPorxInput {
    uid: string;
    createdAt?: any | null;
    firstName: string;
    lastName: string;
    displayName?: string | null;
    email?: string | null;
    bio?: string | null;
    profilePicture?: string | null;
    stripeAccount?: string | null;
    stripeCustomerAccount?: string | null;
    parties?: partyCreateManyWithoutUserIdFkInput | null;
    location?: LocationCreateOneWithoutProfileInput | null;
    skills?: SkillCreateManyWithoutOwnedByPortfolioInput | null;
    reviews?: ReviewCreateManyWithoutAuthorInput | null;
    upcomingJobsServe?: JobCreateManyWithoutProviderInput | null;
    upcomingJobsReceive?: JobCreateManyWithoutRecipientInput | null;
    jobsPosted?: JobPostCreateManyWithoutRecipientInput | null;
    outgoingParticipationRequests?: ParticipationRequestCreateManyWithoutProviderInput | null;
    incomingParticipationRequests?: ParticipationRequestCreateManyWithoutRecipientInput | null;
    organizations?: OrganizationCreateManyWithoutMembersInput | null;
    Presentations?: PresentationCreateManyWithoutProfilesInput | null;
    settings?: SettingCreateOneWithoutOwnedByProfileInput | null;
    devices?: DeviceCreateManyWithoutOwnedByProfileInput | null;
    notifications?: NotificationCreateManyWithoutReceiverProfileInput | null;
    notification2?: NotificationCreateManyWithoutSenderProfileInput | null;
}

export interface ProfileCreateWithoutPresentationsInput {
    uid: string;
    createdAt?: any | null;
    firstName: string;
    lastName: string;
    displayName?: string | null;
    email?: string | null;
    bio?: string | null;
    profilePicture?: string | null;
    stripeAccount?: string | null;
    stripeCustomerAccount?: string | null;
    parties?: partyCreateManyWithoutUserIdFkInput | null;
    location?: LocationCreateOneWithoutProfileInput | null;
    skills?: SkillCreateManyWithoutOwnedByPortfolioInput | null;
    reviews?: ReviewCreateManyWithoutAuthorInput | null;
    upcomingJobsServe?: JobCreateManyWithoutProviderInput | null;
    upcomingJobsReceive?: JobCreateManyWithoutRecipientInput | null;
    jobsPosted?: JobPostCreateManyWithoutRecipientInput | null;
    outgoingParticipationRequests?: ParticipationRequestCreateManyWithoutProviderInput | null;
    incomingParticipationRequests?: ParticipationRequestCreateManyWithoutRecipientInput | null;
    organizations?: OrganizationCreateManyWithoutMembersInput | null;
    settings?: SettingCreateOneWithoutOwnedByProfileInput | null;
    devices?: DeviceCreateManyWithoutOwnedByProfileInput | null;
    notifications?: NotificationCreateManyWithoutReceiverProfileInput | null;
    notification2?: NotificationCreateManyWithoutSenderProfileInput | null;
    porx?: ProfilesOrganizationsRolesxCreateManyWithoutProfileInput | null;
}

export interface ProfileCreateWithoutReviewsInput {
    uid: string;
    createdAt?: any | null;
    firstName: string;
    lastName: string;
    displayName?: string | null;
    email?: string | null;
    bio?: string | null;
    profilePicture?: string | null;
    stripeAccount?: string | null;
    stripeCustomerAccount?: string | null;
    parties?: partyCreateManyWithoutUserIdFkInput | null;
    location?: LocationCreateOneWithoutProfileInput | null;
    skills?: SkillCreateManyWithoutOwnedByPortfolioInput | null;
    upcomingJobsServe?: JobCreateManyWithoutProviderInput | null;
    upcomingJobsReceive?: JobCreateManyWithoutRecipientInput | null;
    jobsPosted?: JobPostCreateManyWithoutRecipientInput | null;
    outgoingParticipationRequests?: ParticipationRequestCreateManyWithoutProviderInput | null;
    incomingParticipationRequests?: ParticipationRequestCreateManyWithoutRecipientInput | null;
    organizations?: OrganizationCreateManyWithoutMembersInput | null;
    Presentations?: PresentationCreateManyWithoutProfilesInput | null;
    settings?: SettingCreateOneWithoutOwnedByProfileInput | null;
    devices?: DeviceCreateManyWithoutOwnedByProfileInput | null;
    notifications?: NotificationCreateManyWithoutReceiverProfileInput | null;
    notification2?: NotificationCreateManyWithoutSenderProfileInput | null;
    porx?: ProfilesOrganizationsRolesxCreateManyWithoutProfileInput | null;
}

export interface ProfileCreateWithoutSkillsInput {
    uid: string;
    createdAt?: any | null;
    firstName: string;
    lastName: string;
    displayName?: string | null;
    email?: string | null;
    bio?: string | null;
    profilePicture?: string | null;
    stripeAccount?: string | null;
    stripeCustomerAccount?: string | null;
    parties?: partyCreateManyWithoutUserIdFkInput | null;
    location?: LocationCreateOneWithoutProfileInput | null;
    reviews?: ReviewCreateManyWithoutAuthorInput | null;
    upcomingJobsServe?: JobCreateManyWithoutProviderInput | null;
    upcomingJobsReceive?: JobCreateManyWithoutRecipientInput | null;
    jobsPosted?: JobPostCreateManyWithoutRecipientInput | null;
    outgoingParticipationRequests?: ParticipationRequestCreateManyWithoutProviderInput | null;
    incomingParticipationRequests?: ParticipationRequestCreateManyWithoutRecipientInput | null;
    organizations?: OrganizationCreateManyWithoutMembersInput | null;
    Presentations?: PresentationCreateManyWithoutProfilesInput | null;
    settings?: SettingCreateOneWithoutOwnedByProfileInput | null;
    devices?: DeviceCreateManyWithoutOwnedByProfileInput | null;
    notifications?: NotificationCreateManyWithoutReceiverProfileInput | null;
    notification2?: NotificationCreateManyWithoutSenderProfileInput | null;
    porx?: ProfilesOrganizationsRolesxCreateManyWithoutProfileInput | null;
}

export interface ProfileCreateWithoutUpcomingJobsReceiveInput {
    uid: string;
    createdAt?: any | null;
    firstName: string;
    lastName: string;
    displayName?: string | null;
    email?: string | null;
    bio?: string | null;
    profilePicture?: string | null;
    stripeAccount?: string | null;
    stripeCustomerAccount?: string | null;
    parties?: partyCreateManyWithoutUserIdFkInput | null;
    location?: LocationCreateOneWithoutProfileInput | null;
    skills?: SkillCreateManyWithoutOwnedByPortfolioInput | null;
    reviews?: ReviewCreateManyWithoutAuthorInput | null;
    upcomingJobsServe?: JobCreateManyWithoutProviderInput | null;
    jobsPosted?: JobPostCreateManyWithoutRecipientInput | null;
    outgoingParticipationRequests?: ParticipationRequestCreateManyWithoutProviderInput | null;
    incomingParticipationRequests?: ParticipationRequestCreateManyWithoutRecipientInput | null;
    organizations?: OrganizationCreateManyWithoutMembersInput | null;
    Presentations?: PresentationCreateManyWithoutProfilesInput | null;
    settings?: SettingCreateOneWithoutOwnedByProfileInput | null;
    devices?: DeviceCreateManyWithoutOwnedByProfileInput | null;
    notifications?: NotificationCreateManyWithoutReceiverProfileInput | null;
    notification2?: NotificationCreateManyWithoutSenderProfileInput | null;
    porx?: ProfilesOrganizationsRolesxCreateManyWithoutProfileInput | null;
}

export interface ProfileCreateWithoutUpcomingJobsServeInput {
    uid: string;
    createdAt?: any | null;
    firstName: string;
    lastName: string;
    displayName?: string | null;
    email?: string | null;
    bio?: string | null;
    profilePicture?: string | null;
    stripeAccount?: string | null;
    stripeCustomerAccount?: string | null;
    parties?: partyCreateManyWithoutUserIdFkInput | null;
    location?: LocationCreateOneWithoutProfileInput | null;
    skills?: SkillCreateManyWithoutOwnedByPortfolioInput | null;
    reviews?: ReviewCreateManyWithoutAuthorInput | null;
    upcomingJobsReceive?: JobCreateManyWithoutRecipientInput | null;
    jobsPosted?: JobPostCreateManyWithoutRecipientInput | null;
    outgoingParticipationRequests?: ParticipationRequestCreateManyWithoutProviderInput | null;
    incomingParticipationRequests?: ParticipationRequestCreateManyWithoutRecipientInput | null;
    organizations?: OrganizationCreateManyWithoutMembersInput | null;
    Presentations?: PresentationCreateManyWithoutProfilesInput | null;
    settings?: SettingCreateOneWithoutOwnedByProfileInput | null;
    devices?: DeviceCreateManyWithoutOwnedByProfileInput | null;
    notifications?: NotificationCreateManyWithoutReceiverProfileInput | null;
    notification2?: NotificationCreateManyWithoutSenderProfileInput | null;
    porx?: ProfilesOrganizationsRolesxCreateManyWithoutProfileInput | null;
}

export interface ProfileWhereUniqueInput {
    id?: number | null;
    uid?: string | null;
}

export interface ProfilesOrganizationsRolesxCreateManyWithoutOrganizationInput {
    create?: ProfilesOrganizationsRolesxCreateWithoutOrganizationInput[] | null;
    connect?: ProfilesOrganizationsRolesxWhereUniqueInput[] | null;
}

export interface ProfilesOrganizationsRolesxCreateManyWithoutProfileInput {
    create?: ProfilesOrganizationsRolesxCreateWithoutProfileInput[] | null;
    connect?: ProfilesOrganizationsRolesxWhereUniqueInput[] | null;
}

export interface ProfilesOrganizationsRolesxCreateOneWithoutJobpostyInput {
    create?: ProfilesOrganizationsRolesxCreateWithoutJobpostyInput | null;
    connect?: ProfilesOrganizationsRolesxWhereUniqueInput | null;
}

export interface ProfilesOrganizationsRolesxCreateOneWithoutJobyInput {
    create?: ProfilesOrganizationsRolesxCreateWithoutJobyInput | null;
    connect?: ProfilesOrganizationsRolesxWhereUniqueInput | null;
}

export interface ProfilesOrganizationsRolesxCreateOneWithoutNotificationsInput {
    create?: ProfilesOrganizationsRolesxCreateWithoutNotificationsInput | null;
    connect?: ProfilesOrganizationsRolesxWhereUniqueInput | null;
}

export interface ProfilesOrganizationsRolesxCreateOneWithoutPrequestInput {
    create?: ProfilesOrganizationsRolesxCreateWithoutPrequestInput | null;
    connect?: ProfilesOrganizationsRolesxWhereUniqueInput | null;
}

export interface ProfilesOrganizationsRolesxCreateWithoutJobpostyInput {
    profile: ProfileCreateOneWithoutPorxInput;
    organization: OrganizationCreateOneWithoutProxInput;
    bowel: BowelCreateOneWithoutProfilesInput;
    notifications?: NotificationCreateManyWithoutAdminInput | null;
    joby?: JobCreateManyWithoutAdminInput | null;
    prequest?: ParticipationRequestCreateManyWithoutAdminInput | null;
}

export interface ProfilesOrganizationsRolesxCreateWithoutJobyInput {
    profile: ProfileCreateOneWithoutPorxInput;
    organization: OrganizationCreateOneWithoutProxInput;
    bowel: BowelCreateOneWithoutProfilesInput;
    notifications?: NotificationCreateManyWithoutAdminInput | null;
    jobposty?: JobPostCreateManyWithoutAdminInput | null;
    prequest?: ParticipationRequestCreateManyWithoutAdminInput | null;
}

export interface ProfilesOrganizationsRolesxCreateWithoutNotificationsInput {
    profile: ProfileCreateOneWithoutPorxInput;
    organization: OrganizationCreateOneWithoutProxInput;
    bowel: BowelCreateOneWithoutProfilesInput;
    jobposty?: JobPostCreateManyWithoutAdminInput | null;
    joby?: JobCreateManyWithoutAdminInput | null;
    prequest?: ParticipationRequestCreateManyWithoutAdminInput | null;
}

export interface ProfilesOrganizationsRolesxCreateWithoutOrganizationInput {
    profile: ProfileCreateOneWithoutPorxInput;
    bowel: BowelCreateOneWithoutProfilesInput;
    notifications?: NotificationCreateManyWithoutAdminInput | null;
    jobposty?: JobPostCreateManyWithoutAdminInput | null;
    joby?: JobCreateManyWithoutAdminInput | null;
    prequest?: ParticipationRequestCreateManyWithoutAdminInput | null;
}

export interface ProfilesOrganizationsRolesxCreateWithoutPrequestInput {
    profile: ProfileCreateOneWithoutPorxInput;
    organization: OrganizationCreateOneWithoutProxInput;
    bowel: BowelCreateOneWithoutProfilesInput;
    notifications?: NotificationCreateManyWithoutAdminInput | null;
    jobposty?: JobPostCreateManyWithoutAdminInput | null;
    joby?: JobCreateManyWithoutAdminInput | null;
}

export interface ProfilesOrganizationsRolesxCreateWithoutProfileInput {
    organization: OrganizationCreateOneWithoutProxInput;
    bowel: BowelCreateOneWithoutProfilesInput;
    notifications?: NotificationCreateManyWithoutAdminInput | null;
    jobposty?: JobPostCreateManyWithoutAdminInput | null;
    joby?: JobCreateManyWithoutAdminInput | null;
    prequest?: ParticipationRequestCreateManyWithoutAdminInput | null;
}

export interface ProfilesOrganizationsRolesxWhereUniqueInput {
    id?: number | null;
}

export interface ReviewCreateManyWithoutAuthorInput {
    create?: ReviewCreateWithoutAuthorInput[] | null;
    connect?: ReviewWhereUniqueInput[] | null;
}

export interface ReviewCreateManyWithoutSkillInput {
    create?: ReviewCreateWithoutSkillInput[] | null;
    connect?: ReviewWhereUniqueInput[] | null;
}

export interface ReviewCreateWithoutAuthorInput {
    createdAt?: any | null;
    rating?: number | null;
    review: string;
    skill?: SkillCreateOneWithoutReviewsInput | null;
}

export interface ReviewCreateWithoutSkillInput {
    createdAt?: any | null;
    rating?: number | null;
    review: string;
    author?: ProfileCreateOneWithoutReviewsInput | null;
}

export interface ReviewWhereUniqueInput {
    id?: number | null;
}

export interface SettingCreateOneWithoutOwnedByProfileInput {
    create?: SettingCreateWithoutOwnedByProfileInput | null;
    connect?: SettingWhereUniqueInput | null;
}

export interface SettingCreateWithoutOwnedByProfileInput {
    timezone?: string | null;
}

export interface SettingWhereUniqueInput {
    id?: number | null;
}

export interface SkillCreateManyWithoutOwnedByPortfolioInput {
    create?: SkillCreateWithoutOwnedByPortfolioInput[] | null;
    connect?: SkillWhereUniqueInput[] | null;
}

export interface SkillCreateManyWithoutPresentationInput {
    create?: SkillCreateWithoutPresentationInput[] | null;
    connect?: SkillWhereUniqueInput[] | null;
}

export interface SkillCreateOneWithoutJobInput {
    create?: SkillCreateWithoutJobInput | null;
    connect?: SkillWhereUniqueInput | null;
}

export interface SkillCreateOneWithoutReviewsInput {
    create?: SkillCreateWithoutReviewsInput | null;
    connect?: SkillWhereUniqueInput | null;
}

export interface SkillCreateWithoutJobInput {
    createdAt?: any | null;
    name: string;
    description?: string | null;
    category: string;
    status?: Status | null;
    availability?: AvailabilityCreateOneWithoutSkillInput | null;
    ownedByPortfolio: ProfileCreateOneWithoutSkillsInput;
    reviews?: ReviewCreateManyWithoutSkillInput | null;
    location?: SkillLocationCreateOneWithoutSkillInput | null;
    presentation?: PresentationCreateManyWithoutSkillsInput | null;
}

export interface SkillCreateWithoutOwnedByPortfolioInput {
    createdAt?: any | null;
    name: string;
    description?: string | null;
    category: string;
    status?: Status | null;
    availability?: AvailabilityCreateOneWithoutSkillInput | null;
    Job?: JobCreateManyWithoutSkillInput | null;
    reviews?: ReviewCreateManyWithoutSkillInput | null;
    location?: SkillLocationCreateOneWithoutSkillInput | null;
    presentation?: PresentationCreateManyWithoutSkillsInput | null;
}

export interface SkillCreateWithoutPresentationInput {
    createdAt?: any | null;
    name: string;
    description?: string | null;
    category: string;
    status?: Status | null;
    availability?: AvailabilityCreateOneWithoutSkillInput | null;
    ownedByPortfolio: ProfileCreateOneWithoutSkillsInput;
    Job?: JobCreateManyWithoutSkillInput | null;
    reviews?: ReviewCreateManyWithoutSkillInput | null;
    location?: SkillLocationCreateOneWithoutSkillInput | null;
}

export interface SkillCreateWithoutReviewsInput {
    createdAt?: any | null;
    name: string;
    description?: string | null;
    category: string;
    status?: Status | null;
    availability?: AvailabilityCreateOneWithoutSkillInput | null;
    ownedByPortfolio: ProfileCreateOneWithoutSkillsInput;
    Job?: JobCreateManyWithoutSkillInput | null;
    location?: SkillLocationCreateOneWithoutSkillInput | null;
    presentation?: PresentationCreateManyWithoutSkillsInput | null;
}

export interface SkillLocationCreateOneWithoutSkillInput {
    create?: SkillLocationCreateWithoutSkillInput | null;
    connect?: SkillLocationWhereUniqueInput | null;
}

export interface SkillLocationCreateWithoutSkillInput {
    createdAt?: any | null;
    lastUpdatedAt?: any | null;
    locationString?: string | null;
    locationPreference?: LocationPreference | null;
}

export interface SkillLocationWhereUniqueInput {
    id?: number | null;
}

export interface SkillNeededCreateManyWithoutJobPostInput {
    create?: SkillNeededCreateWithoutJobPostInput[] | null;
    connect?: SkillNeededWhereUniqueInput[] | null;
}

export interface SkillNeededCreateOneWithoutParticipationRequestsInput {
    create?: SkillNeededCreateWithoutParticipationRequestsInput | null;
    connect?: SkillNeededWhereUniqueInput | null;
}

export interface SkillNeededCreateWithoutJobPostInput {
    createdAt?: any | null;
    name: string;
    description: string;
    additionalDetails: string;
    peopleNeededCount: number;
    status?: Status | null;
    participationRequests?: ParticipationRequestCreateManyWithoutSkillInput | null;
}

export interface SkillNeededCreateWithoutParticipationRequestsInput {
    createdAt?: any | null;
    name: string;
    description: string;
    additionalDetails: string;
    peopleNeededCount: number;
    status?: Status | null;
    jobPost: JobPostCreateOneWithoutSkillsNeededInput;
}

export interface SkillNeededWhereUniqueInput {
    id?: number | null;
}

export interface SkillWhereUniqueInput {
    id?: number | null;
}

export interface UserUidsInput {
    uid: string;
}

export interface bankAccountCreateManyWithoutIdFkInput {
    create?: bankAccountCreateWithoutIdFkInput[] | null;
    connect?: bankAccountWhereUniqueInput[] | null;
}

export interface bankAccountCreateWithoutIdFkInput {
    accountType?: accountType | null;
    balance?: number | null;
}

export interface bankAccountWhereUniqueInput {
    id?: number | null;
    accountNumber?: number | null;
}

export interface partyCreateManyWithoutGroupIdFkInput {
    create?: partyCreateWithoutGroupIdFkInput[] | null;
    connect?: partyWhereUniqueInput[] | null;
}

export interface partyCreateManyWithoutUserIdFkInput {
    create?: partyCreateWithoutUserIdFkInput[] | null;
    connect?: partyWhereUniqueInput[] | null;
}

export interface partyCreateOneWithoutReceiverInput {
    create?: partyCreateWithoutReceiverInput | null;
    connect?: partyWhereUniqueInput | null;
}

export interface partyCreateOneWithoutReceiverTwoInput {
    create?: partyCreateWithoutReceiverTwoInput | null;
    connect?: partyWhereUniqueInput | null;
}

export interface partyCreateOneWithoutSenderInput {
    create?: partyCreateWithoutSenderInput | null;
    connect?: partyWhereUniqueInput | null;
}

export interface partyCreateOneWithoutSenderTwoInput {
    create?: partyCreateWithoutSenderTwoInput | null;
    connect?: partyWhereUniqueInput | null;
}

export interface partyCreateWithoutGroupIdFkInput {
    CreatedDateTime?: any | null;
    bankAccountId?: number | null;
    userIdFk?: ProfileCreateOneWithoutPartiesInput | null;
    partyTypeCodeChar: partyTypeCreateOneWithoutPartyRelationInput;
    bankAccountrelation?: bankAccountCreateManyWithoutIdFkInput | null;
    sender?: JobCreateManyWithoutSenderInput | null;
    receiver?: JobCreateManyWithoutReceiverInput | null;
    senderTwo?: ParticipationRequestCreateManyWithoutSenderInput | null;
    receiverTwo?: ParticipationRequestCreateManyWithoutReceiverInput | null;
}

export interface partyCreateWithoutReceiverInput {
    CreatedDateTime?: any | null;
    bankAccountId?: number | null;
    userIdFk?: ProfileCreateOneWithoutPartiesInput | null;
    groupIdFk?: OrganizationCreateOneWithoutPartiesInput | null;
    partyTypeCodeChar: partyTypeCreateOneWithoutPartyRelationInput;
    bankAccountrelation?: bankAccountCreateManyWithoutIdFkInput | null;
    sender?: JobCreateManyWithoutSenderInput | null;
    senderTwo?: ParticipationRequestCreateManyWithoutSenderInput | null;
    receiverTwo?: ParticipationRequestCreateManyWithoutReceiverInput | null;
}

export interface partyCreateWithoutReceiverTwoInput {
    CreatedDateTime?: any | null;
    bankAccountId?: number | null;
    userIdFk?: ProfileCreateOneWithoutPartiesInput | null;
    groupIdFk?: OrganizationCreateOneWithoutPartiesInput | null;
    partyTypeCodeChar: partyTypeCreateOneWithoutPartyRelationInput;
    bankAccountrelation?: bankAccountCreateManyWithoutIdFkInput | null;
    sender?: JobCreateManyWithoutSenderInput | null;
    receiver?: JobCreateManyWithoutReceiverInput | null;
    senderTwo?: ParticipationRequestCreateManyWithoutSenderInput | null;
}

export interface partyCreateWithoutSenderInput {
    CreatedDateTime?: any | null;
    bankAccountId?: number | null;
    userIdFk?: ProfileCreateOneWithoutPartiesInput | null;
    groupIdFk?: OrganizationCreateOneWithoutPartiesInput | null;
    partyTypeCodeChar: partyTypeCreateOneWithoutPartyRelationInput;
    bankAccountrelation?: bankAccountCreateManyWithoutIdFkInput | null;
    receiver?: JobCreateManyWithoutReceiverInput | null;
    senderTwo?: ParticipationRequestCreateManyWithoutSenderInput | null;
    receiverTwo?: ParticipationRequestCreateManyWithoutReceiverInput | null;
}

export interface partyCreateWithoutSenderTwoInput {
    CreatedDateTime?: any | null;
    bankAccountId?: number | null;
    userIdFk?: ProfileCreateOneWithoutPartiesInput | null;
    groupIdFk?: OrganizationCreateOneWithoutPartiesInput | null;
    partyTypeCodeChar: partyTypeCreateOneWithoutPartyRelationInput;
    bankAccountrelation?: bankAccountCreateManyWithoutIdFkInput | null;
    sender?: JobCreateManyWithoutSenderInput | null;
    receiver?: JobCreateManyWithoutReceiverInput | null;
    receiverTwo?: ParticipationRequestCreateManyWithoutReceiverInput | null;
}

export interface partyCreateWithoutUserIdFkInput {
    CreatedDateTime?: any | null;
    bankAccountId?: number | null;
    groupIdFk?: OrganizationCreateOneWithoutPartiesInput | null;
    partyTypeCodeChar: partyTypeCreateOneWithoutPartyRelationInput;
    bankAccountrelation?: bankAccountCreateManyWithoutIdFkInput | null;
    sender?: JobCreateManyWithoutSenderInput | null;
    receiver?: JobCreateManyWithoutReceiverInput | null;
    senderTwo?: ParticipationRequestCreateManyWithoutSenderInput | null;
    receiverTwo?: ParticipationRequestCreateManyWithoutReceiverInput | null;
}

export interface partyTypeCreateOneWithoutPartyRelationInput {
    create?: partyTypeCreateWithoutPartyRelationInput | null;
    connect?: partyTypeWhereUniqueInput | null;
}

export interface partyTypeCreateWithoutPartyRelationInput {
    partyTypeCode: string;
    name: string;
}

export interface partyTypeWhereUniqueInput {
    partyTypeCode?: string | null;
}

export interface partyWhereUniqueInput {
    partyId?: number | null;
    bankAccountId?: number | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
