// importing modules
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PlacesAutocomplete from 'react-places-autocomplete';
// importing components
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonLabel,
    IonRow,
    IonPage,
} from '@ionic/react';
import CreateTaskTabs from '../UI/CreateTaskTabs/CreateTaskTabs';
import UserImg from '../UI/UserImg/UserImg';
import GroupImg from '../UI/GroupImg/GroupImg';
import TaskLocationItem from './TaskLocationItem/TaskLocationItem';
import CreateTaskFooter from '../../../components/FooterWithChildren/FooterWithChildren';
import Strokes from 'components/Strokes';
// importing assets
// importing helpers
import {
    getLocalStorage,
    setLocalStorage,
} from '../../../helpers/localStorage/localStorageHelper';
import { LocationPreference } from '../../../GraphQL/__generated__/globalTypes';
// importing styles
import './TaskLocation.css';
import '../../../components/GooglePlacesDropDown/GooglePlacesDropDown.css';

const DEFAULT_LOCATIONS = [
    {
        id: 1,
        name: LocationPreference.VIRTUAL,
        address:
            'Send the skill provider a video conference link in the chat once you complete this request',
    },
];

const TaskLocation: React.FC<{ taskType: string }> = ({ taskType }) => {
    const history = useHistory();
    const { groupId, groupCode } = useParams<{
        groupId: string;
        groupCode: string;
    }>();
    const [taskLocation, setTaskLocation] = useState<string>('');
    const [locationType, setLocationType] = useState<string>('');
    const [address, setAddress] = useState<string>('');

    const handleAddress = (address) => {
        setAddress(address);
        setTaskLocation(address);
    };

    useEffect(() => {
        if (getLocalStorage('createTask')) {
            const createTask = getLocalStorage('createTask');
            setLocationType(createTask.locationPreference);
            setTaskLocation(createTask.location);
            setAddress(
                createTask.location === 'VIRTUAL' ? '' : createTask.location
            );
        } else {
            history.push('/home');
        }
    }, [history]);

    const handleLocation = (loc: string) => {
        const createTask = getLocalStorage('createTask');
        createTask.location = loc !== 'VIRTUAL' ? loc : 'VIRTUAL';
        createTask.locationPreference = locationType;
        setLocalStorage('createTask', createTask);
        if (taskType === 'HOME') {
            history.push('/create-task/skills');
        } else if (taskType === 'GROUP') {
            history.push(`/create-task/${groupId}/${groupCode}/skills`);
        }
    };

    let img;
    if (taskType === 'HOME' && getLocalStorage('currentAdminGroupId') == 0) {
        img = <UserImg />;
    } else if (
        taskType === 'GROUP' &&
        getLocalStorage('currentAdminGroupId') == 0
    ) {
        img = <UserImg />;
    } else {
        img = <GroupImg />;
    }

    return (
        <IonPage>
            <IonContent>
                <IonGrid>
                    <IonRow className='taskLocation__header'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='taskLocation__header--container'
                        >
                            <Strokes top={true} />
                            <CreateTaskTabs disablePrevStep={false} />
                            {img}
                        </IonCol>
                    </IonRow>
                    {/* text content */}
                    <IonRow className='taskLocation__textContent'>
                        <IonCol
                            className='taskLocation__textContent--container'
                            sizeSm='12'
                            sizeXs='12'
                        >
                            <h1 className='taskLocation__textContent--title'>
                                Choose a <span>location</span>
                            </h1>
                        </IonCol>
                    </IonRow>
                    {/* task location form */}
                    <IonGrid className='taskLocation__form--container'>
                        <IonRow className='taskLocation__form--row'>
                            <p className='taskLocation__form--row--title'>
                                Your Saved Locations
                            </p>
                            <IonRow className='taskLocation__col--row'>
                                {DEFAULT_LOCATIONS.map((location) => (
                                    <TaskLocationItem
                                        locationType={locationType}
                                        setLocationType={setLocationType}
                                        taskLocation={taskLocation}
                                        setTaskLocation={setTaskLocation}
                                        key={location.id}
                                        {...location}
                                    />
                                ))}
                            </IonRow>
                            {/* section divider */}
                            <IonRow className='taskLocation__typeDivider'>
                                <IonCol
                                    sizeXs='12'
                                    sizeSm='12'
                                    className='taskLocation__typeDivider--container'
                                >
                                    <p className='taskLocation__typeDivider__text'>
                                        <span className='taskLocation__typeDivider__text--span'>
                                            or
                                        </span>
                                    </p>
                                </IonCol>
                            </IonRow>
                            {/* section divider */}
                            <IonCol
                                className='taskLocation__form--input--wrap'
                                sizeXs='12'
                                sizeSm='12'
                            >
                                <IonLabel className='taskLocation__form--label'>
                                    New Location
                                </IonLabel>
                            </IonCol>
                            <PlacesAutocomplete
                                value={address}
                                onChange={handleAddress}
                                shouldFetchSuggestions={address.length > 3}
                                // onSelect={handleSelect}
                            >
                                {({
                                    getInputProps,
                                    suggestions,
                                    getSuggestionItemProps,
                                    loading,
                                }) => (
                                    <IonCol
                                        className='taskLocation__form--input--wrap'
                                        sizeXs='12'
                                        sizeSm='12'
                                        onChange={() => {
                                            setLocationType(
                                                LocationPreference.INPERSON
                                            );
                                            if (address.length === 0) {
                                                setTaskLocation('');
                                            } else {
                                                setTaskLocation(address);
                                            }
                                        }}
                                    >
                                        <input
                                            id='task_location_input_form'
                                            className={
                                                suggestions.length > 0
                                                    ? 'taskLocation__form--input taskLocation__form--input--focus'
                                                    : 'taskLocation__form--input'
                                            }
                                            placeholder='Enter address here...'
                                            value={address}
                                            type='text'
                                            {...getInputProps({
                                                placeholder:
                                                    'Enter address here...',
                                                className:
                                                    'location-search-input taskLocation__form--input',
                                            })}
                                        />
                                        {suggestions.length > 0 && (
                                            <div className='autocomplete__dropdown--container'>
                                                {loading && (
                                                    <div>Loading...</div>
                                                )}
                                                {suggestions.map(
                                                    (suggestion, index) => {
                                                        const className =
                                                            'autocomplete__dropdown--item';
                                                        return (
                                                            <div
                                                                {...getSuggestionItemProps(
                                                                    suggestion,
                                                                    {
                                                                        className,
                                                                        key: index,
                                                                    }
                                                                )}
                                                            >
                                                                <p className='autocomplete__dropdown--item--text'>
                                                                    {
                                                                        suggestion.description
                                                                    }
                                                                </p>
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </div>
                                        )}
                                    </IonCol>
                                )}
                            </PlacesAutocomplete>
                        </IonRow>
                    </IonGrid>
                </IonGrid>
            </IonContent>
            <CreateTaskFooter>
                <IonButton
                    fill='clear'
                    disabled={taskLocation.length === 0}
                    color='ion-primary'
                    onClick={() => handleLocation(taskLocation)}
                    className='footerWithChild__btn'
                    id='task_creation_location_btn'
                >
                    Continue
                </IonButton>
            </CreateTaskFooter>
        </IonPage>
    );
};

export default TaskLocation;
