const filterKWs = (arr) => {
    let keywordsArr = []; // initial array
    arr.forEach((kw) => {
        // check for duplicates
        if (!keywordsArr.includes(kw.keyword)) keywordsArr.push(kw.keyword); // insert into array if no duplicates
    });
    keywordsArr = keywordsArr.slice(0, 5); // return first 5 from filtered
    return keywordsArr; // return array
};

export { filterKWs as default };
