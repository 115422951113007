// about us manifesto & core values
export const MANIFESTO_TEXT_VALUES = [
    {
        id: 1,
        text: 'With time moving at the speed of life and death, we seek joy in passing seconds.',
    },
    {
        id: 2,
        text: 'We believe in the rising stock of human equity. We employ the laws of abundance and stoke the fire of continuous reciprocity.',
    },
    {
        id: 3,
        text: 'We believe in the art of creating our stories and our relationships. We believe in the richness of reliability and trust; in the elegance of the helping hand.',
    },
    {
        id: 4,
        text: 'We believe in the banking of our time. We believe in rising together, in upholding the traditions of the village; in the innate dignity of communal success, where neighborhoods leave no neighbor without.',
    },
    {
        id: 5,
        text: 'We believe in celebration of harmony, understanding there are no throwaway people: that each of us is endowed with gifts and needs for services to exchange with purpose and kindness.',
    },
    {
        id: 6,
        text: 'At YING, your self worth will always come before your net worth. YING: the currency for humankind. Cid williams & azure antoinette',
    },
];

export const CORE_VALUES = [
    {
        id: 1,
        title: 'Everyone is an asset',
        text: 'We believe that humans are innately valuable. Everyone single person has something of value to offer and contribute to the world. Everyone can make a difference.',
    },
    {
        id: 2,
        title: 'Redefining Work',
        text: 'We believe that all work has value and should be respected, recorded and rewarded. All of the work we do, all of the giving we do, is valuable and a form of currency that keeps the world moving in a beautiful way. And it’s inclusive of all the things we do in society to create and sustain whole and functioning communities.',
    },
    {
        id: 3,
        title: 'Social Networks Work',
        text: 'We need each other. We live in a society that is deeply connected and interwoven. YING is place for people to give help to their community or neighborhood when there is a need and receive help when it is needed. This interpersonal dynamic is an integral part of the social fabric of communities all over the world and it will be defined and made easily accessible on our platform.',
    },
    {
        id: 4,
        title: 'Respect',
        text: 'Every Human Being Matters. And every human being is should be valued and respected. YING is grounded in the idea of co-production, i.e. the belief that where I am helping someone, they can also be a part of the solution. It recognizes the value, skills and capacity inherent in vulnerable communities. Ying is community driven, rather than charity driven. Our community is stronger when we all participate and feel seen and valued. WE make change together',
    },
];
