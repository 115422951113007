// importing modules
import React, { useRef, useState } from 'react';
import moment from 'moment-timezone';
import { NavLink, useHistory } from 'react-router-dom';
import { useAuth } from 'context/firebase/authContext';
// importing components
import {
    IonButton,
    IonCol,
    IonImg,
    IonSpinner,
    IonIcon,
    IonToast,
} from '@ionic/react';
// importing assets
import { informationCircleOutline } from 'ionicons/icons';
// importing utilities
import { time_credit_conversion } from '../../utils/timeConversion';
import { getLocalStorage } from '../../helpers/localStorage/localStorageHelper';
// importing graphql
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
    GetProfileJobs_profile_outgoingParticipationRequests_recipient,
    GetProfileJobs_profile_incomingParticipationRequests_provider,
    GetProfileJobs_profile_outgoingParticipationRequests,
} from '../../GraphQL/__generated__/GetProfileJobs';
import { PARTICIPATION_REQUEST_NUDGE } from '../../GraphQL/ParticipationRequest/PARTICIPATION_REQUEST_NUDGE/PARTICIPATION_REQUEST_NUDGE';
import {
    ParticipationRequestNudge,
    ParticipationRequestNudgeVariables,
} from '../../GraphQL/__generated__/ParticipationRequestNudge';
// importing styles
import './ProviderRequestItem.css';
import { ADMIN_GROUP_PROFILE_PICTURE } from 'GraphQL/Admin/adminGroupProfilePicture/adminGroupProfilePicture';

const ProviderRequestItem: React.FC<{
    task: GetProfileJobs_profile_outgoingParticipationRequests;
    otherProfile:
        | GetProfileJobs_profile_outgoingParticipationRequests_recipient
        | GetProfileJobs_profile_incomingParticipationRequests_provider;
}> = ({ task, otherProfile }) => {
    const { currentUser } = useAuth();
    const history = useHistory();
    const chatLink = useRef(null);
    const [disableClick, setDisableClick] = useState<boolean>(false);
    const [showToast, setShowToast] = useState<boolean>(false);
    const [participationRequestNudge, participationRequestNudgeDatas] =
        useMutation<
            ParticipationRequestNudge,
            ParticipationRequestNudgeVariables
        >(PARTICIPATION_REQUEST_NUDGE);

    const { data, loading, error } = useQuery(ADMIN_GROUP_PROFILE_PICTURE, {
        variables: { adminId: task.skill.jobPost.adminId },
    });

    const hasAdmin = task.skill.jobPost.adminId;

    const handleNudge = () => {
        setDisableClick(true);
        setShowToast(true);

        participationRequestNudge({
            variables: { id: task.id, nudgeWho: 'recipient' },
        })
            .then((r) => setShowToast(true))
            .catch((err) => console.error(err));
        // disable nudge button for 30secs after click
        setTimeout(() => {
            setDisableClick(false);
        }, 30000);
    };

    const handleChatroom = () => chatLink.current?.click();
    const taskStartTime = moment(task.skill.jobPost.startTime).tz(
        getLocalStorage('tz'),
        false
    );
    const taskEndTime = moment(task.skill.jobPost.endTime).tz(
        getLocalStorage('tz'),
        false
    );
    let taskTime;
    let btns;

    // conditionals to render component based on task time/status
    if (moment() < taskStartTime) {
        // check if the task hasnt started
        taskTime = (
            <p className='providerItem__info--time'>
                &#x02022;&nbsp;Starts {taskStartTime.fromNow()} &nbsp;{' '}
                <span>- Participating</span>
            </p>
        );
        btns = (
            <React.Fragment>
                <IonButton
                    fill='clear'
                    color='ion-primary'
                    className='providerItem__btn--send'
                    // onClick={() => history.push(`/chatroom/${currentUser.uid}/${otherProfile.uid}`)}
                    onClick={handleChatroom}
                >
                    <a
                        ref={chatLink}
                        href={`/chatroom/${currentUser.uid}/${otherProfile.uid}`}
                        type='hidden'
                    ></a>
                    Send Message
                </IonButton>
                <IonButton
                    fill='clear'
                    color='ion-primary'
                    className='providerItem__btn--cancel'
                    onClick={() =>
                        history.push(`/cancel/${task.id}/participation`)
                    }
                >
                    Cancel Task
                </IonButton>
            </React.Fragment>
        );
    } else if (moment().isBetween(taskStartTime, taskEndTime)) {
        // check if the task is in progress
        taskTime = (
            <p className='providerItem__info--time'>
                &#x02022;&nbsp;Ends {taskEndTime.fromNow()} &nbsp;{' '}
                <span>- Participating</span>
            </p>
        );
        if (task.status.toString() === 'RECIPIENT_HAS_PROPOSED_OFFER') {
            btns = (
                <IonButton
                    fill='clear'
                    color='ion-primary'
                    className='completeTask__btn'
                >
                    <NavLink
                        to={{
                            pathname: `/completeTaskPosted/provider/${task.id}`,
                            state: {
                                id: task.id,
                            },
                        }}
                        className='completeTask__btn--link'
                    >
                        Complete This Task
                    </NavLink>
                </IonButton>
            );
        } else {
            btns = (
                <>
                    <div className='completeTask__btn--waiting'>
                        <IonSpinner
                            className='completeTask__btn--waiting--spinner'
                            name='crescent'
                        />
                        <p className='completeTask__btn--waiting--text'>
                            Waiting on Recipient
                        </p>
                    </div>
                    <IonButton
                        fill='clear'
                        color='ion-primary'
                        onClick={handleNudge}
                        disabled={disableClick}
                        className='completeTask__btn--nudge'
                    >
                        Nudge
                    </IonButton>
                </>
            );
        }
    } else if (moment() > taskEndTime) {
        // check if the task has ended
        taskTime = (
            <p className='providerItem__info--time'>
                &#x02022;&nbsp;Ended {taskEndTime.fromNow()} &nbsp;{' '}
                <span>- Participating</span>
            </p>
        );
        if (task.status.toString() === 'RECIPIENT_HAS_PROPOSED_OFFER') {
            btns = (
                <IonButton
                    fill='clear'
                    color='ion-primary'
                    className='completeTask__btn'
                >
                    <NavLink
                        to={{
                            pathname: `/completeTaskPosted/provider/${task.id}`,
                            state: {
                                id: task.id,
                            },
                        }}
                        className='completeTask__btn--link'
                    >
                        Complete This Task
                    </NavLink>
                </IonButton>
            );
        } else {
            btns = (
                <>
                    <div className='completeTask__btn--waiting'>
                        <IonSpinner
                            className='completeTask__btn--waiting--spinner'
                            name='crescent'
                        />
                        <p className='completeTask__btn--waiting--text'>
                            Waiting on Recipient
                        </p>
                    </div>
                    <IonButton
                        fill='clear'
                        color='ion-primary'
                        onClick={handleNudge}
                        disabled={disableClick}
                        className='completeTask__btn--nudge'
                    >
                        Nudge
                    </IonButton>
                </>
            );
        }
    }

    return (
        <IonCol className='providerItem' sizeSm='11' sizeXs='11'>
            <IonToast
                cssClass='completeTask__nudge--toast'
                isOpen={showToast}
                onDidDismiss={() => setShowToast(false)}
                message='Transaction Reminder Sent!'
                duration={2500}
            />
            <IonIcon
                onClick={() =>
                    history.push(`/task/participationRequest/${task.id}`)
                }
                icon={informationCircleOutline}
                className='providerItem__info--icon'
            />
            <IonCol sizeXs='12' sizeSm='12' className='providerItem__info'>
                <IonCol sizeXl='3' sizeLg='3' sizeMd='3' sizeXs='3' sizeSm='3'>
                    <div className='providerItem__img--wrap'>
                        <IonImg
                            onClick={() =>
                                hasAdmin
                                    ? {}
                                    : history.push(`/user/${otherProfile.uid}`)
                            }
                            className='providerItem__img'
                            src={
                                hasAdmin
                                    ? !loading
                                        ? data.AdminGroupProfilePicture
                                              .orgPicture
                                        : null
                                    : otherProfile.profilePicture
                            }
                        />
                    </div>
                </IonCol>
                <IonCol
                    className='providerItem__info--wrap'
                    sizeXs='9'
                    sizeSm='9'
                >
                    <h3 className='providerItem__info--name'>
                        {task.skill.name} w/ {hasAdmin ? 'admin of' : ''}{' '}
                        {hasAdmin
                            ? !loading
                                ? data.AdminGroupProfilePicture.name
                                : null
                            : otherProfile.firstName}
                    </h3>
                    {taskTime}
                </IonCol>
            </IonCol>
            {task.status.toString() === 'RECIPIENT_HAS_PROPOSED_OFFER' && (
                <IonCol sizeXs='12' sizeSm='12'>
                    <p className='providerItem__info--time'>
                        <span>
                            Recipient has proposed{' '}
                            {time_credit_conversion(task.recipientOfferCredits)}{' '}
                            Balance Tokens
                        </span>
                    </p>
                </IonCol>
            )}
            <IonCol sizeXs='12' sizeSm='12' className='providerItem__btn--wrap'>
                {btns}
            </IonCol>
        </IonCol>
    );
};

export default ProviderRequestItem;
