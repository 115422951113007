// importing modules
import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
// importing components
import { IonButton, IonCol, IonRow } from '@ionic/react';
import { IonAvatar, IonIcon } from '@ionic/react';
import SkillReviewControls from '../../components/SkillReviewControls/SkillReviewControls';
import SkillReviewItemFallback from '../../components/SkillReviewItemFallback/SkillReviewItemFallback';
import limitStr from '../../utils/limitString';
import getTaskTime from '../../utils/getTaskTime';
// importing assets
import { star } from 'ionicons/icons';
// importing styles
import './SkillReview.css';

const SkillReviews: React.FC<{
    skillReviews: any;
    userSkills: any;
    handleSelectedSkill: Function;
    selectedSkill: string;
    selectedSkillId: number;
    userName: string;
}> = ({
    userSkills,
    handleSelectedSkill,
    selectedSkill,
    userName,
    skillReviews,
    selectedSkillId,
}) => {
    const { uid } = useParams<{ uid: string }>();
    const history = useHistory();

    useEffect(() => {
        if (userSkills.length > 0) {
            handleSelectedSkill(userSkills[0].name, userSkills[0].id);
        }
    }, []);

    return (
        <React.Fragment>
            {/* profile reviews */}
            <IonRow className='ion-padding reviews'>
                <IonRow className='reviews__row'>
                    <IonCol
                        className='ion-padding reviews__col--header'
                        sizeSm='12'
                        sizeXs='12'
                    >
                        <h1 className='reviews__title'>Ratings & Reviews</h1>
                    </IonCol>
                </IonRow>
                {/* review-skill-btn-controls */}
                <IonRow className='reviews__btn--row'>
                    {userSkills.length > 0
                        ? userSkills.map((skill) => {
                              return (
                                  <SkillReviewControls
                                      key={skill.id}
                                      skillTitle={skill.name}
                                      handleSelectedSkill={handleSelectedSkill}
                                      selectedSkill={selectedSkill}
                                      reviewsAvg={
                                          skill.reviews.length > 0
                                              ? skill.reviews.length
                                              : 0
                                      }
                                  />
                              );
                          })
                        : null}
                </IonRow>
                {/* skill review list */}
                <IonRow className='reviews__row'>
                    {skillReviews.length > 0 ? (
                        skillReviews.map((review) => {
                            return (
                                <IonCol
                                    key={review.id}
                                    className='ion-margin-bottom ion-margin-top reviews__col'
                                    sizeXs='12'
                                    sizeSm='12'
                                >
                                    <IonAvatar>
                                        <img
                                            className='reviews__avatar--img'
                                            alt='user'
                                            src={review.author.profilePicture}
                                        />
                                    </IonAvatar>
                                    <IonCol
                                        sizeXs='10'
                                        sizeSm='10'
                                        className='ion-padding reviews__content'
                                    >
                                        <IonCol
                                            sizeXs='12'
                                            sizeSm='12'
                                            className='reviews__content--title--wrap'
                                        >
                                            <p className='reviews__content--title'>{`${review.author.firstName} ${review.author.lastName}`}</p>
                                            <p className='reviews__content--title--date'>
                                                {getTaskTime(
                                                    review.createdAt
                                                ).format('YYYY-MM-DD')}
                                            </p>
                                        </IonCol>
                                        <IonCol className='reviews__content--stars--wrap'>
                                            {[...Array(5)].map((s, i) => {
                                                const ratingVal = i + 1;

                                                return (
                                                    <label key={i}>
                                                        <input
                                                            type='radio'
                                                            name='rating'
                                                            value={ratingVal}
                                                        />
                                                        <IonIcon
                                                            style={{
                                                                color:
                                                                    ratingVal <=
                                                                    review.rating
                                                                        ? '#f7b500'
                                                                        : '#d3d3d3',
                                                            }}
                                                            icon={star}
                                                            className='reviews__content--stars'
                                                        />
                                                    </label>
                                                );
                                            })}
                                        </IonCol>
                                        <p className='reviews__content--text'>
                                            {review.review}
                                        </p>
                                    </IonCol>
                                </IonCol>
                            );
                        })
                    ) : (
                        <SkillReviewItemFallback>
                            Request them for a task or skill to
                            <br />
                            give them their first review!
                            {/* This person hasn't performed this skill for
                            <br />
                            anyone in the YING community yet. */}
                        </SkillReviewItemFallback>
                    )}
                </IonRow>
                <IonRow className='reviews__row'>
                    <IonCol
                        sizeXs='12'
                        sizeSm='12'
                        className='reviews__btn--more--wrap'
                    >
                        {skillReviews.length > 0 ? (
                            <IonButton
                                fill='clear'
                                className='reviews__btn--more'
                                color='ion-primary'
                            >
                                See More Reviews
                            </IonButton>
                        ) : (
                            <IonButton
                                onClick={() =>
                                    history.push(
                                        `/requestSkill/${uid}/${selectedSkill}/${selectedSkillId}/date`
                                    )
                                }
                                fill='clear'
                                className='reviews__btn--hire'
                                color='ion-primary'
                            >
                                Request {userName}{' '}
                                {selectedSkill !== '' &&
                                    `for ${limitStr(selectedSkill, 14)}`}{' '}
                            </IonButton>
                        )}
                    </IonCol>
                </IonRow>
            </IonRow>
        </React.Fragment>
    );
};

export default SkillReviews;
