// modules
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Capacitor, Plugins, registerWebPlugin } from '@capacitor/core';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { createBrowserHistory } from 'history';
import * as serviceWorker from './serviceWorker';
// components
import App from './App';
import registerPushNotifications from 'utils/registerPushNotifications';

const { SplashScreen } = Plugins;

// first things first.. kill that splash

SplashScreen.hide().then((r) => console.log(r));

if (Capacitor.platform !== 'web') {
    window.screen.orientation.lock('portrait'); // lock screen to portrait mode
}
// Plugins...
// @ts-ignore
registerWebPlugin(GoogleAuth);

const history = createBrowserHistory();

//registerPushNotifications(history);

ReactDOM.render(
    <Router history={history}>
        <App />
    </Router>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
