// importing modules
import React, { useRef, useState, useEffect, useCallback } from 'react';
// importing component
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonRow,
    IonModal,
} from '@ionic/react';
import ReactCrop from 'react-image-crop';
import Strokes from 'components/Strokes';
import XButton from 'components/XButton';
// importing styles
import 'react-image-crop/dist/ReactCrop.css';
import './ReactCrop.overrides.css';
import styles from './ImageCropperModal.module.css';

// Setting a high pixel ratio avoids blurriness in the canvas crop preview.
const pixelRatio = 4;

// We resize the canvas down when saving on retina devices otherwise the image
// will be double or triple the preview size.
function getResizedCanvas(canvas, newWidth, newHeight) {
    const tmpCanvas = document.createElement('canvas');
    tmpCanvas.width = newWidth;
    tmpCanvas.height = newHeight;

    const ctx = tmpCanvas.getContext('2d');
    ctx.drawImage(
        canvas,
        0,
        0,
        canvas.width,
        canvas.height,
        0,
        0,
        newWidth,
        newHeight
    );

    return tmpCanvas;
}

const ImageCropperModal = ({
    handleCropperModal,
    imgToCrop,
    setImgBlob,
    setImgPreview,
    setImgToCrop,
    showCropperModal,
}: {
    handleCropperModal: () => void;
    imgToCrop: string;
    setImgBlob: Function;
    setImgPreview: Function;
    setImgToCrop: Function;
    showCropperModal: boolean;
}) => {
    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 1 });
    const [completedCrop, setCompletedCrop] = useState(null);

    const handleConfirmImageCrop = (previewCanvas: any, crop: any) => {
        if (!crop || !previewCanvas) {
            return;
        }
        const canvas = getResizedCanvas(previewCanvas, crop.width, crop.height);
        canvas.toBlob((blob) => setImgBlob(blob)); // set the img blob
        setImgPreview(canvas.toDataURL()); // set the imgPreview
        //setImgToCrop(undefined); // clear the imgTo Crop data
        handleCropperModal(); // close the modal
    };

    const onLoad = (img) => {
        imgRef.current = img;
    };

    useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
            return;
        }

        const image = imgRef.current;
        const canvas = previewCanvasRef.current;
        const crop = completedCrop;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');

        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingEnabled = false;

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );
    }, [completedCrop]);

    return (
        <IonModal isOpen={showCropperModal}>
            <IonContent>
                <Strokes top={true} green={false} />
                <IonGrid>
                    <IonRow className={styles.container}>
                        <IonCol className={styles.content}>
                            <XButton action={handleCropperModal} />
                            <h1>
                                <span>Style</span> your pic
                            </h1>
                            <p className={styles.text}>
                                Drag your finger horizontally across the image
                                to crop it.
                            </p>
                            <div className={styles.cropper}>
                                <ReactCrop
                                    src={imgToCrop}
                                    onImageLoaded={onLoad}
                                    crop={crop}
                                    onChange={(c) => setCrop(c)}
                                    onComplete={(c) => setCompletedCrop(c)}
                                />
                            </div>

                            {crop.width > 0 && (
                                <>
                                    <p className={styles.uploadText}>
                                        Pic Preview
                                    </p>
                                    <div className={styles.preview}>
                                        <canvas ref={previewCanvasRef} />
                                    </div>
                                </>
                            )}

                            <IonButton
                                fill='clear'
                                color='ion-primary'
                                className={styles.btn}
                                disabled={crop.width <= 0}
                                onClick={() => {
                                    handleConfirmImageCrop(
                                        previewCanvasRef.current,
                                        completedCrop
                                    );
                                }}
                            >
                                Crop Image
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonModal>
    );
};

export default ImageCropperModal;
