// importing modules
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PlacesAutocomplete from 'react-places-autocomplete';
// importing components
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonLabel,
    IonRow,
    IonIcon,
    IonModal,
} from '@ionic/react';
import UserImg from '../UI/UserImg/UserImg';
import GroupImg from '../UI/GroupImg/GroupImg';
import TaskLocationItem from '../TaskLocation/TaskLocationItem/TaskLocationItem';
import Strokes from 'components/Strokes';
// importing assets
import { arrowBackOutline, closeOutline } from 'ionicons/icons';
// importing helpers
import {
    getLocalStorage,
    setLocalStorage,
} from '../../../helpers/localStorage/localStorageHelper';
import { LocationPreference } from '../../../GraphQL/__generated__/globalTypes';
// importing styles
import '../TaskLocation/TaskLocation.css';
import '../../../components/GooglePlacesDropDown/GooglePlacesDropDown.css';

const SAVED_LOCATIONS = [
    {
        id: 1,
        name: LocationPreference.VIRTUAL,
        address:
            'Send the skill provider a video conference link in the chat once you complete this request',
    },
];

const TaskLocation: React.FC<{
    tskLocation: string;
    setTskLocation: Function;
    modalStatus: boolean;
    handleModal: () => void;
    taskType: string;
}> = ({ handleModal, modalStatus, tskLocation, setTskLocation, taskType }) => {
    const history = useHistory();
    const [locationType, setLocationType] = useState<string>('');
    const [taskLocation, setTaskLocation] = useState<string>(tskLocation);
    const [address, setAddress] = useState<string>(
        tskLocation === 'Virtual' ? '' : tskLocation
    );

    const handleAddress = (address) => {
        setAddress(address);
        setTaskLocation(address);
    };

    useEffect(() => {
        if (getLocalStorage('createTask')) {
            const groupTaskObj = getLocalStorage('createTask');
            setLocationType(groupTaskObj.locationPreference);
            setTaskLocation(groupTaskObj.location);
            setAddress(
                groupTaskObj.location === 'VIRTUAL' ? '' : groupTaskObj.location
            );
        }
    }, []);

    const handleUpdateLocation = (loc: string) => {
        const groupTaskObj = getLocalStorage('createTask');
        groupTaskObj.location = loc !== 'VIRTUAL' ? loc : 'VIRTUAL';
        groupTaskObj.locationPreference = locationType;
        setLocalStorage('createTask', groupTaskObj);
        setTskLocation(loc);
    };

    let img;
    if (taskType === 'HOME' && getLocalStorage('currentAdminGroupId') == 0) {
        img = <UserImg />;
    } else if (
        taskType === 'GROUP' &&
        getLocalStorage('currentAdminGroupId') == 0
    ) {
        img = <UserImg />;
    } else {
        img = <GroupImg />;
    }

    return (
        <IonModal isOpen={modalStatus}>
            <IonContent>
                <IonGrid>
                    <IonRow className='taskLocation__header'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='taskLocation__header--container'
                        >
                            <Strokes top={true} />
                            <IonCol
                                sizeXs='12'
                                sizeSm='12'
                                sizeMd='12'
                                className='completeTaskNav__btns--wrap'
                            >
                                <IonButton
                                    className='completeTaskNav__btn'
                                    onClick={handleModal}
                                    fill='clear'
                                    color='ion-primary'
                                >
                                    <IonIcon
                                        className='completeTaskNav__btn--icon'
                                        icon={arrowBackOutline}
                                    />
                                </IonButton>
                                <IonButton
                                    className='completeTaskNav__btn'
                                    onClick={() => {
                                        handleModal();
                                        const createTask = {
                                            taskName: '',
                                            taskDescr: '',
                                            date: null,
                                            startTime: '',
                                            endTime: '',
                                            location: '',
                                            locationPreference: '',
                                            skillsNeeded: [],
                                            groupCodes: [],
                                            memberInvites: [],
                                        };
                                        setLocalStorage(
                                            'createTask',
                                            createTask
                                        );
                                        history.push('/home');
                                    }}
                                    color='ion-primary'
                                    fill='clear'
                                >
                                    <IonIcon
                                        className='completeTaskNav__btn--icon'
                                        icon={closeOutline}
                                    />
                                </IonButton>
                            </IonCol>
                            {img}
                        </IonCol>
                    </IonRow>
                    {/* text content */}
                    <IonRow className='taskLocation__textContent'>
                        <IonCol
                            className='taskLocation__textContent--container'
                            sizeSm='12'
                            sizeXs='12'
                        >
                            <h1 className='taskLocation__textContent--title'>
                                Choose a <span>location</span>
                            </h1>
                            <p className='taskLocation__textContent--text'>
                                If you don’t know, tap “time hasn’t been
                                decided”.
                            </p>
                        </IonCol>
                    </IonRow>
                    {/* task location form */}
                    <IonGrid className='taskLocation__form--container'>
                        <IonRow className='taskLocation__form--row'>
                            <p className='taskLocation__form--row--title'>
                                Your Saved Locations
                            </p>
                            <IonRow className='taskLocation__col--row'>
                                {SAVED_LOCATIONS.map((location) => (
                                    <TaskLocationItem
                                        locationType={locationType}
                                        setLocationType={setLocationType}
                                        taskLocation={taskLocation}
                                        setTaskLocation={setTaskLocation}
                                        key={location.id}
                                        {...location}
                                    />
                                ))}
                            </IonRow>
                            {/* section divider */}
                            <IonRow className='taskLocation__typeDivider'>
                                <IonCol
                                    sizeXs='12'
                                    sizeSm='12'
                                    className='taskLocation__typeDivider--container'
                                >
                                    <p className='taskLocation__typeDivider__text'>
                                        <span className='taskLocation__typeDivider__text--span'>
                                            or
                                        </span>
                                    </p>
                                </IonCol>
                            </IonRow>
                            {/* section divider */}
                            <IonCol
                                className='taskLocation__form--input--wrap'
                                sizeXs='12'
                                sizeSm='12'
                            >
                                <IonLabel className='taskLocation__form--label'>
                                    New Location
                                </IonLabel>
                            </IonCol>
                            <PlacesAutocomplete
                                value={address}
                                onChange={handleAddress}
                                shouldFetchSuggestions={address.length > 3}
                                // onSelect={handleSelect}
                            >
                                {({
                                    getInputProps,
                                    suggestions,
                                    getSuggestionItemProps,
                                    loading,
                                }) => (
                                    <IonCol
                                        className='taskLocation__form--input--wrap'
                                        sizeXs='12'
                                        sizeSm='12'
                                    >
                                        <input
                                            onClick={() => {
                                                setLocationType(
                                                    LocationPreference.INPERSON
                                                );
                                                if (address.length === 0) {
                                                    setTaskLocation('');
                                                } else {
                                                    setTaskLocation(address);
                                                }
                                            }}
                                            className={
                                                suggestions.length > 0
                                                    ? 'taskLocation__form--input taskLocation__form--input--focus'
                                                    : 'taskLocation__form--input'
                                            }
                                            placeholder='Enter address here...'
                                            value={address}
                                            type='text'
                                            {...getInputProps({
                                                placeholder:
                                                    'Enter address here...',
                                                className:
                                                    'location-search-input taskLocation__form--input',
                                            })}
                                        />
                                        {suggestions.length > 0 && (
                                            <div className='autocomplete__dropdown--container'>
                                                {loading && (
                                                    <div>Loading...</div>
                                                )}
                                                {suggestions.map(
                                                    (suggestion, index) => {
                                                        const className =
                                                            'autocomplete__dropdown--item';
                                                        return (
                                                            <div
                                                                {...getSuggestionItemProps(
                                                                    suggestion,
                                                                    {
                                                                        className,
                                                                        key: index,
                                                                    }
                                                                )}
                                                            >
                                                                <p className='autocomplete__dropdown--item--text'>
                                                                    {
                                                                        suggestion.description
                                                                    }
                                                                </p>
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </div>
                                        )}
                                    </IonCol>
                                )}
                            </PlacesAutocomplete>
                        </IonRow>
                    </IonGrid>
                    {/* btn */}
                    <IonRow className='taskLocation__btn--container'>
                        <IonCol
                            className='taskLocation__btn--wrap'
                            sizeXs='12'
                            sizeSm='12'
                        >
                            <IonButton
                                fill='clear'
                                disabled={taskLocation.length === 0}
                                color='ion-primary'
                                className='createTask__btn'
                                onClick={() => {
                                    handleUpdateLocation(taskLocation);
                                    handleModal();
                                }}
                            >
                                Update Location
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonModal>
    );
};

export default TaskLocation;
