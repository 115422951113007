// importing modules
import React, { useState } from 'react';
// importing components
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonModal,
    IonRow,
} from '@ionic/react';
import Strokes from 'components/Strokes';
// importing assets
// importing graphql
import {
    UpdateBio,
    UpdateBioVariables,
} from '../../../GraphQL/__generated__/UpdateBio';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_BIO_BY_SUB_ID } from '../../../GraphQL/UpdateBioBySubID';
import { yingDebug, yingInfo } from '../../../utils/debug/YingDebug';
// importing styles
import './EditBioModal.css';

const EditBioModal: React.FC<{
    handleBioModal: () => void;
    showBioModal: boolean;
    userBio: string;
    uid: string;
}> = ({ showBioModal, handleBioModal, userBio, uid }) => {
    const [bio, setBio] = useState<string>(userBio === null ? '' : userBio);
    const [updateBio, bioUpdateDatas] = useMutation<
        UpdateBio,
        UpdateBioVariables
    >(UPDATE_BIO_BY_SUB_ID);

    const handleUpdateBio = (newBio: string) => {
        updateBio({
            variables: {
                newBio: newBio,
                uid: uid,
            },
        })
            .catch((e) => yingDebug(e))
            .then((r) => yingInfo(JSON.stringify(r)));

        handleBioModal();
    };

    return (
        <IonModal isOpen={showBioModal}>
            <IonContent scrollY={false}>
                <IonGrid className='editBio__grid'>
                    <IonRow className='editBio__row'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='editBio__container'
                        >
                            <Strokes green={false} />
                            <IonCol
                                sizeSm='12'
                                sizeXs='12'
                                className='editBio__content--container'
                            >
                                <h1 className='editBio__content--title'>
                                    <span>Update</span> Your Bio
                                </h1>
                                <p className='editBio__content--text'>
                                    Provide a short bio about yourself to tell
                                    the rest of the community.
                                </p>
                                <IonRow className='editBio__textarea--container'>
                                    <IonCol
                                        className='editBio__textarea--wrap'
                                        sizeXs='12'
                                        sizeSm='12'
                                    >
                                        <textarea
                                            onChange={(event) =>
                                                setBio(event.target.value)
                                            }
                                            value={bio}
                                            className='editBio__textarea'
                                            placeholder='Tell us about yourself...'
                                            required
                                        ></textarea>
                                    </IonCol>
                                </IonRow>
                                <IonRow className='editBio__btn--container'>
                                    <IonCol
                                        className='editBio__btn--wrap'
                                        sizeXs='12'
                                        sizeSm='12'
                                    >
                                        <IonButton
                                            onClick={handleBioModal}
                                            fill='clear'
                                            color='ion-primary'
                                            className='editBio__btn--cancel'
                                        >
                                            Cancel
                                        </IonButton>
                                        <IonButton
                                            onClick={() => handleUpdateBio(bio)}
                                            fill='clear'
                                            color='ion-primary'
                                            className='editBio__btn--update'
                                        >
                                            Update Bio
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                            </IonCol>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonModal>
    );
};

export default EditBioModal;
