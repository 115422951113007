export const getLocalStorage = (keyName: string) =>
    JSON.parse(localStorage.getItem(keyName)); // gets localStorage based on a key

export const setLocalStorage = (keyName: string, value: any) =>
    localStorage.setItem(keyName, JSON.stringify(value)); // sets localStorage based on a key

export const updateLocalStorage = (
    keyName: string,
    prop: string,
    value: any
) => {
    // updates localStorage based on a key
    const localStorageObj = JSON.parse(localStorage.getItem(keyName));
    localStorageObj[prop] = value;
    localStorage.setItem(keyName, JSON.stringify(localStorageObj));
};

export const addSkillToLocalStorage = (
    keyName: string,
    prop: string,
    value: any
) => {
    // adds a skill to localStorage
    const localStorageObj = JSON.parse(localStorage.getItem(keyName));
    localStorageObj[prop].push(value);
    localStorage.setItem(keyName, JSON.stringify(localStorageObj));
};

export const deleteSkillFromLocalStorage = (
    keyName: string,
    prop: string,
    value: any
) => {
    // deletes a skill from localStorage
    const localStorageObj = JSON.parse(localStorage.getItem(keyName));
    localStorageObj[prop] = localStorageObj[prop].filter(
        (skill) => skill.name !== value
    );
    localStorage.setItem(keyName, JSON.stringify(localStorageObj));
};

export const addMemberInviteToLocalStorage = (
    keyName: string,
    prop: string,
    value: any
) => {
    // adds a member invite to localStorage
    const localStorageObj = JSON.parse(localStorage.getItem(keyName));
    localStorageObj[prop].push(value);
    localStorage.setItem(keyName, JSON.stringify(localStorageObj));
};

export const deleteMemberInviteFromLocalStorage = (
    keyName: string,
    prop: string,
    value: any
) => {
    // deletes a member invite from localStorage
    const localStorageObj = JSON.parse(localStorage.getItem(keyName));
    localStorageObj[prop] = localStorageObj[prop].filter(
        (member) => member.uid !== value
    );
    localStorage.setItem(keyName, JSON.stringify(localStorageObj));
};

export const deleteLocalStorage = (keyName: string) =>
    localStorage.removeItem(keyName); // deletes localStorage based on a key
