import React from 'react';
import { IonCol, IonImg, IonRow } from '@ionic/react';
import './GroupProfileHeader.css';
import Strokes from 'components/Strokes';

const GroupProfileHeader: React.FC<{
    communityName: string;
    profileImg: string;
}> = ({ communityName, profileImg }) => {
    return (
        <IonRow className='groupProfileHeader'>
            <IonCol
                sizeXs='12'
                sizeSm='12'
                className='groupProfileHeader__col--top'
            >
                <Strokes top={true} />
            </IonCol>
            <IonRow className='groupProfileHeader__row'>
                <IonCol
                    sizeSm='4'
                    sizeXs='4'
                    className='groupProfileHeader__col--img'
                >
                    <div className='groupProfileHeader__img--wrap'>
                        <IonImg
                            className='groupProfileHeader__img'
                            src={`${profileImg}`}
                        />
                    </div>
                </IonCol>
                <IonCol
                    sizeSm='8'
                    sizeXs='8'
                    className={
                        communityName.length >= 10
                            ? 'groupProfileHeader__col--bottom--right'
                            : 'groupProfileHeader__col--bottom--left'
                    }
                >
                    <h1 className='groupProfileHeader__name'>
                        {communityName}
                    </h1>
                </IonCol>
            </IonRow>
        </IonRow>
    );
};

export default GroupProfileHeader;
