import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
// importing components
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonPage,
    IonRow,
} from '@ionic/react';
import Strokes from 'components/Strokes';
import BackButton from 'components/BackButton';
// importing assets
import yingLogo from '../../../assets/ying-logo-large-blk.png';
// importing styles
import '../ForgotPassword.css';

const ForgotPasswordConfirmation: React.FC = () => {
    const history = useHistory();
    const { email } = useParams<{ email: string }>();

    return (
        <IonPage>
            <IonContent scrollY={false}>
                <IonGrid className='forgotPassword__grid'>
                    <IonRow className='forgotPassword'>
                        <IonCol
                            sizeXs='12'
                            sizeSm='12'
                            className='forgotPassword__container'
                        >
                            <BackButton />
                            <Strokes green={false} />
                            <IonCol
                                sizeXs='12'
                                sizeSm='12'
                                className='forgotPassword__container'
                            >
                                <IonImg
                                    className='forgotPassword__img'
                                    src={yingLogo}
                                    alt='ying logo'
                                />
                                <IonRow className='forgotPassword__error--container'>
                                    <IonCol
                                        sizeXs='12'
                                        sizeSm='12'
                                        className='forgotPassword__error--col'
                                    >
                                        <h1 className='forgotPassword__title'>
                                            Email
                                            <span className='forgotPassword__title--span'>
                                                {' '}
                                                Sent!
                                            </span>
                                        </h1>
                                        <p className='forgotPassword__text'>
                                            A reset password link has been sent
                                            to {email}
                                        </p>
                                    </IonCol>
                                </IonRow>
                                <IonButton
                                    fill='clear'
                                    color='ion-primary'
                                    className='forgotPassword__form--submit'
                                    type='button'
                                    onClick={() => history.push('/login')}
                                >
                                    Return Home
                                </IonButton>
                            </IonCol>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default ForgotPasswordConfirmation;
